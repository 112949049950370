import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import apiService from "../../../common/api/apiService";
import { EntityIdObject, EntityObject, TwoLevelEntityIdObject } from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import {
  CalcDraft,
  CalcDraftAttachment,
  CalcDraftFilterPageRequest,
  CalcDraftFilterPageResult,
  CreateUpdateCalcDraft
} from "./types";

const FILTER_CALC_DRAFTS = ApiRequestAdapter.create("/calculators/drafts", "GET");
const GET_CALC_DRAFT = ApiRequestAdapter.create("calculators/drafts/{0}", "GET");
const CREATE_CALC_DRAFT = ApiRequestAdapter.create("/calculators/drafts", "POST");
const UPDATE_CALC_DRAFT = ApiRequestAdapter.create("/calculators/drafts/{0}", "PUT");
const DELETE_CALC_DRAFT = ApiRequestAdapter.create("/calculators/drafts/{0}", "DELETE");
const UPLOAD_CALC_DRAFT_ATTACHMENTS = ApiRequestAdapter.create("/calculators/drafts/{0}/attachments", "POST");
const DOWNLOAD_CALC_DRAFT_ATTACHMENT = ApiRequestAdapter.create("/calculators/drafts/{0}/attachments/{1}", "GET");
const DELETE_CALC_DRAFT_ATTACHMENT = ApiRequestAdapter.create("/calculators/drafts/{0}/attachments/{1}", "DELETE");

export const requests = {
  FILTER_CALC_DRAFTS,
  GET_CALC_DRAFT,
  CREATE_CALC_DRAFT,
  UPDATE_CALC_DRAFT,
  DELETE_CALC_DRAFT,
  UPLOAD_CALC_DRAFT_ATTACHMENTS,
  DOWNLOAD_CALC_DRAFT_ATTACHMENT,
  DELETE_CALC_DRAFT_ATTACHMENT
};

const api = {
  filterCalcDrafts: (request: CalcDraftFilterPageRequest): AxiosPromise<CalcDraftFilterPageResult> => {
    return apiService.get(FILTER_CALC_DRAFTS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  getCalcDraft: (request: EntityIdObject): AxiosPromise<CalcDraft> => {
    return apiService.get(GET_CALC_DRAFT.getParametrizedUrl(request.id));
  },
  createCalcDraft: (request: CreateUpdateCalcDraft): AxiosPromise<CalcDraft> => {
    return apiService.post(CREATE_CALC_DRAFT.url, request);
  },
  updateCalcDraft: (request: EntityObject<CreateUpdateCalcDraft>): AxiosPromise<CalcDraft> => {
    return apiService.put(UPDATE_CALC_DRAFT.getParametrizedUrl(request.id), request.object);
  },
  deleteCalcDraft: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_CALC_DRAFT.getParametrizedUrl(request.id));
  },
  uploadCalcDraftAttachments: (request: EntityObject<FormData>): AxiosPromise<CalcDraftAttachment[]> => {
    return apiService.post(UPLOAD_CALC_DRAFT_ATTACHMENTS.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  downloadCalcDraftAttachment: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_CALC_DRAFT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  },
  deleteCalcDraftAttachment: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_CALC_DRAFT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2));
  }
};

export default api;
