import { Button, Col, Modal, Row, Tabs } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import { ModalSizes } from "../../../../../common/constants";
import { CalcType } from "../../../enums";
import type { RealtyCalc, RealtyCalcResultData, RealtyFormClients, RealtyGenForm } from "../../realty/types";
import type { TravelCalc, TravelCalcResultData, TravelFormClients, TravelGenForm } from "../../travel/types";
import type { CalcResult } from "../../types";
import type { VehicleCalc, VehicleCalcResultData, VehicleFormClients, VehicleGenForm } from "../../vehicle/types";
import ClientApprovalsCalcSummaryView from "./ClientApprovalsCalcSummaryView";
import RealtyCalcSummaryView from "./RealtyCalcSummaryView";
import TravelCalcSummaryView from "./TravelCalcSummaryView";
import VehicleCalcSummaryView from "./VehicleCalcSummaryView";

export type VehicleCalcSummaryViewData = {
  calcData: VehicleCalc;
  clients: VehicleFormClients;
  selectedInsurance: CalcResult<VehicleCalcResultData>;
};

export type RealtyCalcSummaryViewData = {
  calcData: RealtyCalc;
  clients: RealtyFormClients;
  selectedInsurance: CalcResult<RealtyCalcResultData>;
};

export type TravelCalcSummaryViewData = {
  calcData: TravelCalc;
  clients: TravelFormClients;
  selectedInsurance: CalcResult<TravelCalcResultData>;
};

interface Props {
  open: boolean;
  form: FormInstance<RealtyGenForm> | FormInstance<TravelGenForm> | FormInstance<VehicleGenForm>;
  data: TravelCalcSummaryViewData | RealtyCalcSummaryViewData | VehicleCalcSummaryViewData;
  onOkClick: () => void;
  onCancelClick: () => void;
}

enum SUMMARY_TABS_KEY {
  CONTRACT_DATA = "contract-data",
  CLIENT_APPROVALS = "client-approvals"
}

const CalcSummaryModal = ({ open, onOkClick, onCancelClick, data, form }: Props) => {
  const [approvals, setApprovals] = useState<boolean[]>(Array(6).fill(false));
  const [allApprovals, setAllApprovals] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(SUMMARY_TABS_KEY.CONTRACT_DATA);
  const [contractSubmitted, setContractSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setApprovals(Array(6).fill(false, 0, 6));
      setAllApprovals(false);
      setContractSubmitted(false);
    }
  }, [open]);

  useEffect(() => {
    const isAllApproved = approvals.every(approval => approval);

    if (!isAllApproved && allApprovals) {
      setAllApprovals(false);
    } else if (approvals.every(approval => approval)) {
      setAllApprovals(true);
    }
  }, [approvals, allApprovals]);

  const handleAllApprovalsChange = (): void => {
    setAllApprovals(!allApprovals);
    setApprovals(Array(6).fill(!allApprovals, 0, 6));
  };

  const handleApprovalChange = (checked: boolean, index: number): void => {
    const updatedApprovals = [...approvals];
    updatedApprovals[index] = checked;
    setApprovals(updatedApprovals);
  };

  const handleGenerateContractClick = (): void => {
    setContractSubmitted(true);
    onOkClick();
  };

  const getCalcTypeSummaryView = (): React.JSX.Element | undefined => {
    switch (data.calcData.type) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        return (
          <VehicleCalcSummaryView
            data={{ ...(data as VehicleCalcSummaryViewData), formData: form.getFieldsValue() as VehicleGenForm }}
          />
        );
      case CalcType.TRAVEL:
        return (
          <TravelCalcSummaryView
            data={{ ...(data as TravelCalcSummaryViewData), formData: form.getFieldsValue() as TravelGenForm }}
          />
        );
      case CalcType.REALTY:
        return (
          <RealtyCalcSummaryView
            data={{ ...(data as RealtyCalcSummaryViewData), formData: form.getFieldsValue() as RealtyGenForm }}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <Modal
      title={
        currentTab === SUMMARY_TABS_KEY.CONTRACT_DATA
          ? t("calc.helpers.summaryData.title")
          : t("calc.helpers.clientApprovals.title")
      }
      open={open}
      width={ModalSizes.LARGE}
      onCancel={onCancelClick}
      centered
      footer={
        <Row justify="space-between">
          <Col flex={1} className="left-align" style={{ marginTop: "4px" }}>
            {currentTab === SUMMARY_TABS_KEY.CLIENT_APPROVALS ? (
              <Button onClick={() => setCurrentTab(SUMMARY_TABS_KEY.CONTRACT_DATA)}>{t("common.back")}</Button>
            ) : undefined}
          </Col>
          <Col flex={1} className="center-align">
            {currentTab === SUMMARY_TABS_KEY.CONTRACT_DATA ? (
              <Button type="primary" size="large" onClick={() => setCurrentTab(SUMMARY_TABS_KEY.CLIENT_APPROVALS)}>
                {t("common.continue")}
              </Button>
            ) : (
              <Button
                type="primary"
                size="large"
                disabled={approvals.some(approval => !approval) || contractSubmitted}
                onClick={handleGenerateContractClick}
              >
                {t("calc.actions.generateContract")}
              </Button>
            )}
          </Col>
          <Col flex={1} className="right-align" style={{ marginTop: "4px" }}>
            <Button onClick={onCancelClick}>{t("common.close")}</Button>
          </Col>
        </Row>
      }
    >
      <div style={{ overflowY: "auto", maxHeight: "825px" }}>
        <Tabs
          activeKey={currentTab.toString()}
          onChange={setCurrentTab}
          renderTabBar={() => <></>}
          items={[
            {
              key: SUMMARY_TABS_KEY.CONTRACT_DATA,
              label: undefined,
              children: getCalcTypeSummaryView()
            },
            {
              key: SUMMARY_TABS_KEY.CLIENT_APPROVALS,
              label: undefined,
              children: (
                <ClientApprovalsCalcSummaryView
                  approvals={approvals}
                  allApprovals={allApprovals}
                  handleAllApprovals={handleAllApprovalsChange}
                  handleApprovalChange={handleApprovalChange}
                />
              )
            }
          ]}
        />
      </div>
    </Modal>
  );
};

export default CalcSummaryModal;
