import { Col, DatePicker, Form, Modal, Row } from "antd";
import dayjs from "dayjs";
import t from "../../../../app/i18n";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import {
  dateTimePickerClearableProps,
  dateToUtcIsoDateTimeString,
  resolveFormValidationError
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { scheduleJobActions } from "../../ducks";
import { JobSettings, ScheduleJobRequest } from "../../types";

interface Props {
  job?: JobSettings;
  open: boolean;
  onSchedule: typeof scheduleJobActions.request;
  onFormCancel: () => void;
}

const ScheduleJobForm = ({ job, open, onSchedule, onFormCancel }: Props) => {
  const [form] = Form.useForm<ScheduleJobRequest>();
  const inProgress = useRequestFinishedCallback([requests.SCHEDULE_JOB], onFormCancel);

  const handleFormSubmit = (): void => {
    if (job) {
      form
        .validateFields()
        .then(values =>
          onSchedule({
            id: job.id,
            object: { scheduledFor: values.scheduledFor }
          })
        )
        .catch(resolveFormValidationError);
    }
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={job ? t("job.titles.scheduleJob", { jobName: job.name }) : undefined}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="scheduleJobForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={16}>
            <Form.Item
              name="scheduledFor"
              label={t("job.attrs.record.scheduledFor")}
              rules={[validations.none]}
              getValueProps={value => ({ value: value && dayjs(value) })}
              normalize={value => dateToUtcIsoDateTimeString(value)}
            >
              <DatePicker {...dateTimePickerClearableProps} showTime />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ScheduleJobForm;
