import { Card, Divider, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { generatePath } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { ExportFileType } from "../../../../../common/enums";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../common/security/authorization/enums";
import { formatAddress } from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { CLIENT_ROUTE_PATHS } from "../../../paths";
import { ClientFilterPageResult, ClientList } from "../../../types";
import ClientTypeTagWithName from "../../tags/ClientTypeTagWithName";

interface Props {
  clientsPage: ClientFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onExportClick: (exportFileType: ExportFileType) => void;
}

const ClientListTableView = (props: Props) => {
  const columns: ColumnsType<ClientList> = [
    {
      key: "aggregatedName",
      title: t("client.attrs.aggregatedName"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, record) => <ClientTypeTagWithName client={record} ellipsis showLink />
    },
    {
      key: "personalIdentificationNumberOrCompanyRegistrationNumber",
      title: (
        <>
          {t("client.attrs.personalIdentificationNumber")} / {t("client.attrs.companyRegistrationNumber")}
        </>
      ),
      width: 120,
      render: (_, record) => record.identifier
    },
    {
      key: "address",
      title: t("client.attrs.address"),
      width: 190,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatAddress(record.address)}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_, record) => (
        <ActionTextIcon
          path={generatePath(CLIENT_ROUTE_PATHS.detail.to, { id: record.id })}
          icon="eye"
          color="blue"
          text={t("common.show")}
        />
      )
    }
  ];

  return (
    <Card className={"card-box"}>
      <ComponentWithPermission permissions={[Permission.CLIENT_EXPORT]}>
        <div
          className={props.clientsPage.totalElementsCount > 0 ? "table-header-export-actions" : "margin-bottom-small"}
        >
          <ActionTextIcon
            icon="download"
            color="blue"
            text={t("common.exportXlsx")}
            onClick={() => props.onExportClick(ExportFileType.XLSX)}
          />

          <Divider type="vertical" />

          <ActionTextIcon
            icon="download"
            color="green"
            text={t("common.exportCsv")}
            onClick={() => props.onExportClick(ExportFileType.CSV)}
          />
        </div>
      </ComponentWithPermission>

      <Table<ClientList>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.SMALL }}
        dataSource={props.clientsPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: props.clientsPage.pageIndex + 1,
          pageSize: props.clientsPage.pageSize,
          total: props.clientsPage.totalElementsCount,
          onChange: props.onPageChange
        }}
      />
    </Card>
  );
};

export default ClientListTableView;
