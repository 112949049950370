import { Checkbox, Col, Form, Input, Modal, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import LifeInsuranceTariffGroupSelect from "../../../enumerations/components/form/LifeInsuranceTariffGroupSelect";
import { requests } from "../../api";
import { createLifeInsuranceTariffActions, updateLifeInsuranceTariffActions } from "../../ducks";
import { CreateUpdateLifeInsuranceTariff, LifeInsuranceTariff } from "../../types";

interface Props {
  open: boolean;
  tariff?: LifeInsuranceTariff;
  onCreate: typeof createLifeInsuranceTariffActions.request;
  onUpdate: typeof updateLifeInsuranceTariffActions.request;
  onFormCancel: () => void;
}

const LifeInsuranceTariffForm = ({ open, tariff, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateLifeInsuranceTariff>();
  useFormErrorHandler(form, "lifeInsuranceTariff.attrs", [
    requests.CREATE_LIFE_INSURANCE_TARIFF,
    requests.UPDATE_LIFE_INSURANCE_TARIFF
  ]);

  useEffect(() => {
    if (open && tariff) {
      form.setFieldsValue({ groupId: tariff.group.id, ...tariff });
    }
  }, [open, tariff, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_LIFE_INSURANCE_TARIFF, requests.UPDATE_LIFE_INSURANCE_TARIFF],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (tariff) {
          onUpdate({ id: tariff.id, object: values });
        } else {
          onCreate(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 8;

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={open}
      title={t(tariff ? "lifeInsuranceTariff.titles.updateTariff" : "lifeInsuranceTariff.titles.createTariff")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={tariff} />

      <Form form={form} layout="vertical" name="lifeInsuranceTariffForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <LifeInsuranceTariffGroupSelect
              formItemProps={{
                name: "groupId",
                label: t("lifeInsuranceTariff.attrs.groupId"),
                rules: [validations.notNull]
              }}
              selectProps={{ disabled: !!tariff }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="code"
              label={t("lifeInsuranceTariff.attrs.code")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="primaryTariff"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("lifeInsuranceTariff.attrs.primaryTariff")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t("lifeInsuranceTariff.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 1024)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default LifeInsuranceTariffForm;
