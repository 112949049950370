import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";
import { generatePath } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import FileTypeIconWithFilename from "../../../../../common/components/icons/FileTypeIconWithFilename";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import UploadDragger from "../../../../../common/components/views/UploadDragger";
import { PageSizes, TableSizes } from "../../../../../common/constants";
import { formatFileSize, formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import {
  deleteAgentAttachmentActions,
  downloadAgentAttachmentsAsZipActions,
  uploadAgentAttachmentsActions
} from "../../../ducks";
import { AGENT_ROUTE_PATHS } from "../../../paths";
import { Agent, AgentAttachment } from "../../../types";
import styles from "./AgentAttachmentsTableView.module.scss";

interface Props {
  agent?: Agent;
  onDownloadAsZip: typeof downloadAgentAttachmentsAsZipActions.request;
  onUpload?: typeof uploadAgentAttachmentsActions.request;
  onDelete?: typeof deleteAgentAttachmentActions.request;
}

const AgentAttachmentsTableView = ({ agent, onDelete, onUpload, onDownloadAsZip }: Props) => {
  const handleAttachmentsUpload = (_: RcFile, fileList: RcFile[], agent: Agent): boolean => {
    if (fileList.length > 0 && onUpload) {
      const formData = new FormData();
      fileList.forEach(file => formData.append("files", file));
      fileList.splice(0, fileList.length);

      onUpload({ id: agent.id, object: formData });
    }

    return false;
  };

  const getColumns = (agent: Agent): ColumnsType<AgentAttachment> => [
    {
      key: "createdAt",
      title: t("common.createdAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "filename",
      title: t("common.filename"),
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <FileTypeIconWithFilename contentType={record.file.contentType} filename={record.file.filename} ellipsis />
      )
    },
    {
      key: "size",
      title: t("common.filesize"),
      width: 100,
      render: (_, record) => formatFileSize(record.file.size)
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon
            path={generatePath(AGENT_ROUTE_PATHS.attachment.to, { id1: agent.id, id2: record.id })}
            target="_blank"
            icon="export"
            color="blue"
            text={t("common.open")}
          />

          {onDelete && (
            <>
              <Divider type="vertical" />

              <Popconfirm
                title={t("common.deleteAttachmentConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => onDelete({ id1: agent.id, id2: record.id })}
              >
                <span>
                  <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
                </span>
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  return agent ? (
    <>
      {agent.attachments.length > 0 && (
        <div className={styles.tableHeaderDownloadActions}>
          <ActionButton icon="download" onClick={() => onDownloadAsZip({ id: agent.id })}>
            {t("agent.actions.downloadAttachmentsAsZip")}
          </ActionButton>
        </div>
      )}
      <Table<AgentAttachment>
        {...tableStandardProps()}
        columns={getColumns(agent)}
        scroll={{ x: TableSizes.SMALL }}
        dataSource={agent.attachments}
        pagination={{ ...paginationTableProps, pageSize: PageSizes.LARGE, total: agent.attachments.length }}
      />

      {onUpload && (
        <div className="margin-top-small margin-bottom-small">
          <UploadDragger
            multiple
            showUploadList={false}
            beforeUpload={(...params) => handleAttachmentsUpload(...params, agent)}
          />
        </div>
      )}
    </>
  ) : null;
};

export default AgentAttachmentsTableView;
