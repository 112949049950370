import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { EntityIdObject, EntityObject } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  AssignFinancialMediation,
  ChangeFinancialMediationVersion,
  CreateUpdateFinancialMediation,
  FinancialMediation,
  FinancialMediationFilterPageRequest,
  FinancialMediationFilterPageResult,
  FinancialMediationGenerateRequest
} from "./types";

const FILTER_FINANCIAL_MEDIATIONS = ApiRequestAdapter.create("/financial-mediations", "GET");
const GET_FINANCIAL_MEDIATION = ApiRequestAdapter.create("/financial-mediations/{0}", "GET");
const CREATE_FINANCIAL_MEDIATION = ApiRequestAdapter.create("/financial-mediations", "POST");
const UPDATE_FINANCIAL_MEDIATION = ApiRequestAdapter.create("/financial-mediations/{0}", "PUT");
const DELETE_FINANCIAL_MEDIATION = ApiRequestAdapter.create("/financial-mediations/{0}", "DELETE");
const GENERATE_FINANCIAL_MEDIATION = ApiRequestAdapter.create("/financial-mediations/{0}/generate", "GET");
const CHANGE_FINANCIAL_MEDIATION_VERSION = ApiRequestAdapter.create("/financial-mediations/{0}/change-version", "POST");
const ASSIGN_FINANCIAL_MEDIATION = ApiRequestAdapter.create("/financial-mediations/{0}/assign", "POST");

export const requests = {
  FILTER_FINANCIAL_MEDIATIONS,
  GET_FINANCIAL_MEDIATION,
  CREATE_FINANCIAL_MEDIATION,
  UPDATE_FINANCIAL_MEDIATION,
  DELETE_FINANCIAL_MEDIATION,
  GENERATE_FINANCIAL_MEDIATION,
  CHANGE_FINANCIAL_MEDIATION_VERSION,
  ASSIGN_FINANCIAL_MEDIATION
};

const api = {
  filterFinancialMediations: (
    request: FinancialMediationFilterPageRequest
  ): AxiosPromise<FinancialMediationFilterPageResult> => {
    return apiService.get(FILTER_FINANCIAL_MEDIATIONS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  getFinancialMediation: (request: EntityIdObject): AxiosPromise<FinancialMediation> => {
    return apiService.get(GET_FINANCIAL_MEDIATION.getParametrizedUrl(request.id));
  },
  createFinancialMediation: (request: CreateUpdateFinancialMediation): AxiosPromise<FinancialMediation> => {
    return apiService.post(CREATE_FINANCIAL_MEDIATION.url, request);
  },
  updateFinancialMediation: (
    request: EntityObject<CreateUpdateFinancialMediation>
  ): AxiosPromise<FinancialMediation> => {
    return apiService.put(UPDATE_FINANCIAL_MEDIATION.getParametrizedUrl(request.id), request.object);
  },
  deleteFinancialMediation: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_FINANCIAL_MEDIATION.getParametrizedUrl(request.id));
  },
  generateFinancialMediation: (request: EntityObject<FinancialMediationGenerateRequest>): AxiosPromise<Blob> => {
    return apiService.get(GENERATE_FINANCIAL_MEDIATION.getParametrizedUrl(request.id), {
      params: request.object,
      responseType: "blob"
    });
  },
  changeFinancialMediationVersion: (
    request: EntityObject<ChangeFinancialMediationVersion>
  ): AxiosPromise<FinancialMediation> => {
    return apiService.post(CHANGE_FINANCIAL_MEDIATION_VERSION.getParametrizedUrl(request.id), request.object);
  },
  assignFinancialMediation: (request: EntityObject<AssignFinancialMediation>): AxiosPromise<FinancialMediation> => {
    return apiService.post(ASSIGN_FINANCIAL_MEDIATION.getParametrizedUrl(request.id), request.object);
  }
};

export default api;
