import { AutoComplete, Form } from "antd";
import { AutoCompleteProps } from "antd/lib/auto-complete";
import { FormItemProps } from "antd/lib/form";
import { ContactType } from "../../../modules/enums";
import { Contact } from "../../../modules/types";
import { selectFilterFunction } from "../../../utils/formUtils";

interface Props {
  formItemProps: FormItemProps;
  autocompleteProps?: AutoCompleteProps<string>;
  contacts?: Contact[];
}

const ContactsEmailAutoComplete = ({ formItemProps, autocompleteProps, contacts }: Props) => (
  <Form.Item {...formItemProps}>
    <AutoComplete<string>
      {...autocompleteProps}
      popupMatchSelectWidth={false}
      options={
        contacts &&
        [...new Set(contacts?.filter(c => c.type === ContactType.EMAIL && !!c.value?.trim()).map(c => c.value))].map(
          email => ({ value: email, label: email })
        )
      }
      filterOption={selectFilterFunction}
    />
  </Form.Item>
);

export default ContactsEmailAutoComplete;
