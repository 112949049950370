import { Route, Routes } from "react-router";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import { Permission } from "../../common/security/authorization/enums";
import PageWithPermission from "../../common/security/authorization/PageWithPermission";
import PartnerConfigsContainer from "./containers/PartnerConfigsContainer";
import PartnerSsoOAuthClientContainer from "./containers/PartnerSsoOAuthClientContainer";
import PartnerSsoOAuthRedirectContainer from "./containers/PartnerSsoOAuthRedirectContainer";
import { PARTNER_ROUTE_PATHS } from "./paths";

const routes = () => (
  <Routes>
    <Route
      index
      element={
        <PageWithPermission
          component={<PartnerConfigsContainer />}
          permissions={[
            Permission.PARTNER_INSURANCE_COMPANIES,
            Permission.PARTNER_BANKS,
            Permission.PARTNER_SECURITIES_BROKERS,
            Permission.PARTNER_BUILDING_SAVINGS_BANKS,
            Permission.PARTNER_MANAGERIAL_PENSION_COMPANIES,
            Permission.PARTNER_SUPPLEMENTARY_PENSION_COMPANIES,
            Permission.PARTNER_OTHERS
          ]}
          anyPermission
        />
      }
    />

    <Route
      path={PARTNER_ROUTE_PATHS.ssoOAuthClient.path}
      element={
        <PageWithPermission
          component={<PartnerSsoOAuthClientContainer />}
          permissions={[Permission.PARTNER_SECURITIES_BROKERS]}
        />
      }
    />

    <Route
      path={PARTNER_ROUTE_PATHS.ssoOAuthRedirect.path}
      element={
        <PageWithPermission
          component={<PartnerSsoOAuthRedirectContainer />}
          permissions={[Permission.PARTNER_INSURANCE_COMPANIES]}
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
