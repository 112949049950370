import { Col, Row } from "antd";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { ContractDetailViewProps, DepositContract } from "../../../../types";
import ContractAgentsSection from "../ContractAgentsSection";
import ContractAttachmentsSection from "../ContractAttachmentsSection";
import ContractHeaderSection from "../ContractHeaderSection";
import DepositContractDataSection from "./sections/DepositContractDataSection";

const DepositContractDetailView = ({ contract, ...props }: ContractDetailViewProps<DepositContract>) => (
  <Row className="margin-top-small">
    <Col span={24}>
      <ContractHeaderSection
        contract={contract}
        updatePermission={Permission.DEPOSIT_UPDATE}
        verificationPermission={Permission.DEPOSIT_VERIFICATION_STATUS_CHANGE}
        deletePermission={Permission.DEPOSIT_DELETE}
        onVerificationStatusChange={props.onVerificationStatusChange}
        onExternalViewGet={props.onExternalViewGet}
        onDelete={props.onDelete}
        onUpdateClick={props.onUpdateClick}
      />

      <DepositContractDataSection contract={contract} />

      <ContractAgentsSection contract={contract} />

      <ContractAttachmentsSection
        contract={contract}
        onDownloadAsZip={props.onDownloadAsZip}
        onUpload={props.onAttachmentsUpload}
        onDelete={props.onAttachmentDelete}
      />
    </Col>
  </Row>
);

export default DepositContractDetailView;
