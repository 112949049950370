import { Col, Form, Input, Modal, Row, Select } from "antd";
import t from "../../../../../app/i18n";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { createVehicleModelMappingActions } from "../../ducks";
import { VehicleMappingType } from "../../enums";
import { CreateVehicleMapping, CreateVehicleMappingFormItem, VehicleMapping, VehicleMappingData } from "../../types";
import { parseVehicleMappingsFromIdentifiers, VEHICLE_IDENTIFIER_DELIMITER, VEHICLE_TYPES_PATH } from "../../utils";

interface Props {
  open: boolean;
  selectedBrand?: VehicleMapping;
  selectedMappingTypes: VehicleMappingType[];
  mappingData: VehicleMappingData[];
  onCreate: typeof createVehicleModelMappingActions.request;
  onFormCancel: () => void;
}

const VehicleModelCreateMappingModal = ({
  open,
  selectedBrand,
  selectedMappingTypes,
  mappingData,
  onCreate,
  onFormCancel
}: Props) => {
  const [form] = Form.useForm<CreateVehicleMappingFormItem>();
  useFormErrorHandler(form, "vehicle.attrs", [requests.CREATE_VEHICLE_MODEL_MAPPING]);

  const inProgress = useRequestFinishedCallback([requests.CREATE_VEHICLE_MODEL_MAPPING], onFormCancel);

  const handleFormSubmit = (): void => {
    if (selectedBrand) {
      form
        .validateFields()
        .then(values => {
          onCreate({
            id: selectedBrand.id,
            object: parseVehicleMappingsFromIdentifiers(values) as CreateVehicleMapping
          });
        })
        .catch(resolveFormValidationError);
    }
  };

  const colSpan = 6;

  return (
    <Modal
      width={ModalSizes.HUGE}
      open={open}
      maskClosable={false}
      confirmLoading={inProgress}
      title={
        selectedBrand
          ? t("vehicle.titles.modelCreateForBrand", { brandName: selectedBrand.name })
          : t("vehicle.titles.modelCreate")
      }
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="vehicleModelCreateMappingForm">
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item name="name" label={t("vehicle.attrs.name")} rules={[validations.notBlank]}>
              <Input size="small" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          {Object.values(VehicleMappingType).map((type, index) => {
            const typePath = VEHICLE_TYPES_PATH[type];
            const mapping = mappingData.find(mapping => mapping.type === type);
            const brandMapping = mapping
              ? mapping.brands.find(brand => selectedBrand && selectedBrand[typePath.keyId] === brand.id)
              : undefined;

            return selectedMappingTypes.includes(type) ? (
              <Col span={colSpan} key={index}>
                <Form.Item
                  name={typePath.keyIdentifier}
                  label={t(`vehicle.attrs.${typePath.keyIdentifier}`)}
                  rules={[validations.none]}
                >
                  <Select
                    {...selectStandardProps}
                    allowClear
                    size="small"
                    options={(brandMapping && brandMapping.models ? brandMapping.models : []).map(item => ({
                      value: item.id + VEHICLE_IDENTIFIER_DELIMITER + item.name,
                      label: `${item.name} (${item.id})`
                    }))}
                  />
                </Form.Item>
              </Col>
            ) : null;
          })}
        </Row>
      </Form>
    </Modal>
  );
};

export default VehicleModelCreateMappingModal;
