import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, EntityIdObject, RootState } from "../../../../common/types";
import { requests } from "../api";
import CommissionsLevelDetailView from "../components/views/detail/CommissionsLevelDetailView";
import {
  createCommissionsLevelBailAccountSettingsActions,
  createCommissionsLevelRuleActions,
  deleteCommissionsLevelActions,
  deleteCommissionsLevelAttachmentActions,
  deleteCommissionsLevelBailAccountSettingsActions,
  deleteCommissionsLevelRuleActions,
  deleteStateCommissionsLevelDetailAction,
  getCommissionsLevelActions,
  selectCommissionsLevelDetail,
  updateCommissionsLevelActions,
  updateCommissionsLevelBailAccountSettingsActions,
  updateCommissionsLevelRuleActions,
  uploadCommissionsLevelAttachmentsActions
} from "../ducks";
import { CommissionsSettingsLevel } from "../types";

interface StateProps {
  level?: CommissionsSettingsLevel;
}

interface ActionsMap {
  getCommissionsLevel: typeof getCommissionsLevelActions.request;
  updateCommissionsLevel: typeof updateCommissionsLevelActions.request;
  deleteCommissionsLevel: typeof deleteCommissionsLevelActions.request;
  deleteStateCommissionsLevelDetail: typeof deleteStateCommissionsLevelDetailAction;
  createCommissionsLevelRule: typeof createCommissionsLevelRuleActions.request;
  updateCommissionsLevelRule: typeof updateCommissionsLevelRuleActions.request;
  deleteCommissionsLevelRule: typeof deleteCommissionsLevelRuleActions.request;
  createCommissionsLevelBailAccountSettings: typeof createCommissionsLevelBailAccountSettingsActions.request;
  updateCommissionsLevelBailAccountSettings: typeof updateCommissionsLevelBailAccountSettingsActions.request;
  deleteCommissionsLevelBailAccountSettings: typeof deleteCommissionsLevelBailAccountSettingsActions.request;
  uploadCommissionsLevelAttachments: typeof uploadCommissionsLevelAttachmentsActions.request;
  deleteCommissionsLevelAttachment: typeof deleteCommissionsLevelAttachmentActions.request;
}

type Props = StateProps & ActionProps<ActionsMap>;

const CommissionsLevelDetailContainer = ({ level, actions }: Props) => {
  const { id } = useParams<EntityIdObject>() as { id: string };

  useEffect(() => {
    if (!level) {
      actions.getCommissionsLevel({ id });
    }
    return () => {
      actions.deleteStateCommissionsLevelDetail();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DisplayWrapper itemLoaded={!!level} notFoundCheckRequest={requests.GET_COMMISSIONS_LEVEL}>
      <ContentWrapper>
        {level ? (
          <CommissionsLevelDetailView
            level={level}
            actions={{
              onUpdate: actions.updateCommissionsLevel,
              onDelete: actions.deleteCommissionsLevel,
              onCreateRule: actions.createCommissionsLevelRule,
              onUpdateRule: actions.updateCommissionsLevelRule,
              onDeleteRule: actions.deleteCommissionsLevelRule,
              onCreateBailAccountSettings: actions.createCommissionsLevelBailAccountSettings,
              onUpdateBailAccountSettings: actions.updateCommissionsLevelBailAccountSettings,
              onDeleteBailAccountSettings: actions.deleteCommissionsLevelBailAccountSettings,
              onUploadAttachments: actions.uploadCommissionsLevelAttachments,
              onDeleteAttachment: actions.deleteCommissionsLevelAttachment
            }}
          />
        ) : undefined}
      </ContentWrapper>
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  level: selectCommissionsLevelDetail(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getCommissionsLevel: getCommissionsLevelActions.request,
      updateCommissionsLevel: updateCommissionsLevelActions.request,
      deleteCommissionsLevel: deleteCommissionsLevelActions.request,
      deleteStateCommissionsLevelDetail: deleteStateCommissionsLevelDetailAction,
      createCommissionsLevelRule: createCommissionsLevelRuleActions.request,
      updateCommissionsLevelRule: updateCommissionsLevelRuleActions.request,
      deleteCommissionsLevelRule: deleteCommissionsLevelRuleActions.request,
      createCommissionsLevelBailAccountSettings: createCommissionsLevelBailAccountSettingsActions.request,
      updateCommissionsLevelBailAccountSettings: updateCommissionsLevelBailAccountSettingsActions.request,
      deleteCommissionsLevelBailAccountSettings: deleteCommissionsLevelBailAccountSettingsActions.request,
      uploadCommissionsLevelAttachments: uploadCommissionsLevelAttachmentsActions.request,
      deleteCommissionsLevelAttachment: deleteCommissionsLevelAttachmentActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CommissionsLevelDetailContainer);
