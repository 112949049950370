import { Modal } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../../app/i18n";
import ContentWrapper from "../../../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, RootState } from "../../../../../common/types";
import messageUtils from "../../../../../common/utils/messageUtils";
import { QueryKeys } from "../../../../../common/utils/queryUtils";
import { ClientFormType } from "../../../../client/enums";
import { LegalClient, NaturalClient, SelfEmployedClient } from "../../../../client/types";
import { createCalcDraftActions, getCalcDraftActions, updateCalcDraftActions } from "../../../drafts/ducks";
import { CreateUpdateCalcDraft } from "../../../drafts/types";
import { CalcType } from "../../../enums";
import { CALC_ROUTE_PATHS } from "../../../paths";
import { getCalcRecordDataActions } from "../../../records/ducks";
import CalcLoadingModal from "../../components/CalcLoadingModal";
import { loadContractToCalculatorActions } from "../../ducks";
import { CalcDataSource, CalcResult, GenResponse } from "../../types";
import RealtyCalcWrapper from "../components/calc/RealtyCalcWrapper";
import RealtyGenWrapper from "../components/gen/RealtyGenWrapper";
import {
  calculateRealtyActions,
  deleteStateRealtyCalcResultsAction,
  deleteStateRealtyDraftAction,
  deleteStateRealtyGenResultAction,
  deleteStateRealtyInitialCalcGenDataAction,
  generateRealtyActions,
  generateRealtyOfferActions,
  selectRealtyCalcResults,
  selectRealtyDraft,
  selectRealtyGenResult,
  selectRealtyInitialCalcData,
  selectRealtyInitialGenData
} from "../ducks";
import { OperationStage } from "../enums";
import {
  RealtyCalc,
  RealtyCalcDraft,
  RealtyCalcResultData,
  RealtyFormClients,
  RealtyGen,
  RealtyGenForm,
  RealtyOfferType
} from "../types";
import {
  createRealtyCalcObjectFromCalcData,
  createRealtyCalcObjectFromGenData,
  createRealtyFormClientsObjectFromCalcData,
  createRealtyFormClientsObjectFromGenData,
  createRealtyGenFormDataObject
} from "../utils";

interface StateProps {
  initialCalcData?: RealtyCalc;
  initialGenData?: RealtyGen;
  draft?: RealtyCalcDraft;
  calcResults: CalcResult<RealtyCalcResultData>[][];
  genResult?: GenResponse;
}

interface ActionsMap {
  calculateRealty: typeof calculateRealtyActions.request;
  generateRealty: typeof generateRealtyActions.request;
  generateRealtyOffer: typeof generateRealtyOfferActions.request;
  createCalcDraft: typeof createCalcDraftActions.request;
  updateCalcDraft: typeof updateCalcDraftActions.request;
  deleteStateRealtyCalcResults: typeof deleteStateRealtyCalcResultsAction;
  deleteStateRealtyGenResult: typeof deleteStateRealtyGenResultAction;
  deleteStateRealtyDraft: typeof deleteStateRealtyDraftAction;
  deleteStateRealtyInitialCalcGenData: typeof deleteStateRealtyInitialCalcGenDataAction;
  getCalcRecordData: typeof getCalcRecordDataActions.request;
  getCalcDraft: typeof getCalcDraftActions.request;
  loadContractToCalculator: typeof loadContractToCalculatorActions.request;
}

const RealtyCalcContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get(QueryKeys.RECORD_ID);
  const draftId = searchParams.get(QueryKeys.DRAFT_ID);
  const contractId = searchParams.get(QueryKeys.CONTRACT_ID);
  const [resetKey, setResetKey] = useState<number>(0);
  const [genStepActive, setGenStepActive] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<CalcResult<RealtyCalcResultData>>();
  const [calcDataSource, setCalcDataSource] = useState<CalcDataSource>("init");
  const [calcData, setCalcData] = useState<RealtyCalc>();
  const [genData, setGenData] = useState<RealtyGenForm>();
  const [clients, setClients] = useState<RealtyFormClients>({
    policyHolder: undefined,
    insured: undefined,
    representative: undefined,
    vinculation: undefined
  });

  const clearData = () => {
    props.actions.deleteStateRealtyInitialCalcGenData();
    props.actions.deleteStateRealtyCalcResults();
    props.actions.deleteStateRealtyGenResult();
    props.actions.deleteStateRealtyDraft();
  };

  useEffect(() => {
    if (recordId) {
      props.actions.getCalcRecordData({ id: recordId });
    }

    if (draftId) {
      props.actions.getCalcDraft({ id: draftId });
    }

    if (contractId) {
      props.actions.loadContractToCalculator({ id: contractId });
    }

    return () => {
      clearData();
    };
  }, [recordId, draftId, contractId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (calcData) {
      return;
    }

    if (props.initialCalcData || props.initialGenData) {
      messageUtils.infoNotification({
        message: t("calc.records.helpers.calcDataInitTitle"),
        description: t("calc.records.helpers.calcDataInit"),
        duration: 10,
        key: "realtyCalcDataInit"
      });
    }

    if (props.initialCalcData) {
      const clients = createRealtyFormClientsObjectFromCalcData(props.initialCalcData);
      setClients(clients);
      setCalcData(createRealtyCalcObjectFromCalcData(props.initialCalcData, clients));
      setCalcDataSource("calcData");
    } else if (props.initialGenData) {
      const clients = createRealtyFormClientsObjectFromGenData(props.initialGenData);
      setClients(clients);
      setCalcData(createRealtyCalcObjectFromGenData(props.initialGenData, clients));
      setGenData(createRealtyGenFormDataObject(props.initialGenData, clients));
      setCalcDataSource("genData");
    } else if (props.draft?.draftData) {
      messageUtils.infoNotification({
        message: t("calc.draft.helpers.draftLoaded"),
        description: t("calc.draft.helpers.draftLoadedInfo"),
        duration: 10,
        key: "realtyDraftLoaded"
      });

      const clients = createRealtyFormClientsObjectFromGenData(props.draft.draftData);
      setClients(clients);
      setCalcData(createRealtyCalcObjectFromGenData(props.draft.draftData, clients));

      if (props.draft.stage === OperationStage.GENERATE) {
        setGenData(createRealtyGenFormDataObject(props.draft.draftData, clients));
        setCalcDataSource("genDraft");
      } else {
        setCalcDataSource("calcDraft");
      }
    }
  }, [props.initialCalcData, props.initialGenData, props.draft?.draftData, calcData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCalculationFormSubmit = (calcData: RealtyCalc): void => {
    setCalcData(calcData);
    props.actions.calculateRealty(calcData);
  };

  const handleClientChange = (
    type: ClientFormType,
    client?: NaturalClient | SelfEmployedClient | LegalClient
  ): RealtyFormClients => {
    let updatedClients: RealtyFormClients;
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        updatedClients = { ...clients, policyHolder: client };
        break;
      case ClientFormType.INSURED:
        updatedClients = { ...clients, insured: client as NaturalClient };
        break;
      case ClientFormType.REPRESENTATIVE:
        updatedClients = { ...clients, representative: client as NaturalClient };
        break;
      case ClientFormType.VINCULATION:
        updatedClients = { ...clients, vinculation: client as LegalClient };
        break;
      default:
        updatedClients = { ...clients };
        break;
    }
    setClients(updatedClients);
    return updatedClients;
  };

  const handleResetCalculatorClick = (): void => {
    Modal.confirm({
      title: t("calc.helpers.resetCalculatorSubmit"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        setResetKey(resetKey + 1);
        setSelectedResult(undefined);
        setClients({ policyHolder: undefined, insured: undefined, representative: undefined, vinculation: undefined });
        setCalcData(undefined);
        setCalcDataSource("init");
        setGenData(undefined);

        clearData();
        navigate(CALC_ROUTE_PATHS.realty.to, { replace: true });
      }
    });
  };

  const handleCalcDataSourceReset = (): void => {
    setCalcDataSource("init");
  };

  const handleGenerateContractClick = (
    calcData: RealtyCalc,
    selectedResult: CalcResult<RealtyCalcResultData>
  ): void => {
    setGenStepActive(true);
    setCalcData(calcData);
    setSelectedResult(selectedResult);
  };

  const handleReturnToCalculationClick = (genData: RealtyGenForm): void => {
    setGenStepActive(false);
    setSelectedResult(undefined);
    setCalcDataSource("calcData");
    setGenData(genData);
    props.actions.deleteStateRealtyGenResult();
  };

  const handleGenerateOfferClick = (type: RealtyOfferType, calcData: RealtyCalc): void => {
    props.actions.generateRealtyOffer({
      type,
      calcRequest: calcData,
      calcResponse: { results: props.calcResults.flat() },
      clientName: clients.policyHolder?.aggregatedName ?? ""
    });
  };

  const handleSaveDraftClick = (draftData: RealtyGen, overwriteExisting: boolean): void => {
    const draft: CreateUpdateCalcDraft = {
      draftData,
      calcResponse: { results: props.calcResults.flat() },
      clientName: clients.policyHolder?.aggregatedName ?? "",
      calcType: CalcType.REALTY,
      stage: genStepActive ? OperationStage.GENERATE : OperationStage.CALCULATE
    };

    if (props.draft && overwriteExisting) {
      props.actions.updateCalcDraft({
        id: props.draft.id,
        object: { ...draft, optimisticLockVersion: props.draft.optimisticLockVersion }
      });
    } else {
      props.actions.createCalcDraft(draft);
    }
  };

  return (
    <DisplayWrapper itemLoaded={recordId || draftId || contractId ? !!calcData : true}>
      <ContentWrapper>
        {genStepActive && calcData && selectedResult ? (
          <RealtyGenWrapper
            initialData={genData}
            genResult={props.genResult}
            calcData={calcData}
            clients={clients}
            calcResults={props.calcResults}
            selectedResult={selectedResult}
            draftId={props.draft?.id}
            onGenerateFormSubmit={props.actions.generateRealty}
            onGenResultDelete={props.actions.deleteStateRealtyGenResult}
            onClientChange={handleClientChange}
            onGenerateOfferClick={type => handleGenerateOfferClick(type, calcData)}
            onSelectedResultChange={setSelectedResult}
            onSaveDraftClick={handleSaveDraftClick}
            onReturnToCalculationClick={handleReturnToCalculationClick}
          />
        ) : (
          <RealtyCalcWrapper
            key={resetKey}
            calcData={calcData}
            calcDataSource={calcDataSource}
            calcResults={props.calcResults}
            policyHolder={clients.policyHolder}
            onCalcResultsDelete={props.actions.deleteStateRealtyCalcResults}
            onCalculationFormSubmit={handleCalculationFormSubmit}
            onClientChange={handleClientChange}
            onGenerateContractClick={handleGenerateContractClick}
            onGenerateOfferClick={handleGenerateOfferClick}
            onSaveDraftClick={(data, overwriteExisting) => handleSaveDraftClick(data as RealtyGen, overwriteExisting)}
            onResetCalculatorClick={handleResetCalculatorClick}
            onCalcDataSourceReset={handleCalcDataSourceReset}
          />
        )}

        <CalcLoadingModal />
      </ContentWrapper>
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  initialCalcData: selectRealtyInitialCalcData(state),
  initialGenData: selectRealtyInitialGenData(state),
  draft: selectRealtyDraft(state),
  calcResults: selectRealtyCalcResults(state),
  genResult: selectRealtyGenResult(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      calculateRealty: calculateRealtyActions.request,
      generateRealty: generateRealtyActions.request,
      generateRealtyOffer: generateRealtyOfferActions.request,
      createCalcDraft: createCalcDraftActions.request,
      updateCalcDraft: updateCalcDraftActions.request,
      deleteStateRealtyCalcResults: deleteStateRealtyCalcResultsAction,
      deleteStateRealtyGenResult: deleteStateRealtyGenResultAction,
      deleteStateRealtyDraft: deleteStateRealtyDraftAction,
      deleteStateRealtyInitialCalcGenData: deleteStateRealtyInitialCalcGenDataAction,
      getCalcRecordData: getCalcRecordDataActions.request,
      getCalcDraft: getCalcDraftActions.request,
      loadContractToCalculator: loadContractToCalculatorActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(RealtyCalcContainer);
