import { WarningTwoTone } from "@ant-design/icons";
import { Badge } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { PageSizes } from "../../../../common/constants";
import { RootState } from "../../../../common/types";
import { cssVariables } from "../../../../common/utils/utils";
import { DashboardNoticePosition } from "../../../dashboard/enums";
import { DashboardNoticeFilterPageResult } from "../../../dashboard/types";
import { deleteStateCalcNoticesPageAction, filterCalcNoticesActions, selectCalcNoticesPage } from "../ducks";
import styles from "./CalcNoticesIconView.module.scss";
import CalcNoticesModal from "./CalcNoticesModal";

interface Props {
  position: DashboardNoticePosition;
}

const CalcNoticesIconView = ({ position }: Props) => {
  const noticesPage = useSelector<RootState, DashboardNoticeFilterPageResult>(selectCalcNoticesPage);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { onFilter, onDeleteState } = useMemo(
    () =>
      bindActionCreators(
        {
          onFilter: filterCalcNoticesActions.request,
          onDeleteState: deleteStateCalcNoticesPageAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    onFilter({ pageIndex: 0, pageSize: PageSizes.LARGE, onlyUnclosed: true, position });

    return () => {
      onDeleteState();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return noticesPage.pageData.length > 0 ? (
    <>
      <Badge count={noticesPage.pageData.length} className={styles.calcNoticeBadge}>
        <WarningTwoTone twoToneColor={cssVariables.colorYellow} onClick={() => setModalOpen(true)} />
      </Badge>

      <CalcNoticesModal open={modalOpen} onCancel={() => setModalOpen(false)} notices={noticesPage.pageData} />
    </>
  ) : null;
};

export default CalcNoticesIconView;
