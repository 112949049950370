import { Alert, Col, Row, Space, Switch } from "antd";
import React from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";

interface Props {
  approvals: boolean[];
  allApprovals: boolean;
  handleAllApprovals: VoidFunction;
  handleApprovalChange: (checked: boolean, index: number) => void;
}

const ClientApprovalsCalcSummaryView = ({
  approvals,
  allApprovals,
  handleAllApprovals,
  handleApprovalChange
}: Props) => {
  return (
    <>
      <div className="margin-bottom-medium">
        <b>{t("calc.helpers.clientApprovals.subtitle")}</b>
      </div>

      {[...Array(6)].map((_, index) => (
        <React.Fragment key={index}>
          <Row className="margin-bottom-medium">
            <Col span={21}>
              <b>{index + 1}.</b> {t("calc.helpers.clientApprovals.approval" + (index + 1))}
            </Col>
            <Col span={3}>
              <Space>
                <Switch
                  checked={approvals[index]}
                  checkedChildren={<AntIcon type="check" />}
                  unCheckedChildren={<AntIcon type="close" />}
                  onChange={checked => handleApprovalChange(checked, index)}
                />
                <span>{(approvals[index] ? t("common.yes") : t("common.no")).toLowerCase()}</span>
              </Space>
            </Col>
          </Row>
        </React.Fragment>
      ))}

      <Row className="margin-top-large">
        <Col span={21}>
          <b>{t("calc.helpers.clientApprovals.allApprovals")}</b>
        </Col>
        <Col span={3}>
          <Space>
            <Switch
              checked={allApprovals}
              checkedChildren={<AntIcon type="check" />}
              unCheckedChildren={<AntIcon type="close" />}
              onChange={handleAllApprovals}
            />
            <span>{(allApprovals ? t("common.yes") : t("common.no")).toLowerCase()}</span>
          </Space>
        </Col>
      </Row>

      {approvals.some(approval => !approval) && (
        <Alert
          className="margin-top-medium"
          type="warning"
          showIcon
          message={t("calc.helpers.clientApprovals.validationMessage")}
        />
      )}
    </>
  );
};

export default ClientApprovalsCalcSummaryView;
