import React from "react";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../../../app/i18n";
import CopyToClipboardView from "../../../../../../common/components/views/CopyToClipboardView";
import HtmlView from "../../../../../../common/components/views/HtmlView";
import { formatAgentName, formatIban, formatPhoneNumber } from "../../../../../../common/utils/formatUtils";
import { AGENT_ROUTE_PATHS } from "../../../../paths";
import { LegalAgent } from "../../../../types";

interface Props {
  agent: LegalAgent;
}

const LegalAgentDataView = ({ agent }: Props) => (
  <>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.attrs.companyName")}:</td>
      <td colSpan={3}>{agent.companyName}</td>
    </tr>
    <tr>
      <td>{t("agent.attrs.companyRegistrationNumber")}:</td>
      <td>
        <CopyToClipboardView content={agent.companyRegistrationNumber} />
      </td>
      <td>{t("agent.attrs.commercialRegisterDistrictOffice")}:</td>
      <td>{agent.commercialRegisterDistrictOffice}</td>
    </tr>
    <tr>
      <td>{t("agent.attrs.taxId")}:</td>
      <td>
        <CopyToClipboardView content={agent.taxId} />
      </td>
      <td>{t("agent.attrs.commercialRegisterSection")}:</td>
      <td>{agent.commercialRegisterSection}</td>
    </tr>
    <tr>
      <td>{t("agent.attrs.vatId")}:</td>
      <td>
        <CopyToClipboardView content={agent.vatId} />
      </td>
      <td>{t("agent.attrs.commercialRegisterInsertionNumber")}:</td>
      <td>{agent.commercialRegisterInsertionNumber}</td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.attrs.nbsRegistrationNumber")}:</td>
      <td colSpan={3}>
        <CopyToClipboardView content={agent.nbsRegistrationNumber} />
      </td>
    </tr>
    <tr>
      <td>{t("common.financialSectors")}:</td>
      <td colSpan={3}>{agent.sectors.map(sector => t("agent.enums.financialSector." + sector)).join(", ")}</td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.attrs.email")}:</td>
      <td>
        <CopyToClipboardView content={agent.email} wrapper={<a href={`mailto:${agent.email}`}>{agent.email}</a>} />
      </td>
      <td>{t("agent.attrs.phone")}:</td>
      <td>
        <CopyToClipboardView
          content={agent.phone}
          wrapper={<a href={`tel:${agent.phone}`}>{formatPhoneNumber(agent.phone)}</a>}
        />
      </td>
    </tr>
    <tr>
      <td>{t("agent.attrs.web")}:</td>
      <td>{agent.web}</td>
      <td>{t("agent.attrs.bankAccountNumber")}:</td>
      <td>
        <span className="nowrap">
          <CopyToClipboardView content={agent.bankAccountNumber ? formatIban(agent.bankAccountNumber) : undefined} />
        </span>
      </td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.attrs.representativeIds")}:</td>
      <td colSpan={3}>
        {agent.representatives.map((agent, index) => (
          <React.Fragment key={agent.id}>
            {index > 0 && ", "}
            <Link
              className="no-link-color"
              to={generatePath(AGENT_ROUTE_PATHS.detail.to, { id: agent.id })}
              target="_blank"
            >
              {formatAgentName(agent)}
            </Link>
          </React.Fragment>
        ))}
      </td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td colSpan={4}>{t("agent.attrs.note")}:</td>
    </tr>
    {agent.note && (
      <tr>
        <td colSpan={4}>
          <HtmlView value={agent.note} />
        </td>
      </tr>
    )}
  </>
);

export default LegalAgentDataView;
