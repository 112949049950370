import imagePlaceholderLogo from "../../assets/images/image-placeholder.svg";
import bank365Logo from "../../assets/images/institutions/365bank.png";
import agraLogo from "../../assets/images/institutions/agra.png";
import allianzLogo from "../../assets/images/institutions/allianz.png";
import amundiLogo from "../../assets/images/institutions/amundi.png";
import axaLogo from "../../assets/images/institutions/axa.png";
import capitalMarketsLogo from "../../assets/images/institutions/capital-markets.png";
import cofidisLogo from "../../assets/images/institutions/cofidis.png";
import colonnadeLogo from "../../assets/images/institutions/colonnade.png";
import csobLeasingLogo from "../../assets/images/institutions/csob-leasing.png";
import csobLogo from "../../assets/images/institutions/csob.png";
import defendLogo from "../../assets/images/institutions/defend.png";
import ecpLogo from "../../assets/images/institutions/ecp.png";
import eicLogo from "../../assets/images/institutions/eic.png";
import generaliLogo from "../../assets/images/institutions/generali.png";
import genertelLogo from "../../assets/images/institutions/genertel.png";
import groupamaLogo from "../../assets/images/institutions/groupama.png";
import komunalnaLogo from "../../assets/images/institutions/komunalna.png";
import kooperativaDssLogo from "../../assets/images/institutions/kooperativa-dss.png";
import kooperativaLogo from "../../assets/images/institutions/kooperativa.png";
import mBankLogo from "../../assets/images/institutions/mbank.png";
import metlifeLogo from "../../assets/images/institutions/metlife.png";
import nnLogo from "../../assets/images/institutions/nn.png";
import novisLogo from "../../assets/images/institutions/novis.png";
import oberbankLogo from "../../assets/images/institutions/oberbank.png";
import pillowLogo from "../../assets/images/institutions/pillow.png";
import premiumLogo from "../../assets/images/institutions/premium.png";
import primaLogo from "../../assets/images/institutions/prima.png";
import privatbankaLogo from "../../assets/images/institutions/privatbanka.png";
import pssLogo from "../../assets/images/institutions/pss.png";
import slspLogo from "../../assets/images/institutions/slsp.png";
import stabilitaLogo from "../../assets/images/institutions/stabilita.png";
import tatraLeasingLogo from "../../assets/images/institutions/tatra-leasing.png";
import tatraLogo from "../../assets/images/institutions/tatra.png";
import uniCreditLeasingLogo from "../../assets/images/institutions/unicredit-leasing.png";
import uniCreditLogo from "../../assets/images/institutions/unicredit.png";
import unionLogo from "../../assets/images/institutions/union.png";
import uniqaLogo from "../../assets/images/institutions/uniqa.png";
import vubGeneraliDssLogo from "../../assets/images/institutions/vub-generali-dss.png";
import vubLogo from "../../assets/images/institutions/vub.png";
import wustenrotLogo from "../../assets/images/institutions/wustenrot.png";
import youPlusLogo from "../../assets/images/institutions/youplus.png";
import { InstitutionEnum } from "./enums";
import { InstitutionBase } from "./types";

export const resolveInstitutionLogo = (institution: InstitutionBase): string => {
  if (institution) {
    switch (institution.institutionEnum) {
      case InstitutionEnum.AGRA:
        return agraLogo;
      case InstitutionEnum.ALLIANZ:
        return allianzLogo;
      case InstitutionEnum.AXA_NON_LIFE:
      case InstitutionEnum.AXA_LIFE:
        return uniqaLogo;
      case InstitutionEnum.AXA_ASSISTANCE:
        return axaLogo;
      case InstitutionEnum.COLONNADE:
        return colonnadeLogo;
      case InstitutionEnum.CSOB:
      case InstitutionEnum.CSOB_BANK:
        return csobLogo;
      case InstitutionEnum.CSOB_LEASING:
        return csobLeasingLogo;
      case InstitutionEnum.ECP:
        return ecpLogo;
      case InstitutionEnum.DEFEND:
        return defendLogo;
      case InstitutionEnum.GENERALI:
        return generaliLogo;
      case InstitutionEnum.GENERTEL:
        return genertelLogo;
      case InstitutionEnum.GROUPAMA:
        return groupamaLogo;
      case InstitutionEnum.KOMUNALNA:
        return komunalnaLogo;
      case InstitutionEnum.KOOPERATIVA:
        return kooperativaLogo;
      case InstitutionEnum.MET_LIFE:
      case InstitutionEnum.MET_LIFE_INSURANCE:
        return metlifeLogo;
      case InstitutionEnum.NN:
      case InstitutionEnum.NN_DSS:
      case InstitutionEnum.NN_DDS:
        return nnLogo;
      case InstitutionEnum.NOVIS:
        return novisLogo;
      case InstitutionEnum.PREMIUM:
        return premiumLogo;
      case InstitutionEnum.UNION:
        return unionLogo;
      case InstitutionEnum.UNIQA:
      case InstitutionEnum.UNIQA_DSS:
      case InstitutionEnum.UNIQA_DDS:
        return uniqaLogo;
      case InstitutionEnum.WUSTENROT:
        return wustenrotLogo;
      case InstitutionEnum.YOUPLUS:
        return youPlusLogo;
      case InstitutionEnum.BANK_365:
        return bank365Logo;
      case InstitutionEnum.COFIDIS:
        return cofidisLogo;
      case InstitutionEnum.MBANK:
        return mBankLogo;
      case InstitutionEnum.OBERBANK:
        return oberbankLogo;
      case InstitutionEnum.PILLOW:
        return pillowLogo;
      case InstitutionEnum.PRIMA:
        return primaLogo;
      case InstitutionEnum.PRIVATBANKA:
      case InstitutionEnum.PRIVATBANKA_OCP:
        return privatbankaLogo;
      case InstitutionEnum.PSS:
        return pssLogo;
      case InstitutionEnum.SLSP:
        return slspLogo;
      case InstitutionEnum.TATRA:
        return tatraLogo;
      case InstitutionEnum.TATRA_LEASING:
        return tatraLeasingLogo;
      case InstitutionEnum.UNI_CREDIT:
        return uniCreditLogo;
      case InstitutionEnum.UNI_CREDIT_LEASING:
        return uniCreditLeasingLogo;
      case InstitutionEnum.VUB:
        return vubLogo;
      case InstitutionEnum.AMUNDI:
        return amundiLogo;
      case InstitutionEnum.CAPITAL_MARKETS:
        return capitalMarketsLogo;
      case InstitutionEnum.EIC:
        return eicLogo;
      case InstitutionEnum.KOOPERATIVA_DSS:
        return kooperativaDssLogo;
      case InstitutionEnum.STABILITA_DDS:
        return stabilitaLogo;
      case InstitutionEnum.VUB_GENERALI_DSS:
        return vubGeneraliDssLogo;
      default:
        return imagePlaceholderLogo;
    }
  }
  return imagePlaceholderLogo;
};
