import { Divider, Dropdown, Modal, Popconfirm } from "antd";
import { MenuItemType } from "antd/es/menu/interface";
import React, { useRef } from "react";
import type { TableActionsViewActionConfig } from "../../types";
import { useGetWidth } from "../../utils/hooksUtils";
import { isDefined } from "../../utils/utils";
import ActionTextIcon, { type ActionTextIconProps } from "../icons/ActionTextIcon";

interface Props {
  actions: TableActionsViewActionConfig[];
  dropdownMaxWidth?: number;
  dropdownAction?: ActionTextIconProps;
  dropdownExtractFirstAction?: boolean;
}

const TableActionsView = ({ actions, dropdownMaxWidth = 0, dropdownAction, dropdownExtractFirstAction }: Props) => {
  const actionsDivRef = useRef<HTMLDivElement>(null);
  const actionsDivWidth = useGetWidth(actionsDivRef);

  const inlineActions = actions.map((action, index) => {
    const { confirmDialog, renderOverride, onClick, ...actionTextIconProps } = action;

    return (
      <React.Fragment key={index}>
        {renderOverride ||
          (confirmDialog ? (
            <Popconfirm
              {...{ ...confirmDialog, content: undefined }}
              title={
                <>
                  {confirmDialog.title}
                  {confirmDialog.content && confirmDialog.content}
                </>
              }
              disabled={actionTextIconProps.disabled}
              key={index}
              onConfirm={() => onClick?.()}
            >
              <ActionTextIcon {...actionTextIconProps} />
            </Popconfirm>
          ) : (
            <ActionTextIcon {...actionTextIconProps} key={index} onClick={() => onClick?.()} />
          ))}
        {index + 1 < actions.length ? <Divider type="vertical" /> : undefined}
      </React.Fragment>
    );
  });

  let dropdownActions = undefined;

  if (isDefined(dropdownMaxWidth)) {
    const handleDropdownItemClick = (index: number) => {
      const action = actions[index];
      if (!action) {
        return;
      }

      const { confirmDialog, onClick } = action;
      if (confirmDialog) {
        Modal.confirm({
          ...confirmDialog,
          onOk: () => {
            onClick?.();
          }
        });
      } else {
        onClick?.();
      }
    };

    let firstExtractedAction: React.ReactNode = undefined;
    if (dropdownExtractFirstAction && actions[0]) {
      const { confirmDialog, renderOverride, onClick, ...actionTextIconProps } = actions[0]; // eslint-disable-line @typescript-eslint/no-unused-vars

      firstExtractedAction = confirmDialog ? (
        <>
          <Popconfirm
            {...{ ...confirmDialog, content: undefined }}
            title={
              <>
                {confirmDialog.title}
                {confirmDialog.content && confirmDialog.content}
              </>
            }
            disabled={actionTextIconProps.disabled}
            onConfirm={() => onClick?.()}
          >
            <ActionTextIcon {...actionTextIconProps} />
          </Popconfirm>
          <Divider type="vertical" />
        </>
      ) : (
        <>
          <ActionTextIcon {...actionTextIconProps} onClick={() => onClick?.()} />
          <Divider type="vertical" />
        </>
      );
    }

    if (dropdownAction) {
      const { onClick, ...dropdownActionTextIconProps } = dropdownAction; // eslint-disable-line @typescript-eslint/no-unused-vars

      dropdownActions = (
        <>
          {firstExtractedAction && firstExtractedAction}
          <Dropdown
            menu={{
              items: (dropdownExtractFirstAction ? actions.slice(1) : actions).map<MenuItemType>((action, index) => {
                const { confirmDialog, renderOverride, onClick, ...actionTextIconProps } = action; // eslint-disable-line @typescript-eslint/no-unused-vars
                return {
                  key: index,
                  label: renderOverride || <ActionTextIcon {...actionTextIconProps} />,
                  disabled: actionTextIconProps.disabled
                };
              }),
              onClick: ({ key }) =>
                handleDropdownItemClick(dropdownExtractFirstAction ? parseInt(key) + 1 : parseInt(key))
            }}
            trigger={["click"]}
            placement="bottomRight"
            disabled={dropdownActionTextIconProps.disabled}
          >
            <ActionTextIcon
              {...dropdownActionTextIconProps}
              color={dropdownExtractFirstAction ? actions[0]?.color : dropdownActionTextIconProps.color}
            />
          </Dropdown>
        </>
      );
    }
  }

  if (dropdownActions && actionsDivWidth < dropdownMaxWidth) {
    return <div ref={actionsDivRef}>{dropdownActions}</div>;
  } else {
    return <div ref={actionsDivRef}>{inlineActions}</div>;
  }
};

export default TableActionsView;
