import AntIcon from "./AntIcon";

interface Props {
  status: "success" | "error";
}

const TimelineDotIcon = ({ status }: Props) => (
  <AntIcon type={status === "success" ? "check" : "close-circle"} style={{ fontSize: 16 }} />
);

export default TimelineDotIcon;
