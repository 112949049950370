import { Card, Form } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { useEffect, useState } from "react";
import t from "../../../../../../app/i18n";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { FieldConstraintViolation } from "../../../../../../common/types";
import { resolveFormValidationError } from "../../../../../../common/utils/formUtils";
import { useScrollToTopOnLoad } from "../../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { removeStringWhiteSpaces } from "../../../../../../common/utils/utils";
import type { UUID } from "../../../../../../typings/global";
import { CalcValidationGroup, ClientFormType, ClientType } from "../../../../../client/enums";
import { Client, CreateUpdateContractClient, NaturalClient } from "../../../../../client/types";
import {
  clientToCreateUpdateContractClient,
  processClientsDataViolations,
  useClientsValidation,
  useClientValidation
} from "../../../../../client/utils";
import { InstitutionEnum } from "../../../../../institution/enums";
import { CalcType, ClientExperience } from "../../../../enums";
import CalcGenNavigation from "../../../components/CalcGenNavigation";
import CalcGenResultModal from "../../../components/CalcGenResultModal";
import CalcSummaryModal from "../../../components/summary/CalcSummaryModal";
import { CalcResult, GenResponse } from "../../../types";
import { filterApplicableSuccessResults, processGenResultError } from "../../../utils";
import { deleteStateTravelGenResultAction, generateTravelActions } from "../../ducks";
import { TravelInsuranceType } from "../../enums";
import { TravelCalc, TravelCalcResultData, TravelFormClients, TravelGen, TravelGenForm } from "../../types";
import TravelCalcResults from "../result/TravelCalcResults";
import TravelGenClientsDataSection from "./sections/TravelGenClientsDataSection";
import TravelGenOtherDataSection from "./sections/TravelGenOtherDataSection";

interface Props {
  initialData?: TravelGenForm;
  genResult?: GenResponse;
  calcData: TravelCalc;
  clients: TravelFormClients;
  calcResults: CalcResult<TravelCalcResultData>[][];
  selectedResult: CalcResult<TravelCalcResultData>;
  draftId?: UUID;
  onGenerateFormSubmit: typeof generateTravelActions.request;
  onGenResultDelete: typeof deleteStateTravelGenResultAction;
  onClientChange: (type: ClientFormType, client?: Client) => TravelFormClients;
  onGenerateOfferClick: () => void;
  onSaveDraftClick: (genData: TravelGen, overwriteExisting: boolean) => void;
  onSelectedResultChange: (selectedResult: CalcResult<TravelCalcResultData>) => void;
  onReturnToCalculationClick: (genFormData: TravelGenForm) => void;
}

const TravelGenWrapper = (props: Props) => {
  useScrollToTopOnLoad();

  const [form] = Form.useForm<TravelGenForm>();
  const clientValidation = useClientValidation();
  const clientsValidation = useClientsValidation();

  const [resultsOpen, setResultsOpen] = useState<boolean>(false);
  const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<ClientFormType, FieldConstraintViolation[]>>(
    new Map()
  );

  useEffect(() => {
    if (props.initialData) {
      let formData = cloneDeep(props.initialData);
      const { financialMediationData } = formData;
      const { policyHolder, representative } = props.clients;

      if (policyHolder?.type !== ClientType.LEGAL) {
        delete formData.clientsData.representativeIdentifier;
        delete formData.clientsData.representativeFunction;
        if (representative) {
          props.onClientChange(ClientFormType.REPRESENTATIVE);
        }
      }

      if (props.calcData.clientsData.insuredClients) {
        const insuredClients = props.calcData.clientsData.insuredClients.map((client, index) => ({
          ...formData.clientsData.insuredClients?.[index],
          birthDate: client.birthDate
        }));

        formData = {
          ...formData,
          clientsData: {
            ...formData.clientsData,
            insuredClients: insuredClients
          }
        };
      }

      formData.financialMediationData = {
        ...financialMediationData,
        clientExperience: (policyHolder?.type === ClientType.SELF_EMPLOYED || policyHolder?.type === ClientType.LEGAL
          ? ClientExperience.SUFFICIENT
          : financialMediationData.clientExperience === ClientExperience.SUFFICIENT
            ? undefined
            : financialMediationData.clientExperience) as ClientExperience,
        recommendedResult: undefined
      };

      form.setFieldsValue(formData);

      const clients = createContractClientsArray(props.clients);
      if (clients.length > 0) {
        clientsValidation.onValidate({
          prefix: "clientsData.clients",
          clients,
          validationGroup: CalcValidationGroup.GENERATE
        });
      }
    } else {
      const { insuredClients } = props.calcData.clientsData;
      if (insuredClients) {
        form.setFieldsValue({
          clientsData: {
            insuredClients: insuredClients.map(client => ({
              birthDate: client.birthDate ?? "",
              firstName: "",
              lastName: ""
            }))
          }
        });
      }
    }
    return () => {
      clientValidation.onErrorResponseDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const violations = clientValidation.errorResponse?.violations?.length
      ? clientValidation.errorResponse.violations
      : clientsValidation.errorResponse?.violations?.length
        ? clientsValidation.errorResponse.violations
        : undefined;

    if (violations) {
      setClientsViolationErrors(
        new Map([
          ...clientsViolationErrors,
          ...processClientsDataViolations(createClientsIndicesMap(props.clients), "clientsData.clients", violations)
        ])
      );
    }
  }, [clientValidation.errorResponse, clientsValidation.errorResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.genResult?.error) {
      const violations = processGenResultError(
        props.genResult.error,
        createClientsIndicesMap(props.clients),
        form,
        "calc.travel.attrs"
      );

      if (violations) {
        messageUtils.errorNotification({
          message: props.genResult.error.message,
          description: (
            <FieldViolationsView violations={violations.notificationViolations} rootPath="calc.travel.attrs" />
          ),
          duration: 10
        });
        setClientsViolationErrors(violations.clientsViolations);
      }
    }
  }, [props.genResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGenerateFormSubmit = (): void => {
    form
      .validateFields()
      .then(() => {
        if (checkHasClientsError()) {
          messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        } else {
          setSummaryOpen(true);
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        resolveFormValidationError(errorInfo);
      });
  };

  const handleGenerateFormApprovalsSubmit = (): void => {
    setSummaryOpen(false);
    form
      .validateFields()
      .then(() => {
        if (checkHasClientsError()) {
          messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        } else {
          props.onGenerateFormSubmit(processAndGetGenFormData());
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        resolveFormValidationError(errorInfo);
      });
  };

  const handleReturnToCalculationClick = (): void => {
    props.onReturnToCalculationClick(form.getFieldsValue());
  };

  const handleResultsSaveDraftClick = (overwriteExisting: boolean): void => {
    setResultsOpen(false);
    props.onSaveDraftClick(processAndGetGenFormData(), overwriteExisting);
  };

  const handleResultsGenerateOfferClick = (): void => {
    setResultsOpen(false);
    props.onGenerateOfferClick();
  };

  const handleResultsGenerateContractClick = (result: CalcResult<TravelCalcResultData>): void => {
    props.onSelectedResultChange(result);
    setResultsOpen(false);
  };

  const handlePolicyHolderIsAlsoInsuredChange = (event: CheckboxChangeEvent): void => {
    setFirstInsuredClientData(event.target.checked ? (props.clients.policyHolder as NaturalClient) : undefined);
  };

  const handleClientChange = (type: ClientFormType, client?: Client): void => {
    const clients = props.onClientChange(type, client);

    if (client) {
      clientValidation.onValidate({
        prefix: `clientsData.clients[${createClientsIndicesMap(clients).get(type)}]`,
        client: clientToCreateUpdateContractClient(client),
        validationGroup: CalcValidationGroup.GENERATE
      });

      if (type === ClientFormType.POLICY_HOLDER) {
        if (client.type === ClientType.SELF_EMPLOYED || client.type === ClientType.LEGAL) {
          form.setFieldsValue({ financialMediationData: { clientExperience: ClientExperience.SUFFICIENT } });
        } else if (form.getFieldValue(["financialMediationData", "clientExperience"]) === ClientExperience.SUFFICIENT) {
          form.setFieldsValue({ financialMediationData: { clientExperience: undefined } });
        }
      }
    }

    if (type === ClientFormType.POLICY_HOLDER && form.getFieldValue(["clientsData", "policyHolderIsAlsoInsured"])) {
      if (client?.type !== ClientType.NATURAL) {
        setFirstInsuredClientData(undefined);
        form.setFieldsValue({ clientsData: { policyHolderIsAlsoInsured: false } });
        form.validateFields(["clientsData", "policyHolderIsAlsoInsured"]);
      } else {
        setFirstInsuredClientData(client as NaturalClient);
      }
    }
  };

  const handleClientViolationErrorsChange = (type: ClientFormType, violations?: FieldConstraintViolation[]): void => {
    const updatedViolationsErrors = new Map<ClientFormType, FieldConstraintViolation[]>([...clientsViolationErrors]);
    if (violations?.length) {
      updatedViolationsErrors.set(type, violations);
    } else {
      updatedViolationsErrors.delete(type);
    }
    setClientsViolationErrors(updatedViolationsErrors);
  };

  const setFirstInsuredClientData = (client?: NaturalClient): void => {
    const formInsuredClients = form.getFieldValue(["clientsData", "insuredClients"]);
    const insuredClients = formInsuredClients ? [...formInsuredClients] : [];

    if (client) {
      insuredClients[0] = {
        firstName: client.firstName,
        lastName: client.lastName,
        academicDegree: client.academicDegree,
        academicDegreeAfter: client.academicDegreeAfter,
        personalIdentificationNumber: client.personalIdentificationNumber,
        birthDate:
          props.calcData.generalData.insuranceType === TravelInsuranceType.CANCELLATION
            ? client.birthDate
            : insuredClients[0].birthDate,
        discountIdentifier: undefined
      };
    } else {
      insuredClients[0] = {
        firstName: undefined,
        lastName: undefined,
        academicDegree: undefined,
        academicDegreeAfter: undefined,
        personalIdentificationNumber: undefined,
        birthDate:
          props.calcData.generalData.insuranceType === TravelInsuranceType.CANCELLATION
            ? undefined
            : insuredClients[0].birthDate,
        discountIdentifier: undefined
      };
    }

    form.setFieldsValue({ clientsData: { insuredClients } });
  };

  const processAndGetGenFormData = (): TravelGen => {
    const values = form.getFieldsValue();
    const { calcData, clients } = props;
    const axaAssistanceYearFamilyInsurance =
      props.selectedResult.insuranceInstitution.institutionEnum === InstitutionEnum.AXA_ASSISTANCE &&
      props.calcData.generalData.insuranceType === TravelInsuranceType.YEAR &&
      props.calcData.discountsData?.familyInsurance;

    const recommendedResultData = (values.financialMediationData.recommendedResult || []) as string[];
    const institutionResults = props.calcResults
      .filter(resultsRow => filterApplicableSuccessResults(resultsRow).length > 0)
      .map(resultsRow => filterApplicableSuccessResults<TravelCalcResultData>(resultsRow))
      .find(
        resultsRow =>
          resultsRow.length > 0 &&
          resultsRow[0]?.insuranceInstitution.institutionEnum ===
            InstitutionEnum[recommendedResultData[0] as keyof typeof InstitutionEnum]
      );
    const recommendedResult =
      recommendedResultData.length > 1
        ? institutionResults?.find(result => result.coverage === recommendedResultData[1])
        : institutionResults?.[0];

    return {
      calcResult: props.selectedResult.data,
      coverage: props.selectedResult.coverage,
      calcResponse: { results: props.calcResults.flat() },
      insuranceInstitutionId: props.selectedResult.insuranceInstitution.id,
      draftId: props.draftId,
      ...calcData,
      clientsData: {
        ...calcData.clientsData,
        clients: createContractClientsArray(clients),
        policyHolderEmail: values.clientsData.policyHolderEmail,
        policyHolderPhone: values.clientsData.policyHolderPhone,
        policyHolderMarketingConsent: values.clientsData.policyHolderMarketingConsent,
        policyHolderIsAlsoInsured: axaAssistanceYearFamilyInsurance
          ? false
          : values.clientsData.policyHolderIsAlsoInsured,
        representativeFunction: values.clientsData.representativeFunction,
        insuredClients: values.clientsData.insuredClients?.map((formClient, index) => ({
          ...(calcData.clientsData.insuredClients ? calcData.clientsData.insuredClients[index] : {}),
          ...formClient
        })),
        insuredClientForFamilyInsurance: axaAssistanceYearFamilyInsurance
          ? clients.policyHolder?.type === ClientType.NATURAL
            ? {
                birthDate: (clients.policyHolder as NaturalClient).birthDate,
                firstName: (clients.policyHolder as NaturalClient).firstName,
                lastName: (clients.policyHolder as NaturalClient).lastName
              }
            : values.clientsData.insuredClientForFamilyInsurance
          : undefined
      },
      coveragesData:
        calcData.generalData.insuranceType !== TravelInsuranceType.CANCELLATION
          ? merge(
              {},
              calcData.coveragesData,
              values.coveragesData
                ? {
                    ...values.coveragesData,
                    vehicleAssistanceData: values.coveragesData.vehicleAssistanceData
                      ? {
                          ...values.coveragesData.vehicleAssistanceData,
                          licensePlate:
                            removeStringWhiteSpaces(values.coveragesData.vehicleAssistanceData.licensePlate) ?? ""
                        }
                      : undefined
                  }
                : undefined
            )
          : undefined,
      financialMediationData: { ...values.financialMediationData, recommendedResult },
      type: CalcType.TRAVEL
    };
  };

  const checkHasClientsError = (): boolean => {
    const { policyHolder, representative } = props.clients;

    if (!policyHolder || clientsViolationErrors.has(ClientFormType.POLICY_HOLDER)) {
      return true;
    }

    // noinspection RedundantIfStatementJS
    if (
      policyHolder.type === ClientType.LEGAL &&
      (!representative || clientsViolationErrors.has(ClientFormType.REPRESENTATIVE))
    ) {
      return true;
    }

    return false;
  };

  const createClientsIndicesMap = (clients: TravelFormClients): Map<ClientFormType, number> => {
    const { policyHolder, representative } = clients;
    const indices = new Map<ClientFormType, number>();
    let index = 0;

    if (policyHolder) {
      indices.set(ClientFormType.POLICY_HOLDER, index++);

      if (policyHolder.type === ClientType.LEGAL && representative) {
        indices.set(ClientFormType.REPRESENTATIVE, index);
      }
    }

    return indices;
  };

  const createContractClientsArray = (clients: TravelFormClients): CreateUpdateContractClient[] => {
    const { policyHolder, representative } = clients;
    const createUpdateClients: CreateUpdateContractClient[] = [];

    if (policyHolder) {
      createUpdateClients.push(clientToCreateUpdateContractClient(policyHolder));

      if (policyHolder.type === ClientType.LEGAL && representative) {
        createUpdateClients.push(clientToCreateUpdateContractClient(representative));
      }
    }

    return createUpdateClients;
  };

  const resolvePageTitle = (): string => {
    const { calcData, selectedResult } = props;
    return `${t("calc.travel.titles.contractGeneration")} - 
    ${t("calc.travel.titles.insuranceType." + calcData.generalData.insuranceType)}, 
    ${selectedResult.insuranceInstitution.name}${
      selectedResult.coverage ? `, ${t("calc.travel.sections.coverage")} ${selectedResult.coverage}` : ""
    }`;
  };

  return (
    <>
      <Card className="card-box card-box--no-body" title={<h2>{resolvePageTitle()}</h2>} />

      <Form form={form} layout="vertical" name="travelGenForm">
        <TravelGenClientsDataSection
          form={form}
          calcData={props.calcData}
          clients={props.clients}
          clientsViolationErrors={clientsViolationErrors}
          selectedInstitutionEnum={props.selectedResult.insuranceInstitution.institutionEnum}
          onPolicyHolderIsAlsoInsuredChange={handlePolicyHolderIsAlsoInsuredChange}
          onClientChange={handleClientChange}
          onClientViolationErrorsChange={handleClientViolationErrorsChange}
        />

        <TravelGenOtherDataSection
          form={form}
          policyHolderType={props.clients.policyHolder?.type}
          calcData={props.calcData}
          calcResults={props.calcResults}
          selectedInstitutionEnum={props.selectedResult.insuranceInstitution.institutionEnum}
        />
      </Form>

      <TravelCalcResults
        open={resultsOpen}
        insuranceType={props.calcData.generalData.insuranceType}
        calcResults={props.calcResults}
        selectedResult={props.selectedResult}
        onClose={() => setResultsOpen(false)}
        onSaveDraftClick={handleResultsSaveDraftClick}
        onGenerateOfferClick={handleResultsGenerateOfferClick}
        onGenerateContractClick={handleResultsGenerateContractClick}
      />

      <CalcGenNavigation
        requiredPermission={Permission.TRAVEL_GENERATE_CONTRACT}
        onFormSubmit={handleGenerateFormSubmit}
        onShowResultsClick={() => setResultsOpen(true)}
        onReturnToCalculationClick={handleReturnToCalculationClick}
      />

      <CalcSummaryModal
        form={form}
        open={summaryOpen}
        data={{
          calcData: props.calcData,
          selectedInsurance: props.selectedResult,
          clients: props.clients
        }}
        onOkClick={handleGenerateFormApprovalsSubmit}
        onCancelClick={() => setSummaryOpen(false)}
      />

      <CalcGenResultModal result={props.genResult} onCancel={props.onGenResultDelete} />
    </>
  );
};

export default TravelGenWrapper;
