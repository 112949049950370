import { Tag, Tooltip } from "antd";
import t from "../../../../app/i18n";
import { BailAccountMovement } from "../types";

interface Props {
  movement: BailAccountMovement;
}

const ManualBailAccountMovementTag = ({ movement }: Props) => {
  if (movement?.manual) {
    const tag = (
      <Tag color="geekblue" style={{ marginRight: 0 }}>
        {t("commissions.bailAccount.helpers.manualMovement")}
      </Tag>
    );
    return movement.note ? (
      <Tooltip title={movement.note}>
        <span>{tag}</span>
      </Tooltip>
    ) : (
      tag
    );
  }
  return null;
};

export default ManualBailAccountMovementTag;
