import { Collapse, Divider, Switch, Tooltip } from "antd";
import { useState } from "react";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { formatCron } from "../../../../common/utils/formatUtils";
import {
  abortJobActions,
  cancelJobActions,
  filterJobRecordsActions,
  getAllJobsActions,
  scheduleJobActions,
  updateJobActions
} from "../../ducks";
import { JobSettings } from "../../types";
import ScheduleJobForm from "../forms/ScheduleJobForm";
import UpdateJobForm from "../forms/UpdateJobForm";
import JobRecordsTableView from "./JobRecordsTableView";

interface Props {
  jobs: JobSettings[];
  onAllJobsGet: typeof getAllJobsActions.request;
  onUpdate: typeof updateJobActions.request;
  onSchedule: typeof scheduleJobActions.request;
  onFilter: typeof filterJobRecordsActions.request;
  onCancel: typeof cancelJobActions.request;
  onAbort: typeof abortJobActions.request;
}

const JobSettingsView = (props: Props) => {
  const [jobToSchedule, setJobToSchedule] = useState<JobSettings>();
  const [jobToUpdate, setJobToUpdate] = useState<JobSettings>();

  const handleJobEnabledChange = (enabled: boolean, job: JobSettings): void => {
    props.onUpdate({
      id: job.id,
      object: {
        optimisticLockVersion: job.optimisticLockVersion,
        name: job.name,
        cron: job.cron,
        preferences: job.preferences,
        enabled
      }
    });
  };

  return (
    <>
      <Collapse
        accordion
        items={props.jobs.map(job => ({
          key: job.id,
          label: (
            <>
              <div style={{ minWidth: 0, maxWidth: 510, float: "left" }}>
                <b>
                  <Ellipsis>{job.name}</Ellipsis>
                </b>
              </div>
              &nbsp;
              <span className="nowrap">{job.cron && <Tooltip title={job.cronDesc}>({job.cron})</Tooltip>}</span>
            </>
          ),
          extra: (
            <div onClick={event => event.stopPropagation()} style={{ width: 250, marginLeft: 10 }}>
              <Switch
                className="margin-right-medium"
                defaultChecked={job.enabled}
                checkedChildren={<AntIcon type="check" />}
                unCheckedChildren={<AntIcon type="close" />}
                onChange={checked => handleJobEnabledChange(checked, job)}
              />

              <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => setJobToUpdate(job)} />

              <Divider type="vertical" />

              <ActionTextIcon
                icon="field-time"
                color="orange"
                text={t("job.actions.schedule")}
                onClick={() => setJobToSchedule(job)}
              />
            </div>
          ),
          children: (
            <>
              <ItemCreatedUpdatedInfoView item={job} />

              {job.cron && (
                <div className="margin-top-small">
                  <b>{t("job.sections.nextJobRuns")}:</b> {formatCron(job.cron, 3)}
                </div>
              )}

              <JobRecordsTableView
                job={job}
                onFilter={props.onFilter}
                onCancel={props.onCancel}
                onAbort={props.onAbort}
              />
            </>
          )
        }))}
      />

      <UpdateJobForm
        job={jobToUpdate}
        open={!!jobToUpdate}
        onUpdate={props.onUpdate}
        onFormCancel={() => setJobToUpdate(undefined)}
      />

      <ScheduleJobForm
        job={jobToSchedule}
        open={!!jobToSchedule}
        onSchedule={props.onSchedule}
        onFormCancel={() => setJobToSchedule(undefined)}
      />
    </>
  );
};

export default JobSettingsView;
