export enum JobType {
  UPDATE_OUTDATED_STATUSES_AND_DATES_ON_CONTRACTS = "UPDATE_OUTDATED_STATUSES_AND_DATES_ON_CONTRACTS",
  DELETE_CALC_RECORDS_OLDER_THAN_1_MONTH = "DELETE_CALC_RECORDS_OLDER_THAN_1_MONTH",
  DELETE_CALC_DRAFTS_OLDER_THAN_6_MONTHS = "DELETE_CALC_DRAFTS_OLDER_THAN_6_MONTHS",
  DELETE_JOB_RECORDS_OLDER_THAN_6_MONTHS = "DELETE_JOB_RECORDS_OLDER_THAN_6_MONTHS",
  GENERATE_ANNIVERSARY_DATE_NOTIFICATIONS = "GENERATE_ANNIVERSARY_DATE_NOTIFICATIONS",
  GENERATE_UNPAID_CONTRACTS_NOTIFICATIONS = "GENERATE_UNPAID_CONTRACTS_NOTIFICATIONS",
  GENERATE_UPCOMING_MONTH_PAYMENTS_NOTIFICATIONS = "GENERATE_UPCOMING_MONTH_PAYMENTS_NOTIFICATIONS",
  SEND_SCHEDULED_NOTIFICATIONS = "SEND_SCHEDULED_NOTIFICATIONS",
  PROCESS_MONLY_WEBHOOK_EVENT = "PROCESS_MONLY_WEBHOOK_EVENT",
  REFRESH_MONLY_TOKENS_NEAR_EXPIRATION = "REFRESH_MONLY_TOKENS_NEAR_EXPIRATION",
  SYNC_PARTNER_SSO_USERS = "SYNC_PARTNER_SSO_USERS"
}

export enum JobState {
  MANUALLY_SCHEDULED = "MANUALLY_SCHEDULED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  ABORTED = "ABORTED",
  TERMINATED_BY_ERROR = "TERMINATED_BY_ERROR"
}
