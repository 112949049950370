export enum QueryKeys {
  RECORD_ID = "recordId",
  DRAFT_ID = "draftId",
  CONTRACT_ID = "contractId"
}

class URLQueryImpl {
  public copy(searchParams: URLSearchParams): URLSearchParams {
    return new URLSearchParams(searchParams.toString());
  }

  public toString(searchParams: URLSearchParams): string {
    const copy = this.copy(searchParams);

    copy.forEach((value, key) => {
      if (["null", "undefined"].includes(value) || key.toString().startsWith("/") || /[{}$]/.test(value)) {
        searchParams.delete(key);
      }
    });

    const prefix = [...searchParams.keys()].length ? "?" : "";

    return `${prefix}${searchParams.toString()}`;
  }

  public toObject(searchParams: URLSearchParams): Record<string, string> {
    return Object.fromEntries(searchParams.entries());
  }

  public merge(target: URLSearchParams, source: URLSearchParams): URLSearchParams {
    const copy = this.copy(target);

    source.forEach((value, key) => {
      copy.append(key, value);
    });

    return copy;
  }
}

export const URLQuery = new URLQueryImpl();
