import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { EntityIdObject, EntityObject } from "../../common/types";
import { CreateUpdateDocumentNode, DocumentNode, DocumentNodeTreeList } from "./types";

const CREATE_DOCUMENT_NODE = ApiRequestAdapter.create("/documents", "POST");
const UPLOAD_DOCUMENT_NODES = ApiRequestAdapter.create("/documents/upload", "POST");
const UPDATE_DOCUMENT_NODE = ApiRequestAdapter.create("/documents/{0}", "PUT");
const DELETE_DOCUMENT_NODE = ApiRequestAdapter.create("/documents/{0}", "DELETE");
const GET_DOCUMENT_NODE_TREE = ApiRequestAdapter.create("/documents/tree", "GET");
const DOWNLOAD_DOCUMENT_NODE = ApiRequestAdapter.create("/documents/{0}", "GET");

export const requests = {
  CREATE_DOCUMENT_NODE,
  UPLOAD_DOCUMENT_NODES,
  UPDATE_DOCUMENT_NODE,
  DELETE_DOCUMENT_NODE,
  GET_DOCUMENT_NODE_TREE,
  DOWNLOAD_DOCUMENT_NODE
};

const api = {
  createDocumentNode: (request: CreateUpdateDocumentNode): AxiosPromise<DocumentNode> => {
    return apiService.post(CREATE_DOCUMENT_NODE.url, request);
  },
  uploadDocumentNodes: (request: FormData): AxiosPromise<DocumentNode[]> => {
    return apiService.post(UPLOAD_DOCUMENT_NODES.url, request, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  updateDocumentNode: (request: EntityObject<CreateUpdateDocumentNode>): AxiosPromise<DocumentNode> => {
    return apiService.put(UPDATE_DOCUMENT_NODE.getParametrizedUrl(request.id), request.object);
  },
  deleteDocumentNode: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_DOCUMENT_NODE.getParametrizedUrl(request.id));
  },
  getDocumentNodeTree: (): AxiosPromise<DocumentNodeTreeList> => {
    return apiService.get(GET_DOCUMENT_NODE_TREE.url);
  },
  downloadDocumentNode: (request: EntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_DOCUMENT_NODE.getParametrizedUrl(request.id), { responseType: "blob" });
  }
};

export default api;
