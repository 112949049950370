import t from "../../app/i18n";

export enum ContractType {
  INSURANCE_CONTRACT = "INSURANCE_CONTRACT",
  LOAN_CONTRACT = "LOAN_CONTRACT",
  INVESTMENT_CONTRACT = "INVESTMENT_CONTRACT",
  DEPOSIT_CONTRACT = "DEPOSIT_CONTRACT",
  SECOND_PILLAR_CONTRACT = "SECOND_PILLAR_CONTRACT",
  THIRD_PILLAR_CONTRACT = "THIRD_PILLAR_CONTRACT",
  GENERIC_CONTRACT = "GENERIC_CONTRACT"
}

export enum InsuranceType {
  GENERIC = "GENERIC",
  MTPL = "MTPL",
  CRASH = "CRASH",
  GAP = "GAP",
  PAS = "PAS",
  REALTY = "REALTY",
  TRAVEL = "TRAVEL",
  LIFE = "LIFE"
}

export enum InsuranceCoverageType {
  BASIC_ASSISTANCE = "BASIC_ASSISTANCE",
  EXTENDED_ASSISTANCE = "EXTENDED_ASSISTANCE",
  POST_ACCIDENT_ASSISTANCE = "POST_ACCIDENT_ASSISTANCE",
  GLASS_WINDSHIELD = "GLASS_WINDSHIELD",
  GLASS = "GLASS",
  ANIMAL = "ANIMAL",
  ELEMENT = "ELEMENT",
  ELEMENT_RODENT = "ELEMENT_RODENT",
  THEFT = "THEFT",
  THEFT_AND_VANDALISM = "THEFT_AND_VANDALISM",
  INJURY_DRIVER = "INJURY_DRIVER",
  INJURY_CREW = "INJURY_CREW",
  GAP = "GAP",
  REPLACEMENT_VEHICLE = "REPLACEMENT_VEHICLE",
  GENERALI_ABROAD_VEHICLE_REPAIR = "GENERALI_ABROAD_VEHICLE_REPAIR",
  KOOP_EXTENDED_WARRANTY = "KOOP_EXTENDED_WARRANTY",
  UNIQA_SMALL_DAMAGE = "UNIQA_SMALL_DAMAGE",
  UNIQA_TOTAL_DAMAGE = "UNIQA_TOTAL_DAMAGE",
  UNIQA_LAWYER_ASSISTANCE = "UNIQA_LAWYER_ASSISTANCE",
  UNIQA_SIDECAR_MTPL = "UNIQA_SIDECAR_MTPL",
  UNIQA_CHILDREN_INJURY = "UNIQA_CHILDREN_INJURY",
  UNIQA_UNLIMITED_TOW = "UNIQA_UNLIMITED_TOW",
  METEO = "METEO",
  BAD_ROAD_CONDITIONS = "BAD_ROAD_CONDITIONS",
  PNEU = "PNEU",
  LUGGAGE = "LUGGAGE",

  TRAVEL_MEDICAL_EXPENSES = "TRAVEL_MEDICAL_EXPENSES",
  TRAVEL_INJURY = "TRAVEL_INJURY",
  TRAVEL_LIABILITY = "TRAVEL_LIABILITY",
  TRAVEL_RESCUE_SERVICE = "TRAVEL_RESCUE_SERVICE",
  TRAVEL_LUGGAGE = "TRAVEL_LUGGAGE",
  TRAVEL_COVID = "TRAVEL_COVID",
  TRAVEL_CHRONIC_DISEASES = "TRAVEL_CHRONIC_DISEASES",
  TRAVEL_CANCELLATION = "TRAVEL_CANCELLATION",
  TRAVEL_DRINK_ALLOWED = "TRAVEL_DRINK_ALLOWED",
  TRAVEL_VEHICLE_ASSISTANCE = "TRAVEL_VEHICLE_ASSISTANCE",
  TRAVEL_VEHICLE_RENTAL = "TRAVEL_VEHICLE_RENTAL",
  TRAVEL_ELECTRONIC_DEVICES = "TRAVEL_ELECTRONIC_DEVICES",
  TRAVEL_DOCUMENTS = "TRAVEL_DOCUMENTS",
  TRAVEL_PET = "TRAVEL_PET",
  TRAVEL_ABANDONED_HOUSEHOLD = "TRAVEL_ABANDONED_HOUSEHOLD",
  TRAVEL_AIRPLANE = "TRAVEL_AIRPLANE"
}

export enum ContractStatus {
  UNSTARTED = "UNSTARTED",
  ACTIVE = "ACTIVE",
  FINISHED = "FINISHED",
  CANCELED = "CANCELED",
  TRANSFERRED_TO_BROKER = "TRANSFERRED_TO_BROKER"
}

export enum ContractVerificationStatus {
  TYPED = "TYPED",
  SENT = "SENT",
  INTERNAL_INTERVENTION = "INTERNAL_INTERVENTION",
  EXTERNAL_INTERVENTION = "EXTERNAL_INTERVENTION",
  NOT_ACCEPTED = "NOT_ACCEPTED",
  VERIFIED = "VERIFIED",
  PAID = "PAID"
}

export enum ContractOrigin {
  TYPED = "TYPED",
  CALC = "CALC",
  IMPORT = "IMPORT",
  MANUAL_IMPORT = "MANUAL_IMPORT"
}

export enum PeriodType {
  DEFINITE = "DEFINITE",
  INDEFINITE = "INDEFINITE"
}

export enum InsurancePeriod {
  CALENDAR_YEAR = "CALENDAR_YEAR",
  TECHNICAL_YEAR = "TECHNICAL_YEAR",
  FIXED = "FIXED"
}

export enum PaymentFrequency {
  ANNUALLY = "ANNUALLY",
  SEMI_ANNUALLY = "SEMI_ANNUALLY",
  QUARTERLY = "QUARTERLY",
  MONTHLY = "MONTHLY",
  ONCE = "ONCE"
}

export enum LoanRateType {
  FIXED_ONE_YEAR = "FIXED_ONE_YEAR",
  FIXED_TWO_YEARS = "FIXED_TWO_YEARS",
  FIXED_THREE_YEARS = "FIXED_THREE_YEARS",
  FIXED_FOUR_YEARS = "FIXED_FOUR_YEARS",
  FIXED_FIVE_YEARS = "FIXED_FIVE_YEARS",
  FIXED_SIX_YEARS = "FIXED_SIX_YEARS",
  FIXED_SEVEN_YEARS = "FIXED_SEVEN_YEARS",
  FIXED_EIGHT_YEARS = "FIXED_EIGHT_YEARS",
  FIXED_NINE_YEARS = "FIXED_NINE_YEARS",
  FIXED_TEN_YEARS = "FIXED_TEN_YEARS",
  FIXED_ELEVEN_YEARS = "FIXED_ELEVEN_YEARS",
  FIXED_TWELVE_YEARS = "FIXED_TWELVE_YEARS",
  FIXED_THIRTEEN_YEARS = "FIXED_THIRTEEN_YEARS",
  FIXED_FOURTEEN_YEARS = "FIXED_FOURTEEN_YEARS",
  FIXED_FIFTEEN_YEARS = "FIXED_FIFTEEN_YEARS",
  FIXED_SIXTEEN_YEARS = "FIXED_SIXTEEN_YEARS",
  FIXED_SEVENTEEN_YEARS = "FIXED_SEVENTEEN_YEARS",
  FIXED_EIGHTEEN_YEARS = "FIXED_EIGHTEEN_YEARS",
  FIXED_NINETEEN_YEARS = "FIXED_NINETEEN_YEARS",
  FIXED_TWENTY_YEARS = "FIXED_TWENTY_YEARS",
  VARIABLE = "VARIABLE"
}

export enum InvestmentType {
  FUND = "FUND",
  ETF = "ETF",
  OTHER = "OTHER"
}

export enum InvestmentRiskLevel {
  CONSERVATIVE = "CONSERVATIVE",
  CAREFUL = "CAREFUL",
  DELIBERATE = "DELIBERATE",
  DYNAMIC = "DYNAMIC",
  AGGRESIVE = "AGGRESIVE",
  SPECULATIVE = "SPECULATIVE"
}

export enum SecondPillarCreationType {
  TRANSFER = "TRANSFER",
  CHANGE = "CHANGE",
  NEW = "NEW"
}

export enum SecondPillarFundType {
  STOCK = "STOCK",
  BOND = "BOND",
  INDEX = "INDEX",
  MIXED = "MIXED"
}

export enum VehicleCategory {
  PERSONAL_M1 = "PERSONAL_M1",
  UTILITY_N1 = "UTILITY_N1",
  MOTORCYCLE_L = "MOTORCYCLE_L",
  TRICYCLE_L = "TRICYCLE_L",
  QUAD_BIKE_L = "QUAD_BIKE_L",
  MOTOR_SLEDGE_LS = "MOTOR_SLEDGE_LS",
  TRACTOR_T = "TRACTOR_T",
  TRACTOR_C = "TRACTOR_C",
  MACHINE_P = "MACHINE_P",
  FORKLIFT_P = "FORKLIFT_P",
  LORRY_N = "LORRY_N",
  SEMI_N3 = "SEMI_N3",
  BUS_CITY_M = "BUS_CITY_M",
  BUS_OTHER_M = "BUS_OTHER_M",
  RV = "RV",
  SIDECAR_PERSONAL_O = "SIDECAR_PERSONAL_O",
  SIDECAR_CARGO_O = "SIDECAR_CARGO_O",
  SIDECAR_CARAVAN_O = "SIDECAR_CARAVAN_O",
  SIDECAR_TRACTOR_R = "SIDECAR_TRACTOR_R",
  TRAILER_O = "TRAILER_O"
}

export enum FuelType {
  PETROL = "PETROL",
  DIESEL = "DIESEL",
  BEV = "BEV",
  PETROL_HEV = "PETROL_HEV",
  DIESEL_HEV = "DIESEL_HEV",
  PETROL_PHEV = "PETROL_PHEV",
  DIESEL_PHEV = "DIESEL_PHEV",
  LPG = "LPG",
  PETROL_LPG = "PETROL_LPG",
  DIESEL_LPG = "DIESEL_LPG",
  CNG = "CNG",
  PETROL_CNG = "PETROL_CNG",
  DIESEL_CNG = "DIESEL_CNG",
  LNG = "LNG",
  PETROL_LNG = "PETROL_LNG",
  DIESEL_LNG = "DIESEL_LNG",
  HYDROGEN = "HYDROGEN",
  ETHANOL = "ETHANOL"
}

export enum Transmission {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
  VARIABLE = "VARIABLE",
  ROBOTIZED = "ROBOTIZED"
}

export enum Bodywork {
  SEDAN = "SEDAN",
  HATCHBACK = "HATCHBACK",
  COMBI = "COMBI",
  PICKUP = "PICKUP",
  COUPE = "COUPE",
  MPV = "MPV",
  LIMOUSINE = "LIMOUSINE",
  CABRIOLET = "CABRIOLET",
  ROADSTER = "ROADSTER",
  VAN = "VAN",
  TARGA = "TARGA",
  FURGON = "FURGON",
  FLATBED_TRUCK = "FLATBED_TRUCK",
  CHASSIS_WITH_CABIN = "CHASSIS_WITH_CABIN",
  BUS = "BUS"
}

export enum ContractView {
  INTERNAL = "INTERNAL",
  INTERNAL_GENERIC_REPORT = "INTERNAL_GENERIC_REPORT",
  INTERNAL_PREDICTED_COMMISSIONS_REPORT = "INTERNAL_PREDICTED_COMMISSIONS_REPORT",
  INTERNAL_ANNIVERSARY_DATE_REPORT = "INTERNAL_ANNIVERSARY_DATE_REPORT",
  INTERNAL_FIXATION_ANNIVERSARY_REPORT = "INTERNAL_FIXATION_ANNIVERSARY_REPORT",
  INTERNAL_UNPAID_REPORT = "INTERNAL_UNPAID_REPORT",
  PUBLIC = "PUBLIC",
  PUBLIC_ACTIVE_REPORT = "PUBLIC_ACTIVE_REPORT",
  PUBLIC_INACTIVE_REPORT = "PUBLIC_INACTIVE_REPORT",
  PUBLIC_UNPAID_REPORT = "PUBLIC_UNPAID_REPORT"
}

export enum ContractOrderByColumn {
  CREATED_AT = "CREATED_AT",
  EFFECTIVE_BEGINNING_OR_SIGN_DATE = "EFFECTIVE_BEGINNING_OR_SIGN_DATE",
  ANNUAL_PREMIUM_OR_APPROVED_AMOUNT = "ANNUAL_PREMIUM_OR_APPROVED_AMOUNT",
  LAST_CONTRACT_CANCELLATION_DATE = "LAST_CONTRACT_CANCELLATION_DATE",
  CONTACT_CLIENT_DATE = "CONTACT_CLIENT_DATE",
  INSURANCE_PERIOD_END_DATE = "INSURANCE_PERIOD_END_DATE",
  FIXATION_ANNIVERSARY_DATE = "FIXATION_ANNIVERSARY_DATE",
  PAID_UNTIL_DATE = "PAID_UNTIL_DATE",
  AMOUNT_OWED = "AMOUNT_OWED"
}

export const contractStatusTMap = new Map<ContractStatus, string>(
  Object.values(ContractStatus).map(status => [status, t("contract.enums.status." + status)])
);

export const contractVerificationStatusTMap = new Map<ContractVerificationStatus, string>(
  Object.values(ContractVerificationStatus).map(status => [status, t("contract.enums.verificationStatus." + status)])
);
