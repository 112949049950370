import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";

// constants moved to separated file from auth/api.ts due to cyclic dependencies what caused errors in imports
const LOGIN = ApiRequestAdapter.create("/auth", "POST");
const SEND_TOTP_CODE_VIA_SMS = ApiRequestAdapter.create("/auth/send-totp-code-via-sms", "POST");
const VERIFY_TOTP_CODE = ApiRequestAdapter.create("/auth/verify-totp-code", "POST");
const REFRESH_TOKEN = ApiRequestAdapter.create("/auth/refresh-token", "POST");

const requests = {
  LOGIN,
  SEND_TOTP_CODE_VIA_SMS,
  VERIFY_TOTP_CODE,
  REFRESH_TOKEN
};

export default requests;
