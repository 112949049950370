import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { deleteVehicleCategoryActions } from "../../ducks";
import { CertificateVehicleCategory, CertificateVehicleCategoryFilterPageResult } from "../../types";

interface Props {
  categoriesCurrentPage: CertificateVehicleCategoryFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (cetrificateVehicleCategory: CertificateVehicleCategory) => void;
  onDelete: typeof deleteVehicleCategoryActions.request;
}

const VehicleCategoryTableView = ({ categoriesCurrentPage, onPageChange, onUpdateClick, onDelete }: Props) => {
  const columns: ColumnsType<CertificateVehicleCategory> = [
    {
      key: "category",
      title: t("contract.enums.vehicleCategory._label"),
      width: 150,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{t("contract.enums.vehicleCategory." + record.category)}</Ellipsis>
    },
    {
      key: "value",
      title: t("vehicleCategory.attrs.value"),
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.value}</Ellipsis>
    },
    {
      key: "description",
      title: t("vehicleCategory.attrs.description"),
      width: 470,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.description}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("vehicleCategory.helpers.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => onDelete({ id: record.id })}
          >
            <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<CertificateVehicleCategory>
        {...tableStandardProps()}
        columns={columns}
        dataSource={categoriesCurrentPage.pageData}
        scroll={{ x: TableSizes.MEDIUM }}
        pagination={{
          ...paginationTableProps,
          current: categoriesCurrentPage.pageIndex + 1,
          pageSize: categoriesCurrentPage.pageSize,
          total: categoriesCurrentPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </Card>
  );
};

export default VehicleCategoryTableView;
