import { Tag } from "antd";
import t from "../../../app/i18n";

interface Props {
  style?: React.CSSProperties;
}

const PrimaryTariffTag = ({ style }: Props) => (
  <Tag color="green" style={style}>
    {t("lifeInsuranceTariff.attrs.primaryTariff")}
  </Tag>
);

export default PrimaryTariffTag;
