import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { formatPhoneNumber } from "../../../../common/utils/formatUtils";
import {
  phoneNumberNormalizeFunction,
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import AgentSelect from "../../../enumerations/components/form/AgentSelect";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import { requests } from "../../api";
import { createDashboardContactActions, updateDashboardContactActions } from "../../ducks";
import { DashboardContactType } from "../../enums";
import { CreateUpdateDashboardContact, DashboardContact } from "../../types";

interface Props {
  open: boolean;
  contact?: DashboardContact;
  onFormCancel: () => void;
}

export const DashboardContactForm = ({ open, contact, onFormCancel }: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<CreateUpdateDashboardContact>();
  useFormErrorHandler(form, "dashboard.contacts.attrs", [
    requests.CREATE_DASHBOARD_CONTACT,
    requests.UPDATE_DASHBOARD_CONTACT
  ]);

  const contactType = Form.useWatch("type", form);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onCreate: createDashboardContactActions.request,
          onUpdate: updateDashboardContactActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    if (open && contact) {
      form.setFieldsValue({
        ...contact,
        phone: formatPhoneNumber(contact.phone),
        agentId: contact.agent?.id,
        institutionId: contact.institution?.id
      });
    }
  }, [open, contact, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_DASHBOARD_CONTACT, requests.UPDATE_DASHBOARD_CONTACT],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (contact) {
          actions.onUpdate({ id: contact.id, object: values });
        } else {
          actions.onCreate(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t(`dashboard.contacts.titles.${contact ? "update" : "create"}`)}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
      afterClose={form.resetFields}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={contact} />

      <Form form={form} layout="vertical" name="dashboardContactForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item name="type" label={t("dashboard.contacts.enums.type._label")} rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={Object.values(DashboardContactType).map(type => ({
                  value: type,
                  label: t(`dashboard.contacts.enums.type.${type}`)
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="position"
              label={t("dashboard.contacts.attrs.position")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {contactType === DashboardContactType.INTERNAL ? (
          <Row gutter={rowGutter}>
            <Col span={12}>
              <AgentSelect
                formItemProps={{
                  name: "agentId",
                  label: t("dashboard.contacts.attrs.agentId"),
                  rules: [validations.notNull]
                }}
                optionsProps={{
                  useAllAgents: true
                }}
              />
            </Col>
          </Row>
        ) : contactType === DashboardContactType.EXTERNAL ? (
          <>
            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label={t("dashboard.contacts.attrs.name")}
                  rules={[validations.notBlank, validations.size(1, 255)]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <InstitutionSelect
                  formItemProps={{
                    name: "institutionId",
                    label: t("dashboard.contacts.attrs.institutionId"),
                    rules: [validations.none]
                  }}
                  selectProps={{
                    allowClear: true
                  }}
                />
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="email"
                  dependencies={["phone"]}
                  label={t("dashboard.contacts.attrs.email")}
                  rules={[
                    validations.size(1, 254),
                    validations.email,
                    validations.notNullIfOtherNull("phone", t("dashboard.contacts.attrs.phone"))
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="phone"
                  dependencies={["email"]}
                  label={t("dashboard.contacts.attrs.phone")}
                  rules={[
                    validations.size(1, 19),
                    validations.phoneNumber,
                    validations.notNullIfOtherNull("email", t("dashboard.contacts.attrs.email"))
                  ]}
                  normalize={phoneNumberNormalizeFunction}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
      </Form>
    </Modal>
  );
};
