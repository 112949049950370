import { Form, Input, Modal } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes } from "../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { userRequestEmailUpdateActions } from "../../ducks";
import { RequestUserEmailUpdate, UserProfile } from "../../types";

interface Props {
  open: boolean;
  user: UserProfile;
  onFormSubmit: typeof userRequestEmailUpdateActions.request;
  onFormCancel: () => void;
}

const RequestEmailUpdateForm = ({ open, user, onFormSubmit, onFormCancel }: Props) => {
  const [form] = Form.useForm<RequestUserEmailUpdate>();
  useFormErrorHandler(form, "user.attrs", [requests.USER_REQUEST_EMAIL_UPDATE]);

  useEffect(() => {
    if (open && user) {
      form.setFieldsValue({
        optimisticLockVersion: user.optimisticLockVersion,
        password: undefined
      });
    }
  }, [open, user, form]);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        onFormSubmit({
          id: user.id,
          object: values
        });
      })
      .catch(resolveFormValidationError);
  };

  const inProgress = useRequestFinishedCallback([requests.USER_REQUEST_EMAIL_UPDATE], onFormCancel);

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t("user.titles.updateEmail")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="requestEmailUpdateForm">
        <HiddenInput name="optimisticLockVersion" />

        <Form.Item
          name="emailToConfirm"
          label={t("user.attrs.emailToConfirm")}
          rules={[validations.notBlank, validations.size(1, 254), validations.email]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="password" label={t("user.attrs.previousPassword")} rules={[validations.notBlank]}>
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RequestEmailUpdateForm;
