import classNames from "classnames";
import { CSSProperties } from "react";
import { resolveFileIconType } from "../../utils/utils";
import AntIcon from "./AntIcon";

const Size = {
  small: 12,
  default: 14,
  large: 16,
  huge: 18
};

interface Props {
  contentType: string;
  size?: keyof typeof Size;
  className?: string;
  style?: CSSProperties;
}

const FileTypeIcon = ({ contentType, size, className, style }: Props) => (
  <AntIcon
    type={resolveFileIconType(contentType)}
    className={classNames("file-type-icon", size, className)}
    style={size ? { fontSize: Size[size], ...style } : { ...style }}
  />
);

export default FileTypeIcon;
