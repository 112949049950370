import t from "../../../../../../app/i18n";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";
import TableActionsView from "../../../../../../common/components/views/TableActionsView";
import { TableActionsViewActionConfig } from "../../../../../../common/types";
import { deleteCommissionActions, tryToIncludeCommissionActions } from "../../../ducks";
import { PostponementReason } from "../../../enums";
import { Commission, CommissionsBatchBase } from "../../../types";

interface Props {
  record: Commission;
  batch: CommissionsBatchBase;
  onUpdateClick?: (commission: Commission) => void;
  onPostponeClick?: (commission: Commission) => void;
  onTryToInclude?: typeof tryToIncludeCommissionActions.request;
  onDelete?: typeof deleteCommissionActions.request;
}

const BatchCommissionActionsView = ({ record, batch, ...props }: Props) => {
  const actions: TableActionsViewActionConfig[] = [];

  if (record.postponed) {
    actions.push({
      color: "green",
      icon: "pull-request",
      text: t("common.include"),
      disabled: batch.stepChangeInProgress || record.postponementReason === PostponementReason.IMPORT_ERROR,
      onClick: () =>
        props.onTryToInclude?.({
          id1: batch.id,
          id2: record.id,
          object: { optimisticLockVersion: record.optimisticLockVersion }
        })
    });
  } else {
    actions.push({
      color: "orange",
      icon: "branch",
      text: t("common.postpone"),
      disabled: batch.stepChangeInProgress,
      onClick: () => props.onPostponeClick?.(record)
    });
  }

  actions.push(
    {
      color: "blue",
      icon: "edit",
      text: t("common.edit"),
      disabled: batch.stepChangeInProgress,
      onClick: () => props.onUpdateClick?.(record)
    },
    {
      color: "red",
      icon: "delete",
      text: t("common.delete"),
      disabled: batch.stepChangeInProgress,
      onClick: () => props.onDelete?.({ id1: batch.id, id2: record.id }),
      confirmDialog: {
        icon: <PopconfirmDeleteIcon />,
        title: t("commissions.batch.helpers.deleteCommissionConfirm"),
        okType: "danger",
        okText: t("common.yes"),
        cancelText: t("common.no")
      }
    }
  );

  return (
    <TableActionsView
      actions={actions}
      dropdownMaxWidth={250}
      dropdownAction={{
        color: "blue",
        icon: "down",
        text: t("common.options"),
        textBeforeIcon: true,
        disabled: batch.stepChangeInProgress
      }}
    />
  );
};

export default BatchCommissionActionsView;
