import { Button, Card, Checkbox, Col, Form, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { validations } from "../../../../../common/utils/validationUtils";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import { AgentTree, AgentTreeFilterRequest } from "../../../types";

interface Props {
  filter?: AgentTree;
  onFilterSubmit: (filter: AgentTreeFilterRequest) => void;
}

const AgentTreeFilterView = ({ filter, onFilterSubmit }: Props) => {
  const [form] = Form.useForm<AgentTreeFilterRequest>();

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        rootAgentId: filter.rootAgentId,
        includeDeactivated: !!filter.includeDeactivated,
        includeRepresentatives: !!filter.includeRepresentatives
      });
    }
  }, [filter, form]);

  return (
    <Card className="card-box" title={<h2>{t("agent.titles.tree")}</h2>}>
      <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="agentTreeFilterForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={6}>
            <AgentSelect
              formItemProps={{
                name: "rootAgentId",
                label: t("agent.filter.rootAgentId")
              }}
              selectProps={{ allowClear: true }}
            />
          </Col>

          <Col span={4} className="center-align">
            <Form.Item
              name="includeDeactivated"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("agent.filter.includeDeactivated")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={4} className="center-align">
            <Form.Item
              name="includeRepresentatives"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("agent.filter.includeRepresentatives")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={3} className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AgentTreeFilterView;
