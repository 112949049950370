import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { deleteVehicleFuelTypeActions } from "../../ducks";
import { CertificateFuelType, CertificateFuelTypeFilterPageResult } from "../../types";

interface Props {
  fuelTypesCurrentPage: CertificateFuelTypeFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (certificateVehicleFuelType: CertificateFuelType) => void;
  onDelete: typeof deleteVehicleFuelTypeActions.request;
}

const VehicleFuelTypeTableView = ({ fuelTypesCurrentPage, onPageChange, onUpdateClick, onDelete }: Props) => {
  const columns: ColumnsType<CertificateFuelType> = [
    {
      key: "fuelType",
      title: t("contract.enums.fuelType._label"),
      width: 150,
      ellipsis: { showTitle: false },
      render: (_, record) => record.fuelType && <Ellipsis>{t("contract.enums.fuelType." + record.fuelType)}</Ellipsis>
    },
    {
      key: "name",
      title: t("vehicleFuelType.attrs.name"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "source",
      title: t("vehicle.enums.fuelNameSource._label"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{t("vehicle.enums.fuelNameSource." + record.source)}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("vehicleFuelType.helpers.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => onDelete({ id: record.id })}
          >
            <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<CertificateFuelType>
        {...tableStandardProps()}
        columns={columns}
        dataSource={fuelTypesCurrentPage.pageData}
        scroll={{ x: TableSizes.SMALL }}
        pagination={{
          ...paginationTableProps,
          current: fuelTypesCurrentPage.pageIndex + 1,
          pageSize: fuelTypesCurrentPage.pageSize,
          total: fuelTypesCurrentPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </Card>
  );
};

export default VehicleFuelTypeTableView;
