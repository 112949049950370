export enum VehicleMappingType {
  ALLIANZ = "ALLIANZ",
  COLONNADE = "COLONNADE",
  CSOB = "CSOB",
  DEFEND = "DEFEND",
  GENERALI = "GENERALI",
  KOMUNALNA = "KOMUNALNA",
  KOOPERATIVA = "KOOPERATIVA",
  UNION_MTPL = "UNION_MTPL",
  UNION_CRASH = "UNION_CRASH",
  UNIQA = "UNIQA"
}

export enum VehicleMappingTypeKeys {
  ALLIANZ = "allianz",
  COLONNADE = "colonnade",
  CSOB = "csob",
  DEFEND = "defend",
  GENERALI = "generali",
  KOMUNALNA = "komunalna",
  KOOPERATIVA = "kooperativa",
  UNION_MTPL = "unionMtpl",
  UNION_CRASH = "unionCrash",
  UNIQA = "uniqa"
}
