import skAffiliatePartner from "./sk/affiliate-partner-translations.json";
import skAgent from "./sk/agent-translations.json";
import skBugReport from "./sk/bug-report-translations.json";
import skCalcRealty from "./sk/calc/calc-realty-translations.json";
import skCalc from "./sk/calc/calc-translations.json";
import skCalcTravel from "./sk/calc/calc-travel-translations.json";
import skCalcVehicle from "./sk/calc/calc-vehicle-translations.json";
import skClient from "./sk/client-translations.json";
import skCommissionsBatch from "./sk/commissions/commissions-batch-translations.json";
import skCommissions from "./sk/commissions/commissions-translations.json";
import skCommon from "./sk/common-translations.json";
import skComplicity from "./sk/complicity-translations.json";
import skConfigProperty from "./sk/config-property-translations.json";
import skContractTermination from "./sk/contract-termination-translations.json";
import skContract from "./sk/contract-translations.json";
import skContractUpdate from "./sk/contract-update-translations.json";
import skCoverageLimit from "./sk/coverage-limit-translations.json";
import skDashboard from "./sk/dashboard-translations.json";
import skDocumentNode from "./sk/document-node-translations.json";
import skFinancialMediation from "./sk/financial-mediation-translations.json";
import skInstitution from "./sk/institution-translations.json";
import skJob from "./sk/job-translations.json";
import skLifeInsuranceTariff from "./sk/life-insurance-tariff-translations.json";
import skNotification from "./sk/notification-translations.json";
import skPartner from "./sk/partner-translations.json";
import skProduct from "./sk/product-translations.json";
import skUser from "./sk/user-translations.json";
import skVehicle from "./sk/vehicle-translations.json";

const locales = {
  sk: {
    calc: {
      ...skCalc,
      ...skCalcRealty,
      ...skCalcTravel,
      ...skCalcVehicle
    },
    commissions: {
      ...skCommissions,
      ...skCommissionsBatch
    },
    ...skAffiliatePartner,
    ...skAgent,
    ...skBugReport,
    ...skClient,
    ...skCommon,
    ...skComplicity,
    ...skConfigProperty,
    ...skContract,
    ...skContractTermination,
    ...skContractUpdate,
    ...skCoverageLimit,
    ...skDashboard,
    ...skDocumentNode,
    ...skFinancialMediation,
    ...skInstitution,
    ...skJob,
    ...skLifeInsuranceTariff,
    ...skNotification,
    ...skPartner,
    ...skProduct,
    ...skUser,
    ...skVehicle
  }
};

export default locales;
