import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";
import { combineReducers } from "redux";
import { replace } from "redux-first-history";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, RootState } from "../../common/types";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { openBlobFile, replaceInArray } from "../../common/utils/utils";
import { getEnumerationsActions } from "../enumerations/ducks";
import api from "./api";
import { INSTITUTION_ROUTE_PATHS } from "./paths";
import {
  CreateUpdateInstitution,
  Institution,
  InstitutionFilterExportRequest,
  InstitutionFilterPageRequest,
  InstitutionFilterPageResult,
  InstitutionList,
  InstitutionReducerState,
  InstitutionSettings,
  UpdateInstitutionSettings
} from "./types";

/**
 * ACTIONS
 */
export const filterInstitutionsActions = createAsyncAction(
  "institution/FILTER_REQUEST",
  "institution/FILTER_SUCCESS",
  "institution/FILTER_FAILURE"
)<InstitutionFilterPageRequest, InstitutionFilterPageResult, void>();

export const downloadInstitutionsExportActions = createAsyncAction(
  "institution/EXPORT_REQUEST",
  "institution/EXPORT_SUCCESS",
  "institution/EXPORT_FAILURE"
)<InstitutionFilterExportRequest, void, void>();

export const getInstitutionActions = createAsyncAction(
  "institution/GET_REQUEST",
  "institution/GET_SUCCESS",
  "institution/GET_FAILURE"
)<EntityIdObject, Institution, void>();

export const createInstitutionActions = createAsyncAction(
  "institution/CREATE_REQUEST",
  "institution/CREATE_SUCCESS",
  "institution/CREATE_FAILURE"
)<CreateUpdateInstitution, Institution, void>();

export const updateInstitutionActions = createAsyncAction(
  "institution/UPDATE_REQUEST",
  "institution/UPDATE_SUCCESS",
  "institution/UPDATE_FAILURE"
)<EntityObject<CreateUpdateInstitution>, Institution, void>();

export const updateInstitutionSettingsActions = createAsyncAction(
  "institution/UPDATE_SETTINGS_REQUEST",
  "institution/UPDATE_SETTINGS_SUCCESS",
  "institution/UPDATE_SETTINGS_FAILURE"
)<EntityObject<UpdateInstitutionSettings>, EntityObject<InstitutionSettings>, void>();

export const deleteInstitutionActions = createAsyncAction(
  "institution/DELETE_REQUEST",
  "institution/DELETE_SUCCESS",
  "institution/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateInstitutionsPageAction = createAction("institution/DELETE_STATE_LIST")<void>();
export const deleteStateInstitutionDetailAction = createAction("institution/DELETE_STATE_DETAIL")<void>();

const actions = {
  filterInstitutionsActions,
  downloadInstitutionsExportActions,
  getInstitutionActions,
  createInstitutionActions,
  updateInstitutionActions,
  updateInstitutionSettingsActions,
  deleteInstitutionActions,
  deleteStateInstitutionsPageAction,
  deleteStateInstitutionDetailAction
};

export type InstitutionAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: InstitutionReducerState = {
  currentPage: {
    ...initSearchPageResult<InstitutionList>(),
    types: []
  },
  institutionDetail: null
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction([filterInstitutionsActions.success], (_, { payload }) => payload)
  .handleAction([updateInstitutionSettingsActions.success], (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      current => ({ ...current, settings: payload.object })
    )
  }))
  .handleAction(
    [filterInstitutionsActions.failure, deleteInstitutionActions.success, deleteStateInstitutionsPageAction],
    () => initialState.currentPage
  );

const institutionDetailReducer = createReducer(initialState.institutionDetail)
  .handleAction([getInstitutionActions.success, updateInstitutionActions.success], (_, { payload }) => payload)
  .handleAction(
    [getInstitutionActions.failure, deleteInstitutionActions.success, deleteStateInstitutionDetailAction],
    () => initialState.institutionDetail
  );

export const institutionReducer = combineReducers<InstitutionReducerState>({
  currentPage: currentPageReducer,
  institutionDetail: institutionDetailReducer
});

/**
 * SELECTORS
 */
const selectInstitution = (state: RootState): InstitutionReducerState => state.institution;

export const selectInstitutionsCurrentPage = (state: RootState): InstitutionFilterPageResult =>
  selectInstitution(state).currentPage;
export const selectInstitutionDetail = (state: RootState): Institution | undefined =>
  selectInstitution(state).institutionDetail ?? undefined;

/**
 * SAGAS
 */
function* filterInstitutions({ payload }: ReturnType<typeof filterInstitutionsActions.request>) {
  try {
    const response: AxiosResponse<InstitutionFilterPageResult> = yield call(api.filterInstitutions, payload);
    yield put(filterInstitutionsActions.success(response.data));
  } catch {
    yield put(filterInstitutionsActions.failure());
  }
}

function* downloadInstitutionsExport({ payload }: ReturnType<typeof downloadInstitutionsExportActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadInstitutionsExportActions, payload);
    openBlobFile(response, true);
    yield put(downloadInstitutionsExportActions.success());
  } catch {
    yield put(downloadInstitutionsExportActions.failure());
  }
}

function* getInstitutionDetail({ payload }: ReturnType<typeof getInstitutionActions.request>) {
  try {
    const response: AxiosResponse<Institution> = yield call(api.getInstitution, payload);
    yield put(getInstitutionActions.success(response.data));
  } catch {
    yield put(getInstitutionActions.failure());
  }
}

function* createInstitution({ payload }: ReturnType<typeof createInstitutionActions.request>) {
  try {
    const response: AxiosResponse<Institution> = yield call(api.createInstitution, payload);
    yield put(createInstitutionActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(replace(generatePath(INSTITUTION_ROUTE_PATHS.detail.to, { id: response.data.id })));
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createInstitutionActions.failure());
  }
}

function* updateInstitution({ payload }: ReturnType<typeof updateInstitutionActions.request>) {
  try {
    const response: AxiosResponse<Institution> = yield call(api.updateInstitution, payload);
    yield put(updateInstitutionActions.success(response.data));
    yield put(getEnumerationsActions.request());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateInstitutionActions.failure());
  }
}

function* updateInstitutionSettings({ payload }: ReturnType<typeof updateInstitutionSettingsActions.request>) {
  try {
    const response: AxiosResponse<InstitutionSettings> = yield call(api.updateInstitutionSettings, payload);
    yield put(updateInstitutionSettingsActions.success({ id: payload.id, object: response.data }));
    yield put(getEnumerationsActions.request());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateInstitutionSettingsActions.failure());
  }
}

function* deleteInstitution({ payload }: ReturnType<typeof deleteInstitutionActions.request>) {
  try {
    yield call(api.deleteInstitution, payload);
    yield put(deleteInstitutionActions.success());
    yield put(getEnumerationsActions.request());
    messageUtils.itemDeletedNotification();
    yield put(replace(INSTITUTION_ROUTE_PATHS.list.to));
  } catch {
    yield put(deleteInstitutionActions.failure());
  }
}

export function* institutionSaga() {
  yield takeLatest(filterInstitutionsActions.request, filterInstitutions);
  yield takeLatest(downloadInstitutionsExportActions.request, downloadInstitutionsExport);
  yield takeLatest(getInstitutionActions.request, getInstitutionDetail);
  yield takeLatest(createInstitutionActions.request, createInstitution);
  yield takeLatest(updateInstitutionActions.request, updateInstitution);
  yield takeLatest(updateInstitutionSettingsActions.request, updateInstitutionSettings);
  yield takeLatest(deleteInstitutionActions.request, deleteInstitution);
}
