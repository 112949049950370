import t from "../../../app/i18n";

export enum CommissionsBatchStep {
  IMPORT = "IMPORT",
  CALCULATION = "CALCULATION",
  RECAPITULATION = "RECAPITULATION",
  REVIEW = "REVIEW",
  FINISH = "FINISH"
}

export enum CommissionOrigin {
  ALL = "ALL",
  CURRENT_BATCH = "CURRENT_BATCH",
  PREVIOUS_BATCHES = "PREVIOUS_BATCHES",
  MANUALLY_ADDED = "MANUALLY_ADDED"
}

export enum CommissionProcessingResult {
  ALL = "ALL",
  PROCESSED = "PROCESSED",
  WITH_UNMODIFIED_CALCULATION = "WITH_UNMODIFIED_CALCULATION",
  WITH_MODIFIED_CALCULATION = "WITH_MODIFIED_CALCULATION",
  WITH_CALCULATION_ERROR = "WITH_CALCULATION_ERROR",
  POSTPONED = "POSTPONED"
}

export enum CommissionKind {
  INITIAL = "INITIAL",
  SUBSEQUENT = "SUBSEQUENT"
}

export enum CommissionType {
  IMPORTED_COMMISSION = "IMPORTED_COMMISSION",
  DUPLICATED_COMMISSION = "DUPLICATED_COMMISSION",
  MANUALLY_ADDED_COMMISSION = "MANUALLY_ADDED_COMMISSION"
}

export enum PostponementReason {
  NOT_FOUND = "NOT_FOUND",
  NOT_VERIFIED = "NOT_VERIFIED",
  DUPLICATE_CONTRACT = "DUPLICATE_CONTRACT",
  IMPORT_ERROR = "IMPORT_ERROR",
  MANUAL = "MANUAL"
}

export enum CommissionsBatchStepDirection {
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS"
}

export enum CommissionsBatchAttachmentType {
  GENERIC = "GENERIC",
  INPUT = "INPUT",
  OUTPUT = "OUTPUT"
}

export enum ManualBailAccountMovementsReport {
  ASSOCIATED = "ASSOCIATED",
  DISASSOCIATED = "DISASSOCIATED"
}

export const commissionsBatchStepTMap = new Map<CommissionsBatchStep, string>(
  Object.values(CommissionsBatchStep).map(step => [step, t("commissions.batch.enums.batchStep." + step)])
);

export const commissionKindTMap = new Map<CommissionKind, string>(
  Object.values(CommissionKind).map(kind => [kind, t("commissions.batch.enums.commissionKind." + kind)])
);

export const postponementReasonTMap = new Map<PostponementReason, string>(
  Object.values(PostponementReason).map(reason => [reason, t("commissions.batch.enums.postponementReason." + reason)])
);
