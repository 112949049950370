import { Tag } from "antd";
import t from "../../../../app/i18n";
import { ClientType } from "../../enums";

interface Props {
  type?: ClientType;
  closable?: boolean;
  style?: React.CSSProperties;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  label?: React.ReactNode;
}

const ClientTypeTag = ({ type, closable, style, onClose, label }: Props) => {
  let color;

  switch (type) {
    case ClientType.NATURAL:
      color = "blue";
      break;
    case ClientType.SELF_EMPLOYED:
      color = "gold";
      break;
    case ClientType.LEGAL:
      color = "purple";
      break;
  }

  return type || label ? (
    <Tag color={color} closable={closable} style={style} onClose={onClose}>
      {type ? t("client.enums.type." + type) : label}
    </Tag>
  ) : null;
};

export default ClientTypeTag;
