import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import apiService from "../../../common/api/apiService";
import { VehiclePriceRequest } from "./types";

const GET_VEHICLE_PRICE = ApiRequestAdapter.create("/vehicles/price", "GET");

export const requests = {
  GET_VEHICLE_PRICE
};

const api = {
  getVehiclePrice: (request: VehiclePriceRequest): AxiosPromise<number> => {
    return apiService.get(GET_VEHICLE_PRICE.url, { params: request });
  }
};

export default api;
