import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import InfoIcon from "../../../../../common/components/icons/InfoIcon";
import DaysCountTag from "../../../../../common/components/tags/DaysCountTag";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import TableActionsView from "../../../../../common/components/views/TableActionsView";
import { TableSizes } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { formatAgentIdNumber, formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { toDate } from "../../../../../common/utils/formUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../auth/ducks";
import { updateAgentCompetenceActions } from "../../../ducks";
import { AgentCompetenceType } from "../../../enums";
import { AgentCompetenceFilterPageResult, AgentCompetenceIntervalList, AgentCompetenceList } from "../../../types";
import AgentTypeTagWithName from "../../AgentTypeTagWithName";
import AgentCompetenceForm from "../../forms/AgentCompetenceForm";

interface Props {
  competencesPage: AgentCompetenceFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onUpdate: typeof updateAgentCompetenceActions.request;
}

const AgentEndingCompetenceTableView = ({ competencesPage, onPageChange, onUpdate }: Props) => {
  const hasAgentCompetenceManagePermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.AGENT_COMPETENCE_MANAGE)(state)
  );

  const [competenceToUpdate, setCompetenceToUpdate] = useState<AgentCompetenceList>();

  const today = dayjs().startOf("day");

  const columns: ColumnsType<AgentCompetenceIntervalList> = [
    {
      key: "days",
      width: 100,
      render: (_, record) => (
        <DaysCountTag
          days={(toDate(record.endDate) as Dayjs).startOf("day").diff(today, "days")}
          style={{ marginRight: 0 }}
        />
      )
    },
    {
      key: "aggregatedName",
      title: t("agent.attrs.aggregatedName"),
      width: 250,
      ellipsis: { showTitle: false },
      render: (_, record) => <AgentTypeTagWithName agent={record.competence.agent} hideIdNumber ellipsis showLink />
    },
    {
      key: "idNumber",
      title: t("agent.attrs.idNumber"),
      width: 90,
      render: (_, record) => formatAgentIdNumber(record.competence.agent)
    },
    {
      key: "type",
      title: t("agent.competence.enums.competenceType._label"),
      ellipsis: { showTitle: false },
      width: 70,
      render: (_, record) => <Ellipsis>{t("agent.competence.enums.competenceType." + record.competence.type)}</Ellipsis>
    },
    {
      key: "label",
      title: t("agent.competence.attrs.label"),
      ellipsis: { showTitle: false },
      width: 170,
      render: (_, record) =>
        record.competence.type === AgentCompetenceType.OTHER ? (
          <Ellipsis>{record.competence.label}</Ellipsis>
        ) : (
          <>
            <Ellipsis>
              <span>{t("agent.enums.financialSector." + record.competence.sector)}</span>
            </Ellipsis>
            <br />
            <Ellipsis>
              <span>{t("agent.competence.helpers.competenceLevel." + record.competence.level)}</span>
            </Ellipsis>
          </>
        )
    },
    {
      key: "provider",
      title: t("agent.competence.enums.competenceProvider._label"),
      ellipsis: { showTitle: false },
      width: 140,
      render: (_, record) =>
        record.competence.provider ? (
          <Ellipsis>{t("agent.competence.enums.competenceProvider." + record.competence.provider)}</Ellipsis>
        ) : null
    },
    {
      key: "startDate",
      title: t("agent.competence.attrs.intervals.startDate"),
      width: 100,
      render: (_, record) => formatLocaleDate(record.startDate)
    },
    {
      key: "endDate",
      title: t("agent.competence.attrs.intervals.endDate"),
      width: 100,
      render: (_, record) => formatLocaleDate(record.endDate)
    },
    {
      key: "note",
      align: "center",
      width: 40,
      render: (_, record) =>
        record.competence.note ? (
          <InfoIcon title={t("agent.competence.attrs.note")} tooltip={record.competence.note} />
        ) : null
    }
  ];

  if (hasAgentCompetenceManagePermission) {
    columns.push({
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_, record) => (
        <TableActionsView
          actions={[
            {
              color: "blue",
              text: t("common.edit"),
              icon: "edit",
              onClick: () => setCompetenceToUpdate(record.competence)
            }
          ]}
        />
      )
    });
  }

  return (
    <>
      <Card className="card-box">
        <Table<AgentCompetenceIntervalList>
          {...tableStandardProps()}
          columns={columns}
          scroll={{ x: TableSizes.LARGE }}
          dataSource={competencesPage.pageData}
          pagination={{
            ...paginationTableProps,
            current: competencesPage.pageIndex + 1,
            pageSize: competencesPage.pageSize,
            total: competencesPage.totalElementsCount,
            onChange: onPageChange
          }}
        />
      </Card>

      {hasAgentCompetenceManagePermission && (
        <AgentCompetenceForm
          open={!!competenceToUpdate}
          agentId={competenceToUpdate?.agent.id}
          competence={competenceToUpdate}
          onUpdate={onUpdate}
          onFormCancel={() => setCompetenceToUpdate(undefined)}
        />
      )}
    </>
  );
};

export default AgentEndingCompetenceTableView;
