import { InstitutionEnum } from "../institution/enums";

export enum PartnerSsoType {
  MONLY = "MONLY",
  ALLIANZ = "ALLIANZ",
  AXA = "AXA",
  CSOB = "CSOB",
  GENERALI = "GENERALI",
  METLIFE = "METLIFE",
  NN_EXPERT_APP = "NN_EXPERT_APP",
  NN_WEB_AGENT_APP = "NN_WEB_AGENT_APP",
  UNIQA = "UNIQA",
  YOUPLUS = "YOUPLUS"
}

export const ssoTypeToInstitutionMap = new Map<PartnerSsoType, InstitutionEnum>([
  [PartnerSsoType.ALLIANZ, InstitutionEnum.ALLIANZ],
  [PartnerSsoType.AXA, InstitutionEnum.AXA_NON_LIFE],
  [PartnerSsoType.CSOB, InstitutionEnum.CSOB],
  [PartnerSsoType.GENERALI, InstitutionEnum.GENERALI],
  [PartnerSsoType.METLIFE, InstitutionEnum.MET_LIFE],
  [PartnerSsoType.NN_EXPERT_APP, InstitutionEnum.NN],
  [PartnerSsoType.NN_WEB_AGENT_APP, InstitutionEnum.NN],
  [PartnerSsoType.UNIQA, InstitutionEnum.UNIQA],
  [PartnerSsoType.YOUPLUS, InstitutionEnum.YOUPLUS]
]);
