import { Search } from "history";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { PageSizes } from "../../../common/constants";
import { ExportFileType } from "../../../common/enums";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { RootState } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import InstitutionFilterView from "../components/views/InstitutionFilterView";
import InstitutionListTableView from "../components/views/InstitutionListTableView";
import {
  deleteStateInstitutionsPageAction,
  downloadInstitutionsExportActions,
  filterInstitutionsActions,
  selectInstitutionsCurrentPage,
  updateInstitutionSettingsActions
} from "../ducks";
import { InstitutionType } from "../enums";
import { InstitutionFilterPageRequest, InstitutionFilterPageResult, InstitutionList } from "../types";

export const InstitutionListContainer = () => {
  const navigate = useNavigate();

  const institutionsCurrentPage = useSelector<RootState, InstitutionFilterPageResult>(selectInstitutionsCurrentPage);
  const urlSearchQuery = useSelector<RootState, Search>(selectRouterLocationSearch);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          filterInstitutions: filterInstitutionsActions.request,
          downloadInstitutionsExport: downloadInstitutionsExportActions.request,
          updateInstitutionSettings: updateInstitutionSettingsActions.request,
          deleteStateInstitutionsPage: deleteStateInstitutionsPageAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterInstitutions({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword") ?? undefined,
      types: urlParams.getAll("types") as InstitutionType[]
    });
    return () => {
      actions.deleteStateInstitutionsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, types } = institutionsCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterInstitutions({ pageIndex: pageNumber - 1, pageSize, keyword, types });
  };

  const handleFilterSubmit = (filter: InstitutionFilterPageRequest): void => {
    navigate(appendSearchParamsToURL({ ...filter, pageIndex: undefined, keyword: filter.keyword || undefined }), {
      replace: true
    });
    actions.filterInstitutions({ pageIndex: 0, pageSize: institutionsCurrentPage.pageSize, ...filter });
  };

  const handleInstitutionDeactivatedToggle = (institution: InstitutionList) => {
    if (institution.settings) {
      actions.updateInstitutionSettings({
        id: institution.id,
        object: {
          optimisticLockVersion: institution.settings.optimisticLockVersion,
          deactivated: !institution.settings.deactivated
        }
      });
    }
  };

  const handleExport = (exportFileType: ExportFileType): void => {
    const { types, keyword } = institutionsCurrentPage;

    actions.downloadInstitutionsExport({ types, keyword, exportFileType });
  };

  return (
    <ContentWrapper>
      <InstitutionFilterView filter={institutionsCurrentPage} onFilterSubmit={handleFilterSubmit} />

      <InstitutionListTableView
        institutionsPage={institutionsCurrentPage}
        onPageChange={handleTablePageChange}
        onInstitutionDeactivatedToggle={handleInstitutionDeactivatedToggle}
        onExport={handleExport}
      />
    </ContentWrapper>
  );
};
