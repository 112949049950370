import { AnyAction, combineReducers } from "redux";
import { RootState } from "../../common/types";
import { affiliatePartnerReducer } from "../../modules/affiliate/ducks";
import { agentReducer } from "../../modules/agent/ducks";
import { authReducer } from "../../modules/auth/ducks";
import { bugReportReducer } from "../../modules/bugreport/ducks";
import { calculatorReducer } from "../../modules/calculator/ducks";
import { clientReducer } from "../../modules/client/ducks";
import { commissionsReducer } from "../../modules/commissions/ducks";
import { complicityReducer } from "../../modules/complicity/ducks";
import { contractReducer } from "../../modules/contract/ducks";
import { contractTerminationReducer } from "../../modules/contracttermination/ducks";
import { coverageLimitReducer } from "../../modules/coveragelimit/ducks";
import { dashboardReducer } from "../../modules/dashboard/ducks";
import { documentNodeReducer } from "../../modules/documents/ducks";
import { appReducer, persistDataReducer } from "../../modules/ducks";
import { configPropertyReducer } from "../../modules/dynamicconfig/ducks";
import { enumerationsReducer } from "../../modules/enumerations/ducks";
import { financialMediationReducer } from "../../modules/financialmediation/ducks";
import { globalSearchReducer } from "../../modules/globalsearch/ducks";
import { institutionReducer } from "../../modules/institution/ducks";
import { jobReducer } from "../../modules/jobs/ducks";
import { lifeInsuranceTariffReducer } from "../../modules/lifeinsurancetariff/ducks";
import { notificationReducer } from "../../modules/notifications/ducks";
import { partnerReducer } from "../../modules/partner/ducks";
import { productReducer } from "../../modules/product/ducks";
import { userReducer } from "../../modules/user/ducks";
import { vehicleReducer } from "../../modules/vehicle/ducks";
import { routerReducer } from "./rootHistory";

const rootReducer = combineReducers<RootState>({
  affiliatePartner: affiliatePartnerReducer,
  agent: agentReducer,
  app: appReducer,
  auth: authReducer,
  bugReport: bugReportReducer,
  calculator: calculatorReducer,
  client: clientReducer,
  commissions: commissionsReducer,
  complicity: complicityReducer,
  configProperty: configPropertyReducer,
  contract: contractReducer,
  contractTermination: contractTerminationReducer,
  coverageLimit: coverageLimitReducer,
  dashboard: dashboardReducer,
  documentNode: documentNodeReducer,
  enumerations: enumerationsReducer,
  financialMediation: financialMediationReducer,
  globalSearch: globalSearchReducer,
  institution: institutionReducer,
  job: jobReducer,
  lifeInsuranceTariff: lifeInsuranceTariffReducer,
  notification: notificationReducer,
  partner: partnerReducer,
  persistData: persistDataReducer,
  product: productReducer,
  router: routerReducer,
  user: userReducer,
  vehicle: vehicleReducer
});

const reducer = (state: RootState, action: AnyAction): any => rootReducer(state, action);

export default reducer;
