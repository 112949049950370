import { Divider } from "antd";
import React from "react";

interface Props {
  tokens?: (string | undefined)[];
  showDivider?: boolean;
}

const MultiLineText = ({ tokens, showDivider }: Props) =>
  tokens ? (
    <>
      {tokens
        .filter(token => token && token.length > 0)
        .map((token, index) => (
          <React.Fragment key={index}>
            {token}
            {index < tokens.length - 1 ? showDivider ? <Divider style={{ margin: "4px 0" }} /> : <br /> : undefined}
          </React.Fragment>
        ))}
    </>
  ) : null;

export default MultiLineText;
