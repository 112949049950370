import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { EntityIdObject, EntityObject, TwoLevelEntityIdObject, TwoLevelEntityObject } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  AgentCreateUserWithClientUserAccount,
  AgentUpdateClientUserAccountWithUser,
  ClientUserAccountWithUserAgentView
} from "../user/types";
import {
  Client,
  ClientAttachment,
  ClientFilterExportRequest,
  ClientFilterPageRequest,
  ClientFilterPageResult,
  ClientSearchRequest,
  ClientSearchResult,
  CreateUpdateClient,
  ValidateContractClientRequest,
  ValidateContractClientsRequest
} from "./types";

const FILTER_CLIENTS = ApiRequestAdapter.create("/clients", "GET");
const IMPORT_CLIENTS = ApiRequestAdapter.create("/clients/import", "POST");
const DOWNLOAD_CLIENTS_IMPORT_RESULT = ApiRequestAdapter.create("/clients/import/{0}", "GET");
const DOWNLOAD_CLIENTS_EXPORT = ApiRequestAdapter.create("/clients/export", "GET");
const SEARCH_CLIENT = ApiRequestAdapter.create("/clients/search", "GET");

const VALIDATE_CLIENT = ApiRequestAdapter.create("/clients/validate", "POST");
const VALIDATE_CLIENTS = ApiRequestAdapter.create("/clients/validate-all", "POST");

const GET_CLIENT = ApiRequestAdapter.create("/clients/{0}", "GET");
const UPDATE_CLIENT = ApiRequestAdapter.create("/clients/{0}", "PUT");
const CREATE_CLIENT = ApiRequestAdapter.create("/clients", "POST");
const DELETE_CLIENT = ApiRequestAdapter.create("/clients/{0}", "DELETE");

const GET_CLIENT_USER_ACCOUNTS = ApiRequestAdapter.create("/clients/{0}/accounts", "GET");
const CREATE_USER_WITH_CLIENT_USER_ACCOUNT = ApiRequestAdapter.create("/clients/{0}/accounts", "POST");
const RESEND_USER_CONFIRM_LINK = ApiRequestAdapter.create("/clients/{0}/accounts/{1}/resend-confirm-link", "PUT");
const UPDATE_CLIENT_USER_ACCOUNT = ApiRequestAdapter.create("/clients/{0}/accounts/{1}", "PUT");
const DELETE_CLIENT_USER_ACCOUNT = ApiRequestAdapter.create("/clients/{0}/accounts/{1}", "DELETE");

const GET_CLIENT_ATTACHMENTS = ApiRequestAdapter.create("/clients/{0}/attachments", "GET");
const UPLOAD_CLIENT_ATTACHMENTS = ApiRequestAdapter.create("/clients/{0}/attachments", "POST");
const DOWNLOAD_CLIENT_ATTACHMENT = ApiRequestAdapter.create("/clients/{0}/attachments/{1}", "GET");
const DOWNLOAD_CLIENT_ATTACHMENTS_AS_ZIP = ApiRequestAdapter.create("/clients/{0}/attachments/all", "GET");
const DELETE_CLIENT_ATTACHMENT = ApiRequestAdapter.create("/clients/{0}/attachments/{1}", "DELETE");

export const requests = {
  FILTER_CLIENTS,
  IMPORT_CLIENTS,
  DOWNLOAD_CLIENTS_IMPORT_RESULT,
  DOWNLOAD_CLIENTS_EXPORT,
  SEARCH_CLIENT,
  VALIDATE_CLIENT,
  VALIDATE_CLIENTS,
  GET_CLIENT,
  UPDATE_CLIENT,
  CREATE_CLIENT,
  DELETE_CLIENT,
  GET_CLIENT_USER_ACCOUNTS,
  CREATE_USER_WITH_CLIENT_USER_ACCOUNT,
  RESEND_USER_CONFIRM_LINK,
  UPDATE_CLIENT_USER_ACCOUNT,
  DELETE_CLIENT_USER_ACCOUNT,
  GET_CLIENT_ATTACHMENTS,
  UPLOAD_CLIENT_ATTACHMENTS,
  DOWNLOAD_CLIENT_ATTACHMENT,
  DOWNLOAD_CLIENT_ATTACHMENTS_AS_ZIP,
  DELETE_CLIENT_ATTACHMENT
};

const api = {
  filterClients: (request: ClientFilterPageRequest): AxiosPromise<ClientFilterPageResult> => {
    return apiService.get(FILTER_CLIENTS.url, { params: request, paramsSerializer: { serialize: serializeParams } });
  },
  importClients: (request: FormData): AxiosPromise<number> => {
    return apiService.post(IMPORT_CLIENTS.url, request, { headers: { "Content-Type": "multipart/form-data" } });
  },
  downloadClientsImportResult: (request: EntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_CLIENTS_IMPORT_RESULT.getParametrizedUrl(request.id), { responseType: "blob" });
  },
  downloadClientsExport: (request: ClientFilterExportRequest): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_CLIENTS_EXPORT.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams },
      responseType: "blob"
    });
  },
  searchClient: (request: ClientSearchRequest): AxiosPromise<ClientSearchResult> => {
    return apiService.get(SEARCH_CLIENT.url, { params: request });
  },
  validateClient: (request: ValidateContractClientRequest): AxiosPromise => {
    return apiService.post(
      VALIDATE_CLIENT.url,
      { ...request, validationGroup: undefined },
      { params: { validationGroup: request.validationGroup } }
    );
  },
  validateClients: (request: ValidateContractClientsRequest): AxiosPromise => {
    return apiService.post(
      VALIDATE_CLIENTS.url,
      { ...request, validationGroup: undefined },
      { params: { validationGroup: request.validationGroup } }
    );
  },
  getClient: (request: EntityIdObject): AxiosPromise<Client> => {
    return apiService.get(GET_CLIENT.getParametrizedUrl(request.id));
  },
  updateClient: (request: EntityObject<CreateUpdateClient>): AxiosPromise<Client> => {
    return apiService.put(UPDATE_CLIENT.getParametrizedUrl(request.id), request.object);
  },
  createClient: (request: CreateUpdateClient): AxiosPromise<Client> => {
    return apiService.post(CREATE_CLIENT.url, request);
  },
  deleteClient: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_CLIENT.getParametrizedUrl(request.id));
  },
  getClientUserAccounts: (request: EntityIdObject): AxiosPromise<ClientUserAccountWithUserAgentView[]> => {
    return apiService.get(GET_CLIENT_USER_ACCOUNTS.getParametrizedUrl(request.id));
  },
  createUserWithClientUserAccount: (
    request: EntityObject<AgentCreateUserWithClientUserAccount>
  ): AxiosPromise<ClientUserAccountWithUserAgentView> => {
    return apiService.post(CREATE_USER_WITH_CLIENT_USER_ACCOUNT.getParametrizedUrl(request.id), request.object);
  },
  resendUserConfirmLink: (request: TwoLevelEntityIdObject): AxiosPromise<ClientUserAccountWithUserAgentView> => {
    return apiService.put(RESEND_USER_CONFIRM_LINK.getParametrizedUrl(request.id1, request.id2));
  },
  updateClientUserAccount: (
    request: TwoLevelEntityObject<AgentUpdateClientUserAccountWithUser>
  ): AxiosPromise<ClientUserAccountWithUserAgentView> => {
    return apiService.put(UPDATE_CLIENT_USER_ACCOUNT.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteClientUserAccount: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_CLIENT_USER_ACCOUNT.getParametrizedUrl(request.id1, request.id2));
  },
  getClientAttachments: (request: EntityIdObject): AxiosPromise<ClientAttachment[]> => {
    return apiService.get(GET_CLIENT_ATTACHMENTS.getParametrizedUrl(request.id));
  },
  uploadClientAttachments: (request: EntityObject<FormData>): AxiosPromise<ClientAttachment[]> => {
    return apiService.post(UPLOAD_CLIENT_ATTACHMENTS.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  downloadClientAttachment: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_CLIENT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  },
  downloadClientAttachmentsAsZip: (request: EntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_CLIENT_ATTACHMENTS_AS_ZIP.getParametrizedUrl(request.id), {
      responseType: "blob"
    });
  },
  deleteClientAttachment: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_CLIENT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2));
  }
};

export default api;
