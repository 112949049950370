import { Button, Divider, Modal, Table, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalSizes } from "../../../common/constants";
import { tInterval } from "../../../common/utils/translationUtils";
import { cssVariables } from "../../../common/utils/utils";
import { ProductFinancialSector } from "../../product/enums";

export type InvestmentQuestionnairePoints = {
  knowledgeAndExperiencePoints: number;
  financialSituationPoints: number;
  riskTolerancePoints: number;
  investmentTargetPoints?: number;
};

type Props = {
  totalPoints: InvestmentQuestionnairePoints;
  isOpen: boolean;
  onFormCancel: VoidFunction;
  sector?: ProductFinancialSector;
};

const getKnowledgeAndExperienceCategory = (points: number) => {
  if (points < 11) {
    return "1";
  } else if (points > 10 && points < 22) {
    return "2";
  } else {
    return "3";
  }
};

const getFinancialSituationCategory = (points: number) => {
  if (points < 6) {
    return "1";
  } else if (points > 5 && points < 11) {
    return "2";
  } else {
    return "3";
  }
};

const getRiskToleranceCategory = (points: number, sector?: ProductFinancialSector) => {
  if (sector === ProductFinancialSector.LIFE_INSURANCES) {
    if (points < 3) {
      return "1";
    } else if (points > 2 && points < 6) {
      return "2";
    } else {
      return "3";
    }
  }

  if (points < 6) {
    return "1";
  } else if (points > 5 && points < 11) {
    return "2";
  } else {
    return "3";
  }
};

export const InvestmentQuestionnaireEvaluationModal = ({ totalPoints, isOpen, onFormCancel, sector }: Props) => {
  const { t } = useTranslation();
  const [riskToleranceExpanded, setRiskToleranceExpanded] = useState([false, false, false]);
  const sectorNumberOfPointsPrefix = sector === ProductFinancialSector.LIFE_INSURANCES ? "p" : "n";

  const knowledgeAndExperienceData = [
    {
      key: "1",
      points: t("financialMediation.helpers.evaluation.knowledgeAndExperience.numberOfPoints.p1"),
      category: t("financialMediation.helpers.evaluation.knowledgeAndExperience.categoryDesc.c1")
    },
    {
      key: "2",
      points: t("financialMediation.helpers.evaluation.knowledgeAndExperience.numberOfPoints.p2"),
      category: t("financialMediation.helpers.evaluation.knowledgeAndExperience.categoryDesc.c2")
    },
    {
      key: "3",
      points: t(
        `financialMediation.helpers.evaluation.knowledgeAndExperience.numberOfPoints.${sectorNumberOfPointsPrefix}3`
      ),
      category: t("financialMediation.helpers.evaluation.knowledgeAndExperience.categoryDesc.c3")
    }
  ];

  const knowledgeAndExperienceColumns = [
    {
      key: "points",
      title: t("financialMediation.helpers.evaluation.numberOfPoints"),
      dataIndex: "points",
      width: 205
    },
    {
      key: "category",
      dataIndex: "category",
      title: t("financialMediation.helpers.evaluation.knowledgeAndExperience.categoryDesc.title")
    }
  ];

  const knowledgeAndExperienceCategory = getKnowledgeAndExperienceCategory(totalPoints.knowledgeAndExperiencePoints);

  const financialSituationData = [
    {
      key: "1",
      points: t("financialMediation.helpers.evaluation.financialSituation.numberOfPoints.p1"),
      category: t("financialMediation.helpers.evaluation.financialSituation.categoryDesc.c1")
    },
    {
      key: "2",
      points: t("financialMediation.helpers.evaluation.financialSituation.numberOfPoints.p2"),
      category: t("financialMediation.helpers.evaluation.financialSituation.categoryDesc.c2")
    },
    {
      key: "3",
      points: t("financialMediation.helpers.evaluation.financialSituation.numberOfPoints.p3"),
      category: t("financialMediation.helpers.evaluation.financialSituation.categoryDesc.c3")
    }
  ];

  const financialSituationColumns = [
    {
      key: "points",
      title: t("financialMediation.helpers.evaluation.numberOfPoints"),
      dataIndex: "points",
      width: 205
    },
    {
      key: "category",
      dataIndex: "category",
      title: t("financialMediation.helpers.evaluation.financialSituation.categoryDesc.title")
    }
  ];

  const financialSituationCategory = getFinancialSituationCategory(totalPoints.financialSituationPoints);

  const riskToleranceData = [
    {
      key: "1",
      points: t(`financialMediation.helpers.evaluation.riskTolerance.numberOfPoints.${sectorNumberOfPointsPrefix}1`),
      category: (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            symbol: riskToleranceExpanded[0] ? t("common.close") : t("common.more"),
            expandable: "collapsible",
            expanded: riskToleranceExpanded[0],
            onExpand: (_, info) => {
              const newData = [...riskToleranceExpanded];
              newData[0] = info.expanded;
              setRiskToleranceExpanded(newData);
            }
          }}
        >
          {t("financialMediation.helpers.evaluation.riskTolerance.categoryDesc.c1")}
        </Typography.Paragraph>
      )
    },
    {
      key: "2",
      points: t(`financialMediation.helpers.evaluation.riskTolerance.numberOfPoints.${sectorNumberOfPointsPrefix}2`),
      category: (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            expandable: "collapsible",
            symbol: riskToleranceExpanded[1] ? t("common.close") : t("common.more"),
            expanded: riskToleranceExpanded[1],
            onExpand: (_, info) => {
              const newData = [...riskToleranceExpanded];
              newData[1] = info.expanded;
              setRiskToleranceExpanded(newData);
            }
          }}
        >
          {t("financialMediation.helpers.evaluation.riskTolerance.categoryDesc.c2")}
        </Typography.Paragraph>
      )
    },
    {
      key: "3",
      points: t(`financialMediation.helpers.evaluation.riskTolerance.numberOfPoints.${sectorNumberOfPointsPrefix}3`),
      category: (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            expandable: "collapsible",
            symbol: riskToleranceExpanded[2] ? t("common.close") : t("common.more"),
            expanded: riskToleranceExpanded[2],
            onExpand: (_, info) => {
              const newData = [...riskToleranceExpanded];
              newData[2] = info.expanded;
              setRiskToleranceExpanded(newData);
            }
          }}
        >
          {t("financialMediation.helpers.evaluation.riskTolerance.categoryDesc.c3")}
        </Typography.Paragraph>
      )
    }
  ];

  const riskToleranceColumns = [
    {
      key: "points",
      title: t("financialMediation.helpers.evaluation.numberOfPoints"),
      dataIndex: "points",
      width: 205
    },
    {
      key: "category",
      dataIndex: "category",
      title: t("financialMediation.helpers.evaluation.riskTolerance.categoryDesc.title")
    }
  ];

  const riskToleranceCategory = getRiskToleranceCategory(totalPoints.riskTolerancePoints, sector);

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={isOpen}
      onCancel={onFormCancel}
      onOk={onFormCancel}
      footer={
        <Button type="primary" onClick={onFormCancel}>
          {t("common.ok").toUpperCase()}
        </Button>
      }
      title={t("financialMediation.titles.investmentQuestionnaireEvaluation")}
    >
      <Divider style={{ marginTop: 24, borderColor: cssVariables.colorGrey }} orientation="left" plain>
        <Typography.Text style={{ fontSize: 18 }} strong>
          {t("financialMediation.sections.questionnaireKnowledge")}
        </Typography.Text>
      </Divider>

      <Table
        dataSource={knowledgeAndExperienceData}
        columns={knowledgeAndExperienceColumns}
        pagination={false}
        rowSelection={{
          selectedRowKeys: [knowledgeAndExperienceCategory],
          hideSelectAll: true,
          type: "radio",
          getCheckboxProps: () => ({ style: { display: "none" } })
        }}
      />

      <div style={{ marginTop: 16 }}>
        <div>
          <Typography.Text strong>{t("financialMediation.helpers.evaluation.numberOfPointsDesc")}: </Typography.Text>
          <span>
            {tInterval(
              "financialMediation.helpers.evaluation.points_interval",
              totalPoints.knowledgeAndExperiencePoints
            )}
            .
          </span>
        </div>
        <div>
          <Typography.Text strong>{t("financialMediation.helpers.evaluation.category")}: </Typography.Text>
          <span>
            {t(
              `financialMediation.helpers.evaluation.knowledgeAndExperience.categoryDesc.c${knowledgeAndExperienceCategory}`
            )}
          </span>
        </div>
      </div>

      <Divider style={{ marginTop: 46, borderColor: cssVariables.colorGrey }} orientation="left" plain>
        <Typography.Text style={{ fontSize: 18 }} strong>
          {t("financialMediation.sections.questionnaireFinanceSituation")}
        </Typography.Text>
      </Divider>

      <Table
        dataSource={financialSituationData}
        columns={financialSituationColumns}
        pagination={false}
        rowSelection={{
          selectedRowKeys: [financialSituationCategory],
          hideSelectAll: true,
          type: "radio",
          getCheckboxProps: () => ({ style: { display: "none" } })
        }}
      />

      <div style={{ marginTop: 16 }}>
        <div>
          <Typography.Text strong>{t("financialMediation.helpers.evaluation.numberOfPointsDesc")}: </Typography.Text>
          <span>
            {tInterval("financialMediation.helpers.evaluation.points_interval", totalPoints.financialSituationPoints)}.
          </span>
        </div>
        <div>
          <Typography.Text strong>{t("financialMediation.helpers.evaluation.category")}: </Typography.Text>
          <span>
            {t(`financialMediation.helpers.evaluation.financialSituation.categoryDesc.c${financialSituationCategory}`)}
          </span>
        </div>
      </div>

      <Divider style={{ marginTop: 46, borderColor: cssVariables.colorGrey }} orientation="left" plain>
        <Typography.Text style={{ fontSize: 18 }} strong>
          {t("financialMediation.sections.questionnaireRiskTolerance")}
        </Typography.Text>
      </Divider>

      <Table
        dataSource={riskToleranceData}
        columns={riskToleranceColumns}
        pagination={false}
        rowSelection={{
          selectedRowKeys: [riskToleranceCategory],
          hideSelectAll: true,
          type: "radio",
          getCheckboxProps: () => ({ style: { display: "none" } })
        }}
      />

      <div style={{ marginTop: 16 }}>
        <div>
          <Typography.Text strong>{t("financialMediation.helpers.evaluation.numberOfPointsDesc")}: </Typography.Text>
          <span>
            {tInterval("financialMediation.helpers.evaluation.points_interval", totalPoints.riskTolerancePoints)}.
          </span>
        </div>
        <div>
          <Typography.Text strong>{t("financialMediation.helpers.evaluation.category")}: </Typography.Text>
          <span>{t(`financialMediation.helpers.evaluation.riskTolerance.categoryDesc.c${riskToleranceCategory}`)}</span>
        </div>
      </div>
    </Modal>
  );
};
