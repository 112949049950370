import { Card } from "antd";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import AdminCreateUserForm from "../components/forms/AdminCreateUserForm";
import { adminCreateUserWithMultipleAgentUserAccountsActions } from "../ducks";

interface ActionsMap {
  createUser: typeof adminCreateUserWithMultipleAgentUserAccountsActions.request;
}

const AdminUserCreateContainer = (props: ActionProps<ActionsMap>) => {
  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>{t("user.titles.createUser")}</h2>
          </BackNavigationArrow>
        }
      >
        <AdminCreateUserForm onFormSubmit={props.actions.createUser} />
      </Card>
    </ContentWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      createUser: adminCreateUserWithMultipleAgentUserAccountsActions.request
    },
    dispatch
  )
});

export default connect<object, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  null,
  mapDispatchToProps
)(AdminUserCreateContainer);
