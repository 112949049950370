import { ConfigProvider, Layout, Skeleton, Spin } from "antd";
import { Pathname } from "history";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import logo from "../../assets/images/logo-cliip-white-color-1000x.png";
import squareLogo from "../../assets/images/logo-clip-1000xsquare-flower-transparent.png";
import { ATTACHMENT_BOUNDARY_PATH_PREFIX, ViewBreakpoints } from "../../common/constants";
import { Role } from "../../common/security/authorization/enums";
import { ActionProps, RootState } from "../../common/types";
import { useGoogleAnalytics } from "../../common/utils/analyticsUtils";
import { useDocumentTitle, useToken, useWindowSize } from "../../common/utils/hooksUtils";
import { cssVariables, getActiveEnvProfile } from "../../common/utils/utils";
import { checkAuthStateAction, selectIsUserAuthenticated, selectSelectedAccount } from "../../modules/auth/ducks";
import { AuthSelectedAccount } from "../../modules/auth/types";
import { DASHBOARD_ROUTE_PATHS } from "../../modules/dashboard/paths";
import { selectBrandingPersistData, selectHasActiveRequest, selectRouterLocationPathname } from "../../modules/ducks";
import { refreshEnumerationsAction, selectEnumsLastRefreshTime } from "../../modules/enumerations/ducks";
import { AgentBrandingEnumeration } from "../../modules/enumerations/types";
import {
  FILTER_NOTIFICATIONS_DEFAULT_REQUEST,
  filterHeaderNotificationsActions
} from "../../modules/notifications/ducks";
import BreadcrumbsBox from "../components/layout/BreadcrumbsBox";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import SideMenu from "../components/layout/SideMenu";
import { agentRoleRoutes, attachmentRoutes, clientRoleRoutes } from "../routes";
import styles from "./App.module.scss";

interface StateProps {
  userAuthenticated?: boolean;
  selectedAccount: AuthSelectedAccount;
  showSpinner: boolean;
  enumsLastRefreshTime?: string;
  branding?: AgentBrandingEnumeration;
}

interface ActionsMap {
  checkAuthState: typeof checkAuthStateAction;
  refreshEnumerations: typeof refreshEnumerationsAction;
  filterHeaderNotifications: typeof filterHeaderNotificationsActions.request;
}

type Props = StateProps & ActionProps<ActionsMap>;

const App = (props: Props) => {
  useGoogleAnalytics();
  useDocumentTitle();

  const { token } = useToken();
  const routerPathname = useSelector<RootState, Pathname>(selectRouterLocationPathname);
  const isAttachmentView = routerPathname.startsWith(ATTACHMENT_BOUNDARY_PATH_PREFIX);

  useEffect(() => {
    props.actions.checkAuthState();
    if (props.userAuthenticated && props.selectedAccount.role === Role.AGENT) {
      props.actions.refreshEnumerations();
      props.actions.filterHeaderNotifications(FILTER_NOTIFICATIONS_DEFAULT_REQUEST);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const windowSize = useWindowSize();

  if (windowSize.outerWidth < ViewBreakpoints.TABLET) {
    document.querySelector("meta[name=viewport]")?.setAttribute("content", "width=991");
  } else {
    document
      .querySelector("meta[name=viewport]")
      ?.setAttribute("content", "width=device-width, initial-scale=1, shrink-to-fit=no");
  }

  const marginLayout =
    windowSize.innerWidth >= token.screenXL ? cssVariables.sideMenuWidth : cssVariables.sideMenuResponsiveWidth;

  return isAttachmentView ? (
    attachmentRoutes
  ) : (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: props.branding?.primaryColor ?? cssVariables.colorPrimary,
          colorText: cssVariables.colorText
        }
      }}
    >
      <Layout hasSider>
        {props.userAuthenticated && (
          <Layout.Sider
            breakpoint="xl"
            collapsedWidth={cssVariables.sideMenuResponsiveWidth}
            width={cssVariables.sideMenuWidth}
            style={{ position: "fixed", overflow: "auto", height: "100vh", top: 0, bottom: 0 }}
          >
            <div className={styles.logo}>
              <Link to={DASHBOARD_ROUTE_PATHS.homepage.to} className={styles.logoLink}>
                <img
                  className={styles.logoImage}
                  src={
                    props.branding?.logoResourcePath
                      ? `/images/${props.branding.logoResourcePath}`
                      : windowSize.innerWidth > ViewBreakpoints.DESKTOP
                        ? logo
                        : squareLogo
                  }
                  alt="Logo"
                />
              </Link>
            </div>
            {props.selectedAccount.role === Role.AGENT && <SideMenu />}
          </Layout.Sider>
        )}

        <Layout
          style={{ marginInlineStart: props.userAuthenticated ? marginLayout : "inherit" }}
          className={`${styles.rootLayout} ${props.userAuthenticated ? "root-layout--signed" : ""} ${getActiveEnvProfile()}`}
        >
          <Header userAuthenticated={props.userAuthenticated} selectedAccountRole={props.selectedAccount.role} />

          <Layout.Content className={styles.layoutContent}>
            <Spin spinning={props.showSpinner} size="large">
              <div className={styles.mainWrapper}>
                {!props.userAuthenticated ||
                (props.selectedAccount.role === Role.AGENT && props.enumsLastRefreshTime) ||
                props.selectedAccount.role === Role.CLIENT ? (
                  !props.userAuthenticated || props.selectedAccount.role === Role.AGENT ? (
                    <>
                      <BreadcrumbsBox />
                      {agentRoleRoutes}
                    </>
                  ) : (
                    clientRoleRoutes
                  )
                ) : (
                  <>
                    <Skeleton active paragraph={{ rows: 6 }} />
                    <Skeleton active paragraph={{ rows: 3 }} />
                    <Skeleton active paragraph={{ rows: 2 }} />
                  </>
                )}
              </div>
            </Spin>
          </Layout.Content>

          <Footer />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  userAuthenticated: selectIsUserAuthenticated(state),
  selectedAccount: selectSelectedAccount(state),
  showSpinner: selectHasActiveRequest(state),
  enumsLastRefreshTime: selectEnumsLastRefreshTime(state),
  branding: selectBrandingPersistData(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      checkAuthState: checkAuthStateAction,
      refreshEnumerations: refreshEnumerationsAction,
      filterHeaderNotifications: filterHeaderNotificationsActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(App);
