import { Col, Row, Statistic } from "antd";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../../../app/i18n";
import CopyToClipboardView from "../../../../../../../common/components/views/CopyToClipboardView";
import ItemCreatedUpdatedInfoView from "../../../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { PageHeader } from "../../../../../../../common/components/views/PageHeader";
import { Permission } from "../../../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../../../common/types";
import { formatProductName } from "../../../../../../../common/utils/formatUtils";
import { threeSpacedStringNormalizeFunction } from "../../../../../../../common/utils/formUtils";
import { isDefinedValue } from "../../../../../../../common/utils/utils";
import { selectHasPermissions, selectUserAccount } from "../../../../../../auth/ducks";
import { ClientsListDrawerView } from "../../../../../../client/components/drawers/ClientsListDrawerView";
import { ClientFormType } from "../../../../../../client/enums";
import { Client } from "../../../../../../client/types";
import { selectInstitutionsEnums } from "../../../../../../enumerations/ducks";
import { InstitutionWithSettings } from "../../../../../../enumerations/types";
import { UserAccount } from "../../../../../../user/types";
import {
  changeContractVerificationStatusActions,
  deleteContractActions,
  getContractExternalViewActions
} from "../../../../../ducks";
import { ContractVerificationStatus } from "../../../../../enums";
import { LoanContract } from "../../../../../types";
import { isAgentOnContract } from "../../../../../utils";
import ContractStatusTag from "../../../../ContractStatusTag";
import ContractVerificationStatusTag from "../../../../ContractVerificationStatusTag";
import ContractChangeVerificationStatusForm from "../../../../forms/ContractChangeVerificationStatusForm";
import ContractHeaderActionsView from "../../ContractHeaderActionsView";

interface Props {
  contract: LoanContract;
  onVerificationStatusChange: typeof changeContractVerificationStatusActions.request;
  onExternalViewGet: typeof getContractExternalViewActions.request;
  onDelete: typeof deleteContractActions.request;
  onUpdateClick: () => void;
}

const LoanContractHeaderSection = ({ contract, ...props }: Props) => {
  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);
  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);
  const hasVerificationStatusChangePermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.LOAN_VERIFICATION_STATUS_CHANGE)(state)
  );

  const [verificationStatusFormOpen, setVerificationStatusFormOpen] = useState<boolean>(false);

  const agentIsOnContract = useMemo(
    () => userAccount && isAgentOnContract(userAccount, contract),
    [userAccount, contract]
  );

  const getTags = () => {
    const tags = [<ContractStatusTag key={1} status={contract.status} />];

    if (contract.verificationStatus) {
      tags.push(
        <ContractVerificationStatusTag
          key={2}
          status={contract.verificationStatus}
          note={contract.verificationNote}
          onNoteChangeClick={
            hasVerificationStatusChangePermission ||
            (agentIsOnContract && contract.verificationStatus === ContractVerificationStatus.SENT)
              ? () => setVerificationStatusFormOpen(true)
              : undefined
          }
        />
      );
    }

    return tags;
  };

  return (
    <>
      <PageHeader
        title={t("contract.sections.loanContract")}
        tags={getTags()}
        extra={
          <ContractHeaderActionsView
            contract={contract}
            updatePermission={Permission.LOAN_UPDATE}
            deletePermission={Permission.LOAN_DELETE}
            hasVerificationStatusChangePermission={hasVerificationStatusChangePermission}
            onVerificationStatusChange={props.onVerificationStatusChange}
            onExternalViewGet={props.onExternalViewGet}
            onDelete={props.onDelete}
            onUpdateClick={props.onUpdateClick}
          />
        }
      >
        <ItemCreatedUpdatedInfoView item={contract} style={{ marginTop: "-15px" }} className="margin-bottom-small" />

        <Row>
          <Col>
            <Statistic
              className="statistic-small"
              title={t("contract.attrs.contractNumber")}
              value={contract.contractNumber}
              formatter={value => (
                <CopyToClipboardView
                  content={value as string}
                  wrapper={threeSpacedStringNormalizeFunction(value as string)}
                />
              )}
            />
          </Col>

          <Col className="margin-left-medium">
            <Statistic
              className="statistic-small"
              title={t("contract.attrs.loanNumber")}
              value={contract.secondaryContractNumber}
              formatter={value => (
                <CopyToClipboardView
                  content={value as string}
                  wrapper={threeSpacedStringNormalizeFunction(value as string)}
                />
              )}
            />
          </Col>

          <Col className="margin-left-medium">
            <Statistic
              className="statistic-small"
              title={t("common.bankInstitution")}
              value={
                contract.institution.name +
                (institutionsEnums.find(i => i.id === contract.institution.id)?.settings.deactivated
                  ? ` (${t("institution.helpers.deactivated")})`
                  : "")
              }
            />
          </Col>

          <Col className="margin-left-medium">
            <Statistic
              className="statistic-small"
              title={t("contract.attrs.product")}
              value={formatProductName(contract.product)}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <h3 className="margin-top-medium">{t("contract.sections.clients")}</h3>
            <ClientsListDrawerView
              clientsData={[
                {
                  client: contract.clients[contract.policyHolderIndex] as Client,
                  clientFormTypes: [ClientFormType.POLICY_HOLDER],
                  clientPolicyHolderType: "loan"
                },
                ...(isDefinedValue(contract.coDebtorIndex)
                  ? [
                      {
                        client: contract.clients[contract.coDebtorIndex] as Client,
                        clientFormTypes: [ClientFormType.CO_DEBTOR]
                      }
                    ]
                  : [])
              ]}
            />
          </Col>
        </Row>
      </PageHeader>

      <ContractChangeVerificationStatusForm
        open={verificationStatusFormOpen}
        contract={contract}
        viewType="noteEdit"
        onSubmit={props.onVerificationStatusChange}
        onFormCancel={() => setVerificationStatusFormOpen(false)}
      />
    </>
  );
};

export default React.memo(LoanContractHeaderSection);
