import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ExportFileType } from "../../../../common/enums";
import { ActionProps, RootState } from "../../../../common/types";
import { appendSearchParamsToURL } from "../../../../common/utils/utils";
import type { UUID } from "../../../../typings/global";
import { selectRouterLocationSearch } from "../../../ducks";
import PointsReportFilterView from "../components/PointsReportFilterView";
import PointsReportTableView from "../components/PointsReportTableView";
import {
  deleteStatePointsReportAction,
  downloadPointsReportExportActions,
  filterPointsReportActions,
  selectPointsReport
} from "../ducks";
import { PointsReportType } from "../enums";
import { PointsReportFilterRequest, PointsReportFilterResult } from "../types";

interface StateProps {
  urlSearchQuery: string;
  report: PointsReportFilterResult;
}

interface ActionsMap {
  filterPointsReport: typeof filterPointsReportActions.request;
  downloadPointsReportExport: typeof downloadPointsReportExportActions.request;
  deleteStatePointsReport: typeof deleteStatePointsReportAction;
}

const PointsReportContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(props.urlSearchQuery);
    props.actions.filterPointsReport({
      periodStartDate: urlParams.get("periodStartDate") ?? undefined,
      periodEndDate: urlParams.get("periodEndDate") ?? undefined,
      institutionIds: urlParams.getAll("institutionIds"),
      productIds: urlParams.getAll("productIds"),
      commissionsSettingsLevelIds: urlParams.getAll("commissionsSettingsLevelIds"),
      agentCreatedAtDateMin: urlParams.get("agentCreatedAtDateMin") ?? undefined,
      reportType: (urlParams.get("reportType") as PointsReportType) ?? undefined,
      rootAgentId: urlParams.get("rootAgentId") as UUID,
      onlyDirectSubordinates: urlParams.get("onlyDirectSubordinates") === "true" || undefined,
      includeDeactivated: urlParams.get("includeDeactivated") === "true" || undefined,
      includeRepresentatives: urlParams.get("includeRepresentatives") === "true" || undefined,
      includeNonGainers: urlParams.get("includeNonGainers") === "true" || undefined,
      includeWithoutPoints: urlParams.get("includeWithoutPoints") === "true" || undefined
    });
    return () => {
      props.actions.deleteStatePointsReport();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSubmit = (filter: PointsReportFilterRequest): void => {
    const urlParams = {
      ...filter,
      reportType: filter.reportType !== PointsReportType.NETTO_AND_BRUTTO_POINTS ? filter.reportType : undefined,
      onlyDirectSubordinates: filter.onlyDirectSubordinates || undefined,
      includeDeactivated: filter.includeDeactivated || undefined,
      includeRepresentatives: filter.includeRepresentatives || undefined,
      includeNonGainers: filter.includeNonGainers || undefined,
      includeWithoutPoints: filter.includeWithoutPoints || undefined
    } as PointsReportFilterRequest;

    navigate(appendSearchParamsToURL(urlParams as Record<string, any>), { replace: true });
    props.actions.filterPointsReport(urlParams);
  };

  const handleReportExportClick = (exportFileType: ExportFileType): void => {
    const {
      periodStartDate,
      periodEndDate,
      institutionIds,
      productIds,
      commissionsSettingsLevelIds,
      agentCreatedAtDateMin,
      reportType,
      rootAgentId,
      onlyDirectSubordinates,
      includeDeactivated,
      includeRepresentatives,
      includeNonGainers,
      includeWithoutPoints
    } = props.report;

    props.actions.downloadPointsReportExport({
      periodStartDate,
      periodEndDate,
      institutionIds,
      productIds,
      commissionsSettingsLevelIds,
      agentCreatedAtDateMin,
      reportType,
      rootAgentId,
      onlyDirectSubordinates,
      includeDeactivated,
      includeRepresentatives,
      includeNonGainers,
      includeWithoutPoints: includeWithoutPoints,
      exportFileType
    });
  };

  return (
    <>
      <PointsReportFilterView filter={props.report} onFilterSubmit={handleFilterSubmit} />
      <PointsReportTableView report={props.report} onExportClick={handleReportExportClick} />
    </>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  urlSearchQuery: selectRouterLocationSearch(state),
  report: selectPointsReport(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterPointsReport: filterPointsReportActions.request,
      downloadPointsReportExport: downloadPointsReportExportActions.request,
      deleteStatePointsReport: deleteStatePointsReportAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PointsReportContainer);
