import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import apiService from "../../../common/api/apiService";
import { EntityIdObject, EntityObject } from "../../../common/types";
import {
  CalcBlacklist,
  CalcBlacklistFilterPageRequest,
  CalcBlacklistFilterPageResult,
  CreateUpdateCalcBlacklist
} from "./types";

const FILTER_CALC_BLACKLISTS = ApiRequestAdapter.create("/calculators/blacklists/filter", "POST");
const CREATE_CALC_BLACKLIST = ApiRequestAdapter.create("/calculators/blacklists", "POST");
const UPDATE_CALC_BLACKLIST = ApiRequestAdapter.create("/calculators/blacklists/{0}", "PUT");
const DELETE_CALC_BLACKLIST = ApiRequestAdapter.create("/calculators/blacklists/{0}", "DELETE");

export const requests = {
  FILTER_CALC_BLACKLISTS,
  CREATE_CALC_BLACKLIST,
  UPDATE_CALC_BLACKLIST,
  DELETE_CALC_BLACKLIST
};

const api = {
  filterCalcBlacklists: (request: CalcBlacklistFilterPageRequest): AxiosPromise<CalcBlacklistFilterPageResult> => {
    return apiService.post(FILTER_CALC_BLACKLISTS.url, request);
  },
  createCalcBlacklist: (request: CreateUpdateCalcBlacklist): AxiosPromise<CalcBlacklist> => {
    return apiService.post(CREATE_CALC_BLACKLIST.url, request);
  },
  updateCalcBlacklist: (request: EntityObject<CreateUpdateCalcBlacklist>): AxiosPromise<CalcBlacklist> => {
    return apiService.put(UPDATE_CALC_BLACKLIST.getParametrizedUrl(request.id), request.object);
  },
  deleteCalcBlacklist: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_CALC_BLACKLIST.getParametrizedUrl(request.id));
  }
};

export default api;
