import { Tag } from "antd";
import t from "../../../app/i18n";
import { FinancialMediationStatus } from "../enums";

interface Props {
  status: FinancialMediationStatus;
  style?: React.CSSProperties;
}

const FinancialMediationStatusTag = ({ status, style }: Props) => {
  let color;

  switch (status) {
    case FinancialMediationStatus.UNFINISHED:
      color = "volcano";
      break;
    case FinancialMediationStatus.FINISHED:
      color = "purple";
      break;
    case FinancialMediationStatus.ASSIGNED_TO_CONTRACT:
      color = "green";
      break;
    case FinancialMediationStatus.ASSIGNED_TO_CLIENT:
      color = "blue";
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} style={style}>
      {t("financialMediation.enums.status." + status)}
    </Tag>
  );
};

export default FinancialMediationStatusTag;
