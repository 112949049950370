import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import groupBy from "lodash/groupBy";
import { useSelector } from "react-redux";
import t, { DEFAULT_LOCALE } from "../../../../app/i18n";
import { RootState } from "../../../../common/types";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { InstitutionType } from "../../../institution/enums";
import { InstitutionBase } from "../../../institution/types";
import { selectInstitutionsEnums } from "../../ducks";
import { FilterFunction, InstitutionWithSettings } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  optionsProps?: InstitutionOptionsProps;
}

interface InstitutionOptionsProps {
  hideAll?: boolean;
  includeDeactivated?: boolean;
  selected?: InstitutionBase[];
  filterType?: InstitutionType;
  optGroups?: InstitutionOptGroupProps[];
  groupByType?: boolean;
  filter?: FilterFunction<InstitutionWithSettings>;
}

interface InstitutionOptGroupProps {
  label: string;
  filter: FilterFunction<InstitutionWithSettings>;
}

const InstitutionSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {
  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);

  const formatInstitutionName = (institution: InstitutionWithSettings): string | undefined => {
    return institution
      ? institution.settings?.deactivated
        ? `${institution.name} (${t("institution.helpers.deactivated")})`
        : institution.name
      : undefined;
  };

  const resolveInstitutionSelectOptions = (): InstitutionWithSettings[] => {
    if (optionsProps?.hideAll) {
      return [];
    }

    let options = [...institutionsEnums];

    if (optionsProps) {
      options = options.filter(institution => optionsProps.includeDeactivated || !institution.settings.deactivated);

      if (optionsProps.filterType) {
        options = options.filter(institution => institution.type === optionsProps.filterType);
      }

      if (optionsProps.filter) {
        options = options.filter(optionsProps.filter);
      }

      if (optionsProps.selected) {
        optionsProps.selected
          .filter(selectedInstitution =>
            optionsProps.filterType ? selectedInstitution.type === optionsProps.filterType : true
          )
          .forEach(selectedInstitution => {
            if (options.some(option => option.id === selectedInstitution.id)) {
              return;
            }

            const institutionWithSettings = institutionsEnums.find(
              institution => institution.id === selectedInstitution.id
            );

            if (institutionWithSettings) {
              options.unshift(institutionWithSettings);
            }
          });
      }
    } else {
      options = options.filter(institution => !institution.settings.deactivated);
    }

    return options.sort((a, b) => a.name.localeCompare(b.name, DEFAULT_LOCALE, { sensitivity: "accent" }));
  };

  const resolveInstitutionSelectGroupOptions = (
    filterOptions: FilterFunction<InstitutionWithSettings>
  ): InstitutionWithSettings[] => {
    let options = [...institutionsEnums];
    if (!optionsProps?.includeDeactivated) {
      options = options.filter(institution => !institution.settings.deactivated);
    }
    return options
      .filter(filterOptions)
      .sort((a, b) => a.name.localeCompare(b.name, DEFAULT_LOCALE, { sensitivity: "accent" }));
  };

  if (optionsProps?.optGroups) {
    return (
      <Form.Item {...formItemProps}>
        <Select {...selectStandardProps} {...selectProps}>
          {optionsProps.optGroups.map((group, index) => (
            <Select.OptGroup key={index} label={group.label}>
              {resolveInstitutionSelectGroupOptions(group.filter).map(i => (
                <Select.Option key={i.id} value={i.id}>
                  {formatInstitutionName(i)}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      </Form.Item>
    );
  } else if (optionsProps?.groupByType) {
    const institutionsGroups = groupBy(resolveInstitutionSelectOptions(), institution => institution.type);
    return (
      <Form.Item {...formItemProps}>
        <Select {...selectStandardProps} {...selectProps}>
          {Object.keys(institutionsGroups)
            .sort((a, b) => Object.keys(InstitutionType).indexOf(a) - Object.keys(InstitutionType).indexOf(b))
            .map((type, index) => (
              <Select.OptGroup label={t("institution.helpers.optGroup." + type)} key={index}>
                {institutionsGroups[type]?.map(i => (
                  <Select.Option key={i.id} value={i.id}>
                    {formatInstitutionName(i)}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
        </Select>
      </Form.Item>
    );
  }

  return (
    <Form.Item {...formItemProps}>
      <Select {...selectStandardProps} {...selectProps}>
        {resolveInstitutionSelectOptions().map(i => (
          <Select.Option key={i.id} value={i.id}>
            {formatInstitutionName(i)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default InstitutionSelect;
