import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import DeactivatedTag from "../../../../common/components/tags/DeactivatedTag";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { deleteComplicityActions } from "../../ducks";
import { Complicity, ComplicityFilterPageResult } from "../../types";

interface Props {
  complicitiesPage: ComplicityFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (complicity: Complicity) => void;
  onDelete: typeof deleteComplicityActions.request;
}

const ComplicityTableView = ({ complicitiesPage, onPageChange, onUpdateClick, onDelete }: Props) => {
  const columns: ColumnsType<Complicity> = [
    {
      key: "institution",
      title: t("common.insuranceInstitution"),
      width: 175,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institution.name}</Ellipsis>
    },
    {
      key: "value",
      title: t("complicity.attrs.value"),
      width: 210,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.value}</Ellipsis>
    },
    {
      key: "complicityEnum",
      title: t("complicity.enums.complicityEnum._label"),
      width: 235,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        record.complicityEnum ? (
          <Ellipsis>{t("complicity.enums.complicityEnum." + record.complicityEnum)}</Ellipsis>
        ) : null
    },
    {
      key: "deactivated",
      align: "center",
      width: 100,
      render: (_, record) => (record.deactivated ? <DeactivatedTag style={{ marginRight: 0 }} /> : null)
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("complicity.helpers.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => onDelete({ id: record.id })}
          >
            <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<Complicity>
        {...tableStandardProps()}
        columns={columns}
        dataSource={complicitiesPage.pageData}
        scroll={{ x: TableSizes.MEDIUM }}
        pagination={{
          ...paginationTableProps,
          current: complicitiesPage.pageIndex + 1,
          pageSize: complicitiesPage.pageSize,
          total: complicitiesPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </Card>
  );
};

export default ComplicityTableView;
