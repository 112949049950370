import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { SearchPageRequest, SearchPageResult } from "../../../../common/types";
import { validationConstants, validations } from "../../../../common/utils/validationUtils";
import { ProductGroup } from "../../types";

interface Props {
  currentFilter: SearchPageResult<ProductGroup>;
  onFilterSubmit: (filter: SearchPageRequest) => void;
  onCreateClick: () => void;
}

const ProductGroupFilterView = (props: Props) => {
  const [form] = Form.useForm<SearchPageRequest>();

  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: props.currentFilter.keyword
    });
  }, [props.currentFilter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("product.titles.productGroupsAdmin")}</h2>}
      extra={
        <Button type="primary" icon={<AntIcon type="plus" />} onClick={props.onCreateClick}>
          {t("product.actions.createProductGroup")}
        </Button>
      }
    >
      <Form form={form} onFinish={props.onFilterSubmit} layout="vertical" name="productGroupSearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.searchKeyword")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("product.helpers.productGroupSearchHint")} />
            </Form.Item>
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ProductGroupFilterView;
