import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import { RootState } from "../../../../common/types";
import { InstitutionBase } from "../../../institution/types";
import { selectInstitutionsEnums } from "../../ducks";
import { InstitutionWithSettings } from "../../types";

interface Props {
  institution: InstitutionBase;
  hideDeactivatedLabel?: boolean;
}

const InstitutionEnumName = ({ institution, hideDeactivatedLabel }: Props) => {
  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);

  return institution ? (
    !hideDeactivatedLabel && institutionsEnums.find(i => i.id === institution.id)?.settings?.deactivated ? (
      <span>{`${institution.name} (${t("institution.helpers.deactivated")})`}</span>
    ) : (
      <span>{institution.name}</span>
    )
  ) : null;
};

export default InstitutionEnumName;
