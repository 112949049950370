import { Col, Form, Input, Modal, Row, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { createUserReportedBugReportActions } from "../../ducks";
import { CreateUserReportedBug } from "../../types";

interface Props {
  open: boolean;
  onFormCancel: () => void;
}

const CreateUserBugReportForm = ({ open, onFormCancel }: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<CreateUserReportedBug>();
  const [fileList, setFileList] = useState<RcFile[]>([]);

  useFormErrorHandler(form, "bugReport.attrs", [requests.CREATE_USER_REPORTED_BUG_REPORT]);

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ createUserReportedBugReport: createUserReportedBugReportActions.request }, dispatch),
    [dispatch]
  );

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_USER_REPORTED_BUG_REPORT, requests.ADD_BUG_REPORT_ATTACHMENTS],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        const formData = new FormData();

        fileList.forEach(file => {
          formData.append("files", file);
        });

        actions.createUserReportedBugReport({
          ...values,
          files: formData
        });
      })
      .catch(resolveFormValidationError);
  };

  const handleFileAdd = (files: RcFile[]): boolean => {
    const allFiles = [...fileList, ...files];

    setFileList(
      allFiles.filter((file, index) => index === allFiles.findIndex(f => f.name === file.name && f.size === file.size))
    );

    return false;
  };

  const handleFileRemove = (file: UploadFile): boolean => {
    setFileList(fileList.filter(f => f.uid !== file.uid));
    return true;
  };

  const handleAfterClosed = (): void => {
    setFileList([]);
    form.resetFields();
  };

  const colSpan = 24;

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t("navigation.sideMenu.bugReport")}
      okText={t("common.send")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={handleAfterClosed}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="createUserBugReportForm">
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="title"
              label={t("bugReport.attrs.title")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="content"
              label={t("bugReport.attrs.content")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Upload
              multiple
              accept="image/*"
              fileList={fileList}
              beforeUpload={(_, fileList) => handleFileAdd(fileList)}
              onRemove={handleFileRemove}
            >
              <ActionButton icon="plus">{t("common.addAttachments")}</ActionButton>
            </Upload>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateUserBugReportForm;
