import { Col, Form, Input, Modal, Row } from "antd";
import t from "../../../../app/i18n";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { resolveFormValidationError } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { PartnerSsoType } from "../../enums";

interface Props {
  open: boolean;
  ssoType?: PartnerSsoType;
  onFormSubmit: (ssoType: PartnerSsoType, username: string) => void;
  onFormCancel: () => void;
}

interface SsoUsernameForm {
  username: string;
}

const PartnerSsoUsernameForm = ({ open, ssoType, onFormSubmit, onFormCancel }: Props) => {
  const [form] = Form.useForm<SsoUsernameForm>();

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (ssoType) {
          onFormSubmit(ssoType, values.username);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t("partnerSso.titles.usernameForm")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="partnerSsoUsernameForm">
        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="username"
              label={
                ssoType && t("partnerSso.attrs.username", { partnerName: t("partnerSso.enums.ssoType." + ssoType) })
              }
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PartnerSsoUsernameForm;
