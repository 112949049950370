export enum CoverageLimitEnum {
  ALLIANZ_645_130 = "ALLIANZ_645_130",
  ALLIANZ_645_300 = "ALLIANZ_645_300",
  ALLIANZ_1000_500 = "ALLIANZ_1000_500",
  CSOB_645_200 = "CSOB_645_200",
  CSOB_700_700 = "CSOB_700_700",
  GENERALI_645_130 = "GENERALI_645_130",
  GENERALI_645_600 = "GENERALI_645_600",
  GENERALI_1000_1000 = "GENERALI_1000_1000",
  KOMUNALNA_645_130 = "KOMUNALNA_645_130",
  KOMUNALNA_645_230 = "KOMUNALNA_645_230",
  KOOPERATIVA_645_130 = "KOOPERATIVA_645_130",
  KOOPERATIVA_645_230 = "KOOPERATIVA_645_230",
  UNION_645_130 = "UNION_645_130",
  UNION_645_500 = "UNION_645_500",
  UNIQA_645_130 = "UNIQA_645_130",
  UNIQA_700_200 = "UNIQA_700_200",
  UNIQA_700_600 = "UNIQA_700_600",
  UNIQA_800_800 = "UNIQA_800_800",
  WUSTENROT_645_130 = "WUSTENROT_645_130",
  WUSTENROT_800_800 = "WUSTENROT_800_800"
}
