import t from "../../app/i18n";

export enum DashboardContractChartViewType {
  ALL_AMOUNTS = "ALL_AMOUNTS",
  ALL_COUNTS = "ALL_COUNTS",
  OWN_AMOUNTS = "OWN_AMOUNTS",
  OWN_COUNTS = "OWN_COUNTS"
}

export enum DashboardNoticeType {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR"
}

export enum DashboardNoticePosition {
  VEHICLE_CALC = "VEHICLE_CALC",
  REALTY_CALC = "REALTY_CALC",
  TRAVEL_CALC = "TRAVEL_CALC"
}

export enum DashboardContactType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL"
}

export const dashboardNoticeTypeTMap = new Map<DashboardNoticeType, string>(
  Object.values(DashboardNoticeType).map(type => [type, t(`dashboard.notices.enums.type.${type}`)])
);

export const dashboardNoticePositionTMap = new Map<DashboardNoticePosition, string>(
  Object.values(DashboardNoticePosition).map(position => [position, t(`dashboard.notices.enums.position.${position}`)])
);
