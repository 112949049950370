import { Card, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import classNames from "classnames";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import BooleanRadioFormItem from "../../../../../common/components/form/components/BooleanRadioFormItem";
import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../common/constants";
import { inputNumberIntegerStandardProps, selectStandardProps } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { ProductFinancialSector } from "../../../../product/enums";
import { FinancialReserveLiquidity } from "../../../enums";
import { CreateUpdateFinancialMediation } from "../../../types";

interface Props {
  form: FormInstance<CreateUpdateFinancialMediation>;
  sector: ProductFinancialSector;
  showSecondaryClient: boolean;
  validateAllFields?: boolean;
}

const FinancialMediationFormClientDataSection = ({ form, sector, showSecondaryClient, ...props }: Props) => {
  const policyHolderRefuseFinancialInfo = Form.useWatch(
    ["mediationData", "policyHolderData", "refuseToProvideFinancialSituationInfo"],
    form
  );
  const secondaryClientRefuseFinancialInfo = Form.useWatch(
    ["mediationData", "secondaryClientData", "refuseToProvideFinancialSituationInfo"],
    form
  );

  const clientsProvideFinancialInfo =
    !policyHolderRefuseFinancialInfo || (showSecondaryClient && !secondaryClientRefuseFinancialInfo);
  const validatePolicyHolder = props.validateAllFields && !policyHolderRefuseFinancialInfo;
  const validateSecondaryClient = props.validateAllFields && !secondaryClientRefuseFinancialInfo;

  const amlFirstColSpan = 14;
  const amlOtherColSpan = 5;
  const financeColSpan = 4;

  const clientDataTPrefix = "financialMediation.attrs.mediationData.policyHolderData";
  const financialDataTPrefix = clientDataTPrefix + ".financialSituation";

  return (
    <Card type="inner" className="card-box" title={t("financialMediation.sections.clientsData")}>
      <Row gutter={rowGutter}>
        <Col span={16}>
          <Divider orientation="left" className="divider-subheader">
            {t(`${clientDataTPrefix}.amlAnswers`)}
          </Divider>

          <Row gutter={rowGutter}>
            <Col offset={amlFirstColSpan} span={amlOtherColSpan} className="center-align">
              <b>{t("financialMediation.attrs.policyHolder")}</b>
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              {showSecondaryClient && <b>{t("financialMediation.attrs.secondaryClient")}</b>}
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={amlFirstColSpan} className="form-item-required">
              {t("financialMediation.helpers.aml.q1")}
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "policyHolderData", "amlAnswers", 0],
                  rules: [validations.notNull],
                  className: "margin-bottom-small"
                }}
              />
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              {showSecondaryClient && (
                <BooleanRadioFormItem
                  formItemProps={{
                    name: ["mediationData", "secondaryClientData", "amlAnswers", 0],
                    rules: [validations.notNull],
                    className: "margin-bottom-small"
                  }}
                />
              )}
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={amlFirstColSpan} className="form-item-required">
              {t("financialMediation.helpers.aml.q2")}
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "policyHolderData", "amlAnswers", 1],
                  rules: [validations.notNull],
                  className: "margin-bottom-small"
                }}
              />
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              {showSecondaryClient && (
                <BooleanRadioFormItem
                  formItemProps={{
                    name: ["mediationData", "secondaryClientData", "amlAnswers", 1],
                    rules: [validations.notNull],
                    className: "margin-bottom-small"
                  }}
                />
              )}
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={amlFirstColSpan} className="form-item-required">
              <LabelWithPopover
                label={t("financialMediation.helpers.aml.q3")}
                popoverTitle={t("financialMediation.helpers.aml.q3desc.title")}
                popoverContent={
                  <div style={{ maxWidth: "550px", maxHeight: "450px", overflowY: "scroll" }}>
                    {[...Array(17)].map((_, index) => (
                      <p key={index}>{t(`financialMediation.helpers.aml.q3desc.p${index + 1}`)}</p>
                    ))}
                  </div>
                }
              />
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "policyHolderData", "amlAnswers", 2],
                  rules: [validations.notNull],
                  className: "margin-bottom-small"
                }}
              />
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              {showSecondaryClient && (
                <BooleanRadioFormItem
                  formItemProps={{
                    name: ["mediationData", "secondaryClientData", "amlAnswers", 2],
                    rules: [validations.notNull],
                    className: "margin-bottom-small"
                  }}
                />
              )}
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={amlFirstColSpan} className="form-item-required">
              <LabelWithPopover
                label={t("financialMediation.helpers.aml.q4")}
                popoverTitle={t("financialMediation.helpers.aml.q4desc.title")}
                popoverContent={
                  <div style={{ maxWidth: "400px" }}>{t("financialMediation.helpers.aml.q4desc.p1")}</div>
                }
              />
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "policyHolderData", "amlAnswers", 3],
                  rules: [validations.notNull],
                  className: "margin-bottom-small"
                }}
              />
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              {showSecondaryClient && (
                <BooleanRadioFormItem
                  formItemProps={{
                    name: ["mediationData", "secondaryClientData", "amlAnswers", 3],
                    rules: [validations.notNull],
                    className: "margin-bottom-small"
                  }}
                />
              )}
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={amlFirstColSpan} className="form-item-required">
              <LabelWithPopover
                label={t("financialMediation.helpers.aml.q5")}
                popoverTitle={t("financialMediation.helpers.aml.q5desc.title")}
                popoverContent={
                  <div style={{ maxWidth: "400px" }}>{t("financialMediation.helpers.aml.q5desc.p1")}</div>
                }
              />
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "policyHolderData", "amlAnswers", 4],
                  rules: [validations.notNull],
                  className: "margin-bottom-small"
                }}
              />
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              {showSecondaryClient && (
                <BooleanRadioFormItem
                  formItemProps={{
                    name: ["mediationData", "secondaryClientData", "amlAnswers", 4],
                    rules: [validations.notNull],
                    className: "margin-bottom-small"
                  }}
                />
              )}
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Divider orientation="left" className="divider-subheader">
            {t("financialMediation.attrs.mediationData.amlAgentStatement")}
          </Divider>

          <span className="sub-header-info form-item-required">
            {t(
              `financialMediation.helpers.aml.agentStatement${
                sector === ProductFinancialSector.NON_LIFE_INSURANCES
                  ? "1"
                  : sector === ProductFinancialSector.SENIOR_PENSION_SAVINGS ||
                      sector === ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS
                    ? "3"
                    : "2"
              }`
            )}
          </span>

          <Row gutter={rowGutter}>
            <Col span={24}>
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "amlAgentStatement"],
                  rules: [validations.notNull]
                }}
                useAgreementLabels
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={16}>
          <Divider orientation="left" className="divider-subheader">
            {t("financialMediation.sections.clientsAnotherData")}
          </Divider>

          <Row gutter={rowGutter}>
            <Col offset={amlFirstColSpan} span={amlOtherColSpan} className="center-align">
              <b>{t("financialMediation.attrs.policyHolder")}</b>
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              {showSecondaryClient && <b>{t("financialMediation.attrs.secondaryClient")}</b>}
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={amlFirstColSpan} className="form-item-required">
              {t(`${clientDataTPrefix}.mediationForPersonalUse`)}
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "policyHolderData", "mediationForPersonalUse"],
                  rules: [validations.notNull],
                  className: "margin-bottom-small"
                }}
              />
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              {showSecondaryClient && (
                <BooleanRadioFormItem
                  formItemProps={{
                    name: ["mediationData", "secondaryClientData", "mediationForPersonalUse"],
                    rules: [validations.notNull],
                    className: "margin-bottom-small"
                  }}
                />
              )}
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={amlFirstColSpan} className="form-item-required">
              {t(`${clientDataTPrefix}.identifiedAsProfessional`)}
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "policyHolderData", "identifiedAsProfessional"],
                  rules: [validations.notNull],
                  className: "margin-bottom-small"
                }}
              />
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              {showSecondaryClient && (
                <BooleanRadioFormItem
                  formItemProps={{
                    name: ["mediationData", "secondaryClientData", "identifiedAsProfessional"],
                    rules: [validations.notNull],
                    className: "margin-bottom-small"
                  }}
                />
              )}
            </Col>
          </Row>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.mediationData?.policyHolderData?.identifiedAsProfessional !==
                next.mediationData?.policyHolderData?.identifiedAsProfessional ||
              prev.mediationData?.secondaryClientData?.identifiedAsProfessional !==
                next.mediationData?.secondaryClientData?.identifiedAsProfessional
            }
          >
            {({ getFieldValue }) => {
              const policyHolderIsProfessional = !!getFieldValue([
                "mediationData",
                "policyHolderData",
                "identifiedAsProfessional"
              ]);
              const secondaryClientIsProfessional = !!getFieldValue([
                "mediationData",
                "secondaryClientData",
                "identifiedAsProfessional"
              ]);

              return policyHolderIsProfessional || secondaryClientIsProfessional ? (
                <Row gutter={rowGutter}>
                  <Col span={amlFirstColSpan} className="form-item-required">
                    {t(`${clientDataTPrefix}.consideredAsNonProfessional`)}
                  </Col>

                  <Col span={amlOtherColSpan} className="center-align">
                    {policyHolderIsProfessional && (
                      <BooleanRadioFormItem
                        formItemProps={{
                          name: ["mediationData", "policyHolderData", "consideredAsNonProfessional"],
                          rules: [validations.notNull],
                          className: "margin-bottom-small"
                        }}
                      />
                    )}
                  </Col>

                  <Col span={amlOtherColSpan} className="center-align">
                    {secondaryClientIsProfessional && showSecondaryClient && (
                      <BooleanRadioFormItem
                        formItemProps={{
                          name: ["mediationData", "secondaryClientData", "consideredAsNonProfessional"],
                          rules: [validations.notNull],
                          className: "margin-bottom-small"
                        }}
                      />
                    )}
                  </Col>
                </Row>
              ) : null;
            }}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={16}>
          <Divider orientation="left" className="divider-subheader">
            {t("financialMediation.sections.clientsConsents")}
          </Divider>

          <Row gutter={rowGutter}>
            <Col offset={amlFirstColSpan} span={amlOtherColSpan} className="center-align">
              <b>{t("financialMediation.attrs.policyHolder")}</b>
            </Col>

            <Col span={amlOtherColSpan} className="center-align">
              {showSecondaryClient && <b>{t("financialMediation.attrs.secondaryClient")}</b>}
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={amlFirstColSpan} className="form-item-required">
              <LabelWithPopover
                label={t(`${clientDataTPrefix}.personalDataProcessingConsent`)}
                popoverTitle={t("financialMediation.helpers.personalDataProcessingConsentDesc.title")}
                popoverContent={
                  <div style={{ maxWidth: "400px" }}>
                    {t("financialMediation.helpers.personalDataProcessingConsentDesc.p1")}
                  </div>
                }
              />
            </Col>

            <Col span={amlOtherColSpan}>
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "policyHolderData", "personalDataProcessingConsent"],
                  rules: [validations.notNull],
                  className: "margin-bottom-small"
                }}
              />
            </Col>

            <Col span={amlOtherColSpan}>
              {showSecondaryClient && (
                <BooleanRadioFormItem
                  formItemProps={{
                    name: ["mediationData", "secondaryClientData", "personalDataProcessingConsent"],
                    rules: [validations.notNull],
                    className: "margin-bottom-small"
                  }}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider orientation="left" className="divider-subheader">
        {t("financialMediation.sections.clientsFinancialSituation")}
      </Divider>

      <Row gutter={rowGutter}>
        <Col offset={financeColSpan} span={financeColSpan} className="center-align">
          <b>{t("financialMediation.attrs.policyHolder")}</b>
        </Col>

        <Col span={financeColSpan} className="center-align">
          {showSecondaryClient && <b>{t("financialMediation.attrs.secondaryClient")}</b>}
        </Col>

        <Col offset={financeColSpan} span={financeColSpan} className="center-align">
          <b>{t("financialMediation.attrs.policyHolder")}</b>
        </Col>

        <Col span={financeColSpan} className="center-align">
          {showSecondaryClient && <b>{t("financialMediation.attrs.secondaryClient")}</b>}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col
          span={financeColSpan}
          className={classNames("table-row-form-label", { "form-item-additional": clientsProvideFinancialInfo })}
        >
          {t(`${financialDataTPrefix}.profession`)}
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "profession"]}
            rules={[validations.conditionalRule(validations.notBlank, validatePolicyHolder), validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "profession"]}
              rules={[
                validations.conditionalRule(validations.notBlank, validateSecondaryClient),
                validations.size(1, 128)
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>

        <Col span={financeColSpan} className={classNames({ "form-item-additional": clientsProvideFinancialInfo })}>
          <LabelWithTooltip
            label={t(`${financialDataTPrefix}.regularExpenses`)}
            tooltip={t("financialMediation.helpers.financialSituation.regularExpensesDesc")}
          />
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "regularExpenses"]}
            rules={[validations.conditionalRule(validations.notNull, validatePolicyHolder), validations.minNumber(0)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "regularExpenses"]}
              rules={[
                validations.conditionalRule(validations.notNull, validateSecondaryClient),
                validations.minNumber(0)
              ]}
            >
              <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col
          span={financeColSpan}
          style={{ paddingRight: "0px" }}
          className={classNames("table-row-form-label", { "form-item-additional": clientsProvideFinancialInfo })}
        >
          <LabelWithTooltip
            label={t(`${financialDataTPrefix}.regularSourceOfIncome`)}
            tooltip={t("financialMediation.helpers.financialSituation.regularSourceOfIncomeDesc")}
          />
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "regularSourceOfIncome"]}
            rules={[validations.conditionalRule(validations.notBlank, validatePolicyHolder), validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "regularSourceOfIncome"]}
              rules={[
                validations.conditionalRule(validations.notBlank, validateSecondaryClient),
                validations.size(1, 128)
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>

        <Col span={financeColSpan} className={classNames({ "form-item-additional": clientsProvideFinancialInfo })}>
          {t(`${financialDataTPrefix}.otherExtraordinaryExpenses`)}
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "otherExtraordinaryExpenses"]}
            rules={[validations.conditionalRule(validations.notBlank, validatePolicyHolder), validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "otherExtraordinaryExpenses"]}
              rules={[
                validations.conditionalRule(validations.notBlank, validateSecondaryClient),
                validations.size(1, 128)
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={financeColSpan} className={classNames({ "form-item-additional": clientsProvideFinancialInfo })}>
          <LabelWithTooltip
            label={t(`${financialDataTPrefix}.irregularSourceOfIncome`)}
            tooltip={t("financialMediation.helpers.financialSituation.irregularSourceOfIncomeDesc")}
          />
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "irregularSourceOfIncome"]}
            rules={[validations.conditionalRule(validations.notBlank, validatePolicyHolder), validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "irregularSourceOfIncome"]}
              rules={[
                validations.conditionalRule(validations.notBlank, validateSecondaryClient),
                validations.size(1, 128)
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>

        <Col
          span={financeColSpan}
          className={classNames("table-row-form-label", { "form-item-additional": clientsProvideFinancialInfo })}
        >
          <LabelWithTooltip
            label={t(`${financialDataTPrefix}.existingObligations`)}
            tooltip={t("financialMediation.helpers.financialSituation.existingObligationsDesc")}
          />
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "existingObligations"]}
            rules={[validations.conditionalRule(validations.notBlank, validatePolicyHolder), validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "existingObligations"]}
              rules={[
                validations.conditionalRule(validations.notBlank, validateSecondaryClient),
                validations.size(1, 128)
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={financeColSpan} className={classNames({ "form-item-additional": clientsProvideFinancialInfo })}>
          {t(`${financialDataTPrefix}.netRegularIncome`)}
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "netRegularIncome"]}
            rules={[validations.conditionalRule(validations.notNull, validatePolicyHolder), validations.minNumber(0)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "netRegularIncome"]}
              rules={[
                validations.conditionalRule(validations.notNull, validateSecondaryClient),
                validations.minNumber(0)
              ]}
            >
              <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
            </Form.Item>
          )}
        </Col>

        <Col span={financeColSpan} className={classNames({ "form-item-additional": clientsProvideFinancialInfo })}>
          {t(`${financialDataTPrefix}.loanPayments`)}
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "loanPayments"]}
            rules={[validations.conditionalRule(validations.notNull, validatePolicyHolder), validations.minNumber(0)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "loanPayments"]}
              rules={[
                validations.conditionalRule(validations.notNull, validateSecondaryClient),
                validations.minNumber(0)
              ]}
            >
              <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={financeColSpan} className={classNames({ "form-item-additional": clientsProvideFinancialInfo })}>
          {t(`${financialDataTPrefix}.netIrregularIncome`)}
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "netIrregularIncome"]}
            rules={[validations.conditionalRule(validations.notNull, validatePolicyHolder), validations.minNumber(0)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "netIrregularIncome"]}
              rules={[
                validations.conditionalRule(validations.notNull, validateSecondaryClient),
                validations.minNumber(0)
              ]}
            >
              <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
            </Form.Item>
          )}
        </Col>

        <Col
          span={financeColSpan}
          className={classNames("table-row-form-label", { "form-item-additional": clientsProvideFinancialInfo })}
        >
          {t(`${financialDataTPrefix}.childSupportObligation`)}
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "childSupportObligation"]}
            rules={[validations.conditionalRule(validations.notBlank, validatePolicyHolder), validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "childSupportObligation"]}
              rules={[
                validations.conditionalRule(validations.notBlank, validateSecondaryClient),
                validations.size(1, 128)
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col
          span={financeColSpan}
          style={{ paddingRight: "0px" }}
          className={classNames("table-row-form-label", { "form-item-additional": clientsProvideFinancialInfo })}
        >
          <LabelWithTooltip
            label={t(`${financialDataTPrefix}.expectedIncomeReduction`)}
            tooltip={t("financialMediation.helpers.financialSituation.expectedIncomeReductionDesc")}
          />
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "expectedIncomeReduction"]}
            rules={[validations.conditionalRule(validations.notBlank, validatePolicyHolder), validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "expectedIncomeReduction"]}
              rules={[
                validations.conditionalRule(validations.notBlank, validateSecondaryClient),
                validations.size(1, 128)
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>

        <Col
          span={financeColSpan}
          className={classNames("table-row-form-label", { "form-item-additional": clientsProvideFinancialInfo })}
        >
          {t(`${financialDataTPrefix}.dependentChildrenCount`)}
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "dependentChildrenCount"]}
            rules={[validations.conditionalRule(validations.notNull, validatePolicyHolder), validations.minNumber(0)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "dependentChildrenCount"]}
              rules={[
                validations.conditionalRule(validations.notNull, validateSecondaryClient),
                validations.minNumber(0)
              ]}
            >
              <InputNumber {...inputNumberIntegerStandardProps} />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col
          span={financeColSpan}
          className={classNames("table-row-form-label", { "form-item-additional": clientsProvideFinancialInfo })}
        >
          {t(`${financialDataTPrefix}.maritalStatus`)}
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "maritalStatus"]}
            rules={[validations.conditionalRule(validations.notBlank, validatePolicyHolder), validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "maritalStatus"]}
              rules={[
                validations.conditionalRule(validations.notBlank, validateSecondaryClient),
                validations.size(1, 128)
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>

        <Col
          span={financeColSpan}
          style={{ paddingRight: "0px" }}
          className={classNames("table-row-form-label", { "form-item-additional": clientsProvideFinancialInfo })}
        >
          <LabelWithTooltip
            label={t("financialMediation.enums.reserveLiquidity._label")}
            tooltip={t("financialMediation.helpers.financialSituation.financialReserveLiquidityDesc")}
          />
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "reserveLiquidity"]}
            rules={[validations.conditionalRule(validations.notNull, validatePolicyHolder)]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(FinancialReserveLiquidity).map(liquidity => ({
                value: liquidity,
                label: t("financialMediation.enums.reserveLiquidity." + liquidity)
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "reserveLiquidity"]}
              rules={[validations.conditionalRule(validations.notNull, validateSecondaryClient)]}
            >
              <Select
                {...selectStandardProps}
                options={Object.keys(FinancialReserveLiquidity).map(liquidity => ({
                  value: liquidity,
                  label: t("financialMediation.enums.reserveLiquidity." + liquidity)
                }))}
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={financeColSpan} className={classNames({ "form-item-additional": clientsProvideFinancialInfo })}>
          {t(`${financialDataTPrefix}.otherInfoAboutClient`)}
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "otherInfoAboutClient"]}
            rules={[validations.conditionalRule(validations.notBlank, validatePolicyHolder), validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "otherInfoAboutClient"]}
              rules={[
                validations.conditionalRule(validations.notBlank, validateSecondaryClient),
                validations.size(1, 128)
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>

        <Col span={financeColSpan} className={classNames({ "form-item-additional": clientsProvideFinancialInfo })}>
          {t(`${financialDataTPrefix}.otherInfoRelevantForMediation`)}
        </Col>

        <Col span={financeColSpan}>
          <Form.Item
            name={["mediationData", "policyHolderData", "financialSituation", "otherInfoRelevantForMediation"]}
            rules={[validations.conditionalRule(validations.notBlank, validatePolicyHolder), validations.size(1, 128)]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={financeColSpan}>
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "financialSituation", "otherInfoRelevantForMediation"]}
              rules={[
                validations.conditionalRule(validations.notBlank, validateSecondaryClient),
                validations.size(1, 128)
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={rowGutter} className="margin-top-medium">
        <Col offset={12} span={2} className="center-align">
          <b>{t("financialMediation.attrs.policyHolder")}</b>
        </Col>

        <Col span={2} className="center-align">
          {showSecondaryClient && <b>{t("financialMediation.attrs.secondaryClient")}</b>}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={12}>
          <LabelWithTooltip
            label={t("financialMediation.helpers.financialSituation.refuseToProvideInfoLabel")}
            tooltip={t("financialMediation.helpers.financialSituation.refuseToProvideInfoDesc")}
          />
        </Col>

        <Col span={2} className="center-align">
          <Form.Item
            name={["mediationData", "policyHolderData", "refuseToProvideFinancialSituationInfo"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox />
          </Form.Item>
        </Col>

        <Col span={2} className="center-align">
          {showSecondaryClient && (
            <Form.Item
              name={["mediationData", "secondaryClientData", "refuseToProvideFinancialSituationInfo"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox />
            </Form.Item>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default FinancialMediationFormClientDataSection;
