import { Col, Modal, Row } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import t from "../../../../../app/i18n";
import { SecondaryButton } from "../../../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { ModalSizes } from "../../../../../common/constants";
import { formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { saveTextFile } from "../../../../../common/utils/utils";
import { CalcRecord } from "../../types";

interface Props {
  open: boolean;
  record?: CalcRecord;
  onCancelClick: () => void;
  onAfterClose: () => void;
}

const CalcRecordRequestResponseLogsModal = ({ open, record, onCancelClick, onAfterClose }: Props) => {
  const handleLogFileSave = (content: string, type: "request" | "response"): void => {
    saveTextFile(content, createLogsTitle() + " _ " + t("common." + type));
  };

  const handleCopyInfoMessageDisplay = (): void => {
    messageUtils.infoMessage(t("common.clipboardCopied"));
  };

  const createLogsTitle = (): string => {
    return t("calc.records.titles.logs") + ` - ${record?.user.name} / ` + formatLocaleDateTime(record?.createdAt);
  };

  if (record && record.request && record.response) {
    const requestString = JSON.stringify(record.request, null, 2);
    const responseString = JSON.stringify(record.response, null, 2);

    return (
      <Modal
        open={open}
        title={createLogsTitle()}
        width={ModalSizes.LARGE}
        centered
        onCancel={onCancelClick}
        afterClose={onAfterClose}
        footer={null}
      >
        <Row gutter={16}>
          <Col span={12}>
            <h3>{t("common.request")}</h3>

            <pre className="modal-code-block">{requestString}</pre>

            <div className="center-align margin-top-small">
              <span className="margin-right-medium">
                <SecondaryButton
                  icon={<AntIcon type="download" />}
                  onClick={() => handleLogFileSave(requestString, "request")}
                >
                  {t("common.download")}
                </SecondaryButton>
              </span>

              <CopyToClipboard text={requestString} onCopy={handleCopyInfoMessageDisplay}>
                <SecondaryButton icon={<AntIcon type="copy" />}>{t("common.copy")}</SecondaryButton>
              </CopyToClipboard>
            </div>
          </Col>

          <Col span={12}>
            <h3>{t("common.response")}</h3>

            <pre className="modal-code-block">{responseString}</pre>

            <div className="center-align margin-top-small">
              <span className="margin-right-medium">
                <SecondaryButton
                  icon={<AntIcon type="download" />}
                  onClick={() => handleLogFileSave(responseString, "response")}
                >
                  {t("common.download")}
                </SecondaryButton>
              </span>

              <CopyToClipboard text={responseString} onCopy={handleCopyInfoMessageDisplay}>
                <SecondaryButton icon={<AntIcon type="copy" />}>{t("common.copy")}</SecondaryButton>
              </CopyToClipboard>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }

  return null;
};

export default CalcRecordRequestResponseLogsModal;
