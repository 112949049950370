import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import apiService from "../../../common/api/apiService";
import { EntityIdObject, EntityObject, TwoLevelEntityIdObject } from "../../../common/types";
import { AttachmentConf, CalcSettings, CalcSettingsRequest, CreateUpdateCalcSettings } from "./types";

const LIST_CALC_SETTINGS = ApiRequestAdapter.create("/calculators/settings", "GET");
const GET_CALC_SETTINGS = ApiRequestAdapter.create("/calculators/settings/{0}", "GET");
const CREATE_CALC_SETTINGS = ApiRequestAdapter.create("/calculators/settings", "POST");
const UPDATE_CALC_SETTINGS = ApiRequestAdapter.create("/calculators/settings/{0}", "PUT");
const DELETE_CALC_SETTINGS = ApiRequestAdapter.create("/calculators/settings/{0}", "DELETE");
const UPLOAD_ATTACHMENT_CONFS = ApiRequestAdapter.create("/calculators/settings/{0}/attachment-confs", "POST");
const DOWNLOAD_ATTACHMENT_CONF = ApiRequestAdapter.create("/calculators/settings/{0}/attachment-confs/{1}", "GET");
const DELETE_ATTACHMENT_CONF = ApiRequestAdapter.create("/calculators/settings/{0}/attachment-confs/{1}", "DELETE");

export const requests = {
  LIST_CALC_SETTINGS,
  GET_CALC_SETTINGS,
  CREATE_CALC_SETTINGS,
  UPDATE_CALC_SETTINGS,
  DELETE_CALC_SETTINGS,
  UPLOAD_ATTACHMENT_CONFS,
  DOWNLOAD_ATTACHMENT_CONF,
  DELETE_ATTACHMENT_CONF
};

const api = {
  listCalcSettings: (request: CalcSettingsRequest): AxiosPromise<CalcSettings[]> => {
    return apiService.get(LIST_CALC_SETTINGS.url, { params: request });
  },
  getCalcSettings: (request: EntityIdObject): AxiosPromise<CalcSettings> => {
    return apiService.get(GET_CALC_SETTINGS.getParametrizedUrl(request.id));
  },
  createCalcSettings: (request: CreateUpdateCalcSettings): AxiosPromise<CalcSettings> => {
    return apiService.post(CREATE_CALC_SETTINGS.url, request);
  },
  updateCalcSettings: (request: EntityObject<CreateUpdateCalcSettings>): AxiosPromise<CalcSettings> => {
    return apiService.put(UPDATE_CALC_SETTINGS.getParametrizedUrl(request.id), request.object);
  },
  deleteCalcSettings: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_CALC_SETTINGS.getParametrizedUrl(request.id));
  },
  uploadAttachmentConfs: (request: EntityObject<FormData>): AxiosPromise<AttachmentConf[]> => {
    return apiService.post(UPLOAD_ATTACHMENT_CONFS.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  downloadAttachmentConf: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_ATTACHMENT_CONF.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  },
  deleteAttachmentConf: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_ATTACHMENT_CONF.getParametrizedUrl(request.id1, request.id2));
  }
};

export default api;
