import { Alert, Col, DatePicker, Form, Modal, Row, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import { accessTreePathDelimiter, ModalSizes, rowGutter } from "../../../../../common/constants";
import { RootState } from "../../../../../common/types";
import { formatLocaleCurrency } from "../../../../../common/utils/formatUtils";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerPast,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import type { UUID } from "../../../../../typings/global";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import { selectSubordinateAgentsEnums } from "../../../../enumerations/ducks";
import { AgentEnumeration } from "../../../../enumerations/types";
import { requests } from "../../api";
import {
  calculateCommissionsBatchPaymentToBePaidActions,
  deleteStateCommissionsBatchPaymentToBePaidAction,
  generateCommissionsBatchPaymentOrderActions
} from "../../ducks";
import { CommissionsBatchAttachmentType, CommissionsBatchStep } from "../../enums";
import { CommissionsBatch, CommissionsBatchOutputAttachment, GenerateBatchPaymentOrder } from "../../types";

interface Props {
  batch: CommissionsBatch;
  paymentToBePaid?: number;
  open: boolean;
  onCalculatePaymentToBePaid: typeof calculateCommissionsBatchPaymentToBePaidActions.request;
  onDeleteStatePaymentToBePaid: typeof deleteStateCommissionsBatchPaymentToBePaidAction;
  onGenerate: typeof generateCommissionsBatchPaymentOrderActions.request;
  onFormCancel: () => void;
}

const CommissionsBatchPaymentOrderForm = ({
  batch,
  paymentToBePaid,
  open,
  onGenerate,
  onCalculatePaymentToBePaid,
  onDeleteStatePaymentToBePaid,
  onFormCancel
}: Props) => {
  const agentsEnums = useSelector<RootState, AgentEnumeration[]>(selectSubordinateAgentsEnums);

  const [form] = Form.useForm<GenerateBatchPaymentOrder>();
  useFormErrorHandler(form, "commissions.batch.attrs.paymentOrder", [
    requests.GENERATE_COMMISSIONS_BATCH_PAYMENT_ORDER
  ]);

  const [excludedGainersDropdownVisible, setExcludedGainersDropdownVisible] = useState<boolean>(false);

  const calculatePaymentToBePaidInProgress = useRequestFinishedCallback([
    requests.CALCULATE_COMMISSIONS_BATCH_PAYMENT_TO_BE_PAID
  ]);

  const handleFormCancel = (): void => {
    onFormCancel();
    onDeleteStatePaymentToBePaid();
  };

  const inProgress = useRequestFinishedCallback([requests.GENERATE_COMMISSIONS_BATCH_PAYMENT_ORDER], handleFormCancel);

  const allGainersIds = useMemo(
    () =>
      (
        batch.attachments.filter(attachment => {
          const path = (attachment as CommissionsBatchOutputAttachment).gainer?.accessTreePath?.split(
            accessTreePathDelimiter
          );
          return attachment.type === CommissionsBatchAttachmentType.OUTPUT && path && path.length > 1;
        }) as CommissionsBatchOutputAttachment[]
      ).map(attachment => attachment.gainer?.id),
    [batch]
  );

  useEffect(() => {
    if (open) {
      const excludedGainerIds = agentsEnums
        .filter(agent => !agent.includeInCommissionsBulkPayment && allGainersIds?.includes(agent.id))
        .map(agent => agent.id);
      form.setFieldsValue({ excludedGainerIds });
      onCalculatePaymentToBePaid({ id: batch.id, object: { excludedGainerIds } });
    }
  }, [open, form, agentsEnums, allGainersIds, batch.id, onCalculatePaymentToBePaid]);

  const handleExcludedGainersVisibleChange = (visible: boolean): void => {
    setExcludedGainersDropdownVisible(visible);
    handleExcludedGainersChange(visible);
  };

  const handleExcludedGainersChange = (dropdownVisible: boolean): void => {
    if (!dropdownVisible) {
      const excludedGainerIds = form.getFieldValue("excludedGainerIds") as UUID[];
      onCalculatePaymentToBePaid({ id: batch.id, object: { excludedGainerIds } });
    }
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values =>
        onGenerate({ id: batch.id, object: { ...values, excludedGainerIds: values.excludedGainerIds || [] } })
      )
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={open}
      title={t("commissions.batch.titles.generatePaymentOrder")}
      okText={t("common.download")}
      okButtonProps={{ disabled: batch.step !== CommissionsBatchStep.FINISH }}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}
    >
      <Form form={form} layout="vertical" name="commissionsBatchPaymentOrderForm">
        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="paymentExecutionDate"
              label={t("commissions.batch.attrs.paymentOrder.paymentExecutionDate")}
              rules={[validations.notNull, validations.notPast]}
              {...datePickerFormItemProps}
            >
              <DatePicker
                {...datePickerStandardProps}
                disabledDate={disableDatePickerPast}
                disabled={batch.step !== CommissionsBatchStep.FINISH}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <AgentSelect
              formItemProps={{
                name: "excludedGainerIds",
                label: t("commissions.batch.attrs.paymentOrder.excludedGainerIds"),
                rules: [validations.none]
              }}
              selectProps={{
                mode: "multiple",
                maxTagCount: "responsive",
                allowClear: true,
                onDropdownVisibleChange: handleExcludedGainersVisibleChange,
                onChange: () => handleExcludedGainersChange(excludedGainersDropdownVisible)
              }}
              optionsProps={{
                groupByBulkPaymentInclusion: true,
                filter: agent => allGainersIds.includes(agent.id)
              }}
            />
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Spin spinning={calculatePaymentToBePaidInProgress}>
              <Alert
                type="info"
                message={
                  <>
                    {t("commissions.batch.helpers.paymentToBePaid")}: <b>{formatLocaleCurrency(paymentToBePaid)}</b>
                  </>
                }
              />
            </Spin>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CommissionsBatchPaymentOrderForm;
