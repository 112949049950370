import { Col, Divider, List, Row, Tooltip } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import notificationIcon from "../../../../assets/images/notification.svg";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import { fromNow } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { requests } from "../../../../modules/notifications/api";
import BugReportNotificationView from "../../../../modules/notifications/components/views/BugReportNotificationView";
import {
  FILTER_NOTIFICATIONS_DEFAULT_REQUEST,
  filterHeaderNotificationsActions,
  markAllHeaderNotificationsAsSeenActions,
  toggleHeaderNotificationSentAndSeenStatusActions
} from "../../../../modules/notifications/ducks";
import { NotificationStatus, NotificationTopic } from "../../../../modules/notifications/enums";
import { NOTIFICATION_ROUTE_PATHS } from "../../../../modules/notifications/paths";
import { Notification, NotificationsHeaderList } from "../../../../modules/notifications/types";
import { openNotificationTopicPage } from "../../../../modules/notifications/utils";
import { CURRENT_USER_ROUTE_PATHS } from "../../../../modules/user/paths";
import t from "../../../i18n";
import styles from "./HeaderNotificationDropdown.module.scss";

interface Props {
  notificationsList: NotificationsHeaderList;
  onDropdownClose: () => void;
}

const HeaderNotificationDropdown = ({ notificationsList, onDropdownClose }: Props) => {
  const [scrollToBottomCaptured, setScrollToBottomCaptured] = useState<boolean>(false);
  const [selectedBugReportNotification, setSelectedBugReportNotification] = useState<Notification>();

  useEffect(() => {
    setScrollToBottomCaptured(false);
  }, [notificationsList]);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onFilter: filterHeaderNotificationsActions.request,
          onMarkAllAsSeen: markAllHeaderNotificationsAsSeenActions.request,
          onToggleSentAndSeenStatus: toggleHeaderNotificationSentAndSeenStatusActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const inProgress = useRequestFinishedCallback([
    requests.FILTER_NOTIFICATIONS,
    requests.MARK_ALL_NOTIFICATIONS_AS_SEEN,
    requests.TOGGLE_NOTIFICATION_SENT_AND_SEEN_STATUS
  ]);

  const handleNotificationListScroll = (event: any): void => {
    if (!scrollToBottomCaptured && notificationsList.items.length < notificationsList.totalElementsCount) {
      const scrollHeight = event?.target?.scrollHeight;
      const scrollTop = event?.target?.scrollTop;
      const clientHeight = event?.target?.clientHeight;

      if (scrollHeight && scrollHeight - scrollTop < clientHeight + 350) {
        setScrollToBottomCaptured(true);
        actions.onFilter({
          pageIndex: notificationsList.items.length / notificationsList.basicPageSize,
          pageSize: notificationsList.basicPageSize
        });
      }
    }
  };

  const handleNotificationToggleClick = (notification: Notification): void => {
    actions.onToggleSentAndSeenStatus({ id: notification.id });
  };

  const handleNotificationClick = (notification: Notification): void => {
    if (notification.status === NotificationStatus.SENT) {
      actions.onToggleSentAndSeenStatus({ id: notification.id });
    }
    onDropdownClose();
    if (notification.data.topic === NotificationTopic.SYSADMIN_BUG_REPORT_CREATED) {
      setSelectedBugReportNotification(notification);
    } else {
      openNotificationTopicPage(notification);
    }
  };

  return (
    <div className={styles.headerNotificationDropdown}>
      <div className={styles.headerNotificationListHeader}>
        <Row>
          <Col span={20}>
            <Tooltip title={t("user.titles.notificationSettings")}>
              <span>
                <ActionTextIcon
                  icon="settings"
                  color="blue"
                  size="huge"
                  path={generatePath(CURRENT_USER_ROUTE_PATHS.profile.to) + "?tab=notification-settings"}
                  onClick={onDropdownClose}
                />
              </span>
            </Tooltip>

            <Divider type="vertical" />

            <Tooltip title={t("notification.actions.reload")}>
              <span>
                <ActionTextIcon
                  icon="reload"
                  color="blue"
                  onClick={() => actions.onFilter(FILTER_NOTIFICATIONS_DEFAULT_REQUEST)}
                />
              </span>
            </Tooltip>
          </Col>
          <Col span={4} className="right-align">
            <Tooltip title={t("notification.actions.markAllAsSeen")}>
              <span>
                <ActionTextIcon icon="eye" color="blue" size="huge" onClick={() => actions.onMarkAllAsSeen()} />
              </span>
            </Tooltip>
          </Col>
        </Row>
      </div>

      {notificationsList.items.length > 0 ? (
        <div onScrollCapture={handleNotificationListScroll}>
          <List<Notification>
            className={styles.headerNotificationList}
            dataSource={notificationsList.items}
            loading={inProgress}
            renderItem={notification => (
              <List.Item
                key={notification.id}
                className={classNames(
                  styles.headerNotificationListItem,
                  notification.status === NotificationStatus.SENT ? styles.headerNotificationListItemSent : ""
                )}
                style={{ paddingLeft: 16, paddingRight: 16 }}
                onClick={() => handleNotificationClick(notification)}
              >
                <List.Item.Meta
                  title={
                    <Row className={styles.title}>
                      <Col span={22}>{notification.data.title}</Col>
                      <Col span={2} className={styles.itemTitleSeenIconToggle}>
                        {notification.status === NotificationStatus.SEEN ? (
                          <Tooltip title={t("notification.actions.markAsUnseen")}>
                            <span onClick={event => event.stopPropagation()}>
                              <ActionTextIcon
                                icon="eye-invisible"
                                onClick={() => handleNotificationToggleClick(notification)}
                              />
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title={t("notification.actions.markAsSeen")}>
                            <span onClick={event => event.stopPropagation()}>
                              <ActionTextIcon
                                icon="eye"
                                color="red"
                                onClick={() => handleNotificationToggleClick(notification)}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </Col>
                    </Row>
                  }
                  description={
                    <div className={styles.description}>
                      {notification.data.content}
                      <br />
                      <div className={styles.itemDescriptionTime}>{fromNow(notification.sentAt)}</div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      ) : (
        <div className={styles.headerNotificationListEmpty}>
          <img src={notificationIcon} alt="no_notifications" style={{ height: 76, marginBottom: 16 }} />
          <div>{t("notification.helpers.empty")}</div>
        </div>
      )}

      <div className={styles.headerNotificationListFooter}>
        <Link to={NOTIFICATION_ROUTE_PATHS.list.to} onClick={onDropdownClose}>
          {t("notification.actions.showAll")}
        </Link>
      </div>

      <BugReportNotificationView
        open={!!selectedBugReportNotification}
        notification={selectedBugReportNotification}
        onCancelClick={() => setSelectedBugReportNotification(undefined)}
      />
    </div>
  );
};

export default HeaderNotificationDropdown;
