import { Col, Divider, Row } from "antd";
import { CalcType } from "../../../../enums";
import { CalcResult } from "../../../types";
import { filterApplicableResults } from "../../../utils";
import { VehicleCalcResultData, VehicleCalcResults, VehicleCalcType } from "../../types";
import { getVehicleCalcResultsByType } from "../../utils";
import VehicleCalcResultsSection from "./VehicleCalcResultsSection";

interface Props {
  calcType: VehicleCalcType;
  calcResults: VehicleCalcResults;
  selectedResult?: CalcResult<VehicleCalcResultData>;
  threeColumnsLayout?: boolean;
  onGenerateContractClick: (result: CalcResult<VehicleCalcResultData>) => void;
  onShowErrorsClick?: (result: CalcResult<VehicleCalcResultData>) => void;
}

const VehicleCalcResultsColumn = ({ calcType, calcResults, selectedResult, threeColumnsLayout, ...props }: Props) => {
  const resultsRows = getVehicleCalcResultsByType(calcResults, calcType).filter(
    resultsRow => filterApplicableResults(resultsRow).length > 0
  );

  let gapResultsRows: CalcResult<VehicleCalcResultData>[][] = [];
  let pasResultsRows: CalcResult<VehicleCalcResultData>[][] = [];
  if (calcType === CalcType.CRASH || calcType === CalcType.MTPL_CRASH) {
    gapResultsRows = calcResults.gap.filter(resultsRow => filterApplicableResults(resultsRow).length > 0);
    pasResultsRows = calcResults.pas.filter(resultsRow => filterApplicableResults(resultsRow).length > 0);
  }

  return (
    <>
      <VehicleCalcResultsSection
        calcType={calcType}
        resultsRows={resultsRows}
        selectedResult={selectedResult}
        threeColumnsLayout={threeColumnsLayout}
        onGenerateContractClick={props.onGenerateContractClick}
        onShowErrorsClick={props.onShowErrorsClick}
      />

      {gapResultsRows.length > 0 && (
        <>
          <Row>
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <VehicleCalcResultsSection
            calcType={CalcType.GAP}
            resultsRows={gapResultsRows}
            selectedResult={selectedResult}
            onGenerateContractClick={props.onGenerateContractClick}
            onShowErrorsClick={props.onShowErrorsClick}
          />
        </>
      )}

      {pasResultsRows.length > 0 && (
        <>
          <Row>
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <VehicleCalcResultsSection
            calcType={CalcType.PAS}
            resultsRows={pasResultsRows}
            selectedResult={selectedResult}
            onGenerateContractClick={props.onGenerateContractClick}
            onShowErrorsClick={props.onShowErrorsClick}
          />
        </>
      )}
    </>
  );
};

export default VehicleCalcResultsColumn;
