import { Tag } from "antd";
import t from "../../../../app/i18n";
import { CommissionsBatchStep } from "../enums";

interface Props {
  step?: CommissionsBatchStep;
  closable?: boolean;
  style?: React.CSSProperties;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  label?: React.ReactNode;
}

const CommissionsBatchStepTag = ({ step, closable, style, onClose, label }: Props) => {
  let color;

  switch (step) {
    case CommissionsBatchStep.IMPORT:
      color = "volcano";
      break;
    case CommissionsBatchStep.CALCULATION:
      color = "blue";
      break;
    case CommissionsBatchStep.RECAPITULATION:
      color = "gold";
      break;
    case CommissionsBatchStep.REVIEW:
      color = "purple";
      break;
    case CommissionsBatchStep.FINISH:
      color = "green";
      break;
  }

  return step || label ? (
    <Tag color={color} closable={closable} onClose={onClose} style={{ textAlign: "center", width: "94px", ...style }}>
      {step ? t("commissions.batch.enums.batchStep." + step) : label}
    </Tag>
  ) : null;
};

export default CommissionsBatchStepTag;
