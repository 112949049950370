import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, List, Modal, Tooltip } from "antd";
import classNames from "classnames";
import { MenuInfo } from "rc-menu/lib/interface";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import HtmlView from "../../../../../common/components/views/HtmlView";
import { PageSizes } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import { fromNow } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { selectHasPermissions } from "../../../../auth/ducks";
import { requests } from "../../../api";
import {
  deleteDashboardNoticeActions,
  filterDashboardNoticesActions,
  selectDashboardNoticesCurrentPage
} from "../../../ducks";
import { DashboardNotice, DashboardNoticeFilterPageResult } from "../../../types";
import { DashboardNoticeForm } from "../../forms";
import {
  DashboardNoticeAttachments,
  DashboardNoticeClosedTag,
  DashboardNoticeIcon,
  DashboardNoticePositionTag
} from "./components";
import styles from "./DashboardNoticeWidget.module.scss";

const ACTION = {
  UPDATE: "update",
  DELETE: "delete"
};

export const DashboardNoticesWidget = () => {
  const { t } = useTranslation();

  const currentPage = useSelector<RootState, DashboardNoticeFilterPageResult>(selectDashboardNoticesCurrentPage);
  const hasAdminDashboardPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.ADMIN_DASHBOARD)(state)
  );

  const dispatch = useDispatch();
  const { onFilter, onDelete } = useMemo(
    () =>
      bindActionCreators(
        {
          onFilter: filterDashboardNoticesActions.request,
          onDelete: deleteDashboardNoticeActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const [noticeFormOpen, setNoticeFormOpen] = useState<boolean>(false);
  const [selectedNotice, setSelectedNotice] = useState<DashboardNotice>();

  const inProgress = useRequestFinishedCallback([requests.FILTER_DASHBOARD_NOTICES]);

  const handleContactFormCancel = (): void => {
    setSelectedNotice(undefined);
    setNoticeFormOpen(false);
  };

  const handleMenuDropdownClick = (clickInfo: MenuInfo, notice: DashboardNotice): void => {
    switch (clickInfo.key) {
      case ACTION.UPDATE:
        setSelectedNotice(notice);
        setNoticeFormOpen(true);
        break;
      case ACTION.DELETE:
        Modal.confirm({
          title: t("dashboard.notices.helpers.deleteConfirm"),
          icon: <ExclamationCircleOutlined />,
          okText: t("common.yes"),
          cancelText: t("common.no"),
          okType: "danger",
          onOk: () => {
            onDelete({ id: notice.id });
          }
        });
        break;
    }
  };

  return (
    <Card
      className={classNames("card-box", styles.dashboardNotice)}
      size="small"
      title={t("dashboard.notices.card")}
      loading={inProgress}
      extra={
        hasAdminDashboardPermission && (
          <Button size="small" type="primary" icon={<AntIcon type="plus" />} onClick={() => setNoticeFormOpen(true)}>
            {t("common.add")}
          </Button>
        )
      }
    >
      <div id="dashboard-notice-scroll" className={styles.dashboardNoticeScrollableBox}>
        <InfiniteScroll
          dataLength={currentPage.pageData.length}
          next={() => {
            onFilter({
              pageIndex: currentPage.pageData.length / currentPage.pageSize,
              pageSize: PageSizes.HUGE,
              additionalItems: true
            });
          }}
          hasMore={!currentPage.isLast}
          loader="..."
          scrollableTarget="dashboard-notice-scroll"
        >
          <List<DashboardNotice>
            itemLayout="vertical"
            dataSource={currentPage.pageData}
            renderItem={item => (
              <List.Item
                key={item.id}
                styles={{ extra: { marginLeft: 0 } }}
                actions={item.attachments.length > 0 ? [<DashboardNoticeAttachments notice={item} />] : undefined}
                extra={
                  hasAdminDashboardPermission && (
                    <Dropdown
                      trigger={["click"]}
                      placement="bottomRight"
                      menu={{
                        items: [
                          {
                            key: ACTION.UPDATE,
                            label: <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} />
                          },
                          {
                            key: ACTION.DELETE,
                            label: <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
                          }
                        ],
                        onClick: clickInfo => handleMenuDropdownClick(clickInfo, item)
                      }}
                    >
                      <div className={styles.dashboardNoticeActions}>
                        <AntIcon type="more" />
                      </div>
                    </Dropdown>
                  )
                }
              >
                <List.Item.Meta
                  className={styles.dashboardNoticeItem}
                  avatar={<DashboardNoticeIcon type={item.type} />}
                  title={<Ellipsis>{item.title}</Ellipsis>}
                  description={
                    <>
                      <Tooltip title={formatLocaleDateTime(item.createdAt)}>{fromNow(item.createdAt)}</Tooltip>
                      {item.closed ? (
                        <DashboardNoticeClosedTag style={{ marginLeft: 8 }} className="margin-bottom-tiny" />
                      ) : undefined}
                    </>
                  }
                />

                {item.positions?.length > 0 ? (
                  <div className="margin-bottom-tiny">
                    {item.positions.map(type => (
                      <DashboardNoticePositionTag key={type} position={type} />
                    ))}
                  </div>
                ) : undefined}

                <HtmlView value={item.text} />
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>

      <DashboardNoticeForm notice={selectedNotice} open={noticeFormOpen} onFormCancel={handleContactFormCancel} />
    </Card>
  );
};
