import t from "../../../../../../../app/i18n";
import {
  formatLicensePlate,
  formatLifeInsuranceTariff,
  formatPlaceOfInsurance
} from "../../../../../../../common/utils/formatUtils";
import { tInterval } from "../../../../../../../common/utils/translationUtils";
import { InsuranceType } from "../../../../../enums";
import {
  Insurance,
  InsuranceContract,
  LifeInsurance,
  RealtyInsurance,
  TravelInsurance,
  VehicleInsurance
} from "../../../../../types";
import { parseVehicleModelName } from "../../../../../utils";

interface Props {
  insurance: Insurance;
  contract: InsuranceContract;
}

const InsuranceHeaderView = ({ insurance, contract }: Props) => {
  switch (insurance.type) {
    case InsuranceType.MTPL:
    case InsuranceType.CRASH:
    case InsuranceType.GAP:
    case InsuranceType.PAS:
      const { vehicle, licensePlate } = insurance as VehicleInsurance;
      return (
        <span>
          {[
            parseVehicleModelName({
              customModel: vehicle.customModel,
              customBrand: vehicle.customBrand,
              brandName: vehicle.model.brand.name,
              modelName: vehicle.model.name
            }),
            formatLicensePlate(licensePlate),
            vehicle.vin
          ]
            .filter(v => !!v)
            .join(" | ")}
        </span>
      );
    case InsuranceType.REALTY:
      const realtyData = (insurance as RealtyInsurance).insuranceData;
      const realtyHouseholdLabel =
        realtyData.realty && realtyData.household
          ? t("contract.helpers.realtyAndHousehold")
          : realtyData.realty
            ? t("contract.attrs.insurances.insuranceData.realty._label")
            : t("contract.attrs.insurances.insuranceData.household._label");
      return (
        <span>
          {[
            t("calc.realty.enums.buildingType." + realtyData.buildingType),
            formatPlaceOfInsurance(realtyData.placeOfInsurance),
            realtyHouseholdLabel
          ].join(" | ")}
        </span>
      );
    case InsuranceType.TRAVEL:
      const travelData = (insurance as TravelInsurance).insuranceData;
      return (
        <span>
          {[
            t("calc.travel.enums.territorialValidity." + travelData.territorialValidity),
            tInterval("contract.helpers.insuredClientsCount_interval", travelData.insuredClients.length)
          ].join(" | ")}
        </span>
      );
    case InsuranceType.LIFE:
      return <span>{formatLifeInsuranceTariff((insurance as LifeInsurance).tariff)}</span>;
    case InsuranceType.GENERIC:
      return <span>{contract.product.name}</span>;
    default:
      return null;
  }
};

export default InsuranceHeaderView;
