import t from "../../../../../app/i18n";
import CopyToClipboardView from "../../../../../common/components/views/CopyToClipboardView";
import HtmlView from "../../../../../common/components/views/HtmlView";
import { LegalClient } from "../../../types";

interface Props {
  client: LegalClient;
}

const LegalClientDataView = ({ client }: Props) => (
  <table className="data-table-view">
    <tbody>
      <tr>
        <td>{t("client.attrs.companyName")}:</td>
        <td colSpan={3}>{client.companyName}</td>
      </tr>
      <tr>
        <td colSpan={4} />
      </tr>
      <tr>
        <td>{t("client.attrs.companyRegistrationNumber")}:</td>
        <td>
          <CopyToClipboardView content={client.companyRegistrationNumber} />
        </td>
        <td>{t("client.attrs.commercialRegisterDistrictOffice")}:</td>
        <td>{client.commercialRegisterDistrictOffice}</td>
      </tr>
      <tr>
        <td>{t("client.attrs.taxId")}:</td>
        <td>
          <CopyToClipboardView content={client.taxId} />
        </td>
        <td>{t("client.attrs.commercialRegisterSection")}:</td>
        <td>{client.commercialRegisterSection}</td>
      </tr>
      <tr>
        <td>{t("client.attrs.vatId")}:</td>
        <td>
          <CopyToClipboardView content={client.vatId} />
        </td>
        <td>{t("client.attrs.commercialRegisterInsertionNumber")}:</td>
        <td>{client.commercialRegisterInsertionNumber}</td>
      </tr>
      <tr>
        <td colSpan={4} />
      </tr>
      <tr>
        <td colSpan={4}>{t("client.attrs.note")}:</td>
      </tr>
      {client.note && (
        <tr>
          <td colSpan={4}>
            <HtmlView value={client.note} />
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

export default LegalClientDataView;
