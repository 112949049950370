import { Checkbox, Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import {
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import { InstitutionType } from "../../../institution/enums";
import { requests } from "../../api";
import { createComplicityActions, updateComplicityActions } from "../../ducks";
import { ComplicityEnum } from "../../enums";
import { Complicity, CreateUpdateComplicity } from "../../types";

interface Props {
  complicity?: Complicity;
  open: boolean;
  onCreate?: typeof createComplicityActions.request;
  onUpdate?: typeof updateComplicityActions.request;
  onFormCancel: () => void;
}

const ComplicityForm = ({ complicity, open, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateComplicity>();
  useFormErrorHandler(form, "complicity.attrs", [requests.CREATE_COMPLICITY, requests.UPDATE_COMPLICITY]);

  useEffect(() => {
    if (open && complicity) {
      form.setFieldsValue({ ...complicity, institutionId: complicity.institution.id });
    }
  }, [complicity, open, form]);

  const inProgress = useRequestFinishedCallback([requests.CREATE_COMPLICITY, requests.UPDATE_COMPLICITY], onFormCancel);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (complicity) {
          onUpdate?.({ id: complicity.id, object: values });
        } else {
          onCreate?.(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={complicity ? t("complicity.titles.updateComplicity") : t("complicity.titles.createComplicity")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="complicityForm">
        <HiddenInput name="optimisticLockVersion" />
        <Row gutter={rowGutter}>
          <Col span={12}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionId",
                label: t("complicity.attrs.institutionId"),
                rules: [validations.notNull]
              }}
              optionsProps={{
                includeDeactivated: true,
                filterType: InstitutionType.INSURANCE_COMPANY
              }}
            />
          </Col>

          <Col span={12}>
            <Form.Item
              name="complicityEnum"
              label={t("complicity.enums.complicityEnum._label")}
              rules={[validations.none]}
            >
              <Select
                {...selectStandardProps}
                allowClear
                options={Object.keys(ComplicityEnum).map(complicity => ({
                  value: complicity,
                  label: t("complicity.enums.complicityEnum." + complicity)
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item
              name="value"
              label={t("complicity.attrs.value")}
              rules={[validations.notBlank, validations.size(1, 64)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="deactivated"
              valuePropName="checked"
              rules={[validations.none]}
              className="form-item-without-label"
              initialValue={false}
            >
              <Checkbox>{t("complicity.attrs.deactivated")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ComplicityForm;
