import { Card } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { PageSizes } from "../../../common/constants";
import { ExportFileType, OrderDirection } from "../../../common/enums";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { RootState } from "../../../common/types";
import { sortOrderToOrderDirection } from "../../../common/utils/formUtils";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectEnums } from "../../enumerations/ducks";
import { Enumerations } from "../../enumerations/types";
import RecalculatePredictedCommissionsForm from "../components/forms/RecalculatePredictedCommissionsForm";
import ContractListFilterView from "../components/views/list/ContractListFilterView";
import ContractListTableView from "../components/views/list/ContractListTableView";
import {
  changeContractVerificationStatusActions,
  deleteStateContractPageAction,
  downloadContractsExportActions,
  filterContractsActions,
  recalculatePredictedCommissionsActions,
  selectCurrentContractsPage
} from "../ducks";
import { ContractOrderByColumn, ContractStatus, ContractVerificationStatus, ContractView } from "../enums";
import { CONTRACT_ROUTE_PATHS } from "../paths";
import { ContractFilterPageRequest, ContractFilterPageResult, ContractList } from "../types";

const getReport = (pathname?: string) => {
  switch (pathname) {
    case CONTRACT_ROUTE_PATHS.predictedCommissions.path:
      return ContractView.INTERNAL_PREDICTED_COMMISSIONS_REPORT;
    case CONTRACT_ROUTE_PATHS.anniversaryDate.path:
      return ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT;
    case CONTRACT_ROUTE_PATHS.fixationAnniversaryDate.path:
      return ContractView.INTERNAL_FIXATION_ANNIVERSARY_REPORT;
    case CONTRACT_ROUTE_PATHS.unpaid.path:
      return ContractView.INTERNAL_UNPAID_REPORT;
    default:
      return ContractView.INTERNAL_GENERIC_REPORT;
  }
};

const ContractListContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search: urlSearchQuery, pathname: locationPathname } = useLocation();
  const currentContractsPage = useSelector<RootState, ContractFilterPageResult>(selectCurrentContractsPage);
  const enumerations = useSelector<RootState, Enumerations>(selectEnums);

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          filterContracts: filterContractsActions.request,
          downloadContractsExport: downloadContractsExportActions.request,
          recalculatePredictedCommissions: recalculatePredictedCommissionsActions.request,
          changeContractVerificationStatus: changeContractVerificationStatusActions.request,
          deleteStateContractPage: deleteStateContractPageAction
        },
        dispatch
      ),
    [dispatch]
  );

  const [recalculatePredictedCommissionsModalOpen, setRecalculatePredictedCommissionsModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const { productGroups, institutions, subordinateAgents, affiliatePartners } = enumerations;
    const products = productGroups.map(productGroup => productGroup.products).flat();

    const locationPathnameParts = locationPathname.split("/");
    const lastPathnamePart = locationPathnameParts[locationPathnameParts.length - 1];
    const report = getReport(lastPathnamePart);

    const urlParams = new URLSearchParams(urlSearchQuery);
    const pageSize = urlParams.get("pageSize");
    const annualPremiumOrApprovedAmountMin = urlParams.get("annualPremiumOrApprovedAmountMin");
    const annualPremiumOrApprovedAmountMax = urlParams.get("annualPremiumOrApprovedAmountMax");
    const vehicleFirstRegistrationYearMin = urlParams.get("vehicleFirstRegistrationYearMin");
    const vehicleFirstRegistrationYearMax = urlParams.get("vehicleFirstRegistrationYearMax");

    const initialFilter: ContractFilterPageRequest = {
      report,
      orderBy:
        urlParams.getAll("orderBy").length > 0
          ? urlParams
              .getAll("orderBy")
              .map(column => ContractOrderByColumn[column as keyof typeof ContractOrderByColumn])
          : getDefaultOrderBy(report),
      orderDirections:
        urlParams.getAll("orderDirections").length > 0
          ? urlParams
              .getAll("orderDirections")
              .map(direction => OrderDirection[direction as keyof typeof OrderDirection])
          : getDefaultOrderDirections(report),
      keyword: urlParams.get("keyword") ?? undefined,
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: pageSize ? parseInt(pageSize) : PageSizes.HUGE,
      institutionIds: urlParams
        .getAll("institutionIds")
        .filter(id => institutions.some(i => i.id === id && !i.settings.deactivated)),
      productIds: urlParams.getAll("productIds").filter(id => products.some(p => p.id === id)),
      signerIds: urlParams.getAll("signerIds").filter(id => subordinateAgents.some(a => a.id === id)),
      managerIds: urlParams.getAll("managerIds").filter(id => subordinateAgents.some(a => a.id === id)),
      gainerIds: urlParams.getAll("gainerIds").filter(id => subordinateAgents.some(a => a.id === id)),
      affiliatePartnerIds: urlParams
        .getAll("affiliatePartnerIds")
        .filter(id => affiliatePartners.some(ap => ap.id === id)),
      statuses: urlParams.getAll("statuses").map(status => ContractStatus[status as keyof typeof ContractStatus]),
      verificationStatuses: urlParams
        .getAll("verificationStatuses")
        .map(status => ContractVerificationStatus[status as keyof typeof ContractVerificationStatus]),
      createdAtMin: urlParams.get("createdAtMin") ?? undefined,
      createdAtMax: urlParams.get("createdAtMax") ?? undefined,
      mediationReportSignDateMin: urlParams.get("mediationReportSignDateMin") ?? undefined,
      mediationReportSignDateMax: urlParams.get("mediationReportSignDateMax") ?? undefined,
      effectiveBeginningDateOrSignDateMin: urlParams.get("effectiveBeginningDateOrSignDateMin") ?? undefined,
      effectiveBeginningDateOrSignDateMax: urlParams.get("effectiveBeginningDateOrSignDateMax") ?? undefined,
      effectiveEndDateOrLoanMaturityDateMin: urlParams.get("effectiveEndDateOrLoanMaturityDateMin") ?? undefined,
      effectiveEndDateOrLoanMaturityDateMax: urlParams.get("effectiveEndDateOrLoanMaturityDateMax") ?? undefined,
      cancellationDateMin: urlParams.get("cancellationDateMin") ?? undefined,
      cancellationDateMax: urlParams.get("cancellationDateMax") ?? undefined,
      transferredFromOtherBroker: urlParams.get("transferredFromOtherBroker") === "true" || undefined,
      transferredToOtherBrokerDateMin: urlParams.get("transferredToOtherBrokerDateMin") ?? undefined,
      transferredToOtherBrokerDateMax: urlParams.get("transferredToOtherBrokerDateMax") ?? undefined,
      annualPremiumOrApprovedAmountMin: annualPremiumOrApprovedAmountMin
        ? parseFloat(annualPremiumOrApprovedAmountMin)
        : undefined,
      annualPremiumOrApprovedAmountMax: annualPremiumOrApprovedAmountMax
        ? parseFloat(annualPremiumOrApprovedAmountMax)
        : undefined,
      vehicleFirstRegistrationYearMin: vehicleFirstRegistrationYearMin
        ? parseInt(vehicleFirstRegistrationYearMin)
        : undefined,
      vehicleFirstRegistrationYearMax: vehicleFirstRegistrationYearMax
        ? parseInt(vehicleFirstRegistrationYearMax)
        : undefined
    };

    actions.filterContracts(initialFilter);

    return () => {
      actions.deleteStateContractPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDefaultOrderBy = (report?: ContractView): ContractOrderByColumn[] => {
    if (report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT) {
      return [ContractOrderByColumn.LAST_CONTRACT_CANCELLATION_DATE];
    } else if (report === ContractView.INTERNAL_FIXATION_ANNIVERSARY_REPORT) {
      return [ContractOrderByColumn.CONTACT_CLIENT_DATE];
    }

    return [ContractOrderByColumn.CREATED_AT];
  };

  const getDefaultOrderDirections = (report?: ContractView): OrderDirection[] => {
    return report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT ||
      report === ContractView.INTERNAL_FIXATION_ANNIVERSARY_REPORT
      ? [OrderDirection.ASC]
      : [OrderDirection.DESC];
  };

  const mapFilterResultToFilterRequest = (result: ContractFilterPageResult): ContractFilterPageRequest => {
    const newResult = { ...result };

    delete newResult.pageData;
    delete newResult.totalElementsCount;
    delete newResult.pageElementsCount;
    delete newResult.pagesCount;
    delete newResult.isFirst;
    delete newResult.isLast;

    return newResult;
  };

  const appendSearchParamsToLink = (filter: ContractFilterPageRequest): void => {
    const shouldSerializeOrderIntoUrl =
      !isEqual(filter.orderBy, getDefaultOrderBy(filter.report)) ||
      !isEqual(filter.orderDirections, getDefaultOrderDirections(filter.report));

    const urlParams = {
      ...filter,
      pageSize: filter.pageSize !== PageSizes.HUGE ? filter.pageSize : undefined,
      report: undefined,
      orderBy: shouldSerializeOrderIntoUrl ? filter.orderBy : undefined,
      orderDirections: shouldSerializeOrderIntoUrl ? filter.orderDirections : undefined,
      transferredFromOtherBroker: filter.transferredFromOtherBroker || undefined
    } as ContractFilterPageRequest;

    navigate(appendSearchParamsToURL(urlParams as Record<string, any>), { replace: true });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _: Record<string, FilterValue | null | undefined>,
    sorter: SorterResult<ContractList> | SorterResult<ContractList>[]
  ): void => {
    const orderBy: ContractOrderByColumn[] = [];
    const orderDirections = [];
    if (Array.isArray(sorter)) {
      sorter
        .filter(item => item.order)
        .forEach(item => {
          const order = ContractOrderByColumn[item.field?.toString() as keyof typeof ContractOrderByColumn];
          if (orderBy.indexOf(order) === -1 && item.order) {
            orderBy.push(order);
            orderDirections.push(sortOrderToOrderDirection(item.order));
          }
        });
    } else if (sorter.order) {
      orderBy.push(ContractOrderByColumn[sorter.field?.toString() as keyof typeof ContractOrderByColumn]);
      const orderDirection = sortOrderToOrderDirection(sorter.order);
      if (orderDirection) {
        orderDirections.push(orderDirection);
      }
    }

    const filter: ContractFilterPageRequest = {
      ...mapFilterResultToFilterRequest(currentContractsPage),
      pageIndex: pagination.current ? pagination.current - 1 : undefined,
      pageSize: pagination.pageSize,
      orderBy: orderBy.length ? orderBy : getDefaultOrderBy(currentContractsPage.report),
      orderDirections: orderDirections.length ? orderDirections : getDefaultOrderDirections(currentContractsPage.report)
    };

    appendSearchParamsToLink(filter);
    actions.filterContracts(filter);
  };

  const handleFilterSubmit = (filter: ContractFilterPageRequest): void => {
    const filterRequest = {
      ...mapFilterResultToFilterRequest(currentContractsPage),
      ...filter,
      keyword: filter.keyword || undefined,
      pageIndex: 0
    };

    appendSearchParamsToLink({ ...filterRequest, pageIndex: undefined });
    actions.filterContracts(filterRequest);
  };

  const handleExportClick = (exportFileType: ExportFileType): void => {
    actions.downloadContractsExport({
      ...mapFilterResultToFilterRequest(currentContractsPage),
      exportFileType
    });
  };

  return (
    <DisplayWrapper itemLoaded={!!currentContractsPage.report}>
      <ContractListFilterView
        contractsPage={currentContractsPage}
        onFilterSubmit={handleFilterSubmit}
        onRecalculatePredictedCommissionsClick={() => setRecalculatePredictedCommissionsModalOpen(true)}
      />
      <Card>
        <ContractListTableView
          contractsPage={currentContractsPage}
          onTableChange={handleTableChange}
          onVerificationStatusChange={actions.changeContractVerificationStatus}
          onExportClick={handleExportClick}
        />
      </Card>
      <RecalculatePredictedCommissionsForm
        open={recalculatePredictedCommissionsModalOpen}
        onSubmit={actions.recalculatePredictedCommissions}
        onFormCancel={() => setRecalculatePredictedCommissionsModalOpen(false)}
      />
    </DisplayWrapper>
  );
};

export default ContractListContainer;
