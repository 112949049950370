import { Col, Modal, Row, Select } from "antd";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import t from "../../../../../app/i18n";
import { SecondaryButton } from "../../../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { ModalSizes } from "../../../../../common/constants";
import { formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../../common/utils/formUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { isJson, saveTextFile } from "../../../../../common/utils/utils";
import { CalcRecord, WebserviceLog } from "../../types";

interface Props {
  open: boolean;
  record?: CalcRecord;
  onCancelClick: () => void;
  onAfterClose: () => void;
}

const CalcRecordWebservicesLogsModal = ({ open, record, onCancelClick, onAfterClose }: Props) => {
  const [selectedLogIndex, setSelectedLogIndex] = useState<number>(0);

  const handleSelectedLogChange = (value: number): void => {
    setSelectedLogIndex(value);
  };

  const handleAfterCloseExecuted = (): void => {
    setSelectedLogIndex(0);
    onAfterClose();
  };

  const handleLogFileSave = (content: string, type: "request" | "response"): void => {
    saveTextFile(content, createLogsTitle() + " _ " + t("common." + type));
  };

  const handleCopyInfoMessageDisplay = (): void => {
    messageUtils.infoMessage(t("common.clipboardCopied"));
  };

  const createLogsTitle = (): string => {
    return (
      t("calc.records.titles.webserviceLogs") + ` - ${record?.user.name} / ` + formatLocaleDateTime(record?.createdAt)
    );
  };

  if (record && record.webservicesLogs && record.webservicesLogs.length > 0) {
    const { request, response } = record.webservicesLogs[selectedLogIndex] as WebserviceLog;
    const requestString = isJson(request) ? JSON.stringify(JSON.parse(request), undefined, 2) : request;
    const responseString = isJson(response) ? JSON.stringify(JSON.parse(response), undefined, 2) : response;

    return (
      <Modal
        open={open}
        title={createLogsTitle()}
        width={ModalSizes.HUGE}
        centered
        onCancel={onCancelClick}
        afterClose={handleAfterCloseExecuted}
        footer={null}
      >
        <Row gutter={16} className="margin-bottom-medium">
          <Select
            {...selectStandardProps}
            style={{ width: "200px" }}
            value={selectedLogIndex}
            options={record.webservicesLogs.map((log, index) => ({
              value: index,
              label: log.institution + (log.coverage ? " - " + log.coverage : "")
            }))}
            onChange={handleSelectedLogChange}
          />
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <h3>{t("common.request")}</h3>

            <pre className="modal-code-block">{requestString}</pre>

            <div className="center-align margin-top-small">
              <span className="margin-right-medium">
                <SecondaryButton
                  icon={<AntIcon type="download" />}
                  onClick={() => handleLogFileSave(requestString, "request")}
                >
                  {t("common.download")}
                </SecondaryButton>
              </span>

              <CopyToClipboard text={requestString} onCopy={handleCopyInfoMessageDisplay}>
                <SecondaryButton icon={<AntIcon type="copy" />}>{t("common.copy")}</SecondaryButton>
              </CopyToClipboard>
            </div>
          </Col>

          <Col span={12}>
            <h3>{t("common.response")}</h3>

            <pre className="modal-code-block">{responseString}</pre>

            <div className="center-align margin-top-small">
              <span className="margin-right-medium">
                <SecondaryButton
                  icon={<AntIcon type="download" />}
                  onClick={() => handleLogFileSave(responseString, "response")}
                >
                  {t("common.download")}
                </SecondaryButton>
              </span>

              <CopyToClipboard text={responseString} onCopy={handleCopyInfoMessageDisplay}>
                <SecondaryButton icon={<AntIcon type="copy" />}>{t("common.copy")}</SecondaryButton>
              </CopyToClipboard>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }

  return null;
};

export default CalcRecordWebservicesLogsModal;
