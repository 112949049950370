import { Card } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import AgentForm from "../components/forms/agent/AgentForm";
import { createAgentActions } from "../ducks";
import { useAgentSearch } from "../utils";

interface ActionsMap {
  createAgent: typeof createAgentActions.request;
}

const AgentCreateContainer = (props: ActionProps<ActionsMap>) => {
  const agentSearch = useAgentSearch();

  useEffect(() => {
    return () => {
      agentSearch.onResultDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>{t("agent.titles.create")}</h2>
          </BackNavigationArrow>
        }
      >
        <AgentForm onCreate={props.actions.createAgent} agentSearch={agentSearch} />
      </Card>
    </ContentWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      createAgent: createAgentActions.request
    },
    dispatch
  )
});

export default connect<object, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  undefined,
  mapDispatchToProps
)(AgentCreateContainer);
