import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import { RootState } from "../../../../common/types";
import { formatCoverageLimit } from "../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import type { UUID } from "../../../../typings/global";
import { CoverageLimitBase } from "../../../coveragelimit/types";
import { selectCoverageLimitsClassificationEnums } from "../../ducks";
import { InstitutionWithCoverageLimits } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  institutionId: UUID;
}

const CoverageLimitSelect = ({ formItemProps, selectProps, institutionId }: Props) => {
  const institutionsWithLimits = useSelector<RootState, InstitutionWithCoverageLimits[]>(
    selectCoverageLimitsClassificationEnums
  );

  const resolveCoverageLimitsByDeactivated = (deactivated: boolean): CoverageLimitBase[] => {
    return (institutionsWithLimits.find(institution => institution.id === institutionId)?.coverageLimits || []).filter(
      limit => limit.deactivated === deactivated
    );
  };

  const activeCoverageLimits = resolveCoverageLimitsByDeactivated(false);
  const deactivatedCoverageLimits = resolveCoverageLimitsByDeactivated(true);

  return (
    <Form.Item {...formItemProps}>
      <Select {...selectStandardProps} {...selectProps}>
        {activeCoverageLimits.length > 0 && (
          <Select.OptGroup key={1} label={t("coverageLimit.helpers.activated")}>
            {activeCoverageLimits.map(limit => (
              <Select.Option key={limit.id} value={limit.id}>
                {formatCoverageLimit(limit)}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}
        {deactivatedCoverageLimits.length > 0 && (
          <Select.OptGroup key={2} label={t("coverageLimit.helpers.deactivated")}>
            {deactivatedCoverageLimits.map(limit => (
              <Select.Option key={limit.id} value={limit.id}>
                {formatCoverageLimit(limit)}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}
      </Select>
    </Form.Item>
  );
};

export default CoverageLimitSelect;
