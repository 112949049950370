import { Typography } from "antd";
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  tooltip?: string;
  shouldRender?: boolean;
  shouldRenderTooltip?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const Ellipsis = ({ children, tooltip, shouldRender = true, shouldRenderTooltip = true, className, style }: Props) =>
  shouldRender ? (
    <Typography.Text
      ellipsis={{ tooltip: shouldRenderTooltip && (tooltip || children) }}
      className={classNames(className)}
      style={style}
    >
      {children}
    </Typography.Text>
  ) : (
    <>{children}</>
  );

export default Ellipsis;
