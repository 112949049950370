import { FormInstance } from "antd/lib/form";
import t from "../../../../../app/i18n";
import AddressForm from "../../../../../common/modules/address/AddressForm";
import { AgentType } from "../../../enums";
import { CreateUpdateAgent } from "../../../types";

interface Props {
  form: FormInstance<CreateUpdateAgent>;
  agentType: AgentType;
  serviceAddressEnabled: boolean;
  correspondenceAddressEnabled: boolean;
  onServiceAddressEnabledChange: (enabled: boolean) => void;
  onCorrespondenceAddressEnabledChange: (enabled: boolean) => void;
}

const AgentAddressesFormPart = (props: Props) => (
  <>
    <AddressForm
      form={props.form}
      rootNamePath={["address"]}
      required
      label={props.agentType === AgentType.LEGAL ? t("agent.attrs.addressLongPO") : t("agent.attrs.addressLong")}
    />

    <AddressForm
      form={props.form}
      rootNamePath={["serviceAddress"]}
      label={
        props.agentType === AgentType.SELF_EMPLOYED
          ? t("agent.attrs.serviceAddressFOP")
          : t("agent.attrs.serviceAddress")
      }
      switchProps={{
        enabled: props.serviceAddressEnabled,
        onEnabledChange: props.onServiceAddressEnabledChange
      }}
    />

    <AddressForm
      form={props.form}
      rootNamePath={["correspondenceAddress"]}
      label={t("agent.attrs.correspondenceAddress")}
      switchProps={{
        enabled: props.correspondenceAddressEnabled,
        onEnabledChange: props.onCorrespondenceAddressEnabledChange
      }}
    />
  </>
);

export default AgentAddressesFormPart;
