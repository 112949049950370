export enum ATTACHMENT_BOUNDARY_TYPE {
  AGENT = "agent",
  BUG_REPORT = "bug_report",
  CALCULATOR_DRAFT = "calculator_draft",
  CALCULATOR_SETTING = "calculator_settings",
  CALCULATOR_RESULT = "calculator_result",
  CLIENT = "client",
  CLIENT_IMPORT_RESULT = "client_import_result",
  COMMISSIONS_BATCH = "commissions_batch",
  COMMISSIONS_LEVEL = "commissions_level",
  COMMISSIONS_SETTINGS = "commissions_settings",
  COMMISSIONS_OUTPUT = "commissions_output",
  CONTRACT = "contracts",
  CONTRACT_IMPORT_RESULT = "contract_import_result",
  DASHBOARD_NOTICE = "dashboard_notice",
  DOCUMENT_NODE = "document_node",
  FINANCIAL_MEDIATION = "financial_mediation"
}
