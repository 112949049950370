import { Button, Col, Divider, Drawer, Popconfirm, Row } from "antd";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import t from "../../../../../../app/i18n";
import { SecondaryButton } from "../../../../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { DrawerSizes } from "../../../../../../common/constants";
import { RootState } from "../../../../../../common/types";
import { formatPlaceOfInsurance } from "../../../../../../common/utils/formatUtils";
import { OfferType } from "../../../enums";
import { CalcResult } from "../../../types";
import { selectRealtyDraft } from "../../ducks";
import { RealtyCalcDraft, RealtyCalcResultData, RealtyOfferType } from "../../types";
import RealtyCalcResultItem from "./RealtyCalcResultItem";
import styles from "./RealtyCalResults.module.scss";

interface Props {
  open: boolean;
  calcResults: CalcResult<RealtyCalcResultData>[][];
  selectedResult?: CalcResult<RealtyCalcResultData>;
  onClose: () => void;
  onSaveDraftClick: (overwriteExisting: boolean) => void;
  onGenerateOfferClick: (type: RealtyOfferType) => void;
  onGenerateContractClick: (result: CalcResult<RealtyCalcResultData>) => void;
  onShowErrorsClick?: (result: CalcResult<RealtyCalcResultData>) => void;
}

const RealtyCalcResults = (props: Props) => {
  const draft = useSelector<RootState, RealtyCalcDraft | undefined>(selectRealtyDraft);

  return (
    <Drawer
      title={t("calc.realty.sections.results")}
      width={DrawerSizes.MEDIUM}
      open={props.open}
      onClose={props.onClose}
      closable
    >
      <Row>
        <Col flex={1}>
          <div style={{ paddingBottom: 65 }}>
            {props.calcResults.map((resultsRow, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <Row>
                  <Col span={24}>
                    <h3 style={{ paddingLeft: 16 }}>{resultsRow[0]?.insuranceInstitution.name}</h3>
                  </Col>
                </Row>

                <Row>
                  {resultsRow.map((result, index) => {
                    const resultSelected = !!(
                      props.selectedResult &&
                      props.selectedResult.insuranceInstitution.id === result.insuranceInstitution.id &&
                      props.selectedResult.coverage === result.coverage
                    );

                    return (
                      <Col
                        span={12}
                        key={index}
                        className={classNames(styles.resultData, resultSelected ? styles.resultDataSelected : "")}
                      >
                        <RealtyCalcResultItem
                          result={result}
                          selected={resultSelected}
                          onGenerateContractClick={props.onGenerateContractClick}
                          onShowErrorsClick={props.onShowErrorsClick}
                        />
                      </Col>
                    );
                  })}
                </Row>

                {rowIndex < props.calcResults.length - 1 && (
                  <Row>
                    <Col span={24}>
                      <Divider />
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            ))}
          </div>

          <div className={styles.calcResultsNavigation}>
            {draft ? (
              <Popconfirm
                title={
                  <>
                    <b>{t("calc.helpers.loadedDraftTitle")}:</b>
                    <div className="margin-top-tiny margin-bottom-tiny">
                      {draft.clientName}
                      <br />
                      {t("calc.realty.enums.buildingType." + draft.draftData.generalBuildingData.type)} |{" "}
                      {draft.draftData.realtyInsuranceData && draft.draftData.householdInsuranceData
                        ? t("contract.helpers.realtyAndHousehold")
                        : draft.draftData.realtyInsuranceData
                          ? t("contract.attrs.insurances.insuranceData.realty._label")
                          : t("contract.attrs.insurances.insuranceData.household._label")}
                      <br />
                      {formatPlaceOfInsurance(draft.draftData.generalBuildingData.placeOfInsurance)}
                    </div>
                    <i>{t("calc.helpers.loadedDraftDesc")}</i>
                  </>
                }
                icon={<AntIcon type="question" />}
                okText={t("calc.actions.overwriteDraft")}
                cancelText={t("calc.actions.saveNewDraft")}
                overlayStyle={{ maxWidth: 450 }}
                onConfirm={() => props.onSaveDraftClick(true)}
                onCancel={() => props.onSaveDraftClick(false)}
              >
                <SecondaryButton icon={<AntIcon type="form" />}>{t("calc.actions.saveDraft")}</SecondaryButton>
              </Popconfirm>
            ) : (
              <SecondaryButton icon={<AntIcon type="form" />} onClick={() => props.onSaveDraftClick(false)}>
                {t("calc.actions.saveDraft")}
              </SecondaryButton>
            )}

            <Button
              type="primary"
              icon={<AntIcon type="file-text" />}
              onClick={() => props.onGenerateOfferClick(OfferType.REALTY)}
            >
              {t("calc.actions.generateOffer")}
            </Button>

            <Button
              type="primary"
              icon={<AntIcon type="file-text" />}
              className="margin-left-tiny"
              onClick={() => props.onGenerateOfferClick(OfferType.REALTY_RISKS)}
            >
              {t("calc.realty.actions.generateRisksOffer")}
            </Button>

            <Button icon={<AntIcon type="double-right" />} onClick={props.onClose}>
              {t("common.close")}
            </Button>
          </div>
        </Col>
      </Row>
    </Drawer>
  );
};

export default RealtyCalcResults;
