import t from "../../../../../../../app/i18n";
import {
  formatIntegerLocaleCurrency,
  formatLocaleCurrency,
  formatLocaleDate,
  formatPlaceOfInsurance
} from "../../../../../../../common/utils/formatUtils";
import { tBoolean } from "../../../../../../../common/utils/translationUtils";
import { isDefinedValue } from "../../../../../../../common/utils/utils";
import { ClientsListDrawerView } from "../../../../../../client/components/drawers/ClientsListDrawerView";
import { ClientFormType } from "../../../../../../client/enums";
import { Client } from "../../../../../../client/types";
import { InsuranceContract, RealtyInsurance } from "../../../../../types";

interface Props {
  contract: InsuranceContract;
  index: number;
  multipleInsurances: boolean;
  multipleClients: boolean;
}

const RealtyInsuranceView = ({ contract, index, multipleInsurances, multipleClients }: Props) => {
  const { insuranceData, ...insurance } = contract.insurances[index] as RealtyInsurance;

  return (
    <>
      <table className="data-table-view">
        <tbody>
          {multipleInsurances && (
            <>
              <tr>
                <td>{t("contract.attrs.insurances.contractEntryDate")}:</td>
                <td>{formatLocaleDate(insurance.contractEntryDate)}</td>
                <td>{t("contract.attrs.insurances.annualPremium")}:</td>
                <td className="right-align">{formatLocaleCurrency(insurance.annualPremium)}</td>
                <td colSpan={4} />
              </tr>
              <tr>
                <td>{t("contract.attrs.insurances.contractWithdrawalDate")}:</td>
                <td>{formatLocaleDate(insurance.contractWithdrawalDate)}</td>
                <td>{t("contract.attrs.insurances.partialPremium")}:</td>
                <td className="right-align">{formatLocaleCurrency(insurance.partialPremium)}</td>
                <td colSpan={4} />
              </tr>
            </>
          )}

          <tr>
            <td>{t("calc.realty.enums.buildingType._label")}:</td>
            <td>{t("calc.realty.enums.buildingType." + insuranceData.buildingType)}</td>
            <td>{t("calc.realty.enums.buildingState._label")}:</td>
            <td>{t("calc.realty.enums.buildingState." + insuranceData.buildingState)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.constructionYear")}:</td>
            <td>{insuranceData.constructionYear}</td>
            <td colSpan={2} />
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.permanentlyOccupied")}:</td>
            <td>{tBoolean(insuranceData.permanentlyOccupied)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.complicity")}:</td>
            <td>{tBoolean(insuranceData.complicity)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.vinculation")}:</td>
            <td>{tBoolean(insuranceData.vinculation)}</td>
            <td colSpan={2} />
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.placeOfInsurance._label")}:</td>
            <td colSpan={7}>{formatPlaceOfInsurance(insuranceData.placeOfInsurance)}</td>
          </tr>

          {insuranceData.houseOrRecreationalBuilding && (
            <>
              <tr>
                <td colSpan={8}>
                  <b>{t("calc.realty.enums.buildingType." + insuranceData.buildingType)}</b>
                </td>
              </tr>
              <tr>
                <td>{t("calc.realty.enums.constructionMaterial._label")}:</td>
                <td>
                  {t(
                    "calc.realty.enums.constructionMaterial." +
                      insuranceData.houseOrRecreationalBuilding.constructionMaterial
                  )}
                </td>
                <td>{t("calc.realty.enums.roofType._label")}:</td>
                <td>{t("calc.realty.enums.roofType." + insuranceData.houseOrRecreationalBuilding.roofType)}</td>
                <td>{t("contract.attrs.insurances.insuranceData.houseOrRecreationalBuilding.buildArea")}:</td>
                <td>
                  {insuranceData.houseOrRecreationalBuilding.buildArea} m<sup>2</sup>
                </td>
                <td colSpan={2} />
              </tr>
            </>
          )}

          {insuranceData.apartment && (
            <>
              <tr>
                <td colSpan={8}>
                  <b>{t("contract.attrs.insurances.insuranceData.apartment._label")}</b>
                </td>
              </tr>
              <tr>
                <td>{t("contract.attrs.insurances.insuranceData.apartment.floor")}:</td>
                <td>{insuranceData.apartment.floor}</td>
                <td>{t("contract.attrs.insurances.insuranceData.apartment.floorArea")}:</td>
                <td>
                  {insuranceData.apartment.floorArea} m<sup>2</sup>
                </td>
                <td colSpan={4} />
              </tr>
            </>
          )}

          <tr>
            <td>
              <b>{t("contract.attrs.insurances.insuranceData.realty._label")}:</b>
            </td>
            <td colSpan={7}>{tBoolean(!!insuranceData.realty)}</td>
          </tr>
          {insuranceData.realty && (
            <tr>
              <td>{t("contract.attrs.insurances.insuranceData.realty.insuranceAmount")}:</td>
              <td>{formatIntegerLocaleCurrency(insuranceData.realty.insuranceAmount)}</td>
              <td>{t("contract.attrs.insurances.insuranceData.realty.liabilityInsuranceAmount")}:</td>
              <td>{formatIntegerLocaleCurrency(insuranceData.realty.liabilityInsuranceAmount)}</td>
              <td colSpan={4} />
            </tr>
          )}

          <tr>
            <td>
              <b>{t("contract.attrs.insurances.insuranceData.household._label")}:</b>
            </td>
            <td colSpan={7}>{tBoolean(!!insuranceData.household)}</td>
          </tr>
          {insuranceData.household && (
            <tr>
              <td>{t("contract.attrs.insurances.insuranceData.household.insuranceAmount")}:</td>
              <td>{formatIntegerLocaleCurrency(insuranceData.household.insuranceAmount)}</td>
              <td>{t("contract.attrs.insurances.insuranceData.household.liabilityInsuranceAmount")}:</td>
              <td>{formatIntegerLocaleCurrency(insuranceData.household.liabilityInsuranceAmount)}</td>
              <td colSpan={4} />
            </tr>
          )}

          <tr>
            <td colSpan={8}>
              <b>{t("contract.attrs.insurances.insuranceData.reinsurances._label")}</b>
            </td>
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.reinsurances.cyberSecurity")}:</td>
            <td>{tBoolean(insuranceData.reinsurances.cyberSecurity)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.reinsurances.warranty")}:</td>
            <td>{tBoolean(insuranceData.reinsurances.warranty)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.reinsurances.cycling")}:</td>
            <td>{tBoolean(insuranceData.reinsurances.cycling)}</td>
            <td colSpan={2} />
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.reinsurances.loan")}:</td>
            <td>{tBoolean(insuranceData.reinsurances.loan)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.reinsurances.rent")}:</td>
            <td>{tBoolean(insuranceData.reinsurances.rent)}</td>
            <td colSpan={4} />
          </tr>
        </tbody>
      </table>

      {multipleClients && (
        <ClientsListDrawerView
          className="margin-top-medium"
          clientsData={[
            {
              client: contract.clients[insurance.insuredClientIndex] as Client,
              clientFormTypes: [ClientFormType.INSURED]
            },
            ...(isDefinedValue(insurance.vinculationClientIndex)
              ? [
                  {
                    client: contract.clients[insurance.vinculationClientIndex] as Client,
                    clientFormTypes: [ClientFormType.VINCULATION]
                  }
                ]
              : [])
          ]}
        />
      )}
    </>
  );
};

export default RealtyInsuranceView;
