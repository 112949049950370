import { Form, Input } from "antd";
import { NamePath } from "rc-field-form/lib/interface";
import { validations } from "../../../utils/validationUtils";

interface Props {
  name: NamePath;
  initialValue?: string;
}

const HiddenInput = ({ name, initialValue }: Props) => (
  <Form.Item name={name} rules={[validations.none]} initialValue={initialValue} hidden noStyle>
    <Input type="hidden" />
  </Form.Item>
);

export default HiddenInput;
