import { ATTACHMENT_BOUNDARY_PATH_PREFIX } from "../../../common/constants";

export const ADMIN_CALC_ROUTE_PREFIX = "/admin/calculators";

export const ADMIN_CALC_ROUTE_PATHS = {
  list: { path: "*", to: ADMIN_CALC_ROUTE_PREFIX },
  create: { path: "new", to: `${ADMIN_CALC_ROUTE_PREFIX}/new` },
  detail: { path: ":id", to: `${ADMIN_CALC_ROUTE_PREFIX}/:id` },
  attachmentConf: { to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${ADMIN_CALC_ROUTE_PREFIX}/:id1/attachment-confs/:id2` }
};
