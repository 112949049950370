import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useEffect } from "react";
import t from "../../../../../../app/i18n";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import ComponentWithPermission from "../../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { selectTagsStandardProps } from "../../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../../common/utils/validationUtils";
import { CommissionsBatchStep, commissionsBatchStepTMap } from "../../../enums";
import { CommissionsBatchFilterPageRequest, CommissionsBatchFilterPageResult } from "../../../types";
import CommissionsBatchStepTag from "../../CommissionsBatchStepTag";

interface Props {
  filter: CommissionsBatchFilterPageResult;
  onFilterSubmit: (filter: CommissionsBatchFilterPageRequest) => void;
  onCreateClick: () => void;
}

const CommissionsBatchFilterView = ({ filter, onFilterSubmit, onCreateClick }: Props) => {
  const [form] = Form.useForm<CommissionsBatchFilterPageRequest>();

  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({ keyword: filter.keyword, steps: filter.steps || [] });
  }, [filter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("commissions.batch.titles.batchesList")}</h2>}
      extra={
        <ComponentWithPermission permissions={[Permission.COMMISSIONS_MANAGE]}>
          <Button type="primary" icon={<AntIcon type="plus" />} onClick={onCreateClick}>
            {t("commissions.batch.actions.addBatch")}
          </Button>
        </ComponentWithPermission>
      }
    >
      <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="commissionsBatchSearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.searchKeyword")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("commissions.batch.helpers.searchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item name="steps" label={t("commissions.batch.enums.batchStep._label")} rules={[validations.none]}>
              <Select
                {...selectTagsStandardProps(commissionsBatchStepTMap)}
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                tagRender={props => (
                  <CommissionsBatchStepTag
                    step={props.value}
                    closable={props.closable}
                    onClose={props.onClose}
                    label={props.label}
                  />
                )}
                options={Object.values(CommissionsBatchStep).map(step => ({
                  value: step,
                  label: <CommissionsBatchStepTag step={step} />
                }))}
              />
            </Form.Item>
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CommissionsBatchFilterView;
