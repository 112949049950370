import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import { RootState } from "../../../../common/types";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { AffiliatePartnerBase } from "../../../affiliate/types";
import { selectAffiliatePartnersEnums } from "../../ducks";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
}

const AffiliatePartnerSelect = ({ formItemProps, selectProps }: Props) => {
  const affiliatePartnersEnums = useSelector<RootState, AffiliatePartnerBase[]>(selectAffiliatePartnersEnums);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps}
        {...selectProps}
        options={affiliatePartnersEnums.map(partner => ({ value: partner.id, label: partner.name }))}
      />
    </Form.Item>
  );
};

export default AffiliatePartnerSelect;
