import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { EntityIdObject, EntityObject } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  CoverageLimit,
  CoverageLimitFilterPageRequest,
  CoverageLimitFilterPageResult,
  CreateUpdateCoverageLimit
} from "./types";

const FILTER_COVERAGE_LIMITS = ApiRequestAdapter.create("/coverage-limits", "GET");
const CREATE_COVERAGE_LIMIT = ApiRequestAdapter.create("/coverage-limits", "POST");
const UPDATE_COVERAGE_LIMIT = ApiRequestAdapter.create("/coverage-limits/{0}", "PUT");
const DELETE_COVERAGE_LIMIT = ApiRequestAdapter.create("/coverage-limits/{0}", "DELETE");

export const requests = {
  FILTER_COVERAGE_LIMITS,
  CREATE_COVERAGE_LIMIT,
  UPDATE_COVERAGE_LIMIT,
  DELETE_COVERAGE_LIMIT
};

const api = {
  filterCoverageLimits: (request: CoverageLimitFilterPageRequest): AxiosPromise<CoverageLimitFilterPageResult> => {
    return apiService.get(FILTER_COVERAGE_LIMITS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createCoverageLimit: (request: CreateUpdateCoverageLimit): AxiosPromise<CoverageLimit> => {
    return apiService.post(CREATE_COVERAGE_LIMIT.url, request);
  },
  updateCoverageLimit: (request: EntityObject<CreateUpdateCoverageLimit>): AxiosPromise<CoverageLimit> => {
    return apiService.put(UPDATE_COVERAGE_LIMIT.getParametrizedUrl(request.id), request.object);
  },
  deleteCoverageLimit: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COVERAGE_LIMIT.getParametrizedUrl(request.id));
  }
};

export default api;
