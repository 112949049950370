import { Card, Collapse, Divider, Space, Tag } from "antd";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import t from "../../../../../../../app/i18n";
import ActionButton from "../../../../../../../common/components/buttons/ActionButton";
import ActionTextIcon from "../../../../../../../common/components/icons/ActionTextIcon";
import { ExportFileType } from "../../../../../../../common/enums";
import { toDate } from "../../../../../../../common/utils/formUtils";
import { downloadContractVehiclesExportActions } from "../../../../../ducks";
import { InsuranceType } from "../../../../../enums";
import { InsuranceContract } from "../../../../../types";
import InsuranceHeaderView from "../views/InsuranceHeaderView";
import InsuranceView from "../views/InsuranceView";

interface Props {
  contract: InsuranceContract;
}

const InsuranceContractInsurancesSection = ({ contract }: Props) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators({ downloadContractVehiclesExport: downloadContractVehiclesExportActions.request }, dispatch),
    [dispatch]
  );

  const [openedInsurancesKeys, setOpenedInsurancesKeys] = useState<string[]>([]);

  const multipleInsurances = contract.insurances.length > 1;
  const multipleClients = multipleInsurances && contract.clients.length > 1;

  return (
    <Card
      type="inner"
      className="card-box card-box--inner-extra margin-top-medium"
      title={
        <>
          <span>{t("contract.sections.insurances")}</span>
          {contract.insurances.some(
            insurance =>
              insurance.type === InsuranceType.MTPL ||
              insurance.type === InsuranceType.CRASH ||
              insurance.type === InsuranceType.GAP ||
              insurance.type === InsuranceType.PAS
          ) && (
            <span style={{ fontWeight: "normal" }} className="margin-left-small">
              <ActionTextIcon
                icon="download"
                color="blue"
                text={t("common.exportXlsx")}
                onClick={() =>
                  actions.downloadContractVehiclesExport({
                    id: contract.id,
                    object: { exportFileType: ExportFileType.XLSX }
                  })
                }
              />

              <Divider type="vertical" />

              <ActionTextIcon
                icon="download"
                color="green"
                text={t("common.exportCsv")}
                onClick={() =>
                  actions.downloadContractVehiclesExport({
                    id: contract.id,
                    object: { exportFileType: ExportFileType.CSV }
                  })
                }
              />
            </span>
          )}
        </>
      }
      extra={
        <Space>
          <ActionButton
            icon="arrows"
            onClick={() => setOpenedInsurancesKeys(contract.insurances.map(insurance => insurance.id))}
          >
            {t("common.openAll")}
          </ActionButton>
          <ActionButton icon="shrink" onClick={() => setOpenedInsurancesKeys([])}>
            {t("common.closeAll")}{" "}
          </ActionButton>
        </Space>
      }
    >
      <Collapse
        activeKey={openedInsurancesKeys}
        onChange={key => setOpenedInsurancesKeys(key as string[])}
        items={contract.insurances.map((insurance, index) => ({
          key: insurance.id,
          label: <InsuranceHeaderView insurance={insurance} contract={contract} />,
          extra: (
            <span onClick={event => event.stopPropagation()}>
              {multipleInsurances && (
                <>
                  {toDate(insurance.contractEntryDate)?.isAfter(dayjs(), "day") && (
                    <Tag color="green">{t("contract.helpers.insuranceBeforeEntry")}</Tag>
                  )}
                  {insurance.contractWithdrawalDate &&
                    toDate(insurance.contractWithdrawalDate)?.isBefore(dayjs(), "day") && (
                      <Tag color="geekblue">{t("contract.helpers.insuranceAfterWithdrawal")}</Tag>
                    )}
                </>
              )}
            </span>
          ),
          children: (
            <InsuranceView
              insurance={insurance}
              insuranceIndex={index}
              contract={contract}
              multipleInsurances={multipleInsurances}
              multipleClients={multipleClients}
            />
          )
        }))}
      />
    </Card>
  );
};

export default React.memo(InsuranceContractInsurancesSection);
