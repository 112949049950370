import { Route, Routes } from "react-router";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import NotificationPageContainer from "./containers/NotificationPageContainer";

const routes = () => (
  <Routes>
    <Route index element={<NotificationPageContainer />} />
    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
