import AntIcon from "../icons/AntIcon";
import styles from "./PageHeader.module.scss";

type Props = {
  title: React.ReactNode;
  tags?: React.ReactNode[] | React.ReactNode;
  children?: React.ReactNode;
  extra?: React.ReactNode;
  onBack?: () => void;
};

export const PageHeader = ({ title, tags, extra, children, onBack }: Props) => {
  return (
    <div className={styles.pageHeaderBox}>
      <div className={styles.heading}>
        <div className={styles.titleWrapper}>
          {onBack ? (
            <div className={styles.back} onClick={onBack}>
              <AntIcon style={{ fontSize: 18 }} type="arrow-left" />
            </div>
          ) : undefined}
          <div className={styles.title}>{title}</div>
          <div className={styles.tags}>{tags}</div>
        </div>
        <div className={styles.extra}>{extra}</div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
