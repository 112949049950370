import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import {
  EntityIdObject,
  EntityObject,
  RootState,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../../common/types";
import { initPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { changeRunningRequestKeyAction } from "../../ducks";
import api from "./api";
import {
  BailAccountBalance,
  BailAccountMovement,
  BailAccountMovementFilterPageRequest,
  BailAccountMovementFilterPageResult,
  BailAccountReducerState,
  BailAccountSettings,
  BailAccountsReportFilterPageRequest,
  BailAccountsReportFilterPageResult,
  CreateUpdateBailAccountMovement,
  CreateUpdateBailAccountSettings
} from "./types";

/**
 * ACTIONS - REPORT
 */
export const getBailAccountsReportActions = createAsyncAction(
  "bail-accounts-report/GET_REQUEST",
  "bail-accounts-report/GET_SUCCESS",
  "bail-accounts-report/GET_FAILURE"
)<BailAccountsReportFilterPageRequest, BailAccountsReportFilterPageResult, void>();

export const deleteStateBailAccountsReportAction = createAction("bail-accounts-report/DELETE_STATE_REPORT")<void>();

/**
 * ACTIONS - SETTINGS
 */
export const createBailAccountSettingsActions = createAsyncAction(
  "bail-account/CREATE_REQUEST",
  "bail-account/CREATE_SUCCESS",
  "bail-account/CREATE_FAILURE"
)<EntityObject<CreateUpdateBailAccountSettings>, BailAccountSettings, void>();

export const updateBailAccountSettingsActions = createAsyncAction(
  "bail-account/UPDATE_REQUEST",
  "bail-account/UPDATE_SUCCESS",
  "bail-account/UPDATE_FAILURE"
)<EntityObject<CreateUpdateBailAccountSettings>, BailAccountSettings, void>();

export const deleteBailAccountSettingsActions = createAsyncAction(
  "bail-account/DELETE_REQUEST",
  "bail-account/DELETE_SUCCESS",
  "bail-account/DELETE_FAILURE"
)<EntityIdObject, void, void>();

/**
 * ACTIONS - MOVEMENTS
 */
export const filterBailAccountMovementsActions = createAsyncAction(
  "bail-account-movement/FILTER_REQUEST",
  "bail-account-movement/FILTER_SUCCESS",
  "bail-account-movement/FILTER_FAILURE"
)<EntityObject<BailAccountMovementFilterPageRequest>, BailAccountMovementFilterPageResult, void>();

export const createBailAccountMovementActions = createAsyncAction(
  "bail-account-movement/CREATE_REQUEST",
  "bail-account-movement/CREATE_SUCCESS",
  "bail-account-movement/CREATE_FAILURE"
)<EntityObject<CreateUpdateBailAccountMovement>, BailAccountMovement, void>();

export const updateBailAccountMovementActions = createAsyncAction(
  "bail-account-movement/UPDATE_REQUEST",
  "bail-account-movement/UPDATE_SUCCESS",
  "bail-account-movement/UPDATE_FAILURE"
)<TwoLevelEntityObject<CreateUpdateBailAccountMovement>, BailAccountMovement, void>();

export const deleteBailAccountMovementActions = createAsyncAction(
  "bail-account-movement/DELETE_REQUEST",
  "bail-account-movement/DELETE_SUCCESS",
  "bail-account-movement/DELETE_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const deleteStateBailAccountMovementsPageAction = createAction(
  "bail-account-movement/DELETE_STATE_LIST"
)<void>();

const actions = {
  getBailAccountsReportActions,
  deleteStateBailAccountsReportAction,
  createBailAccountSettingsActions,
  updateBailAccountSettingsActions,
  deleteBailAccountSettingsActions,
  filterBailAccountMovementsActions,
  createBailAccountMovementActions,
  updateBailAccountMovementActions,
  deleteBailAccountMovementActions,
  deleteStateBailAccountMovementsPageAction
};

export type BailAccountAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: BailAccountReducerState = {
  report: {
    ...initPageResult<BailAccountBalance>(),
    balanceAsOfDate: undefined,
    commissionsSettingsLevelIds: [],
    agentCreatedAtDateMin: undefined,
    includeZeroBalance: false,
    rootAgentId: undefined,
    onlyDirectSubordinates: false,
    includeDeactivated: false,
    includeRepresentatives: false,
    includeNonGainers: false
  },
  movementsPage: {
    ...initPageResult<BailAccountMovement>(),
    onlyProcessed: true,
    onlyManual: false,
    amountsSum: undefined,
    agent: undefined
  }
};

const bailAccountsReportReducer = createReducer(initialState.report)
  .handleAction(getBailAccountsReportActions.success, (_, { payload }) => payload)
  .handleAction([getBailAccountsReportActions.failure, deleteStateBailAccountsReportAction], () => initialState.report);

const movementsPageReducer = createReducer(initialState.movementsPage)
  .handleAction(filterBailAccountMovementsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterBailAccountMovementsActions.failure, deleteStateBailAccountMovementsPageAction],
    () => initialState.movementsPage
  );

export const bailAccountReducer = combineReducers<BailAccountReducerState>({
  report: bailAccountsReportReducer,
  movementsPage: movementsPageReducer
});

/**
 * SELECTORS
 */
const selectBailAccount = (state: RootState): BailAccountReducerState => state.commissions.bailAccount;

export const selectBailAccountsReport = (state: RootState): BailAccountsReportFilterPageResult =>
  selectBailAccount(state).report;
export const selectBailAccountMovementsPage = (state: RootState): BailAccountMovementFilterPageResult =>
  selectBailAccount(state).movementsPage;

/**
 * SAGAS - REPORT
 */
function* getBailAccountsReport({ payload }: ReturnType<typeof getBailAccountsReportActions.request>) {
  try {
    const response: AxiosResponse<BailAccountsReportFilterPageResult> = yield call(api.getBailAccountsReport, payload);
    yield put(getBailAccountsReportActions.success(response.data));
  } catch {
    yield put(getBailAccountsReportActions.failure());
  }
}

/**
 * SAGAS - SETTINGS
 */
function* createBailAccountSettings({ payload }: ReturnType<typeof createBailAccountSettingsActions.request>) {
  try {
    const response: AxiosResponse<BailAccountSettings> = yield call(api.createBailAccountSettings, payload);
    yield put(createBailAccountSettingsActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createBailAccountSettingsActions.failure());
  }
}

function* updateBailAccountSettings({ payload }: ReturnType<typeof updateBailAccountSettingsActions.request>) {
  try {
    const response: AxiosResponse<BailAccountSettings> = yield call(api.updateBailAccountSettings, payload);
    yield put(updateBailAccountSettingsActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateBailAccountSettingsActions.failure());
  }
}

function* deleteBailAccountSettings({ payload }: ReturnType<typeof deleteBailAccountSettingsActions.request>) {
  try {
    yield call(api.deleteBailAccountSettings, payload);
    yield put(deleteBailAccountSettingsActions.success());
    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteBailAccountSettingsActions.failure());
  }
}

/**
 * SAGAS - MOVEMENTS
 */
function* filterBailAccountMovements({ payload }: ReturnType<typeof filterBailAccountMovementsActions.request>) {
  try {
    const response: AxiosResponse<BailAccountMovementFilterPageResult> = yield call(
      api.filterBailAccountMovements,
      payload
    );
    yield put(filterBailAccountMovementsActions.success(response.data));
  } catch {
    yield put(filterBailAccountMovementsActions.failure());
  }
}

function* createBailAccountMovement({ payload }: ReturnType<typeof createBailAccountMovementActions.request>) {
  try {
    const response: AxiosResponse<BailAccountMovement> = yield call(api.createBailAccountMovement, payload);
    yield put(createBailAccountMovementActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
    yield* refreshBailAccountMovementsPage();
  } catch {
    yield put(createBailAccountMovementActions.failure());
  }
}

function* updateBailAccountMovement({ payload }: ReturnType<typeof updateBailAccountMovementActions.request>) {
  try {
    const response: AxiosResponse<BailAccountMovement> = yield call(api.updateBailAccountMovement, payload);
    yield put(updateBailAccountMovementActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
    yield* refreshBailAccountMovementsPage();
  } catch {
    yield put(updateBailAccountMovementActions.failure());
  }
}

function* deleteBailAccountMovement({ payload }: ReturnType<typeof deleteBailAccountMovementActions.request>) {
  try {
    yield call(api.deleteBailAccountMovement, payload);
    yield put(deleteBailAccountMovementActions.success());
    messageUtils.itemDeletedNotification();
    yield* refreshBailAccountMovementsPage(true);
  } catch {
    yield put(deleteBailAccountMovementActions.failure());
  }
}

function* refreshBailAccountMovementsPage(movePage?: boolean) {
  const page: BailAccountMovementFilterPageResult = yield select(selectBailAccountMovementsPage);
  if (page.agent) {
    yield put(
      filterBailAccountMovementsActions.request({
        id: page.agent?.id,
        object: {
          pageIndex: movePage
            ? page.pageElementsCount === 1
              ? Math.max(page.pageIndex - 1, 0)
              : page.pageIndex
            : page.pageIndex,
          pageSize: page.pageSize,
          onlyProcessed: page.onlyProcessed,
          onlyManual: page.onlyManual
        }
      })
    );
  }
}

export function* bailAccountSaga() {
  yield takeLatest(getBailAccountsReportActions.request, getBailAccountsReport);
  yield takeLatest(createBailAccountSettingsActions.request, createBailAccountSettings);
  yield takeLatest(updateBailAccountSettingsActions.request, updateBailAccountSettings);
  yield takeLatest(deleteBailAccountSettingsActions.request, deleteBailAccountSettings);
  yield takeLatest(filterBailAccountMovementsActions.request, filterBailAccountMovements);
  yield takeLatest(createBailAccountMovementActions.request, createBailAccountMovement);
  yield takeLatest(updateBailAccountMovementActions.request, updateBailAccountMovement);
  yield takeLatest(deleteBailAccountMovementActions.request, deleteBailAccountMovement);
}
