import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { EntityIdObject, RootState } from "../../../common/types";
import { requests } from "../api";
import FinancialMediationForm from "../components/forms/FinancialMediationForm";
import {
  assignFinancialMediationActions,
  changeFinancialMediationVersionActions,
  deleteFinancialMediationActions,
  deleteStateFinancialMediationDetailAction,
  getFinancialMediationActions,
  selectFinancialMediationDetail,
  updateFinancialMediationActions
} from "../ducks";
import { FinancialMediation } from "../types";

const FinancialMediationDetailContainer = () => {
  const { id } = useParams<EntityIdObject>() as { id: string };
  const financialMediation = useSelector<RootState, FinancialMediation | undefined>(selectFinancialMediationDetail);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getFinancialMediation: getFinancialMediationActions.request,
          updateFinancialMediation: updateFinancialMediationActions.request,
          deleteFinancialMediation: deleteFinancialMediationActions.request,
          changeFinancialMediationVersion: changeFinancialMediationVersionActions.request,
          assignFinancialMediation: assignFinancialMediationActions.request,
          deleteStateFinancialMediationDetail: deleteStateFinancialMediationDetailAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    actions.getFinancialMediation({ id });

    return () => {
      actions.deleteStateFinancialMediationDetail();
    };
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper>
      <DisplayWrapper itemLoaded={!!financialMediation} notFoundCheckRequest={requests.GET_FINANCIAL_MEDIATION}>
        <FinancialMediationForm
          financialMediation={financialMediation}
          onUpdate={actions.updateFinancialMediation}
          onDelete={actions.deleteFinancialMediation}
          onChangeVersion={actions.changeFinancialMediationVersion}
          onAssign={actions.assignFinancialMediation}
        />
      </DisplayWrapper>
    </ContentWrapper>
  );
};

export default FinancialMediationDetailContainer;
