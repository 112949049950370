import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { selectStandardProps } from "../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import { VehicleCategory } from "../../../../contract/enums";
import { CertificateVehicleCategoryFilterPageRequest, CertificateVehicleCategoryFilterPageResult } from "../../types";

interface Props {
  filter: CertificateVehicleCategoryFilterPageResult;
  onFilterSubmit: (filter: CertificateVehicleCategoryFilterPageRequest) => void;
  onCreateClick: () => void;
}

const VehicleCategoryFilterView = ({ filter, onFilterSubmit, onCreateClick }: Props) => {
  const [form] = Form.useForm<CertificateVehicleCategoryFilterPageRequest>();
  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: filter.keyword,
      categories: filter.categories || []
    });
  }, [filter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("vehicleCategory.titles.pageTitle")}</h2>}
      extra={
        <Button type="primary" icon={<AntIcon type="plus" />} onClick={onCreateClick}>
          {t("vehicleCategory.actions.createCategory")}
        </Button>
      }
    >
      <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="vehicleCategorySearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.search")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("vehicleCategory.helpers.filterSearchPlaceholder")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item name="categories" label={t("contract.enums.vehicleCategory._label")} rules={[validations.none]}>
              <Select
                {...selectStandardProps}
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                options={Object.keys(VehicleCategory).map(category => ({
                  value: category,
                  label: t("contract.enums.vehicleCategory." + category)
                }))}
              />
            </Form.Item>
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default VehicleCategoryFilterView;
