import { Card, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import classNames from "classnames";
import { DefaultOptionType } from "rc-select/lib/Select";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import BooleanRadioFormItem from "../../../../../common/components/form/components/BooleanRadioFormItem";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../common/constants";
import {
  inputNumberDecimalStandardProps,
  inputNumberIntegerStandardProps,
  selectStandardProps
} from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { FinancialSector } from "../../../../agent/enums";
import { ProductFinancialSector } from "../../../../product/enums";
import {
  AssetEvaluation,
  ClientFinancialServiceExperience,
  DepositRequirement,
  LifeInsuranceRequirement,
  LifeInsuranceRiskRequirement,
  LifeInsuranceSpecificRequirement,
  LoanPurpose,
  NonLifeInsuranceRequirement
} from "../../../enums";
import { FINANCIAL_MEDIATION_PILLAR_TO_REQUIREMENT_MAP } from "../../../utils";

interface Props {
  sector: ProductFinancialSector;
  showSecondaryClient: boolean;
  validateAllFields?: boolean;
}

interface SectorRequirementsProps {
  validateAllFields?: boolean;
  sector?: ProductFinancialSector;
}

const NON_LIFE_INSURANCE_REQUIREMENTS_MAP = new Map<string, NonLifeInsuranceRequirement[]>([
  [
    t("financialMediation.helpers.requirements.vehicleInsurance"),
    [NonLifeInsuranceRequirement.MTPL, NonLifeInsuranceRequirement.CRASH, NonLifeInsuranceRequirement.GAP]
  ],
  [
    t("financialMediation.helpers.requirements.personalInsurance"),
    [
      NonLifeInsuranceRequirement.REALTY,
      NonLifeInsuranceRequirement.HOUSEHOLD,
      NonLifeInsuranceRequirement.REALTY_AND_HOUSEHOLD_LIABILITY
    ]
  ],
  [
    t("financialMediation.helpers.requirements.businessInsurance"),
    [
      NonLifeInsuranceRequirement.BUSINESS,
      NonLifeInsuranceRequirement.BUSINESS_LIABILITY,
      NonLifeInsuranceRequirement.BUSINESS_OTHER
    ]
  ],
  [
    t("financialMediation.helpers.requirements.otherInsurance"),
    [
      NonLifeInsuranceRequirement.TRAVEL,
      NonLifeInsuranceRequirement.EMPLOYEE_LIABILITY,
      NonLifeInsuranceRequirement.OTHER
    ]
  ]
]);

const NonLifeInsuranceRequirementsSection = ({ validateAllFields }: SectorRequirementsProps) => (
  <>
    <Row gutter={rowGutter}>
      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientRequirements"]}
          label={
            <span className="form-item-additional">
              {t("financialMediation.enums.nonLifeInsuranceRequirement._label")}
            </span>
          }
          rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
        >
          <Select
            {...selectStandardProps}
            mode="multiple"
            maxTagCount="responsive"
            options={[...NON_LIFE_INSURANCE_REQUIREMENTS_MAP.keys()].map<DefaultOptionType>(groupName => ({
              label: groupName,
              options: NON_LIFE_INSURANCE_REQUIREMENTS_MAP.get(groupName)?.map<DefaultOptionType>(requirement => ({
                label: t("financialMediation.enums.nonLifeInsuranceRequirement." + requirement),
                value: requirement
              }))
            }))}
          />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientIsInterestedInDescription"]}
          label={t("financialMediation.attrs.mediationData.clientIsInterestedInDescription")}
          rules={[validations.size(1, 255)]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientIsNotInterestedInDescription"]}
          label={t("financialMediation.attrs.mediationData.clientIsNotInterestedInDescription")}
          rules={[validations.size(1, 255)]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const LifeInsuranceRequirementsSection = ({ validateAllFields }: SectorRequirementsProps) => (
  <>
    <Row gutter={rowGutter}>
      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientRequirements"]}
          label={
            <span className="form-item-additional">
              {t("financialMediation.enums.lifeInsuranceRequirement._label")}
            </span>
          }
          rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
        >
          <Select
            {...selectStandardProps}
            mode="multiple"
            maxTagCount="responsive"
            options={Object.keys(LifeInsuranceRequirement).map(requirement => ({
              label: t("financialMediation.enums.lifeInsuranceRequirement." + requirement),
              value: requirement
            }))}
          />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientSpecificRequirement"]}
          label={
            <span className="form-item-additional">
              {t("financialMediation.enums.lifeInsuranceSpecificRequirement._label")}
            </span>
          }
          rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
        >
          <Select
            {...selectStandardProps}
            options={Object.keys(LifeInsuranceSpecificRequirement).map(requirement => ({
              label: t("financialMediation.enums.lifeInsuranceSpecificRequirement." + requirement),
              value: requirement
            }))}
          />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientRiskRequirements"]}
          label={
            <span className="form-item-additional">
              {t("financialMediation.enums.lifeInsuranceRiskRequirement._label")}
            </span>
          }
          rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
        >
          <Select
            {...selectStandardProps}
            mode="multiple"
            maxTagCount="responsive"
            options={Object.keys(LifeInsuranceRiskRequirement).map(requirement => ({
              label: t("financialMediation.enums.lifeInsuranceRiskRequirement." + requirement),
              value: requirement
            }))}
          />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientIsInterestedInDescription"]}
          label={t("financialMediation.attrs.mediationData.clientIsInterestedInDescription")}
          rules={[validations.size(1, 255)]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientIsNotInterestedInDescription"]}
          label={t("financialMediation.attrs.mediationData.clientIsNotInterestedInDescription")}
          rules={[validations.size(1, 255)]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientRequirementsAdditionalStatement"]}
          label={
            <LabelWithTooltip
              label={t("financialMediation.attrs.mediationData.clientRequirementsAdditionalStatement")}
              tooltip={t("financialMediation.helpers.requirements.clientRequirementsAdditionalStatementDesc")}
            />
          }
          rules={[validations.size(1, 255)]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const LoanRequirementsSection = ({ validateAllFields }: SectorRequirementsProps) => (
  <>
    <Row gutter={rowGutter}>
      <Col span={4}>
        <Form.Item
          name={["mediationData", "loanAmount"]}
          label={<span className="form-item-additional">{t("financialMediation.attrs.mediationData.loanAmount")}</span>}
          rules={[validations.conditionalRule(validations.notNull, validateAllFields), validations.minNumber(1)]}
        >
          <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item
          name={["mediationData", "loanPurpose"]}
          label={<span className="form-item-additional">{t("financialMediation.enums.loanPurpose._label")}</span>}
          rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
        >
          <Select
            {...selectStandardProps}
            options={Object.keys(LoanPurpose).map(purpose => ({
              value: purpose,
              label: t("financialMediation.enums.loanPurpose." + purpose)
            }))}
          />
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item
          name={["mediationData", "loanMaturityInYears"]}
          label={
            <span className="form-item-additional">
              {t("financialMediation.attrs.mediationData.loanMaturityInYears")}
            </span>
          }
          rules={[validations.conditionalRule(validations.notNull, validateAllFields), validations.minNumber(1)]}
        >
          <InputNumber {...inputNumberIntegerStandardProps} />
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item
          name={["mediationData", "maxMonthlyPaymentAmount"]}
          label={
            <span className="form-item-additional">
              {t("financialMediation.attrs.mediationData.maxMonthlyPaymentAmount")}
            </span>
          }
          rules={[validations.conditionalRule(validations.notNull, validateAllFields), validations.minNumber(1)]}
        >
          <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item
          name={["mediationData", "securitySubject"]}
          label={
            <span className="form-item-additional">{t("financialMediation.attrs.mediationData.securitySubject")}</span>
          }
          rules={[validations.conditionalRule(validations.notBlank, validateAllFields), validations.size(1, 128)]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item
          name={["mediationData", "ltvRatio"]}
          label={<span className="form-item-additional">{t("financialMediation.attrs.mediationData.ltvRatio")}</span>}
          rules={[
            validations.conditionalRule(validations.notNull, validateAllFields),
            validations.minNumber(0.01),
            validations.maxNumber(100)
          ]}
        >
          <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="percentage" />} />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={8}>
        <Form.Item
          name={["mediationData", "anotherRelevantNeeds"]}
          label={
            <LabelWithTooltip
              label={t("financialMediation.attrs.mediationData.anotherRelevantNeeds")}
              tooltip={t("financialMediation.helpers.requirements.anotherRelevantNeedsDesc")}
            />
          }
          rules={[validations.size(1, 255)]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const DepositRequirementsSection = ({ validateAllFields }: SectorRequirementsProps) => (
  <>
    <Row gutter={rowGutter}>
      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientRequirements"]}
          label={
            <span className="form-item-additional">{t("financialMediation.enums.depositRequirement._label")}</span>
          }
          rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
        >
          <Select
            {...selectStandardProps}
            mode="multiple"
            maxTagCount="responsive"
            options={Object.keys(DepositRequirement).map(requirement => ({
              label: t("financialMediation.enums.depositRequirement." + requirement),
              value: requirement
            }))}
          />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={["mediationData", "mediationHeadingToSector"]}
          label={
            <span className="form-item-additional">
              {t("financialMediation.helpers.requirements.mediationHeadingToSector")}
            </span>
          }
          rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
        >
          <Select
            {...selectStandardProps}
            options={Object.keys(FinancialSector).map(sector => ({
              label: t("agent.enums.financialSector." + sector),
              value: sector
            }))}
          />
        </Form.Item>
      </Col>
    </Row>

    <Divider orientation="left" className="divider-subheader">
      {t("financialMediation.sections.otherClientRequirements")}
    </Divider>

    {[1, 2, 3].map((value, index) => (
      <Row gutter={rowGutter} key={index}>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.mediationData?.clientSpecificRequirements?.[index]?.selected !==
            next.mediationData?.clientSpecificRequirements?.[index]?.selected
          }
        >
          {({ getFieldValue }) => {
            const requirementSelected = getFieldValue([
              "mediationData",
              "clientSpecificRequirements",
              index,
              "selected"
            ]);

            return (
              <>
                <Col span={8}>
                  <Form.Item
                    name={["mediationData", "clientSpecificRequirements", index, "selected"]}
                    valuePropName="checked"
                    rules={[validations.none]}
                    initialValue={false}
                    className={classNames({ "form-item-without-label": requirementSelected })}
                  >
                    <Checkbox>{t("financialMediation.helpers.requirements.deposit.r" + value)}</Checkbox>
                  </Form.Item>
                </Col>

                {requirementSelected && (
                  <>
                    <Col span={4}>
                      <Form.Item
                        name={["mediationData", "clientSpecificRequirements", index, "depositAmount"]}
                        label={
                          <span className="form-item-additional">
                            {t("financialMediation.attrs.mediationData.clientSpecificRequirementsObj.depositAmount")}
                          </span>
                        }
                        rules={[
                          validations.conditionalRule(validations.notNull, validateAllFields),
                          validations.minNumber(1)
                        ]}
                      >
                        <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        name={["mediationData", "clientSpecificRequirements", index, "commitmentInYears"]}
                        label={
                          <span className="form-item-additional">
                            {t(
                              "financialMediation.attrs.mediationData.clientSpecificRequirementsObj.commitmentInYears"
                            )}
                          </span>
                        }
                        rules={[
                          validations.conditionalRule(validations.notNull, validateAllFields),
                          validations.minNumber(1)
                        ]}
                      >
                        <InputNumber {...inputNumberIntegerStandardProps} />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            );
          }}
        </Form.Item>
      </Row>
    ))}

    <Row gutter={rowGutter}>
      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientIsInterestedInDescription"]}
          label={t("financialMediation.attrs.mediationData.clientIsInterestedInDescription")}
          rules={[validations.size(1, 255)]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientIsNotInterestedInDescription"]}
          label={t("financialMediation.attrs.mediationData.clientIsNotInterestedInDescription")}
          rules={[validations.size(1, 255)]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={["mediationData", "anotherRelevantNeeds"]}
          label={t("financialMediation.attrs.mediationData.anotherRelevantNeeds")}
          rules={[validations.size(1, 255)]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const PillarsRequirementsSection = ({ validateAllFields, sector }: SectorRequirementsProps) => (
  <>
    <Row gutter={rowGutter}>
      <Col span={8}>
        <Form.Item
          name={["mediationData", "clientRequirements"]}
          label={
            <span className="form-item-additional">{t("financialMediation.enums.pillarsRequirement._label")}</span>
          }
          rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
        >
          <Select
            {...selectStandardProps}
            mode="multiple"
            maxTagCount="responsive"
            options={
              sector
                ? FINANCIAL_MEDIATION_PILLAR_TO_REQUIREMENT_MAP.get(sector)?.map(requirement => ({
                    label: t("financialMediation.enums.pillarsRequirement." + requirement),
                    value: requirement
                  }))
                : undefined
            }
          />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name={["mediationData", "assetEvaluation"]}
          label={<span className="form-item-additional">{t("financialMediation.enums.assetEvaluation._label")}</span>}
          rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
        >
          <Select
            {...selectStandardProps}
            options={Object.keys(AssetEvaluation).map(evaluation => ({
              label: t("financialMediation.enums.assetEvaluation." + evaluation),
              value: evaluation
            }))}
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);

const FinancialMediationFormRequirementsSection = ({ sector, showSecondaryClient, validateAllFields }: Props) => {
  let requirementsSection = undefined;
  switch (sector) {
    case ProductFinancialSector.NON_LIFE_INSURANCES:
      requirementsSection = <NonLifeInsuranceRequirementsSection validateAllFields={validateAllFields} />;
      break;
    case ProductFinancialSector.LIFE_INSURANCES:
      requirementsSection = <LifeInsuranceRequirementsSection validateAllFields={validateAllFields} />;
      break;
    case ProductFinancialSector.LOANS_AND_MORTGAGES:
      requirementsSection = <LoanRequirementsSection validateAllFields={validateAllFields} />;
      break;
    case ProductFinancialSector.DEPOSITS:
      requirementsSection = <DepositRequirementsSection validateAllFields={validateAllFields} />;
      break;
    case ProductFinancialSector.SENIOR_PENSION_SAVINGS:
    case ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS:
      requirementsSection = <PillarsRequirementsSection validateAllFields={validateAllFields} sector={sector} />;
      break;
  }

  const colSpan = 8;

  return (
    <Card type="inner" className="card-box" title={t("financialMediation.sections.clientsRequirements")}>
      {requirementsSection}

      <Divider orientation="left" className="divider-subheader">
        {t("financialMediation.sections.clientsExperience")}
      </Divider>

      <Row gutter={rowGutter}>
        <Col span={12}>
          {sector === ProductFinancialSector.NON_LIFE_INSURANCES ||
          sector === ProductFinancialSector.LIFE_INSURANCES ||
          sector === ProductFinancialSector.LOANS_AND_MORTGAGES ||
          sector === ProductFinancialSector.DEPOSITS ? (
            <>
              <Row gutter={rowGutter}>
                <Col offset={colSpan} span={colSpan} className="center-align">
                  <b>{t("financialMediation.attrs.policyHolder")}</b>
                </Col>

                <Col span={colSpan} className="center-align">
                  {showSecondaryClient && <b>{t("financialMediation.attrs.secondaryClient")}</b>}
                </Col>
              </Row>

              <Row gutter={rowGutter}>
                <Col span={colSpan} className={classNames("table-row-form-label", "form-item-additional")}>
                  {t("financialMediation.enums.serviceExperience._label")}
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["mediationData", "policyHolderData", "serviceExperience"]}
                    rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
                  >
                    <Select
                      {...selectStandardProps}
                      options={Object.keys(ClientFinancialServiceExperience).map(experience => ({
                        value: experience,
                        label: t("financialMediation.enums.serviceExperience." + experience)
                      }))}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  {showSecondaryClient && (
                    <Form.Item
                      name={["mediationData", "secondaryClientData", "serviceExperience"]}
                      rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
                    >
                      <Select
                        {...selectStandardProps}
                        options={Object.keys(ClientFinancialServiceExperience).map(experience => ({
                          value: experience,
                          label: t("financialMediation.enums.serviceExperience." + experience)
                        }))}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>

              <Row gutter={rowGutter}>
                <Col span={colSpan} className="form-item-additional">
                  {t("financialMediation.attrs.mediationData.policyHolderData.understandService")}
                </Col>

                <Col span={colSpan} className="center-align">
                  <BooleanRadioFormItem
                    formItemProps={{
                      name: ["mediationData", "policyHolderData", "understandService"],
                      rules: [validations.conditionalRule(validations.notNull, validateAllFields)],
                      className: "margin-bottom-small"
                    }}
                  />
                </Col>

                <Col span={colSpan} className="center-align">
                  {showSecondaryClient && (
                    <BooleanRadioFormItem
                      formItemProps={{
                        name: ["mediationData", "secondaryClientData", "understandService"],
                        rules: [validations.conditionalRule(validations.notNull, validateAllFields)],
                        className: "margin-bottom-small"
                      }}
                    />
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <span className="sub-header-info margin-bottom-small form-item-additional">
                {t("financialMediation.helpers.experiencedClient.desc", {
                  sector: t("product.enums.financialSector." + sector)
                })}
              </span>

              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "experiencedClient"],
                  rules: [validations.conditionalRule(validations.notNull, validateAllFields)],
                  className: "margin-bottom-small"
                }}
                trueLabel={t("financialMediation.helpers.experiencedClient.true")}
                falseLabel={t("financialMediation.helpers.experiencedClient.false")}
              />
            </>
          )}
        </Col>

        <Col span={12}>
          <Row gutter={rowGutter}>
            <Col span={colSpan * 2}>
              <Form.Item
                name={["mediationData", "clientExperienceAdditionalStatement"]}
                label={t("financialMediation.attrs.mediationData.clientExperienceAdditionalStatement")}
                rules={[validations.size(1, 255)]}
              >
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider orientation="left" className="divider-subheader">
        {t("financialMediation.sections.conflictOfInterest")}
      </Divider>

      <Row gutter={rowGutter}>
        <Col span={12}>
          <span className="sub-header-info form-item-additional">
            {t("financialMediation.helpers.conflictOfInterest.desc")}
          </span>

          <Row gutter={rowGutter}>
            <Col span={24}>
              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "conflictOfInterestIdentified"],
                  rules: [validations.conditionalRule(validations.notNull, validateAllFields)]
                }}
                trueLabel={t("financialMediation.helpers.conflictOfInterest.identified")}
                falseLabel={t("financialMediation.helpers.conflictOfInterest.unidentified")}
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.mediationData?.conflictOfInterestIdentified !== next.mediationData?.conflictOfInterestIdentified
            }
          >
            {({ getFieldValue }) =>
              getFieldValue(["mediationData", "conflictOfInterestIdentified"]) && (
                <Row gutter={rowGutter}>
                  <Col span={colSpan * 2}>
                    <Form.Item
                      name={["mediationData", "conflictOfInterestDescription"]}
                      label={
                        <LabelWithTooltip
                          label={t("financialMediation.attrs.mediationData.conflictOfInterestDescription")}
                          tooltip={t("financialMediation.helpers.conflictOfInterest.identifiedDesc")}
                          className="form-item-additional"
                        />
                      }
                      rules={[
                        validations.conditionalRule(validations.notBlank, validateAllFields),
                        validations.size(1, 255)
                      ]}
                    >
                      <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                    </Form.Item>
                  </Col>
                </Row>
              )
            }
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default FinancialMediationFormRequirementsSection;
