import { Card } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../../common/constants";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../../ducks";
import { selectUsersEnums } from "../../../enumerations/ducks";
import { UserBase } from "../../../user/types";
import { CalcType } from "../../enums";
import CalcRecordRequestResponseLogsModal from "../components/modals/CalcRecordRequestResponseLogsModal";
import CalcRecordStackTracesLogsModal from "../components/modals/CalcRecordStackTracesLogsModal";
import CalcRecordWebservicesLogsModal from "../components/modals/CalcRecordWebservicesLogsModal";
import CalcRecordsListFilterView from "../components/views/CalcRecordsListFilterView";
import CalcRecordsListTableView from "../components/views/CalcRecordsListTableView";
import {
  deleteStateCalcRecordDetailAction,
  deleteStateCalcRecordsPageAction,
  filterCalcRecordsActions,
  getCalcRecordRequestResponseActions,
  getCalcRecordStackTracesLogsActions,
  getCalcRecordWebservicesLogsActions,
  selectCalcRecordDetail,
  selectCalcRecordsCurrentPage
} from "../ducks";
import { OperationType } from "../enums";
import { CalcRecord, CalcRecordFilterPageRequest, CalcRecordFilterPageResult } from "../types";

interface StateProps {
  calcRecordsCurrentPage: CalcRecordFilterPageResult;
  calcRecord?: CalcRecord;
  usersEnums: UserBase[];
  urlSearchQuery: string;
}

interface ActionsMap {
  filterCalcRecords: typeof filterCalcRecordsActions.request;
  getCalcRecordRequestResponse: typeof getCalcRecordRequestResponseActions.request;
  getCalcRecordWebservicesLogs: typeof getCalcRecordWebservicesLogsActions.request;
  getCalcRecordStackTracesLogs: typeof getCalcRecordStackTracesLogsActions.request;
  deleteStateCalcRecordsPage: typeof deleteStateCalcRecordsPageAction;
  deleteStateCalcRecordDetail: typeof deleteStateCalcRecordDetailAction;
}

const CalcRecordsContainer = ({
  calcRecordsCurrentPage,
  calcRecord,
  ...props
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [requestResponseLogsModalOpen, setRequestResponseLogsModalOpen] = useState<boolean>(false);
  const [webserviceLogsModalOpen, setWebserviceLogsModalOpen] = useState<boolean>(false);
  const [stackTraceLogsModalOpen, setStackTraceLogsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(props.urlSearchQuery);
    const userIdUrlParam = urlParams.get("userId");

    props.actions.filterCalcRecords({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      calcType: CalcType[urlParams.get("calcType") as keyof typeof CalcType],
      operationType: OperationType[urlParams.get("operationType") as keyof typeof OperationType],
      userId: userIdUrlParam && props.usersEnums.some(user => user.id === userIdUrlParam) ? userIdUrlParam : undefined
    });

    return () => {
      props.actions.deleteStateCalcRecordsPage();
      props.actions.deleteStateCalcRecordDetail();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (calcRecord) {
      if (calcRecord.request && calcRecord.response) {
        setRequestResponseLogsModalOpen(true);
      } else if (calcRecord.webservicesLogs && calcRecord.webservicesLogs.length > 0) {
        setWebserviceLogsModalOpen(true);
      } else if (calcRecord.stackTracesLogs && calcRecord.stackTracesLogs.length > 0) {
        setStackTraceLogsModalOpen(true);
      }
    }
  }, [calcRecord]);

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, operationType, calcType, userId } = calcRecordsCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.filterCalcRecords({
      pageIndex: pageNumber - 1,
      pageSize,
      operationType,
      calcType,
      userId
    });
  };

  const handleFilterSubmit = (filter: CalcRecordFilterPageRequest): void => {
    navigate(appendSearchParamsToURL({ ...filter, pageIndex: undefined }), { replace: true });
    props.actions.filterCalcRecords({
      ...filter,
      pageIndex: 0,
      pageSize: calcRecordsCurrentPage.pageSize
    });
  };

  const handleLogsModalsCancelClick = (): void => {
    setRequestResponseLogsModalOpen(false);
    setWebserviceLogsModalOpen(false);
    setStackTraceLogsModalOpen(false);
  };

  const handleLogsModalsAfterCloseExecuted = (): void => {
    props.actions.deleteStateCalcRecordDetail();
  };

  return (
    <ContentWrapper>
      <CalcRecordsListFilterView initialFilter={calcRecordsCurrentPage} onFilterSubmit={handleFilterSubmit} />

      <Card>
        <CalcRecordsListTableView
          calcRecordsPage={calcRecordsCurrentPage}
          onPageChange={handleTablePageChange}
          onGetRequestResponseLogs={props.actions.getCalcRecordRequestResponse}
          onGetWebserviceLogs={props.actions.getCalcRecordWebservicesLogs}
          onGetStackTraceLogs={props.actions.getCalcRecordStackTracesLogs}
        />

        <CalcRecordRequestResponseLogsModal
          open={requestResponseLogsModalOpen}
          record={calcRecord}
          onCancelClick={handleLogsModalsCancelClick}
          onAfterClose={handleLogsModalsAfterCloseExecuted}
        />

        <CalcRecordWebservicesLogsModal
          open={webserviceLogsModalOpen}
          record={calcRecord}
          onCancelClick={handleLogsModalsCancelClick}
          onAfterClose={handleLogsModalsAfterCloseExecuted}
        />

        <CalcRecordStackTracesLogsModal
          open={stackTraceLogsModalOpen}
          record={calcRecord}
          onCancelClick={handleLogsModalsCancelClick}
          onAfterClose={handleLogsModalsAfterCloseExecuted}
        />
      </Card>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  calcRecordsCurrentPage: selectCalcRecordsCurrentPage(state),
  calcRecord: selectCalcRecordDetail(state),
  usersEnums: selectUsersEnums(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterCalcRecords: filterCalcRecordsActions.request,
      getCalcRecordRequestResponse: getCalcRecordRequestResponseActions.request,
      getCalcRecordWebservicesLogs: getCalcRecordWebservicesLogsActions.request,
      getCalcRecordStackTracesLogs: getCalcRecordStackTracesLogsActions.request,
      deleteStateCalcRecordsPage: deleteStateCalcRecordsPageAction,
      deleteStateCalcRecordDetail: deleteStateCalcRecordDetailAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CalcRecordsContainer);
