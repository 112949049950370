import { Card, Col, Form, Modal, Radio, Row } from "antd";
import isEqual from "lodash/isEqual";
import { FieldData } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import t from "../../../../../../app/i18n";
import { SecondaryButton } from "../../../../../../common/components/buttons/SecondaryButton";
import AntIcon, { type AntIconType } from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import { resolveFormValidationError, setErrorsToForm } from "../../../../../../common/utils/formUtils";
import { useScrollToTopOnLoad } from "../../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { DashboardNoticePosition } from "../../../../../dashboard/enums";
import { CalcType } from "../../../../enums";
import { CALC_ROUTE_PATHS } from "../../../../paths";
import CalcNavigation from "../../../components/CalcNavigation";
import CalcNoticesIconView from "../../../components/CalcNoticesIconView";
import { CalcDataSource, CalcResult } from "../../../types";
import { deleteStateTravelCalcResultsAction } from "../../ducks";
import { TravelInsuranceType } from "../../enums";
import { TravelCalc, TravelCalcResultData } from "../../types";
import TravelCalcResults from "../result/TravelCalcResults";
import TravelCalcForm from "./TravelCalcForm";

interface Props {
  calcData?: TravelCalc;
  calcDataSource: CalcDataSource;
  calcResults: CalcResult<TravelCalcResultData>[][];
  onCalcResultsDelete: typeof deleteStateTravelCalcResultsAction;
  onCalculationFormSubmit: (calcData: TravelCalc) => void;
  onGenerateContractClick: (calcData: TravelCalc, selectedResult: CalcResult<TravelCalcResultData>) => void;
  onGenerateOfferClick: (calcData: TravelCalc) => void;
  onSaveDraftClick: (calcData: TravelCalc, overwriteExisting: boolean) => void;
  onResetCalculatorClick: () => void;
  onCalcDataSourceReset: () => void;
}

const TravelCalcWrapper = (props: Props) => {
  useScrollToTopOnLoad();

  const [form] = Form.useForm<TravelCalc>();

  const [resultsOpen, setResultsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.calcData) {
      const { generalData, cancellationData, coveragesData, discountsData } = props.calcData;
      form.setFieldsValue({
        ...props.calcData,
        generalData: {
          ...generalData,
          studyStay: !!generalData.studyStay
        },
        cancellationData: cancellationData
          ? {
              ...cancellationData,
              extendedCoverage: !!cancellationData.extendedCoverage,
              complicity: !!cancellationData.complicity
            }
          : undefined,
        coveragesData: coveragesData
          ? {
              ...coveragesData,
              injury: !!coveragesData.injury,
              liability: !!coveragesData.liability,
              rescueService: !!coveragesData.rescueService,
              luggage: !!coveragesData.luggage,
              covid: !!coveragesData.covid,
              airplaneTravel: !!coveragesData.airplaneTravel,
              cancellation: !!coveragesData.cancellation,
              cancellationData: coveragesData.cancellationData
                ? {
                    ...coveragesData.cancellationData,
                    extendedCoverage: !!coveragesData.cancellationData.extendedCoverage,
                    complicity: !!coveragesData.cancellationData.complicity
                  }
                : undefined,
              drinkAllowed: !!coveragesData.drinkAllowed,
              vehicleAssistance: !!coveragesData.vehicleAssistance,
              vehicleRental: !!coveragesData.vehicleRental,
              electronicDevices: !!coveragesData.electronicDevices,
              travelDocuments: !!coveragesData.travelDocuments,
              pet: !!coveragesData.pet,
              abandonedHousehold: !!coveragesData.abandonedHousehold
            }
          : undefined,
        discountsData: discountsData
          ? {
              ...discountsData,
              familyInsurance: !!discountsData.familyInsurance,
              unionHealthInsuranceClient: !!discountsData.unionHealthInsuranceClient
            }
          : undefined,
        type: undefined
      });
      props.onCalcDataSourceReset();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.calcDataSource === "init" && props.calcResults.length > 0) {
      setResultsOpen(true);
    }
  }, [props.calcResults]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInsuranceTypeChange = (type: TravelInsuranceType): void => {
    if (type === TravelInsuranceType.YEAR) {
      form.setFieldsValue({ generalData: { effectiveEndDate: undefined } });
    }
  };

  const handleFormFieldsChange = (changedFields: FieldData[]): void => {
    if (props.calcResults.length > 0 && changedFields?.length > 0) {
      props.onCalcResultsDelete();
    }
  };

  const handleCalculationFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (props.calcDataSource !== "init") {
          props.onCalcDataSourceReset();
        }
        props.onCalculationFormSubmit(processAndGetCalcFormData(values));
      })
      .catch(errorInfo => {
        messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        resolveFormValidationError(errorInfo);
      });
  };

  const handleResultsSaveDraftClick = (overwriteExisting: boolean): void => {
    setResultsOpen(false);
    if (checkAreCalcFormDataUnchanged()) {
      props.onSaveDraftClick(processAndGetCalcFormData(), overwriteExisting);
    }
  };

  const handleResultsGenerateOfferClick = (): void => {
    setResultsOpen(false);
    if (checkAreCalcFormDataUnchanged()) {
      props.onGenerateOfferClick(processAndGetCalcFormData());
    }
  };

  const handleResultsGenerateContractClick = (result: CalcResult<TravelCalcResultData>): void => {
    setResultsOpen(false);
    if (checkAreCalcFormDataUnchanged()) {
      props.onGenerateContractClick(processAndGetCalcFormData(), result);
    }
  };

  const handleResultShowErrorsClick = (result: CalcResult<TravelCalcResultData>): void => {
    if (result.error) {
      setErrorsToForm(form, "calc.travel.attrs", result.error.violations);
      setResultsOpen(false);
    }
  };

  const processAndGetCalcFormData = (calcData?: TravelCalc): TravelCalc => {
    return {
      ...(calcData ? { ...calcData } : form.getFieldsValue()),
      type: CalcType.TRAVEL
    };
  };

  const checkAreCalcFormDataUnchanged = (): boolean => {
    const calcDataUnchanged = isEqual(props.calcData, processAndGetCalcFormData());

    if (!calcDataUnchanged) {
      Modal.warning({
        title: t("common.warning"),
        content: t("calc.validations.calcDataChanged"),
        okText: t("calc.actions.recalculationSubmit"),
        maskClosable: true,
        onOk: handleCalculationFormSubmit,
        onCancel: handleCalculationFormSubmit
      });
    }

    if (props.calcDataSource !== "init") {
      props.onCalcDataSourceReset();
    }

    return calcDataUnchanged;
  };

  const resolvePageTitle = (type: TravelInsuranceType): string => {
    return type ? t("calc.travel.titles.insuranceType." + type) : t("calc.travel.titles.noSelectedInsurance");
  };

  const resolveInsuranceTypeIcon = (type: TravelInsuranceType): AntIconType => {
    switch (type) {
      case TravelInsuranceType.SHORT_TERM:
        return "schedule";
      case TravelInsuranceType.YEAR:
        return "global";
      case TravelInsuranceType.CANCELLATION:
        return "euro";
      default:
        return "schedule";
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" name="travelCalcForm" onFieldsChange={handleFormFieldsChange}>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.generalData?.insuranceType !== next.generalData?.insuranceType}
        >
          {({ getFieldValue }) => {
            const insuranceType = getFieldValue(["generalData", "insuranceType"]);
            return (
              <>
                <Card
                  className="card-box card-box--inner-extra"
                  title={<h2>{resolvePageTitle(insuranceType)}</h2>}
                  extra={
                    <>
                      <CalcNoticesIconView position={DashboardNoticePosition.TRAVEL_CALC} />
                      <Link to={CALC_ROUTE_PATHS.travelDraft.to}>
                        <SecondaryButton icon={<AntIcon type="form" />}>{t("calc.actions.showDrafts")}</SecondaryButton>
                      </Link>
                    </>
                  }
                >
                  <Row gutter={rowGutter} justify="center">
                    <Col span={24} className="center-align">
                      <Form.Item
                        name={["generalData", "insuranceType"]}
                        rules={[validations.notNull]}
                        className="no-bottom-space"
                      >
                        <Radio.Group
                          size="large"
                          buttonStyle="solid"
                          className="radio-group-icon"
                          onChange={e => handleInsuranceTypeChange(e.target.value)}
                        >
                          {Object.values(TravelInsuranceType).map(type => (
                            <Radio.Button value={type} key={type} style={{ width: 168 }}>
                              <AntIcon type={resolveInsuranceTypeIcon(type)} />
                              <span>{t("calc.travel.enums.insuranceType." + type)}</span>
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <TravelCalcForm form={form} insuranceType={insuranceType} />

                <TravelCalcResults
                  open={resultsOpen}
                  insuranceType={insuranceType}
                  calcResults={props.calcResults}
                  onClose={() => setResultsOpen(false)}
                  onSaveDraftClick={handleResultsSaveDraftClick}
                  onGenerateOfferClick={handleResultsGenerateOfferClick}
                  onGenerateContractClick={handleResultsGenerateContractClick}
                  onShowErrorsClick={handleResultShowErrorsClick}
                />
              </>
            );
          }}
        </Form.Item>
      </Form>

      <CalcNavigation
        hasResults={props.calcResults.length > 0}
        onFormSubmit={handleCalculationFormSubmit}
        onShowResultsClick={() => setResultsOpen(true)}
        onResetCalculatorClick={props.onResetCalculatorClick}
      />
    </>
  );
};

export default TravelCalcWrapper;
