import { Breadcrumb } from "antd";
import { BreadcrumbsItemType } from "../../../../app/components/layout/BreadcrumbsBox";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import { DocumentNodeType } from "../../enums";
import { DocumentNodeTree } from "../../types";
import styles from "./DocumentNodeBreadcrumbsView.module.scss";

interface Props {
  breadcrumbs?: DocumentNodeTree[];
  treeNodeType: DocumentNodeType;
  onClick: (breadcrumb?: DocumentNodeTree) => void;
}

const DocumentNodeBreadcrumbsView = ({ breadcrumbs, treeNodeType, onClick }: Props) => {
  const getItems = () => {
    const items: BreadcrumbsItemType[] = [
      {
        title: (
          <ActionTextIcon onClick={onClick} icon="home" color="blue" text={t(`documentNode.enums.${treeNodeType}`)} />
        )
      }
    ];

    breadcrumbs?.map((breadcrumb, index) =>
      index === breadcrumbs.length - 1
        ? items.push({ title: breadcrumb.name, className: styles.documentsBreadcrumbsLink })
        : items.push({
            title: (
              <span className={styles.documentsBreadcrumbsLink} onClick={() => onClick(breadcrumb)}>
                {breadcrumb.name}
              </span>
            )
          })
    );

    return items;
  };

  return (
    <div className={styles.documentsBreadcrumbs}>
      <Breadcrumb items={getItems()} />
    </div>
  );
};

export default DocumentNodeBreadcrumbsView;
