import Ellipsis from "../../../../../../common/components/views/Ellipsis";
import ContactTypeIcon from "../../../../../../common/modules/contact/ContactTypeIcon";
import { ContactType } from "../../../../../../common/modules/enums";
import { formatPhoneNumber } from "../../../../../../common/utils/formatUtils";
import styles from "./DashboardContactFooterView.module.scss";

interface Props {
  email?: string;
  phone?: string;
}

export const DashboardContactFooterView = ({ phone, email }: Props) => (
  <div className={styles.dashboardContactFooter}>
    <p className={styles.dashboardContactFooterOption}>
      {email ? (
        <>
          <span style={{ paddingRight: 10 }}>
            <ContactTypeIcon type={ContactType.EMAIL} />
          </span>
          <a className={styles.dashboardContactFooterLink} href={`mailto:${email}`}>
            <Ellipsis className={styles.dashboardContactFooterOptionDetail} tooltip={email}>
              {email}
            </Ellipsis>
          </a>
        </>
      ) : (
        <span>&nbsp;</span>
      )}
    </p>

    <p className={styles.dashboardContactFooterOption}>
      {phone ? (
        <>
          <span style={{ paddingRight: 10 }}>
            <ContactTypeIcon type={ContactType.PHONE_NUMBER} />
          </span>
          <a className={styles.dashboardContactFooterLink} href={`tel:${phone}`}>
            <Ellipsis className={styles.dashboardContactFooterOptionDetail} tooltip={formatPhoneNumber(phone)}>
              {formatPhoneNumber(phone)}
            </Ellipsis>
          </a>
        </>
      ) : (
        <span>&nbsp;</span>
      )}
    </p>
  </div>
);
