export enum ErrorResponseType {
  GENERAL = "GENERAL",
  VALIDATION = "VALIDATION",
  OPTIMISTIC_LOCK = "OPTIMISTIC_LOCK",
  EXTERNAL_WEBSERVICE = "EXTERNAL_WEBSERVICE"
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export enum MobileDeviceOs {
  ANDROID = "ANDROID",
  IOS = "IOS"
}

export enum Month {
  JANUARY = "JANUARY",
  FEBRUARY = "FEBRUARY",
  MARCH = "MARCH",
  APRIL = "APRIL",
  MAY = "MAY",
  JUNE = "JUNE",
  JULY = "JULY",
  AUGUST = "AUGUST",
  SEPTEMBER = "SEPTEMBER",
  OCTOBER = "OCTOBER",
  NOVEMBER = "NOVEMBER",
  DECEMBER = "DECEMBER"
}

export enum ExportFileType {
  CSV = "CSV",
  XLSX = "XLSX"
}
