import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import apiService from "../../../common/api/apiService";
import {
  EntityObject,
  OptimisticLockVersion,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import { Commission, UpdateCommission } from "../batch/types";
import {
  DuplicatePostponedCommission,
  PostponedCommissionsFilterPageRequest,
  PostponedCommissionsFilterPageResult
} from "./types";

const FILTER_POSTPONED_COMMISSIONS = ApiRequestAdapter.create("/postponed-commissions", "GET");
const UPDATE_POSTPONED_COMMISSION = ApiRequestAdapter.create("/postponed-commissions/{0}", "PUT");
const RESOLVE_POSTPONED_COMMISSION = ApiRequestAdapter.create("/postponed-commissions/{0}/resolve", "POST");
const UNRESOLVE_POSTPONED_COMMISSION = ApiRequestAdapter.create("/postponed-commissions/{0}/unresolve", "POST");
const CREATE_DUPLICATED_COMMISSION = ApiRequestAdapter.create(
  "/commissions-batches/{0}/duplicated-commissions",
  "POST"
);
const UPDATE_DUPLICATED_COMMISSION = ApiRequestAdapter.create(
  "/commissions-batches/{0}/duplicated-commissions/{1}",
  "PUT"
);
const DELETE_DUPLICATED_COMMISSION = ApiRequestAdapter.create(
  "/commissions-batches/{0}/duplicated-commissions/{1}",
  "DELETE"
);

export const requests = {
  FILTER_POSTPONED_COMMISSIONS,
  UPDATE_POSTPONED_COMMISSION,
  RESOLVE_POSTPONED_COMMISSION,
  UNRESOLVE_POSTPONED_COMMISSION,
  CREATE_DUPLICATED_COMMISSION,
  UPDATE_DUPLICATED_COMMISSION,
  DELETE_DUPLICATED_COMMISSION
};

const api = {
  filterPostponedCommissions: (
    request: PostponedCommissionsFilterPageRequest
  ): AxiosPromise<PostponedCommissionsFilterPageResult> => {
    return apiService.get(FILTER_POSTPONED_COMMISSIONS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  updatePostponedCommission: (request: EntityObject<UpdateCommission>): AxiosPromise<Commission> => {
    return apiService.put(UPDATE_POSTPONED_COMMISSION.getParametrizedUrl(request.id), request.object);
  },
  resolvePostponedCommission: (request: EntityObject<OptimisticLockVersion>): AxiosPromise<Commission> => {
    return apiService.post(RESOLVE_POSTPONED_COMMISSION.getParametrizedUrl(request.id), request.object);
  },
  unresolvePostponedCommission: (request: EntityObject<OptimisticLockVersion>): AxiosPromise<Commission> => {
    return apiService.post(UNRESOLVE_POSTPONED_COMMISSION.getParametrizedUrl(request.id), request.object);
  },
  createDuplicatedCommission: (request: EntityObject<DuplicatePostponedCommission>): AxiosPromise<Commission> => {
    return apiService.post(CREATE_DUPLICATED_COMMISSION.getParametrizedUrl(request.id), request.object);
  },
  updateDuplicatedCommission: (request: TwoLevelEntityObject<UpdateCommission>): AxiosPromise<Commission> => {
    return apiService.put(UPDATE_DUPLICATED_COMMISSION.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteDuplicatedCommission: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_DUPLICATED_COMMISSION.getParametrizedUrl(request.id1, request.id2));
  }
};

export default api;
