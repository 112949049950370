import { Button, Col, Drawer, Popconfirm, Row } from "antd";
import { useSelector } from "react-redux";
import t from "../../../../../../app/i18n";
import { SecondaryButton } from "../../../../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { DrawerSizes } from "../../../../../../common/constants";
import { RootState } from "../../../../../../common/types";
import VehicleBrandEnumName from "../../../../../enumerations/components/view/VehicleBrandEnumName";
import VehicleModelEnumName from "../../../../../enumerations/components/view/VehicleModelEnumName";
import { CalcType } from "../../../../enums";
import { CalcResult } from "../../../types";
import { selectVehicleDraft } from "../../ducks";
import {
  VehicleCalcDraft,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleCalcType,
  VehicleOfferType
} from "../../types";
import { resolveVehicleOfferType } from "../../utils";
import styles from "./VehicleCalcResults.module.scss";
import VehicleCalcResultsColumn from "./VehicleCalcResultsColumn";

interface Props {
  open: boolean;
  calcType: VehicleCalcType;
  calcResults: VehicleCalcResults;
  selectedResult?: CalcResult<VehicleCalcResultData>;
  onClose: () => void;
  onSaveDraftClick: (overwriteExisting: boolean) => void;
  onGenerateOfferClick: (type: VehicleOfferType) => void;
  onGenerateContractClick: (result: CalcResult<VehicleCalcResultData>) => void;
  onShowErrorsClick?: (result: CalcResult<VehicleCalcResultData>) => void;
}

const VehicleCalcResultsView = ({ open, calcType, calcResults, selectedResult, ...props }: Props) => {
  const draft = useSelector<RootState, VehicleCalcDraft | undefined>(selectVehicleDraft);

  return (
    <Drawer
      title={t("calc.vehicle.sections.results")}
      width={calcType === CalcType.CRASH ? DrawerSizes.MEDIUM : DrawerSizes.LARGE}
      open={open}
      closable
      onClose={props.onClose}
    >
      <Row>
        <Col flex={1}>
          <div style={{ paddingBottom: 65 }}>
            <VehicleCalcResultsColumn
              calcType={calcType}
              calcResults={calcResults}
              selectedResult={selectedResult}
              threeColumnsLayout={calcType === CalcType.MTPL || calcType === CalcType.MTPL_CRASH}
              onGenerateContractClick={props.onGenerateContractClick}
              onShowErrorsClick={props.onShowErrorsClick}
            />
          </div>

          <div
            className={styles.calcResultsNavigation}
            style={{ width: (calcType === CalcType.CRASH ? DrawerSizes.MEDIUM : DrawerSizes.LARGE) - 65 }}
          >
            <div>
              {draft ? (
                <Popconfirm
                  title={
                    <>
                      <b>{t("calc.helpers.loadedDraftTitle")}:</b>
                      <div className="margin-top-tiny margin-bottom-tiny">
                        {draft.clientName}
                        <br />
                        <VehicleBrandEnumName
                          brandId={draft.draftData.vehicleData.brandId}
                          otherBrandName={draft.draftData.vehicleData.customBrand}
                        />{" "}
                        <VehicleModelEnumName
                          brandId={draft.draftData.vehicleData.brandId}
                          modelId={draft.draftData.vehicleData.modelId}
                          otherModelName={draft.draftData.vehicleData.customModel}
                        />
                        <br />
                        {draft.draftData.vehicleData.licensePlate}{" "}
                        {draft.draftData.vehicleData.vin && ` | ${draft.draftData.vehicleData.vin}`}
                        <br />
                        {t("calc.vehicle.enums.vehiclePurpose." + draft.draftData.vehicleData.purpose)}
                      </div>
                      <i>{t("calc.helpers.loadedDraftDesc")}</i>
                    </>
                  }
                  icon={<AntIcon type="question" />}
                  okText={t("calc.actions.overwriteDraft")}
                  cancelText={t("calc.actions.saveNewDraft")}
                  overlayStyle={{ maxWidth: 450 }}
                  onConfirm={() => props.onSaveDraftClick(true)}
                  onCancel={() => props.onSaveDraftClick(false)}
                >
                  <SecondaryButton icon={<AntIcon type="form" />}>{t("calc.actions.saveDraft")} </SecondaryButton>
                </Popconfirm>
              ) : (
                <SecondaryButton icon={<AntIcon type="form" />} onClick={() => props.onSaveDraftClick(false)}>
                  {t("calc.actions.saveDraft")}
                </SecondaryButton>
              )}
            </div>

            <Button
              type="primary"
              icon={<AntIcon type="file-text" />}
              onClick={() => {
                const type = resolveVehicleOfferType(calcType);
                if (type) {
                  return props.onGenerateOfferClick(type);
                }
              }}
            >
              {t("calc.actions.generateOffer")}
            </Button>

            <Button icon={<AntIcon type="double-right" />} onClick={props.onClose}>
              {t("common.close")}
            </Button>
          </div>
        </Col>
      </Row>
    </Drawer>
  );
};

export default VehicleCalcResultsView;
