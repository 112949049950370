import { Route, Routes } from "react-router-dom";
import DashboardContainer from "./containers/DashboardContainer";

const routes = () => (
  <Routes>
    <Route index element={<DashboardContainer />} />
  </Routes>
);

export default routes;
