import { Permission } from "../../common/security/authorization/enums";
import { InstitutionType } from "../institution/enums";
import { ProductFinancialSector } from "./enums";

export const PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP = new Map<ProductFinancialSector, InstitutionType>([
  [ProductFinancialSector.NON_LIFE_INSURANCES, InstitutionType.INSURANCE_COMPANY],
  [ProductFinancialSector.LIFE_INSURANCES, InstitutionType.INSURANCE_COMPANY],
  [ProductFinancialSector.LOANS_AND_MORTGAGES, InstitutionType.BANK],
  [ProductFinancialSector.CAPITAL_MARKET, InstitutionType.SECURITIES_BROKER],
  [ProductFinancialSector.DEPOSITS, InstitutionType.BUILDING_SAVINGS_BANK],
  [ProductFinancialSector.SENIOR_PENSION_SAVINGS, InstitutionType.MANAGERIAL_PENSION_COMPANY],
  [ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS, InstitutionType.SUPPLEMENTARY_PENSION_COMPANY],
  [ProductFinancialSector.OTHERS, InstitutionType.OTHER]
]);

export const PRODUCT_SECTOR_TO_UPDATE_CONTRACT_PERMISSION_MAP = new Map<ProductFinancialSector, Permission>([
  [ProductFinancialSector.NON_LIFE_INSURANCES, Permission.INSURANCE_UPDATE],
  [ProductFinancialSector.LIFE_INSURANCES, Permission.INSURANCE_UPDATE],
  [ProductFinancialSector.LOANS_AND_MORTGAGES, Permission.LOAN_UPDATE],
  [ProductFinancialSector.CAPITAL_MARKET, Permission.INVESTMENT_UPDATE],
  [ProductFinancialSector.DEPOSITS, Permission.DEPOSIT_UPDATE],
  [ProductFinancialSector.SENIOR_PENSION_SAVINGS, Permission.SECOND_PILLAR_UPDATE],
  [ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS, Permission.THIRD_PILLAR_UPDATE],
  [ProductFinancialSector.OTHERS, Permission.GENERIC_UPDATE]
]);
