import { Tag } from "antd";
import { tInterval } from "../../utils/translationUtils";

interface Props {
  days: number;
  style?: React.CSSProperties;
}

const DaysCountTag = ({ days, style }: Props) => (
  <Tag color={days <= 0 ? "red" : "geekblue"} style={style}>
    {days < 0 ? <>-</> : undefined}
    {tInterval("helpers.daysUntil_interval", Math.abs(days))}
  </Tag>
);

export default DaysCountTag;
