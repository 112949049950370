import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { EntityIdObject, EntityObject, SearchPageRequest, SearchPageResult } from "../../common/types";
import { AffiliatePartner, CreateUpdateAffiliatePartner } from "./types";

const FILTER_AFFILIATE_PARTNERS = ApiRequestAdapter.create("/affiliate-partners", "GET");
const CREATE_AFFILIATE_PARTNER = ApiRequestAdapter.create("/affiliate-partners", "POST");
const UPDATE_AFFILIATE_PARTNER = ApiRequestAdapter.create("/affiliate-partners/{0}", "PUT");
const DELETE_AFFILIATE_PARTNER = ApiRequestAdapter.create("/affiliate-partners/{0}", "DELETE");

export const requests = {
  FILTER_AFFILIATE_PARTNERS,
  CREATE_AFFILIATE_PARTNER,
  UPDATE_AFFILIATE_PARTNER,
  DELETE_AFFILIATE_PARTNER
};

const api = {
  filterAffiliatePartners: (request: SearchPageRequest): AxiosPromise<SearchPageResult<AffiliatePartner>> => {
    return apiService.get(FILTER_AFFILIATE_PARTNERS.url, { params: request });
  },
  createAffiliatePartner: (request: CreateUpdateAffiliatePartner): AxiosPromise<AffiliatePartner> => {
    return apiService.post(CREATE_AFFILIATE_PARTNER.url, request);
  },
  updateAffiliatePartner: (request: EntityObject<CreateUpdateAffiliatePartner>): AxiosPromise<AffiliatePartner> => {
    return apiService.put(UPDATE_AFFILIATE_PARTNER.getParametrizedUrl(request.id), request.object);
  },
  deleteAffiliatePartner: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_AFFILIATE_PARTNER.getParametrizedUrl(request.id));
  }
};

export default api;
