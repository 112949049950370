import { Card, Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import t from "../../../../../../../app/i18n";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../../common/constants";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerPast,
  selectStandardProps,
  toDate
} from "../../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../../common/utils/validationUtils";
import { ClientType } from "../../../../../../client/enums";
import { Client } from "../../../../../../client/types";
import { PaymentFrequency, VehicleCategory } from "../../../../../../contract/enums";
import { CalcType } from "../../../../../enums";
import {
  AnimalReinsuranceAmount,
  GapDuration,
  GlassReinsuranceAmount,
  ReplacementVehicleCategory,
  SmallDamageReinsuranceAmount,
  TotalDamageReinsuranceAmount
} from "../../../enums";
import { REINSURANCES_MAP, resolveGapCoverageLimitOptions } from "../../../utils";

interface Props {
  holder?: Client;
}

const VehicleCalcGeneralAndReinsurancesDataSection = ({ holder }: Props) => {
  const colSpan = 8;

  return (
    <Card type="inner" className="card-box" title={t("calc.vehicle.sections.generalData")}>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <Divider className="divider-subheader">{t("calc.vehicle.sections.generalInfo")}</Divider>
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) =>
                  prev.vehicleData.firstRegistrationDate !== next.vehicleData.firstRegistrationDate
                }
              >
                {({ getFieldValue }) => {
                  const firstRegistrationDate = getFieldValue(["vehicleData", "firstRegistrationDate"]);

                  return (
                    <Form.Item
                      name={["generalData", "effectiveBeginningDate"]}
                      label={t("calc.vehicle.attrs.generalData.effectiveBeginningDate")}
                      rules={[
                        validations.notNull,
                        validations.dateInInterval(
                          !!firstRegistrationDate && toDate(firstRegistrationDate)?.isAfter(dayjs(), "day")
                            ? firstRegistrationDate
                            : dayjs(),
                          dayjs().add(1, "year")
                        )
                      ]}
                      {...datePickerFormItemProps}
                    >
                      <DatePicker {...datePickerStandardProps} disabledDate={disableDatePickerPast} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={["generalData", "paymentFrequency"]}
                label={t("contract.enums.paymentFrequency._label")}
                rules={[validations.notNull]}
              >
                <Select
                  {...selectStandardProps}
                  options={[PaymentFrequency.ANNUALLY, PaymentFrequency.SEMI_ANNUALLY, PaymentFrequency.QUARTERLY].map(
                    frequency => ({
                      value: frequency,
                      label: t("contract.enums.paymentFrequency." + frequency)
                    })
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Divider className="divider-subheader">{t("calc.vehicle.sections.gapInfo")}</Divider>
          <Row gutter={rowGutter}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
              {({ getFieldValue }) => {
                const calcType = getFieldValue(["type"]);
                return (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["generalData", "gapDuration"]}
                        label={t("calc.vehicle.enums.gapDuration._label")}
                        rules={[validations.none]}
                      >
                        <Select
                          {...selectStandardProps}
                          allowClear
                          disabled={calcType === CalcType.MTPL}
                          options={[
                            GapDuration.MONTHS_12,
                            GapDuration.MONTHS_24,
                            GapDuration.MONTHS_36,
                            GapDuration.MONTHS_48,
                            GapDuration.MONTHS_60
                          ].map(duration => ({
                            value: duration,
                            label: t("calc.vehicle.enums.gapDuration." + duration)
                          }))}
                        />
                      </Form.Item>
                    </Col>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.clientsData.holderRegistrationDate !== next.clientsData.holderRegistrationDate
                      }
                    >
                      {({ getFieldValue }) => {
                        const holderRegistrationDate = getFieldValue(["clientsData", "holderRegistrationDate"]) as
                          | string
                          | undefined;
                        return (
                          <Col span={colSpan}>
                            <Form.Item
                              name={["generalData", "gapCoverageLimit"]}
                              label={t("calc.vehicle.enums.gapCoverageLimit._label")}
                              rules={[validations.none]}
                            >
                              <Select
                                {...selectStandardProps}
                                allowClear
                                disabled={calcType === CalcType.MTPL}
                                options={resolveGapCoverageLimitOptions(toDate(holderRegistrationDate)).map(
                                  coverageLimit => ({
                                    value: coverageLimit,
                                    label: t("calc.vehicle.enums.gapCoverageLimit." + coverageLimit)
                                  })
                                )}
                              />
                            </Form.Item>
                          </Col>
                        );
                      }}
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </Row>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={12}>
          <Divider className="divider-subheader">{t("calc.vehicle.sections.reinsurancesData")}</Divider>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.type !== next.type ||
              prev.vehicleData.category !== next.vehicleData.category ||
              prev.reinsurancesData.glass !== next.reinsurancesData.glass ||
              prev.reinsurancesData.animal !== next.reinsurancesData.animal ||
              prev.reinsurancesData.gap !== next.reinsurancesData.gap ||
              prev.reinsurancesData.replacementVehicle !== next.reinsurancesData.replacementVehicle
            }
          >
            {({ getFieldValue }) => {
              const calcType = getFieldValue(["type"]);
              const category = getFieldValue(["vehicleData", "category"]);
              const glass = getFieldValue(["reinsurancesData", "glass"]);
              const animal = getFieldValue(["reinsurancesData", "animal"]);
              const gap = getFieldValue(["reinsurancesData", "gap"]);
              const replacementVehicle = getFieldValue(["reinsurancesData", "replacementVehicle"]);
              return (
                <>
                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "extendedAssistance"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox disabled={!REINSURANCES_MAP.get("extendedAssistance")?.includes(category)}>
                          {t("calc.vehicle.attrs.reinsurancesData.extendedAssistance")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "injury"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={calcType === CalcType.CRASH || !REINSURANCES_MAP.get("injury")?.includes(category)}
                        >
                          {t("calc.vehicle.attrs.reinsurancesData.injury")}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "theftAndVandalism"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.CRASH ||
                            calcType === CalcType.MTPL_CRASH ||
                            !REINSURANCES_MAP.get("theftAndVandalism")?.includes(category)
                          }
                        >
                          {t("calc.vehicle.attrs.reinsurancesData.theftAndVandalism")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "element"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.CRASH ||
                            calcType === CalcType.MTPL_CRASH ||
                            !REINSURANCES_MAP.get("element")?.includes(category)
                          }
                        >
                          {t("calc.vehicle.attrs.reinsurancesData.element")}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "glass"]}
                        className={glass ? "form-item-without-label" : undefined}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox disabled={!REINSURANCES_MAP.get("glass")?.includes(category)}>
                          {t("calc.vehicle.attrs.reinsurancesData.glass")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      {glass && (
                        <Form.Item
                          name={["reinsurancesData", "glassAmount"]}
                          label={t("calc.vehicle.attrs.reinsurancesData.glassAmountLabel")}
                          rules={[validations.notNull]}
                        >
                          <Select
                            {...selectStandardProps}
                            options={Object.keys(GlassReinsuranceAmount).map(amount => ({
                              value: amount,
                              label: t("calc.vehicle.enums.glassReinsuranceAmount." + amount)
                            }))}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "animal"]}
                        className={animal ? "form-item-without-label" : undefined}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.CRASH ||
                            calcType === CalcType.MTPL_CRASH ||
                            !REINSURANCES_MAP.get("animal")?.includes(category)
                          }
                        >
                          {t("calc.vehicle.attrs.reinsurancesData.animal")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      {animal && (
                        <Form.Item
                          name={["reinsurancesData", "animalAmount"]}
                          label={t("calc.vehicle.attrs.reinsurancesData.animalAmountLabel")}
                          rules={[validations.notNull]}
                        >
                          <Select
                            {...selectStandardProps}
                            options={Object.keys(AnimalReinsuranceAmount).map(amount => ({
                              value: amount,
                              label: t("calc.vehicle.enums.animalReinsuranceAmount." + amount)
                            }))}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "gap"]}
                        className={gap ? "form-item-without-label" : undefined}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={calcType === CalcType.MTPL || !REINSURANCES_MAP.get("gap")?.includes(category)}
                        >
                          {t("calc.vehicle.attrs.reinsurancesData.gap")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    {gap && (
                      <>
                        <Col span={colSpan}>
                          <Form.Item
                            name={["reinsurancesData", "gapDuration"]}
                            label={t("calc.vehicle.enums.gapDuration._label")}
                            rules={[validations.notNull]}
                          >
                            <Select
                              {...selectStandardProps}
                              options={[
                                GapDuration.MONTHS_24,
                                GapDuration.MONTHS_36,
                                GapDuration.MONTHS_48,
                                GapDuration.MONTHS_60
                              ].map(duration => ({
                                value: duration,
                                label: t("calc.vehicle.enums.gapDuration." + duration)
                              }))}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={colSpan}>
                          <Form.Item
                            name={["reinsurancesData", "gapComplicityReinsurance"]}
                            className="form-item-without-label"
                            valuePropName="checked"
                            rules={[validations.none]}
                            initialValue={false}
                          >
                            <Checkbox style={{ maxHeight: 22 }}>
                              {t("calc.vehicle.attrs.reinsurancesData.gapComplicityReinsurance")}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "replacementVehicle"]}
                        className={replacementVehicle ? "form-item-without-label" : undefined}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.MTPL ||
                            !REINSURANCES_MAP.get("replacementVehicle")?.includes(category)
                          }
                        >
                          {t("calc.vehicle.attrs.reinsurancesData.replacementVehicle")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    {replacementVehicle && (
                      <Col span={colSpan}>
                        <Form.Item
                          name={["reinsurancesData", "replacementVehicleCategory"]}
                          label={t("calc.vehicle.enums.replacementVehicleCategory._label")}
                          rules={[validations.notNull]}
                        >
                          <Select
                            {...selectStandardProps}
                            options={Object.keys(ReplacementVehicleCategory).map(category => ({
                              value: category,
                              label: t("calc.vehicle.enums.replacementVehicleCategory." + category)
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "generaliAbroadVehicleRepair"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.MTPL ||
                            calcType === CalcType.MTPL_CRASH ||
                            !REINSURANCES_MAP.get("generaliAbroadVehicleRepair")?.includes(category)
                          }
                        >
                          <LabelWithTooltip
                            label={t("calc.vehicle.attrs.reinsurancesData.generaliAbroadVehicleRepair")}
                            tooltip={t("calc.vehicle.helpers.generaliAbroadVehicleRepairDesc")}
                          />
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "koopExtendedWarranty"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.MTPL ||
                            calcType === CalcType.CRASH ||
                            !REINSURANCES_MAP.get("koopExtendedWarranty")?.includes(category)
                          }
                        >
                          {t("calc.vehicle.attrs.reinsurancesData.koopExtendedWarranty")}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Divider className="divider-subheader">
            {t("calc.vehicle.sections.additionalReinsurances")}
            <LabelWithTooltip
              tooltip={t("calc.vehicle.helpers.additionalReinsurancesDesc")}
              className="margin-left-tiny"
            />
          </Divider>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.type !== next.type ||
              prev.vehicleData.category !== next.vehicleData.category ||
              prev.reinsurancesData.uniqaSmallDamage !== next.reinsurancesData.uniqaSmallDamage ||
              prev.reinsurancesData.uniqaTotalDamage !== next.reinsurancesData.uniqaTotalDamage
            }
          >
            {({ getFieldValue }) => {
              const calcType = getFieldValue(["type"]) as CalcType;
              const category = getFieldValue(["vehicleData", "category"]) as VehicleCategory;
              const smallDamage = getFieldValue(["reinsurancesData", "uniqaSmallDamage"]);
              const totalDamage = getFieldValue(["reinsurancesData", "uniqaTotalDamage"]);

              return (
                <>
                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "uniqaSmallDamage"]}
                        className={classNames({ "form-item-without-label": smallDamage })}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.CRASH ||
                            calcType === CalcType.MTPL_CRASH ||
                            !REINSURANCES_MAP.get("uniqaSmallDamage")?.includes(category)
                          }
                        >
                          <LabelWithTooltip
                            tooltip={t("calc.vehicle.helpers.uniqaSmallDamageDesc")}
                            label={t("calc.vehicle.attrs.reinsurancesData.uniqaSmallDamage")}
                          />
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      {smallDamage && (
                        <Form.Item
                          name={["reinsurancesData", "uniqaSmallDamageAmount"]}
                          label={t("calc.vehicle.attrs.reinsurancesData.uniqaSmallDamageAmountLabel")}
                          rules={[validations.notNull]}
                        >
                          <Select
                            {...selectStandardProps}
                            options={Object.keys(SmallDamageReinsuranceAmount).map(amount => ({
                              value: amount,
                              label: t("calc.vehicle.enums.smallDamageReinsuranceAmount." + amount)
                            }))}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={9}>
                      <Form.Item
                        name={["reinsurancesData", "uniqaTotalDamage"]}
                        className={classNames({ "form-item-without-label": totalDamage })}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.CRASH ||
                            calcType === CalcType.MTPL_CRASH ||
                            !REINSURANCES_MAP.get("uniqaTotalDamage")?.includes(category)
                          }
                        >
                          <LabelWithTooltip
                            tooltip={t("calc.vehicle.helpers.uniqaTotalDamageDesc")}
                            label={t("calc.vehicle.attrs.reinsurancesData.uniqaTotalDamage")}
                          />
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      {totalDamage && (
                        <Form.Item
                          name={["reinsurancesData", "uniqaTotalDamageAmount"]}
                          label={t("calc.vehicle.attrs.reinsurancesData.uniqaTotalDamageAmountLabel")}
                          rules={[validations.notNull]}
                        >
                          <Select
                            {...selectStandardProps}
                            allowClear
                            options={Object.keys(TotalDamageReinsuranceAmount).map(amount => ({
                              value: amount,
                              label: t("calc.vehicle.enums.totalDamageReinsuranceAmount." + amount)
                            }))}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "uniqaLawyerAssistance"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.CRASH ||
                            !REINSURANCES_MAP.get("uniqaLawyerAssistance")?.includes(category)
                          }
                        >
                          <LabelWithTooltip
                            tooltip={t("calc.vehicle.helpers.uniqaLawyerAssistanceDesc")}
                            label={t("calc.vehicle.attrs.reinsurancesData.uniqaLawyerAssistance")}
                          />
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "uniqaSidecarMtpl"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.CRASH || !REINSURANCES_MAP.get("uniqaSidecarMtpl")?.includes(category)
                          }
                        >
                          {t("calc.vehicle.attrs.reinsurancesData.uniqaSidecarMtpl")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "uniqaChildrenInjury"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.CRASH ||
                            !REINSURANCES_MAP.get("uniqaChildrenInjury")?.includes(category)
                          }
                        >
                          {t("calc.vehicle.attrs.reinsurancesData.uniqaChildrenInjury")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={["reinsurancesData", "uniqaUnlimitedTow"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            calcType === CalcType.CRASH ||
                            !REINSURANCES_MAP.get("uniqaUnlimitedTow")?.includes(category)
                          }
                        >
                          <LabelWithTooltip
                            tooltip={t("calc.vehicle.helpers.uniqaUnlimitedTowDesc")}
                            label={t("calc.vehicle.attrs.reinsurancesData.uniqaUnlimitedTow")}
                          />
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Form.Item>

          <Divider className="divider-subheader">{t("calc.vehicle.sections.crossSelling")}</Divider>

          <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
            {({ getFieldValue }) => {
              const type = getFieldValue(["type"]) as CalcType;
              return (
                <Row gutter={rowGutter}>
                  <Col span={colSpan}>
                    <Form.Item
                      name={["generalData", "crossSelling", "csobContract"]}
                      valuePropName="checked"
                      rules={[validations.none]}
                      initialValue={false}
                    >
                      <Checkbox disabled={type === CalcType.MTPL_CRASH}>
                        <LabelWithTooltip
                          label={t("calc.vehicle.attrs.generalData.crossSelling.csobContract")}
                          tooltip={t("calc.vehicle.attrs.generalData.crossSelling.csobContractDesc")}
                        />
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name={["generalData", "crossSelling", "kooperativaContract"]}
                      valuePropName="checked"
                      rules={[validations.none]}
                      initialValue={false}
                    >
                      <Checkbox disabled={type === CalcType.MTPL || type === CalcType.MTPL_CRASH}>
                        {t("calc.vehicle.attrs.generalData.crossSelling.kooperativaContract")}
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name={["generalData", "crossSelling", "unionHealthContract"]}
                      valuePropName="checked"
                      rules={[validations.none]}
                      initialValue={false}
                    >
                      <Checkbox disabled={holder?.type !== ClientType.NATURAL || type === CalcType.MTPL_CRASH}>
                        {t("calc.vehicle.attrs.generalData.crossSelling.unionHealthContract")}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              );
            }}
          </Form.Item>

          <Row gutter={rowGutter}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.generalData.crossSelling?.uniqaContract !== next.generalData.crossSelling?.uniqaContract
              }
            >
              {({ getFieldValue }) => {
                const uniqaContract = getFieldValue(["generalData", "crossSelling", "uniqaContract"]) as boolean;
                return (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["generalData", "crossSelling", "uniqaContract"]}
                        valuePropName="checked"
                        className={classNames({ "form-item-without-label": uniqaContract })}
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox style={uniqaContract ? { maxHeight: 22 } : undefined}>
                          {t("calc.vehicle.attrs.generalData.crossSelling.uniqaContract")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    {uniqaContract && (
                      <Col span={colSpan}>
                        <Form.Item
                          name={["generalData", "crossSelling", "uniqaContractNumber"]}
                          label={t("calc.vehicle.attrs.generalData.crossSelling.uniqaContractNumber")}
                          rules={[validations.notBlank, validations.size(1, 64)]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default VehicleCalcGeneralAndReinsurancesDataSection;
