import { Button, Result } from "antd";
import React, { ErrorInfo, useMemo } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import t from "../../../app/i18n";
import { selectIsUserAuthenticated } from "../../../modules/auth/ducks";
import { createAutomaticFrontendErrorLogBugReportActions } from "../../../modules/bugreport/ducks";
import { DASHBOARD_ROUTE_PATHS } from "../../../modules/dashboard/paths";
import AntIcon from "../../components/icons/AntIcon";
import { RootState } from "../../types";
import { useToken } from "../../utils/hooksUtils";

interface Props {
  children: React.ReactNode;
}

const ErrorBoundary = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useToken();

  const isUserAuthenticated = useSelector<RootState, boolean | undefined>(selectIsUserAuthenticated);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          createErrorLogBugReport: createAutomaticFrontendErrorLogBugReportActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const handleError = (error: Error, errorInfo: ErrorInfo): void => {
    if (isUserAuthenticated) {
      actions.createErrorLogBugReport({
        name: error.name || "Unknown error",
        message: error.message || "Unknown error occurred.",
        stack: error.stack,
        componentStack: errorInfo.componentStack,
        locationPath: location.pathname + location.search
      });
    }
  };

  return (
    <ReactErrorBoundary
      fallback={
        <Result
          className="result-page margin-top-large"
          status="500"
          title={<span style={{ color: token.colorPrimary }}>{t("navigation.error.title")}</span>}
          subTitle={t("navigation.error.text")}
          extra={
            <Button onClick={() => navigate(DASHBOARD_ROUTE_PATHS.homepage.to, { replace: true })}>
              {t("navigation.error.homeAction")}
              <AntIcon type="home" />
            </Button>
          }
        />
      }
      onError={handleError}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
