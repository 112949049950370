import { Table } from "antd";
import classNames from "classnames";
import { Dayjs } from "dayjs";
import React from "react";
import t from "../../../app/i18n";
import { AgentType } from "../../../modules/agent/enums";
import { Agent } from "../../../modules/agent/types";
import { Client } from "../../../modules/client/types";
import { resolveClientAddressLabel } from "../../../modules/client/utils";
import InsuranceHeaderView from "../../../modules/contract/components/views/detail/insurance/views/InsuranceHeaderView";
import { ContractType } from "../../../modules/contract/enums";
import { Contract, Insurance, InsuranceContract } from "../../../modules/contract/types";
import { Institution } from "../../../modules/institution/types";
import Ellipsis from "../../components/views/Ellipsis";
import { formatLocaleDateTime } from "../../utils/formatUtils";
import { toDate } from "../../utils/formUtils";
import { FIELD_PATH_ENUM_END, FIELD_PATH_ENUM_START, tExists } from "../../utils/translationUtils";
import { tableStandardProps } from "../../utils/utils";
import { HistoryItem, HistoryRecord, HistoryRecordType } from "../types";
import HistoryItemValueView from "./HistoryItemValueView";
import styles from "./HistoryView.module.scss";
import { CONTRACT_SPECIFIC_FIELDS, INSURANCE_SPECIFIC_FIELDS } from "./items/contractHistoryItems";

interface Props {
  item: HistoryViewItem;
  translationRootPath: string;
  type?: HistoryRecordType;
  excludedFields?: string[];
}

type HistoryViewItem = Institution | Agent | Client | Insurance | Contract;

interface HistoryItemRender {
  render: React.ReactNode;
  createdAt: string;
}

const INSURANCE_DATA_KEY = "insuranceData";

const HistoryView = ({ item, translationRootPath, type, excludedFields }: Props) => {
  const reduceHistoryItems = (items: HistoryItem[]): HistoryItem[] => {
    return items
      .filter(currentItem => !excludedFields?.includes(currentItem.fieldName))
      .reduce<HistoryItem[]>((previousItems, currentItem) => {
        if (currentItem.fieldName === INSURANCE_DATA_KEY && Object.keys(currentItem.value).length > 1) {
          Object.entries(currentItem.value).forEach(([key, data]) =>
            previousItems.push({ fieldName: INSURANCE_DATA_KEY, value: { [key]: data } })
          );
          return previousItems;
        }

        previousItems.push(currentItem);

        return previousItems;
      }, []);
  };

  const resolveHistoryItemLabel = (historyItem: HistoryItem, renderingInsurance?: boolean): string => {
    let fieldName = historyItem.fieldName;

    if (type === "agent") {
      if (fieldName === "address") {
        return (item as Agent).type === AgentType.LEGAL ? t("agent.attrs.addressLongPO") : t("agent.attrs.addressLong");
      }
      if (fieldName === "serviceAddress") {
        return (item as Agent).type === AgentType.SELF_EMPLOYED
          ? t("agent.attrs.serviceAddressFOP")
          : t("agent.attrs.serviceAddress");
      }
    }
    if (type === "client" && fieldName === "address") {
      return resolveClientAddressLabel((item as Client).type);
    }

    if (renderingInsurance) {
      if (INSURANCE_SPECIFIC_FIELDS.includes(fieldName)) {
        fieldName += "Index";
      } else if (fieldName === INSURANCE_DATA_KEY) {
        fieldName += "." + Object.keys(historyItem.value)[0];
      }
    }

    if (tExists(translationRootPath + (renderingInsurance ? ".insurances." : ".") + fieldName + "._label")) {
      fieldName += "._label";
    }

    const translation = t(translationRootPath + (renderingInsurance ? ".insurances." : ".") + fieldName);

    return translation.startsWith(FIELD_PATH_ENUM_START) && translation.endsWith(FIELD_PATH_ENUM_END)
      ? t(translation.substring(2, translation.length - 2) + "._label")
      : translation;
  };

  const findClosestNewerHistoryItem = (
    recordIndex: number,
    historyItem: HistoryItem,
    parentItem: HistoryViewItem,
    renderingInsurance?: boolean
  ): HistoryItem | undefined => {
    const { fieldName, value } = historyItem;

    for (let i = recordIndex - 1; i >= 0; i--) {
      const historyRecord = parentItem.historyRecords?.[i];
      if (!historyRecord) {
        return;
      }
      const reducedItems = reduceHistoryItems(historyRecord.items);

      for (let j = 0; j < reducedItems.length; j++) {
        const item = reducedItems[j];

        if (fieldName === INSURANCE_DATA_KEY && item?.fieldName === INSURANCE_DATA_KEY) {
          const historyItemValueKey = Object.keys(value)[0];
          if (historyItemValueKey && Object.keys(item?.value).includes(historyItemValueKey)) {
            return { fieldName: fieldName + "." + historyItemValueKey, value: item?.value };
          }
        } else if (item?.fieldName === fieldName) {
          return item;
        }
      }
    }

    if (type === "contract" && CONTRACT_SPECIFIC_FIELDS.includes(fieldName)) {
      // @ts-ignore ts-problem
      return { fieldName, value: (parentItem as Contract).clients[parentItem[`${fieldName}Index`]] };
    }

    if (renderingInsurance) {
      if (INSURANCE_SPECIFIC_FIELDS.includes(fieldName)) {
        // @ts-ignore ts-problem
        return { fieldName, value: (item as Contract).clients[parentItem[`${fieldName}Index`]] };
      } else if (fieldName === INSURANCE_DATA_KEY) {
        return {
          fieldName: fieldName + "." + Object.keys(value)[0],
          // @ts-ignore ts-problem
          value: parentItem[fieldName]
        };
      }
    }

    // @ts-ignore ts-problem
    return { fieldName, value: parentItem[fieldName] };
  };

  const renderHistoryData = (
    recordIndex: number,
    record: HistoryRecord,
    parentItem: HistoryViewItem,
    renderingInsurance?: boolean
  ): React.ReactNode => {
    const historyItems = reduceHistoryItems(record.items || []);

    return historyItems.length > 0 ? (
      <React.Fragment key={recordIndex}>
        <Table<HistoryItem>
          {...tableStandardProps()}
          className={classNames("standard-table", "stripped", styles.historyViewTable)}
          rowKey={r => r.fieldName + Math.random()}
          pagination={false}
          dataSource={historyItems}
          showHeader={false}
          title={() => (
            <>
              <span style={{ fontWeight: 500 }}>
                {`${formatLocaleDateTime(record.changeCreatedAt)} | ${
                  record.changeCreatedBy.userId
                    ? record.changeCreatedBy.userName
                    : t("history.helpers.systemChangeCreator")
                }`}
              </span>
              {type === "contract" && renderingInsurance && (
                <i>
                  {t("contract.attrs.insurances._label")}:{" "}
                  <InsuranceHeaderView insurance={parentItem as Insurance} contract={item as InsuranceContract} />
                </i>
              )}
            </>
          )}
          columns={[
            {
              key: "fieldName",
              width: 150,
              ellipsis: { showTitle: false },
              render: (_, historyItem) => (
                <Ellipsis>{resolveHistoryItemLabel(historyItem, renderingInsurance)}</Ellipsis>
              )
            },
            {
              key: "oldValue",
              render: (_, historyItem) => (
                <HistoryItemValueView
                  item={
                    historyItem.fieldName === INSURANCE_DATA_KEY
                      ? { ...historyItem, fieldName: INSURANCE_DATA_KEY + "." + Object.keys(historyItem.value)[0] }
                      : historyItem
                  }
                  recordType={type}
                />
              )
            },
            {
              key: "newValue",
              render: (_, historyItem) => (
                <HistoryItemValueView
                  item={findClosestNewerHistoryItem(recordIndex, historyItem, parentItem, renderingInsurance)}
                  recordType={type}
                />
              )
            }
          ]}
        />
      </React.Fragment>
    ) : null;
  };

  let outputRecords =
    item.historyRecords?.map<HistoryItemRender>((record, index) => ({
      render: renderHistoryData(index, record, item),
      createdAt: record.changeCreatedAt
    })) || [];

  if (type === "contract" && (item as Contract).type === ContractType.INSURANCE_CONTRACT) {
    const insurancesRecords =
      (item as InsuranceContract).insurances
        .filter(insurance => !!insurance.historyRecords)
        .map(
          insurance =>
            insurance.historyRecords?.map<HistoryItemRender>((record, index) => ({
              render: renderHistoryData(index, record, insurance, true),
              createdAt: record.changeCreatedAt
            })) ?? []
        )
        .flat() ?? [];

    outputRecords = [...outputRecords, ...insurancesRecords].sort((a, b) => {
      const historyItemBCreatedAt = b?.createdAt ?? "";
      const historyItemACreatedAt = a?.createdAt ?? "";

      return (toDate(historyItemBCreatedAt) as Dayjs).diff(toDate(historyItemACreatedAt));
    });
  }

  return (
    <>
      {outputRecords.length > 0 ? (
        outputRecords.map((record, index) => <React.Fragment key={index}>{record.render}</React.Fragment>)
      ) : (
        <span
          style={{ display: "inline-block", width: "100%", textAlign: "center" }}
          className="sub-header-info dashed"
        >
          {t("history.helpers.noData")}
        </span>
      )}
    </>
  );
};

export default HistoryView;
