import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { selectIsUserAuthenticated } from "../../../modules/auth/ducks";
import { DASHBOARD_ROUTE_PATHS } from "../../../modules/dashboard/paths";
import { AUTH_REDIRECT_SEARCH_PARAM } from "../../constants";
import { RootState } from "../../types";

interface Props {
  component: ReactElement;
}

const LoginWrapper = ({ component }: Props) => {
  const { search } = useLocation();
  const isUserAuthenticated = useSelector<RootState, boolean | undefined>(selectIsUserAuthenticated);

  const redirectParam = new URLSearchParams(search).get(AUTH_REDIRECT_SEARCH_PARAM);

  return isUserAuthenticated ? <Navigate replace to={redirectParam || DASHBOARD_ROUTE_PATHS.homepage.to} /> : component;
};

export default LoginWrapper;
