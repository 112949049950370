import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";
import { combineReducers } from "redux";
import { replace } from "redux-first-history";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, RootState, TwoLevelEntityIdObject } from "../../../common/types";
import messageUtils from "../../../common/utils/messageUtils";
import { openBlobFile, removeFromArray, replaceInArray } from "../../../common/utils/utils";
import api from "./api";
import { ADMIN_CALC_ROUTE_PATHS } from "./paths";
import {
  AttachmentConf,
  CalcSettings,
  CalcSettingsReducerState,
  CalcSettingsRequest,
  CreateUpdateCalcSettings
} from "./types";

/**
 * ACTIONS
 */
export const listCalcSettingsActions = createAsyncAction(
  "calc-settings/LIST_REQUEST",
  "calc-settings/LIST_SUCCESS",
  "calc-settings/LIST_FAILURE"
)<CalcSettingsRequest, CalcSettings[], void>();

export const getCalcSettingsActions = createAsyncAction(
  "calc-settings/GET_REQUEST",
  "calc-settings/GET_SUCCESS",
  "calc-settings/GET_FAILURE"
)<EntityIdObject, CalcSettings, void>();

export const createCalcSettingsActions = createAsyncAction(
  "calc-settings/CREATE_REQUEST",
  "calc-settings/CREATE_SUCCESS",
  "calc-settings/CREATE_FAILURE"
)<CreateUpdateCalcSettings, void, void>();

export const updateCalcSettingsActions = createAsyncAction(
  "calc-settings/UPDATE_REQUEST",
  "calc-settings/UPDATE_SUCCESS",
  "calc-settings/UPDATE_FAILURE"
)<EntityObject<CreateUpdateCalcSettings>, CalcSettings, void>();

export const deleteCalcSettingsActions = createAsyncAction(
  "calc-settings/DELETE_REQUEST",
  "calc-settings/DELETE_SUCCESS",
  "calc-settings/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateCalcSettingsListAction = createAction("calc-settings/DELETE_STATE_LIST")<void>();
export const deleteStateCalcSettingsDetailAction = createAction("calc-settings/DELETE_STATE_DETAIL")<void>();

export const uploadCalcSettingsAttachmentConfsActions = createAsyncAction(
  "calc-settings-attachment/UPLOAD_REQUEST",
  "calc-settings-attachment/UPLOAD_SUCCESS",
  "calc-settings-attachment/UPLOAD_FAILURE"
)<EntityObject<FormData>, CalcSettings, void>();

export const downloadCalcSettingsAttachmentConfActions = createAsyncAction(
  "calc-settings-attachment/DOWNLOAD_REQUEST",
  "calc-settings-attachment/DOWNLOAD_SUCCESS",
  "calc-settings-attachment/DOWNLOAD_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const deleteCalcSettingsAttachmentConfActions = createAsyncAction(
  "calc-settings-attachment/DELETE_REQUEST",
  "calc-settings-attachment/DELETE_SUCCESS",
  "calc-settings-attachment/DELETE_FAILURE"
)<TwoLevelEntityIdObject, CalcSettings, void>();

const actions = {
  listCalcSettingsActions,
  getCalcSettingsActions,
  createCalcSettingsActions,
  updateCalcSettingsActions,
  deleteCalcSettingsActions,
  deleteStateCalcSettingsListAction,
  deleteStateCalcSettingsDetailAction,
  uploadCalcSettingsAttachmentConfsActions,
  downloadCalcSettingsAttachmentConfActions,
  deleteCalcSettingsAttachmentConfActions
};

export type CalcSettingsAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: CalcSettingsReducerState = {
  settingsList: [],
  settingsDetail: null
};

const calcSettingsListReducer = createReducer(initialState.settingsList)
  .handleAction([listCalcSettingsActions.success], (_, { payload }) => payload)
  .handleAction([updateCalcSettingsActions.success], (state, { payload }) =>
    state.length > 0
      ? replaceInArray(
          state,
          item => item.id === payload.id,
          () => payload
        )
      : state
  )
  .handleAction(
    [listCalcSettingsActions.failure, deleteCalcSettingsActions.success, deleteStateCalcSettingsListAction],
    () => initialState.settingsList
  );

const calcSettingsDetailReducer = createReducer(initialState.settingsDetail)
  .handleAction(
    [
      getCalcSettingsActions.success,
      updateCalcSettingsActions.success,
      uploadCalcSettingsAttachmentConfsActions.success,
      deleteCalcSettingsAttachmentConfActions.success
    ],
    (_, { payload }) => payload
  )
  .handleAction(
    [getCalcSettingsActions.failure, deleteCalcSettingsActions.success, deleteStateCalcSettingsDetailAction],
    () => initialState.settingsDetail
  );

export const calcSettingsReducer = combineReducers<CalcSettingsReducerState>({
  settingsList: calcSettingsListReducer,
  settingsDetail: calcSettingsDetailReducer
});

/**
 * SELECTORS
 */
const selectCalcSettings = (state: RootState): CalcSettingsReducerState => state.calculator.settings;

export const selectCalcSettingsList = (state: RootState): CalcSettings[] => selectCalcSettings(state).settingsList;
export const selectCalcSettingsDetail = (state: RootState): CalcSettings | undefined =>
  selectCalcSettings(state).settingsDetail ?? undefined;

/**
 * SAGAS
 */
function* listCalcSettings({ payload }: ReturnType<typeof listCalcSettingsActions.request>) {
  try {
    const response: AxiosResponse<CalcSettings[]> = yield call(api.listCalcSettings, payload);
    yield put(listCalcSettingsActions.success(response.data));
  } catch {
    yield put(listCalcSettingsActions.failure());
  }
}

function* getCalcSettings({ payload }: ReturnType<typeof getCalcSettingsActions.request>) {
  try {
    const response: AxiosResponse<CalcSettings> = yield call(api.getCalcSettings, payload);
    yield put(getCalcSettingsActions.success(response.data));
  } catch {
    yield put(getCalcSettingsActions.failure());
  }
}

function* createCalcSettings({ payload }: ReturnType<typeof createCalcSettingsActions.request>) {
  try {
    const response: AxiosResponse<CalcSettings> = yield call(api.createCalcSettings, payload);
    yield put(createCalcSettingsActions.success());
    yield put(replace(generatePath(ADMIN_CALC_ROUTE_PATHS.detail.to, { id: response.data.id })));
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createCalcSettingsActions.failure());
  }
}

function* updateCalcSettings({ payload }: ReturnType<typeof updateCalcSettingsActions.request>) {
  try {
    const response: AxiosResponse<CalcSettings> = yield call(api.updateCalcSettings, payload);
    yield put(updateCalcSettingsActions.success(response.data));
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateCalcSettingsActions.failure());
  }
}

function* deleteCalcSettings({ payload }: ReturnType<typeof deleteCalcSettingsActions.request>) {
  try {
    yield call(api.deleteCalcSettings, payload);
    yield put(deleteCalcSettingsActions.success());
    yield put(replace(ADMIN_CALC_ROUTE_PATHS.list.to));
  } catch {
    yield put(deleteCalcSettingsActions.failure());
  }
}

function* uploadAttachmentConfs({ payload }: ReturnType<typeof uploadCalcSettingsAttachmentConfsActions.request>) {
  try {
    const response: AxiosResponse<AttachmentConf[]> = yield call(api.uploadAttachmentConfs, payload);
    const settings: CalcSettings = { ...(yield select(selectCalcSettingsDetail)) };
    yield put(
      uploadCalcSettingsAttachmentConfsActions.success({
        ...settings,
        attachmentConfs: response.data
      })
    );
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(uploadCalcSettingsAttachmentConfsActions.failure());
  }
}

function* downloadAttachmentConf({ payload }: ReturnType<typeof downloadCalcSettingsAttachmentConfActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadAttachmentConf, payload);
    openBlobFile(response);
    yield put(downloadCalcSettingsAttachmentConfActions.success());
  } catch {
    yield put(downloadCalcSettingsAttachmentConfActions.failure());
  }
}

function* deleteAttachmentConf({ payload }: ReturnType<typeof deleteCalcSettingsAttachmentConfActions.request>) {
  try {
    yield call(api.deleteAttachmentConf, payload);
    const settings: CalcSettings = { ...(yield select(selectCalcSettingsDetail)) };
    yield put(
      deleteCalcSettingsAttachmentConfActions.success({
        ...settings,
        attachmentConfs: removeFromArray(settings.attachmentConfs, conf => conf.id === payload.id2)
      })
    );
    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteCalcSettingsAttachmentConfActions.failure());
  }
}

export function* calcSettingsSaga() {
  yield takeLatest(listCalcSettingsActions.request, listCalcSettings);
  yield takeLatest(getCalcSettingsActions.request, getCalcSettings);
  yield takeLatest(createCalcSettingsActions.request, createCalcSettings);
  yield takeLatest(updateCalcSettingsActions.request, updateCalcSettings);
  yield takeLatest(deleteCalcSettingsActions.request, deleteCalcSettings);
  yield takeLatest(uploadCalcSettingsAttachmentConfsActions.request, uploadAttachmentConfs);
  yield takeLatest(downloadCalcSettingsAttachmentConfActions.request, downloadAttachmentConf);
  yield takeLatest(deleteCalcSettingsAttachmentConfActions.request, deleteAttachmentConf);
}
