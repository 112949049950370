import { Popover } from "antd";
import AntIcon from "./AntIcon";

interface Props {
  title?: React.ReactNode;
  tooltip: React.ReactNode;
}

const InfoIcon = ({ title, tooltip }: Props) => (
  <Popover title={title} content={tooltip}>
    <span>
      <AntIcon type="info" style={{ fontSize: 16 }} />
    </span>
  </Popover>
);

export default InfoIcon;
