import { Card } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../app/i18n";
import BackNavigationArrow from "../../../../common/components/views/BackNavigationArrow";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../../common/security/authorization/enums";
import { ActionProps, RootState } from "../../../../common/types";
import { hasAllPermissions, hasPermission } from "../../../../common/utils/utils";
import { selectPermissions } from "../../../auth/ducks";
import { selectRouterLocationSearchParam } from "../../../ducks";
import { CalcType } from "../../enums";
import CalcSettingsForm from "../components/forms/CalcSettingsForm";
import { createCalcSettingsActions } from "../ducks";

interface StateProps {
  permissions: Permission[];
  urlCalcType: keyof typeof CalcType;
}

interface ActionsMap {
  createCalcSettings: typeof createCalcSettingsActions.request;
}

type Props = StateProps & ActionProps<ActionsMap>;

const CalcSettingsCreateContainer = (props: Props) => {
  const [initialCalcType, setInitialCalcType] = useState<CalcType>();

  useEffect(() => {
    const allowedTypes = [
      ...(hasPermission(props.permissions, Permission.ADMIN_MTPL_CALCULATORS) ? [CalcType.MTPL] : []),
      ...(hasPermission(props.permissions, Permission.ADMIN_CRASH_CALCULATORS) ? [CalcType.CRASH] : []),
      ...(hasAllPermissions(props.permissions, [Permission.ADMIN_MTPL_CALCULATORS, Permission.ADMIN_CRASH_CALCULATORS])
        ? [CalcType.MTPL_CRASH]
        : []),
      ...(hasPermission(props.permissions, Permission.ADMIN_CRASH_CALCULATORS) ? [CalcType.GAP, CalcType.PAS] : []),
      ...(hasPermission(props.permissions, Permission.ADMIN_REALTY_CALCULATORS) ? [CalcType.REALTY] : []),
      ...(hasPermission(props.permissions, Permission.ADMIN_TRAVEL_CALCULATORS) ? [CalcType.TRAVEL] : [])
    ];
    const initialType = allowedTypes.includes(CalcType[props.urlCalcType])
      ? CalcType[props.urlCalcType]
      : allowedTypes[0];

    setInitialCalcType(initialType);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper>
      {initialCalcType && (
        <Card
          className="card-box"
          title={
            <BackNavigationArrow>
              <h2>{t("calc.settings.titles.new", { calcType: t("calc.enums.calcType." + initialCalcType) })}</h2>
            </BackNavigationArrow>
          }
        >
          <CalcSettingsForm calcType={initialCalcType} onCreateCalcSettings={props.actions.createCalcSettings} />
        </Card>
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  permissions: selectPermissions(state),
  urlCalcType: selectRouterLocationSearchParam(state, "calcType") as keyof typeof CalcType
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      createCalcSettings: createCalcSettingsActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CalcSettingsCreateContainer);
