import { Form } from "antd";
import Big from "big.js";
import cloneDeep from "lodash/cloneDeep";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { Permission } from "../../../../../common/security/authorization/enums";
import { FieldConstraintViolation } from "../../../../../common/types";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { Client } from "../../../../client/types";
import { clientToCreateUpdateContractClient, useClientValidation } from "../../../../client/utils";
import { ProductFinancialSector } from "../../../../product/enums";
import { requests } from "../../../api";
import { ContractType } from "../../../enums";
import { ContractFormProps, CreateUpdateThirdPillarContract, ThirdPillarContract } from "../../../types";
import { calculateGainerRecordRatesSum } from "../../../utils";
import ContractFormAgentsSection from "../ContractFormAgentsSection";
import ContractFormHeaderSection from "../ContractFormHeaderSection";
import ThirdPillarContractFormDataSection from "./sections/ThirdPillarContractFormDataSection";

const ThirdPillarContractForm = ({
  initialContract,
  onCreateFormSubmit,
  onUpdateFormSubmit,
  onCancelClick
}: ContractFormProps<ThirdPillarContract>) => {
  const [form] = Form.useForm<CreateUpdateThirdPillarContract>();

  const errorResponse = useFormErrorHandler(form, "contract.attrs", [
    initialContract ? requests.UPDATE_CONTRACT : requests.CREATE_CONTRACT
  ]);

  const clientValidation = useClientValidation();

  const [client, setClient] = useState<Client | undefined>(initialContract?.clients?.[0]);
  const [clientViolationErrors, setClientViolationErrors] = useState<Map<number, FieldConstraintViolation[]>>(
    new Map()
  );

  useEffect(() => {
    if (initialContract) {
      const initialContractCopy = cloneDeep(initialContract);
      form.setFieldsValue({
        ...initialContractCopy,
        historyRecords: undefined,
        clients: [],
        clientIds: [],
        institution: undefined,
        product: undefined,
        signer: undefined,
        manager: undefined,
        affiliatePartner: undefined,
        attachments: undefined,
        institutionId: initialContractCopy.institution.id,
        productId: initialContractCopy.product.id,
        clientIdentifiers: initialContractCopy.clients.map(client => client.identifier),
        signerId: initialContractCopy.signer?.id,
        managerId: initialContractCopy.manager?.id,
        affiliatePartnerId: initialContractCopy.affiliatePartner?.id,
        gainerRecords: initialContractCopy.gainerRecords?.map(record => ({
          id: record.id,
          optimisticLockVersion: record.optimisticLockVersion,
          startDate: record.startDate,
          endDate: record.endDate,
          gainer1Id: record.gainer1.id,
          gainer1Rate: record.gainer1Rate,
          gainer2Id: record.gainer2?.id,
          gainer2Rate: record.gainer2Rate,
          gainer3Id: record.gainer3?.id,
          gainer3Rate: record.gainer3Rate,
          gainer4Id: record.gainer4?.id,
          gainer4Rate: record.gainer4Rate,
          gainer5Id: record.gainer5?.id,
          gainer5Rate: record.gainer5Rate
        }))
      } as CreateUpdateThirdPillarContract);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorResponse?.violations) {
      const parsedViolationErrors = new Map<number, FieldConstraintViolation[]>();
      errorResponse.violations
        .filter(violation => violation.fieldPath.startsWith("clients"))
        .forEach(violation => {
          const index = parseInt(violation.fieldPath.charAt(8));
          const parsedViolation = { ...violation, fieldPath: violation.fieldPath.substring(18) };
          parsedViolationErrors.set(
            index,
            parsedViolationErrors.has(index)
              ? [parsedViolation, ...(parsedViolationErrors.get(index) as FieldConstraintViolation[])]
              : [parsedViolation]
          );
        });
      setClientViolationErrors(parsedViolationErrors);
    }
  }, [errorResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientValidation.errorResponse?.violations?.length) {
      setClientViolationErrors(
        new Map([
          [0, clientValidation.errorResponse.violations.map(v => ({ ...v, fieldPath: v.fieldPath.substring(18) }))]
        ])
      );
    }
  }, [clientValidation.errorResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormFinish = (): void => {
    form
      .validateFields()
      .then(values => {
        if (
          clientViolationErrors.size > 0 ||
          values.gainerRecords.some(record => !calculateGainerRecordRatesSum(record).eq(new Big(100)))
        ) {
          messageUtils.errorNotification({
            message: t("common.error"),
            description: t("contract.validations.formError")
          });
        } else {
          const processedValues = { ...values };
          processedValues.clients = client ? [clientToCreateUpdateContractClient(client)] : [];
          processedValues.clientIds = [];
          processedValues.policyHolderIndex = 0;
          delete processedValues.clientIdentifiers;

          if (initialContract) {
            onUpdateFormSubmit?.({ id: initialContract.id, object: processedValues });
          } else {
            onCreateFormSubmit?.(processedValues);
          }
        }
      })
      .catch((errors: ValidateErrorEntity) => {
        messageUtils.errorNotification({
          message: t("common.error"),
          description: t("contract.validations.formError")
        });
        resolveFormValidationError(errors);
      });
  };

  const handleClientChange = (client?: Client): void => {
    setClient(client);
    if (client) {
      clientValidation.onValidate({ prefix: `clients[${0}]`, client: clientToCreateUpdateContractClient(client) });
    }
  };

  return (
    <Form form={form} layout="vertical" name="thirdPillarContractForm">
      <HiddenInput name="type" initialValue={ContractType.THIRD_PILLAR_CONTRACT} />
      <HiddenInput name="optimisticLockVersion" />
      <HiddenInput name="status" />

      <ContractFormHeaderSection
        initialContract={initialContract}
        financialSector={ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS}
        privilegedChangesPermission={Permission.PRIVILEGED_CHANGES_ON_VERIFIED_THIRD_PILLAR}
        form={form}
        client={client}
        clientViolationErrors={clientViolationErrors}
        onClientChange={handleClientChange}
        onClientViolationErrorsDelete={() => setClientViolationErrors(new Map())}
      />

      <ThirdPillarContractFormDataSection form={form} />

      <ContractFormAgentsSection
        initialContract={initialContract}
        privilegedChangesPermission={Permission.PRIVILEGED_CHANGES_ON_VERIFIED_THIRD_PILLAR}
        form={form}
        onFinish={handleFormFinish}
        onCancel={onCancelClick}
      />
    </Form>
  );
};

export default ThirdPillarContractForm;
