import AntIcon from "./AntIcon";
import style from "./DeleteIcon.module.scss";

interface Props {
  index?: number;
  onClick?(index?: number): void;
  style?: React.CSSProperties;
}

const DeleteIcon = (props: Props) => (
  <AntIcon
    type="minus-circle"
    style={props.style}
    className={style.deleteIcon}
    onClick={() => props.onClick?.(props.index)}
  />
);

export default DeleteIcon;
