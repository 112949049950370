import { Button, Col, Divider, Drawer, Popconfirm, Row } from "antd";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import t from "../../../../../../app/i18n";
import { SecondaryButton } from "../../../../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { DrawerSizes } from "../../../../../../common/constants";
import { RootState } from "../../../../../../common/types";
import { tInterval } from "../../../../../../common/utils/translationUtils";
import { CalcResult } from "../../../types";
import { filterApplicableResults } from "../../../utils";
import { selectTravelDraft } from "../../ducks";
import { TravelInsuranceType } from "../../enums";
import { TravelCalcDraft, TravelCalcResultData } from "../../types";
import TravelCalcResultItem from "./TravelCalcResultItem";
import styles from "./TravelCalResults.module.scss";

interface Props {
  open: boolean;
  insuranceType: TravelInsuranceType;
  calcResults: CalcResult<TravelCalcResultData>[][];
  selectedResult?: CalcResult<TravelCalcResultData>;
  onClose: () => void;
  onSaveDraftClick: (overwriteExisting: boolean) => void;
  onGenerateOfferClick: () => void;
  onGenerateContractClick: (result: CalcResult<TravelCalcResultData>) => void;
  onShowErrorsClick?: (result: CalcResult<TravelCalcResultData>) => void;
}

const TravelCalcResults = (props: Props) => {
  const draft = useSelector<RootState, TravelCalcDraft | undefined>(selectTravelDraft);

  return (
    <Drawer
      title={t("calc.travel.sections.results")}
      width={DrawerSizes.MEDIUM}
      open={props.open}
      closable
      onClose={props.onClose}
    >
      <Row>
        <Col flex={1}>
          <div style={{ paddingBottom: 65 }}>
            {props.calcResults
              .filter(resultsRow => filterApplicableResults(resultsRow).length > 0)
              .map((resultsRow, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <Row>
                    <Col span={24}>
                      <h3 style={{ paddingLeft: 16 }}>{resultsRow[0]?.insuranceInstitution.name}</h3>
                    </Col>
                  </Row>

                  <Row>
                    {filterApplicableResults(resultsRow).map((result, index) => {
                      const resultSelected = !!(
                        props.selectedResult &&
                        props.selectedResult.insuranceInstitution.id === result.insuranceInstitution.id &&
                        props.selectedResult.coverage === result.coverage
                      );

                      return (
                        <Col
                          span={12}
                          key={index}
                          className={classNames(styles.resultData, resultSelected ? styles.resultDataSelected : "")}
                        >
                          <TravelCalcResultItem
                            result={result}
                            disabled={resultSelected}
                            insuranceType={props.insuranceType}
                            onGenerateContractClick={props.onGenerateContractClick}
                            onShowErrorsClick={props.onShowErrorsClick}
                          />
                        </Col>
                      );
                    })}
                  </Row>

                  {rowIndex < props.calcResults.length - 1 && (
                    <Row>
                      <Col span={24}>
                        <Divider />
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              ))}
          </div>

          <div className={styles.calcResultsNavigation}>
            <div>
              {draft ? (
                <Popconfirm
                  title={
                    <>
                      <b>{t("calc.helpers.loadedDraftTitle")}:</b>
                      <div className="margin-top-tiny margin-bottom-tiny">
                        {draft.clientName}
                        <br />
                        {t("calc.travel.enums.insuranceType." + draft.draftData.generalData.insuranceType)}
                        <br />
                        {t("calc.travel.enums.territorialValidity." + draft.draftData.generalData.territorialValidity)}
                        {draft.draftData.generalData.riskGroup &&
                          ` | ${t("calc.travel.enums.riskGroup." + draft.draftData.generalData.riskGroup)}`}
                        <br />
                        {draft.draftData.clientsData.insuredClients &&
                          tInterval(
                            "contract.helpers.insuredClientsCount_interval",
                            draft.draftData.clientsData.insuredClients.length
                          )}
                      </div>
                      <i>{t("calc.helpers.loadedDraftDesc")}</i>
                    </>
                  }
                  icon={<AntIcon type="question" />}
                  okText={t("calc.actions.overwriteDraft")}
                  cancelText={t("calc.actions.saveNewDraft")}
                  overlayStyle={{ maxWidth: 450 }}
                  onConfirm={() => props.onSaveDraftClick(true)}
                  onCancel={() => props.onSaveDraftClick(false)}
                >
                  <SecondaryButton icon={<AntIcon type="form" />}>{t("calc.actions.saveDraft")}</SecondaryButton>
                </Popconfirm>
              ) : (
                <SecondaryButton icon={<AntIcon type="form" />} onClick={() => props.onSaveDraftClick(false)}>
                  {t("calc.travel.actions.saveDraft")}
                </SecondaryButton>
              )}
            </div>

            <Button type="primary" icon={<AntIcon type="file-text" />} onClick={props.onGenerateOfferClick}>
              {t("calc.actions.generateOffer")}
            </Button>

            <Button icon={<AntIcon type="double-right" />} onClick={props.onClose}>
              {t("common.close")}
            </Button>
          </div>
        </Col>
      </Row>
    </Drawer>
  );
};

export default TravelCalcResults;
