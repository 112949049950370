import { Checkbox, Col, Form, InputNumber, Modal, Row, Select } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import InputAddon from "../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import {
  inputNumberDecimalStandardProps,
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import { InstitutionType } from "../../../institution/enums";
import { requests } from "../../api";
import { createCoverageLimitActions, updateCoverageLimitActions } from "../../ducks";
import { CoverageLimitEnum } from "../../enums";
import { CoverageLimit, CreateUpdateCoverageLimit } from "../../types";

interface Props {
  coverageLimit?: CoverageLimit;
  open: boolean;
  onCreate?: typeof createCoverageLimitActions.request;
  onUpdate?: typeof updateCoverageLimitActions.request;
  onFormCancel: () => void;
}

const CoverageLimitForm = ({ coverageLimit, open, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateCoverageLimit>();
  useFormErrorHandler(form, "coverageLimit.attrs", [requests.CREATE_COVERAGE_LIMIT, requests.UPDATE_COVERAGE_LIMIT]);

  useEffect(() => {
    if (open && coverageLimit) {
      form.setFieldsValue({ ...coverageLimit, institutionId: coverageLimit.institution.id });
    }
  }, [coverageLimit, open, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_COVERAGE_LIMIT, requests.UPDATE_COVERAGE_LIMIT],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (coverageLimit) {
          onUpdate?.({ id: coverageLimit.id, object: values });
        } else {
          onCreate?.(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={coverageLimit ? t("coverageLimit.titles.updateLimit") : t("coverageLimit.titles.createLimit")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="coverageLimitForm">
        <HiddenInput name="optimisticLockVersion" />
        <Row gutter={rowGutter}>
          <Col span={12}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionId",
                label: t("coverageLimit.attrs.institutionId"),
                rules: [validations.notNull]
              }}
              optionsProps={{
                includeDeactivated: true,
                filterType: InstitutionType.INSURANCE_COMPANY
              }}
            />
          </Col>

          <Col span={12}>
            <Form.Item
              name="coverageLimitEnum"
              label={t("coverageLimit.enums.coverageLimitEnum._label")}
              rules={[validations.none]}
            >
              <Select
                {...selectStandardProps}
                allowClear
                options={Object.keys(CoverageLimitEnum).map(limit => ({
                  value: limit,
                  label: t("coverageLimit.enums.coverageLimitEnum." + limit)
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="health"
              label={t("coverageLimit.attrs.health")}
              rules={[validations.notNull, validations.minNumber(0)]}
            >
              <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="million" />} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="property"
              label={t("coverageLimit.attrs.property")}
              rules={[validations.notNull, validations.minNumber(0)]}
            >
              <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="million" />} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="deactivated"
              valuePropName="checked"
              rules={[validations.none]}
              className="form-item-without-label"
              initialValue={false}
            >
              <Checkbox>{t("coverageLimit.attrs.deactivated")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CoverageLimitForm;
