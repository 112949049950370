import { Col, Form, InputNumber, Modal, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  inputNumberDecimalStandardProps,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { BailAccountSettings, CreateUpdateBailAccountSettings } from "../../types";

interface Props {
  settings?: BailAccountSettings;
  open: boolean;
  onCreateSubmit?: (settings: CreateUpdateBailAccountSettings) => void;
  onUpdateSubmit?: (settings: CreateUpdateBailAccountSettings) => void;
  onFormCancel: () => void;
}

const BailAccountSettingsForm = ({ settings, open, onCreateSubmit, onUpdateSubmit, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateBailAccountSettings>();

  useFormErrorHandler(form, "commissions.bailAccount.attrs", [
    requests.CREATE_BAIL_ACCOUNT_SETTINGS,
    requests.UPDATE_BAIL_ACCOUNT_SETTINGS
  ]);

  useEffect(() => {
    if (open && settings) {
      form.setFieldsValue(settings);
    }
  }, [open, settings, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_BAIL_ACCOUNT_SETTINGS, requests.UPDATE_BAIL_ACCOUNT_SETTINGS],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (settings) {
          onUpdateSubmit?.(values);
        } else {
          onCreateSubmit?.(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 12;

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={
        settings
          ? t("commissions.bailAccount.titles.updateSettings")
          : t("commissions.bailAccount.titles.createSettings")
      }
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="bailAccountSettingsForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="targetAmount"
              label={t("commissions.bailAccount.attrs.targetAmount")}
              rules={[validations.notNull, validations.minNumber(0.01)]}
            >
              <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={"rate"}
              label={t("commissions.bailAccount.attrs.rate")}
              rules={[validations.notNull, validations.minNumber(0.01), validations.maxNumber(100)]}
            >
              <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="percentage" />} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BailAccountSettingsForm;
