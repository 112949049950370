import { Col, Divider, Row } from "antd";
import classNames from "classnames";
import React from "react";
import t from "../../../../../../app/i18n";
import { CalcType } from "../../../../enums";
import { CalcResult } from "../../../types";
import { filterApplicableResults } from "../../../utils";
import { VehicleCalcResultData, VehicleCalcType } from "../../types";
import VehicleCalcResultItem from "./VehicleCalcResultItem";
import styles from "./VehicleCalcResultsSection.module.scss";

interface Props {
  calcType: VehicleCalcType;
  resultsRows: CalcResult<VehicleCalcResultData>[][];
  selectedResult?: CalcResult<VehicleCalcResultData>;
  threeColumnsLayout?: boolean;
  onGenerateContractClick(result: CalcResult<VehicleCalcResultData>): void;
  onShowErrorsClick?(result: CalcResult<VehicleCalcResultData>): void;
}

const VehicleCalcResultsSection = ({ calcType, resultsRows, selectedResult, threeColumnsLayout, ...props }: Props) => {
  const colSpan = calcType === CalcType.GAP ? 24 : threeColumnsLayout ? 8 : 12;

  return (
    <>
      <div className={styles.resultColumnTitle}>{t("calc.enums.calcType." + calcType)}</div>

      {resultsRows.map((resultsRow, rowIndex) => (
        <React.Fragment key={rowIndex}>
          <Row style={{ paddingLeft: 16 }}>
            <Col span={24}>
              <h3>{resultsRow[0]?.insuranceInstitution.name}</h3>
            </Col>
          </Row>

          <Row>
            {filterApplicableResults(resultsRow).map((result, index) => {
              const resultSelected = !!(
                selectedResult &&
                selectedResult.insuranceInstitution.id === result.insuranceInstitution.id &&
                selectedResult.coverage === result.coverage &&
                selectedResult.calcType === result.calcType
              );

              return (
                <Col
                  span={colSpan}
                  key={index}
                  className={classNames(styles.resultData, resultSelected ? styles.resultDataSelected : "")}
                >
                  <VehicleCalcResultItem
                    result={result}
                    disabled={resultSelected}
                    onGenerateContractClick={props.onGenerateContractClick}
                    onShowErrorsClick={props.onShowErrorsClick}
                  />
                </Col>
              );
            })}
          </Row>

          {rowIndex < resultsRows.length - 1 && (
            <Row>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default VehicleCalcResultsSection;
