import { Alert, Checkbox, Col, Form, Input, Modal, Row } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { RootState } from "../../../../common/types";
import {
  phoneNumberNormalizeFunction,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import { selectIsSystemAdmin } from "../../../auth/ducks";
import { requests } from "../../api";
import { adminUpdateUserActions } from "../../ducks";
import { AdminUpdateUser, UserAdminView } from "../../types";
import { hasConfirmedAgentUserAccount, hasConfirmedClientUserAccount } from "../../utils";

interface Props {
  open: boolean;
  user?: UserAdminView;
  onFormSubmit: typeof adminUpdateUserActions.request;
  onFormCancel: () => void;
}

const AdminUpdateUserForm = ({ open, user, onFormSubmit, onFormCancel }: Props) => {
  const isSystemAdmin = useSelector<RootState, boolean | undefined>(selectIsSystemAdmin);

  const [form] = Form.useForm<AdminUpdateUser>();
  useFormErrorHandler(form, "user.attrs", [requests.ADMIN_UPDATE_USER]);

  useEffect(() => {
    if (open && user) {
      form.setFieldsValue({
        optimisticLockVersion: user.optimisticLockVersion,
        email: user.emailToConfirm,
        phone: user.phoneToConfirm,
        name: user.name,
        locked: user.locked,
        systemAdmin: user.agentUserRole?.systemAdmin
      });
    }
  }, [open, user, form]);

  const inProgress = useRequestFinishedCallback([requests.ADMIN_UPDATE_USER], onFormCancel);

  const handleFormSubmit = (): void => {
    if (user) {
      form
        .validateFields()
        .then(values => {
          onFormSubmit({
            id: user.id,
            object: values
          });
        })
        .catch(resolveFormValidationError);
    }
  };

  const colSpan = 8;

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("user.titles.updateUser")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="userUpdateForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="name"
              label={t("user.attrs.name")}
              rules={[validations.notNull, validations.size(1, 255), validations.pattern(regexPatterns.nameRegex)]}
            >
              <Input placeholder={t("user.helpers.userNamePlaceholder")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.locked !== next.locked}>
              {({ getFieldValue }) => (
                <Form.Item
                  name="locked"
                  className="form-item-without-label"
                  valuePropName="checked"
                  initialValue={false}
                  rules={[validations.none]}
                >
                  <Checkbox disabled={!getFieldValue("locked")}>{t("user.attrs.locked")}</Checkbox>
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          {isSystemAdmin && (
            <Col span={colSpan}>
              <Form.Item
                name="systemAdmin"
                className="form-item-without-label"
                valuePropName="checked"
                initialValue={false}
                rules={[validations.none]}
              >
                <Checkbox>{t("user.attrs.systemAdmin")}</Checkbox>
              </Form.Item>
            </Col>
          )}
        </Row>

        {user && !hasConfirmedAgentUserAccount(user) && !hasConfirmedClientUserAccount(user) && (
          <>
            <Row gutter={rowGutter}>
              <Col span={colSpan}>
                <Form.Item
                  name="email"
                  label={t("user.attrs.email")}
                  rules={[validations.notBlank, validations.size(1, 254), validations.email]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={colSpan}>
                <Form.Item
                  name="phone"
                  label={t("user.attrs.phone")}
                  rules={[validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber]}
                  normalize={phoneNumberNormalizeFunction}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={colSpan * 3}>
                <Alert
                  className="margin-top-medium"
                  message={t("user.helpers.updateUnconfirmedUserInfo")}
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
          </>
        )}
      </Form>

      {user && hasConfirmedAgentUserAccount(user) && (
        <Alert message={t("user.helpers.updateInfo")} type="info" showIcon />
      )}
    </Modal>
  );
};

export default AdminUpdateUserForm;
