import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { EntityIdObject, EntityObject, PageRequest, PageResult, TwoLevelEntityIdObject } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  ChangeContractVerificationStatus,
  Contract,
  ContractAttachment,
  ContractCommission,
  ContractExternalView,
  ContractFilterExportRequest,
  ContractFilterPageRequest,
  ContractFilterPageResult,
  ContractVehiclesExportRequest,
  CreateUpdateContract,
  RecalculatePredictedCommissionsRequest
} from "./types";

const FILTER_CONTRACTS = ApiRequestAdapter.create("/contracts/filter", "POST");
const IMPORT_CONTRACTS = ApiRequestAdapter.create("/contracts/import", "POST");
const DOWNLOAD_CONTRACTS_IMPORT_RESULT = ApiRequestAdapter.create("/contracts/import/{0}", "GET");
const DOWNLOAD_CONTRACTS_EXPORT = ApiRequestAdapter.create("/contracts/export", "POST");
const DOWNLOAD_CONTRACT_VEHICLES_EXPORT = ApiRequestAdapter.create("/contracts/{0}/export-vehicles", "GET");
const RECALCULATE_PREDICTED_COMMISSIONS = ApiRequestAdapter.create(
  "/contracts/recalculate-predicted-commissions",
  "POST"
);
const GET_CONTRACT = ApiRequestAdapter.create("/contracts/{0}", "GET");
const GET_CONTRACT_EXTERNAL_VIEW = ApiRequestAdapter.create("/contracts/{0}/external-view", "GET");
const FILTER_CONTRACT_COMMISSIONS = ApiRequestAdapter.create("/contracts/{0}/commissions", "GET");
const CREATE_CONTRACT = ApiRequestAdapter.create("/contracts", "POST");
const CHANGE_CONTRACT_VERIFICATION_STATUS = ApiRequestAdapter.create(
  "/contracts/{0}/change-verification-status",
  "POST"
);
const UPDATE_CONTRACT = ApiRequestAdapter.create("/contracts/{0}", "PUT");
const DELETE_CONTRACT = ApiRequestAdapter.create("/contracts/{0}", "DELETE");

const UPLOAD_CONTRACT_ATTACHMENTS = ApiRequestAdapter.create("/contracts/{0}/attachments", "POST");
const DOWNLOAD_CONTRACT_ATTACHMENT = ApiRequestAdapter.create("/contracts/{0}/attachments/{1}", "GET");
const DOWNLOAD_CONTRACT_ATTACHMENTS_AS_ZIP = ApiRequestAdapter.create("/contracts/{0}/attachments/all", "GET");
const DELETE_CONTRACT_ATTACHMENT = ApiRequestAdapter.create("/contracts/{0}/attachments/{1}", "DELETE");

export const requests = {
  FILTER_CONTRACTS,
  IMPORT_CONTRACTS,
  DOWNLOAD_CONTRACTS_IMPORT_RESULT,
  DOWNLOAD_CONTRACTS_EXPORT,
  DOWNLOAD_CONTRACT_VEHICLES_EXPORT,
  RECALCULATE_PREDICTED_COMMISSIONS,
  GET_CONTRACT,
  GET_CONTRACT_EXTERNAL_VIEW,
  FILTER_CONTRACT_COMMISSIONS,
  CREATE_CONTRACT,
  CHANGE_CONTRACT_VERIFICATION_STATUS,
  UPDATE_CONTRACT,
  DELETE_CONTRACT,
  UPLOAD_CONTRACT_ATTACHMENTS,
  DOWNLOAD_CONTRACT_ATTACHMENT,
  DOWNLOAD_CONTRACT_ATTACHMENTS_AS_ZIP,
  DELETE_CONTRACT_ATTACHMENT
};

const api = {
  filterContracts: (request: ContractFilterPageRequest): AxiosPromise<ContractFilterPageResult> => {
    return apiService.post(FILTER_CONTRACTS.url, request, {
      params: { report: request.report }
    });
  },
  importContracts: (request: FormData): AxiosPromise<number> => {
    return apiService.post(IMPORT_CONTRACTS.url, request, { headers: { "Content-Type": "multipart/form-data" } });
  },
  downloadContractsImportResult: (request: EntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_CONTRACTS_IMPORT_RESULT.getParametrizedUrl(request.id), { responseType: "blob" });
  },
  downloadContractsExport: (request: ContractFilterExportRequest): AxiosPromise<Blob> => {
    return apiService.post(DOWNLOAD_CONTRACTS_EXPORT.url, request, {
      params: { report: request.report },
      responseType: "blob"
    });
  },
  downloadContractVehiclesExport: (request: EntityObject<ContractVehiclesExportRequest>): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_CONTRACT_VEHICLES_EXPORT.getParametrizedUrl(request.id), {
      params: request.object,
      paramsSerializer: { serialize: serializeParams },
      responseType: "blob"
    });
  },
  recalculatePredictedCommissions: (request: RecalculatePredictedCommissionsRequest): AxiosPromise<number> => {
    return apiService.post(RECALCULATE_PREDICTED_COMMISSIONS.url, request);
  },
  getContract: (request: EntityIdObject): AxiosPromise<Contract> => {
    return apiService.get(GET_CONTRACT.getParametrizedUrl(request.id));
  },
  getContractExternalView: (request: EntityIdObject): AxiosPromise<ContractExternalView> => {
    return apiService.get(GET_CONTRACT_EXTERNAL_VIEW.getParametrizedUrl(request.id));
  },
  filterContractCommissions: (request: EntityObject<PageRequest>): AxiosPromise<PageResult<ContractCommission>> => {
    return apiService.get(FILTER_CONTRACT_COMMISSIONS.getParametrizedUrl(request.id), { params: request.object });
  },
  createContract: (request: CreateUpdateContract): AxiosPromise<Contract> => {
    return apiService.post(CREATE_CONTRACT.url, request);
  },
  changeContractVerificationStatus: (
    request: EntityObject<ChangeContractVerificationStatus>
  ): AxiosPromise<Contract> => {
    return apiService.post(CHANGE_CONTRACT_VERIFICATION_STATUS.getParametrizedUrl(request.id), request.object);
  },
  updateContract: (request: EntityObject<CreateUpdateContract>): AxiosPromise<Contract> => {
    return apiService.put(UPDATE_CONTRACT.getParametrizedUrl(request.id), request.object);
  },
  deleteContract: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_CONTRACT.getParametrizedUrl(request.id));
  },
  uploadContractAttachments: (request: EntityObject<FormData>): AxiosPromise<ContractAttachment[]> => {
    return apiService.post(UPLOAD_CONTRACT_ATTACHMENTS.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  downloadContractAttachment: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_CONTRACT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  },
  downloadContractAttachmentsAsZip: (request: EntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_CONTRACT_ATTACHMENTS_AS_ZIP.getParametrizedUrl(request.id), {
      responseType: "blob"
    });
  },
  deleteContractAttachment: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_CONTRACT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2));
  }
};

export default api;
