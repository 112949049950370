import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { DEFAULT_LOCALE } from "../../app/i18n";
import { RootState } from "../../common/types";
import { toDate } from "../../common/utils/formUtils";
import { selectIsRequestInProgress } from "../ducks";
import { InstitutionType } from "../institution/enums";
import { requests } from "./api";
import { deleteStateAgentSearchResultAction, searchAgentActions, selectAgentSearchResult } from "./ducks";
import { AgentCompetenceLevel, AgentCompetenceType, AgentType, FinancialSector } from "./enums";
import type {
  Agent,
  AgentBrokerNumber,
  AgentCompetence,
  AgentLicense,
  AgentSearchProps,
  AgentSearchResult,
  LegalAgent,
  NaturalAgent,
  SelfEmployedAgent
} from "./types";

export const useAgentSearch = (): AgentSearchProps => {
  const result = useSelector<RootState, AgentSearchResult>(selectAgentSearchResult);
  const inProgress = useSelector<RootState, boolean>(state => selectIsRequestInProgress(state, requests.SEARCH_AGENT));

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onSearch: searchAgentActions.request,
          onResultDelete: deleteStateAgentSearchResultAction
        },
        dispatch
      ),
    [dispatch]
  );

  return { result, inProgress, ...actions };
};

export const sortAgentCompetences = (competences: AgentCompetence[]): AgentCompetence[] => {
  return [...competences].sort(
    (a, b) =>
      Object.keys(AgentCompetenceType).indexOf(a.type) - Object.keys(AgentCompetenceType).indexOf(b.type) ||
      Object.keys(FinancialSector).indexOf(a.sector) - Object.keys(FinancialSector).indexOf(b.sector) ||
      Object.keys(AgentCompetenceLevel).indexOf(a.level) - Object.keys(AgentCompetenceLevel).indexOf(b.level) ||
      a.label.localeCompare(b.label, DEFAULT_LOCALE, { sensitivity: "accent" })
  );
};

export const sortAgentLicenses = (licenses: AgentLicense[]): AgentLicense[] => {
  return [...licenses].sort(
    (a, b) =>
      Object.keys(FinancialSector).indexOf(a.sector) - Object.keys(FinancialSector).indexOf(b.sector) ||
      toDate(a.registrationProposalDate)?.diff(toDate(b.registrationProposalDate)) ||
      a.value?.localeCompare(b.value, DEFAULT_LOCALE, { sensitivity: "accent" })
  );
};

export const sortAgentBrokerNumbers = (brokerNumbers: AgentBrokerNumber[]): AgentBrokerNumber[] => {
  return [...brokerNumbers].sort(
    (a, b) =>
      Object.keys(InstitutionType).indexOf(a.institution.type) -
        Object.keys(InstitutionType).indexOf(b.institution.type) ||
      a.institution.name.localeCompare(b.institution.name, DEFAULT_LOCALE, { sensitivity: "accent" }) ||
      toDate(a.validFrom)?.diff(toDate(b.validFrom)) ||
      toDate(a.validTo)?.diff(toDate(b.validTo)) ||
      a.value.localeCompare(b.value, DEFAULT_LOCALE, { sensitivity: "accent" })
  );
};

export const isNaturalAgent = (agent: Agent): agent is NaturalAgent => agent.type === AgentType.NATURAL;

export const isSelfEmployedAgent = (agent: Agent): agent is SelfEmployedAgent => agent.type === AgentType.SELF_EMPLOYED;

export const isLegalAgent = (agent: Agent): agent is LegalAgent => agent.type === AgentType.LEGAL;
