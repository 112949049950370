import { Route, Routes } from "react-router";
import { WILDCARD_ROUTE } from "../../../common/constants";
import NotFound from "../../../common/pages/NotFound/NotFound";
import { Permission } from "../../../common/security/authorization/enums";
import PageWithPermission from "../../../common/security/authorization/PageWithPermission";
import PostponedCommissionListContainer from "./containers/PostponedCommissionListContainer";

const routes = () => (
  <Routes>
    <Route
      index
      element={
        <PageWithPermission
          component={<PostponedCommissionListContainer />}
          permissions={[Permission.POSTPONED_COMMISSIONS]}
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
