import { Upload } from "antd";
import { UploadProps } from "antd/lib/upload/interface";
import t from "../../../app/i18n";
import messageUtils from "../../utils/messageUtils";
import AntIcon from "../icons/AntIcon";
import MultiLineText from "./MultiLineText";

const UploadDragger = (props: UploadProps) => {
  const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    if (!props.multiple && event.dataTransfer.files.length > 1) {
      messageUtils.warnNotification({ message: t("common.warning"), description: t("helpers.draggerValidationCount") });
    } else if (props.accept) {
      const supportedTypes = props.accept.split(",").map(type => type.trim());
      const unsupportedFiles = [];

      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        const file = event.dataTransfer.files[i];
        if (
          !supportedTypes.some(supportedType =>
            supportedType.startsWith(".")
              ? file?.name.endsWith(supportedType)
              : supportedType.endsWith("*")
                ? file?.type.startsWith(supportedType.split("/")[0] ?? "")
                : supportedType === file?.type
          )
        ) {
          unsupportedFiles.push(file);
        }
      }

      if (unsupportedFiles.length > 0) {
        messageUtils.warnNotification({
          message: t("common.warning"),
          description: (
            <>
              <span>{t("helpers.draggerValidationType")}</span>
              <br />
              <MultiLineText tokens={unsupportedFiles.map(f => f?.name)} />
            </>
          )
        });
      }
    }
  };

  return (
    <Upload.Dragger {...props} onDrop={handleDrop}>
      <AntIcon type="paper-clip" className="margin-right-tiny sub-header-info" />
      <span className="sub-header-info">{t(`helpers.draggerHint${props.multiple ? "MultipleFiles" : "OneFile"}`)}</span>
    </Upload.Dragger>
  );
};

export default UploadDragger;
