import { useBackNavigation } from "../../utils/hooksUtils";
import AntIcon from "../icons/AntIcon";
import styles from "./BackNavigationArrow.module.scss";

interface Props {
  children?: React.ReactNode;
}

const BackNavigationArrow = (props: Props) => {
  const onBackClick = useBackNavigation();

  return (
    <div className={styles.backNavigation}>
      <div onClick={onBackClick} className={styles.backNavigationArrow}>
        <AntIcon type="arrow-left" />
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default BackNavigationArrow;
