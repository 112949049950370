import { Col, Form, InputNumber, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import t from "../../../../../../app/i18n";
import InputAddon from "../../../../../../common/components/form/addons/InputAddon";
import { rowGutter } from "../../../../../../common/constants";
import { formatClientName } from "../../../../../../common/utils/formatUtils";
import { inputNumberDecimalStandardProps, selectStandardProps } from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { Client } from "../../../../../client/types";
import LifeInsuranceTariffSelect from "../../../../../enumerations/components/form/LifeInsuranceTariffSelect";
import { CreateUpdateInsuranceContract, CreateUpdateLifeInsurance } from "../../../../types";

interface Props {
  index: number;
  clients: (Client | undefined)[];
}

const noRepeatedTariffWithInsuredClient =
  (index: number): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) => {
      const insurances = getFieldValue(["insurances"]) || [];
      return value &&
        insurances[index]?.insuredClientIdentifier &&
        insurances.some(
          (insurance: CreateUpdateLifeInsurance, i: number) =>
            index !== i &&
            insurance?.tariffId === value &&
            insurance?.insuredClientIdentifier === insurances[index].insuredClientIdentifier
        )
        ? Promise.reject(t("contract.validations.duplicityLifeTariffWithInsuredClient"))
        : Promise.resolve();
    }
  });

const LifeInsuranceFormPart = ({ index, clients }: Props) => {
  const colSpan = 4;
  const colSpanBig = 6;
  const formNamePrefix = ["insurances", index];
  const formNameDataPrefix = [...formNamePrefix, "insuranceData"];

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan * 3}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.productId !== next.productId}>
            {({ getFieldsValue }) => {
              const { productId } = getFieldsValue([["productId"]]) as CreateUpdateInsuranceContract;
              return (
                <LifeInsuranceTariffSelect
                  formItemProps={{
                    name: [...formNamePrefix, "tariffId"],
                    label: t("contract.attrs.insurances.tariffId"),
                    rules: [validations.notNull, noRepeatedTariffWithInsuredClient(index)]
                  }}
                  optionsProps={{
                    productId,
                    hideAll: !productId,
                    groupByPriority: true
                  }}
                />
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "insurancePeriod"]}
            label={t("contract.attrs.insurances.insuranceData.insurancePeriod")}
            rules={[validations.minNumber(1)]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "insuranceAmount"]}
            label={t("contract.attrs.insurances.insuranceData.insuranceAmount")}
            rules={[validations.minNumber(0.01)]}
          >
            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpanBig}>
          <Form.Item
            name={[...formNamePrefix, "insuredClientIdentifier"]}
            label={t("contract.attrs.insurances.insuredClientIdentifier")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={clients
                .filter((c): c is Client => !!c)
                .map(c => ({ value: c.identifier, label: formatClientName(c) }))}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default LifeInsuranceFormPart;
