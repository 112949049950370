import { ATTACHMENT_BOUNDARY_PATH_PREFIX } from "../../common/constants";

export const AGENT_ROUTE_PREFIX = "/agents";

export const AGENT_ROUTE_PATHS = {
  list: { path: "*", to: AGENT_ROUTE_PREFIX },
  tree: { path: "tree", to: `${AGENT_ROUTE_PREFIX}/tree` },
  create: { path: "new", to: `${AGENT_ROUTE_PREFIX}/new` },
  endingCompetences: { path: "ending-competences", to: `${AGENT_ROUTE_PREFIX}/ending-competences` },
  pointsReport: { path: "points-report", to: `${AGENT_ROUTE_PREFIX}/points-report` },
  bailAccountsReport: { path: "bail-accounts-report", to: `${AGENT_ROUTE_PREFIX}/bail-accounts-report` },
  debtAccountsReport: { path: "debt-accounts-report", to: `${AGENT_ROUTE_PREFIX}/debt-accounts-report` },
  detail: { path: ":id", to: `${AGENT_ROUTE_PREFIX}/:id` },
  commissions: { path: ":id/commissions", to: `${AGENT_ROUTE_PREFIX}/:id/commissions` },
  attachment: { to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${AGENT_ROUTE_PREFIX}/:id1/attachments/:id2` },
  commissionsSettingsAttachment: {
    to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${AGENT_ROUTE_PREFIX}/:id1/commissions-settings/attachments/:id2`
  },
  commissionsOutput: {
    to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${AGENT_ROUTE_PREFIX}/:id1/commissions-outputs/:id2`
  }
};
