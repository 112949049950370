import { Col, Divider, Row } from "antd";
import groupBy from "lodash/groupBy";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import imagePlaceholderLogo from "../../../../assets/images/image-placeholder.svg";
import monlyLogo from "../../../../assets/images/monly.svg";
import { rowGutter } from "../../../../common/constants";
import { Permission } from "../../../../common/security/authorization/enums";
import { RootState } from "../../../../common/types";
import { selectHasPermissions } from "../../../auth/ducks";
import { InstitutionType } from "../../../institution/enums";
import { resolveInstitutionLogo } from "../../../institution/utils";
import { getPartnerSsoActions } from "../../ducks";
import { PartnerSsoType } from "../../enums";
import { PartnerConfig } from "../../types";
import PartnerConfigsCardView from "./PartnerConfigsCardView";

interface Props {
  configList: PartnerConfig[];
  onPartnerSsoGet: typeof getPartnerSsoActions.request;
  onLogoutClick: (configs: PartnerConfig[]) => void;
  onUpdateClick: (configs: PartnerConfig[]) => void;
  onDeleteClick: (configs: PartnerConfig[]) => void;
}

const groupConfigs = (configList: PartnerConfig[], groupKey: (config: PartnerConfig) => string): PartnerConfig[][] => {
  const groups = groupBy(configList, groupKey);
  return Object.keys(groups).map(groupKey => groups[groupKey] as PartnerConfig[]);
};

const PartnerConfigListView = ({ configList, onPartnerSsoGet, onLogoutClick, onUpdateClick, onDeleteClick }: Props) => {
  const hasAdminPartnerConfigsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.ADMIN_PARTNER_CONFIGS)(state)
  );

  const extPortals = groupConfigs(
    configList.filter(config => config.ssoType === PartnerSsoType.MONLY),
    config => config.ssoType
  );

  const insuranceCompanies = groupConfigs(
    configList.filter(config => config.institution?.type === InstitutionType.INSURANCE_COMPANY),
    config => config.institution.id
  );

  const banks = groupConfigs(
    configList.filter(config => config.institution?.type === InstitutionType.BANK),
    config => config.institution.id
  );

  const securitiesBrokers = groupConfigs(
    configList.filter(config => config.institution?.type === InstitutionType.SECURITIES_BROKER),
    config => config.institution.id
  );

  const other = groupConfigs(
    configList.filter(
      config =>
        config.institution?.type !== InstitutionType.INSURANCE_COMPANY &&
        config.institution?.type !== InstitutionType.BANK &&
        config.institution?.type !== InstitutionType.SECURITIES_BROKER &&
        config.ssoType !== PartnerSsoType.MONLY
    ),
    config => config.id
  );

  return configList.length > 0 ? (
    <>
      {extPortals.length > 0 && (
        <Row gutter={rowGutter}>
          {extPortals.map((configs, index) => (
            <Col key={index} sm={8} md={6}>
              <PartnerConfigsCardView
                configs={configs}
                hasAdminPartnerConfigsPermission={hasAdminPartnerConfigsPermission}
                logoProvider={() => monlyLogo}
                onPartnerSsoGet={onPartnerSsoGet}
                onLogoutClick={onLogoutClick}
                onUpdateClick={onUpdateClick}
                onDeleteClick={onDeleteClick}
              />
            </Col>
          ))}
        </Row>
      )}

      {insuranceCompanies.length > 0 && (
        <>
          <Divider orientation="left">
            <b>{t("common.insuranceInstitutions")}</b>
          </Divider>
          <Row gutter={rowGutter}>
            {insuranceCompanies.map((configs, index) => (
              <Col key={index} sm={8} md={6}>
                <PartnerConfigsCardView
                  configs={configs}
                  hasAdminPartnerConfigsPermission={hasAdminPartnerConfigsPermission}
                  logoProvider={() => (configs[0]?.institution ? resolveInstitutionLogo(configs[0].institution) : "")}
                  onPartnerSsoGet={onPartnerSsoGet}
                  onLogoutClick={onLogoutClick}
                  onUpdateClick={onUpdateClick}
                  onDeleteClick={onDeleteClick}
                />
              </Col>
            ))}
          </Row>
        </>
      )}

      {banks.length > 0 && (
        <>
          <Divider orientation="left">
            <b>{t("common.bankInstitutions")}</b>
          </Divider>
          <Row gutter={rowGutter}>
            {banks.map((configs, index) => (
              <Col key={index} sm={8} md={6}>
                <PartnerConfigsCardView
                  configs={configs}
                  hasAdminPartnerConfigsPermission={hasAdminPartnerConfigsPermission}
                  logoProvider={() => (configs[0]?.institution ? resolveInstitutionLogo(configs[0].institution) : "")}
                  onPartnerSsoGet={onPartnerSsoGet}
                  onLogoutClick={onLogoutClick}
                  onUpdateClick={onUpdateClick}
                  onDeleteClick={onDeleteClick}
                />
              </Col>
            ))}
          </Row>
        </>
      )}

      {securitiesBrokers.length > 0 && (
        <>
          <Divider orientation="left">
            <b>{t("common.securitiesBrokers")}</b>
          </Divider>
          <Row gutter={rowGutter}>
            {securitiesBrokers.map((configs, index) => (
              <Col key={index} sm={8} md={6}>
                <PartnerConfigsCardView
                  configs={configs}
                  hasAdminPartnerConfigsPermission={hasAdminPartnerConfigsPermission}
                  logoProvider={() => (configs[0]?.institution ? resolveInstitutionLogo(configs[0].institution) : "")}
                  onPartnerSsoGet={onPartnerSsoGet}
                  onLogoutClick={onLogoutClick}
                  onUpdateClick={onUpdateClick}
                  onDeleteClick={onDeleteClick}
                />
              </Col>
            ))}
          </Row>
        </>
      )}

      {other.length > 0 && (
        <>
          <Divider orientation="left">
            <b>{t("partnerConfig.titles.other")}</b>
          </Divider>
          <Row gutter={rowGutter}>
            {other.map((configs, index) => (
              <Col key={index} sm={8} md={6}>
                <PartnerConfigsCardView
                  configs={configs}
                  hasAdminPartnerConfigsPermission={hasAdminPartnerConfigsPermission}
                  logoProvider={() =>
                    configs[0]?.institution ? resolveInstitutionLogo(configs[0]?.institution) : imagePlaceholderLogo
                  }
                  onPartnerSsoGet={onPartnerSsoGet}
                  onLogoutClick={onLogoutClick}
                  onUpdateClick={onUpdateClick}
                  onDeleteClick={onDeleteClick}
                />
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  ) : (
    <div className="sub-header-info dashed center-align">{t("partnerConfig.helpers.noData")}</div>
  );
};

export default PartnerConfigListView;
