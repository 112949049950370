import { Layout } from "antd";
import classNames from "classnames";
import { Role } from "../../../common/security/authorization/enums";
import { getActiveEnvProfile } from "../../../common/utils/utils";
import HeaderNotificationView from "../header/notification/HeaderNotificationView";
import HeaderGlobalSearch from "../header/search/HeaderGlobalSearch";
import HeaderUserMenuView from "../header/user/HeaderUserMenuView";
import styles from "./Header.module.scss";

interface Props {
  userAuthenticated?: boolean;
  selectedAccountRole?: Role;
}

const Header = (props: Props) => {
  const activeEnvProfile = getActiveEnvProfile();

  return (
    <Layout.Header
      className={classNames(styles.headerWrapper, props.userAuthenticated ? styles.headerAuthenticated : "")}
    >
      <div style={{ width: 400 }}>
        {activeEnvProfile !== "prod" && (
          <span className={styles[`environment-${activeEnvProfile}`]}>{activeEnvProfile.toUpperCase()}</span>
        )}
      </div>

      {props.userAuthenticated && (
        <>
          {props.selectedAccountRole === Role.AGENT && <HeaderGlobalSearch />}
          <div className={styles.headerRightSide}>
            {props.selectedAccountRole === Role.AGENT && <HeaderNotificationView />}
            <HeaderUserMenuView />
          </div>
        </>
      )}
    </Layout.Header>
  );
};

export default Header;
