import { Button, Card, Col, Form, Row, Select } from "antd";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { selectTagsStandardProps } from "../../../../../common/utils/formUtils";
import { hasAllPermissions, hasPermission } from "../../../../../common/utils/utils";
import { validations } from "../../../../../common/utils/validationUtils";
import { selectPermissions } from "../../../../auth/ducks";
import UserSelect from "../../../../enumerations/components/form/UserSelect";
import { CalcType, calcTypeTMap } from "../../../enums";
import { OperationType, operationTypeTMap } from "../../enums";
import { CalcRecordFilterPageRequest } from "../../types";
import CalcTypeTag from "../CalcTypeTag";
import OperationTypeTag from "../OperationTypeTag";

interface Props {
  initialFilter: CalcRecordFilterPageRequest;
  onFilterSubmit: (filter: CalcRecordFilterPageRequest) => void;
}

const CalcRecordsListFilterView = ({ initialFilter, onFilterSubmit }: Props) => {
  const [form] = Form.useForm<CalcRecordFilterPageRequest>();

  const permissions = useSelector<RootState, Permission[]>(selectPermissions);

  useEffect(() => {
    form.setFieldsValue({
      calcType: initialFilter.calcType,
      operationType: initialFilter.operationType,
      userId: initialFilter.userId
    });
  }, [initialFilter, form]);

  const calcTypeOptions = useMemo(() => {
    const calcTypes: CalcType[] = [];

    if (hasPermission(permissions, Permission.ADMIN_MTPL_CALCULATORS)) {
      calcTypes.push(CalcType.MTPL);
    }
    if (hasPermission(permissions, Permission.ADMIN_CRASH_CALCULATORS)) {
      calcTypes.push(CalcType.CRASH);
    }
    if (hasAllPermissions(permissions, [Permission.ADMIN_MTPL_CALCULATORS, Permission.ADMIN_CRASH_CALCULATORS])) {
      calcTypes.push(CalcType.MTPL_CRASH);
    }
    if (hasPermission(permissions, Permission.ADMIN_CRASH_CALCULATORS)) {
      calcTypes.push(CalcType.GAP, CalcType.PAS);
    }
    if (hasPermission(permissions, Permission.ADMIN_REALTY_CALCULATORS)) {
      calcTypes.push(CalcType.REALTY);
    }
    if (hasPermission(permissions, Permission.ADMIN_TRAVEL_CALCULATORS)) {
      calcTypes.push(CalcType.TRAVEL);
    }

    return calcTypes;
  }, [permissions]);

  const colSpan = 6;

  return (
    <Card className="card-box" title={<h2>{t("calc.records.titles.list")}</h2>}>
      <Form form={form} layout="vertical" onFinish={onFilterSubmit} name="calcRecordsListFilterForm">
        <Row justify="center">
          <Col flex="1200px">
            <Row gutter={rowGutter} justify="center">
              <Col span={colSpan}>
                <Form.Item
                  name="operationType"
                  label={t("calc.records.enums.operationType._label")}
                  rules={[validations.none]}
                >
                  <Select
                    {...selectTagsStandardProps(operationTypeTMap)}
                    allowClear
                    options={Object.keys(OperationType).map(type => ({
                      value: type,
                      label: <OperationTypeTag type={OperationType[type as OperationType]} />
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item name="calcType" label={t("calc.enums.calcType._label")} rules={[validations.none]}>
                  <Select
                    {...selectTagsStandardProps(calcTypeTMap)}
                    allowClear
                    options={calcTypeOptions.map(type => ({
                      value: type,
                      label: <CalcTypeTag type={type} />
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <UserSelect
                  formItemProps={{
                    name: "userId",
                    label: t("calc.records.filter.user"),
                    rules: [validations.none]
                  }}
                  selectProps={{ allowClear: true }}
                />
              </Col>

              <Col flex="110px" className="no-title-space">
                <Form.Item>
                  <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                    {t("calc.records.filter.submit")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CalcRecordsListFilterView;
