import { VehicleMappingType, VehicleMappingTypeKeys } from "./enums";
import {
  CreateVehicleMapping,
  CreateVehicleMappingFormItem,
  UpdateVehicleMapping,
  UpdateVehicleMappingFormItem,
  VehicleMapping
} from "./types";

export const VEHICLE_TYPES_PATH: {
  [key in VehicleMappingType]: {
    name: string;
    keyId: keyof Pick<UpdateVehicleMappingFormItem, `${VehicleMappingTypeKeys}Id`>;
    keyName: keyof Pick<UpdateVehicleMappingFormItem, `${VehicleMappingTypeKeys}Name`>;
    keyIdentifier: keyof Pick<
      CreateVehicleMappingFormItem | UpdateVehicleMappingFormItem,
      `${VehicleMappingTypeKeys}Identifier`
    >;
  };
} = {
  [VehicleMappingType.ALLIANZ]: {
    name: VehicleMappingTypeKeys.ALLIANZ,
    keyId: `${VehicleMappingTypeKeys.ALLIANZ}Id`,
    keyName: `${VehicleMappingTypeKeys.ALLIANZ}Name`,
    keyIdentifier: `${VehicleMappingTypeKeys.ALLIANZ}Identifier`
  },
  [VehicleMappingType.COLONNADE]: {
    name: VehicleMappingTypeKeys.COLONNADE,
    keyId: `${VehicleMappingTypeKeys.COLONNADE}Id`,
    keyName: `${VehicleMappingTypeKeys.COLONNADE}Name`,
    keyIdentifier: `${VehicleMappingTypeKeys.COLONNADE}Identifier`
  },
  [VehicleMappingType.CSOB]: {
    name: VehicleMappingTypeKeys.CSOB,
    keyId: `${VehicleMappingTypeKeys.CSOB}Id`,
    keyName: `${VehicleMappingTypeKeys.CSOB}Name`,
    keyIdentifier: `${VehicleMappingTypeKeys.CSOB}Identifier`
  },
  [VehicleMappingType.DEFEND]: {
    name: VehicleMappingTypeKeys.DEFEND,
    keyId: `${VehicleMappingTypeKeys.DEFEND}Id`,
    keyName: `${VehicleMappingTypeKeys.DEFEND}Name`,
    keyIdentifier: `${VehicleMappingTypeKeys.DEFEND}Identifier`
  },
  [VehicleMappingType.GENERALI]: {
    name: VehicleMappingTypeKeys.GENERALI,
    keyId: `${VehicleMappingTypeKeys.GENERALI}Id`,
    keyName: `${VehicleMappingTypeKeys.GENERALI}Name`,
    keyIdentifier: `${VehicleMappingTypeKeys.GENERALI}Identifier`
  },
  [VehicleMappingType.KOMUNALNA]: {
    name: VehicleMappingTypeKeys.KOMUNALNA,
    keyId: `${VehicleMappingTypeKeys.KOMUNALNA}Id`,
    keyName: `${VehicleMappingTypeKeys.KOMUNALNA}Name`,
    keyIdentifier: `${VehicleMappingTypeKeys.KOMUNALNA}Identifier`
  },
  [VehicleMappingType.KOOPERATIVA]: {
    name: VehicleMappingTypeKeys.KOOPERATIVA,
    keyId: `${VehicleMappingTypeKeys.KOOPERATIVA}Id`,
    keyName: `${VehicleMappingTypeKeys.KOOPERATIVA}Name`,
    keyIdentifier: `${VehicleMappingTypeKeys.KOOPERATIVA}Identifier`
  },
  [VehicleMappingType.UNION_MTPL]: {
    name: VehicleMappingTypeKeys.UNION_MTPL,
    keyId: `${VehicleMappingTypeKeys.UNION_MTPL}Id`,
    keyName: `${VehicleMappingTypeKeys.UNION_MTPL}Name`,
    keyIdentifier: `${VehicleMappingTypeKeys.UNION_MTPL}Identifier`
  },
  [VehicleMappingType.UNION_CRASH]: {
    name: VehicleMappingTypeKeys.UNION_CRASH,
    keyId: `${VehicleMappingTypeKeys.UNION_CRASH}Id`,
    keyName: `${VehicleMappingTypeKeys.UNION_CRASH}Name`,
    keyIdentifier: `${VehicleMappingTypeKeys.UNION_CRASH}Identifier`
  },
  [VehicleMappingType.UNIQA]: {
    name: VehicleMappingTypeKeys.UNIQA,
    keyId: `${VehicleMappingTypeKeys.UNIQA}Id`,
    keyName: `${VehicleMappingTypeKeys.UNIQA}Name`,
    keyIdentifier: `${VehicleMappingTypeKeys.UNIQA}Identifier`
  }
};

export const VEHICLE_IDENTIFIER_DELIMITER = "<->";

export const parseVehicleMappingsFromIdentifiers = (
  item: CreateVehicleMappingFormItem | UpdateVehicleMappingFormItem
): CreateVehicleMapping | UpdateVehicleMapping => {
  const itemCopy: CreateVehicleMappingFormItem | UpdateVehicleMappingFormItem = { ...item };

  Object.values(VehicleMappingType).forEach(type => {
    const typePath = VEHICLE_TYPES_PATH[type];
    const mappingIdentifier = itemCopy[typePath.keyIdentifier];

    if (mappingIdentifier) {
      const identifierTokens = mappingIdentifier.split(VEHICLE_IDENTIFIER_DELIMITER);
      itemCopy[typePath.keyId] = identifierTokens[0] as string;
      itemCopy[typePath.keyName] = identifierTokens[1] as string;
    } else {
      delete itemCopy[typePath.keyId];
      delete itemCopy[typePath.keyName];
    }

    delete itemCopy[typePath.keyIdentifier];
  });

  return itemCopy;
};

export const parseVehicleMappingsToUpdateFormItems = (...items: VehicleMapping[]): UpdateVehicleMappingFormItem[] => {
  const updateItems = items.map<UpdateVehicleMappingFormItem>(mapping => ({
    ...mapping,
    createdAt: undefined,
    updatedAt: undefined
  }));

  updateItems.forEach(item => {
    Object.values(VehicleMappingType).forEach(type => {
      const idValue = item[VEHICLE_TYPES_PATH[type].keyId];
      const nameValue = item[VEHICLE_TYPES_PATH[type].keyName];
      item[VEHICLE_TYPES_PATH[type].keyIdentifier] =
        idValue && nameValue ? idValue + VEHICLE_IDENTIFIER_DELIMITER + nameValue : undefined;
    });
  });

  return updateItems;
};
