export enum TravelInsuranceType {
  SHORT_TERM = "SHORT_TERM",
  YEAR = "YEAR",
  CANCELLATION = "CANCELLATION"
}

export enum TravelRiskGroup {
  TOURIST = "TOURIST",
  SPORT = "SPORT",
  MANUAL_WORK = "MANUAL_WORK"
}

export enum TravelTerritorialValidity {
  EUROPE = "EUROPE",
  WORLD = "WORLD",
  WORLD_WITHOUT_USA_AND_CANADA = "WORLD_WITHOUT_USA_AND_CANADA",
  CZ = "CZ"
}

export enum TravelLuggageInsuranceAmount {
  ONE_THOUSAND = "ONE_THOUSAND",
  TWO_THOUSAND = "TWO_THOUSAND",
  THREE_THOUSAND = "THREE_THOUSAND",
  FOUR_THOUSAND = "FOUR_THOUSAND"
}

export enum TravelClientDiscountType {
  ISIC_CARD = "ISIC_CARD",
  EURO26_CARD = "EURO26_CARD",
  ITIC_CARD = "ITIC_CARD",
  UNION_HEALTH_INSURANCE_CLIENT = "UNION_HEALTH_INSURANCE_CLIENT"
}

export enum TravelVehicleAssistanceType {
  CAR = "CAR",
  MOTORCYCLE = "MOTORCYCLE"
}

export enum TravelAbandonedHouseholdType {
  HOUSE = "HOUSE",
  FLAT = "FLAT"
}

export enum TravelPetType {
  DOG = "DOG",
  CAT = "CAT",
  RABBIT = "RABBIT",
  BIRD = "BIRD"
}
