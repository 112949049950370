import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import DeactivatedTag from "../../../../common/components/tags/DeactivatedTag";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { ExportFileType } from "../../../../common/enums";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { deleteProductActions } from "../../ducks";
import { Product, ProductFilterPageResult } from "../../types";

interface Props {
  productsPage: ProductFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (product: Product) => void;
  onDelete: typeof deleteProductActions.request;
  onExport: (exportFileType: ExportFileType) => void;
}

const ProductTableView = (props: Props) => {
  const columns: ColumnsType<Product> = [
    {
      key: "group",
      title: t("product.attrs.productGroupId"),
      width: 160,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.productGroup.name}</Ellipsis>
    },
    {
      key: "name",
      title: t("product.helpers.productName"),
      width: 190,
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <>
          <Ellipsis>{record.name}</Ellipsis>
          {record.deactivated && (
            <>
              <br />
              <DeactivatedTag style={{ marginRight: 0 }} />
            </>
          )}
        </>
      )
    },
    {
      key: "sector",
      title: t("product.enums.financialSector._label"),
      width: 150,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{t("product.enums.financialSector." + record.sector)}</Ellipsis>
    },
    {
      key: "insuranceType",
      title: (
        <>
          <span>{t("contract.enums.insuranceType._label")}</span>
          <br />
          <i>{t("product.attrs.tariffGroupId")}</i>
        </>
      ),
      width: 150,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        record.insuranceType && (
          <>
            <Ellipsis>{t("contract.enums.insuranceType." + record.insuranceType)}</Ellipsis>
            {record.tariffGroup && (
              <>
                <br />
                <Ellipsis style={{ fontStyle: "italic" }}>{record.tariffGroup.name}</Ellipsis>
              </>
            )}
          </>
        )
    },
    {
      key: "institutions",
      title: t("common.institutions"),
      width: 320,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institutions.map(institution => institution.name).join(", ")}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <div className="nowrap">
          <ActionTextIcon
            icon="edit"
            color="blue"
            text={t("common.edit")}
            onClick={() => props.onUpdateClick(record)}
          />

          <Divider type="vertical" />

          <Popconfirm
            title={t("product.helpers.productDeleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => props.onDelete({ id: record.id })}
          >
            <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <Card className="card-box">
      <div
        className={props.productsPage.totalElementsCount > 0 ? "table-header-export-actions" : "margin-bottom-small"}
      >
        <ActionTextIcon
          icon="download"
          color="blue"
          text={t("common.exportXlsx")}
          onClick={() => props.onExport(ExportFileType.XLSX)}
        />

        <Divider type="vertical" />

        <ActionTextIcon
          icon="download"
          color="green"
          text={t("common.exportCsv")}
          onClick={() => props.onExport(ExportFileType.CSV)}
        />
      </div>

      <Table<Product>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.LARGE }}
        dataSource={props.productsPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: props.productsPage.pageIndex + 1,
          pageSize: props.productsPage.pageSize,
          total: props.productsPage.totalElementsCount,
          onChange: props.onPageChange
        }}
      />
    </Card>
  );
};

export default ProductTableView;
