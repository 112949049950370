import { Col, Form, Row, Select } from "antd";
import { useState } from "react";
import t from "../../../../app/i18n";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import { rowGutter } from "../../../../common/constants";
import { Feature, featurePermissionsMap, Permission } from "../../../../common/security/authorization/enums";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import messageUtils from "../../../../common/utils/messageUtils";
import { FinancialSector } from "../../../agent/enums";
import { ProductFinancialSector } from "../../../product/enums";
import { defaultPermissionsMap, DefaultPermissionsSet, financialSectorPermissionsMap } from "../../enums";

interface Props {
  allowedFeatures: Feature[];
  onPermissionsSet: (permissions: Permission[]) => void;
}

const DefaultPermissionsFormPart = ({ allowedFeatures, onPermissionsSet }: Props) => {
  const [permissionsSet, setPermissionsSet] = useState<DefaultPermissionsSet>();
  const [sectors, setSectors] = useState<(FinancialSector | ProductFinancialSector.OTHERS)[]>([]);
  const colSpan = 8;

  const handlePermissionsSetClick = (): void => {
    if (!permissionsSet || sectors.length === 0) {
      messageUtils.errorMessage(t("user.helpers.defaultPermissionsFillError"));
    } else {
      let permissionsToRemove: Permission[] = [];

      financialSectorPermissionsMap.forEach((permissions, sector) => {
        if (!sectors.includes(sector)) {
          permissionsToRemove = [...permissionsToRemove, ...permissions];
        }
      });

      Object.values(Feature)
        .filter(feature => !allowedFeatures.includes(feature))
        .forEach(
          feature => (permissionsToRemove = [...permissionsToRemove, ...(featurePermissionsMap.get(feature) ?? [])])
        );

      onPermissionsSet(
        defaultPermissionsMap.get(permissionsSet)?.filter(permission => !permissionsToRemove.includes(permission)) ?? []
      );

      setPermissionsSet(undefined);
      setSectors([]);
    }
  };

  return (
    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item label={t("user.enums.defaultPermissionsSet._label")}>
          <Select<DefaultPermissionsSet>
            {...selectStandardProps}
            allowClear
            size="small"
            value={permissionsSet}
            options={Object.keys(DefaultPermissionsSet).map(type => ({
              value: type,
              label: t("user.enums.defaultPermissionsSet." + type)
            }))}
            onChange={setPermissionsSet}
          />
        </Form.Item>
      </Col>

      <Col span={colSpan}>
        <Form.Item label={t("common.financialSectors")}>
          <Select<(FinancialSector | ProductFinancialSector.OTHERS)[]>
            {...selectStandardProps}
            allowClear
            mode="multiple"
            maxTagCount="responsive"
            size="small"
            value={sectors}
            options={[
              ...Object.keys(FinancialSector).map(sector => ({
                value: sector,
                label: t("agent.enums.financialSector." + sector)
              })),
              {
                value: ProductFinancialSector.OTHERS,
                label: t("product.enums.financialSector." + ProductFinancialSector.OTHERS)
              }
            ]}
            onChange={setSectors}
          />
        </Form.Item>
      </Col>

      <Col span={colSpan}>
        <Form.Item className="form-item-without-label">
          <ActionButton icon="menu-unfold" onClick={handlePermissionsSetClick}>
            {t("user.actions.setDefaultPermissions")}
          </ActionButton>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default DefaultPermissionsFormPart;
