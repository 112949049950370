import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../common/types";
import { selectIsRequestInProgress } from "../../ducks";
import { requests } from "./api";
import {
  autocompleteVehiclesActions,
  deleteStateVehicleAutocompleteResultAction,
  selectVehicleAutocompleteResult
} from "./ducks";
import { VehicleAutocompleteProps, VehicleAutocompleteResult } from "./types";

export const useVehicleAutocomplete = (): VehicleAutocompleteProps => {
  const result = useSelector<RootState, VehicleAutocompleteResult>(selectVehicleAutocompleteResult);
  const inProgress = useSelector<RootState, boolean>(state =>
    selectIsRequestInProgress(state, requests.AUTOCOMPLETE_VEHICLES)
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onSearch: autocompleteVehiclesActions.request,
          onResultDelete: deleteStateVehicleAutocompleteResultAction
        },
        dispatch
      ),
    [dispatch]
  );

  return { result, inProgress, onSearch: actions.onSearch, onResultDelete: actions.onResultDelete };
};
