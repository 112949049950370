import {
  Alert,
  AutoComplete,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin
} from "antd";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { FormInstance, Rule } from "antd/lib/form";
import { Dayjs } from "dayjs";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../../app/i18n";
import InputAddon from "../../../../../../common/components/form/addons/InputAddon";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../common/constants";
import { RootState } from "../../../../../../common/types";
import { formatClientName, formatLocaleDate, formatLocaleNumber } from "../../../../../../common/utils/formatUtils";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerOutOfInterval,
  inputNumberDecimalStandardProps,
  inputNumberIntegerStandardProps,
  licensePlateNormalizeFunction,
  selectStandardProps,
  toDate,
  upperCaseStringNormalizeFunction
} from "../../../../../../common/utils/formUtils";
import { contains, isDefinedValue, removeStringWhiteSpaces } from "../../../../../../common/utils/utils";
import { regexPatterns, validationFunctions, validations } from "../../../../../../common/utils/validationUtils";
import { GapDuration, VehiclePurpose } from "../../../../../calculator/calcs/vehicle/enums";
import { SPECS_MAP } from "../../../../../calculator/calcs/vehicle/utils";
import { ClientType } from "../../../../../client/enums";
import { Client } from "../../../../../client/types";
import ComplicitySelect from "../../../../../enumerations/components/form/ComplicitySelect";
import CoverageLimitSelect from "../../../../../enumerations/components/form/CoverageLimitSelect";
import VehicleBrandSelect from "../../../../../enumerations/components/form/VehicleBrandSelect";
import VehicleColorSelect from "../../../../../enumerations/components/form/VehicleColorSelect";
import VehicleModelSelect from "../../../../../enumerations/components/form/VehicleModelSelect";
import { selectVehicleOtherBrandsIdsEnums, selectVehicleOtherModelsIdsEnums } from "../../../../../enumerations/ducks";
import { AutocompleteRequestOrigin, AutocompleteVehiclesBy } from "../../../../../vehicle/autocomplete/enums";
import { useVehicleAutocomplete } from "../../../../../vehicle/autocomplete/utils";
import { Bodywork, FuelType, InsuranceType, Transmission, VehicleCategory } from "../../../../enums";
import {
  CreateUpdateInsuranceContract,
  CreateUpdateVehicle,
  CreateUpdateVehicleInsurance,
  Vehicle,
  VehicleInsuranceData
} from "../../../../types";

interface Props {
  index: number;
  clients: (Client | undefined)[];
  form: FormInstance<CreateUpdateInsuranceContract>;
  type: InsuranceType;
}

const noRepeatedLicensePlate =
  (index: number): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) =>
      value &&
      getFieldValue(["insurances"])?.some(
        (insurance: CreateUpdateVehicleInsurance, i: number) => index !== i && insurance?.licensePlate === value
      )
        ? Promise.reject(t("contract.validations.duplicityVehicleLicensePlate"))
        : Promise.resolve(),
    warningOnly: true
  });

const noRepeatedVin =
  (index: number): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) =>
      value &&
      getFieldValue(["insurances"])?.some(
        (insurance: CreateUpdateVehicleInsurance, i: number) => index !== i && insurance?.vehicle?.vin === value
      )
        ? Promise.reject(t("contract.validations.duplicityVehicleVin"))
        : Promise.resolve(),
    warningOnly: true
  });

const AUTOCOMPLETE_MAX_SIZE = 7;

const VehicleInsuranceFormPart = ({ index, clients, form, type }: Props) => {
  const otherBrandsIds = useSelector<RootState, string[]>(selectVehicleOtherBrandsIdsEnums);
  const otherModelIds = useSelector<RootState, string[]>(selectVehicleOtherModelsIdsEnums);

  const autocomplete = useVehicleAutocomplete();

  const [autocompleteFocusItem, setAutocompleteFocusItem] = useState<AutocompleteVehiclesBy>();
  const [autocompleteExecuted, setAutocompleteExecuted] = useState<boolean>(false);

  useEffect(() => {
    if (
      autocomplete.result.data.length === 1 &&
      autocompleteExecuted &&
      autocomplete.result.keyword ===
        (autocomplete.result.by === AutocompleteVehiclesBy.VIN
          ? autocomplete.result.data[0]?.vin
          : autocomplete.result.data[0]?.licensePlate)
    ) {
      setVehicleData(autocomplete.result.data[0] as Vehicle, autocomplete.result.by);
    }
  }, [autocomplete.result, form]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAutocompleteSearchDebounced = useMemo(
    () =>
      debounce((value: string, focusedItem: AutocompleteVehiclesBy): void => {
        const keyword = removeStringWhiteSpaces(value);

        if (keyword && keyword.length >= 5 && validationFunctions.validateSearchKeyword(keyword)) {
          setAutocompleteExecuted(true);
          autocomplete.onSearch({
            keyword: keyword.toUpperCase(),
            maxResultSize: AUTOCOMPLETE_MAX_SIZE,
            by: focusedItem,
            origin: AutocompleteRequestOrigin.CONTRACT
          });
        } else if (autocomplete.result.data) {
          setAutocompleteExecuted(false);
          autocomplete.onResultDelete();
        }
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleAutocompleteSearch = useCallback(
    (value: string, focusedItem: AutocompleteVehiclesBy): void => {
      handleAutocompleteSearchDebounced(value, focusedItem);
    },
    [handleAutocompleteSearchDebounced]
  );

  const handleAutocompleteSelect = (value: string, focusedItem: AutocompleteVehiclesBy): void => {
    setVehicleData(
      autocomplete.result.data.find(vehicle =>
        focusedItem === AutocompleteVehiclesBy.VIN
          ? vehicle.vin === value
          : vehicle.licensePlate === removeStringWhiteSpaces(value)
      ) as Vehicle,
      focusedItem
    );
  };

  const handleBrandIdChange = (): void => {
    const insurances = getInsurances();
    insurances[index] = {
      ...(insurances[index] as CreateUpdateVehicleInsurance),
      vehicle: { ...insurances[index]?.vehicle, modelId: undefined } as CreateUpdateVehicle
    };
    form.setFieldsValue({ insurances });
  };

  const handleCategoryChange = (category: VehicleCategory): void => {
    const insurances = getInsurances();
    const vehicle = insurances[index]?.vehicle;
    insurances[index] = {
      ...(insurances[index] as CreateUpdateVehicleInsurance),
      vehicle: {
        ...(vehicle as CreateUpdateVehicle),
        fuelType: contains(SPECS_MAP.get("fuelType") as VehicleCategory[], category) ? vehicle?.fuelType : undefined,
        engineDisplacement: contains(SPECS_MAP.get("engineDisplacement") as VehicleCategory[], category)
          ? vehicle?.engineDisplacement
          : undefined,
        enginePower: contains(SPECS_MAP.get("enginePower") as VehicleCategory[], category)
          ? vehicle?.enginePower
          : undefined,
        transmission: contains(SPECS_MAP.get("transmission") as VehicleCategory[], category)
          ? vehicle?.transmission
          : undefined,
        bodywork: contains(SPECS_MAP.get("bodywork") as VehicleCategory[], category) ? vehicle?.bodywork : undefined,
        seatsNumber: contains(SPECS_MAP.get("seatsNumber") as VehicleCategory[], category)
          ? vehicle?.seatsNumber
          : undefined,
        doorsNumber: contains(SPECS_MAP.get("doorsNumber") as VehicleCategory[], category)
          ? vehicle?.doorsNumber
          : undefined
      }
    };
    form.setFieldsValue({ insurances });
  };

  const handleFuelTypeChange = (fuelType: FuelType): void => {
    const insurances = getInsurances();
    const vehicle = insurances[index]?.vehicle;
    insurances[index] = {
      ...(insurances[index] as CreateUpdateVehicleInsurance),
      vehicle: {
        ...(vehicle as CreateUpdateVehicle),
        engineDisplacement: fuelType === FuelType.BEV ? undefined : vehicle?.engineDisplacement
      }
    };
    form.setFieldsValue({ insurances });
  };

  const handleLeasingChange = (): void => {
    const insurances = getInsurances();
    insurances[index] = { ...(insurances[index] as CreateUpdateVehicleInsurance), vehicleOwnerIdentifier: undefined };
    form.setFieldsValue({ insurances });
  };

  const resolveAutocompleteOptions = (focusedItem: AutocompleteVehiclesBy): DataSourceItemObject[] => {
    return focusedItem === autocomplete.result.by
      ? autocomplete.result.data.map<DataSourceItemObject>(vehicle => ({
          value:
            focusedItem === AutocompleteVehiclesBy.VIN
              ? vehicle.vin
              : licensePlateNormalizeFunction(vehicle.licensePlate),
          text:
            focusedItem === AutocompleteVehiclesBy.VIN
              ? vehicle.vin
              : licensePlateNormalizeFunction(vehicle.licensePlate)
        }))
      : [];
  };

  const setVehicleData = (vehicleData: Vehicle, focusedItem?: AutocompleteVehiclesBy): void => {
    const vehicle = { ...vehicleData };
    const brandId = vehicle.model?.brand?.id;
    const modelId = vehicle.model?.id;
    const colorId = vehicle.color?.id;

    const insurances = getInsurances();
    const licensePlate = insurances[index]?.licensePlate;

    Modal.confirm({
      title: t("calc.vehicle.helpers.vehicleFound"),
      content: (
        <>
          <table className="data-table-view">
            <tbody>
              <tr>
                <td>{t("common.vehicle")}:</td>
                <td>
                  <b>
                    {`${vehicle.customBrand || vehicle.model?.brand?.name || ""} ${
                      vehicle.customModel || vehicle.model?.name || ""
                    }`}
                  </b>
                </td>
              </tr>

              <tr>
                <td>{t("contract.attrs.insurances.licensePlate")}:</td>
                <td>
                  <b>{licensePlateNormalizeFunction(vehicle.licensePlate)}</b>
                </td>
              </tr>

              <tr>
                <td>{t("contract.attrs.insurances.vehicle.vin")}:</td>
                <td>
                  <b>{vehicle.vin}</b>
                </td>
              </tr>

              <tr>
                <td>{t("calc.vehicle.sections.otherData")}:</td>
                <td>
                  {[
                    vehicle.category ? t("contract.enums.vehicleCategory." + vehicle.category) : undefined,
                    formatLocaleDate(vehicle.firstRegistrationDate),
                    isDefinedValue(vehicle.engineDisplacement)
                      ? `${formatLocaleNumber(vehicle.engineDisplacement)} ${t("common.sign.engineDisplacement")}`
                      : undefined,
                    isDefinedValue(vehicle.enginePower)
                      ? `${formatLocaleNumber(vehicle.enginePower)} ${t("common.sign.enginePower")}`
                      : undefined,
                    vehicle.fuelType ? t("contract.enums.fuelType." + vehicle.fuelType) : undefined,
                    isDefinedValue(vehicle.totalWeight)
                      ? `${formatLocaleNumber(vehicle.totalWeight)} ${t("common.sign.weight")}`
                      : undefined
                  ]
                    .filter(v => !!v)
                    .join(", ")}
                </td>
              </tr>
            </tbody>
          </table>

          {focusedItem === AutocompleteVehiclesBy.VIN &&
          licensePlate &&
          removeStringWhiteSpaces(licensePlate) !== vehicle.licensePlate ? (
            <Alert
              className="margin-top-small margin-right-medium"
              type="warning"
              showIcon
              message={t("calc.vehicle.helpers.twoPlatesFound", { licensePlate })}
            />
          ) : (
            <Alert
              className="margin-top-small margin-right-medium"
              type="info"
              showIcon
              message={t("calc.vehicle.helpers.vehicleFillCheck")}
            />
          )}
        </>
      ),
      width: 630,
      okText: t("common.yes"),
      cancelText: t("common.no"),
      onOk: () => {
        const vehicleData: Record<string, any> = { ...vehicle };
        delete vehicleData.model;
        delete vehicleData.color;
        delete vehicleData.certificateCategory;

        insurances[index] = {
          ...(insurances[index] as CreateUpdateVehicleInsurance),
          licensePlate:
            focusedItem === AutocompleteVehiclesBy.VIN && !!licensePlate
              ? licensePlate
              : licensePlateNormalizeFunction(vehicle.licensePlate),
          insuranceData: {
            ...(insurances[index]?.insuranceData as VehicleInsuranceData),
            registrationCertificateNumber: vehicle.registrationCertificateNumber
          },
          vehicle: {
            ...(vehicleData as CreateUpdateVehicle),
            firstRegistrationDate: vehicle.firstRegistrationDate,
            brandId,
            modelId,
            colorId
          }
        };

        form.setFieldsValue({ insurances });
      },
      afterClose: () => {
        setAutocompleteExecuted(false);
        autocomplete.onResultDelete();
      }
    });
  };

  const getInsurances = (): CreateUpdateVehicleInsurance[] => {
    return [...(form.getFieldValue(["insurances"]) || [])];
  };

  const colSpan = 4;
  const colSpanBig = 6;
  const formNamePrefix = ["insurances", index];
  const formNameDataPrefix = [...formNamePrefix, "insuranceData"];
  const formNameCoveragesPrefix = [...formNameDataPrefix, "coverages"];
  const formNameMtplPrefix = [...formNameDataPrefix, "mtpl"];
  const formNameVehiclePrefix = [...formNamePrefix, "vehicle"];

  const standardInsuranceDataForm = (
    <>
      <Col span={colSpan}>
        <Form.Item
          name={[...formNameDataPrefix, "purpose"]}
          label={t("calc.vehicle.enums.vehiclePurpose._label")}
          rules={[validations.notNull]}
        >
          <Select
            {...selectStandardProps}
            options={Object.keys(VehiclePurpose).map(purpose => ({
              value: purpose,
              label: t("calc.vehicle.enums.vehiclePurpose." + purpose)
            }))}
          />
        </Form.Item>
      </Col>

      <Col span={colSpan}>
        <Form.Item
          name={[...formNameDataPrefix, "leasing"]}
          valuePropName="checked"
          rules={[validations.none]}
          initialValue={false}
          className="form-item-without-label"
        >
          <Checkbox onChange={handleLeasingChange}>{t("contract.attrs.insurances.insuranceData.leasing")}</Checkbox>
        </Form.Item>
      </Col>
    </>
  );

  let insuranceDataForm = undefined;
  switch (type) {
    case InsuranceType.MTPL:
      insuranceDataForm = (
        <>
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item noStyle shouldUpdate={(prev, next) => prev.institutionId !== next.institutionId}>
                {({ getFieldValue }) => (
                  <CoverageLimitSelect
                    formItemProps={{
                      name: [...formNamePrefix, "coverageLimitId"],
                      label: t("contract.attrs.insurances.coverageLimitId"),
                      rules: [validations.notNull]
                    }}
                    institutionId={getFieldValue(["institutionId"])}
                  />
                )}
              </Form.Item>
            </Col>

            {standardInsuranceDataForm}

            <Col span={colSpan}>
              <Form.Item
                name={[...formNameCoveragesPrefix, "extendedAssistance"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
                className="form-item-without-label"
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.extendedAssistance")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={[...formNameCoveragesPrefix, "glass"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
                className="form-item-without-label"
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.glass")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) =>
                  get(prev, formNameCoveragesPrefix)?.glass !== get(next, formNameCoveragesPrefix)?.glass
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue([...formNameCoveragesPrefix, "glass"]) && (
                    <Form.Item
                      name={[...formNameCoveragesPrefix, "glassAmount"]}
                      label={t("contract.attrs.insurances.insuranceData.coverages.glassAmount")}
                      rules={[validations.minNumber(1)]}
                    >
                      <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name={[...formNameCoveragesPrefix, "animal"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.animal")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={[...formNameCoveragesPrefix, "element"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.element")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={[...formNameCoveragesPrefix, "injury"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.injury")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan * 2}>
              <Form.Item
                name={[...formNameCoveragesPrefix, "theftAndVandalism"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.theftAndVandalism")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </>
      );
      break;
    case InsuranceType.CRASH:
      insuranceDataForm = (
        <>
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name={[...formNameDataPrefix, "price"]}
                label={t("contract.attrs.insurances.insuranceData.price")}
                rules={[validations.minNumber(1)]}
              >
                <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item noStyle shouldUpdate={(prev, next) => prev.institutionId !== next.institutionId}>
                {({ getFieldValue }) => (
                  <ComplicitySelect
                    formItemProps={{
                      name: [...formNamePrefix, "complicityId"],
                      label: t("contract.attrs.insurances.complicityId"),
                      rules: [validations.notNull]
                    }}
                    institutionId={getFieldValue(["institutionId"])}
                  />
                )}
              </Form.Item>
            </Col>

            {standardInsuranceDataForm}
          </Row>

          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name={[...formNameCoveragesPrefix, "extendedAssistance"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.extendedAssistance")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={[...formNameCoveragesPrefix, "glass"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.glass")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={[...formNameCoveragesPrefix, "replacementVehicle"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.replacementVehicle")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan * 2}>
              <Form.Item
                name={[...formNameCoveragesPrefix, "abroadVehicleRepair"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.abroadVehicleRepair")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                get(prev, formNameCoveragesPrefix)?.gap !== get(next, formNameCoveragesPrefix)?.gap
              }
            >
              {({ getFieldValue }) => {
                const gap = getFieldValue([...formNameCoveragesPrefix, "gap"]) as boolean;
                return (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameCoveragesPrefix, "gap"]}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                        className={gap ? "form-item-without-label" : undefined}
                      >
                        <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.gap")}</Checkbox>
                      </Form.Item>
                    </Col>

                    {gap && (
                      <>
                        <Col span={colSpan}>
                          <Form.Item
                            name={[...formNameCoveragesPrefix, "gapDuration"]}
                            label={t("calc.vehicle.enums.gapDuration._label")}
                            rules={[validations.none]}
                          >
                            <Select
                              {...selectStandardProps}
                              allowClear
                              options={Object.keys(GapDuration).map(duration => ({
                                value: duration,
                                label: t("calc.vehicle.enums.gapDuration." + duration)
                              }))}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={colSpan}>
                          <Form.Item
                            name={[...formNameCoveragesPrefix, "gapComplicityReinsurance"]}
                            valuePropName="checked"
                            rules={[validations.none]}
                            initialValue={false}
                            className="form-item-without-label"
                          >
                            <Checkbox>
                              {t("contract.attrs.insurances.insuranceData.coverages.gapComplicityReinsurance")}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.institutionId !== next.institutionId || prev.effectiveBeginningDate !== next.effectiveBeginningDate
            }
          >
            {({ getFieldValue }) => {
              const beginningDate = getFieldValue(["effectiveBeginningDate"]) as string | undefined;
              let mtplBeginningDateRules: Rule[] = [validations.none];
              let mtplBeginningDateMax: Dayjs;
              if (beginningDate) {
                mtplBeginningDateMax = toDate(beginningDate)?.add(1, "year").subtract(1, "day") as Dayjs;
                mtplBeginningDateRules = [validations.dateInInterval(beginningDate, mtplBeginningDateMax)];
              }
              return (
                <>
                  <Row gutter={rowGutter}>
                    <Col span={24}>
                      <b>{t("contract.attrs.insurances.insuranceData.mtpl._label")}</b>
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <CoverageLimitSelect
                        formItemProps={{
                          name: [...formNamePrefix, "coverageLimitId"],
                          label: t("contract.attrs.insurances.coverageLimitId"),
                          rules: [validations.none]
                        }}
                        institutionId={getFieldValue(["institutionId"])}
                        selectProps={{ allowClear: true }}
                      />
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameMtplPrefix, "effectiveBeginningDate"]}
                        label={t("contract.attrs.insurances.insuranceData.mtpl.effectiveBeginningDate")}
                        rules={mtplBeginningDateRules}
                        {...datePickerFormItemProps}
                      >
                        <DatePicker
                          {...datePickerStandardProps}
                          allowClear
                          disabledDate={current =>
                            beginningDate
                              ? disableDatePickerOutOfInterval(current, beginningDate, mtplBeginningDateMax)
                              : false
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Form.Item>
        </>
      );
      break;
    case InsuranceType.GAP:
      insuranceDataForm = (
        <>
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name={[...formNameDataPrefix, "duration"]}
                label={t("calc.vehicle.enums.gapDuration._label")}
                rules={[validations.notNull]}
              >
                <Select
                  {...selectStandardProps}
                  options={Object.keys(GapDuration).map(duration => ({
                    value: duration,
                    label: t("calc.vehicle.enums.gapDuration." + duration)
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={[...formNameDataPrefix, "buyingPrice"]}
                label={t("contract.attrs.insurances.insuranceData.buyingPrice")}
                rules={[validations.minNumber(1)]}
              >
                <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
              </Form.Item>
            </Col>

            {standardInsuranceDataForm}

            <Col span={colSpan}>
              <Form.Item
                name={[...formNameDataPrefix, "complicityReinsurance"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
                className="form-item-without-label"
              >
                <Checkbox>{t("contract.attrs.insurances.insuranceData.complicityReinsurance")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </>
      );
      break;
    case InsuranceType.PAS:
      insuranceDataForm = <Row gutter={rowGutter}>{standardInsuranceDataForm}</Row>;
      break;
  }

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <b>{t("contract.attrs.insurances.vehicle._label")}</b>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Spin spinning={autocomplete.inProgress && autocompleteFocusItem === AutocompleteVehiclesBy.LICENSE_PLATE}>
            <Form.Item
              name={[...formNamePrefix, "licensePlate"]}
              label={
                <LabelWithTooltip
                  tooltip={t("calc.vehicle.helpers.licensePlateDesc")}
                  label={t("contract.attrs.insurances.licensePlate")}
                />
              }
              rules={[validations.licensePlate, noRepeatedLicensePlate(index)]}
              normalize={licensePlateNormalizeFunction}
            >
              <AutoComplete
                popupMatchSelectWidth={false}
                onFocus={() => setAutocompleteFocusItem(AutocompleteVehiclesBy.LICENSE_PLATE)}
                onSearch={value => handleAutocompleteSearch(value, AutocompleteVehiclesBy.LICENSE_PLATE)}
                onSelect={(value: string) => handleAutocompleteSelect(value, AutocompleteVehiclesBy.LICENSE_PLATE)}
                options={resolveAutocompleteOptions(AutocompleteVehiclesBy.LICENSE_PLATE)}
              />
            </Form.Item>
          </Spin>
        </Col>

        <Col span={colSpan}>
          <Spin spinning={autocomplete.inProgress && autocompleteFocusItem === AutocompleteVehiclesBy.VIN}>
            <Form.Item
              name={[...formNameVehiclePrefix, "vin"]}
              label={t("contract.attrs.insurances.vehicle.vin")}
              rules={[validations.notBlank, validations.size(3, 17), noRepeatedVin(index)]}
              normalize={upperCaseStringNormalizeFunction}
            >
              <AutoComplete
                popupMatchSelectWidth={false}
                onFocus={() => setAutocompleteFocusItem(AutocompleteVehiclesBy.VIN)}
                onSearch={value => handleAutocompleteSearch(value, AutocompleteVehiclesBy.VIN)}
                onSelect={(value: string) => handleAutocompleteSelect(value, AutocompleteVehiclesBy.VIN)}
                options={resolveAutocompleteOptions(AutocompleteVehiclesBy.VIN)}
              />
            </Form.Item>
          </Spin>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameVehiclePrefix, "firstRegistrationDate"]}
            label={t("contract.attrs.insurances.vehicle.firstRegistrationDate")}
            rules={[validations.notNull]}
            {...datePickerFormItemProps}
          >
            <DatePicker {...datePickerStandardProps} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "registrationCertificateNumber"]}
            label={t("contract.attrs.insurances.insuranceData.registrationCertificateNumber")}
            rules={[validations.pattern(regexPatterns.registrationCertificateNumberRegex), validations.length(8)]}
            normalize={upperCaseStringNormalizeFunction}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <VehicleBrandSelect
            formItemProps={{
              name: [...formNameVehiclePrefix, "brandId"],
              label: (
                <LabelWithTooltip
                  label={t("contract.attrs.insurances.vehicle.brandId")}
                  tooltip={t("calc.vehicle.helpers.vehicleBrandDesc")}
                />
              ),
              rules: [validations.notNull]
            }}
            selectProps={{ onChange: handleBrandIdChange }}
          />
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              get(prev, formNameVehiclePrefix)?.brandId !== get(next, formNameVehiclePrefix)?.brandId
            }
          >
            {({ getFieldValue }) => {
              const brandId = getFieldValue([...formNameVehiclePrefix, "brandId"]);
              return (
                <VehicleModelSelect
                  formItemProps={{
                    name: [...formNameVehiclePrefix, "modelId"],
                    label: (
                      <LabelWithTooltip
                        label={t("contract.attrs.insurances.vehicle.modelId")}
                        tooltip={t("calc.vehicle.helpers.vehicleModelDesc")}
                      />
                    ),
                    rules: [validations.notNull]
                  }}
                  brandId={brandId}
                  selectProps={{ placeholder: !brandId ? t("contract.helpers.vehicleModelPlaceholder") : undefined }}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          get(prev, formNameVehiclePrefix)?.brandId !== get(next, formNameVehiclePrefix)?.brandId ||
          get(prev, formNameVehiclePrefix)?.modelId !== get(next, formNameVehiclePrefix)?.modelId
        }
      >
        {({ getFieldValue }) => {
          const hasOtherBrand = contains(otherBrandsIds, getFieldValue([...formNameVehiclePrefix, "brandId"]));
          const hasOtherModel = contains(otherModelIds, getFieldValue([...formNameVehiclePrefix, "modelId"]));
          return hasOtherBrand || hasOtherModel ? (
            <Row gutter={rowGutter}>
              {hasOtherBrand && (
                <Col span={colSpan} offset={colSpan * 4}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "customBrand"]}
                    label={t("contract.attrs.insurances.vehicle.customBrand")}
                    rules={[validations.notBlank, validations.size(1, 255)]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
              {hasOtherModel && (
                <Col span={colSpan} offset={hasOtherBrand ? 0 : colSpan * 5}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "customModel"]}
                    label={t("contract.attrs.insurances.vehicle.customModel")}
                    rules={[validations.notBlank, validations.size(1, 255)]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
            </Row>
          ) : null;
        }}
      </Form.Item>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameVehiclePrefix, "category"]}
            label={t("contract.enums.vehicleCategory._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(VehicleCategory).map(category => ({
                value: category,
                label: t("contract.enums.vehicleCategory." + category)
              }))}
              onChange={handleCategoryChange}
            />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            get(prev, formNameVehiclePrefix)?.category !== get(next, formNameVehiclePrefix)?.category ||
            get(prev, formNameVehiclePrefix)?.fuelType !== get(next, formNameVehiclePrefix)?.fuelType
          }
        >
          {({ getFieldValue }) => {
            const category = getFieldValue([...formNameVehiclePrefix, "category"]) as VehicleCategory;
            const fuelType = getFieldValue([...formNameVehiclePrefix, "fuelType"]) as FuelType;
            return (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "engineDisplacement"]}
                    label={t("contract.attrs.insurances.vehicle.engineDisplacement")}
                    rules={[
                      validations.minNumber(1),
                      !contains(SPECS_MAP.get("engineDisplacement") as VehicleCategory[], category) ||
                      fuelType === FuelType.BEV
                        ? validations.none
                        : validations.notNull
                    ]}
                  >
                    <InputNumber
                      {...inputNumberIntegerStandardProps}
                      addonAfter={<InputAddon type="engineDisplacement" />}
                      disabled={
                        !contains(SPECS_MAP.get("engineDisplacement") as VehicleCategory[], category) ||
                        fuelType === FuelType.BEV
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "enginePower"]}
                    label={t("contract.attrs.insurances.vehicle.enginePower")}
                    rules={[
                      validations.minNumber(1),
                      !contains(SPECS_MAP.get("enginePower") as VehicleCategory[], category)
                        ? validations.none
                        : validations.notNull
                    ]}
                  >
                    <InputNumber
                      {...inputNumberIntegerStandardProps}
                      addonAfter={<InputAddon type="enginePower" />}
                      disabled={!contains(SPECS_MAP.get("enginePower") as VehicleCategory[], category)}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "fuelType"]}
                    label={t("contract.enums.fuelType._label")}
                    rules={[
                      !contains(SPECS_MAP.get("fuelType") as VehicleCategory[], category)
                        ? validations.none
                        : validations.notNull
                    ]}
                  >
                    <Select
                      {...selectStandardProps}
                      disabled={!contains(SPECS_MAP.get("fuelType") as VehicleCategory[], category)}
                      options={Object.keys(FuelType).map(type => ({
                        value: type,
                        label: t("contract.enums.fuelType." + type)
                      }))}
                      onChange={handleFuelTypeChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "transmission"]}
                    label={t("contract.enums.transmission._label")}
                    rules={[validations.none]}
                  >
                    <Select
                      {...selectStandardProps}
                      allowClear
                      disabled={!contains(SPECS_MAP.get("transmission") as VehicleCategory[], category)}
                      options={Object.keys(Transmission).map(transmission => ({
                        value: transmission,
                        label: t("contract.enums.transmission." + transmission)
                      }))}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "bodywork"]}
                    label={t("contract.enums.bodywork._label")}
                    rules={[validations.none]}
                  >
                    <Select
                      {...selectStandardProps}
                      allowClear
                      disabled={!contains(SPECS_MAP.get("bodywork") as VehicleCategory[], category)}
                      options={Object.keys(Bodywork).map(bodywork => ({
                        value: bodywork,
                        label: t("contract.enums.bodywork." + bodywork)
                      }))}
                    />
                  </Form.Item>
                </Col>
              </>
            );
          }}
        </Form.Item>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameVehiclePrefix, "totalWeight"]}
            label={t("contract.attrs.insurances.vehicle.totalWeight")}
            rules={[validations.notNull, validations.minNumber(1)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="weight" />} />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            get(prev, formNameVehiclePrefix)?.category !== get(next, formNameVehiclePrefix)?.category
          }
        >
          {({ getFieldValue }) => {
            const category = getFieldValue([...formNameVehiclePrefix, "category"]) as VehicleCategory;
            return (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "seatsNumber"]}
                    label={t("contract.attrs.insurances.vehicle.seatsNumber")}
                    rules={[
                      validations.minNumber(0),
                      !contains(SPECS_MAP.get("seatsNumber") as VehicleCategory[], category)
                        ? validations.none
                        : validations.notNull
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      disabled={!contains(SPECS_MAP.get("seatsNumber") as VehicleCategory[], category)}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "doorsNumber"]}
                    label={t("contract.attrs.insurances.vehicle.doorsNumber")}
                    rules={[validations.minNumber(0)]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      disabled={!contains(SPECS_MAP.get("doorsNumber") as VehicleCategory[], category)}
                    />
                  </Form.Item>
                </Col>
              </>
            );
          }}
        </Form.Item>

        <Col span={colSpan}>
          <VehicleColorSelect
            formItemProps={{
              name: [...formNameVehiclePrefix, "colorId"],
              label: t("contract.attrs.insurances.vehicle.colorId"),
              rules: [validations.notNull]
            }}
          />
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <b>{t("contract.sections.vehicleInsuranceData")}</b>
        </Col>
      </Row>

      {insuranceDataForm}

      <Row gutter={rowGutter}>
        <Col span={colSpanBig}>
          <Form.Item
            name={[...formNamePrefix, "vehicleHolderIdentifier"]}
            label={t("contract.attrs.insurances.vehicleHolderIdentifier")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={clients
                .filter((client): client is Client => !!client)
                .map(c => ({ value: c.identifier, label: formatClientName(c) }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpanBig}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              get(prev, formNameDataPrefix)?.leasing !== get(next, formNameDataPrefix)?.leasing
            }
          >
            {({ getFieldValue }) => {
              const leasing = getFieldValue([...formNameDataPrefix, "leasing"]) as boolean;
              return (
                <Form.Item
                  name={[...formNamePrefix, "vehicleOwnerIdentifier"]}
                  label={
                    leasing ? (
                      <LabelWithTooltip
                        label={t("contract.attrs.insurances.vehicleOwnerIdentifier")}
                        tooltip={t("contract.helpers.vehicleOwnerClient")}
                      />
                    ) : (
                      t("contract.attrs.insurances.vehicleOwnerIdentifier")
                    )
                  }
                  rules={[validations.notNull]}
                >
                  <Select
                    {...selectStandardProps}
                    options={clients
                      .filter((c, i): c is Client => (leasing ? c?.type === ClientType.LEGAL && i > 0 : !!c))
                      .map(c => ({ value: c.identifier, label: formatClientName(c) }))}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default VehicleInsuranceFormPart;
