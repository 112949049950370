import { Card, Checkbox, Col, Form, InputNumber, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import t from "../../../../../../../app/i18n";
import LabelWithPopover from "../../../../../../../common/components/form/labels/LabelWithPopover";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../../common/constants";
import PlaceOfInsuranceForm from "../../../../../../../common/modules/address/PlaceOfInsuranceForm";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import { resolveFormValidationError, selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../../common/utils/validationUtils";
import ClientDrawerForm from "../../../../../../client/components/drawers/ClientDrawerForm";
import ClientSearchInput from "../../../../../../client/components/search/ClientSearchInput";
import { ClientFormStage, ClientFormType, ClientSearchActionType, ClientType } from "../../../../../../client/enums";
import { Client, LegalClient, NaturalClient, SelfEmployedClient } from "../../../../../../client/types";
import { useClientSearch } from "../../../../../../client/utils";
import { CalcDataSource } from "../../../../types";
import { AreaType, BuildingLevel, BuildingState } from "../../../enums";
import { RealtyCalc, RealtyCalcClientsData } from "../../../types";

interface Props {
  form: FormInstance<RealtyCalc>;
  initialClientsData?: RealtyCalcClientsData;
  calcDataSource: CalcDataSource;
  policyHolder?: Client;
  clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  onPolicyHolderChange: (client?: NaturalClient | SelfEmployedClient | LegalClient) => void;
  onPolicyHolderViolationErrorsDelete: () => void;
}

const YEAR_NUMBER_INPUT_MIN = 1900;
const YEAR_NUMBER_INPUT_MAX = dayjs().year();

const RealtyCalcGeneralSection = ({ form, policyHolder, ...props }: Props) => {
  const clientSearch = useClientSearch();

  const [clientFormOpen, setClientFormOpen] = useState<boolean>(false);
  const [policyHolderFormStage, setPolicyHolderFormStage] = useState<ClientFormStage | undefined>(
    policyHolder ? ClientFormStage.SELECTED : undefined
  );

  useEffect(() => {
    if (
      clientSearch.result.keyword &&
      clientSearch.result.keyword === form.getFieldValue(["clientsData", "policyHolderIdentifier"])
    ) {
      if (clientSearch.result.data) {
        setClientFormOpen(true);
        setPolicyHolderFormStage(ClientFormStage.EXISTING);
        if (
          clientSearch.result.data.type === ClientType.NATURAL ||
          clientSearch.result.data.type === ClientType.SELF_EMPLOYED
        ) {
          props.onPolicyHolderChange({
            ...clientSearch.result.data,
            previousIdentityCardNumber: (clientSearch.result.data as NaturalClient).identityCardNumber
          } as NaturalClient);
        } else {
          props.onPolicyHolderChange(clientSearch.result.data as LegalClient);
        }
      } else {
        setPolicyHolderFormStage(ClientFormStage.NEW);
      }
    }
  }, [clientSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clientSearch.onResultDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientSearchActionClick = (_: ClientFormType, actionType: ClientSearchActionType): void => {
    switch (actionType) {
      case ClientSearchActionType.CREATE:
      case ClientSearchActionType.UPDATE:
        setClientFormOpen(true);
        break;
      case ClientSearchActionType.DELETE:
        form.setFieldsValue({ clientsData: { policyHolderIdentifier: undefined } });
        setPolicyHolderFormStage(undefined);
        props.onPolicyHolderViolationErrorsDelete();
        props.onPolicyHolderChange(undefined);
        break;
    }
  };

  const handleClientSearchSubmit = (value: string): void => {
    form
      .validateFields([["clientsData", "policyHolderIdentifier"]])
      .then(() => clientSearch.onSearch({ keyword: value }))
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string): void => {
    if (policyHolderFormStage) {
      setPolicyHolderFormStage(undefined);
    }
    if (policyHolder) {
      props.onPolicyHolderChange(undefined);
    }
    if (props.clientsViolationErrors.has(ClientFormType.POLICY_HOLDER)) {
      props.onPolicyHolderViolationErrorsDelete();
    }
    handleClientSearchSubmit(value);
  };

  const handleClientFormSubmit = (client: NaturalClient | SelfEmployedClient | LegalClient): void => {
    setClientFormOpen(false);
    setPolicyHolderFormStage(ClientFormStage.SELECTED);
    props.onPolicyHolderViolationErrorsDelete();
    props.onPolicyHolderChange(client);
  };

  const getPolicyHolderFormIdentifier = (): string => {
    return form.getFieldValue(["clientsData", "policyHolderIdentifier"]);
  };

  const handleBuildingStateChange = (buildingState: BuildingState): void => {
    if (buildingState === BuildingState.UNDER_CONSTRUCTION) {
      form.setFieldsValue({
        generalBuildingData: { constructionYear: undefined, reconstructionYear: undefined },
        householdInsuranceEnabled: false
      });
    }
  };

  const handleConstructionYearChange = (constructionYear: string | number | null): void => {
    if (!constructionYear) {
      form.setFieldsValue({ generalBuildingData: { reconstructionYear: undefined } });
    }
  };

  const handleRentedChange = (event: CheckboxChangeEvent): void => {
    if (!event.target.checked) {
      form.setFieldsValue({ generalInsuranceData: { rentReinsurance: false } });
    }
  };

  const handleNumberOfDamagesInLastThreeYearsChange = (damagesInThreeYears: string | number | null): void => {
    const damagesInTenYears = form.getFieldValue(["generalBuildingData", "numberOfDamagesInLastTenYears"]);
    if (damagesInThreeYears) {
      form.setFieldValue(
        ["generalBuildingData", "numberOfDamagesInLastTenYears"],
        damagesInTenYears < damagesInThreeYears ? damagesInThreeYears : damagesInTenYears
      );
    }
  };

  const handleNumberOfDamagesInLastTenYearsChange = (damagesInTenYears: string | number | null): void => {
    const damagesInThreeYears = form.getFieldValue(["generalBuildingData", "numberOfDamagesInLastThreeYears"]);
    if (damagesInTenYears && damagesInTenYears < damagesInThreeYears) {
      form.setFieldValue(["generalBuildingData", "numberOfDamagesInLastThreeYears"], damagesInTenYears);
    }
  };

  const handlePermanentlyOccupiedChanged = (event: CheckboxChangeEvent): void => {
    if (!event.target.checked) {
      form.setFieldsValue({
        householdInsuranceData: {
          artworksInsuranceAmount: undefined,
          valuablesInsuranceAmount: undefined
        }
      });
    }
  };

  const colSpan = 4;

  return (
    <>
      <Card type="inner" className="card-box" title={t("calc.realty.sections.generalDataAndClient")}>
        <Row gutter={rowGutter}>
          <ClientSearchInput<ClientFormType>
            formItemProps={{
              name: ["clientsData", "policyHolderIdentifier"],
              label: t("calc.realty.attrs.clientsData.policyHolderIndexPinCrn"),
              rules: [validations.notBlank, validations.pinOrCrn, validations.adultByPin],
              validateFirst: true
            }}
            formStage={policyHolderFormStage}
            formType={ClientFormType.POLICY_HOLDER}
            processedType={ClientFormType.POLICY_HOLDER}
            client={policyHolder}
            violationErrors={props.clientsViolationErrors}
            inProgress={clientSearch.inProgress}
            inputColSpan={colSpan + 1}
            clientNameColSpan={colSpan + 3}
            onActionClick={handleClientSearchActionClick}
            onSearch={handleClientSearchSubmit}
            onChange={handleClientSearchChange}
          />
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name={["generalBuildingData", "state"]}
              label={t("calc.realty.enums.buildingState._label")}
              rules={[validations.notNull]}
            >
              <Select
                {...selectStandardProps}
                options={Object.keys(BuildingState).map(state => ({
                  value: state,
                  label: t("calc.realty.enums.buildingState." + state)
                }))}
                onChange={handleBuildingStateChange}
              />
            </Form.Item>
          </Col>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.generalBuildingData.state !== next.generalBuildingData.state ||
              prev.generalBuildingData.constructionYear !== next.generalBuildingData.constructionYear
            }
          >
            {({ getFieldValue }) => {
              const state = getFieldValue(["generalBuildingData", "state"]) as BuildingState;
              const constructionYear = getFieldValue(["generalBuildingData", "constructionYear"]);
              return (
                <>
                  <Col span={colSpan}>
                    <Form.Item
                      name={["generalBuildingData", "constructionYear"]}
                      label={
                        <LabelWithTooltip
                          label={t("calc.realty.attrs.generalBuildingData.constructionYear")}
                          tooltip={t("calc.realty.helpers.constructionYear")}
                        />
                      }
                      rules={[
                        state === BuildingState.FINISHED || state === BuildingState.FINISHED_UNDER_RECONSTRUCTION
                          ? validations.notNull
                          : validations.none,
                        validations.minNumber(YEAR_NUMBER_INPUT_MIN),
                        validations.maxNumber(YEAR_NUMBER_INPUT_MAX)
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        onChange={handleConstructionYearChange}
                        disabled={state === BuildingState.UNDER_CONSTRUCTION}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name={["generalBuildingData", "reconstructionYear"]}
                      label={
                        <LabelWithTooltip
                          label={t("calc.realty.attrs.generalBuildingData.reconstructionYear")}
                          tooltip={t("calc.realty.helpers.reconstructionYear")}
                        />
                      }
                      rules={[
                        validations.minNumber(constructionYear ? constructionYear + 1 : YEAR_NUMBER_INPUT_MIN),
                        validations.maxNumber(YEAR_NUMBER_INPUT_MAX)
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        disabled={state === BuildingState.UNDER_CONSTRUCTION || !constructionYear}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name={["generalBuildingData", "numberOfDamagesInLastThreeYears"]}
                      label={t("calc.realty.attrs.generalBuildingData.numberOfDamagesInLastThreeYears")}
                      rules={[validations.minNumber(0)]}
                    >
                      <InputNumber style={{ width: "100%" }} onChange={handleNumberOfDamagesInLastThreeYearsChange} />
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name={["generalBuildingData", "numberOfDamagesInLastTenYears"]}
                      label={t("calc.realty.attrs.generalBuildingData.numberOfDamagesInLastTenYears")}
                      rules={[validations.minNumber(0)]}
                    >
                      <InputNumber style={{ width: "100%" }} onChange={handleNumberOfDamagesInLastTenYearsChange} />
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name={["generalBuildingData", "numberOfFloodsInLastTenYears"]}
                      label={t("calc.realty.attrs.generalBuildingData.numberOfFloodsInLastTenYears")}
                      rules={[validations.minNumber(0)]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </>
              );
            }}
          </Form.Item>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name={["generalBuildingData", "areaType"]}
              label={
                <LabelWithPopover
                  label={t("calc.realty.attrs.generalBuildingData.areaTypeLabel")}
                  popoverTitle={t("calc.realty.helpers.areaTypeDesc.title")}
                  popoverContent={
                    <div style={{ maxWidth: 750 }}>
                      <div>
                        <h4>{t("calc.realty.helpers.areaTypeDesc.risk.title")}</h4>
                        <span>{t("calc.realty.helpers.areaTypeDesc.risk.text1")}</span>
                        <ul>
                          {[2, 3, 4, 5].map(value => (
                            <li key={value}>{t("calc.realty.helpers.areaTypeDesc.risk.text" + value)}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  }
                />
              }
              rules={[validations.none]}
            >
              <Select
                {...selectStandardProps}
                allowClear
                options={Object.keys(AreaType).map(type => ({
                  value: type,
                  label: t("calc.realty.enums.areaType." + type)
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={["generalBuildingData", "level"]}
              label={t("calc.realty.enums.buildingLevel._label")}
              rules={[validations.none]}
            >
              <Select
                {...selectStandardProps}
                allowClear
                options={Object.keys(BuildingLevel).map(level => ({
                  value: level,
                  label: t("calc.realty.enums.buildingLevel." + level)
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan * 2}>
            <Form.Item
              name={["generalBuildingData", "business"]}
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("calc.realty.attrs.generalBuildingData.business")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name={["generalBuildingData", "inCity"]}
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={true}
            >
              <Checkbox>{t("calc.realty.attrs.generalBuildingData.inCity")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={["generalBuildingData", "permanentlyOccupied"]}
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={true}
            >
              <Checkbox onChange={handlePermanentlyOccupiedChanged}>
                <LabelWithPopover
                  label={t("calc.realty.attrs.generalBuildingData.permanentlyOccupied")}
                  popoverTitle={t("calc.realty.helpers.permanentlyOccupiedDesc.title")}
                  popoverContent={
                    <table className="data-table-view wide-margins" style={{ maxWidth: 650 }}>
                      <tbody>
                        {[1, 2, 3, 4, 5, 6, 7, 8].map(value => (
                          <tr key={value}>
                            <td>{t("calc.realty.helpers.permanentlyOccupiedDesc.institution" + value)}</td>
                            <td>{t("calc.realty.helpers.permanentlyOccupiedDesc.value" + value)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  }
                />
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={["generalBuildingData", "rented"]}
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox onChange={handleRentedChange}>{t("calc.realty.attrs.generalBuildingData.rented")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={["generalBuildingData", "occupiedByDisabledPerson"]}
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox style={{ maxHeight: 22 }}>
                <LabelWithTooltip
                  label={t("calc.realty.attrs.generalBuildingData.occupiedByDisabledPerson")}
                  tooltip={t("calc.realty.helpers.occupiedByDisabledPerson")}
                />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.generalBuildingData.state !== next.generalBuildingData.state}
        >
          {({ getFieldValue }) => {
            const buildingState = getFieldValue(["generalBuildingData", "state"]) as BuildingState;
            return (
              <PlaceOfInsuranceForm
                form={form}
                rootNamePath={["generalBuildingData", "placeOfInsurance"]}
                label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance._label")}
                required
                buildingFinished={
                  !buildingState ||
                  buildingState === BuildingState.FINISHED ||
                  buildingState === BuildingState.FINISHED_UNDER_RECONSTRUCTION
                }
                allowOnlySvkCountry
              />
            );
          }}
        </Form.Item>
      </Card>

      <ClientDrawerForm<ClientFormType>
        open={clientFormOpen}
        client={policyHolder}
        initialIdentifier={getPolicyHolderFormIdentifier()}
        formType={ClientFormType.POLICY_HOLDER}
        violationErrors={props.clientsViolationErrors}
        onFormSubmit={handleClientFormSubmit}
      />
    </>
  );
};

export default RealtyCalcGeneralSection;
