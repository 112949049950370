import { Button, Checkbox, Col, Divider, Popconfirm, Row, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { VehicleMappingType } from "../enums";

interface Props {
  selectedMappingTypes: VehicleMappingType[];
  showDownloadButton: boolean;
  onSelectedMappingTypesChange: (checkedValues: any[]) => void;
  onDownloadMappingsClick: () => void;
  onRemapMappingsClick: () => void;
  onMapMappingsUpdatesClick: () => void;
  onImportMappingsClick: (file: RcFile) => void;
  onExportMappingsClick: () => void;
}

const VehicleSettingsHeader = (props: Props) => (
  <>
    <Divider orientation="left">{t("vehicle.titles.selectedInstitutions")}</Divider>

    <Row gutter={rowGutter}>
      <Col flex="550px">
        <Checkbox.Group onChange={props.onSelectedMappingTypesChange} defaultValue={props.selectedMappingTypes}>
          <Row gutter={rowGutter}>
            {Object.values(VehicleMappingType).map((type, index) => (
              <Col span={6} key={index}>
                <Checkbox value={type}>{t("vehicle.enums.vehicleMappingType." + type)}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Col>

      <Col flex="auto" style={{ display: "flex", justifyContent: "end" }}>
        <Row style={{ width: 380 }}>
          <Col span={14} className="right-align">
            <Popconfirm
              title={<p style={{ maxWidth: 350 }}>{t("vehicle.helpers.remapMappingsConfirm")}</p>}
              icon={<AntIcon type="question" />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="primary"
              onConfirm={props.onRemapMappingsClick}
            >
              <Button icon={<AntIcon type="cloud-upload" />} size="small" style={{ width: 190 }}>
                {t("vehicle.actions.remapMappings")}
              </Button>
            </Popconfirm>

            <Popconfirm
              title={<p style={{ maxWidth: 350 }}>{t("vehicle.helpers.mapMappingsUpdatesConfirm")}</p>}
              icon={<AntIcon type="question" />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="primary"
              onConfirm={props.onMapMappingsUpdatesClick}
            >
              <Button icon={<AntIcon type="swap" />} size="small" className="margin-top-tiny" style={{ width: 190 }}>
                {t("vehicle.actions.mapMappingsUpdates")}
              </Button>
            </Popconfirm>
          </Col>

          <Col span={10} className="right-align">
            <Button
              icon={<AntIcon type="download" />}
              size="small"
              style={{ width: 145 }}
              onClick={props.onExportMappingsClick}
            >
              {t("vehicle.actions.exportMappings")}
            </Button>

            <Upload beforeUpload={props.onImportMappingsClick} showUploadList={false} accept=".csv">
              <Button className="margin-top-tiny" icon={<AntIcon type="import" />} size="small" style={{ width: 145 }}>
                {t("vehicle.actions.importMappings")}
              </Button>
            </Upload>
          </Col>
        </Row>
      </Col>
    </Row>

    {props.showDownloadButton && (
      <Row gutter={rowGutter} className="margin-top-large">
        <Col span={24}>
          <Button type="primary" icon={<AntIcon type="reload" />} onClick={props.onDownloadMappingsClick}>
            {t("vehicle.actions.downloadMappings")}
          </Button>
        </Col>
      </Row>
    )}
  </>
);

export default VehicleSettingsHeader;
