import { ATTACHMENT_BOUNDARY_PATH_PREFIX } from "../../../common/constants";

export const COMMISSIONS_BATCH_ROUTE_PREFIX = "/commissions/batches";

export const COMMISSIONS_BATCH_ROUTE_PATHS = {
  list: {
    path: "*",
    to: COMMISSIONS_BATCH_ROUTE_PREFIX
  },
  detail: {
    path: ":id",
    to: `${COMMISSIONS_BATCH_ROUTE_PREFIX}/:id`
  },
  attachmentImports: {
    path: ":id1/attachments/:id2/imports",
    to: `${COMMISSIONS_BATCH_ROUTE_PREFIX}/:id1/attachments/:id2/imports`
  },
  manualImports: {
    path: ":id1/institutions/:id2/manual-imports",
    to: `${COMMISSIONS_BATCH_ROUTE_PREFIX}/:id1/institutions/:id2/manual-imports`
  },
  postponedCommissions: {
    path: ":id/postponed-commissions",
    to: `${COMMISSIONS_BATCH_ROUTE_PREFIX}/:id/postponed-commissions`
  },
  specialCommissions: {
    path: ":id1/agents/:id2/special-commissions",
    to: `${COMMISSIONS_BATCH_ROUTE_PREFIX}/:id1/agents/:id2/special-commissions`
  },
  manualBailAccountMovements: {
    path: ":id/manual-bail-account-movements",
    to: `${COMMISSIONS_BATCH_ROUTE_PREFIX}/:id/manual-bail-account-movements`
  },
  attachment: {
    to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${COMMISSIONS_BATCH_ROUTE_PREFIX}/:id1/attachments/:id2`
  }
};
