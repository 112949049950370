import { ATTACHMENT_BOUNDARY_PATH_PREFIX } from "../../common/constants";
import { CONTRACT_ROUTE_PATHS } from "../contract/paths";

export const FINANCIAL_MEDIATION_ROUTE_PREFIX = CONTRACT_ROUTE_PATHS.forms.to + "/financial-mediations";

export const FINANCIAL_MEDIATION_ROUTE_PATHS = {
  detail: { path: ":id", to: `${FINANCIAL_MEDIATION_ROUTE_PREFIX}/:id` },
  file: { to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${FINANCIAL_MEDIATION_ROUTE_PREFIX}/:id` },
  create: { path: "new", to: `${FINANCIAL_MEDIATION_ROUTE_PREFIX}/new` }
};
