import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { validationConstants, validations } from "../../../../common/utils/validationUtils";
import LifeInsuranceTariffGroupSelect from "../../../enumerations/components/form/LifeInsuranceTariffGroupSelect";
import { LifeInsuranceTariffFilterPageRequest, LifeInsuranceTariffFilterPageResult } from "../../types";

interface Props {
  currentFilter: LifeInsuranceTariffFilterPageResult;
  onFilterSubmit: (filter: LifeInsuranceTariffFilterPageRequest) => void;
  onCreateClick: () => void;
}

const LifeInsuranceTariffFilterView = (props: Props) => {
  const [form] = Form.useForm<LifeInsuranceTariffFilterPageRequest>();

  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: props.currentFilter.keyword,
      groupIds: props.currentFilter.groupIds || []
    });
  }, [props.currentFilter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("lifeInsuranceTariff.titles.tariffsAdmin")}</h2>}
      extra={
        <Button type="primary" icon={<AntIcon type="plus" />} onClick={props.onCreateClick}>
          {t("lifeInsuranceTariff.actions.createTariff")}
        </Button>
      }
    >
      <Form form={form} onFinish={props.onFilterSubmit} layout="vertical" name="lifeInsuranceTariffSearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.search")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("lifeInsuranceTariff.helpers.tariffSearchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <LifeInsuranceTariffGroupSelect
              formItemProps={{
                name: "groupIds",
                label: t("lifeInsuranceTariff.attrs.groupId"),
                rules: [validations.none]
              }}
              selectProps={{
                mode: "multiple",
                maxTagCount: "responsive",
                allowClear: true
              }}
            />
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default LifeInsuranceTariffFilterView;
