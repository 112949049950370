import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { EntityIdObject, EntityObject, TwoLevelEntityIdObject } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  AgentCompetenceIntervalDashboard,
  ContractStatisticsDashboard,
  ContractStatusesDashboard,
  CreateUpdateDashboardContact,
  CreateUpdateDashboardNotice,
  DashboardContact,
  DashboardContactSearchRequest,
  DashboardContactSearchResult,
  DashboardMinMaxDatesRequest,
  DashboardNotice,
  DashboardNoticeAttachment,
  DashboardNoticeFilterPageRequest,
  DashboardNoticeFilterPageResult,
  DashboardStatistics,
  PersonalDatesDashboard
} from "./types";

const GET_DASHBOARD_STATISTICS = ApiRequestAdapter.create("/dashboard/statistics", "GET");
const GET_DASHBOARD_PERSONAL_DATES = ApiRequestAdapter.create("/dashboard/personal-dates", "GET");
const GET_DASHBOARD_AGENT_COMPETENCE = ApiRequestAdapter.create("/dashboard/agent-competences", "GET");
const GET_DASHBOARD_CONTRACT_STATUSES = ApiRequestAdapter.create("/dashboard/contract-statuses", "GET");
const GET_DASHBOARD_CONTRACT_STATISTICS = ApiRequestAdapter.create("/dashboard/contract-statistics", "GET");

const FILTER_DASHBOARD_NOTICES = ApiRequestAdapter.create("/dashboard/notices", "GET");
const CREATE_DASHBOARD_NOTICE = ApiRequestAdapter.create("/dashboard/notices", "POST");
const UPDATE_DASHBOARD_NOTICE = ApiRequestAdapter.create("/dashboard/notices/{0}", "PUT");
const DELETE_DASHBOARD_NOTICE = ApiRequestAdapter.create("/dashboard/notices/{0}", "DELETE");
const ADD_DASHBOARD_NOTICE_ATACHMENTS = ApiRequestAdapter.create("/dashboard/notices/{0}/attachments", "GET");
const DOWNLOAD_DASHBOARD_NOTICE_ATACHMENT = ApiRequestAdapter.create("/dashboard/notices/{0}/attachments/{1}", "GET");
const DELETE_DASHBOARD_NOTICE_ATACHMENT = ApiRequestAdapter.create("/dashboard/notices/{0}/attachments/{1}", "DELETE");

const SEARCH_DASHBOARD_CONTACTS = ApiRequestAdapter.create("/dashboard/contacts", "GET");
const CREATE_DASHBOARD_CONTACT = ApiRequestAdapter.create("/dashboard/contacts", "POST");
const UPDATE_DASHBOARD_CONTACT = ApiRequestAdapter.create("/dashboard/contacts/{0}", "PUT");
const DELETE_DASHBOARD_CONTACT = ApiRequestAdapter.create("/dashboard/contacts/{0}", "DELETE");

export const requests = {
  GET_DASHBOARD_STATISTICS,
  GET_DASHBOARD_PERSONAL_DATES,
  GET_DASHBOARD_AGENT_COMPETENCE,
  GET_DASHBOARD_CONTRACT_STATUSES,
  GET_DASHBOARD_CONTRACT_STATISTICS,
  FILTER_DASHBOARD_NOTICES,
  CREATE_DASHBOARD_NOTICE,
  UPDATE_DASHBOARD_NOTICE,
  DELETE_DASHBOARD_NOTICE,
  ADD_DASHBOARD_NOTICE_ATACHMENTS,
  DOWNLOAD_DASHBOARD_NOTICE_ATACHMENT,
  DELETE_DASHBOARD_NOTICE_ATACHMENT,
  SEARCH_DASHBOARD_CONTACTS,
  CREATE_DASHBOARD_CONTACT,
  UPDATE_DASHBOARD_CONTACT,
  DELETE_DASHBOARD_CONTACT
};

const api = {
  getDashboardStatistics: (): AxiosPromise<DashboardStatistics> => {
    return apiService.get(GET_DASHBOARD_STATISTICS.url);
  },
  getDashboardPersonalDates: (request: DashboardMinMaxDatesRequest): AxiosPromise<PersonalDatesDashboard> => {
    return apiService.get(GET_DASHBOARD_PERSONAL_DATES.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  getDashboardAgentCompetences: (): AxiosPromise<AgentCompetenceIntervalDashboard[]> => {
    return apiService.get(GET_DASHBOARD_AGENT_COMPETENCE.url);
  },
  getDashboardContractStatuses: (): AxiosPromise<ContractStatusesDashboard> => {
    return apiService.get(GET_DASHBOARD_CONTRACT_STATUSES.url);
  },
  getDashboardContractStatistics: (request: DashboardMinMaxDatesRequest): AxiosPromise<ContractStatisticsDashboard> => {
    return apiService.get(GET_DASHBOARD_CONTRACT_STATISTICS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  filterDashboardNotices: (
    request: DashboardNoticeFilterPageRequest
  ): AxiosPromise<DashboardNoticeFilterPageResult> => {
    return apiService.get(FILTER_DASHBOARD_NOTICES.url, { params: request, paramsSerializer: serializeParams });
  },
  createDashboardNotice: (request: CreateUpdateDashboardNotice): AxiosPromise<DashboardNotice> => {
    return apiService.post(CREATE_DASHBOARD_NOTICE.url, request);
  },
  updateDashboardNotice: (request: EntityObject<CreateUpdateDashboardNotice>): AxiosPromise<DashboardNotice> => {
    return apiService.put(UPDATE_DASHBOARD_NOTICE.getParametrizedUrl(request.id), request.object);
  },
  deleteDashboardNotice: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_DASHBOARD_NOTICE.getParametrizedUrl(request.id));
  },
  addDashboardNoticeAttachments: (request: EntityObject<FormData>): AxiosPromise<DashboardNoticeAttachment[]> => {
    return apiService.post(ADD_DASHBOARD_NOTICE_ATACHMENTS.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  downloadDashboardNoticeAttachment: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_DASHBOARD_NOTICE_ATACHMENT.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  },
  deleteDashboardNoticeAttachment: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_DASHBOARD_NOTICE_ATACHMENT.getParametrizedUrl(request.id1, request.id2));
  },
  searchDashboardContacts: (request: DashboardContactSearchRequest): AxiosPromise<DashboardContactSearchResult> => {
    return apiService.get(SEARCH_DASHBOARD_CONTACTS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createDashboardContact: (request: CreateUpdateDashboardContact): AxiosPromise<DashboardContact> => {
    return apiService.post(CREATE_DASHBOARD_CONTACT.url, request);
  },
  updateDashboardContact: (request: EntityObject<CreateUpdateDashboardContact>): AxiosPromise<DashboardContact> => {
    return apiService.put(UPDATE_DASHBOARD_CONTACT.getParametrizedUrl(request.id), request.object);
  },
  deleteDashboardContact: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_DASHBOARD_CONTACT.getParametrizedUrl(request.id));
  }
};

export default api;
