import { DatePicker, Form } from "antd";
import { NamePath } from "rc-field-form/lib/interface";
import { datePickerFormItemProps } from "../../../utils/formUtils";
import { validations } from "../../../utils/validationUtils";

interface Props {
  name: NamePath;
  initialValue?: string;
}

const HiddenDatePicker = ({ name, initialValue }: Props) => (
  <Form.Item
    name={name}
    rules={[validations.none]}
    initialValue={initialValue}
    hidden
    noStyle
    {...datePickerFormItemProps}
  >
    <DatePicker style={{ visibility: "hidden" }} />
  </Form.Item>
);

export default HiddenDatePicker;
