import dayjs, { Dayjs } from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import { contains, isNotEmptyArray } from "../../../../common/utils/utils";
import { ClientType } from "../../../client/enums";
import { Client, CreateUpdateContractClient, NaturalClient } from "../../../client/types";
import { createUpdateContractClientToClient } from "../../../client/utils";
import { VehicleCategory } from "../../../contract/enums";
import { CalcType } from "../../enums";
import { OfferType } from "../enums";
import { CalcResponse, CalcResult } from "../types";
import {
  sortResultGroupsByAnnualPremium,
  sortResultGroupsByInstitutionName,
  sortResultsWithinGroupsByCoverageIndex
} from "../utils";
import { GapCoverageLimit, HolderAccidentsYearsKey, TimeWithoutAccident, VehiclePolicyHolderRelation } from "./enums";
import {
  CrashCalcResultData,
  GapCalcResultData,
  MtplCalcResultData,
  MtplCrashCalcResultData,
  PasCalcResultData,
  VehicleCalc,
  VehicleCalcClientsData,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleCalcType,
  VehicleFormClients,
  VehicleGen,
  VehicleGenForm,
  VehicleOfferType,
  VehicleSecurityInfo
} from "./types";

export const resolveVehiclePolicyHolder = (
  clients: VehicleFormClients,
  policyHolderRelation: VehiclePolicyHolderRelation
): Client | undefined => {
  return policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER
    ? clients.policyHolder
    : clients.holder;
};

export const resolveVehiclePolicyHolderType = (
  clients: VehicleFormClients,
  policyHolderRelation: VehiclePolicyHolderRelation
): ClientType | undefined => {
  return resolveVehiclePolicyHolder(clients, policyHolderRelation)?.type;
};

export const resolveVehicleOfferType = (calcType: VehicleCalcType): VehicleOfferType | undefined => {
  switch (calcType) {
    case CalcType.MTPL:
      return OfferType.MTPL;
    case CalcType.CRASH:
      return OfferType.CRASH;
    case CalcType.MTPL_CRASH:
      return OfferType.MTPL_CRASH;
    default:
      return undefined;
  }
};

export const sortAndGroupVehicleCalcResults = (
  calcResponse: CalcResponse<VehicleCalcResultData>
): VehicleCalcResults => {
  return {
    mtpl: sortResults<MtplCalcResultData>(
      calcResponse.results.filter(result => result.calcType === CalcType.MTPL) as CalcResult<MtplCalcResultData>[]
    ),
    crash: sortResults<CrashCalcResultData>(
      calcResponse.results.filter(result => result.calcType === CalcType.CRASH) as CalcResult<CrashCalcResultData>[]
    ),
    mtplCrash: sortResults<MtplCrashCalcResultData>(
      calcResponse.results.filter(
        result => result.calcType === CalcType.MTPL_CRASH
      ) as CalcResult<MtplCrashCalcResultData>[]
    ),
    gap: sortResults<GapCalcResultData>(
      calcResponse.results.filter(result => result.calcType === CalcType.GAP) as CalcResult<GapCalcResultData>[]
    ),
    pas: sortResults<PasCalcResultData>(
      calcResponse.results.filter(result => result.calcType === CalcType.PAS) as CalcResult<PasCalcResultData>[]
    )
  };
};

const sortResults = <T extends VehicleCalcResultData>(results: CalcResult<T>[]): CalcResult<T>[][] => {
  const resultGroups: CalcResult<T>[][] = [];

  cloneDeep(results).forEach(result => {
    const index = resultGroups.findIndex(items => items[0]?.insuranceInstitution.id === result.insuranceInstitution.id);
    if (index === -1) {
      resultGroups.push([result as CalcResult<T>]);
    } else {
      resultGroups[index]?.push(result as CalcResult<T>);
    }
  });

  let successResultGroups: CalcResult<T>[][] = [];
  let notRecommendedGroups: CalcResult<T>[][] = [];
  let errorResultGroups: CalcResult<T>[][] = [];

  resultGroups.forEach(results => {
    if (results.some(result => result.error)) {
      errorResultGroups.push(results);
    } else if (results.some(result => result.data.missingCoverages && result.data.missingCoverages.length > 0)) {
      notRecommendedGroups.push(results);
    } else {
      successResultGroups.push(results);
    }
  });

  successResultGroups = sortResultsWithinGroupsByCoverageIndex(successResultGroups);
  notRecommendedGroups = sortResultsWithinGroupsByCoverageIndex(notRecommendedGroups);
  errorResultGroups = sortResultsWithinGroupsByCoverageIndex(errorResultGroups);

  successResultGroups = sortResultGroupsByAnnualPremium(successResultGroups);
  notRecommendedGroups = sortResultGroupsByAnnualPremium(notRecommendedGroups);

  errorResultGroups = sortResultGroupsByInstitutionName(errorResultGroups);

  return [...successResultGroups, ...notRecommendedGroups, ...errorResultGroups];
};

export const hasAnyVehicleResult = (results: VehicleCalcResults): boolean => {
  return (
    isNotEmptyArray(results.mtpl) ||
    isNotEmptyArray(results.crash) ||
    isNotEmptyArray(results.mtplCrash) ||
    isNotEmptyArray(results.gap) ||
    isNotEmptyArray(results.pas)
  );
};

export const getVehicleCalcResultsByType = (
  calcResults: VehicleCalcResults,
  calcType: CalcType
): CalcResult<VehicleCalcResultData>[][] => {
  switch (calcType) {
    case CalcType.MTPL:
      return calcResults.mtpl;
    case CalcType.CRASH:
      return calcResults.crash;
    case CalcType.MTPL_CRASH:
      return calcResults.mtplCrash;
    case CalcType.GAP:
      return calcResults.gap;
    case CalcType.PAS:
      return calcResults.pas;
    default:
      return [];
  }
};

type HolderAccidentsYearsKeyType = keyof Pick<
  VehicleCalcClientsData,
  | "holderAccidentsIn2Years"
  | "holderAccidentsIn3Years"
  | "holderAccidentsIn5Years"
  | "holderAccidentsIn8Years"
  | "holderAccidentsIn10Years"
  | "holderAccidentsInAllYears"
>;

export const createVehicleCalcObjectFromCalcData = (calcData: VehicleCalc): VehicleCalc => {
  const data = cloneDeep(calcData);

  Object.values(HolderAccidentsYearsKey).forEach(years => {
    if (
      !contains(
        YEARS_TO_ACCIDENT_TIMES_MAP.get(years as HolderAccidentsYearsKey) as TimeWithoutAccident[],
        data.clientsData.holderTimeWithoutAccident
      )
    ) {
      delete data.clientsData[`holderAccidentsIn${years}Years` as HolderAccidentsYearsKeyType];
    }
  });

  return data;
};

export const createVehicleFormClientsObjectFromCalcData = (calcData: VehicleCalc): VehicleFormClients => ({
  holder: createUpdateContractClientToClient(
    calcData.clientsData.clients[calcData.clientsData.holderIndex] as CreateUpdateContractClient
  ),
  policyHolder: undefined,
  owner: undefined,
  representative: undefined
});

export const createVehicleFormClientsObjectFromGenData = (genData: VehicleGen): VehicleFormClients => {
  const { clients, holderIndex, policyHolderIndex, ownerIndex, representativeIndex } = genData.clientsData;
  return {
    holder: createUpdateContractClientToClient(clients[holderIndex] as CreateUpdateContractClient),
    policyHolder:
      policyHolderIndex !== holderIndex
        ? createUpdateContractClientToClient(clients[policyHolderIndex] as CreateUpdateContractClient)
        : undefined,
    owner:
      ownerIndex !== holderIndex && ownerIndex !== policyHolderIndex
        ? createUpdateContractClientToClient(clients[ownerIndex] as CreateUpdateContractClient)
        : undefined,
    representative: representativeIndex
      ? createUpdateContractClientToClient(clients[representativeIndex] as CreateUpdateContractClient)
      : undefined
  };
};

export const createVehicleCalcObjectFromGenData = (genData: VehicleGen): VehicleCalc => {
  const data: Record<string, any> = cloneDeep(genData);

  data.type = data.type === CalcType.GAP || data.type === CalcType.PAS ? CalcType.MTPL_CRASH : data.type;

  delete data.insuranceInstitutionId;
  delete data.coverage;
  delete data.calcResult;
  delete data.calcResponse;
  delete data.draftId;

  delete data.vehicleData.damaged;
  delete data.vehicleData.damageDescription;
  delete data.vehicleData.allKeysAvailable;
  delete data.vehicleData.allServiceInspectionsPassed;
  delete data.vehicleData.priceSource;
  delete data.vehicleData.invoiceIssueDate;
  delete data.vehicleData.slovexpertaIssuerName;
  delete data.vehicleData.slovexpertaIssueDate;
  delete data.vehicleData.key;
  delete data.vehicleData.equipment;
  delete data.vehicleData.odometerPerYear;
  delete data.vehicleData.purchaseDate;
  if (data.vehicleData.security) {
    data.vehicleData.security.forEach((security: VehicleSecurityInfo) => delete security.numberOfKeysOrControllers);
  }

  delete data.clientsData.policyHolderIndex;
  delete data.clientsData.ownerIndex;
  delete data.clientsData.representativeIndex;
  delete data.clientsData.policyHolderEmail;
  delete data.clientsData.policyHolderPhone;
  delete data.clientsData.policyHolderMarketingConsent;
  delete data.clientsData.representativeFunction;

  delete data.generalData.signCity;
  delete data.generalData.loanNumber;
  delete data.generalData.mtplInsuranceEffectiveBeginningDate;
  delete data.generalData.childrenUnder15InVehicle;
  delete data.generalData.agentIsClosePersonToClient;
  delete data.generalData.gapComplicityReinsurance;
  delete data.generalData.gapDuration;
  delete data.generalData.gapPaymentFrequency;
  delete data.generalData.crossSelling.unionHealthContractNumber;
  delete data.financialMediationData;

  if (data.reinsurancesData) {
    delete data.reinsurancesData.uniqaSidecarMtplData;
  }

  return createVehicleCalcObjectFromCalcData(data as VehicleCalc);
};

export const createVehicleGenFormDataObject = (genData: VehicleGen, clients: VehicleFormClients): VehicleGenForm => {
  const { vehicleData, clientsData, generalData, reinsurancesData, financialMediationData } = genData;
  return {
    vehicleData: {
      registrationCertificatePresent: !!vehicleData.registrationCertificatePresent,
      registrationCertificateNumber: vehicleData.registrationCertificateNumber,
      colorId: vehicleData.colorId,
      damaged: !!vehicleData.damaged,
      damageDescription: vehicleData.damageDescription,
      allKeysAvailable: !!vehicleData.allKeysAvailable,
      allServiceInspectionsPassed: !!vehicleData.allServiceInspectionsPassed,
      invoiceIssueDate: vehicleData.invoiceIssueDate,
      slovexpertaIssuerName: vehicleData.slovexpertaIssuerName,
      slovexpertaIssueDate: vehicleData.slovexpertaIssueDate,
      priceSource: vehicleData.priceSource,
      key: vehicleData.key,
      equipment: vehicleData.equipment,
      security: vehicleData.security,
      odometer: vehicleData.odometer,
      odometerPerYear: vehicleData.odometerPerYear,
      purchaseDate: vehicleData.purchaseDate
    },
    clientsData: {
      policyHolderIdentifier: clients.policyHolder?.identifier,
      ownerIdentifier: clients.owner?.identifier,
      representativeIdentifier: clients.representative?.identifier,
      holderIdentityCardNumber: (clients.holder as NaturalClient)?.identityCardNumber,
      policyHolderEmail: clientsData.policyHolderEmail,
      policyHolderPhone: clientsData.policyHolderPhone,
      policyHolderMarketingConsent: clientsData.policyHolderMarketingConsent,
      representativeFunction: clientsData.representativeFunction
    },
    generalData: {
      signCity: generalData.signCity,
      loanNumber: generalData.loanNumber,
      mtplInsuranceEffectiveBeginningDate: generalData.mtplInsuranceEffectiveBeginningDate,
      childrenUnder15InVehicle: !!generalData.childrenUnder15InVehicle,
      agentIsClosePersonToClient: !!generalData.agentIsClosePersonToClient,
      gapComplicityReinsurance: generalData.gapComplicityReinsurance,
      gapPaymentFrequency: generalData.gapPaymentFrequency,
      crossSelling: {
        unionHealthContractNumber: generalData.crossSelling.unionHealthContract
          ? generalData.crossSelling.unionHealthContractNumber
          : undefined
      }
    },
    reinsurancesData: {
      uniqaSidecarMtplData: reinsurancesData?.uniqaSidecarMtplData
    },
    financialMediationData
  };
};

export const resolveGapCoverageLimitOptions = (holderRegistrationDate?: Dayjs): GapCoverageLimit[] => {
  if (holderRegistrationDate) {
    return holderRegistrationDate.isBefore(dayjs().subtract(120, "day"))
      ? [GapCoverageLimit.LIMIT_5000, GapCoverageLimit.LIMIT_10000, GapCoverageLimit.LIMIT_20000]
      : [GapCoverageLimit.LIMIT_20000, GapCoverageLimit.LIMIT_35000, GapCoverageLimit.LIMIT_50000];
  }

  return Object.values(GapCoverageLimit);
};

export const CALC_OMITTED_CATEGORIES = [
  VehicleCategory.MOTOR_SLEDGE_LS,
  VehicleCategory.TRACTOR_C,
  VehicleCategory.MACHINE_P,
  VehicleCategory.BUS_CITY_M
];

export const YEARS_TO_ACCIDENT_TIMES_MAP = new Map<HolderAccidentsYearsKey, TimeWithoutAccident[]>([
  [HolderAccidentsYearsKey.TWO, [TimeWithoutAccident.LESS_THAN_1_YEAR, TimeWithoutAccident.AT_LEAST_1_YEAR]],
  [
    HolderAccidentsYearsKey.THREE,
    [TimeWithoutAccident.LESS_THAN_1_YEAR, TimeWithoutAccident.AT_LEAST_1_YEAR, TimeWithoutAccident.AT_LEAST_2_YEARS]
  ],
  [
    HolderAccidentsYearsKey.FIVE,
    [
      TimeWithoutAccident.LESS_THAN_1_YEAR,
      TimeWithoutAccident.AT_LEAST_1_YEAR,
      TimeWithoutAccident.AT_LEAST_2_YEARS,
      TimeWithoutAccident.AT_LEAST_3_YEARS,
      TimeWithoutAccident.AT_LEAST_4_YEARS
    ]
  ],
  [
    HolderAccidentsYearsKey.EIGHT,
    [
      TimeWithoutAccident.LESS_THAN_1_YEAR,
      TimeWithoutAccident.AT_LEAST_1_YEAR,
      TimeWithoutAccident.AT_LEAST_2_YEARS,
      TimeWithoutAccident.AT_LEAST_3_YEARS,
      TimeWithoutAccident.AT_LEAST_4_YEARS,
      TimeWithoutAccident.AT_LEAST_5_YEARS,
      TimeWithoutAccident.AT_LEAST_6_YEARS,
      TimeWithoutAccident.AT_LEAST_7_YEARS
    ]
  ],
  [
    HolderAccidentsYearsKey.TEN,
    [
      TimeWithoutAccident.LESS_THAN_1_YEAR,
      TimeWithoutAccident.AT_LEAST_1_YEAR,
      TimeWithoutAccident.AT_LEAST_2_YEARS,
      TimeWithoutAccident.AT_LEAST_3_YEARS,
      TimeWithoutAccident.AT_LEAST_4_YEARS,
      TimeWithoutAccident.AT_LEAST_5_YEARS,
      TimeWithoutAccident.AT_LEAST_6_YEARS,
      TimeWithoutAccident.AT_LEAST_7_YEARS,
      TimeWithoutAccident.AT_LEAST_8_YEARS,
      TimeWithoutAccident.AT_LEAST_9_YEARS
    ]
  ],
  [
    HolderAccidentsYearsKey.ALL,
    [
      TimeWithoutAccident.LESS_THAN_1_YEAR,
      TimeWithoutAccident.AT_LEAST_1_YEAR,
      TimeWithoutAccident.AT_LEAST_2_YEARS,
      TimeWithoutAccident.AT_LEAST_3_YEARS,
      TimeWithoutAccident.AT_LEAST_4_YEARS,
      TimeWithoutAccident.AT_LEAST_5_YEARS,
      TimeWithoutAccident.AT_LEAST_6_YEARS,
      TimeWithoutAccident.AT_LEAST_7_YEARS,
      TimeWithoutAccident.AT_LEAST_8_YEARS,
      TimeWithoutAccident.AT_LEAST_9_YEARS
    ]
  ]
]);

export const M1_N1 = [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1];

export const SELF_MOVING = [
  VehicleCategory.PERSONAL_M1,
  VehicleCategory.UTILITY_N1,
  VehicleCategory.MOTORCYCLE_L,
  VehicleCategory.TRICYCLE_L,
  VehicleCategory.QUAD_BIKE_L,
  VehicleCategory.TRACTOR_T,
  VehicleCategory.TRACTOR_C,
  VehicleCategory.MACHINE_P,
  VehicleCategory.FORKLIFT_P,
  VehicleCategory.LORRY_N,
  VehicleCategory.SEMI_N3,
  VehicleCategory.BUS_CITY_M,
  VehicleCategory.BUS_OTHER_M,
  VehicleCategory.RV
];

export const SPECS_MAP = new Map<string, VehicleCategory[]>([
  ["fuelType", SELF_MOVING],
  ["transmission", M1_N1],
  ["bodywork", M1_N1],
  ["seatsNumber", SELF_MOVING],
  [
    "doorsNumber",
    [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.BUS_CITY_M, VehicleCategory.BUS_OTHER_M]
  ],
  ["engineDisplacement", SELF_MOVING],
  ["enginePower", SELF_MOVING],
  ["odometer", SELF_MOVING],
  [
    "steeringWheelOnTheRight",
    [
      VehicleCategory.PERSONAL_M1,
      VehicleCategory.UTILITY_N1,
      VehicleCategory.TRACTOR_T,
      VehicleCategory.TRACTOR_C,
      VehicleCategory.MACHINE_P,
      VehicleCategory.FORKLIFT_P,
      VehicleCategory.LORRY_N,
      VehicleCategory.SEMI_N3,
      VehicleCategory.BUS_CITY_M,
      VehicleCategory.BUS_OTHER_M,
      VehicleCategory.RV
    ]
  ],
  ["emergencyBrakingSystem", M1_N1],
  ["parkingAssistant", M1_N1]
]);

export const REINSURANCES_MAP = new Map<string, VehicleCategory[]>([
  [
    "extendedAssistance",
    [
      VehicleCategory.PERSONAL_M1,
      VehicleCategory.UTILITY_N1,
      VehicleCategory.MOTORCYCLE_L,
      VehicleCategory.TRICYCLE_L,
      VehicleCategory.QUAD_BIKE_L
    ]
  ],
  [
    "glass",
    [
      VehicleCategory.PERSONAL_M1,
      VehicleCategory.UTILITY_N1,
      VehicleCategory.TRACTOR_T,
      VehicleCategory.TRACTOR_C,
      VehicleCategory.MACHINE_P,
      VehicleCategory.FORKLIFT_P,
      VehicleCategory.LORRY_N,
      VehicleCategory.SEMI_N3,
      VehicleCategory.BUS_CITY_M,
      VehicleCategory.BUS_OTHER_M,
      VehicleCategory.RV
    ]
  ],
  ["animal", M1_N1],
  [
    "element",
    [
      VehicleCategory.PERSONAL_M1,
      VehicleCategory.UTILITY_N1,
      VehicleCategory.MOTORCYCLE_L,
      VehicleCategory.TRICYCLE_L,
      VehicleCategory.QUAD_BIKE_L
    ]
  ],
  ["theftAndVandalism", M1_N1],
  [
    "injury",
    [
      VehicleCategory.PERSONAL_M1,
      VehicleCategory.UTILITY_N1,
      VehicleCategory.MOTORCYCLE_L,
      VehicleCategory.TRICYCLE_L,
      VehicleCategory.QUAD_BIKE_L
    ]
  ],
  ["gap", M1_N1],
  ["replacementVehicle", M1_N1],
  ["generaliAbroadVehicleRepair", M1_N1],
  ["koopExtendedWarranty", M1_N1],
  ["uniqaSmallDamage", [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.RV]],
  ["uniqaTotalDamage", [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.RV]],
  ["uniqaLawyerAssistance", M1_N1],
  ["uniqaSidecarMtpl", M1_N1],
  ["uniqaChildrenInjury", M1_N1],
  ["uniqaUnlimitedTow", M1_N1]
]);
