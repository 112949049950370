import { Tooltip } from "antd";
import AntIcon from "../../icons/AntIcon";

interface Props {
  label?: string;
  tooltip: string;
  className?: string;
}

const LabelWithTooltip = ({ label, tooltip, className }: Props) => (
  <span style={{ width: "100%" }} className={className}>
    {label && <span>{label}&nbsp;</span>}
    <Tooltip title={tooltip}>
      <span>
        <AntIcon type="question" className="cursor-help" />
      </span>
    </Tooltip>
  </span>
);

export default LabelWithTooltip;
