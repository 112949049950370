import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";
import { combineReducers } from "redux";
import { push, replace } from "redux-first-history";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import {
  EntityIdObject,
  EntityObject,
  RootState,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../../common/types";
import { initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { openBlobFile, removeFromArray, replaceInArray } from "../../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../../ducks";
import { getEnumerationsActions } from "../../enumerations/ducks";
import { BailAccountSettings, CreateUpdateBailAccountSettings } from "../bailaccount/types";
import { sortCommissionsSettingsRules } from "../settings/utils";
import api from "./api";
import { COMMISSIONS_LEVEL_ROUTE_PATHS } from "./paths";
import {
  CommissionsSettingsLevel,
  CommissionsSettingsLevelAttachment,
  CommissionsSettingsLevelBase,
  CommissionsSettingsLevelFilterPageRequest,
  CommissionsSettingsLevelFilterPageResult,
  CommissionsSettingsLevelReducerState,
  CommissionsSettingsLevelRule,
  CreateUpdateCommissionsSettingsLevel,
  CreateUpdateCommissionsSettingsLevelRule
} from "./types";

/**
 * ACTIONS - LEVELS
 */
export const filterCommissionsLevelsActions = createAsyncAction(
  "commissions-level/FILTER_REQUEST",
  "commissions-level/FILTER_SUCCESS",
  "commissions-level/FILTER_FAILURE"
)<CommissionsSettingsLevelFilterPageRequest, CommissionsSettingsLevelFilterPageResult, void>();

export const getCommissionsLevelActions = createAsyncAction(
  "commissions-level/GET_REQUEST",
  "commissions-level/GET_SUCCESS",
  "commissions-level/GET_FAILURE"
)<EntityIdObject, CommissionsSettingsLevel, void>();

export const createCommissionsLevelActions = createAsyncAction(
  "commissions-level/CREATE_REQUEST",
  "commissions-level/CREATE_SUCCESS",
  "commissions-level/CREATE_FAILURE"
)<CreateUpdateCommissionsSettingsLevel, CommissionsSettingsLevel, void>();

export const updateCommissionsLevelActions = createAsyncAction(
  "commissions-level/UPDATE_REQUEST",
  "commissions-level/UPDATE_SUCCESS",
  "commissions-level/UPDATE_FAILURE"
)<EntityObject<CreateUpdateCommissionsSettingsLevel>, CommissionsSettingsLevel, void>();

export const deleteCommissionsLevelActions = createAsyncAction(
  "commissions-level/DELETE_REQUEST",
  "commissions-level/DELETE_SUCCESS",
  "commissions-level/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateCommissionsLevelsPageAction = createAction("commissions-level/DELETE_STATE_LIST")<void>();

export const deleteStateCommissionsLevelDetailAction = createAction("commissions-level/DELETE_STATE_DETAIL")<void>();

/**
 * ACTIONS - LEVEL RULES
 */
export const createCommissionsLevelRuleActions = createAsyncAction(
  "commissions-level-rule/CREATE_REQUEST",
  "commissions-level-rule/CREATE_SUCCESS",
  "commissions-level-rule/CREATE_FAILURE"
)<EntityObject<CreateUpdateCommissionsSettingsLevelRule>, CommissionsSettingsLevelRule, void>();

export const updateCommissionsLevelRuleActions = createAsyncAction(
  "commissions-level-rule/UPDATE_REQUEST",
  "commissions-level-rule/UPDATE_SUCCESS",
  "commissions-level-rule/UPDATE_FAILURE"
)<TwoLevelEntityObject<CreateUpdateCommissionsSettingsLevelRule>, CommissionsSettingsLevelRule, void>();

export const deleteCommissionsLevelRuleActions = createAsyncAction(
  "commissions-level-rule/DELETE_REQUEST",
  "commissions-level-rule/DELETE_SUCCESS",
  "commissions-level-rule/DELETE_FAILURE"
)<TwoLevelEntityIdObject, TwoLevelEntityIdObject, void>();

/**
 * ACTIONS - LEVEL BAIL ACCOUNT SETTINGS
 */
export const createCommissionsLevelBailAccountSettingsActions = createAsyncAction(
  "commissions-level-bail-account/CREATE_REQUEST",
  "commissions-level-bail-account/CREATE_SUCCESS",
  "commissions-level-bail-account/CREATE_FAILURE"
)<EntityObject<CreateUpdateBailAccountSettings>, BailAccountSettings, void>();

export const updateCommissionsLevelBailAccountSettingsActions = createAsyncAction(
  "commissions-level-bail-account/UPDATE_REQUEST",
  "commissions-level-bail-account/UPDATE_SUCCESS",
  "commissions-level-bail-account/UPDATE_FAILURE"
)<EntityObject<CreateUpdateBailAccountSettings>, BailAccountSettings, void>();

export const deleteCommissionsLevelBailAccountSettingsActions = createAsyncAction(
  "commissions-level-bail-account/DELETE_REQUEST",
  "commissions-level-bail-account/DELETE_SUCCESS",
  "commissions-level-bail-account/DELETE_FAILURE"
)<EntityIdObject, void, void>();

/**
 * ACTIONS - LEVEL ATTACHMENTS
 */
export const uploadCommissionsLevelAttachmentsActions = createAsyncAction(
  "commissions-level-attachment/UPLOAD_REQUEST",
  "commissions-level-attachment/UPLOAD_SUCCESS",
  "commissions-level-attachment/UPLOAD_FAILURE"
)<EntityObject<FormData>, CommissionsSettingsLevelAttachment[], void>();

export const downloadCommissionsLevelAttachmentActions = createAsyncAction(
  "commissions-level-attachment/DOWNLOAD_REQUEST",
  "commissions-level-attachment/DOWNLOAD_SUCCESS",
  "commissions-level-attachment/DOWNLOAD_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const deleteCommissionsLevelAttachmentActions = createAsyncAction(
  "commissions-level-attachment/DELETE_REQUEST",
  "commissions-level-attachment/DELETE_SUCCESS",
  "commissions-level-attachment/DELETE_FAILURE"
)<TwoLevelEntityIdObject, EntityIdObject, void>();

const actions = {
  filterCommissionsLevelsActions,
  getCommissionsLevelActions,
  createCommissionsLevelActions,
  updateCommissionsLevelActions,
  deleteCommissionsLevelActions,
  deleteStateCommissionsLevelsPageAction,
  deleteStateCommissionsLevelDetailAction,
  createCommissionsLevelRuleActions,
  updateCommissionsLevelRuleActions,
  deleteCommissionsLevelRuleActions,
  createCommissionsLevelBailAccountSettingsActions,
  updateCommissionsLevelBailAccountSettingsActions,
  deleteCommissionsLevelBailAccountSettingsActions,
  uploadCommissionsLevelAttachmentsActions,
  downloadCommissionsLevelAttachmentActions,
  deleteCommissionsLevelAttachmentActions
};

export type CommissionsSettingsLevelAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: CommissionsSettingsLevelReducerState = {
  currentPage: { ...initSearchPageResult<CommissionsSettingsLevelBase>(), type: undefined },
  levelDetail: null
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterCommissionsLevelsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterCommissionsLevelsActions.failure, deleteStateCommissionsLevelsPageAction],
    () => initialState.currentPage
  );

const levelDetailReducer = createReducer(initialState.levelDetail)
  .handleAction(
    [getCommissionsLevelActions.success, createCommissionsLevelActions.success, updateCommissionsLevelActions.success],
    (_, { payload }) => ({
      ...payload,
      rules: payload.rules ? sortCommissionsSettingsRules<CommissionsSettingsLevelRule>(payload.rules) : undefined
    })
  )
  .handleAction(createCommissionsLevelRuleActions.success, (state, { payload }) => ({
    ...(state as CommissionsSettingsLevel),
    rules: sortCommissionsSettingsRules<CommissionsSettingsLevelRule>([...(state?.rules ?? []), payload])
  }))
  .handleAction(updateCommissionsLevelRuleActions.success, (state, { payload }) => ({
    ...(state as CommissionsSettingsLevel),
    rules: replaceInArray(
      state?.rules ?? [],
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(deleteCommissionsLevelRuleActions.success, (state, { payload }) => ({
    ...(state as CommissionsSettingsLevel),
    rules: removeFromArray(state?.rules ?? [], item => item.id === payload.id2)
  }))
  .handleAction(
    [
      createCommissionsLevelBailAccountSettingsActions.success,
      updateCommissionsLevelBailAccountSettingsActions.success
    ],
    (state, { payload }) => ({ ...(state as CommissionsSettingsLevel), bailAccountSettings: payload })
  )
  .handleAction(deleteCommissionsLevelBailAccountSettingsActions.success, state => ({
    ...(state as CommissionsSettingsLevel),
    bailAccountSettings: undefined
  }))
  .handleAction(uploadCommissionsLevelAttachmentsActions.success, (state, { payload }) => ({
    ...(state as CommissionsSettingsLevel),
    attachments: payload
  }))
  .handleAction(deleteCommissionsLevelAttachmentActions.success, (state, { payload }) => ({
    ...(state as CommissionsSettingsLevel),
    attachments: state?.attachments ? removeFromArray(state.attachments, item => item.id === payload.id) : []
  }))
  .handleAction(
    [
      getCommissionsLevelActions.failure,
      deleteCommissionsLevelActions.success,
      deleteStateCommissionsLevelDetailAction
    ],
    () => initialState.levelDetail
  );

export const commissionsSettingsLevelReducer = combineReducers<CommissionsSettingsLevelReducerState>({
  currentPage: currentPageReducer,
  levelDetail: levelDetailReducer
});

/**
 * SELECTORS
 */
const selectCommissionsSettingsLevel = (state: RootState): CommissionsSettingsLevelReducerState =>
  state.commissions.level;

export const selectCommissionsLevelsCurrentPage = (state: RootState): CommissionsSettingsLevelFilterPageResult =>
  selectCommissionsSettingsLevel(state).currentPage;
export const selectCommissionsLevelDetail = (state: RootState): CommissionsSettingsLevel | undefined =>
  selectCommissionsSettingsLevel(state).levelDetail ?? undefined;

/**
 * SAGAS - LEVELS
 */
function* filterCommissionsLevels({ payload }: ReturnType<typeof filterCommissionsLevelsActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettingsLevelFilterPageResult> = yield call(
      api.filterCommissionsLevels,
      payload
    );
    yield put(filterCommissionsLevelsActions.success(response.data));
  } catch {
    yield put(filterCommissionsLevelsActions.failure());
  }
}

function* getCommissionsLevel({ payload }: ReturnType<typeof getCommissionsLevelActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettingsLevel> = yield call(api.getCommissionsLevel, payload);
    yield put(getCommissionsLevelActions.success(response.data));
  } catch {
    yield put(getCommissionsLevelActions.failure());
  }
}

function* createCommissionsLevel({ payload }: ReturnType<typeof createCommissionsLevelActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettingsLevel> = yield call(api.createCommissionsLevel, payload);
    yield put(createCommissionsLevelActions.success(response.data));
    yield put(push(generatePath(COMMISSIONS_LEVEL_ROUTE_PATHS.detail.to, { id: response.data.id })));
    messageUtils.itemCreatedNotification();
    yield put(getEnumerationsActions.request());
  } catch {
    yield put(createCommissionsLevelActions.failure());
  }
}

function* updateCommissionsLevel({ payload }: ReturnType<typeof updateCommissionsLevelActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettingsLevel> = yield call(api.updateCommissionsLevel, payload);
    yield put(updateCommissionsLevelActions.success(response.data));
    messageUtils.itemUpdatedNotification();
    yield put(changeRunningRequestKeyAction());
    yield put(getEnumerationsActions.request());
  } catch {
    yield put(updateCommissionsLevelActions.failure());
  }
}

function* deleteCommissionsLevel({ payload }: ReturnType<typeof deleteCommissionsLevelActions.request>) {
  try {
    yield call(api.deleteCommissionsLevel, payload);
    yield put(deleteCommissionsLevelActions.success());
    yield put(replace(COMMISSIONS_LEVEL_ROUTE_PATHS.list.to));
    messageUtils.itemDeletedNotification();
    yield put(getEnumerationsActions.request());
  } catch {
    yield put(deleteCommissionsLevelActions.failure());
  }
}

/**
 * SAGAS - LEVEL RULES
 */
function* createCommissionsLevelRule({ payload }: ReturnType<typeof createCommissionsLevelRuleActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettingsLevelRule> = yield call(api.createCommissionsLevelRule, payload);
    yield put(createCommissionsLevelRuleActions.success(response.data));
    messageUtils.itemCreatedNotification();
    yield put(changeRunningRequestKeyAction());
  } catch {
    yield put(createCommissionsLevelRuleActions.failure());
  }
}

function* updateCommissionsLevelRule({ payload }: ReturnType<typeof updateCommissionsLevelRuleActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettingsLevelRule> = yield call(api.updateCommissionsLevelRule, payload);
    yield put(updateCommissionsLevelRuleActions.success(response.data));
    messageUtils.itemUpdatedNotification();
    yield put(changeRunningRequestKeyAction());
  } catch {
    yield put(updateCommissionsLevelRuleActions.failure());
  }
}

function* deleteCommissionsLevelRule({ payload }: ReturnType<typeof deleteCommissionsLevelRuleActions.request>) {
  try {
    yield call(api.deleteCommissionsLevelRule, payload);
    yield put(deleteCommissionsLevelRuleActions.success(payload));
    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteCommissionsLevelRuleActions.failure());
  }
}

/**
 * SAGAS - LEVEL BAIL ACCOUNT SETTINGS
 */
function* createCommissionsLevelBailAccountSettings({
  payload
}: ReturnType<typeof createCommissionsLevelBailAccountSettingsActions.request>) {
  try {
    const response: AxiosResponse<BailAccountSettings> = yield call(
      api.createCommissionsLevelBailAccountSettings,
      payload
    );
    yield put(createCommissionsLevelBailAccountSettingsActions.success(response.data));
    messageUtils.itemCreatedNotification();
    yield put(changeRunningRequestKeyAction());
  } catch {
    yield put(createCommissionsLevelBailAccountSettingsActions.failure());
  }
}

function* updateCommissionsLevelBailAccountSettings({
  payload
}: ReturnType<typeof updateCommissionsLevelBailAccountSettingsActions.request>) {
  try {
    const response: AxiosResponse<BailAccountSettings> = yield call(
      api.updateCommissionsLevelBailAccountSettings,
      payload
    );
    yield put(updateCommissionsLevelBailAccountSettingsActions.success(response.data));
    messageUtils.itemUpdatedNotification();
    yield put(changeRunningRequestKeyAction());
  } catch {
    yield put(updateCommissionsLevelBailAccountSettingsActions.failure());
  }
}

function* deleteCommissionsLevelBailAccountSettings({
  payload
}: ReturnType<typeof deleteCommissionsLevelBailAccountSettingsActions.request>) {
  try {
    yield call(api.deleteCommissionsLevelBailAccountSettings, payload);
    yield put(deleteCommissionsLevelBailAccountSettingsActions.success());
    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteCommissionsLevelBailAccountSettingsActions.failure());
  }
}

/**
 * SAGAS - LEVEL ATTACHMENTS
 */
function* uploadCommissionsLevelAttachments({
  payload
}: ReturnType<typeof uploadCommissionsLevelAttachmentsActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettingsLevelAttachment[]> = yield call(
      api.uploadCommissionsLevelAttachments,
      payload
    );
    yield put(uploadCommissionsLevelAttachmentsActions.success(response.data));
  } catch {
    yield put(uploadCommissionsLevelAttachmentsActions.failure());
  }
}

function* downloadCommissionsLevelAttachment({
  payload
}: ReturnType<typeof downloadCommissionsLevelAttachmentActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadCommissionsLevelAttachment, payload);
    openBlobFile(response);
    yield put(downloadCommissionsLevelAttachmentActions.success());
  } catch {
    yield put(downloadCommissionsLevelAttachmentActions.failure());
  }
}

function* deleteCommissionsLevelAttachment({
  payload
}: ReturnType<typeof deleteCommissionsLevelAttachmentActions.request>) {
  try {
    yield call(api.deleteCommissionsLevelAttachment, payload);
    yield put(deleteCommissionsLevelAttachmentActions.success({ id: payload.id2 }));
  } catch {
    yield put(deleteCommissionsLevelAttachmentActions.failure());
  }
}

export function* commissionsLevelSaga() {
  yield takeLatest(filterCommissionsLevelsActions.request, filterCommissionsLevels);
  yield takeLatest(getCommissionsLevelActions.request, getCommissionsLevel);
  yield takeLatest(createCommissionsLevelActions.request, createCommissionsLevel);
  yield takeLatest(updateCommissionsLevelActions.request, updateCommissionsLevel);
  yield takeLatest(deleteCommissionsLevelActions.request, deleteCommissionsLevel);

  yield takeLatest(createCommissionsLevelRuleActions.request, createCommissionsLevelRule);
  yield takeLatest(updateCommissionsLevelRuleActions.request, updateCommissionsLevelRule);
  yield takeLatest(deleteCommissionsLevelRuleActions.request, deleteCommissionsLevelRule);

  yield takeLatest(createCommissionsLevelBailAccountSettingsActions.request, createCommissionsLevelBailAccountSettings);
  yield takeLatest(updateCommissionsLevelBailAccountSettingsActions.request, updateCommissionsLevelBailAccountSettings);
  yield takeLatest(deleteCommissionsLevelBailAccountSettingsActions.request, deleteCommissionsLevelBailAccountSettings);

  yield takeLatest(uploadCommissionsLevelAttachmentsActions.request, uploadCommissionsLevelAttachments);
  yield takeLatest(downloadCommissionsLevelAttachmentActions.request, downloadCommissionsLevelAttachment);
  yield takeLatest(deleteCommissionsLevelAttachmentActions.request, deleteCommissionsLevelAttachment);
}
