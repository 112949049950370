import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import FileTypeIcon from "../../../../common/components/icons/FileTypeIcon";
import { CALC_ROUTE_PATHS } from "../../paths";
import { CalcAttachmentConf } from "../types";

type Props = {
  attachments?: CalcAttachmentConf[];
};

export const CalcResultAttachments = ({ attachments }: Props) => {
  const { t } = useTranslation();

  return attachments?.length ? (
    <div style={{ marginTop: 8 }}>
      <b>{t("calc.settings.sections.attachments")}</b>
      <div style={{ maxHeight: 200, overflowY: "auto" }}>
        {attachments.map(attachment => (
          <div key={attachment.id}>
            <Link to={generatePath(CALC_ROUTE_PATHS.resultAttachment.to, { id: attachment.id })} target="_blank">
              <FileTypeIcon contentType={attachment.file.contentType} /> {attachment.filename}
            </Link>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
