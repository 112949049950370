import t from "../../../app/i18n/index";
import LabelWithPopover from "../../../common/components/form/labels/LabelWithPopover";
import { ContractType } from "../enums";

const CONTRACT_TYPE_TO_AMOUNT_TYPE_MAP: [ContractType, string][] = [
  [ContractType.INSURANCE_CONTRACT, t("contract.attrs.annualPremium")],
  [ContractType.LOAN_CONTRACT, t("contract.attrs.approvedAmount")],
  [ContractType.INVESTMENT_CONTRACT, t("contract.attrs.prepaidTargetAmount")],
  [ContractType.DEPOSIT_CONTRACT, t("contract.attrs.targetAmount")],
  [ContractType.SECOND_PILLAR_CONTRACT, "-"],
  [ContractType.THIRD_PILLAR_CONTRACT, t("contract.attrs.annualPremium")],
  [ContractType.GENERIC_CONTRACT, t("contract.attrs.tradeAmount")]
];

const ContractAmountInfoPopover = () => (
  <LabelWithPopover
    label={t("contract.filter.annualPremiumOrApprovedAmountRange")}
    popoverTitle={t("contract.helpers.amountInfoTitle")}
    popoverContent={
      <table className="data-table-view">
        <tbody>
          {CONTRACT_TYPE_TO_AMOUNT_TYPE_MAP.map((value, index) => (
            <tr key={index}>
              <td>{t("contract.enums.contractType." + value[0])}</td>
              <td>{value[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    }
  />
);

export default ContractAmountInfoPopover;
