import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import apiService from "../../../common/api/apiService";
import { EntityObject, TwoLevelEntityIdObject, TwoLevelEntityObject } from "../../../common/types";
import { CreateUpdateSpecialCommissionsSettingsRule, SpecialCommissionsSettingsRule } from "./types";

const CREATE_SPECIAL_COMMISSIONS_RULE = ApiRequestAdapter.create(
  "/agents/{0}/special-commissions-settings/rules",
  "POST"
);
const UPDATE_SPECIAL_COMMISSIONS_RULE = ApiRequestAdapter.create(
  "/agents/{0}/special-commissions-settings/rules/{1}",
  "PUT"
);
const DELETE_SPECIAL_COMMISSIONS_RULE = ApiRequestAdapter.create(
  "/agents/{0}/special-commissions-settings/rules/{1}",
  "DELETE"
);

export const requests = {
  CREATE_SPECIAL_COMMISSIONS_RULE,
  UPDATE_SPECIAL_COMMISSIONS_RULE,
  DELETE_SPECIAL_COMMISSIONS_RULE
};

const api = {
  createSpecialCommissionsRule: (
    request: EntityObject<CreateUpdateSpecialCommissionsSettingsRule>
  ): AxiosPromise<SpecialCommissionsSettingsRule> => {
    return apiService.post(CREATE_SPECIAL_COMMISSIONS_RULE.getParametrizedUrl(request.id), request.object);
  },
  updateSpecialCommissionsRule: (
    request: TwoLevelEntityObject<CreateUpdateSpecialCommissionsSettingsRule>
  ): AxiosPromise<SpecialCommissionsSettingsRule> => {
    return apiService.put(UPDATE_SPECIAL_COMMISSIONS_RULE.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteSpecialCommissionsRule: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_SPECIAL_COMMISSIONS_RULE.getParametrizedUrl(request.id1, request.id2));
  }
};

export default api;
