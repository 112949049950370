import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { Feature } from "../../common/security/authorization/enums";
import { RootState } from "../../common/types";
import { dateToIsoDateTimeString, toDate } from "../../common/utils/formUtils";
import { AffiliatePartnerBase } from "../affiliate/types";
import { CommissionsSettingsLevelBase } from "../commissions/level/types";
import { LifeInsuranceTariffGroup } from "../lifeinsurancetariff/types";
import { UserBase } from "../user/types";
import { CertificateVehicleColor } from "../vehicle/color/types";
import api from "./api";
import {
  AgentEnumeration,
  CommissionSourceTemplate,
  Enumerations,
  EnumerationsReducerState,
  InstitutionWithComplicities,
  InstitutionWithCoverageLimits,
  InstitutionWithProducts,
  InstitutionWithSettings,
  ProductGroupWithProducts,
  ProductWithLifeInsuranceTariffs,
  VehicleBrandWithModels
} from "./types";

export const VEHICLE_OTHER_MAPPING_NAME = "Ostatné";

/**
 * ACTIONS
 */
export const getEnumerationsActions = createAsyncAction(
  "enumerations/GET_REQUEST",
  "enumerations/GET_SUCCESS",
  "enumerations/GET_FAILURE"
)<void, Enumerations, void>();

export const refreshEnumerationsAction = createAction("enumerations/REFRESH")<void>();

export const deleteStateEnumerationsAction = createAction("enumerations/DELETE_STATE_COMMON")<void>();

const actions = {
  getEnumerationsActions,
  refreshEnumerationsAction,
  deleteStateEnumerationsAction
};

export type EnumerationAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: EnumerationsReducerState = {
  common: {
    lastRefreshTime: undefined,
    subordinateAgents: [],
    allAgents: [],
    institutions: [],
    productGroups: [],
    productsClassification: [],
    affiliatePartners: [],
    vehicleBrands: [],
    vehicleColors: [],
    coverageLimitsClassification: [],
    complicitiesClassification: [],
    tariffsClassification: [],
    tariffGroups: [],
    users: [],
    commissionSourceTemplates: [],
    commissionsSettingsLevels: [],
    branding: {
      logoResourcePath: "",
      primaryColor: "",
      secondaryColor: ""
    },
    topAgentAllowedFeatures: [],
    topAgents: []
  }
};

const commonEnumerationsReducer = createReducer(initialState.common)
  .handleAction(getEnumerationsActions.success, (_, { payload }) => payload)
  .handleAction(getEnumerationsActions.failure, () => ({
    ...initialState.common,
    lastRefreshTime: dateToIsoDateTimeString(dayjs())
  }))
  .handleAction(deleteStateEnumerationsAction, () => initialState.common);

export const enumerationsReducer = combineReducers<EnumerationsReducerState>({ common: commonEnumerationsReducer });

/**
 * SELECTORS
 */
const selectStateEnums = (state: RootState): EnumerationsReducerState => state.enumerations;

export const selectEnums = (state: RootState): Enumerations => selectStateEnums(state).common;

export const selectEnumsLastRefreshTime = (state: RootState): string | undefined => selectEnums(state).lastRefreshTime;

export const selectSubordinateAgentsEnums = (state: RootState): AgentEnumeration[] =>
  selectEnums(state).subordinateAgents;

export const selectAllAgentsEnums = (state: RootState): AgentEnumeration[] => selectEnums(state).allAgents;

export const selectInstitutionsEnums = (state: RootState): InstitutionWithSettings[] => selectEnums(state).institutions;

export const selectProductGroupsEnums = (state: RootState): ProductGroupWithProducts[] =>
  selectEnums(state).productGroups;

export const selectProductsClassificationEnums = (state: RootState): InstitutionWithProducts[] =>
  selectEnums(state).productsClassification;

export const selectAffiliatePartnersEnums = (state: RootState): AffiliatePartnerBase[] =>
  selectEnums(state).affiliatePartners;

export const selectVehicleBrandsEnums = (state: RootState): VehicleBrandWithModels[] =>
  selectEnums(state).vehicleBrands;

export const selectVehicleColorsEnums = (state: RootState): CertificateVehicleColor[] =>
  selectEnums(state).vehicleColors;

export const selectCoverageLimitsClassificationEnums = (state: RootState): InstitutionWithCoverageLimits[] =>
  selectEnums(state).coverageLimitsClassification;

export const selectComplicitiesClassificationEnums = (state: RootState): InstitutionWithComplicities[] =>
  selectEnums(state).complicitiesClassification;

export const selectTariffsClassificationEnums = (state: RootState): ProductWithLifeInsuranceTariffs[] =>
  selectEnums(state).tariffsClassification;

export const selectTariffGroupsEnums = (state: RootState): LifeInsuranceTariffGroup[] =>
  selectEnums(state).tariffGroups;

export const selectUsersEnums = (state: RootState): UserBase[] => selectEnums(state).users;

export const selectCommissionSourceTemplatesEnums = (state: RootState): CommissionSourceTemplate[] =>
  selectEnums(state).commissionSourceTemplates;

export const selectCommissionsSettingsLevelsEnums = (state: RootState): CommissionsSettingsLevelBase[] =>
  selectEnums(state).commissionsSettingsLevels;

export const selectTopAgentAllowedFeaturesEnums = (state: RootState): Feature[] =>
  selectEnums(state).topAgentAllowedFeatures;

export const selectTopAgentsEnums = (state: RootState): AgentEnumeration[] => selectEnums(state).topAgents;

export const selectVehicleOtherBrandsIdsEnums = createSelector(selectVehicleBrandsEnums, brands =>
  brands.filter(brand => brand.name === VEHICLE_OTHER_MAPPING_NAME).flatMap(brand => brand.id)
);

export const selectVehicleOtherModelsIdsEnums = createSelector(selectVehicleBrandsEnums, brands =>
  brands
    .flatMap(brand => brand.models)
    .filter(model => model.name === VEHICLE_OTHER_MAPPING_NAME)
    .flatMap(model => model.id)
);

/**
 * SAGAS
 */
function* getEnumerations() {
  try {
    const response: AxiosResponse<Enumerations> = yield call(api.getEnumerations);
    yield put(getEnumerationsActions.success({ lastRefreshTime: dateToIsoDateTimeString(dayjs()), ...response.data }));
  } catch {
    yield put(getEnumerationsActions.failure());
  }
}

function* refreshEnumerations() {
  const lastRefreshTime: string = yield select(selectEnumsLastRefreshTime);
  if (lastRefreshTime === undefined || dayjs().diff(toDate(lastRefreshTime), "minutes") >= 60) {
    yield put(getEnumerationsActions.request());
  }
}

export function* enumerationsSaga() {
  yield takeLatest(getEnumerationsActions.request, getEnumerations);
  yield takeLatest(refreshEnumerationsAction, refreshEnumerations);
}
