import { AutoComplete, Card, Col, Divider, Form, Input, Row } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import { RcFile } from "antd/lib/upload";
import { NamePath } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import t from "../../../../../../../app/i18n";
import ContactsEmailAutoComplete from "../../../../../../../common/components/form/components/ContactsEmailAutoComplete";
import ContactsPhoneNumberAutoComplete from "../../../../../../../common/components/form/components/ContactsPhoneNumberAutoComplete";
import { rowGutter } from "../../../../../../../common/constants";
import MarketingConsentSelect from "../../../../../../../common/modules/contact/MarketingConsentSelect";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import {
  phoneNumberNormalizeFunction,
  resolveFormValidationError,
  upperCaseStringNormalizeFunction
} from "../../../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../../../common/utils/validationUtils";
import ClientDrawerForm from "../../../../../../client/components/drawers/ClientDrawerForm";
import ClientRepresentativeSearchForm from "../../../../../../client/components/search/ClientRepresentativeSearchForm";
import ClientSearchInput from "../../../../../../client/components/search/ClientSearchInput";
import { ClientFormStage, ClientFormType, ClientSearchActionType, ClientType } from "../../../../../../client/enums";
import { Client, LegalClient, NaturalClient } from "../../../../../../client/types";
import { useClientSearch } from "../../../../../../client/utils";
import { InstitutionEnum } from "../../../../../../institution/enums";
import { CalcType } from "../../../../../enums";
import CalcAttachmentUpload from "../../../../components/CalcAttachmentUpload";
import { CalcAttachmentType } from "../../../../enums";
import { CalcResult } from "../../../../types";
import { resolveClientFormTypeIdentifierName } from "../../../../utils";
import { VehicleOwnerRelation, VehiclePolicyHolderRelation } from "../../../enums";
import { VehicleCalcClientsData, VehicleCalcResultData, VehicleFormClients, VehicleGenForm } from "../../../types";
import { resolveVehiclePolicyHolder, resolveVehiclePolicyHolderType } from "../../../utils";

interface Props {
  form: FormInstance<VehicleGenForm>;
  clientsData: VehicleCalcClientsData;
  clients: VehicleFormClients;
  selectedResult: CalcResult<VehicleCalcResultData>;
  clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  onClientChange: (type: ClientFormType, client?: Client) => void;
  onClientViolationErrorsChange: (type: ClientFormType, violations?: FieldConstraintViolation[]) => void;
  onCalcAttachmentChange: (type: CalcAttachmentType, file?: RcFile) => void;
}

interface ClientsStagesState {
  policyHolder?: ClientFormStage;
  owner?: ClientFormStage;
  representative?: ClientFormStage;
}

const noRepeatedClient =
  (holderIdentifier: string, otherIdentifierPath: NamePath): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) =>
      value && (value === holderIdentifier || value === getFieldValue(otherIdentifierPath))
        ? Promise.reject(t("validation.noRepeatedClient"))
        : Promise.resolve()
  });

const VehicleGenClientsDataSection = ({
  form,
  clients,
  clientsData,
  selectedResult,
  onCalcAttachmentChange,
  ...props
}: Props) => {
  const clientSearch = useClientSearch();

  const [processedClientFormType, setProcessedClientFormType] = useState<ClientFormType>();
  const [clientFormOpen, setClientFormOpen] = useState<boolean>(false);
  const [clientStages, setClientStages] = useState<ClientsStagesState>({
    policyHolder:
      clients.policyHolder &&
      clientsData.policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER
        ? ClientFormStage.EXISTING
        : undefined,
    owner:
      clients.owner && clientsData.ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_BOTH
        ? ClientFormStage.EXISTING
        : undefined,
    representative:
      clients.representative &&
      resolveVehiclePolicyHolderType(clients, clientsData.policyHolderRelation) === ClientType.LEGAL &&
      (
        (resolveVehiclePolicyHolder(clients, clientsData.policyHolderRelation) as LegalClient)?.representatives || []
      ).some(r => r.representative.identifier === clients.representative?.identifier)
        ? ClientFormStage.EXISTING
        : undefined
  });

  useEffect(() => {
    return () => {
      clientSearch.onResultDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (processedClientFormType && clientSearch.result.keyword === getClientFormIdentifier(processedClientFormType)) {
      if (clientSearch.result.data) {
        setClientFormOpen(true);
        setClientFormStage(processedClientFormType, ClientFormStage.EXISTING);
        if (
          clientSearch.result.data.type === ClientType.NATURAL ||
          clientSearch.result.data.type === ClientType.SELF_EMPLOYED
        ) {
          props.onClientChange(processedClientFormType, {
            ...clientSearch.result.data,
            identityCardNumber: undefined,
            previousIdentityCardNumber: (clientSearch.result.data as NaturalClient).identityCardNumber
          } as NaturalClient);
        } else {
          props.onClientChange(processedClientFormType, clientSearch.result.data);
        }
      } else {
        setClientFormStage(processedClientFormType, ClientFormStage.NEW);
      }
    }
  }, [clientSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientSearchSubmit = (value: string, type: ClientFormType): void => {
    form
      .validateFields([["clientsData", resolveClientFormTypeIdentifierName(type)]])
      .then(() => clientSearch.onSearch({ keyword: value, clientType: resolveClientTypeByClientFormType(type) }))
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string, type: ClientFormType): void => {
    if (getClientFormStage(type)) {
      setClientFormStage(type);
    }
    if (getClientFromProps(type)) {
      props.onClientChange(type);
    }
    if (props.clientsViolationErrors.has(type)) {
      props.onClientViolationErrorsChange(type);
    }
    handleClientSearchSubmit(value, type);
  };

  const handleClientSearchActionClick = (type: ClientFormType, actionType: ClientSearchActionType): void => {
    switch (actionType) {
      case ClientSearchActionType.CREATE:
      case ClientSearchActionType.UPDATE:
        setProcessedClientFormType(type);
        setClientFormOpen(true);
        break;
      case ClientSearchActionType.DELETE:
        form.setFieldsValue({ clientsData: { [resolveClientFormTypeIdentifierName(type)]: undefined } });
        setClientFormStage(type);
        props.onClientViolationErrorsChange(type);
        props.onClientChange(type);
        break;
    }
  };

  const handleClientFormSubmit = (client: Client, clientFormType: ClientFormType): void => {
    setClientFormOpen(false);
    setProcessedClientFormType(undefined);
    setClientFormStage(clientFormType, ClientFormStage.SELECTED);
    props.onClientViolationErrorsChange(clientFormType);
    props.onClientChange(clientFormType, client);
  };

  const getClientFormIdentifier = (type?: ClientFormType): string => {
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        return form.getFieldValue(["clientsData", "policyHolderIdentifier"]);
      case ClientFormType.OWNER:
        return form.getFieldValue(["clientsData", "ownerIdentifier"]);
      case ClientFormType.REPRESENTATIVE:
        return form.getFieldValue(["clientsData", "representativeIdentifier"]);
      default:
        return "";
    }
  };

  const getClientFormStage = (type?: ClientFormType): ClientFormStage | undefined => {
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        return clientStages.policyHolder;
      case ClientFormType.OWNER:
        return clientStages.owner;
      case ClientFormType.REPRESENTATIVE:
        return clientStages.representative;
      default:
        return undefined;
    }
  };

  const getClientFromProps = (type?: ClientFormType): Client | undefined => {
    switch (type) {
      case ClientFormType.HOLDER:
        return clients.holder;
      case ClientFormType.POLICY_HOLDER:
        return clients.policyHolder;
      case ClientFormType.OWNER:
        return clients.owner;
      case ClientFormType.REPRESENTATIVE:
        return clients.representative;
      default:
        return undefined;
    }
  };

  const setClientFormStage = (type: ClientFormType, stage?: ClientFormStage): void => {
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        setClientStages({ ...clientStages, policyHolder: stage });
        break;
      case ClientFormType.OWNER:
        setClientStages({ ...clientStages, owner: stage });
        break;
      case ClientFormType.REPRESENTATIVE:
        setClientStages({ ...clientStages, representative: stage });
        break;
    }
  };

  const resolveClientTypeByClientFormType = (type?: ClientFormType): ClientType | undefined => {
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        return undefined;
      case ClientFormType.OWNER:
        return clientsData.leasing ? ClientType.LEGAL : undefined;
      case ClientFormType.REPRESENTATIVE:
        return ClientType.NATURAL;
      default:
        return undefined;
    }
  };

  const { calcType } = selectedResult;
  const { institutionEnum } = selectedResult.insuranceInstitution;

  const colSpan = 4;

  const clientSearchProps = {
    processedType: processedClientFormType,
    violationErrors: props.clientsViolationErrors,
    inProgress: clientSearch.inProgress,
    inputColSpan: 5,
    clientNameColSpan: 6,
    onActionClick: handleClientSearchActionClick,
    onFocus: setProcessedClientFormType,
    onSearch: handleClientSearchSubmit,
    onChange: handleClientSearchChange
  };

  return (
    <>
      <Card type="inner" className="card-box" title={t("calc.vehicle.sections.clientsData")}>
        <div className="sub-header-info normal-font-size margin-bottom-small">
          <span>{t("calc.vehicle.sections.holder")}: </span>
          {clients.holder?.aggregatedName} ({clients.holder?.identifier})
          {clientsData.policyHolderRelation === VehiclePolicyHolderRelation.SAME_AS_VEHICLE_HOLDER && (
            <>
              <br />
              <span>{t("calc.vehicle.sections.policyHolder")}: </span>
              {t("calc.vehicle.sections.policyHolderSameAsHolder")}
            </>
          )}
          {clientsData.ownerRelation === VehicleOwnerRelation.SAME_AS_POLICY_HOLDER && (
            <>
              <br />
              <span>{t("calc.vehicle.sections.owner")}: </span>
              {t("calc.vehicle.sections.ownerSameAsPolicyHolder")}
            </>
          )}
          {clientsData.ownerRelation === VehicleOwnerRelation.SAME_AS_VEHICLE_HOLDER && (
            <>
              <br />
              <span>{t("calc.vehicle.sections.owner")}: </span>
              {t("calc.vehicle.sections.ownerSameAsHolder")}
            </>
          )}
        </div>

        {(clients.holder?.type === ClientType.NATURAL || clients.holder?.type === ClientType.SELF_EMPLOYED) && (
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name={["clientsData", "holderIdentityCardNumber"]}
                label={t("calc.vehicle.attrs.clientsData.holderIdentityCardNumber")}
                rules={[validations.notBlank, validations.size(8, 10), validations.pattern(regexPatterns.idCardRegex)]}
                normalize={upperCaseStringNormalizeFunction}
              >
                {(clients.holder as NaturalClient).previousIdentityCardNumber ? (
                  <AutoComplete
                    options={[
                      {
                        value: (clients.holder as NaturalClient).previousIdentityCardNumber,
                        label: (
                          <>
                            <span className="sub-header-info">{t("client.attrs.previousIdentityCardNumber")}</span>
                            <br />
                            {(clients.holder as NaturalClient).previousIdentityCardNumber}
                          </>
                        )
                      }
                    ]}
                  />
                ) : (
                  <Input />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={rowGutter}>
          {clientsData.policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER && (
            <ClientSearchInput<ClientFormType>
              {...clientSearchProps}
              formItemProps={{
                name: ["clientsData", "policyHolderIdentifier"],
                label: t("calc.vehicle.attrs.clientsData.policyHolderIdentifier"),
                rules: [
                  validations.notBlank,
                  validations.pinOrCrn,
                  clients.holder?.identifier
                    ? noRepeatedClient(clients.holder?.identifier, ["clientsData", "ownerIdentifier"])
                    : validations.none
                ],
                validateFirst: true
              }}
              formStage={clientStages.policyHolder}
              formType={ClientFormType.POLICY_HOLDER}
              client={clients.policyHolder}
            />
          )}

          <Col span={colSpan}>
            <ContactsEmailAutoComplete
              formItemProps={{
                name: ["clientsData", "policyHolderEmail"],
                label: t("calc.vehicle.attrs.clientsData.policyHolderEmail"),
                rules: [validations.notBlank, validations.size(1, 254), validations.email]
              }}
              contacts={resolveVehiclePolicyHolder(clients, clientsData.policyHolderRelation)?.contacts}
            />
          </Col>

          <Col span={colSpan}>
            <ContactsPhoneNumberAutoComplete
              formItemProps={{
                name: ["clientsData", "policyHolderPhone"],
                label: t("calc.vehicle.attrs.clientsData.policyHolderPhone"),
                rules: [validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber],
                normalize: phoneNumberNormalizeFunction
              }}
              contacts={resolveVehiclePolicyHolder(clients, clientsData.policyHolderRelation)?.contacts}
            />
          </Col>

          <Col span={colSpan}>
            <MarketingConsentSelect
              formItemProps={{
                name: ["clientsData", "policyHolderMarketingConsent"],
                label: t("contact.enums.marketingConsent._label"),
                rules: [validations.notNull]
              }}
            />
          </Col>
        </Row>

        {clientsData.ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_BOTH && (
          <Row gutter={rowGutter}>
            <ClientSearchInput<ClientFormType>
              {...clientSearchProps}
              formItemProps={{
                name: ["clientsData", "ownerIdentifier"],
                label: clientsData.leasing
                  ? t("calc.vehicle.attrs.clientsData.ownerLeasingIdentifier")
                  : t("calc.vehicle.attrs.clientsData.ownerIdentifier"),
                rules: [
                  validations.notBlank,
                  clientsData.leasing ? validations.crn : validations.pinOrCrn,
                  clients.holder?.identifier
                    ? noRepeatedClient(clients.holder?.identifier, ["clientsData", "policyHolderIdentifier"])
                    : validations.none
                ],
                validateFirst: true
              }}
              formStage={clientStages.owner}
              formType={ClientFormType.OWNER}
              client={clients.owner}
            />
          </Row>
        )}

        {institutionEnum === InstitutionEnum.KOOPERATIVA &&
          calcType === CalcType.MTPL &&
          clientsData.holderIsDisabledPerson && (
            <>
              <Divider orientation="left" className="divider-subheader">
                {t("calc.vehicle.sections.clientAttachments")}
              </Divider>

              <Row gutter={rowGutter}>
                <Col span={colSpan * 2}>
                  <CalcAttachmentUpload
                    type={CalcAttachmentType.DISABLED_PERSON_ID_CARD}
                    onChange={onCalcAttachmentChange}
                  />
                </Col>
              </Row>
            </>
          )}

        <ClientRepresentativeSearchForm
          form={form}
          policyHolder={
            clientsData.policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER
              ? clients.policyHolder
              : clients.holder
          }
          searchInputProps={{
            ...clientSearchProps,
            formStage: clientStages.representative,
            formType: ClientFormType.REPRESENTATIVE,
            client: clients.representative
          }}
          colSpan={colSpan}
        />
      </Card>

      <ClientDrawerForm<ClientFormType>
        open={clientFormOpen}
        client={getClientFromProps(processedClientFormType)}
        initialClientType={resolveClientTypeByClientFormType(processedClientFormType)}
        initialIdentifier={getClientFormIdentifier(processedClientFormType)}
        requireIdCardNumber
        formType={processedClientFormType}
        violationErrors={props.clientsViolationErrors}
        onFormSubmit={handleClientFormSubmit}
      />
    </>
  );
};

export default VehicleGenClientsDataSection;
