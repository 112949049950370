import { Col, Row } from "antd";
import Ellipsis from "../views/Ellipsis";
import FileTypeIcon from "./FileTypeIcon";

interface Props {
  contentType: string;
  filename: string;
  size?: "small" | "default" | "large" | "huge";
  ellipsis?: boolean;
}

const FileTypeIconWithFilename = ({ contentType, filename, size, ellipsis }: Props) => (
  <Row gutter={5}>
    <Col>
      <FileTypeIcon contentType={contentType} size={size} />
    </Col>
    <Col flex="1 1 0" style={{ minWidth: 0 }}>
      <Ellipsis shouldRender={!!ellipsis}>{filename}</Ellipsis>
    </Col>
  </Row>
);

export default FileTypeIconWithFilename;
