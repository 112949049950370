import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import { RootState } from "../../../../common/types";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import type { UUID } from "../../../../typings/global";
import { ComplicityBase } from "../../../complicity/types";
import { selectComplicitiesClassificationEnums } from "../../ducks";
import { InstitutionWithComplicities } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  institutionId: UUID;
}

const ComplicitySelect = ({ formItemProps, selectProps, institutionId }: Props) => {
  const institutionsWithComplicities = useSelector<RootState, InstitutionWithComplicities[]>(
    selectComplicitiesClassificationEnums
  );

  const resolveComplicitiesByDeactivated = (deactivated: boolean): ComplicityBase[] => {
    return (
      institutionsWithComplicities.find(institution => institution.id === institutionId)?.complicities || []
    ).filter(complicity => complicity.deactivated === deactivated);
  };

  const activeComplicities = resolveComplicitiesByDeactivated(false);
  const deactivatedComplicities = resolveComplicitiesByDeactivated(true);

  return (
    <Form.Item {...formItemProps}>
      <Select {...selectStandardProps} {...selectProps}>
        {activeComplicities.length > 0 && (
          <Select.OptGroup key={1} label={t("complicity.helpers.activated")}>
            {activeComplicities.map(complicity => (
              <Select.Option key={complicity.id} value={complicity.id}>
                {complicity.value}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}
        {deactivatedComplicities.length > 0 && (
          <Select.OptGroup key={2} label={t("complicity.helpers.deactivated")}>
            {deactivatedComplicities.map(complicity => (
              <Select.Option key={complicity.id} value={complicity.id}>
                {complicity.value}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}
      </Select>
    </Form.Item>
  );
};

export default ComplicitySelect;
