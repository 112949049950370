import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../app/i18n";
import BackNavigationArrow from "../../../../common/components/views/BackNavigationArrow";
import { PageSizes } from "../../../../common/constants";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, RootState, TwoLevelEntityIdObject } from "../../../../common/types";
import { formatAgentName } from "../../../../common/utils/formatUtils";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../../ducks";
import { SpecialCommissionsReport } from "../../special/enums";
import {
  SpecialCommission,
  SpecialCommissionsFilterPageRequest,
  SpecialCommissionsFilterPageResult
} from "../../special/types";
import SpecialCommissionExcludeForm from "../components/forms/SpecialCommissionExcludeForm";
import BatchSpecialCommissionTableView from "../components/views/BatchSpecialCommissionTableView";
import BatchSpecialCommissionFilterView from "../components/views/filter/BatchSpecialCommissionFilterView";
import {
  deleteStateSpecialCommissionsPageAction,
  excludeSpecialCommissionActions,
  filterSpecialCommissionsActions,
  includeSpecialCommissionActions,
  selectSpecialCommissionsPage
} from "../ducks";

interface StateProps {
  commissionsPage: SpecialCommissionsFilterPageResult<SpecialCommission>;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterSpecialCommissions: typeof filterSpecialCommissionsActions.request;
  excludeSpecialCommission: typeof excludeSpecialCommissionActions.request;
  includeSpecialCommission: typeof includeSpecialCommissionActions.request;
  deleteStateSpecialCommissionsPage: typeof deleteStateSpecialCommissionsPageAction;
}

const CommissionsBatchSpecialCommissionsContainer = ({
  commissionsPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const { id1, id2 } = useParams<TwoLevelEntityIdObject>() as { id1: string; id2: string };
  const navigate = useNavigate();

  const [commissionToExclude, setCommissionToExclude] = useState<SpecialCommission>();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterSpecialCommissions({
      id1,
      id2,
      object: {
        pageIndex: numberOrZero(urlParams.get("pageIndex")),
        pageSize: PageSizes.LARGE,
        report:
          SpecialCommissionsReport[urlParams.get("report") as keyof typeof SpecialCommissionsReport] ||
          SpecialCommissionsReport.INCLUDED,
        code: urlParams.get("code") ?? undefined
      }
    });

    return () => {
      actions.deleteStateSpecialCommissionsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReportSwitch = (filter: SpecialCommissionsFilterPageRequest): void => {
    const params = {
      report: filter.report !== SpecialCommissionsReport.INCLUDED ? filter.report : null,
      code: filter.code || null
    } as SpecialCommissionsFilterPageRequest;

    navigate(appendSearchParamsToURL({ ...params, pageIndex: undefined }), { replace: true });
    if (commissionsPage.batch && commissionsPage.agent) {
      actions.filterSpecialCommissions({
        id1: commissionsPage.batch.id,
        id2: commissionsPage.agent.id,
        object: {
          ...params,
          pageIndex: 0,
          pageSize: commissionsPage.pageSize
        }
      });
    }
  };

  const handleFilterSubmit = (filter: SpecialCommissionsFilterPageRequest): void => {
    const params = {
      report: commissionsPage.report !== SpecialCommissionsReport.INCLUDED ? commissionsPage.report : undefined,
      code: filter.code || undefined
    } as SpecialCommissionsFilterPageRequest;

    navigate(appendSearchParamsToURL({ ...params, pageIndex: undefined }), { replace: true });
    if (commissionsPage.batch && commissionsPage.agent) {
      actions.filterSpecialCommissions({
        id1: commissionsPage.batch.id,
        id2: commissionsPage.agent.id,
        object: {
          ...params,
          pageIndex: 0,
          pageSize: commissionsPage.pageSize
        }
      });
    }
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, report, code } = commissionsPage;

    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    if (commissionsPage.batch && commissionsPage.agent) {
      actions.filterSpecialCommissions({
        id1: commissionsPage.batch.id,
        id2: commissionsPage.agent.id,
        object: {
          pageIndex: pageNumber - 1,
          pageSize,
          report,
          code
        }
      });
    }
  };

  return (
    <DisplayWrapper itemLoaded={!!commissionsPage.report}>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>
              {commissionsPage.agent &&
                t("commissions.batch.titles.manageSpecialCommissions", {
                  agentName: formatAgentName(commissionsPage.agent)
                })}
            </h2>
            <span className="sub-header-info normal-font-size">
              {commissionsPage.batch && t("commissions.batch.titles.batchDetail", { name: commissionsPage.batch.name })}
            </span>
          </BackNavigationArrow>
        }
      >
        <Row justify="center">
          <Col flex="1000px">
            <BatchSpecialCommissionFilterView
              filter={commissionsPage}
              onReportSwitch={handleReportSwitch}
              onFilterSubmit={handleFilterSubmit}
            />
          </Col>
        </Row>
      </Card>

      <Card className="card-box">
        <BatchSpecialCommissionTableView
          commissionsPage={commissionsPage}
          onPageChange={handleTablePageChange}
          onExcludeClick={commission => setCommissionToExclude(commission)}
          onInclude={actions.includeSpecialCommission}
        />
      </Card>

      {commissionsPage.batch && commissionsPage.agent && (
        <SpecialCommissionExcludeForm
          open={!!commissionToExclude}
          batchId={commissionsPage.batch.id}
          agentId={commissionsPage.agent.id}
          commission={commissionToExclude}
          onExclude={actions.excludeSpecialCommission}
          onFormCancel={() => setCommissionToExclude(undefined)}
        />
      )}
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  commissionsPage: selectSpecialCommissionsPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterSpecialCommissions: filterSpecialCommissionsActions.request,
      excludeSpecialCommission: excludeSpecialCommissionActions.request,
      includeSpecialCommission: includeSpecialCommissionActions.request,
      deleteStateSpecialCommissionsPage: deleteStateSpecialCommissionsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CommissionsBatchSpecialCommissionsContainer);
