import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { QueryKeys, URLQuery } from "../../../../common/utils/queryUtils";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { TravelCalcDraftList } from "../../calcs/travel/types";
import { CALC_ROUTE_PATHS } from "../../paths";
import { CalcDraftTableViewProps } from "../types";
import CalcDraftAttachmentsTableView from "./CalcDraftAttachmentsTableView";

const TravelCalcDraftTableView = ({ draftsPage, ...props }: CalcDraftTableViewProps) => {
  const columns: ColumnsType<TravelCalcDraftList> = [
    {
      key: "clientName",
      title: t("calc.draft.attrs.clientName"),
      width: 280,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.clientName}</Ellipsis>
    },
    {
      key: "insuranceType",
      title: t("calc.travel.enums.insuranceType._label"),
      width: 170,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{t("calc.travel.enums.insuranceType." + record.insuranceType)}</Ellipsis>
    },
    {
      key: "territorialValidity",
      title: t("calc.travel.enums.territorialValidity._label"),
      width: 170,
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <Ellipsis>{t("calc.travel.enums.territorialValidity." + record.territorialValidity)}</Ellipsis>
      )
    },
    {
      key: "riskGroup",
      title: t("calc.travel.enums.riskGroup._label"),
      width: 170,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        record.riskGroup && <Ellipsis>{t("calc.travel.enums.riskGroup." + record.riskGroup)}</Ellipsis>
    },
    {
      key: "insuredClientsCount",
      title: t("calc.travel.attrs.clientsData.insuredClientsCount"),
      width: 60,
      render: (_, record) => record.insuredClientsCount
    },
    {
      key: "attachmentsCount",
      title: t("calc.draft.attrs.attachmentsCount"),
      width: 60,
      render: (_, record) => record.attachments?.length || 0
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 190,
      render: (_, record) => (
        <>
          <ActionTextIcon
            path={`${CALC_ROUTE_PATHS.travel.to}${URLQuery.toString(new URLSearchParams({ [QueryKeys.DRAFT_ID]: record.id }))}`}
            target="_blank"
            icon="import"
            color="blue"
            text={t("calc.draft.actions.setToCalcForm")}
          />

          <Divider type="vertical" />

          <Popconfirm
            title={t("calc.draft.titles.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => props.onDelete({ id: record.id })}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Table<TravelCalcDraftList>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.LARGE }}
      dataSource={draftsPage.pageData as TravelCalcDraftList[]}
      expandable={{
        expandedRowRender: record => (
          <CalcDraftAttachmentsTableView
            calcDraft={record}
            onUpload={props.onUploadCalcDraftAttachments}
            onDelete={props.onDeleteCalcDraftAttachment}
          />
        )
      }}
      pagination={{
        ...paginationTableProps,
        current: draftsPage.pageIndex + 1,
        pageSize: draftsPage.pageSize,
        total: draftsPage.totalElementsCount,
        onChange: props.onPageChange
      }}
    />
  );
};

export default TravelCalcDraftTableView;
