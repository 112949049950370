import { Popover } from "antd";
import AntIcon from "../../icons/AntIcon";

interface Props {
  label?: string;
  popoverTitle?: string;
  popoverContent: React.ReactNode;
  className?: string;
}

const LabelWithPopover = ({ label, popoverTitle, popoverContent, className }: Props) => (
  <span className={className}>
    {label && <>{label}&nbsp;</>}
    <Popover title={popoverTitle ? <b>{popoverTitle}</b> : undefined} content={popoverContent}>
      <span>
        <AntIcon type="question" className="cursor-help" />
      </span>
    </Popover>
  </span>
);

export default LabelWithPopover;
