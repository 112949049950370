import { Tag } from "antd";
import t from "../../../../app/i18n";

interface Props {
  style?: React.CSSProperties;
}

const SpecialCommissionsSettingsRuleSuspendedTag = ({ style }: Props) => (
  <Tag color="warning">
    <span style={{ textTransform: "capitalize", ...style }}>{t("commissions.special.attrs.suspended")}</span>
  </Tag>
);

export default SpecialCommissionsSettingsRuleSuspendedTag;
