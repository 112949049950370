import t from "../../../../../../../app/i18n";
import {
  formatLifeInsuranceTariff,
  formatLocaleCurrency,
  formatLocaleDate
} from "../../../../../../../common/utils/formatUtils";
import { ClientsListDrawerView } from "../../../../../../client/components/drawers/ClientsListDrawerView";
import { ClientFormType } from "../../../../../../client/enums";
import { Client } from "../../../../../../client/types";
import { InsuranceContract, LifeInsurance } from "../../../../../types";

interface Props {
  contract: InsuranceContract;
  index: number;
  multipleInsurances: boolean;
  multipleClients: boolean;
}

const LifeInsuranceView = ({ contract, index, multipleInsurances, multipleClients }: Props) => {
  const { insuranceData, ...insurance } = contract.insurances[index] as LifeInsurance;

  return (
    <>
      <table className="data-table-view">
        <tbody>
          <tr>
            <td>{t("contract.attrs.insurances.tariffId")}:</td>
            <td colSpan={5}>{formatLifeInsuranceTariff(insurance.tariff)}</td>
          </tr>
        </tbody>
      </table>

      <table className="data-table-view">
        <tbody>
          {multipleInsurances && (
            <>
              <tr>
                <td>{t("contract.attrs.insurances.contractEntryDateLife")}:</td>
                <td>{formatLocaleDate(insurance.contractEntryDate)}</td>
                <td>{t("contract.attrs.insurances.annualPremium")}:</td>
                <td className="right-align">{formatLocaleCurrency(insurance.annualPremium)}</td>
                <td colSpan={2} />
              </tr>
              <tr>
                <td>{t("contract.attrs.insurances.contractWithdrawalDateLife")}:</td>
                <td>{formatLocaleDate(insurance.contractWithdrawalDate)}</td>
                <td>{t("contract.attrs.insurances.partialPremium")}:</td>
                <td className="right-align">{formatLocaleCurrency(insurance.partialPremium)}</td>
                <td colSpan={2} />
              </tr>
            </>
          )}
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.insurancePeriod")}:</td>
            <td>{insuranceData.insurancePeriod}</td>
            <td>{t("contract.attrs.insurances.insuranceData.insuranceAmount")}:</td>
            <td className="right-align">{formatLocaleCurrency(insuranceData.insuranceAmount)}</td>
            <td colSpan={2} />
          </tr>
        </tbody>
      </table>

      {multipleClients && (
        <ClientsListDrawerView
          className="margin-top-medium"
          clientsData={[
            {
              client: contract.clients[insurance.insuredClientIndex] as Client,
              clientFormTypes: [ClientFormType.INSURED]
            }
          ]}
        />
      )}
    </>
  );
};

export default LifeInsuranceView;
