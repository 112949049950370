import { Button, Card, Col, Form, Input, Radio, Row, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { resolveFormValidationError, selectTagsStandardProps } from "../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import InstitutionSelect from "../../../../enumerations/components/form/InstitutionSelect";
import PostponementReasonTag from "../../../batch/components/PostponementReasonTag";
import { PostponementReason, postponementReasonTMap } from "../../../batch/enums";
import { PostponedCommissionsReport } from "../../enums";
import { PostponedCommissionsFilterPageRequest, PostponedCommissionsFilterPageResult } from "../../types";

interface Props {
  filter: PostponedCommissionsFilterPageResult;
  onReportSwitch: (filter: PostponedCommissionsFilterPageRequest) => void;
  onFilterSubmit: (filter: PostponedCommissionsFilterPageRequest) => void;
}

const PostponedCommissionsFilterView = ({ filter, onReportSwitch, onFilterSubmit }: Props) => {
  const [currentReport, setCurrentReport] = useState<PostponedCommissionsReport>(PostponedCommissionsReport.UNRESOLVED);

  const [form] = Form.useForm<PostponedCommissionsFilterPageRequest>();

  useEffect(() => {
    form.setFieldsValue({
      keyword: filter.keyword,
      postponementReasons: filter.postponementReasons || [],
      institutionIds: filter.institutionIds || []
    });

    if (filter.report) {
      setCurrentReport(filter.report);
    }
  }, [filter, form]);

  const colSpan = 6;

  const handleReportSwitch = (report: PostponedCommissionsReport): void => {
    form
      .validateFields()
      .then(values => {
        onReportSwitch({ ...values, report });
        setCurrentReport(report);
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Card className="card-box" title={<h2>{t("commissions.postponed.titles.list")}</h2>}>
      <Row justify="center">
        <Col flex="1000px">
          <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="PostponedCommissionsFilterForm">
            <Row gutter={rowGutter} className="margin-bottom-medium">
              <Col span={24} className="center-align">
                <Radio.Group
                  size="large"
                  buttonStyle="solid"
                  value={currentReport}
                  onChange={e =>
                    handleReportSwitch(PostponedCommissionsReport[e.target.value as PostponedCommissionsReport])
                  }
                >
                  {Object.keys(PostponedCommissionsReport).map(report => (
                    <Tooltip title={t("commissions.postponed.enums.report." + report + "_tooltip")} key={report}>
                      <Radio.Button value={report} key={report}>
                        {t("commissions.postponed.enums.report." + report)}
                      </Radio.Button>
                    </Tooltip>
                  ))}
                </Radio.Group>
              </Col>
            </Row>
            <Row gutter={rowGutter} justify="center">
              <Col span={colSpan}>
                <Form.Item
                  name="keyword"
                  label={t("common.searchKeyword")}
                  rules={[
                    validations.size(
                      validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                      validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                    )
                  ]}
                >
                  <Input allowClear placeholder={t("commissions.batch.helpers.commissionsSearchHint")} />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name="postponementReasons"
                  label={t("commissions.batch.enums.postponementReason._label")}
                  rules={[validations.none]}
                >
                  <Select
                    {...selectTagsStandardProps(postponementReasonTMap)}
                    allowClear
                    mode="multiple"
                    maxTagCount="responsive"
                    tagRender={props => (
                      <PostponementReasonTag
                        reason={props.value}
                        closable={props.closable}
                        onClose={props.onClose}
                        label={props.label}
                      />
                    )}
                    options={Object.values(PostponementReason).map(reason => ({
                      value: reason,
                      label: <PostponementReasonTag reason={reason} />
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <InstitutionSelect
                  formItemProps={{
                    name: "institutionIds",
                    label: t("common.institution"),
                    rules: [validations.none]
                  }}
                  selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
                  optionsProps={{ groupByType: true }}
                />
              </Col>

              <Col flex="110px" className="no-title-space">
                <Form.Item>
                  <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                    {t("common.filter")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default PostponedCommissionsFilterView;
