import { Popover, Tag } from "antd";
import t from "../../../app/i18n";
import ActionTextIcon from "../../../common/components/icons/ActionTextIcon";
import { cssVariables } from "../../../common/utils/utils";
import { ContractVerificationStatus } from "../enums";

interface Props {
  status?: ContractVerificationStatus;
  note?: string;
  closable?: boolean;
  style?: React.CSSProperties;
  onNoteChangeClick?: () => void;
  onSendClick?: () => void;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  label?: React.ReactNode;
}

const ContractVerificationStatusTag = ({
  status,
  note,
  closable,
  style,
  onNoteChangeClick,
  onSendClick,
  onClose,
  label
}: Props) => {
  let tagColor;

  switch (status) {
    case ContractVerificationStatus.TYPED:
      tagColor = cssVariables.colorYellow;
      break;
    case ContractVerificationStatus.SENT:
      tagColor = "warning";
      break;
    case ContractVerificationStatus.INTERNAL_INTERVENTION:
      tagColor = cssVariables.colorRed;
      break;
    case ContractVerificationStatus.EXTERNAL_INTERVENTION:
      tagColor = cssVariables.colorRed;
      break;
    case ContractVerificationStatus.NOT_ACCEPTED:
      tagColor = "black";
      break;
    case ContractVerificationStatus.VERIFIED:
      tagColor = cssVariables.colorOrange;
      break;
    case ContractVerificationStatus.PAID:
      tagColor = cssVariables.colorGreen;
      break;
  }

  const tag =
    status || label ? (
      <Tag color={tagColor} closable={closable} style={{ ...style }} onClose={onClose}>
        {status ? t("contract.enums.verificationStatus." + status) : label}
      </Tag>
    ) : null;

  if (
    (status === ContractVerificationStatus.SENT ||
      status === ContractVerificationStatus.INTERNAL_INTERVENTION ||
      status === ContractVerificationStatus.EXTERNAL_INTERVENTION) &&
    (note || onNoteChangeClick)
  ) {
    return (
      <Popover
        title={t("contract.helpers.verificationStatusAdditionalInfo")}
        content={
          <>
            <span>{note}</span>
            {onNoteChangeClick && (
              <div className="margin-top-tiny right-align">
                <ActionTextIcon color="green" text={t("common.editNote")} icon="edit" onClick={onNoteChangeClick} />
              </div>
            )}
            {onSendClick && (
              <div className="margin-top-tiny right-align">
                <ActionTextIcon
                  color="green"
                  text={t("contract.actions.sendToBackoffice")}
                  icon="file-done"
                  onClick={onSendClick}
                />
              </div>
            )}
          </>
        }
        overlayStyle={{ maxWidth: 400 }}
      >
        {tag}
      </Popover>
    );
  } else if (onSendClick) {
    return (
      <Popover
        content={
          <ActionTextIcon
            color="green"
            text={t("contract.actions.sendToBackoffice")}
            icon="file-done"
            onClick={onSendClick}
          />
        }
      >
        {tag}
      </Popover>
    );
  }

  return tag;
};

export default ContractVerificationStatusTag;
