import { Col, Form, Input, InputNumber, Modal, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  inputNumberDecimalStandardProps,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import type { UUID } from "../../../../../typings/global";
import { requests } from "../../api";
import { createBailAccountMovementActions, updateBailAccountMovementActions } from "../../ducks";
import { BailAccountMovement, CreateUpdateBailAccountMovement } from "../../types";

interface Props {
  movement?: BailAccountMovement;
  agentId: UUID;
  open: boolean;
  onCreate?: typeof createBailAccountMovementActions.request;
  onUpdate?: typeof updateBailAccountMovementActions.request;
  onFormCancel: () => void;
}

const BailAccountMovementForm = ({ movement, agentId, open, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateBailAccountMovement>();

  useFormErrorHandler(form, "commissions.bailAccount.attrs.movements", [
    requests.CREATE_BAIL_ACCOUNT_MOVEMENT,
    requests.UPDATE_BAIL_ACCOUNT_MOVEMENT
  ]);

  useEffect(() => {
    if (open && movement) {
      form.setFieldsValue({
        optimisticLockVersion: movement.optimisticLockVersion,
        amount: movement.amount,
        note: movement.note
      });
    }
  }, [open, movement, form]);

  const colSpan = 10;

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_BAIL_ACCOUNT_MOVEMENT, requests.UPDATE_BAIL_ACCOUNT_MOVEMENT],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (movement) {
          onUpdate?.({ id1: agentId, id2: movement.id, object: values });
        } else {
          onCreate?.({ id: agentId, object: values });
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={
        movement
          ? t("commissions.bailAccount.titles.updateMovement")
          : t("commissions.bailAccount.titles.createMovement")
      }
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="bailAccountMovementForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="amount"
              label={t("commissions.bailAccount.attrs.movements.amount")}
              rules={[validations.notNull, validations.positiveOrNegativeNumber]}
            >
              <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item name="note" label={t("commissions.bailAccount.attrs.movements.note")} rules={[validations.none]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BailAccountMovementForm;
