import { AutoComplete, Empty, Spin } from "antd";
import { BaseOptionType } from "antd/lib/select";
import debounce from "lodash/debounce";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { pageRulePermissionsMap, Permission } from "../../../../common/security/authorization/enums";
import { RootState } from "../../../../common/types";
import { formatAgentName } from "../../../../common/utils/formatUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { PagesMap } from "../../../../common/utils/navigationUtils";
import { hasAllPermissions, hasAnyPermission, stripAccents } from "../../../../common/utils/utils";
import { AGENT_ROUTE_PATHS } from "../../../../modules/agent/paths";
import { selectPermissions } from "../../../../modules/auth/ducks";
import { CLIENT_ROUTE_PATHS } from "../../../../modules/client/paths";
import { CONTRACT_ROUTE_PATHS } from "../../../../modules/contract/paths";
import { FINANCIAL_MEDIATION_ROUTE_PATHS } from "../../../../modules/financialmediation/paths";
import { requests } from "../../../../modules/globalsearch/api";
import {
  deleteStateSearchResultAction,
  searchGloballyActions,
  selectGlobalSearchResult
} from "../../../../modules/globalsearch/ducks";
import { GlobalSearchType } from "../../../../modules/globalsearch/enums";
import { GlobalSearchResult } from "../../../../modules/globalsearch/types";
import { NavigationGlobalSearchMap } from "../../../../modules/globalsearch/utils";
import { ADMIN_USER_ROUTE_PATHS } from "../../../../modules/user/paths";
import t from "../../../i18n";
import styles from "./HeaderGlobalSearch.module.scss";

const HeaderGlobalSearch = () => {
  const navigate = useNavigate();

  const [autocompleteValue, setAutocompleteValue] = useState<string>();
  const [autocompleteOptions, setAutocompleteOptions] = useState<DefaultOptionType[]>([]);

  const searchResult = useSelector<RootState, GlobalSearchResult>(selectGlobalSearchResult);
  const accountPermissions = useSelector<RootState, Permission[]>(selectPermissions);

  const inProgress = useRequestFinishedCallback([requests.SEARCH_GLOBALLY]);
  const [isComputed, setIsComputed] = useState(false);

  useEffect(() => {
    if (inProgress) {
      setIsComputed(true);
    }
  }, [inProgress]);

  const availablePages = useMemo(
    () =>
      NavigationGlobalSearchMap.filter(item => {
        if (item.config) {
          return hasAllPermissions(accountPermissions, item.config.permissions);
        } else {
          const permissionsObject = pageRulePermissionsMap.get(item.path);
          return (
            !permissionsObject ||
            (permissionsObject.isAnyPermissionRequired
              ? hasAnyPermission(accountPermissions, permissionsObject.permissions)
              : hasAllPermissions(accountPermissions, permissionsObject.permissions))
          );
        }
      }),
    [accountPermissions]
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          searchGlobally: searchGloballyActions.request,
          deleteStateSearchResult: deleteStateSearchResultAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    const result: DefaultOptionType[] = [];

    if (searchResult.clients.length) {
      result.push({
        label: t("globalSearch.category.clients"),
        options: searchResult.clients.map(client => ({
          value: client.id,
          type: GlobalSearchType.CLIENT,
          label: (
            <span className={styles.searchText}>
              <span>{client.aggregatedName}</span>
              <br />
              <span className={styles.searchSubtext}>{client.identifier}</span>
            </span>
          )
        }))
      });
    }

    if (searchResult.contracts.length) {
      result.push({
        label: t("globalSearch.category.contracts"),
        options: searchResult.contracts.map(contract => ({
          value: contract.id,
          type: GlobalSearchType.CONTRACT,
          label: (
            <span className={styles.searchText}>
              <span>
                {contract.contractNumber || contract.secondaryContractNumber} | {contract.policyHolder?.aggregatedName}
              </span>
              <br />
              <span className={styles.searchSubtext}>
                {contract.product.name} | {contract.institution.name}
              </span>
            </span>
          )
        }))
      });
    }

    if (searchResult.mediations?.length) {
      result.push({
        label: t("globalSearch.category.financialMediations"),
        options: searchResult.mediations.map(financialMediation => {
          const secondaryClientAggregatedName = financialMediation.secondaryClient?.aggregatedName;

          return {
            value: financialMediation.id,
            type: GlobalSearchType.FINANCIAL_MEDIATION,
            label: (
              <div className={styles.searchText}>
                <div>
                  {financialMediation.policyHolder.aggregatedName}{" "}
                  {secondaryClientAggregatedName ? `| ${secondaryClientAggregatedName}` : undefined}
                </div>
                <div className={styles.searchSubtext}>
                  {`${t("product.enums.financialSector." + financialMediation.sector)} | ${t("financialMediation.enums.version." + financialMediation.version)}`}
                </div>
              </div>
            )
          };
        })
      });
    }

    if (searchResult.agents.length) {
      result.push({
        label: t("globalSearch.category.agents"),
        options: searchResult.agents.map(agent => ({
          value: agent.id,
          type: GlobalSearchType.AGENT,
          label: (
            <span className={styles.searchText}>
              <span>{formatAgentName(agent)}</span>
              <br />
              <span className={styles.searchSubtext}>{agent.identifier}</span>
            </span>
          )
        }))
      });
    }

    if (searchResult.users.length) {
      result.push({
        label: t("globalSearch.category.users"),
        options: searchResult.users.map(user => ({
          value: user.id,
          type: GlobalSearchType.USER,
          label: (
            <span className={styles.searchText}>
              <span>{user.name}</span>
              <br />
              <span className={styles.searchSubtext}>{user.email || user.emailToConfirm}</span>
            </span>
          )
        }))
      });
    }

    const strippedKeyword = searchResult.keyword ? stripAccents(searchResult.keyword).toLowerCase() : undefined;
    const foundPages = strippedKeyword ? availablePages.filter(page => page.keyword.includes(strippedKeyword)) : [];

    if (foundPages.length) {
      result.push({
        label: t("globalSearch.category.navigation"),
        options: foundPages.map(page => ({
          value: page.path,
          type: GlobalSearchType.NAVIGATION,
          label: (
            <span className={styles.searchText}>
              <span>{page.config?.title || PagesMap.get(page.path)?.title}</span>
            </span>
          )
        }))
      });
    }

    setAutocompleteOptions(result);
    setIsComputed(false);
  }, [searchResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAutocompleteSearchDebounced = useMemo(
    () =>
      debounce((keyword: string): void => {
        if (keyword?.length >= 3) {
          actions.searchGlobally({ keyword });
        } else {
          setAutocompleteOptions([]);
        }
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleAutocompleteSearch = useCallback(
    (value: string): void => {
      handleAutocompleteSearchDebounced(value);
    },
    [handleAutocompleteSearchDebounced]
  );

  const handleAutocompleteSelect = (value: string, option: BaseOptionType): void => {
    switch (option.type) {
      case GlobalSearchType.CLIENT:
        navigate(generatePath(CLIENT_ROUTE_PATHS.detail.to, { id: value }));
        break;
      case GlobalSearchType.CONTRACT:
        navigate(generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: value }));
        break;
      case GlobalSearchType.AGENT:
        navigate(generatePath(AGENT_ROUTE_PATHS.detail.to, { id: value }));
        break;
      case GlobalSearchType.USER:
        navigate(generatePath(ADMIN_USER_ROUTE_PATHS.detail.to, { id: value }));
        break;
      case GlobalSearchType.NAVIGATION:
        navigate(generatePath(value));
        break;
      case GlobalSearchType.FINANCIAL_MEDIATION:
        navigate(generatePath(FINANCIAL_MEDIATION_ROUTE_PATHS.detail.to, { id: value }));
        break;
    }

    handleAutocompleteClear();
  };

  const handleAutocompleteClear = (): void => {
    setAutocompleteOptions([]);
    setAutocompleteValue(undefined);
    actions.deleteStateSearchResult();
  };

  return (
    <div className={styles.globalSearch}>
      <AutoComplete
        style={{ width: 400 }}
        allowClear
        size="middle"
        value={autocompleteValue}
        options={autocompleteOptions}
        dropdownRender={menu => {
          return isComputed ? (
            <div className="center-align" style={{ margin: 48 }}>
              <Spin spinning size="large" />
            </div>
          ) : (
            <>{menu}</>
          );
        }}
        placeholder={
          <div className="center-align">
            <AntIcon type="search" style={{ cursor: "pointer", fontSize: 16 }} /> {t("globalSearch.placeholder")}
          </div>
        }
        notFoundContent={<Empty description={t("globalSearch.noData")} />}
        getPopupContainer={trigger => trigger.parentElement}
        onChange={setAutocompleteValue}
        onSearch={handleAutocompleteSearch}
        onSelect={handleAutocompleteSelect}
        onClear={handleAutocompleteClear}
      />
    </div>
  );
};

export default HeaderGlobalSearch;
