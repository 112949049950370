import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import AnonymousContentWrapper from "../../../common/modules/wrappers/AnonymousContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import RequestPasswordResetForm from "../components/forms/RequestPasswordResetForm";
import { requestPasswordResetActions } from "../ducks";

interface ActionsMap {
  requestPasswordReset: typeof requestPasswordResetActions.request;
}

const RequestPasswordResetContainer = (props: ActionProps<ActionsMap>) => {
  return (
    <AnonymousContentWrapper>
      <RequestPasswordResetForm onFormSubmit={props.actions.requestPasswordReset} />
    </AnonymousContentWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      requestPasswordReset: requestPasswordResetActions.request
    },
    dispatch
  )
});

export default connect<object, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  null,
  mapDispatchToProps
)(RequestPasswordResetContainer);
