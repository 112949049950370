import { Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes } from "../../../../common/constants";
import {
  phoneNumberNormalizeFunction,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { userRequestPhoneUpdateActions, userUpdatePhoneActions } from "../../ducks";
import { RequestUserPhoneUpdate, UpdateUserPhone, UserProfile } from "../../types";

interface Props {
  open: boolean;
  user: UserProfile;
  onRequestPhoneUpdateFormSubmit: typeof userRequestPhoneUpdateActions.request;
  onUpdatePhoneFormSubmit: typeof userUpdatePhoneActions.request;
  onFormCancel: () => void;
}

type FormState = "request-form" | "request-form-submitted" | "update-form";

const PhoneUpdateForm = ({
  open,
  user,
  onRequestPhoneUpdateFormSubmit,
  onUpdatePhoneFormSubmit,
  onFormCancel
}: Props) => {
  const [formState, setFormState] = useState<FormState>("request-form");

  const [form] = Form.useForm<RequestUserPhoneUpdate | UpdateUserPhone>();
  useFormErrorHandler(form, "user.attrs", [requests.USER_REQUEST_PHONE_UPDATE, requests.USER_UPDATE_PHONE]);

  useEffect(() => {
    if (open && user && formState === "request-form") {
      form.setFieldsValue({
        optimisticLockVersion: user.optimisticLockVersion,
        phoneToConfirm: undefined,
        password: undefined
      });
    }
  }, [open, user, formState, form]);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (formState === "request-form" || formState === "request-form-submitted") {
          setFormState("request-form-submitted");
          onRequestPhoneUpdateFormSubmit({
            id: user.id,
            object: values as RequestUserPhoneUpdate
          });
        } else if (formState === "update-form") {
          onUpdatePhoneFormSubmit({
            id: user.id,
            object: values as UpdateUserPhone
          });
        }
      })
      .catch(resolveFormValidationError);
  };

  const requestPhoneUpdateInProgress = useRequestFinishedCallback([requests.USER_REQUEST_PHONE_UPDATE], () =>
    setFormState(formState === "request-form-submitted" ? "update-form" : "request-form")
  );

  const updatePhoneInProgress = useRequestFinishedCallback([requests.USER_UPDATE_PHONE], () => {
    if (formState === "update-form") {
      onFormCancel();
    }
  });

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t("user.titles.updatePhone")}
      okText={formState === "update-form" ? t("common.save") : t("user.actions.verifyTotpCodeUsingSms")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={requestPhoneUpdateInProgress || updatePhoneInProgress}
      afterClose={() => {
        form.resetFields();
        setFormState("request-form");
      }}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="userPhoneUpdateForm">
        {(formState === "request-form" || formState === "request-form-submitted") && (
          <>
            <HiddenInput name="optimisticLockVersion" />
            <Form.Item
              name="phoneToConfirm"
              label={t("user.attrs.phoneToConfirm")}
              rules={[validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber]}
              normalize={phoneNumberNormalizeFunction}
            >
              <Input />
            </Form.Item>

            <Form.Item name="password" label={t("user.attrs.previousPassword")} rules={[validations.notBlank]}>
              <Input.Password />
            </Form.Item>
          </>
        )}
        {formState === "update-form" && (
          <Form.Item
            name="smsTotpCode"
            label={t("user.attrs.smsTotpCode")}
            rules={[validations.notBlank, validations.length(6), validations.numeric]}
          >
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default PhoneUpdateForm;
