import { Card, Col, Divider, Form, Input, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../../app/i18n";
import ActionButton from "../../../../../../common/components/buttons/ActionButton";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../common/constants";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { FieldConstraintViolation, RootState } from "../../../../../../common/types";
import { resolveFormValidationError } from "../../../../../../common/utils/formUtils";
import { tInterval } from "../../../../../../common/utils/translationUtils";
import { isDefined } from "../../../../../../common/utils/utils";
import { validationFunctions, validations } from "../../../../../../common/utils/validationUtils";
import type { UUID } from "../../../../../../typings/global";
import { selectHasPermissions } from "../../../../../auth/ducks";
import ClientDrawerForm from "../../../../../client/components/drawers/ClientDrawerForm";
import ClientSearchInput from "../../../../../client/components/search/ClientSearchInput";
import { ClientFormStage, ClientSearchActionType } from "../../../../../client/enums";
import { Client } from "../../../../../client/types";
import { useClientSearch } from "../../../../../client/utils";
import InstitutionSelect from "../../../../../enumerations/components/form/InstitutionSelect";
import ProductGroupSelect from "../../../../../enumerations/components/form/ProductGroupSelect";
import ProductSelect from "../../../../../enumerations/components/form/ProductSelect";
import { selectProductsClassificationEnums } from "../../../../../enumerations/ducks";
import { InstitutionWithProducts } from "../../../../../enumerations/types";
import { InstitutionType } from "../../../../../institution/enums";
import { ProductFinancialSector } from "../../../../../product/enums";
import { ProductBase } from "../../../../../product/types";
import { InsuranceType } from "../../../../enums";
import {
  CreateUpdateInsurance,
  CreateUpdateInsuranceContract,
  CreateUpdateLifeInsurance,
  CreateUpdateVehicleInsurance,
  InsuranceContract
} from "../../../../types";
import { isContractVerified, VEHICLE_INSURANCE_TYPES } from "../../../../utils";

interface Props {
  initialContract?: InsuranceContract;
  form: FormInstance<CreateUpdateInsuranceContract>;
  clients: (Client | undefined)[];
  clientsViolationErrors: Map<number, FieldConstraintViolation[]>;
  insurancesCount: number;
  onClientChange(index: number, client?: Client): void;
  onClientDelete(index: number): void;
  onClientViolationErrorsChange(errorsMap: Map<number, FieldConstraintViolation[]>): void;
}

const InsuranceContractFormHeaderSection = ({ initialContract, form, clients, ...props }: Props) => {
  const clientSearch = useClientSearch();

  const productsClassification = useSelector<RootState, InstitutionWithProducts[]>(selectProductsClassificationEnums);

  const hasPrivilegedChangesPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INSURANCE)(state)
  );

  const disablePrivilegedInputs = isContractVerified(initialContract) && !hasPrivilegedChangesPermission;

  const [clientFormOpen, setClientFormOpen] = useState<boolean>(false);
  const [clientsCount, setClientsCount] = useState<number>(initialContract?.clients.length || 1);
  const [processedClientIndex, setProcessedClientIndex] = useState<number>();
  const [clientStages, setClientStages] = useState<(ClientFormStage | undefined)[]>(
    initialContract?.clients.map(() => ClientFormStage.SELECTED) || []
  );

  const initialProductGroup = initialContract
    ? productsClassification
        .flatMap(i => i.productGroups)
        .find(group => group.products.some(p => p.id === initialContract.product.id))
    : undefined;

  useEffect(() => {
    if (
      isDefined<number>(processedClientIndex) &&
      clientSearch.result.keyword === form.getFieldValue(["clientIdentifiers", processedClientIndex])
    ) {
      if (clientSearch.result.data) {
        setClientStage(processedClientIndex, ClientFormStage.EXISTING);
        props.onClientChange(processedClientIndex, clientSearch.result.data);
      } else {
        setClientStage(processedClientIndex, ClientFormStage.NEW);
      }
    }
  }, [clientSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInstitutionIdChange = (institutionId: UUID): void => {
    const institution = productsClassification.find(institution => institution.id === institutionId);
    const contract = form.getFieldsValue([
      ["productGroupId"],
      ["productId"],
      ["insurances"]
    ]) as CreateUpdateInsuranceContract;

    const productGroup = contract.productGroupId
      ? institution?.productGroups.find(group => group.id === contract.productGroupId)
      : undefined;

    form.setFieldsValue({
      productGroupId: productGroup ? contract.productGroupId : undefined,
      productId:
        productGroup && productGroup.products.find(product => product.id === contract.productId)
          ? contract.productId
          : undefined,
      insurances: (contract.insurances || []).map(insurance => {
        if (insurance.type && VEHICLE_INSURANCE_TYPES.includes(insurance.type)) {
          const updatedInsurance = { ...(insurance as CreateUpdateVehicleInsurance) };
          delete updatedInsurance.coverageLimitId;
          delete updatedInsurance.complicityId;
          return updatedInsurance;
        }
        return insurance;
      })
    });
  };

  const handleProductGroupChange = (): void => {
    form.setFieldsValue({ productId: undefined });
  };

  const handleProductChange = (product: ProductBase): void => {
    const insurances = [...(form.getFieldValue(["insurances"]) || [])];

    [...Array(props.insurancesCount)].forEach((_, index) => {
      let updatedInsurance = { ...insurances[index] };

      if (!updatedInsurance.id) {
        updatedInsurance = { ...updatedInsurance, type: product.insuranceType };
      }

      if (updatedInsurance.type === InsuranceType.LIFE) {
        updatedInsurance = { ...updatedInsurance, tariffId: undefined } as CreateUpdateLifeInsurance;
      }

      insurances[index] = updatedInsurance;
    });

    form.setFieldsValue({ insurances: insurances as CreateUpdateInsurance[] });
  };

  const handleClientSearchActionClick = (index: number, actionType: ClientSearchActionType): void => {
    switch (actionType) {
      case ClientSearchActionType.CREATE:
      case ClientSearchActionType.UPDATE:
        setProcessedClientIndex(index);
        setClientFormOpen(true);
        break;
      case ClientSearchActionType.DELETE:
        if (index === 0) {
          const updatedIdentifiers = [...(form.getFieldValue("clientIdentifiers") || [])];
          deselectClientOnInsurances(updatedIdentifiers[index]);
          updatedIdentifiers[index] = undefined;
          form.setFieldsValue({ clientIdentifiers: updatedIdentifiers });

          const updatedClientStages = [...clientStages];

          updatedClientStages[index] = undefined;
          setClientStages(updatedClientStages);

          deleteClientViolationErrors(index);
          setProcessedClientIndex(undefined);
          props.onClientChange(index);
        } else {
          const updatedIdentifiers = [...(form.getFieldValue("clientIdentifiers") || [])];
          const deletedIdentifiers = updatedIdentifiers.splice(index, 1);
          deselectClientOnInsurances(deletedIdentifiers[0]);
          form.setFieldsValue({ clientIdentifiers: updatedIdentifiers });

          const updatedClientStages = [...clientStages];
          updatedClientStages.splice(index, 1);
          setClientStages(updatedClientStages);

          const updatedViolationsErrors = new Map<number, FieldConstraintViolation[]>();
          props.clientsViolationErrors.forEach((value, key) => {
            if (key < index) {
              updatedViolationsErrors.set(key, value);
            } else if (key > index) {
              updatedViolationsErrors.set(key - 1, value);
            }
          });
          props.onClientViolationErrorsChange(updatedViolationsErrors);

          setProcessedClientIndex(undefined);
          setClientsCount(clientsCount - 1);
          props.onClientDelete(index);
        }
        break;
    }
  };

  const handleClientSearchSubmit = (value: string, index: number): void => {
    form
      .validateFields([["clientIdentifiers", index]])
      .then(() => clientSearch.onSearch({ keyword: value }))
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string, index: number): void => {
    if (clientStages[index]) {
      setClientStage(index, index === 0 ? undefined : ClientFormStage.EMPTY);
    }

    const identifier = clients[index]?.identifier;
    if (clients[index] && identifier) {
      deselectClientOnInsurances(identifier);
      props.onClientChange(index);
    }
    if (props.clientsViolationErrors.has(index)) {
      deleteClientViolationErrors(index);
    }

    handleClientSearchSubmit(value, index);
  };

  const handleClientAdd = (): void => {
    setClientStage(clientsCount, ClientFormStage.EMPTY);
    setClientsCount(clientsCount + 1);
  };

  const handleClientFormSubmit = (client: Client, index: number): void => {
    setClientFormOpen(false);
    setProcessedClientIndex(undefined);
    setClientStage(index, ClientFormStage.SELECTED);
    deleteClientViolationErrors(index);
    props.onClientChange(index, client);
  };

  const getClientFormIdentifier = (index: number): string => {
    return (form.getFieldValue("clientIdentifiers") || [])[index];
  };

  const setClientStage = (index: number, stage?: ClientFormStage): void => {
    if (stage) {
      const updatedClientStages = [...clientStages];
      updatedClientStages[index] = stage;
      setClientStages(updatedClientStages);
    }
  };

  const deselectClientOnInsurances = (identifier: string): void => {
    if (identifier && validationFunctions.validatePinOrCrn(identifier)) {
      const contract = form.getFieldsValue(["insurances"]) as CreateUpdateInsuranceContract;

      form.setFieldsValue({
        insurances: (contract.insurances || []).map(formInsurance => {
          const insurance = { ...formInsurance } as CreateUpdateInsurance;

          if ("insuredClientIdentifier" in insurance) {
            insurance["insuredClientIdentifier"] =
              insurance["insuredClientIdentifier"] === identifier ? undefined : insurance["insuredClientIdentifier"];
          }

          if ("vehicleHolderIdentifier" in insurance || "vehicleOwnerIdentifier" in insurance) {
            insurance["vehicleHolderIdentifier"] =
              insurance["vehicleHolderIdentifier"] === identifier ? undefined : insurance["vehicleHolderIdentifier"];

            insurance["vehicleOwnerIdentifier"] =
              insurance["vehicleOwnerIdentifier"] === identifier ? undefined : insurance["vehicleOwnerIdentifier"];
          }

          if ("vinculationClientIdentifier" in insurance) {
            insurance["vinculationClientIdentifier"] =
              insurance["vinculationClientIdentifier"] === identifier
                ? undefined
                : insurance["vinculationClientIdentifier"];
          }

          return insurance;
        })
      });
    }
  };

  const deleteClientViolationErrors = (index: number): void => {
    const updatedViolationsErrors = new Map<number, FieldConstraintViolation[]>([...props.clientsViolationErrors]);
    updatedViolationsErrors.delete(index);
    props.onClientViolationErrorsChange(updatedViolationsErrors);
  };

  const colSpan = 4;
  const clientInputColSpan = 5;
  const clientNameColSpan = 6;

  const clientSearchProps = {
    processedType: processedClientIndex,
    violationErrors: props.clientsViolationErrors,
    inProgress: clientSearch.inProgress,
    inputColSpan: clientInputColSpan,
    clientNameColSpan,
    onActionClick: handleClientSearchActionClick,
    onFocus: setProcessedClientIndex,
    onSearch: handleClientSearchSubmit,
    onChange: handleClientSearchChange
  };

  return (
    <>
      <Card type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractIdentification")}>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="contractNumber"
              label={t("contract.attrs.contractNumber")}
              dependencies={["secondaryContractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull("secondaryContractNumber", t("contract.attrs.proposalNumber")),
                validations.notEqualTo("secondaryContractNumber", t("contract.attrs.proposalNumber"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="secondaryContractNumber"
              label={t("contract.attrs.proposalNumber")}
              dependencies={["contractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull("contractNumber", t("contract.attrs.contractNumber")),
                validations.notEqualTo("contractNumber", t("contract.attrs.contractNumber"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionId",
                label: t("common.insuranceInstitution"),
                rules: [validations.notNull]
              }}
              selectProps={{ disabled: disablePrivilegedInputs, onChange: handleInstitutionIdChange }}
              optionsProps={{
                selected: initialContract ? [initialContract.institution] : undefined,
                filterType: InstitutionType.INSURANCE_COMPANY
              }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.institutionId !== next.institutionId}>
              {({ getFieldValue }) => {
                const institutionId = getFieldValue("institutionId");
                return (
                  <ProductGroupSelect
                    formItemProps={{
                      name: "productGroupId",
                      label: t("contract.attrs.productGroupId"),
                      rules: [validations.notNull]
                    }}
                    optionsProps={{
                      selectedProduct: initialContract?.product,
                      institutionId,
                      hideAll: !institutionId,
                      initialValuesSelected: initialContract?.institution.id === institutionId,
                      filterSectors: [
                        ProductFinancialSector.LIFE_INSURANCES,
                        ProductFinancialSector.NON_LIFE_INSURANCES
                      ]
                    }}
                    selectProps={{
                      placeholder: !institutionId ? t("contract.helpers.productGroupPlaceholder") : undefined,
                      disabled: disablePrivilegedInputs,
                      onChange: handleProductGroupChange
                    }}
                  />
                );
              }}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.productGroupId !== next.productGroupId || prev.institutionId !== next.institutionId
              }
            >
              {({ getFieldValue }) => {
                const institutionId = getFieldValue("institutionId");
                const groupId = getFieldValue("productGroupId");
                return (
                  <ProductSelect
                    formItemProps={{
                      name: "productId",
                      label: t("contract.attrs.productId"),
                      rules: [validations.notNull]
                    }}
                    optionsProps={{
                      selectedInTree: {
                        selected: initialContract?.product,
                        condition:
                          initialContract?.institution.id === institutionId && initialProductGroup?.id === groupId
                      },
                      institutionId,
                      productGroupId: groupId,
                      hideAll: !groupId,
                      filterSectors: [
                        ProductFinancialSector.LIFE_INSURANCES,
                        ProductFinancialSector.NON_LIFE_INSURANCES
                      ],
                      onChange: handleProductChange
                    }}
                    selectProps={{
                      disabled: disablePrivilegedInputs,
                      placeholder: !groupId ? t("contract.helpers.productPlaceholder") : undefined
                    }}
                  />
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{t("contract.sections.clients")}</Divider>

        <Row gutter={rowGutter}>
          {[...Array(clientsCount)].map((_, index) => (
            <React.Fragment key={index}>
              <ClientSearchInput<number>
                {...clientSearchProps}
                formItemProps={{
                  name: ["clientIdentifiers", index],
                  label: (
                    <LabelWithTooltip
                      tooltip={t("helpers.pinFormat")}
                      label={tInterval("contract.attrs.insuranceClientIdentifiers_interval", index)}
                    />
                  ),
                  rules: [validations.notBlank, validations.pinOrCrn, validations.noRepeatedClient("clientIdentifiers")]
                }}
                formStage={clientStages[index]}
                formType={index}
                client={clients[index]}
              />

              <Col span={index === 0 ? 13 : 1} />
            </React.Fragment>
          ))}
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <ActionButton icon="plus" onClick={handleClientAdd}>
              {t("contract.actions.addClient")}
            </ActionButton>
          </Col>
        </Row>
      </Card>

      <ClientDrawerForm<number>
        open={clientFormOpen}
        client={isDefined<number>(processedClientIndex) ? clients[processedClientIndex] : undefined}
        initialIdentifier={
          isDefined<number>(processedClientIndex) ? getClientFormIdentifier(processedClientIndex) : undefined
        }
        formType={processedClientIndex}
        violationErrors={props.clientsViolationErrors}
        placement="contract"
        onFormSubmit={handleClientFormSubmit}
      />
    </>
  );
};

export default InsuranceContractFormHeaderSection;
