import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { useTranslation } from "react-i18next";
import { Country } from "../../../../common/modules/enums";
import { selectStandardProps } from "../../../../common/utils/formUtils";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
}

const CountrySelect = ({ formItemProps, selectProps }: Props) => {
  const { t } = useTranslation();
  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps}
        {...selectProps}
        options={Object.keys(Country).map(country => ({
          value: country,
          label: t(`address.enums.country.${country as Country}`)
        }))}
      />
    </Form.Item>
  );
};

export default CountrySelect;
