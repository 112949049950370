import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import { RootState } from "../../../../common/types";
import type { UUID } from "../../../../typings/global";
import { selectVehicleBrandsEnums, VEHICLE_OTHER_MAPPING_NAME } from "../../ducks";
import { VehicleBrandWithModels } from "../../types";

interface Props {
  brandId: UUID;
  modelId: UUID;
  otherModelName?: string;
}

const VehicleBrandEnumerationsName = ({ brandId, modelId, otherModelName }: Props) => {
  const vehicleBrandsEnums = useSelector<RootState, VehicleBrandWithModels[]>(selectVehicleBrandsEnums);

  const model = vehicleBrandsEnums.find(brand => brand.id === brandId)?.models.find(model => model.id === modelId);
  return model ? (
    model.name === VEHICLE_OTHER_MAPPING_NAME && otherModelName ? (
      <>{`${model.name} (${otherModelName})`}</>
    ) : (
      <>{model.name}</>
    )
  ) : (
    <>{t("vehicle.helpers.unknownModel")}</>
  );
};

export default VehicleBrandEnumerationsName;
