import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Dropdown, Modal } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { useTranslation } from "react-i18next";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { deleteDashboardContactActions } from "../../../../ducks";
import { DashboardContact } from "../../../../types";
import styles from "./DashboardContactActionsView.module.scss";

const ACTION = {
  UPDATE: "update",
  DELETE: "delete"
};

interface Props {
  contact: DashboardContact;
  onUpdate: (contact: DashboardContact) => void;
  onDelete: typeof deleteDashboardContactActions.request;
}

export const DashboardContactActionsView = ({ onDelete, onUpdate, contact }: Props) => {
  const { t } = useTranslation();

  const handleMenuDropdownClick = (clickInfo: MenuInfo): void => {
    switch (clickInfo.key) {
      case ACTION.UPDATE:
        onUpdate(contact);
        break;
      case ACTION.DELETE:
        Modal.confirm({
          title: t("dashboard.contacts.helpers.deleteConfirm"),
          icon: <ExclamationCircleOutlined />,
          okText: t("common.yes"),
          cancelText: t("common.no"),
          okType: "danger",
          onOk: () => {
            onDelete({ id: contact.id });
          }
        });
        break;
    }
  };

  return (
    <div className={styles.dashboardContactActions}>
      <Dropdown
        trigger={["click"]}
        placement="bottomRight"
        menu={{
          items: [
            { key: ACTION.UPDATE, label: <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} /> },
            { key: ACTION.DELETE, label: <ActionTextIcon icon="delete" color="red" text={t("common.delete")} /> }
          ],
          onClick: handleMenuDropdownClick
        }}
      >
        <div className={styles.dashboardContactActionsSettings}>
          <AntIcon type="more" />
        </div>
      </Dropdown>
    </div>
  );
};
