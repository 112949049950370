import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import apiService from "../../../common/api/apiService";
import { EntityIdObject, EntityObject } from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import {
  CertificateVehicleCategory,
  CertificateVehicleCategoryFilterPageRequest,
  CertificateVehicleCategoryFilterPageResult,
  CreateUpdateCertificateVehicleCategory
} from "./types";

const FILTER_VEHICLE_CATEGORIES = ApiRequestAdapter.create("/vehicle-categories", "GET");
const CREATE_VEHICLE_CATEGORY = ApiRequestAdapter.create("/vehicle-categories", "POST");
const UPDATE_VEHICLE_CATEGORY = ApiRequestAdapter.create("/vehicle-categories/{0}", "PUT");
const DELETE_VEHICLE_CATEGORY = ApiRequestAdapter.create("/vehicle-categories/{0}", "DELETE");

export const requests = {
  FILTER_VEHICLE_CATEGORIES,
  CREATE_VEHICLE_CATEGORY,
  UPDATE_VEHICLE_CATEGORY,
  DELETE_VEHICLE_CATEGORY
};

const api = {
  filterVehicleCategories: (
    request: CertificateVehicleCategoryFilterPageRequest
  ): AxiosPromise<CertificateVehicleCategoryFilterPageResult> => {
    return apiService.get(FILTER_VEHICLE_CATEGORIES.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createVehicleCategory: (
    request: CreateUpdateCertificateVehicleCategory
  ): AxiosPromise<CertificateVehicleCategory> => {
    return apiService.post(CREATE_VEHICLE_CATEGORY.url, request);
  },
  updateVehicleCategory: (
    request: EntityObject<CreateUpdateCertificateVehicleCategory>
  ): AxiosPromise<CertificateVehicleCategory> => {
    return apiService.put(UPDATE_VEHICLE_CATEGORY.getParametrizedUrl(request.id), request.object);
  },
  deleteVehicleCategory: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_VEHICLE_CATEGORY.getParametrizedUrl(request.id));
  }
};

export default api;
