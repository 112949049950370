import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../../common/constants";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../../ducks";
import { CalcType } from "../../enums";
import CalcBlacklistForm from "../components/forms/CalcBlacklistForm";
import CalcBlacklistFilterView from "../components/views/CalcBlacklistFilterView";
import CalcBlacklistTableView from "../components/views/CalcBlacklistTableView";
import {
  createCalcBlacklistActions,
  deleteCalcBlacklistActions,
  deleteStateCalcBlacklistsPageAction,
  filterCalcBlacklistsActions,
  selectCalcBlacklistsCurrentPage,
  updateCalcBlacklistActions
} from "../ducks";
import { CalcBlacklist, CalcBlacklistFilterPageRequest, CalcBlacklistFilterPageResult } from "../types";

interface StateProps {
  calcBlacklistsCurrentPage: CalcBlacklistFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterCalcBlacklists: typeof filterCalcBlacklistsActions.request;
  createCalcBlacklist: typeof createCalcBlacklistActions.request;
  updateCalcBlacklist: typeof updateCalcBlacklistActions.request;
  deleteCalcBlacklist: typeof deleteCalcBlacklistActions.request;
  deleteStateCalcBlacklistsPage: typeof deleteStateCalcBlacklistsPageAction;
}

const CalcBlacklistContainer = ({
  calcBlacklistsCurrentPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [calcBlacklistFormOpen, setCalcBlacklistFormOpen] = useState<boolean>(false);
  const [calcBlacklistToUpdate, setCalcBlacklistToUpdate] = useState<CalcBlacklist>();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);

    actions.filterCalcBlacklists({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      calcType: CalcType[urlParams.get("calcType") as keyof typeof CalcType],
      institutionIds: urlParams.getAll("institutionIds"),
      agentIds: urlParams.getAll("agentIds")
    });

    return () => {
      actions.deleteStateCalcBlacklistsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateClick = (): void => {
    setCalcBlacklistFormOpen(true);
  };

  const handleUpdateClick = (calcBlacklist: CalcBlacklist): void => {
    setCalcBlacklistFormOpen(true);
    setCalcBlacklistToUpdate(calcBlacklist);
  };

  const handleFormCancel = (): void => {
    setCalcBlacklistFormOpen(false);
    setCalcBlacklistToUpdate(undefined);
  };

  const handleFilterSubmit = (filter: CalcBlacklistFilterPageRequest): void => {
    navigate(appendSearchParamsToURL({ ...filter, pageIndex: undefined }), { replace: true });
    actions.filterCalcBlacklists({
      pageIndex: 0,
      pageSize: calcBlacklistsCurrentPage.pageSize,
      calcType: filter.calcType,
      institutionIds: filter.institutionIds,
      agentIds: filter.agentIds
    });
  };

  const handlePageChange = (pageNumber: number): void => {
    const { pageSize, calcType, institutionIds, agentIds } = calcBlacklistsCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterCalcBlacklists({ pageIndex: pageNumber - 1, pageSize, calcType, institutionIds, agentIds });
  };

  return (
    <ContentWrapper>
      <CalcBlacklistFilterView
        filter={calcBlacklistsCurrentPage}
        onFilterSubmit={handleFilterSubmit}
        onCreateClick={handleCreateClick}
      />

      <CalcBlacklistTableView
        calcBlacklistPage={calcBlacklistsCurrentPage}
        onPageChange={handlePageChange}
        onUpdateClick={handleUpdateClick}
        onDelete={actions.deleteCalcBlacklist}
      />

      <CalcBlacklistForm
        calcBlacklist={calcBlacklistToUpdate}
        open={calcBlacklistFormOpen}
        onCreate={actions.createCalcBlacklist}
        onUpdate={actions.updateCalcBlacklist}
        onFormCancel={handleFormCancel}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  calcBlacklistsCurrentPage: selectCalcBlacklistsCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterCalcBlacklists: filterCalcBlacklistsActions.request,
      createCalcBlacklist: createCalcBlacklistActions.request,
      updateCalcBlacklist: updateCalcBlacklistActions.request,
      deleteCalcBlacklist: deleteCalcBlacklistActions.request,
      deleteStateCalcBlacklistsPage: deleteStateCalcBlacklistsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CalcBlacklistContainer);
