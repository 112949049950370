import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import apiService from "../../../common/api/apiService";
import { EntityObject } from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import {
  CreateVehicleMapping,
  UpdateVehicleMappingList,
  VehicleBrandMappingFilterPageResult,
  VehicleMapping,
  VehicleMappingData,
  VehicleMappingFilterPageRequest,
  VehicleMappingTypesRequest,
  VehicleModelMappingFilterPageResult
} from "./types";

const GET_VEHICLE_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings", "GET");
const REMAP_VEHICLE_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings/remap", "POST");
const MAP_VEHICLE_MAPPINGS_UPDATES = ApiRequestAdapter.create("/vehicles/mappings/map-updates", "POST");
const UNMAP_OBSOLETE_VEHICLE_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings/unmap-obsolete", "POST");
const IMPORT_VEHICLE_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings/import", "POST");
const EXPORT_VEHICLE_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings/export", "GET");
const FILTER_VEHICLE_BRANDS = ApiRequestAdapter.create("/vehicles/mappings/brands", "GET");
const CREATE_VEHICLE_BRAND_MAPPING = ApiRequestAdapter.create("/vehicles/mappings/brands", "POST");
const UPDATE_VEHICLE_BRAND_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings/brands", "PUT");
const FILTER_VEHICLE_MODELS = ApiRequestAdapter.create("/vehicles/mappings/brands/{0}/models", "GET");
const CREATE_VEHICLE_MODEL_MAPPING = ApiRequestAdapter.create("/vehicles/mappings/brands/{0}/models", "POST");
const UPDATE_VEHICLE_MODEL_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings/brands/{0}/models", "PUT");

export const requests = {
  GET_VEHICLE_MAPPINGS,
  REMAP_VEHICLE_MAPPINGS,
  MAP_VEHICLE_MAPPINGS_UPDATES,
  UNMAP_OBSOLETE_VEHICLE_MAPPINGS,
  IMPORT_VEHICLE_MAPPINGS,
  EXPORT_VEHICLE_MAPPINGS,
  FILTER_VEHICLE_BRANDS,
  CREATE_VEHICLE_BRAND_MAPPING,
  UPDATE_VEHICLE_BRAND_MAPPINGS,
  FILTER_VEHICLE_MODELS,
  CREATE_VEHICLE_MODEL_MAPPING,
  UPDATE_VEHICLE_MODEL_MAPPINGS
};

const api = {
  getVehicleMappings: (request: VehicleMappingTypesRequest): AxiosPromise<VehicleMappingData[]> => {
    return apiService.get(GET_VEHICLE_MAPPINGS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  remapVehicleMappings: (request: VehicleMappingTypesRequest): AxiosPromise<void> => {
    return apiService.post(REMAP_VEHICLE_MAPPINGS.url, null, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  mapVehicleMappingsUpdates: (request: VehicleMappingTypesRequest): AxiosPromise<void> => {
    return apiService.post(MAP_VEHICLE_MAPPINGS_UPDATES.url, null, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  unmapObsoleteVehicleMappings: (request: VehicleMappingTypesRequest): AxiosPromise<void> => {
    return apiService.post(UNMAP_OBSOLETE_VEHICLE_MAPPINGS.url, null, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  importVehicleMappings: (request: FormData): AxiosPromise<void> => {
    return apiService.post(IMPORT_VEHICLE_MAPPINGS.url, request, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  exportVehicleMappings: (): AxiosPromise<void> => {
    return apiService.get(EXPORT_VEHICLE_MAPPINGS.url, { responseType: "blob" });
  },
  filterVehicleBrands: (
    request: VehicleMappingFilterPageRequest
  ): AxiosPromise<VehicleBrandMappingFilterPageResult> => {
    return apiService.get(FILTER_VEHICLE_BRANDS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createVehicleBrandMapping: (request: CreateVehicleMapping): AxiosPromise<VehicleMapping> => {
    return apiService.post(CREATE_VEHICLE_BRAND_MAPPING.url, request);
  },
  updateVehicleBrandMappings: (request: UpdateVehicleMappingList): AxiosPromise<VehicleMapping[]> => {
    return apiService.put(UPDATE_VEHICLE_BRAND_MAPPINGS.url, request);
  },
  filterVehicleModels: (
    request: EntityObject<VehicleMappingFilterPageRequest>
  ): AxiosPromise<VehicleModelMappingFilterPageResult> => {
    return apiService.get(FILTER_VEHICLE_MODELS.getParametrizedUrl(request.id), {
      params: request.object,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createVehicleModelMapping: (request: EntityObject<CreateVehicleMapping>): AxiosPromise<VehicleMapping> => {
    return apiService.post(CREATE_VEHICLE_MODEL_MAPPING.getParametrizedUrl(request.id), request.object);
  },
  updateVehicleModelMappings: (request: EntityObject<UpdateVehicleMappingList>): AxiosPromise<VehicleMapping[]> => {
    return apiService.put(UPDATE_VEHICLE_MODEL_MAPPINGS.getParametrizedUrl(request.id), request.object);
  }
};

export default api;
