import { Card, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import t from "../../../../../../../app/i18n";
import ActionButton from "../../../../../../../common/components/buttons/ActionButton";
import { PageSizes } from "../../../../../../../common/constants";
import { RootState } from "../../../../../../../common/types";
import { appendSearchParamsToURL } from "../../../../../../../common/utils/utils";
import { selectRouterLocationSearchParam } from "../../../../../../ducks";
import { downloadCommissionsBatchAttachmentsAsZipActions, filterCommissionsActions } from "../../../../ducks";
import { CommissionOrigin, CommissionProcessingResult } from "../../../../enums";
import { CommissionsBatch, CommissionsFilterPageRequest, CommissionsFilterPageResult } from "../../../../types";
import CommissionsBatchAttachmentsAsZipForm from "../../../forms/CommissionsBatchAttachmentsAsZipForm";
import BatchCommissionTableView from "../../BatchCommissionTableView";
import BatchCalculatedCommissionFilterView from "../../filter/BatchCalculatedCommissionFilterView";
import CommissionsBatchOutputAttachmentsTableView from "../views/CommissionsBatchOutputAttachmentsTableView";
import CommissionsUnitTableView from "../views/CommissionsUnitTableView";

interface Props {
  batch: CommissionsBatch;
  commissionsPage: CommissionsFilterPageResult;
  onFilterCommissions: typeof filterCommissionsActions.request;
  onDownloadAttachmentsAsZip: typeof downloadCommissionsBatchAttachmentsAsZipActions.request;
}

const TAB = {
  UNITS: "units",
  COMMISSIONS: "commissions",
  OUTPUTS: "outputs"
};

const CommissionsBatchFinishStep = ({
  batch,
  commissionsPage,
  onFilterCommissions,
  onDownloadAttachmentsAsZip
}: Props) => {
  const navigate = useNavigate();

  const urlTabKey = useSelector<RootState, string>(
    state => selectRouterLocationSearchParam(state, "tab") || TAB.OUTPUTS
  );

  const [tabKey, setTabKey] = useState<string>(TAB.OUTPUTS);
  const [downloadAttachmentsFormOpen, setDownloadAttachmentsFormOpen] = useState<boolean>(false);

  useEffect(() => {
    onFilterCommissions({
      id: batch.id,
      object: {
        pageIndex: 0,
        pageSize: PageSizes.LARGE,
        keyword: undefined,
        origin: CommissionOrigin.ALL,
        processingResult: CommissionProcessingResult.ALL,
        postponementReasons: [],
        institutionIds: []
      }
    });

    switch (urlTabKey) {
      case TAB.UNITS:
      case TAB.COMMISSIONS:
      case TAB.OUTPUTS:
        setTabKey(urlTabKey);
        break;
      default:
        setTabKey(TAB.OUTPUTS);
        break;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabKeyChange = (key: string): void => {
    setTabKey(key);
    navigate(appendSearchParamsToURL({ tab: key }), { replace: true });
  };

  const handleFilterSubmit = (filter: CommissionsFilterPageRequest): void => {
    onFilterCommissions({
      id: batch.id,
      object: { ...filter, pageIndex: 0, pageSize: commissionsPage.pageSize }
    });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, origin, processingResult, postponementReasons, institutionIds } = commissionsPage;
    onFilterCommissions({
      id: batch.id,
      object: {
        pageIndex: pageNumber - 1,
        pageSize,
        keyword,
        origin,
        processingResult,
        postponementReasons,
        institutionIds
      }
    });
  };

  return (
    <>
      <Card>
        <Tabs
          activeKey={tabKey}
          tabBarExtraContent={
            tabKey === TAB.OUTPUTS && (
              <ActionButton icon="download" onClick={() => setDownloadAttachmentsFormOpen(true)}>
                {t("commissions.batch.actions.downloadBatchAttachmentsAsZip")}
              </ActionButton>
            )
          }
          onChange={handleTabKeyChange}
          items={[
            {
              key: TAB.UNITS,
              label: t("commissions.batch.sections.batchUnits"),
              children: <CommissionsUnitTableView batch={batch} />
            },
            {
              key: TAB.COMMISSIONS,
              label: t("commissions.batch.sections.batchCommissions"),
              children: (
                <>
                  <BatchCalculatedCommissionFilterView onFilterSubmit={handleFilterSubmit} />

                  <BatchCommissionTableView
                    commissionsPage={commissionsPage}
                    batch={batch}
                    viewType="calculated"
                    onPageChange={handleTablePageChange}
                  />
                </>
              )
            },
            {
              key: TAB.OUTPUTS,
              label: t("commissions.batch.sections.batchOutputs"),
              children: <CommissionsBatchOutputAttachmentsTableView batch={batch} />
            }
          ]}
        />
      </Card>

      <CommissionsBatchAttachmentsAsZipForm
        batch={batch}
        open={downloadAttachmentsFormOpen}
        onDownload={onDownloadAttachmentsAsZip}
        onFormCancel={() => setDownloadAttachmentsFormOpen(false)}
      />
    </>
  );
};

export default CommissionsBatchFinishStep;
