import { Card, Checkbox, Col, DatePicker, Form, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance, Rule } from "antd/lib/form";
import ReactQuill from "react-quill-new";
import t from "../../../../../../app/i18n";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../common/constants";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerOutOfMaxDate,
  disableDatePickerOutOfMinDate,
  disableDatePickerOutOfMinDateIncluded,
  quillEditorStandardProps,
  selectStandardProps
} from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { SecondPillarCreationType, SecondPillarFundType } from "../../../../enums";
import { CreateUpdateContractGainerRecord, CreateUpdateSecondPillarContract } from "../../../../types";
import { calculateContractStatus } from "../../../../utils";
import ContractStatusTag from "../../../ContractStatusTag";

interface Props {
  form: FormInstance<CreateUpdateSecondPillarContract>;
}

const SecondPillarContractFormDataSections = ({ form }: Props) => {
  const handleSignDateChange = (): void => {
    handleStatusDefiningDateChange();
    const signDate = form.getFieldValue(["signDate"]);
    const records = [...form.getFieldValue(["gainerRecords"])] as CreateUpdateContractGainerRecord[];
    records[0] = { ...(records[0] as CreateUpdateContractGainerRecord), startDate: signDate };
    form.setFieldsValue({ gainerRecords: records });
  };

  const handleStatusDefiningDateChange = (): void => {
    const { signDate, cancellationDate, transferredToOtherBrokerDate } = form.getFieldsValue([
      ["signDate"],
      ["cancellationDate"],
      ["transferredToOtherBrokerDate"]
    ]) as CreateUpdateSecondPillarContract;
    form.setFieldsValue({
      status: calculateContractStatus({
        startDate: signDate,
        endDate: undefined,
        cancellationDate: cancellationDate,
        transferredToOtherBrokerDate: transferredToOtherBrokerDate
      })
    });
  };

  const handleTransferredToOtherBrokerChange = (event: CheckboxChangeEvent): void => {
    if (!event.target.checked) {
      form.setFieldsValue({ transferredToOtherBrokerDate: undefined });
      handleStatusDefiningDateChange();
    }
  };

  const colSpan = 4;

  return (
    <Card
      type="inner"
      className="card-box card-box--inner-extra"
      title={t("contract.sections.contractData")}
      extra={
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.status !== next.status}>
          {({ getFieldValue }) => {
            const status = getFieldValue("status");
            return status ? <ContractStatusTag status={status} /> : undefined;
          }}
        </Form.Item>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.signDate !== next.signDate}>
            {({ getFieldValue }) => {
              const signDate = getFieldValue("signDate") as string | undefined;
              const rules: Rule[] = [validations.notNull];
              if (signDate) {
                rules.push(validations.notAfter(signDate, t("contract.attrs.signDate")));
              }
              return (
                <Form.Item
                  name="mediationReportSignDate"
                  label={t("contract.attrs.mediationReportSignDate")}
                  rules={rules}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerStandardProps}
                    disabledDate={current => (signDate ? disableDatePickerOutOfMaxDate(current, signDate) : false)}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="signDate"
            label={t("contract.attrs.signDate")}
            rules={[validations.notNull]}
            {...datePickerFormItemProps}
          >
            <DatePicker {...datePickerStandardProps} onChange={handleSignDateChange} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.signDate !== next.signDate}>
            {({ getFieldValue }) => {
              const signDate = getFieldValue("signDate") as string | undefined;
              return (
                <Form.Item
                  name="cancellationDate"
                  label={t("contract.attrs.cancellationDate")}
                  rules={[signDate ? validations.notBefore(signDate, t("contract.attrs.signDate")) : validations.none]}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current => (signDate ? disableDatePickerOutOfMinDate(current, signDate) : false)}
                    onChange={handleStatusDefiningDateChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="creationType"
            label={t("contract.enums.secondPillarCreationType._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(SecondPillarCreationType).map(type => ({
                value: type,
                label: t("contract.enums.secondPillarCreationType." + type)
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="fundType"
            label={t("contract.enums.secondPillarFundType._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(SecondPillarFundType).map(type => ({
                value: type,
                label: t("contract.enums.secondPillarFundType." + type)
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="transferredFromOtherBroker"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox style={{ maxHeight: 22 }}>
              <LabelWithTooltip
                label={t("contract.attrs.transferredFromOtherBroker")}
                tooltip={t("contract.helpers.transferredFromOtherBrokerDesc")}
              />
            </Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="transferredToOtherBroker"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox style={{ maxHeight: 22 }} onChange={handleTransferredToOtherBrokerChange}>
              <LabelWithTooltip
                label={t("contract.attrs.transferredToOtherBroker")}
                tooltip={t("contract.helpers.transferredToOtherBrokerDesc")}
              />
            </Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.signDate !== next.signDate ||
              prev.cancellationDate !== next.cancellationDate ||
              prev.transferredToOtherBroker !== next.transferredToOtherBroker
            }
          >
            {({ getFieldValue }) => {
              const signDate = getFieldValue("signDate") as string | undefined;
              const cancellationDate = getFieldValue("cancellationDate") as string | undefined;
              const transferredToOtherBroker = getFieldValue("transferredToOtherBroker");
              const rules = [];
              if (signDate) {
                rules.push(validations.notSameOrBefore(signDate, t("contract.attrs.signDate")));
              }
              if (cancellationDate) {
                rules.push(validations.notAfter(cancellationDate, t("contract.attrs.cancellationDate")));
              }
              if (transferredToOtherBroker) {
                rules.push(validations.notNull);
              }
              return (
                <Form.Item
                  name="transferredToOtherBrokerDate"
                  label={
                    <LabelWithTooltip
                      label={t("contract.attrs.transferredToOtherBrokerDate")}
                      tooltip={t("contract.helpers.transferredToOtherBrokerDateDesc")}
                    />
                  }
                  rules={rules.length > 0 ? rules : [validations.none]}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerClearableProps}
                    disabled={!transferredToOtherBroker}
                    disabledDate={current =>
                      (!!signDate && disableDatePickerOutOfMinDateIncluded(current, signDate)) ||
                      (!!cancellationDate && disableDatePickerOutOfMaxDate(current, cancellationDate))
                    }
                    onChange={handleStatusDefiningDateChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} className="margin-top-small">
        <Col span={24}>
          <Form.Item
            name="note"
            label={t("contract.attrs.note")}
            rules={[validations.size(1, 8192)]}
            initialValue={undefined}
          >
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default SecondPillarContractFormDataSections;
