import { Col, Form, Row, Select } from "antd";
import ReactQuill from "react-quill-new";
import t from "../../../../../../app/i18n";
import { rowGutter } from "../../../../../../common/constants";
import { formatClientName } from "../../../../../../common/utils/formatUtils";
import { quillEditorStandardProps, selectStandardProps } from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { Client } from "../../../../../client/types";

interface Props {
  index: number;
  clients: (Client | undefined)[];
}

const GenericInsuranceFormPart = ({ index, clients }: Props) => {
  const colSpan = 6;

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name={["insurances", index, "insuranceData", "description"]}
            label={t("contract.attrs.insurances.insuranceData.description")}
            rules={[validations.size(1, 32768)]}
            initialValue={undefined}
          >
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={colSpan}>
          <Form.Item
            name={["insurances", index, "insuredClientIdentifier"]}
            label={t("contract.attrs.insurances.insuredClientIdentifier")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={clients
                .filter((c): c is Client => !!c)
                .map(c => ({ value: c.identifier, label: formatClientName(c) }))}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default GenericInsuranceFormPart;
