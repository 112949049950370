import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, RootState } from "../../common/types";
import messageUtils from "../../common/utils/messageUtils";
import { openBlobFile } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import api from "./api";
import { DocumentNodeType, DocumentNodeTypeKey } from "./enums";
import {
  CreateUpdateDocumentNode,
  DocumentNode,
  DocumentNodeReducerState,
  DocumentNodeTreeList,
  MoveDocumentNodesPayload,
  RootDocumentNodeTree
} from "./types";

/**
 * ACTIONS
 */
export const createDocumentNodeActions = createAsyncAction(
  "document-node/CREATE_REQUEST",
  "document-node/CREATE_SUCCESS",
  "document-node/CREATE_FAILURE"
)<CreateUpdateDocumentNode, DocumentNode, void>();

export const uploadDocumentNodesActions = createAsyncAction(
  "document-node/UPLOAD_REQUEST",
  "document-node/UPLOAD_SUCCESS",
  "document-node/UPLOAD_FAILURE"
)<FormData, DocumentNode[], void>();

export const moveDocumentNodesActions = createAsyncAction(
  "document-node/MOVE_REQUEST",
  "document-node/MOVE_SUCCESS",
  "document-node/MOVE_FAILURE"
)<MoveDocumentNodesPayload, void, void>();

export const renameDocumentNodeActions = createAsyncAction(
  "document-node/RENAME_REQUEST",
  "document-node/RENAME_SUCCESS",
  "document-node/RENAME_FAILURE"
)<EntityObject<CreateUpdateDocumentNode>, DocumentNode, void>();

export const deleteDocumentNodesActions = createAsyncAction(
  "document-node/DELETE_REQUEST",
  "document-node/DELETE_SUCCESS",
  "document-node/DELETE_FAILURE"
)<EntityIdObject[], void, void>();

export const getDocumentNodeTreeActions = createAsyncAction(
  "document-node/GET_TREE_REQUEST",
  "document-node/GET_TREE_SUCCESS",
  "document-node/GET_TREE_FAILURE"
)<void, DocumentNodeTreeList, void>();

export const downloadDocumentNodeActions = createAsyncAction(
  "document-node/DOWNLOAD_REQUEST",
  "document-node/DOWNLOAD_SUCCESS",
  "document-node/DOWNLOAD_FAILURE"
)<EntityIdObject, void, void>();

export const openDocumentNodeActions = createAsyncAction(
  "document-node/OPEN_REQUEST",
  "document-node/OPEN_SUCCESS",
  "document-node/OPEN_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateDocumentNodeTreeAction = createAction("document-node/DELETE_STATE_TREE_LIST")<void>();

const actions = {
  createDocumentNodeActions,
  uploadDocumentNodesActions,
  moveDocumentNodesActions,
  renameDocumentNodeActions,
  deleteDocumentNodesActions,
  getDocumentNodeTreeActions,
  downloadDocumentNodeActions,
  openDocumentNodeActions,
  deleteStateDocumentNodeTreeAction
};

export type DocumentNodeAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: DocumentNodeReducerState = {
  documentTree: {
    [DocumentNodeTypeKey.GENERAL]: [],
    [DocumentNodeTypeKey.PERSONAL]: []
  }
};

const documentTreeReducer = createReducer(initialState.documentTree)
  .handleAction(getDocumentNodeTreeActions.success, (_, { payload }) => ({
    [DocumentNodeTypeKey.GENERAL]: payload.generalNodes,
    [DocumentNodeTypeKey.PERSONAL]: payload.personalNodes
  }))
  .handleAction(
    [getDocumentNodeTreeActions.failure, deleteStateDocumentNodeTreeAction],
    () => initialState.documentTree
  );

export const documentNodeReducer = combineReducers<DocumentNodeReducerState>({
  documentTree: documentTreeReducer
});

/**
 * SELECTORS
 */
const selectDocumentNode = (state: RootState): DocumentNodeReducerState => state.documentNode;

export const selectDocumentNodeTree = (state: RootState): RootDocumentNodeTree =>
  selectDocumentNode(state).documentTree;

/**
 * SAGAS
 */
function* createDocumentNode({ payload }: ReturnType<typeof createDocumentNodeActions.request>) {
  try {
    const response: AxiosResponse<DocumentNode> = yield call(api.createDocumentNode, payload);

    yield put(createDocumentNodeActions.success(response.data));
    yield put(getDocumentNodeTreeActions.request());
    yield put(changeRunningRequestKeyAction());

    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createDocumentNodeActions.failure());
  }
}

function* uploadDocumentNodes({ payload }: ReturnType<typeof uploadDocumentNodesActions.request>) {
  try {
    const response: AxiosResponse<DocumentNode[]> = yield call(api.uploadDocumentNodes, payload);

    yield put(uploadDocumentNodesActions.success(response.data));
    yield put(getDocumentNodeTreeActions.request());

    messageUtils.itemCreatedNotification();
  } catch {
    yield put(uploadDocumentNodesActions.failure());
  }
}

function* moveDocumentNodes({ payload }: ReturnType<typeof moveDocumentNodesActions.request>) {
  try {
    const nodesToMove = payload.nodes.map<EntityObject<CreateUpdateDocumentNode>>(node => ({
      id: node.id,
      object: {
        optimisticLockVersion: node.optimisticLockVersion,
        name: node.name,
        type: node.type as DocumentNodeType,
        parentId: payload.parentId
      }
    }));

    yield all(
      nodesToMove.map(object => {
        return call(function* () {
          try {
            return (yield call(api.updateDocumentNode, object)) as DocumentNode;
          } catch {
            return { data: {} };
          }
        });
      })
    );

    yield put(moveDocumentNodesActions.success());
    yield put(getDocumentNodeTreeActions.request());
    yield put(changeRunningRequestKeyAction());

    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(moveDocumentNodesActions.failure());
  }
}

function* renameDocumentNode({ payload }: ReturnType<typeof renameDocumentNodeActions.request>) {
  try {
    const response: AxiosResponse<DocumentNode> = yield call(api.updateDocumentNode, payload);

    yield put(renameDocumentNodeActions.success(response.data));
    yield put(getDocumentNodeTreeActions.request());
    yield put(changeRunningRequestKeyAction());

    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(renameDocumentNodeActions.failure());
  }
}

function* deleteDocumentNodes({ payload }: ReturnType<typeof deleteDocumentNodesActions.request>) {
  try {
    yield all(
      payload.map(object => {
        return call(function* () {
          try {
            return (yield call(api.deleteDocumentNode, object)) as typeof deleteDocumentNodesActions.request;
          } catch {
            return { data: {} };
          }
        });
      })
    );

    yield put(deleteDocumentNodesActions.success());
    yield put(getDocumentNodeTreeActions.request());

    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteDocumentNodesActions.failure());
  }
}

function* getDocumentNodeTree() {
  try {
    const response: AxiosResponse<DocumentNodeTreeList> = yield call(api.getDocumentNodeTree);

    yield put(getDocumentNodeTreeActions.success(response.data));
  } catch {
    yield put(getDocumentNodeTreeActions.failure());
  }
}

function* downloadDocumentNode({ payload }: ReturnType<typeof downloadDocumentNodeActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadDocumentNode, payload);
    openBlobFile(response, true);
    yield put(downloadDocumentNodeActions.success());
  } catch {
    yield put(downloadDocumentNodeActions.failure());
  }
}

function* openDocumentNode({ payload }: ReturnType<typeof openDocumentNodeActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadDocumentNode, payload);
    openBlobFile(response);
    yield put(openDocumentNodeActions.success());
  } catch {
    yield put(openDocumentNodeActions.failure());
  }
}

export function* documentNodeSaga() {
  yield takeLatest(createDocumentNodeActions.request, createDocumentNode);
  yield takeLatest(uploadDocumentNodesActions.request, uploadDocumentNodes);
  yield takeLatest(moveDocumentNodesActions.request, moveDocumentNodes);
  yield takeLatest(renameDocumentNodeActions.request, renameDocumentNode);
  yield takeLatest(deleteDocumentNodesActions.request, deleteDocumentNodes);
  yield takeLatest(getDocumentNodeTreeActions.request, getDocumentNodeTree);
  yield takeLatest(downloadDocumentNodeActions.request, downloadDocumentNode);
  yield takeLatest(openDocumentNodeActions.request, openDocumentNode);
}
