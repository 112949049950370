import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { dateToIsoDateTimeString, toDate } from "../../../common/utils/formUtils";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import BugReportNotificationView from "../components/views/BugReportNotificationView";
import NotificationPageSearchView from "../components/views/NotificationPageSearchView";
import NotificationTableView from "../components/views/NotificationTableView";
import {
  deleteStatePageNotificationsListAction,
  filterPageNotificationsActions,
  markAllPageNotificationsAsSeenActions,
  selectNotificationsPageList,
  togglePageNotificationSentAndSeenStatusActions
} from "../ducks";
import { NotificationStatus, NotificationTopic } from "../enums";
import { Notification, NotificationFilterPageRequest, NotificationFilterPageResult } from "../types";
import { openNotificationTopicPage } from "../utils";

interface StateProps {
  notificationsCurrentPage: NotificationFilterPageResult;
  urlSearchQuery: string;
}

interface ActionMap {
  filterPageNotifications: typeof filterPageNotificationsActions.request;
  markAllPageNotificationsAsSeen: typeof markAllPageNotificationsAsSeenActions.request;
  togglePageNotificationSentAndSeenStatus: typeof togglePageNotificationSentAndSeenStatusActions.request;
  deleteStatePageNotificationsList: typeof deleteStatePageNotificationsListAction;
}

const NotificationPageContainer = ({
  notificationsCurrentPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionMap>) => {
  const navigate = useNavigate();

  const [selectedBugReportNotification, setSelectedBugReportNotification] = useState<Notification>();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);

    actions.filterPageNotifications({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword") ?? undefined,
      onlyUnseen: urlParams.get("onlyUnseen") === "true" || undefined,
      topics: urlParams.getAll("topics").map(topic => NotificationTopic[topic as keyof typeof NotificationTopic]),
      sentAtMin: urlParams.get("sentAtMin") ?? undefined,
      sentAtMax: urlParams.get("sentAtMax") ?? undefined
    });

    return () => {
      actions.deleteStatePageNotificationsList();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSubmit = (filter: NotificationFilterPageRequest): void => {
    const minDate = toDate(filter.sentAtMin)?.startOf("day");
    const maxDate = toDate(filter.sentAtMax)?.endOf("day");

    const sentAtMin = minDate ? dateToIsoDateTimeString(minDate) : undefined;
    const sentAtMax = maxDate ? dateToIsoDateTimeString(maxDate) : undefined;

    navigate(
      appendSearchParamsToURL({
        ...filter,
        pageIndex: undefined,
        keyword: filter.keyword || undefined,
        sentAtMin,
        sentAtMax
      }),
      { replace: true }
    );

    actions.filterPageNotifications({
      ...filter,
      pageIndex: 0,
      pageSize: notificationsCurrentPage.pageSize,
      sentAtMin,
      sentAtMax
    });
  };

  const handlePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, onlyUnseen, topics, sentAtMin, sentAtMax } = notificationsCurrentPage;

    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });

    actions.filterPageNotifications({
      pageIndex: pageNumber - 1,
      pageSize,
      keyword,
      onlyUnseen,
      topics,
      sentAtMin,
      sentAtMax
    });
  };

  const handleNotificationShowClick = (notification: Notification): void => {
    if (notification.status === NotificationStatus.SENT) {
      actions.togglePageNotificationSentAndSeenStatus({ id: notification.id });
    }
    if (notification.data.topic === NotificationTopic.SYSADMIN_BUG_REPORT_CREATED) {
      setSelectedBugReportNotification(notification);
    } else {
      openNotificationTopicPage(notification);
    }
  };

  return (
    <>
      <ContentWrapper>
        <NotificationPageSearchView
          currentFilter={notificationsCurrentPage}
          onFilterSubmit={handleFilterSubmit}
          onMarkAllAsSeen={actions.markAllPageNotificationsAsSeen}
        />

        <NotificationTableView
          currentPage={notificationsCurrentPage}
          onPageChange={handlePageChange}
          onStatusToggle={actions.togglePageNotificationSentAndSeenStatus}
          onShowClick={handleNotificationShowClick}
        />
      </ContentWrapper>

      <BugReportNotificationView
        open={!!selectedBugReportNotification}
        notification={selectedBugReportNotification}
        onCancelClick={() => setSelectedBugReportNotification(undefined)}
      />
    </>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  notificationsCurrentPage: selectNotificationsPageList(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionMap> => ({
  actions: bindActionCreators(
    {
      filterPageNotifications: filterPageNotificationsActions.request,
      markAllPageNotificationsAsSeen: markAllPageNotificationsAsSeenActions.request,
      togglePageNotificationSentAndSeenStatus: togglePageNotificationSentAndSeenStatusActions.request,
      deleteStatePageNotificationsList: deleteStatePageNotificationsListAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(NotificationPageContainer);
