import { generatePath, Link } from "react-router-dom";
import { formatAddress } from "../../../../common/utils/formatUtils";
import { CLIENT_ROUTE_PATHS } from "../../paths";
import { Client } from "../../types";
import { resolveClientIdentifier } from "../../utils";
import ClientTypeTag from "./ClientTypeTag";

interface Props {
  client: Client;
  showLink?: boolean;
}

const ClientTypeTagWithDescription = ({ client, showLink }: Props) => {
  const clientDescription = (
    <span>
      <b>{client.aggregatedName}</b> {`(${resolveClientIdentifier(client)}), ${formatAddress(client.address)}`}
    </span>
  );

  return client ? (
    <>
      <ClientTypeTag type={client.type} style={{ fontWeight: "normal" }} />
      {showLink && client.id ? (
        <Link to={generatePath(CLIENT_ROUTE_PATHS.detail.to, { id: client.id })}>{clientDescription}</Link>
      ) : (
        clientDescription
      )}
    </>
  ) : null;
};

export default ClientTypeTagWithDescription;
