import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { combineReducers } from "redux";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { PageSizes } from "../../common/constants";
import { Permission } from "../../common/security/authorization/enums";
import { EntityIdObject, EntityObject, RootState, TwoLevelEntityIdObject } from "../../common/types";
import { initPageResult } from "../../common/utils/apiUtils";
import { dateToIsoDateString, dateToIsoDateTimeString, toDate } from "../../common/utils/formUtils";
import messageUtils from "../../common/utils/messageUtils";
import { hasAnyPermission, hasPermission, openBlobFile, replaceInArray } from "../../common/utils/utils";
import { selectPermissions } from "../auth/ducks";
import { changeRunningRequestKeyAction } from "../ducks";
import api from "./api";
import {
  AgentCompetenceIntervalDashboard,
  ContractStatisticsDashboard,
  ContractStatusesDashboard,
  CreateUpdateDashboardContact,
  CreateUpdateDashboardNotice,
  DashboardContact,
  DashboardContactSearchRequest,
  DashboardContactSearchResult,
  DashboardMinMaxDatesRequest,
  DashboardNotice,
  DashboardNoticeAttachment,
  DashboardNoticeFilterPageRequest,
  DashboardNoticeFilterPageResult,
  DashboardReducerState,
  DashboardRefreshType,
  DashboardStatistics,
  PersonalDatesDashboard
} from "./types";

/**
 * ACTIONS - DASHBOARD COMMON
 */
export const refreshDashboardAction = createAction("dashboard/REFRESH_ALL")<DashboardRefreshType>();

export const setDashboardLastRefreshTimeAction = createAction("dashboard/SET_REFRESH_TIME")<string>();

export const getDashboardStatisticsActions = createAsyncAction(
  "dashboard-statistics/GET_REQUEST",
  "dashboard-statistics/GET_SUCCESS",
  "dashboard-statistics/GET_FAILURE"
)<void, DashboardStatistics, void>();

export const getDashboardPersonalDatesActions = createAsyncAction(
  "dashboard-personal-dates/GET_REQUEST",
  "dashboard-personal-dates/GET_SUCCESS",
  "dashboard-personal-dates/GET_FAILURE"
)<DashboardMinMaxDatesRequest, PersonalDatesDashboard, void>();

export const getDashboardAgentCompetencesActions = createAsyncAction(
  "dashboard-agent-competences/GET_REQUEST",
  "dashboard-agent-competences/GET_SUCCESS",
  "dashboard-agent-competences/GET_FAILURE"
)<void, AgentCompetenceIntervalDashboard[], void>();

export const getDashboardContractStatusesActions = createAsyncAction(
  "dashboard-contract-statuses/GET_REQUEST",
  "dashboard-contract-statuses/GET_SUCCESS",
  "dashboard-contract-statuses/GET_FAILURE"
)<void, ContractStatusesDashboard, void>();

export const getDashboardContractStatisticsActions = createAsyncAction(
  "dashboard-contract-statistics/GET_REQUEST",
  "dashboard-contract-statistics/GET_SUCCESS",
  "dashboard-contract-statistics/GET_FAILURE"
)<DashboardMinMaxDatesRequest, ContractStatisticsDashboard, void>();

export const deleteStateDashboardDataAction = createAction("dashboard/DELETE_STATE_DATA")<void>();

/**
 * ACTIONS - DASHBOARD NOTICE
 */
export const filterDashboardNoticesActions = createAsyncAction(
  "dashboard-notice/FILTER_REQUEST",
  "dashboard-notice/FILTER_SUCCESS",
  "dashboard-notice/FILTER_FAILURE"
)<DashboardNoticeFilterPageRequest, DashboardNoticeFilterPageResult, void>();

export const createDashboardNoticeActions = createAsyncAction(
  "dashboard-notice/CREATE_REQUEST",
  "dashboard-notice/CREATE_SUCCESS",
  "dashboard-notice/CREATE_FAILURE"
)<CreateUpdateDashboardNotice, DashboardNotice, void>();

export const updateDashboardNoticeActions = createAsyncAction(
  "dashboard-notice/UPDATE_REQUEST",
  "dashboard-notice/UPDATE_SUCCESS",
  "dashboard-notice/UPDATE_FAILURE"
)<EntityObject<CreateUpdateDashboardNotice>, DashboardNotice, void>();

export const deleteDashboardNoticeActions = createAsyncAction(
  "dashboard-notice/DELETE_REQUEST",
  "dashboard-notice/DELETE_SUCCESS",
  "dashboard-notice/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const addDashboardNoticeAttachmentsActions = createAsyncAction(
  "dashboard-notice-attachment/ADD_REQUEST",
  "dashboard-notice-attachment/ADD_SUCCESS",
  "dashboard-notice-attachment/ADD_FAILURE"
)<EntityObject<FormData>, void, void>();

export const downloadDashboardNoticeAttachmentActions = createAsyncAction(
  "dashboard-notice-attachment/DOWNLOAD_REQUEST",
  "dashboard-notice-attachment/DOWNLOAD_SUCCESS",
  "dashboard-notice-attachment/DOWNLOAD_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const deleteDashboardNoticeAttachmentActions = createAsyncAction(
  "dashboard-notice-attachment/DELETE_REQUEST",
  "dashboard-notice-attachment/DELETE_SUCCESS",
  "dashboard-notice-attachment/DELETE_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

/**
 * ACTIONS - DASHBOARD CONTACT
 */
export const searchDashboardContactsActions = createAsyncAction(
  "dashboard-contact/SEARCH_REQUEST",
  "dashboard-contact/SEARCH_SUCCESS",
  "dashboard-contact/SEARCH_FAILURE"
)<DashboardContactSearchRequest, DashboardContactSearchResult, void>();

export const createDashboardContactActions = createAsyncAction(
  "dashboard-contact/CREATE_REQUEST",
  "dashboard-contact/CREATE_SUCCESS",
  "dashboard-contact/CREATE_FAILURE"
)<CreateUpdateDashboardContact, DashboardContact, void>();

export const updateDashboardContactActions = createAsyncAction(
  "dashboard-contact/UPDATE_REQUEST",
  "dashboard-contact/UPDATE_SUCCESS",
  "dashboard-contact/UPDATE_FAILURE"
)<EntityObject<CreateUpdateDashboardContact>, DashboardContact, void>();

export const deleteDashboardContactActions = createAsyncAction(
  "dashboard-contact/DELETE_REQUEST",
  "dashboard-contact/DELETE_SUCCESS",
  "dashboard-contact/DELETE_FAILURE"
)<EntityIdObject, void, void>();

const actions = {
  refreshDashboardAction,
  setDashboardLastRefreshTimeAction,
  getDashboardStatisticsActions,
  getDashboardPersonalDatesActions,
  getDashboardAgentCompetencesActions,
  getDashboardContractStatusesActions,
  getDashboardContractStatisticsActions,
  deleteStateDashboardDataAction,
  filterDashboardNoticesActions,
  createDashboardNoticeActions,
  updateDashboardNoticeActions,
  deleteDashboardNoticeActions,
  addDashboardNoticeAttachmentsActions,
  downloadDashboardNoticeAttachmentActions,
  deleteDashboardNoticeAttachmentActions,
  searchDashboardContactsActions,
  createDashboardContactActions,
  updateDashboardContactActions,
  deleteDashboardContactActions
};

export type DashboardAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: DashboardReducerState = {
  lastRefreshTime: null,
  statistics: null,
  personalDates: null,
  agentCompetences: [],
  contractStatuses: null,
  contractStatistics: null,
  noticesCurrentPage: { ...initPageResult<DashboardNotice>(), position: undefined, onlyUnclosed: false },
  contacts: { keyword: undefined, data: [] }
};

const lastRefreshTimeReducer = createReducer(initialState.lastRefreshTime)
  .handleAction(setDashboardLastRefreshTimeAction, (_, { payload }) => payload)
  .handleAction(deleteStateDashboardDataAction, () => initialState.lastRefreshTime);

const statisticsReducer = createReducer(initialState.statistics)
  .handleAction(getDashboardStatisticsActions.success, (_, { payload }) => payload)
  .handleAction([getDashboardStatisticsActions.failure, deleteStateDashboardDataAction], () => initialState.statistics);

const personalDatesReducer = createReducer(initialState.personalDates)
  .handleAction(getDashboardPersonalDatesActions.success, (_, { payload }) => payload)
  .handleAction(
    [getDashboardPersonalDatesActions.failure, deleteStateDashboardDataAction],
    () => initialState.personalDates
  );

const agentCompetencesReducer = createReducer(initialState.agentCompetences)
  .handleAction(getDashboardAgentCompetencesActions.success, (_, { payload }) => payload)
  .handleAction(
    [getDashboardAgentCompetencesActions.failure, deleteStateDashboardDataAction],
    () => initialState.agentCompetences
  );

const contractStatusesReducer = createReducer(initialState.contractStatuses)
  .handleAction(getDashboardContractStatusesActions.success, (_, { payload }) => payload)
  .handleAction(
    [getDashboardContractStatusesActions.failure, deleteStateDashboardDataAction],
    () => initialState.contractStatuses
  );

const contractStatisticsReducer = createReducer(initialState.contractStatistics)
  .handleAction(getDashboardContractStatisticsActions.success, (_, { payload }) => payload)
  .handleAction(
    [getDashboardContractStatisticsActions.failure, deleteStateDashboardDataAction],
    () => initialState.contractStatistics
  );

const noticesCurrentPageReducer = createReducer(initialState.noticesCurrentPage)
  .handleAction(filterDashboardNoticesActions.success, (state, { payload }) => ({
    ...payload,
    pageData: payload.additionalItems ? [...state.pageData, ...payload.pageData] : payload.pageData,
    pageElementsCount: payload.additionalItems
      ? state.pageData.length + payload.pageElementsCount
      : payload.pageElementsCount
  }))
  .handleAction(updateDashboardNoticeActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterDashboardNoticesActions.failure, deleteStateDashboardDataAction],
    () => initialState.noticesCurrentPage
  );

const contactsReducer = createReducer(initialState.contacts)
  .handleAction(searchDashboardContactsActions.success, (_, { payload }) => payload)
  .handleAction([searchDashboardContactsActions.failure, deleteStateDashboardDataAction], () => initialState.contacts);

export const dashboardReducer = combineReducers<DashboardReducerState>({
  lastRefreshTime: lastRefreshTimeReducer,
  statistics: statisticsReducer,
  personalDates: personalDatesReducer,
  agentCompetences: agentCompetencesReducer,
  contractStatuses: contractStatusesReducer,
  contractStatistics: contractStatisticsReducer,
  noticesCurrentPage: noticesCurrentPageReducer,
  contacts: contactsReducer
});

/**
 * SELECTORS
 */
const selectDashboard = (state: RootState): DashboardReducerState => state.dashboard;

export const selectDashboardLastRefreshTime = (state: RootState): string | undefined =>
  selectDashboard(state).lastRefreshTime ?? undefined;
export const selectDashboardStatistics = (state: RootState): DashboardStatistics | undefined =>
  selectDashboard(state).statistics ?? undefined;
export const selectDashboardPersonalDates = (state: RootState): PersonalDatesDashboard | undefined =>
  selectDashboard(state).personalDates ?? undefined;
export const selectDashboardAgentCompetences = (state: RootState): AgentCompetenceIntervalDashboard[] =>
  selectDashboard(state).agentCompetences ?? undefined;
export const selectDashboardContractStatuses = (state: RootState): ContractStatusesDashboard | undefined =>
  selectDashboard(state).contractStatuses ?? undefined;
export const selectDashboardContractStatistics = (state: RootState): ContractStatisticsDashboard | undefined =>
  selectDashboard(state).contractStatistics ?? undefined;
export const selectDashboardNoticesCurrentPage = (state: RootState): DashboardNoticeFilterPageResult =>
  selectDashboard(state).noticesCurrentPage;
export const selectDashboardContactsSearchResult = (state: RootState): DashboardContactSearchResult =>
  selectDashboard(state).contacts;

/**
 * SAGAS - DASHBOARD COMMON
 */
function* refreshDashboard({ payload }: ReturnType<typeof refreshDashboardAction>) {
  const lastRefreshTime: string = yield select(selectDashboardLastRefreshTime);

  if (lastRefreshTime === undefined || dayjs().diff(toDate(lastRefreshTime), "minutes") >= 60 || payload === "manual") {
    const permissions: Permission[] = yield select(selectPermissions);

    yield put(setDashboardLastRefreshTimeAction(dateToIsoDateTimeString(dayjs())));
    yield put(getDashboardStatisticsActions.request());

    if (hasAnyPermission(permissions, [Permission.CLIENT_READ, Permission.AGENT_READ])) {
      const personalDates: PersonalDatesDashboard = yield select(selectDashboardPersonalDates);
      yield put(
        getDashboardPersonalDatesActions.request({
          minDate: personalDates?.minDate || dateToIsoDateString(dayjs().startOf("month")),
          maxDate: personalDates?.maxDate || dateToIsoDateString(dayjs().endOf("month"))
        })
      );
    }

    if (hasPermission(permissions, Permission.PROFILE_COMPETENCE)) {
      yield put(getDashboardAgentCompetencesActions.request());
    }

    if (
      hasAnyPermission(permissions, [
        Permission.INSURANCE_READ,
        Permission.LOAN_READ,
        Permission.INVESTMENT_READ,
        Permission.DEPOSIT_READ,
        Permission.SECOND_PILLAR_READ,
        Permission.THIRD_PILLAR_READ,
        Permission.GENERIC_READ
      ])
    ) {
      yield put(getDashboardContractStatusesActions.request());

      const statistics: ContractStatisticsDashboard = yield select(selectDashboardContractStatistics);
      yield put(
        getDashboardContractStatisticsActions.request({
          minDate: statistics?.minDate || dateToIsoDateString(dayjs().startOf("month")),
          maxDate: statistics?.maxDate || dateToIsoDateString(dayjs())
        })
      );
    }

    yield put(
      filterDashboardNoticesActions.request({
        pageIndex: 0,
        pageSize: PageSizes.HUGE
      })
    );

    const contacts: DashboardContactSearchResult = yield select(selectDashboardContactsSearchResult);
    yield put(searchDashboardContactsActions.request({ keyword: contacts.keyword }));
  }
}

function* getDashboardStatistics() {
  try {
    const response: AxiosResponse<DashboardStatistics> = yield call(api.getDashboardStatistics);
    yield put(getDashboardStatisticsActions.success(response.data));
  } catch {
    yield put(getDashboardStatisticsActions.failure());
  }
}

function* getDashboardPersonalDates({ payload }: ReturnType<typeof getDashboardPersonalDatesActions.request>) {
  try {
    const response: AxiosResponse<PersonalDatesDashboard> = yield call(api.getDashboardPersonalDates, payload);
    yield put(getDashboardPersonalDatesActions.success(response.data));
  } catch {
    yield put(getDashboardPersonalDatesActions.failure());
  }
}

function* getDashboardAgentCompetences() {
  try {
    const response: AxiosResponse<AgentCompetenceIntervalDashboard[]> = yield call(api.getDashboardAgentCompetences);
    yield put(getDashboardAgentCompetencesActions.success(response.data));
  } catch {
    yield put(getDashboardAgentCompetencesActions.failure());
  }
}

function* getDashboardContractStatuses() {
  try {
    const response: AxiosResponse<ContractStatusesDashboard> = yield call(api.getDashboardContractStatuses);
    yield put(getDashboardContractStatusesActions.success(response.data));
  } catch {
    yield put(getDashboardContractStatusesActions.failure());
  }
}

function* getDashboardContractStatistics({
  payload
}: ReturnType<typeof getDashboardContractStatisticsActions.request>) {
  try {
    const response: AxiosResponse<ContractStatisticsDashboard> = yield call(
      api.getDashboardContractStatistics,
      payload
    );
    yield put(getDashboardContractStatisticsActions.success(response.data));
  } catch {
    yield put(getDashboardContractStatisticsActions.failure());
  }
}

/**
 * SAGAS - DASHBOARD NOTICE
 */
function* filterDashboardNotices({ payload }: ReturnType<typeof filterDashboardNoticesActions.request>) {
  try {
    const response: AxiosResponse<DashboardNoticeFilterPageResult> = yield call(api.filterDashboardNotices, payload);
    yield put(filterDashboardNoticesActions.success({ ...response.data, additionalItems: payload.additionalItems }));
  } catch {
    yield put(filterDashboardNoticesActions.failure());
  }
}

function* createDashboardNotice({ payload }: ReturnType<typeof createDashboardNoticeActions.request>) {
  try {
    const createDashboardNoticeResponse: AxiosResponse<DashboardNotice> = yield call(
      api.createDashboardNotice,
      payload
    );

    let attachmentsResponse: AxiosResponse<DashboardNoticeAttachment[]> | undefined = undefined;
    if (payload.filesToAdd?.get("files")) {
      attachmentsResponse = yield call(api.addDashboardNoticeAttachments, {
        id: createDashboardNoticeResponse.data.id,
        object: payload.filesToAdd
      });
    }

    yield put(
      createDashboardNoticeActions.success({
        ...createDashboardNoticeResponse.data,
        attachments: attachmentsResponse && attachmentsResponse.data ? attachmentsResponse?.data : []
      })
    );

    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();

    yield put(
      filterDashboardNoticesActions.request({
        pageIndex: 0,
        pageSize: PageSizes.HUGE
      })
    );
  } catch {
    yield put(createDashboardNoticeActions.failure());
  }
}

function* updateDashboardNotice({ payload }: ReturnType<typeof updateDashboardNoticeActions.request>) {
  try {
    if (payload.object.fileIdsToDelete?.length) {
      yield all(
        payload.object.fileIdsToDelete.map(attachmentId =>
          call(function* () {
            try {
              return (yield call(api.deleteDashboardNoticeAttachment, {
                id1: payload.id,
                id2: attachmentId
              })) as typeof updateDashboardNoticeActions.request;
            } catch {
              return undefined;
            }
          })
        )
      );
    }

    const updateNoticeResponse: AxiosResponse<DashboardNotice> = yield call(api.updateDashboardNotice, payload);

    let attachmentsResponse: AxiosResponse<DashboardNoticeAttachment[]> | undefined = undefined;
    if (payload.object.filesToAdd?.get("files")) {
      attachmentsResponse = yield call(api.addDashboardNoticeAttachments, {
        id: payload.id,
        object: payload.object.filesToAdd
      });
    }

    yield put(
      updateDashboardNoticeActions.success({
        ...updateNoticeResponse.data,
        attachments: attachmentsResponse?.data ?? updateNoticeResponse.data.attachments
      })
    );

    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateDashboardNoticeActions.failure());
  }
}

function* deleteDashboardNotice({ payload }: ReturnType<typeof deleteDashboardNoticeActions.request>) {
  try {
    yield call(api.deleteDashboardNotice, payload);
    yield put(deleteDashboardNoticeActions.success());

    messageUtils.itemDeletedNotification();

    yield put(
      filterDashboardNoticesActions.request({
        pageIndex: 0,
        pageSize: PageSizes.HUGE
      })
    );
  } catch {
    yield put(deleteDashboardNoticeActions.failure());
  }
}

function* downloadDashboardNoticeAttachment({
  payload
}: ReturnType<typeof downloadDashboardNoticeAttachmentActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadDashboardNoticeAttachment, payload);
    openBlobFile(response);
    yield put(downloadDashboardNoticeAttachmentActions.success());
  } catch {
    yield put(downloadDashboardNoticeAttachmentActions.failure());
  }
}

function* deleteDashboardNoticeAttachment({
  payload
}: ReturnType<typeof deleteDashboardNoticeAttachmentActions.request>) {
  try {
    yield call(api.deleteDashboardNoticeAttachment, payload);
    yield put(deleteDashboardNoticeAttachmentActions.success());
    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteDashboardNoticeAttachmentActions.failure());
  }
}

/**
 * SAGAS - DASHBOARD CONTACT
 */
function* searchDashboardContacts({ payload }: ReturnType<typeof searchDashboardContactsActions.request>) {
  try {
    const response: AxiosResponse<DashboardContactSearchResult> = yield call(api.searchDashboardContacts, payload);
    yield put(searchDashboardContactsActions.success(response.data));
  } catch {
    yield put(searchDashboardContactsActions.failure());
  }
}

function* createDashboardContact({ payload }: ReturnType<typeof createDashboardContactActions.request>) {
  try {
    const response: AxiosResponse<DashboardContact> = yield call(api.createDashboardContact, payload);
    yield put(createDashboardContactActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
    yield put(searchDashboardContactsActions.request({}));
  } catch {
    yield put(createDashboardContactActions.failure());
  }
}

function* updateDashboardContact({ payload }: ReturnType<typeof updateDashboardContactActions.request>) {
  try {
    const response: AxiosResponse<DashboardContact> = yield call(api.updateDashboardContact, payload);
    yield put(updateDashboardContactActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
    yield put(searchDashboardContactsActions.request({}));
  } catch {
    yield put(updateDashboardContactActions.failure());
  }
}

function* deleteDashboardContact({ payload }: ReturnType<typeof deleteDashboardContactActions.request>) {
  try {
    yield call(api.deleteDashboardContact, payload);
    yield put(deleteDashboardContactActions.success());
    messageUtils.itemDeletedNotification();
    yield put(searchDashboardContactsActions.request({}));
  } catch {
    yield put(deleteDashboardContactActions.failure());
  }
}

export function* dashboardSaga() {
  yield takeLatest(refreshDashboardAction, refreshDashboard);
  yield takeLatest(getDashboardStatisticsActions.request, getDashboardStatistics);
  yield takeLatest(getDashboardPersonalDatesActions.request, getDashboardPersonalDates);
  yield takeLatest(getDashboardAgentCompetencesActions.request, getDashboardAgentCompetences);
  yield takeLatest(getDashboardContractStatusesActions.request, getDashboardContractStatuses);
  yield takeLatest(getDashboardContractStatisticsActions.request, getDashboardContractStatistics);

  yield takeLatest(filterDashboardNoticesActions.request, filterDashboardNotices);
  yield takeLatest(createDashboardNoticeActions.request, createDashboardNotice);
  yield takeLatest(updateDashboardNoticeActions.request, updateDashboardNotice);
  yield takeLatest(deleteDashboardNoticeActions.request, deleteDashboardNotice);
  yield takeLatest(downloadDashboardNoticeAttachmentActions.request, downloadDashboardNoticeAttachment);
  yield takeLatest(deleteDashboardNoticeAttachmentActions.request, deleteDashboardNoticeAttachment);

  yield takeLatest(searchDashboardContactsActions.request, searchDashboardContacts);
  yield takeLatest(createDashboardContactActions.request, createDashboardContact);
  yield takeLatest(updateDashboardContactActions.request, updateDashboardContact);
  yield takeLatest(deleteDashboardContactActions.request, deleteDashboardContact);
}
