import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import t from "../../../app/i18n";
import { SecondaryButton } from "../../../common/components/buttons/SecondaryButton";
import HiddenInput from "../../../common/components/form/components/HiddenInput";
import ActionTextIcon from "../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../common/components/icons/AntIcon";
import { resolveFormValidationError, useFormErrorHandler } from "../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../common/utils/hooksUtils";
import { validations } from "../../../common/utils/validationUtils";
import type { UUID } from "../../../typings/global";
import { USER_ROUTE_PATHS } from "../../user/paths";
import { UserTotpDevice } from "../../user/types";
import { loginActions, sendTotpCodeViaSmsActions, verifyTotpCodeActions } from "../ducks";
import requests from "../requests";
import { LoginBaseRequest, VerifyTotpCodeRequest } from "../types";

interface Props {
  totpDevices: UserTotpDevice[];
  maskedUserPhone?: string;
  onFormSubmit: typeof loginActions.request;
  onLoginFormSubmit: (request: LoginBaseRequest) => void;
  onVerifyTotpCode: typeof verifyTotpCodeActions.request;
  onSendTotpCodeViaSms: typeof sendTotpCodeViaSmsActions.request;
}

type FormState = "email-and-password" | "email-and-password-submitted" | "totp-code" | "sent-totp-code-via-sms";
const SMS_TOTP_DEVICE_ID = "SMS";

const LoginForm = (props: Props) => {
  const [formState, setFormState] = useState<FormState>("email-and-password");
  const [count, setCount] = useState<number>(0);
  const [countIntervalId, setCountIntervalId] = useState<any>();
  const countIntervalIdRef = useRef<any>(null);
  const [form] = Form.useForm<LoginBaseRequest | VerifyTotpCodeRequest>();

  useFormErrorHandler(form, "login.attrs", [requests.LOGIN, requests.VERIFY_TOTP_CODE]);

  useEffect(() => {
    return () => {
      if (countIntervalIdRef.current) {
        clearInterval(countIntervalIdRef.current);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    countIntervalIdRef.current = countIntervalId;
  }, [countIntervalId]);

  useEffect(() => {
    if (count === 0 && countIntervalId) {
      clearInterval(countIntervalId);
    }
  }, [count, countIntervalId]);

  useRequestFinishedCallback([requests.LOGIN], () => {
    if (formState === "email-and-password-submitted") {
      const totpDeviceId = form.getFieldValue("totpDeviceId") as UUID;
      if (!props.totpDevices.some(device => device.id === totpDeviceId)) {
        form.setFieldValue(
          "totpDeviceId",
          props.totpDevices.length > 0 ? props.totpDevices[0]?.id : SMS_TOTP_DEVICE_ID
        );
      }
      setFormState("totp-code");
    }
  });

  useRequestFinishedCallback([requests.SEND_TOTP_CODE_VIA_SMS], () => {
    if (formState === "sent-totp-code-via-sms") {
      setCount(30);
      setCountIntervalId(setInterval(() => setCount(prevCount => prevCount - 1), 1000));
    }
  });

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        switch (formState) {
          case "email-and-password":
          case "email-and-password-submitted":
            setFormState("email-and-password-submitted");
            props.onLoginFormSubmit(values as LoginBaseRequest);
            break;
          case "totp-code":
          case "sent-totp-code-via-sms":
            const formData = values as VerifyTotpCodeRequest;
            props.onVerifyTotpCode({
              ...formData,
              totpDeviceId: formData.totpDeviceId === SMS_TOTP_DEVICE_ID ? undefined : formData.totpDeviceId
            });
            break;
        }
      })
      .catch(resolveFormValidationError);
  };

  const handleSendTotpCodeViaSmsClick = (): void => {
    setFormState("sent-totp-code-via-sms");
    const { email, password } = form.getFieldsValue() as LoginBaseRequest & VerifyTotpCodeRequest;
    props.onSendTotpCodeViaSms({
      email,
      password
    });
  };

  return (
    <>
      <h2 className="center-align margin-bottom-large">
        {formState === "email-and-password" || formState === "email-and-password-submitted"
          ? t("login.titles.loginForm")
          : t("login.titles.totpCodeForm")}
      </h2>

      <Row justify="center">
        <Col className="anonymous-form">
          <Form form={form} layout="vertical" name="loginForm">
            {(formState === "email-and-password" || formState === "email-and-password-submitted") && (
              <>
                <Form.Item
                  name="email"
                  label={t("login.attrs.email")}
                  rules={[validations.notBlank, validations.size(1, 254), validations.email]}
                >
                  <Input autoFocus prefix={<AntIcon type="user" className="icon-black-opacity" />} />
                </Form.Item>

                <Form.Item name="password" label={t("login.attrs.password")} rules={[validations.notBlank]}>
                  <Input.Password prefix={<AntIcon type="lock" className="icon-black-opacity" />} />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="margin-top-medium"
                    type="primary"
                    htmlType="submit"
                    block
                    onClick={handleFormSubmit}
                  >
                    {t("login.actions.loginSubmit")}
                  </Button>

                  <div className="center-align margin-top-medium">
                    <Link to={USER_ROUTE_PATHS.requestPasswordReset.to}>{t("login.actions.forgottenPassword")}</Link>
                  </div>
                </Form.Item>
              </>
            )}

            {(formState === "totp-code" || formState === "sent-totp-code-via-sms") && (
              <>
                <HiddenInput name="email" />

                <HiddenInput name="password" />

                <Form.Item
                  name="totpDeviceId"
                  label={t("login.attrs.totpDeviceId")}
                  rules={[validations.notNull]}
                  initialValue={props.totpDevices.length > 0 ? props.totpDevices[0]?.id : SMS_TOTP_DEVICE_ID}
                  className="left-align"
                >
                  <Select
                    options={[
                      ...props.totpDevices.map(device => ({
                        value: device.id,
                        label: (
                          <>
                            <AntIcon type="mobile" /> <Divider type="vertical" /> {device.name}
                          </>
                        )
                      })),
                      {
                        value: SMS_TOTP_DEVICE_ID,
                        label: (
                          <>
                            <AntIcon type="message" /> <Divider type="vertical" />{" "}
                            {t("login.helpers.sms") + " (" + props.maskedUserPhone + ")"}
                          </>
                        )
                      }
                    ]}
                  />
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(prev, next) => prev.totpDeviceId !== next.totpDeviceId}>
                  {({ getFieldValue }) => {
                    const totpDeviceId = getFieldValue("totpDeviceId");

                    return totpDeviceId !== SMS_TOTP_DEVICE_ID ? (
                      <Form.Item
                        name="totpCode"
                        label={t("login.attrs.totpCode")}
                        rules={[validations.notBlank, validations.length(6), validations.numeric]}
                      >
                        <Input autoFocus prefix={<AntIcon type="lock" className="icon-black-opacity" />} />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name="totpCode"
                        label={t("login.attrs.totpCode")}
                        rules={[validations.notBlank, validations.length(6), validations.numeric]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <SecondaryButton
                            onClick={handleSendTotpCodeViaSmsClick}
                            icon={!count && <AntIcon type="send" />}
                            disabled={!!count}
                          >
                            {!!count && `(${count}) `}
                            {t("user.actions.sendTotpCodeViaSms")}
                          </SecondaryButton>
                          <Input autoFocus prefix={<AntIcon type="lock" className="icon-black-opacity" />} />
                        </Space.Compact>
                      </Form.Item>
                    );
                  }}
                </Form.Item>

                <Form.Item
                  name="rememberMe"
                  className="form-item-without-label"
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue
                >
                  <Checkbox>{t("login.attrs.rememberMe")}</Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" block onClick={handleFormSubmit}>
                    {t("login.actions.verifyTotpCode")}
                  </Button>
                </Form.Item>

                <div className="center-align margin-top-medium">
                  <ActionTextIcon
                    color="blue"
                    icon="arrow-left"
                    text={t("user.actions.backToLogin")}
                    onClick={() => setFormState("email-and-password")}
                  />
                </div>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default LoginForm;
