import { AttachmentConfType } from "./enums";
import { AttachmentConf, FileAttachmentConf, UpdateAttachmentConf } from "./types";

export const isFileAttachmentConf = (attachmentConf: AttachmentConf): attachmentConf is FileAttachmentConf =>
  attachmentConf.type === AttachmentConfType.STATIC || attachmentConf.type === AttachmentConfType.DYNAMIC;

export const mapToUpdateAttachmentConf = (attachmentConf: AttachmentConf): UpdateAttachmentConf => {
  const result: UpdateAttachmentConf = {
    id: attachmentConf.id,
    optimisticLockVersion: attachmentConf.optimisticLockVersion,
    shouldAttachToContract: attachmentConf.shouldAttachToContract,
    shouldAttachToEmail: attachmentConf.shouldAttachToEmail,
    shouldEncrypt: attachmentConf.shouldEncrypt,
    filename: attachmentConf.filename
  };

  if (isFileAttachmentConf(attachmentConf)) {
    result.shouldAttachToCalc = attachmentConf.shouldAttachToCalc;
  }

  return result;
};
