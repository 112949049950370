import { Tag } from "antd";
import t from "../../../app/i18n";

interface Props {
  style?: React.CSSProperties;
}

const DeactivatedTag = ({ style }: Props) => (
  <Tag color="red">
    <span style={{ textTransform: "capitalize", ...style }}>{t("common.deactivated")}</span>
  </Tag>
);

export default DeactivatedTag;
