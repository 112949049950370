export const ADMIN_USER_ROUTE_PREFIX = "/admin/users";
export const CURRENT_USER_ROUTE_PREFIX = "/users/current";
export const USER_ROUTE_PREFIX = "/users";

export const ADMIN_USER_ROUTE_PATHS = {
  list: { path: "*", to: ADMIN_USER_ROUTE_PREFIX },
  create: { path: "new", to: `${ADMIN_USER_ROUTE_PREFIX}/new` },
  detail: { path: ":id", to: `${ADMIN_USER_ROUTE_PREFIX}/:id` }
};

export const CURRENT_USER_ROUTE_PATHS = {
  profile: { path: "profile", to: `${CURRENT_USER_ROUTE_PREFIX}/profile` },
  commissions: { path: "commissions", to: `${CURRENT_USER_ROUTE_PREFIX}/commissions` }
};

export const USER_ROUTE_PATHS = {
  confirm: { path: ":id/confirm", to: `${USER_ROUTE_PREFIX}/:id/confirm` },
  updateEmail: { path: ":id/update-email", to: `${USER_ROUTE_PREFIX}/:id/update-email` },
  updatePhone: { path: ":id/update-phone", to: `${USER_ROUTE_PREFIX}/:id/update-phone` },
  requestPasswordReset: { path: "request-password-reset", to: `${USER_ROUTE_PREFIX}/request-password-reset` },
  resetPassword: { path: ":id/reset-password", to: `${USER_ROUTE_PREFIX}/:id/reset-password` }
};
