import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { validationConstants, validations } from "../../../../common/utils/validationUtils";
import TopAgentSelect from "../../../enumerations/components/form/TopAgentSelect";
import { DynamicConfigPropertyFilterPageRequest, DynamicConfigPropertyFilterPageResult } from "../../types";

interface Props {
  initialFilter: DynamicConfigPropertyFilterPageResult;
  onFilterSubmit: (filter: DynamicConfigPropertyFilterPageRequest) => void;
  onCreateClick: () => void;
}

const ConfigPropertyFilterView = ({ initialFilter, onFilterSubmit, onCreateClick }: Props) => {
  const [form] = Form.useForm<DynamicConfigPropertyFilterPageRequest>();
  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: initialFilter.keyword,
      ownedByIds: initialFilter.ownedByIds || []
    });
  }, [initialFilter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("configProperty.titles.list")}</h2>}
      extra={
        <Button className="margin-right-small" type="primary" icon={<AntIcon type="plus" />} onClick={onCreateClick}>
          {t("configProperty.actions.createProperty")}
        </Button>
      }
    >
      <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="configPropertySearchViewForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.search")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("configProperty.helpers.propertySearchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <TopAgentSelect
              formItemProps={{
                name: "ownedByIds",
                label: t("configProperty.attrs.ownedById"),
                rules: [validations.none]
              }}
              selectProps={{
                mode: "multiple",
                maxTagCount: "responsive",
                allowClear: true
              }}
            />
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ConfigPropertyFilterView;
