import { Col, Form, Input, Modal, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import type { UUID } from "../../../../../typings/global";
import { SpecialCommission } from "../../../special/types";
import { requests } from "../../api";
import { excludeSpecialCommissionActions } from "../../ducks";
import { ExcludeSpecialCommission } from "../../types";

interface Props {
  open: boolean;
  batchId: UUID;
  agentId: UUID;
  commission?: SpecialCommission;
  onExclude: typeof excludeSpecialCommissionActions.request;
  onFormCancel: () => void;
}

const SpecialCommissionExcludeForm = ({ commission, batchId, agentId, open, onExclude, onFormCancel }: Props) => {
  const [form] = Form.useForm<ExcludeSpecialCommission>();
  useFormErrorHandler(form, "commissions.special.attrs", [requests.EXCLUDE_SPECIAL_COMMISSION]);

  useEffect(() => {
    if (open && commission) {
      form.setFieldsValue({ optimisticLockVersion: commission.optimisticLockVersion });
    }
  }, [open, commission, form]);

  const inProgress = useRequestFinishedCallback([requests.EXCLUDE_SPECIAL_COMMISSION], onFormCancel);

  const handleFormSubmit = (): void => {
    if (commission) {
      form
        .validateFields()
        .then(values => onExclude({ id1: batchId, id2: agentId, id3: commission.id, object: values }))
        .catch(resolveFormValidationError);
    }
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("commissions.batch.titles.excludeSpecialCommission")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="excludeSpecialCommissionForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="exclusionReason"
              label={t("commissions.special.attrs.exclusionReason")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SpecialCommissionExcludeForm;
