import { InsuranceType } from "../../../../../enums";
import { Insurance, InsuranceContract } from "../../../../../types";
import GenericInsuranceView from "./GenericInsuranceView";
import LifeInsuranceView from "./LifeInsuranceView";
import RealtyInsuranceView from "./RealtyInsuranceView";
import TravelInsuranceView from "./TravelInsuranceView";
import VehicleInsuranceView from "./VehicleInsuranceView";

interface Props {
  insurance: Insurance;
  insuranceIndex: number;
  contract: InsuranceContract;
  multipleInsurances: boolean;
  multipleClients: boolean;
}

const InsuranceView = ({ insurance, insuranceIndex, contract, multipleInsurances, multipleClients }: Props) => {
  switch (insurance.type) {
    case InsuranceType.MTPL:
    case InsuranceType.CRASH:
    case InsuranceType.GAP:
    case InsuranceType.PAS:
      return (
        <VehicleInsuranceView
          contract={contract}
          index={insuranceIndex}
          multipleInsurances={multipleInsurances}
          multipleClients={multipleClients}
        />
      );
    case InsuranceType.REALTY:
      return (
        <RealtyInsuranceView
          contract={contract}
          index={insuranceIndex}
          multipleInsurances={multipleInsurances}
          multipleClients={multipleClients}
        />
      );
    case InsuranceType.TRAVEL:
      return <TravelInsuranceView contract={contract} index={insuranceIndex} multipleInsurances={multipleInsurances} />;
    case InsuranceType.LIFE:
      return (
        <LifeInsuranceView
          contract={contract}
          index={insuranceIndex}
          multipleInsurances={multipleInsurances}
          multipleClients={multipleClients}
        />
      );
    case InsuranceType.GENERIC:
      return (
        <GenericInsuranceView
          contract={contract}
          index={insuranceIndex}
          multipleInsurances={multipleInsurances}
          multipleClients={multipleClients}
        />
      );
    default:
      return null;
  }
};

export default InsuranceView;
