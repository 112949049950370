import { Alert, Checkbox, Col, Form, Input, Modal, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import { AgentUpdateClientUserAccountWithUser, ClientUserAccountWithUserAgentView } from "../../../../user/types";
import { requests } from "../../../api";
import { updateClientUserAccountActions } from "../../../ducks";
import { Client } from "../../../types";

interface Props {
  client: Client;
  account?: ClientUserAccountWithUserAgentView;
  onFormSubmit: typeof updateClientUserAccountActions.request;
  onFormCancel: () => void;
}

const UpdateClientUserAccountForm = (props: Props) => {
  const [form] = Form.useForm<AgentUpdateClientUserAccountWithUser>();
  useFormErrorHandler(form, "user.attrs", [requests.UPDATE_CLIENT_USER_ACCOUNT]);

  useEffect(() => {
    if (props.account) {
      form.setFieldsValue({
        optimisticLockVersion: props.account.optimisticLockVersion,
        disabled: props.account.disabled,
        user: {
          optimisticLockVersion: props.account.user.optimisticLockVersion,
          name: props.account.user.name,
          locked: props.account.user.locked
        }
      });
    }
  }, [props.account, form]);

  const inProgress = useRequestFinishedCallback([requests.UPDATE_CLIENT_USER_ACCOUNT], props.onFormCancel);

  const handleFormSubmit = (): void => {
    const accountId = props.account?.id;
    if (accountId) {
      form
        .validateFields()
        .then(values => {
          props.onFormSubmit({
            id1: props.client.id,
            id2: accountId,
            object: values
          });
        })
        .catch(resolveFormValidationError);
    }
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={!!props.account}
      title={t("user.titles.updateAccount")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={props.onFormCancel}
    >
      <Form form={form} layout="vertical" name="clientUserAccountUpdateForm">
        <HiddenInput name="optimisticLockVersion" />
        <HiddenInput name={["user", "optimisticLockVersion"]} />

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name={["user", "name"]}
              label={t("user.attrs.name")}
              rules={[validations.notNull, validations.size(1, 255), validations.pattern(regexPatterns.nameRegex)]}
            >
              <Input placeholder={t("user.helpers.userNamePlaceholder")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item
              name="disabled"
              className="form-item-without-label"
              valuePropName="checked"
              initialValue={false}
              rules={[validations.none]}
            >
              <Checkbox>{t("user.attrs.disabled")}</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.user.locked !== next.user.locked}>
              {({ getFieldValue }) => (
                <Form.Item
                  name={["user", "locked"]}
                  className="form-item-without-label"
                  valuePropName="checked"
                  initialValue={false}
                  rules={[validations.none]}
                >
                  <Checkbox disabled={!getFieldValue(["user", "locked"])}>{t("user.attrs.user.locked")}</Checkbox>
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {props.account?.confirmed && <Alert message={t("user.helpers.updateInfo")} type="info" showIcon />}
    </Modal>
  );
};

export default UpdateClientUserAccountForm;
