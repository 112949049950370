import t from "../../../app/i18n";

export enum CommissionSource {
  COMMISSION_BASE_AMOUNT = "COMMISSION_BASE_AMOUNT",
  COMMISSION_AMOUNT = "COMMISSION_AMOUNT"
}

export const commissionSourceTMap = new Map<CommissionSource, string>(
  Object.values(CommissionSource).map(source => [source, t("commissions.settings.enums.commissionSource." + source)])
);
