import dayjs from "dayjs";
import t from "../../../../../app/i18n";
import CopyToClipboardView from "../../../../../common/components/views/CopyToClipboardView";
import HtmlView from "../../../../../common/components/views/HtmlView";
import { formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { SelfEmployedClient } from "../../../types";

interface Props {
  client: SelfEmployedClient;
}

const SelfEmployedClientDataView = ({ client }: Props) => (
  <table className="data-table-view">
    <tbody>
      <tr>
        <td>{t("client.attrs.companyName")}:</td>
        <td colSpan={3}>{client.aggregatedName}</td>
      </tr>
      <tr>
        <td>{t("client.attrs.aggregatedNaturalName")}:</td>
        <td colSpan={3}>{client.aggregatedNaturalName}</td>
      </tr>
      <tr>
        <td colSpan={4} />
      </tr>
      <tr>
        <td>{t("client.attrs.companyRegistrationNumber")}:</td>
        <td>
          <CopyToClipboardView content={client.companyRegistrationNumber} />
        </td>
        <td>{t("client.attrs.districtOffice")}:</td>
        <td>{client.districtOffice}</td>
      </tr>
      <tr>
        <td>{t("client.attrs.taxId")}:</td>
        <td>
          <CopyToClipboardView content={client.taxId} />
        </td>
        <td>{t("client.attrs.tradeRegisterNumber")}:</td>
        <td>{client.tradeRegisterNumber}</td>
      </tr>
      <tr>
        <td>{t("client.attrs.vatId")}:</td>
        <td>
          <CopyToClipboardView content={client.vatId} />
        </td>
        <td colSpan={2} />
      </tr>
      <tr>
        <td colSpan={4} />
      </tr>
      <tr>
        <td>{t("client.attrs.personalIdentificationNumberLong")}:</td>
        <td>
          <CopyToClipboardView content={client.personalIdentificationNumber} />
        </td>
        <td>{t("client.attrs.birthDate")}:</td>
        <td>{`${formatLocaleDate(client.birthDate)} (${t("client.helpers.age")}: ${dayjs().diff(
          dayjs(client.birthDate),
          "years"
        )})`}</td>
      </tr>
      <tr>
        <td>{t("client.attrs.identityCardNumber")}:</td>
        <td>
          <CopyToClipboardView content={client.identityCardNumber} />
        </td>
        <td>{t("client.attrs.nationalityLabel")}:</td>
        <td>{client.nationality}</td>
      </tr>
      <tr>
        <td colSpan={4} />
      </tr>
      <tr>
        <td colSpan={4}>{t("client.attrs.note")}:</td>
      </tr>
      {client.note && (
        <tr>
          <td colSpan={4}>
            <HtmlView value={client.note} />
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

export default SelfEmployedClientDataView;
