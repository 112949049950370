import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { AcademicDegreesAfter } from "../../../../common/constants";
import { selectStandardProps } from "../../../../common/utils/formUtils";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
}

const AcademicDegreeAfterSelect = ({ formItemProps, selectProps }: Props) => (
  <Form.Item {...formItemProps}>
    <Select
      {...selectStandardProps}
      {...selectProps}
      options={AcademicDegreesAfter.map(degree => ({ value: degree, label: degree }))}
    />
  </Form.Item>
);

export default AcademicDegreeAfterSelect;
