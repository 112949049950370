import { Button, ButtonProps, ConfigProvider } from "antd";
import { cssVariables } from "../../utils/utils";
import AntIcon, { type AntIconType } from "../icons/AntIcon";

type Props = Omit<ButtonProps, "color"> & {
  icon: AntIconType;
  children: React.ReactNode;
  color?: "default" | "danger" | "primary" | "green" | "orange";
};

const ActionButton = ({ icon, color = "default", size = "small", variant = "dashed", children, ...props }: Props) => {
  if (color === "green" || color === "orange") {
    return (
      <ConfigProvider
        theme={{ token: { colorPrimary: color === "green" ? cssVariables.colorGreen : cssVariables.colorOrange } }}
      >
        <Button size={size} icon={<AntIcon type={icon} />} variant={variant} color="primary" {...props}>
          {children}
        </Button>
      </ConfigProvider>
    );
  }

  return (
    <Button size={size} icon={<AntIcon type={icon} />} variant={variant} color={color} {...props}>
      {children}
    </Button>
  );
};

export default ActionButton;
