export enum ContactType {
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE_NUMBER",
  OTHER = "OTHER"
}

export enum MarketingConsent {
  GRANTED = "GRANTED",
  NOT_GRANTED = "NOT_GRANTED"
}

export enum Country {
  SVK = "SVK", //Slovakia
  CZE = "CZE", //Czech Republic
  AFG = "AFG", //Afghanistan
  ALA = "ALA", //Åland Islands
  ALB = "ALB", //Albania
  DZA = "DZA", //Algeria
  ASM = "ASM", //American Samoa
  AND = "AND", //Andorra
  AGO = "AGO", //Angola
  AIA = "AIA", //Anguilla
  ATA = "ATA", //Antarctica
  ATG = "ATG", //Antigua and Barbuda
  ARG = "ARG", //Argentina
  ARM = "ARM", //Armenia
  ABW = "ABW", //Aruba
  AUS = "AUS", //Australia
  AUT = "AUT", //Austria
  AZE = "AZE", //Azerbaijan
  BHS = "BHS", //Bahamas
  BHR = "BHR", //Bahrain
  BGD = "BGD", //Bangladesh
  BRB = "BRB", //Barbados
  BLR = "BLR", //Belarus
  BEL = "BEL", //Belgium
  BLZ = "BLZ", //Belize
  BEN = "BEN", //Benin
  BMU = "BMU", //Bermuda
  BTN = "BTN", //Bhutan
  BOL = "BOL", //Bolivia, Plurinational State of
  BES = "BES", //Bonaire, Sint Eustatius and Saba
  BIH = "BIH", //Bosnia and Herzegovina
  BWA = "BWA", //Botswana
  BVT = "BVT", //Bouvet Island
  BRA = "BRA", //Brazil
  IOT = "IOT", //British Indian Ocean Territory
  BRN = "BRN", //Brunei Darussalam
  BGR = "BGR", //Bulgaria
  BFA = "BFA", //Burkina Faso
  BDI = "BDI", //Burundi
  KHM = "KHM", //Cambodia
  CMR = "CMR", //Cameroon
  CAN = "CAN", //Canada
  CPV = "CPV", //Cabo Verde
  CYM = "CYM", //Cayman Islands
  CAF = "CAF", //Central African Republic
  TCD = "TCD", //Chad
  CHL = "CHL", //Chile
  CHN = "CHN", //China
  CXR = "CXR", //Christmas Island
  CCK = "CCK", //Cocos (Keeling) Islands
  COL = "COL", //Colombia
  COM = "COM", //Comoros
  COG = "COG", //Congo
  COD = "COD", //Congo, the Democratic Republic of the
  COK = "COK", //Cook Islands
  CRI = "CRI", //Costa Rica
  CIV = "CIV", //Côte d'Ivoire
  HRV = "HRV", //Croatia
  CUB = "CUB", //Cuba
  CUW = "CUW", //Curaçao
  CYP = "CYP", //Cyprus
  DNK = "DNK", //Denmark
  DJI = "DJI", //Djibouti
  DMA = "DMA", //Dominica
  DOM = "DOM", //Dominican Republic
  ECU = "ECU", //Ecuador
  EGY = "EGY", //Egypt
  SLV = "SLV", //El Salvador
  GNQ = "GNQ", //Equatorial Guinea
  ERI = "ERI", //Eritrea
  EST = "EST", //Estonia
  ETH = "ETH", //Ethiopia
  FLK = "FLK", //Falkland Islands (Malvinas)
  FRO = "FRO", //Faroe Islands
  FJI = "FJI", //Fiji
  FIN = "FIN", //Finland
  FRA = "FRA", //France
  GUF = "GUF", //French Guiana
  PYF = "PYF", //French Polynesia
  ATF = "ATF", //French Southern Territories
  GAB = "GAB", //Gabon
  GMB = "GMB", //Gambia
  GEO = "GEO", //Georgia
  DEU = "DEU", //Germany
  GHA = "GHA", //Ghana
  GIB = "GIB", //Gibraltar
  GRC = "GRC", //Greece
  GRL = "GRL", //Greenland
  GRD = "GRD", //Grenada
  GLP = "GLP", //Guadeloupe
  GUM = "GUM", //Guam
  GTM = "GTM", //Guatemala
  GGY = "GGY", //Guernsey
  GIN = "GIN", //Guinea
  GNB = "GNB", //Guinea-Bissau
  GUY = "GUY", //Guyana
  HTI = "HTI", //Haiti
  HMD = "HMD", //Heard Island and McDonald Islands
  VAT = "VAT", //Holy See (Vatican City State)
  HND = "HND", //Honduras
  HKG = "HKG", //Hong Kong
  HUN = "HUN", //Hungary
  ISL = "ISL", //Iceland
  IND = "IND", //India
  IDN = "IDN", //Indonesia
  IRN = "IRN", //Iran, Islamic Republic of
  IRQ = "IRQ", //Iraq
  IRL = "IRL", //Ireland
  IMN = "IMN", //Isle of Man
  ISR = "ISR", //Israel
  ITA = "ITA", //Italy
  JAM = "JAM", //Jamaica
  JPN = "JPN", //Japan
  JEY = "JEY", //Jersey
  JOR = "JOR", //Jordan
  KAZ = "KAZ", //Kazakhstan
  KEN = "KEN", //Kenya
  KIR = "KIR", //Kiribati
  PRK = "PRK", //Korea, Democratic People's Republic of
  KOR = "KOR", //Korea, Republic of
  KWT = "KWT", //Kuwait
  KGZ = "KGZ", //Kyrgyzstan
  LAO = "LAO", //Lao People's Democratic Republic
  LVA = "LVA", //Latvia
  LBN = "LBN", //Lebanon
  LSO = "LSO", //Lesotho
  LBR = "LBR", //Liberia
  LBY = "LBY", //Libya
  LIE = "LIE", //Liechtenstein
  LTU = "LTU", //Lithuania
  LUX = "LUX", //Luxembourg
  MAC = "MAC", //Macao
  MKD = "MKD", //Macedonia, the former Yugoslav Republic of
  MDG = "MDG", //Madagascar
  MWI = "MWI", //Malawi
  MYS = "MYS", //Malaysia
  MDV = "MDV", //Maldives
  MLI = "MLI", //Mali
  MLT = "MLT", //Malta
  MHL = "MHL", //Marshall Islands
  MTQ = "MTQ", //Martinique
  MRT = "MRT", //Mauritania
  MUS = "MUS", //Mauritius
  MYT = "MYT", //Mayotte
  MEX = "MEX", //Mexico
  FSM = "FSM", //Micronesia, Federated States of
  MDA = "MDA", //Moldova, Republic of
  MCO = "MCO", //Monaco
  MNG = "MNG", //Mongolia
  MNE = "MNE", //Montenegro
  MSR = "MSR", //Montserrat
  MAR = "MAR", //Morocco
  MOZ = "MOZ", //Mozambique
  MMR = "MMR", //Myanmar
  NAM = "NAM", //Namibia
  NRU = "NRU", //Nauru
  NPL = "NPL", //Nepal
  NLD = "NLD", //Netherlands
  NCL = "NCL", //New Caledonia
  NZL = "NZL", //New Zealand
  NIC = "NIC", //Nicaragua
  NER = "NER", //Niger
  NGA = "NGA", //Nigeria
  NIU = "NIU", //Niue
  NFK = "NFK", //Norfolk Island
  MNP = "MNP", //Northern Mariana Islands
  NOR = "NOR", //Norway
  OMN = "OMN", //Oman
  PAK = "PAK", //Pakistan
  PLW = "PLW", //Palau
  PSE = "PSE", //Palestine, State of
  PAN = "PAN", //Panama
  PNG = "PNG", //Papua New Guinea
  PRY = "PRY", //Paraguay
  PER = "PER", //Peru
  PHL = "PHL", //Philippines
  PCN = "PCN", //Pitcairn
  POL = "POL", //Poland
  PRT = "PRT", //Portugal
  PRI = "PRI", //Puerto Rico
  QAT = "QAT", //Qatar
  REU = "REU", //Réunion
  ROU = "ROU", //Romania
  RUS = "RUS", //Russian Federation
  RWA = "RWA", //Rwanda
  BLM = "BLM", //Saint Barthélemy
  SHN = "SHN", //Saint Helena, Ascension and Tristan da Cunha
  KNA = "KNA", //Saint Kitts and Nevis
  LCA = "LCA", //Saint Lucia
  MAF = "MAF", //Saint Martin (French part)
  SPM = "SPM", //Saint Pierre and Miquelon
  VCT = "VCT", //Saint Vincent and the Grenadines
  WSM = "WSM", //Samoa
  SMR = "SMR", //San Marino
  STP = "STP", //Sao Tome and Principe
  SAU = "SAU", //Saudi Arabia
  SEN = "SEN", //Senegal
  SRB = "SRB", //Serbia
  SYC = "SYC", //Seychelles
  SLE = "SLE", //Sierra Leone
  SGP = "SGP", //Singapore
  SXM = "SXM", //Sint Maarten (Dutch part)
  SVN = "SVN", //Slovenia
  SLB = "SLB", //Solomon Islands
  SOM = "SOM", //Somalia
  ZAF = "ZAF", //South Africa
  SGS = "SGS", //South Georgia and the South Sandwich Islands
  SSD = "SSD", //South Sudan
  ESP = "ESP", //Spain
  LKA = "LKA", //Sri Lanka
  SDN = "SDN", //Sudan
  SUR = "SUR", //Suriname
  SJM = "SJM", //Svalbard and Jan Mayen
  SWZ = "SWZ", //Swaziland
  SWE = "SWE", //Sweden
  CHE = "CHE", //Switzerland
  SYR = "SYR", //Syrian Arab Republic
  TWN = "TWN", //Taiwan, Province of China
  TJK = "TJK", //Tajikistan
  TZA = "TZA", //Tanzania, United Republic of
  THA = "THA", //Thailand
  TLS = "TLS", //Timor-Leste
  TGO = "TGO", //Togo
  TKL = "TKL", //Tokelau
  TON = "TON", //Tonga
  TTO = "TTO", //Trinidad and Tobago
  TUN = "TUN", //Tunisia
  TUR = "TUR", //Turkey
  TKM = "TKM", //Turkmenistan
  TCA = "TCA", //Turks and Caicos Islands
  TUV = "TUV", //Tuvalu
  UGA = "UGA", //Uganda
  UKR = "UKR", //Ukraine
  ARE = "ARE", //United Arab Emirates
  GBR = "GBR", //United Kingdom
  USA = "USA", //United States
  UMI = "UMI", //United States Minor Outlying Islands
  URY = "URY", //Uruguay
  UZB = "UZB", //Uzbekistan
  VUT = "VUT", //Vanuatu
  VEN = "VEN", //Venezuela, Bolivarian Republic of
  VNM = "VNM", //Viet Nam
  VGB = "VGB", //Virgin Islands, British
  VIR = "VIR", //Virgin Islands, U.S.
  WLF = "WLF", //Wallis and Futuna
  ESH = "ESH", //Western Sahara
  YEM = "YEM", //Yemen
  ZMB = "ZMB", //Zambia
  ZWE = "ZWE" //Zimbabwe
}

export enum CountryTwoLetterCode {
  SVK = "SK", //Slovakia
  CZE = "CZ", //Czech Republic
  AFG = "AF", //Afghanistan
  ALA = "AX", //Åland Islands
  ALB = "AL", //Albania
  DZA = "DZ", //Algeria
  ASM = "AS", //American Samoa
  AND = "AD", //Andorra
  AGO = "AO", //Angola
  AIA = "AI", //Anguilla
  ATA = "AQ", //Antarctica
  ATG = "AG", //Antigua and Barbuda
  ARG = "AR", //Argentina
  ARM = "AM", //Armenia
  ABW = "AW", //Aruba
  AUS = "AU", //Australia
  AUT = "AT", //Austria
  AZE = "AZ", //Azerbaijan
  BHS = "BS", //Bahamas
  BHR = "BH", //Bahrain
  BGD = "BD", //Bangladesh
  BRB = "BB", //Barbados
  BLR = "BY", //Belarus
  BEL = "BE", //Belgium
  BLZ = "BZ", //Belize
  BEN = "BJ", //Benin
  BMU = "BM", //Bermuda
  BTN = "BT", //Bhutan
  BOL = "BO", //Bolivia, Plurinational State of
  BES = "BQ", //Bonaire, Sint Eustatius and Saba
  BIH = "BA", //Bosnia and Herzegovina
  BWA = "BW", //Botswana
  BVT = "BV", //Bouvet Island
  BRA = "BR", //Brazil
  IOT = "IO", //British Indian Ocean Territory
  BRN = "BN", //Brunei Darussalam
  BGR = "BG", //Bulgaria
  BFA = "BF", //Burkina Faso
  BDI = "BI", //Burundi
  KHM = "KH", //Cambodia
  CMR = "CM", //Cameroon
  CAN = "CA", //Canada
  CPV = "CV", //Cabo Verde
  CYM = "KY", //Cayman Islands
  CAF = "CF", //Central African Republic
  TCD = "TD", //Chad
  CHL = "CL", //Chile
  CHN = "CN", //China
  CXR = "CX", //Christmas Island
  CCK = "CC", //Cocos (Keeling) Islands
  COL = "CO", //Colombia
  COM = "KM", //Comoros
  COG = "CG", //Congo
  COD = "CD", //Congo, the Democratic Republic of the
  COK = "CK", //Cook Islands
  CRI = "CR", //Costa Rica
  CIV = "CI", //Côte d'Ivoire
  HRV = "HR", //Croatia
  CUB = "CU", //Cuba
  CUW = "CW", //Curaçao
  CYP = "CY", //Cyprus
  DNK = "DK", //Denmark
  DJI = "DJ", //Djibouti
  DMA = "DM", //Dominica
  DOM = "DO", //Dominican Republic
  ECU = "EC", //Ecuador
  EGY = "EG", //Egypt
  SLV = "SV", //El Salvador
  GNQ = "GQ", //Equatorial Guinea
  ERI = "ER", //Eritrea
  EST = "EE", //Estonia
  ETH = "ET", //Ethiopia
  FLK = "FK", //Falkland Islands (Malvinas)
  FRO = "FO", //Faroe Islands
  FJI = "FJ", //Fiji
  FIN = "FI", //Finland
  FRA = "FR", //France
  GUF = "GF", //French Guiana
  PYF = "PF", //French Polynesia
  ATF = "TF", //French Southern Territories
  GAB = "GA", //Gabon
  GMB = "GM", //Gambia
  GEO = "GE", //Georgia
  DEU = "DE", //Germany
  GHA = "GH", //Ghana
  GIB = "GI", //Gibraltar
  GRC = "GR", //Greece
  GRL = "GL", //Greenland
  GRD = "GD", //Grenada
  GLP = "GP", //Guadeloupe
  GUM = "GU", //Guam
  GTM = "GT", //Guatemala
  GGY = "GG", //Guernsey
  GIN = "GN", //Guinea
  GNB = "GW", //Guinea-Bissau
  GUY = "GY", //Guyana
  HTI = "HT", //Haiti
  HMD = "HM", //Heard Island and McDonald Islands
  VAT = "VA", //Holy See (Vatican City State)
  HND = "HN", //Honduras
  HKG = "HK", //Hong Kong
  HUN = "HU", //Hungary
  ISL = "IS", //Iceland
  IND = "IN", //India
  IDN = "ID", //Indonesia
  IRN = "IR", //Iran, Islamic Republic of
  IRQ = "IQ", //Iraq
  IRL = "IE", //Ireland
  IMN = "IM", //Isle of Man
  ISR = "IL", //Israel
  ITA = "IT", //Italy
  JAM = "JM", //Jamaica
  JPN = "JP", //Japan
  JEY = "JE", //Jersey
  JOR = "JO", //Jordan
  KAZ = "KZ", //Kazakhstan
  KEN = "KE", //Kenya
  KIR = "KI", //Kiribati
  PRK = "KP", //Korea, Democratic People's Republic of
  KOR = "KR", //Korea, Republic of
  KWT = "KW", //Kuwait
  KGZ = "KG", //Kyrgyzstan
  LAO = "LA", //Lao People's Democratic Republic
  LVA = "LV", //Latvia
  LBN = "LB", //Lebanon
  LSO = "LS", //Lesotho
  LBR = "LR", //Liberia
  LBY = "LY", //Libya
  LIE = "LI", //Liechtenstein
  LTU = "LT", //Lithuania
  LUX = "LU", //Luxembourg
  MAC = "MO", //Macao
  MKD = "MK", //Macedonia, the former Yugoslav Republic of
  MDG = "MG", //Madagascar
  MWI = "MW", //Malawi
  MYS = "MY", //Malaysia
  MDV = "MV", //Maldives
  MLI = "ML", //Mali
  MLT = "MT", //Malta
  MHL = "MH", //Marshall Islands
  MTQ = "MQ", //Martinique
  MRT = "MR", //Mauritania
  MUS = "MU", //Mauritius
  MYT = "YT", //Mayotte
  MEX = "MX", //Mexico
  FSM = "FM", //Micronesia, Federated States of
  MDA = "MD", //Moldova, Republic of
  MCO = "MC", //Monaco
  MNG = "MN", //Mongolia
  MNE = "ME", //Montenegro
  MSR = "MS", //Montserrat
  MAR = "MA", //Morocco
  MOZ = "MZ", //Mozambique
  MMR = "MM", //Myanmar
  NAM = "NA", //Namibia
  NRU = "NR", //Nauru
  NPL = "NP", //Nepal
  NLD = "NL", //Netherlands
  NCL = "NC", //New Caledonia
  NZL = "NZ", //New Zealand
  NIC = "NI", //Nicaragua
  NER = "NE", //Niger
  NGA = "NG", //Nigeria
  NIU = "NU", //Niue
  NFK = "NF", //Norfolk Island
  MNP = "MP", //Northern Mariana Islands
  NOR = "NO", //Norway
  OMN = "OM", //Oman
  PAK = "PK", //Pakistan
  PLW = "PW", //Palau
  PSE = "PS", //Palestine, State of
  PAN = "PA", //Panama
  PNG = "PG", //Papua New Guinea
  PRY = "PY", //Paraguay
  PER = "PE", //Peru
  PHL = "PH", //Philippines
  PCN = "PN", //Pitcairn
  POL = "PL", //Poland
  PRT = "PT", //Portugal
  PRI = "PR", //Puerto Rico
  QAT = "QA", //Qatar
  REU = "RE", //Réunion
  ROU = "RO", //Romania
  RUS = "RU", //Russian Federation
  RWA = "RW", //Rwanda
  BLM = "BL", //Saint Barthélemy
  SHN = "SH", //Saint Helena, Ascension and Tristan da Cunha
  KNA = "KN", //Saint Kitts and Nevis
  LCA = "LC", //Saint Lucia
  MAF = "MF", //Saint Martin (French part)
  SPM = "PM", //Saint Pierre and Miquelon
  VCT = "VC", //Saint Vincent and the Grenadines
  WSM = "WS", //Samoa
  SMR = "SM", //San Marino
  STP = "ST", //Sao Tome and Principe
  SAU = "SA", //Saudi Arabia
  SEN = "SN", //Senegal
  SRB = "RS", //Serbia
  SYC = "SC", //Seychelles
  SLE = "SL", //Sierra Leone
  SGP = "SG", //Singapore
  SXM = "SX", //Sint Maarten (Dutch part)
  SVN = "SI", //Slovenia
  SLB = "SB", //Solomon Islands
  SOM = "SO", //Somalia
  ZAF = "ZA", //South Africa
  SGS = "GS", //South Georgia and the South Sandwich Islands
  SSD = "SS", //South Sudan
  ESP = "ES", //Spain
  LKA = "LK", //Sri Lanka
  SDN = "SD", //Sudan
  SUR = "SR", //Suriname
  SJM = "SJ", //Svalbard and Jan Mayen
  SWZ = "SZ", //Swaziland
  SWE = "SE", //Sweden
  CHE = "CH", //Switzerland
  SYR = "SY", //Syrian Arab Republic
  TWN = "TW", //Taiwan, Province of China
  TJK = "TJ", //Tajikistan
  TZA = "TZ", //Tanzania, United Republic of
  THA = "TH", //Thailand
  TLS = "TL", //Timor-Leste
  TGO = "TG", //Togo
  TKL = "TK", //Tokelau
  TON = "TO", //Tonga
  TTO = "TT", //Trinidad and Tobago
  TUN = "TN", //Tunisia
  TUR = "TR", //Turkey
  TKM = "TM", //Turkmenistan
  TCA = "TC", //Turks and Caicos Islands
  TUV = "TV", //Tuvalu
  UGA = "UG", //Uganda
  UKR = "UA", //Ukraine
  ARE = "AE", //United Arab Emirates
  GBR = "GB", //United Kingdom
  USA = "US", //United States
  UMI = "UM", //United States Minor Outlying Islands
  URY = "UY", //Uruguay
  UZB = "UZ", //Uzbekistan
  VUT = "VU", //Vanuatu
  VEN = "VE", //Venezuela, Bolivarian Republic of
  VNM = "VN", //Viet Nam
  VGB = "VG", //Virgin Islands, British
  VIR = "VI", //Virgin Islands, U.S.
  WLF = "WF", //Wallis and Futuna
  ESH = "EH", //Western Sahara
  YEM = "YE", //Yemen
  ZMB = "ZM", //Zambia
  ZWE = "ZW" //Zimbabwe
}
