import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { CalcBlacklistAction, calcBlacklistReducer, calcBlacklistSaga } from "./blacklist/ducks";
import { CalcAction, calcsReducer, calcsSaga } from "./calcs/ducks";
import { CalcDraftAction, calcDraftsReducer, calcDraftsSaga } from "./drafts/ducks";
import { CalcRecordAction, calcRecordsReducer, calcRecordsSaga } from "./records/ducks";
import { CalcSettingsAction, calcSettingsReducer, calcSettingsSaga } from "./settings/ducks";
import { CalculatorReducerState } from "./types";

/**
 * ACTIONS
 */
export type CalculatorAction =
  | CalcAction
  | CalcBlacklistAction
  | CalcDraftAction
  | CalcRecordAction
  | CalcSettingsAction;

/**
 * REDUCERS
 */
export const calculatorReducer = combineReducers<CalculatorReducerState>({
  blacklist: calcBlacklistReducer,
  calcs: calcsReducer,
  records: calcRecordsReducer,
  settings: calcSettingsReducer,
  drafts: calcDraftsReducer
});

/**
 * SAGAS
 */
export function* calculatorSaga() {
  yield all([calcBlacklistSaga(), calcsSaga(), calcRecordsSaga(), calcSettingsSaga(), calcDraftsSaga()]);
}
