import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { selectPermissions } from "../../../modules/auth/ducks";
import NotFound from "../../pages/NotFound/NotFound";
import { RootState } from "../../types";
import { useAreParamsIdsValid } from "../../utils/hooksUtils";
import { hasAllPermissions, hasAnyPermission } from "../../utils/utils";
import { Permission } from "./enums";

interface Props {
  component: ReactElement;
  permissions: Permission[];
  anyPermission?: boolean;
}

const PageWithPermission = ({ component, permissions, anyPermission }: Props) => {
  const areParamsIdsValid = useAreParamsIdsValid();
  const accountPermissions = useSelector<RootState, Permission[]>(selectPermissions);

  const shouldRender = anyPermission
    ? hasAnyPermission(accountPermissions, permissions)
    : hasAllPermissions(accountPermissions, permissions);

  return areParamsIdsValid && shouldRender ? component : <NotFound />;
};

export default PageWithPermission;
