import { Button } from "antd";
import t from "../../../../app/i18n";
import { SecondaryButton } from "../../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../../common/components/icons/AntIcon";

interface Props {
  hasResults: boolean;
  onFormSubmit: () => void;
  onShowResultsClick: () => void;
  onResetCalculatorClick: () => void;
}

const CalcNavigation = (props: Props) => (
  <div className="bottom-fixed-navigation">
    <Button icon={<AntIcon type="reload" />} onClick={props.onResetCalculatorClick}>
      {t("common.resetCalculator")}
    </Button>

    <Button icon={<AntIcon type="calculator" />} type="primary" size="large" onClick={props.onFormSubmit}>
      {t("common.calculate")}
    </Button>

    <SecondaryButton
      icon={<AntIcon type="double-left" />}
      onClick={props.onShowResultsClick}
      disabled={!props.hasResults}
    >
      {t("common.showResults")}
    </SecondaryButton>
  </div>
);

export default CalcNavigation;
