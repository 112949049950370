import { Badge, Dropdown } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { RootState } from "../../../../common/types";
import { selectNotificationsHeaderList } from "../../../../modules/notifications/ducks";
import { NotificationsHeaderList } from "../../../../modules/notifications/types";
import HeaderNotificationDropdown from "./HeaderNotificationDropdown";

const HeaderNotificationView = () => {
  const notificationsList = useSelector<RootState, NotificationsHeaderList>(selectNotificationsHeaderList);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Dropdown
      placement="bottomRight"
      overlayStyle={{ width: 400 }}
      dropdownRender={() => (
        <HeaderNotificationDropdown notificationsList={notificationsList} onDropdownClose={() => setOpen(false)} />
      )}
      open={open}
      onOpenChange={setOpen}
      trigger={["click"]}
    >
      <span style={{ display: "inline-block", cursor: "pointer", transition: "all 0.3s", padding: "0 12px" }}>
        <Badge count={notificationsList.totalUnseenCount} style={{ fontSize: 14 }}>
          <AntIcon type="bell" style={{ fontSize: 18, verticalAlign: "middle" }} />
        </Badge>
      </span>
    </Dropdown>
  );
};

export default HeaderNotificationView;
