import { Tooltip } from "antd";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import t from "../../../app/i18n";
import messageUtils from "../../utils/messageUtils";
import AntIcon from "../icons/AntIcon";
import styles from "./CopyToClipboardView.module.scss";

interface Props {
  content?: string;
  wrapper?: React.ReactElement | string;
}

const CopyToClipboardView = ({ content, wrapper }: Props) => {
  const [isHovered, setHovered] = useState(false);

  return content ? (
    <span
      className="copy-to-clipboard-view"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {wrapper ?? content}&nbsp;
      <CopyToClipboard text={content} onCopy={() => messageUtils.infoMessage(t("common.clipboardCopied"))}>
        <Tooltip title={t("common.copy")} mouseEnterDelay={0.5}>
          <span>
            <AntIcon
              type="copy"
              style={isHovered ? { visibility: "visible" } : undefined}
              className={styles.copyToClipboardViewAnticon}
            />
          </span>
        </Tooltip>
      </CopyToClipboard>
    </span>
  ) : null;
};

export default CopyToClipboardView;
