import { AxiosResponse } from "axios";
import { Pathname } from "history";
import { generatePath } from "react-router-dom";
import { combineReducers } from "redux";
import { push, replace } from "redux-first-history";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import t, { DEFAULT_LOCALE } from "../../../app/i18n";
import {
  EntityIdObject,
  EntityObject,
  OptimisticLockVersion,
  RootState,
  ThreeLevelEntityObject,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../../common/types";
import { initPageResult, initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { openBlobFile, replaceInArray } from "../../../common/utils/utils";
import type { UUID } from "../../../typings/global";
import { changeRunningRequestKeyAction, selectRouterLocationPathname } from "../../ducks";
import { InstitutionType } from "../../institution/enums";
import { BailAccountMovement } from "../bailaccount/types";
import {
  SpecialCommission,
  SpecialCommissionsFilterPageRequest,
  SpecialCommissionsFilterPageResult
} from "../special/types";
import api from "./api";
import { CommissionsBatchAttachmentType } from "./enums";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "./paths";
import {
  CalculateBatchPaymentToBePaid,
  ChangeCommissionsBatchStepRequest,
  Commission,
  CommissionsBatch,
  CommissionsBatchFilterPageRequest,
  CommissionsBatchFilterPageResult,
  CommissionsBatchInputAttachment,
  CommissionsBatchReducerState,
  CommissionsFilterPageRequest,
  CommissionsFilterPageResult,
  CommissionsUnit,
  CreateCommissionsUnit,
  CreateManuallyAddedCommission,
  CreateUpdateCommissionsBatch,
  DeleteCommissionsUnitRequest,
  DownloadBatchOutputAttachmentsAsZip,
  ExcludeSpecialCommission,
  GenerateBatchPaymentOrder,
  ManualBailAccountMovementsFilterPageRequest,
  ManualBailAccountMovementsFilterPageResult,
  PostponeCommission,
  RecalculateCommissionsForContractRequest,
  UpdateCalculatedCommissionList,
  UpdateCommission,
  UpdateCommissionsUnit
} from "./types";

/**
 * ACTIONS - BATCH
 */
export const filterCommissionsBatchesActions = createAsyncAction(
  "commissions-batch/FILTER_REQUEST",
  "commissions-batch/FILTER_SUCCESS",
  "commissions-batch/FILTER_FAILURE"
)<CommissionsBatchFilterPageRequest, CommissionsBatchFilterPageResult, void>();

export const getCommissionsBatchActions = createAsyncAction(
  "commissions-batch/GET_REQUEST",
  "commissions-batch/GET_SUCCESS",
  "commissions-batch/GET_FAILURE"
)<EntityIdObject, CommissionsBatch, void>();

export const calculateCommissionsBatchPaymentToBePaidActions = createAsyncAction(
  "commissions-batch/CALCULATE_PAYMENT_TO_BE_PAID_REQUEST",
  "commissions-batch/CALCULATE_PAYMENT_TO_BE_PAID_SUCCESS",
  "commissions-batch/CALCULATE_PAYMENT_TO_BE_PAID_FAILURE"
)<EntityObject<CalculateBatchPaymentToBePaid>, number, void>();

export const generateCommissionsBatchPaymentOrderActions = createAsyncAction(
  "commissions-batch/GENERATE_PAYMENT_ORDER_REQUEST",
  "commissions-batch/GENERATE_PAYMENT_ORDER_SUCCESS",
  "commissions-batch/GENERATE_PAYMENT_ORDER_FAILURE"
)<EntityObject<GenerateBatchPaymentOrder>, void, void>();

export const downloadCommissionsBatchAttachmentsAsZipActions = createAsyncAction(
  "commissions-batch/DOWNLOAD_ATTACHMENTS_AS_ZIP_REQUEST",
  "commissions-batch/DOWNLOAD_ATTACHMENTS_AS_ZIP_SUCCESS",
  "commissions-batch/DOWNLOAD_ATTACHMENTS_AS_ZIP_FAILURE"
)<EntityObject<DownloadBatchOutputAttachmentsAsZip>, void, void>();

export const getCommissionsBatchNamePrefixActions = createAsyncAction(
  "commissions-batch/GET_NAME_PREFIX_REQUEST",
  "commissions-batch/GET_NAME_PREFIX_SUCCESS",
  "commissions-batch/GET_NAME_PREFIX_FAILURE"
)<void, string, void>();

export const createCommissionsBatchActions = createAsyncAction(
  "commissions-batch/CREATE_REQUEST",
  "commissions-batch/CREATE_SUCCESS",
  "commissions-batch/CREATE_FAILURE"
)<CreateUpdateCommissionsBatch, CommissionsBatch, void>();

export const updateCommissionsBatchActions = createAsyncAction(
  "commissions-batch/UPDATE_REQUEST",
  "commissions-batch/UPDATE_SUCCESS",
  "commissions-batch/UPDATE_FAILURE"
)<EntityObject<CreateUpdateCommissionsBatch>, CommissionsBatch | undefined, void>();

export const deleteCommissionsBatchActions = createAsyncAction(
  "commissions-batch/DELETE_REQUEST",
  "commissions-batch/DELETE_SUCCESS",
  "commissions-batch/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const changeCommissionsBatchStepActions = createAsyncAction(
  "commissions-batch/CHANGE_STEP_REQUEST",
  "commissions-batch/CHANGE_STEP_SUCCESS",
  "commissions-batch/CHANGE_STEP_FAILURE"
)<EntityObject<ChangeCommissionsBatchStepRequest>, CommissionsBatch, void>();

export const setStateCommissionsBatchesPageAction = createAction(
  "commissions-batch/SET_STATE_LIST"
)<CommissionsBatchFilterPageResult>();

export const setStateCommissionsBatchDetailAction = createAction(
  "commissions-batch/SET_STATE_DETAIL"
)<CommissionsBatch>();

export const deleteStateCommissionsBatchesPageAction = createAction("commissions-batch/DELETE_STATE_LIST")<void>();
export const deleteStateCommissionsBatchDetailAction = createAction("commissions-batch/DELETE_STATE_DETAIL")<void>();
export const deleteStateCommissionsBatchPaymentToBePaidAction = createAction(
  "commissions-batch/DELETE_STATE_PAYMENT_TO_BE_PAID"
)<void>();
export const deleteStateCommissionsBatchNamePrefixAction = createAction(
  "commissions-batch/DELETE_STATE_NAME_PREFIX"
)<void>();

/**
 * ACTIONS - UNIT
 */
export const createCommissionsUnitActions = createAsyncAction(
  "commissions-unit/CREATE_REQUEST",
  "commissions-unit/CREATE_SUCCESS",
  "commissions-unit/CREATE_FAILURE"
)<EntityObject<CreateCommissionsUnit>, CommissionsUnit, void>();

export const updateCommissionsUnitActions = createAsyncAction(
  "commissions-unit/UPDATE_REQUEST",
  "commissions-unit/UPDATE_SUCCESS",
  "commissions-unit/UPDATE_FAILURE"
)<TwoLevelEntityObject<UpdateCommissionsUnit>, CommissionsUnit, void>();

export const deleteCommissionsUnitActions = createAsyncAction(
  "commissions-unit/DELETE_REQUEST",
  "commissions-unit/DELETE_SUCCESS",
  "commissions-unit/DELETE_FAILURE"
)<EntityObject<DeleteCommissionsUnitRequest>, void, void>();

/**
 * ACTIONS - ATTACHMENT
 */
export const uploadCommissionsBatchAttachmentActions = createAsyncAction(
  "commissions-batch-attachment/UPLOAD_REQUEST",
  "commissions-batch-attachment/UPLOAD_SUCCESS",
  "commissions-batch-attachment/UPLOAD_FAILURE"
)<EntityObject<FormData>, CommissionsBatchInputAttachment, void>();

export const replaceCommissionsBatchAttachmentActions = createAsyncAction(
  "commissions-batch-attachment/REPLACE_REQUEST",
  "commissions-batch-attachment/REPLACE_SUCCESS",
  "commissions-batch-attachment/REPLACE_FAILURE"
)<TwoLevelEntityObject<FormData>, CommissionsBatchInputAttachment, void>();

export const downloadCommissionsBatchAttachmentActions = createAsyncAction(
  "commissions-batch-attachment/DOWNLOAD_REQUEST",
  "commissions-batch-attachment/DOWNLOAD_SUCCESS",
  "commissions-batch-attachment/DOWNLOAD_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const deleteCommissionsBatchAttachmentActions = createAsyncAction(
  "commissions-batch-attachment/DELETE_REQUEST",
  "commissions-batch-attachment/DELETE_SUCCESS",
  "commissions-batch-attachment/DELETE_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

/**
 * ACTIONS - COMMISSION
 */
export const filterCommissionsActions = createAsyncAction(
  "commission/FILTER_REQUEST",
  "commission/FILTER_SUCCESS",
  "commission/FILTER_FAILURE"
)<EntityObject<CommissionsFilterPageRequest>, CommissionsFilterPageResult, void>();

export const createCommissionActions = createAsyncAction(
  "commission/CREATE_REQUEST",
  "commission/CREATE_SUCCESS",
  "commission/CREATE_FAILURE"
)<EntityObject<CreateManuallyAddedCommission>, Commission, void>();

export const updateCommissionActions = createAsyncAction(
  "commission/UPDATE_REQUEST",
  "commission/UPDATE_SUCCESS",
  "commission/UPDATE_FAILURE"
)<TwoLevelEntityObject<UpdateCommission>, Commission, void>();

export const postponeCommissionActions = createAsyncAction(
  "commission/POSTPONE_REQUEST",
  "commission/POSTPONE_SUCCESS",
  "commission/POSTPONE_FAILURE"
)<TwoLevelEntityObject<PostponeCommission>, Commission, void>();

export const tryToIncludeCommissionActions = createAsyncAction(
  "commission/TRY_TO_INCLUDE_REQUEST",
  "commission/TRY_TO_INCLUDE_SUCCESS",
  "commission/TRY_TO_INCLUDE_FAILURE"
)<TwoLevelEntityObject<OptimisticLockVersion>, Commission, void>();

export const recalculateCommissionsForContractActions = createAsyncAction(
  "commission/RECALCULATE_COMMISSIONS_FOR_CONTRACT_REQUEST",
  "commission/RECALCULATE_COMMISSIONS_FOR_CONTRACT_SUCCESS",
  "commission/RECALCULATE_COMMISSIONS_FOR_CONTRACT_FAILURE"
)<EntityObject<RecalculateCommissionsForContractRequest>, Commission[], void>();

export const updateCalculatedCommissionsActions = createAsyncAction(
  "commission/UPDATE_CALCULATED_COMMISSIONS_REQUEST",
  "commission/UPDATE_CALCULATED_COMMISSIONS_SUCCESS",
  "commission/UPDATE_CALCULATED_COMMISSIONS_FAILURE"
)<TwoLevelEntityObject<UpdateCalculatedCommissionList>, Commission, void>();

export const deleteCommissionActions = createAsyncAction(
  "commission/DELETE_REQUEST",
  "commission/DELETE_SUCCESS",
  "commission/DELETE_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const setStateCommissionsPageAction = createAction(
  "commission/SET_STATE_COMMISSIONS_PAGE"
)<CommissionsFilterPageResult>();

export const deleteStateCommissionsPageAction = createAction("commission/DELETE_STATE_COMMISSIONS_PAGE")<void>();

/**
 * ACTIONS - MANUAL BAIL ACCOUNT MOVEMENTS
 */
export const filterManualBailAccountMovementsActions = createAsyncAction(
  "manual-bail-account-movement/FILTER_REQUEST",
  "manual-bail-account-movement/FILTER_SUCCESS",
  "manual-bail-account-movement/FILTER_FAILURE"
)<EntityObject<ManualBailAccountMovementsFilterPageRequest>, ManualBailAccountMovementsFilterPageResult, void>();

export const associateBailAccountMovementToBatchActions = createAsyncAction(
  "manual-bail-account-movement/ASSOCIATE_TO_BATCH_REQUEST",
  "manual-bail-account-movement/ASSOCIATE_TO_BATCH_SUCCESS",
  "manual-bail-account-movement/ASSOCIATE_TO_BATCH_FAILURE"
)<TwoLevelEntityObject<OptimisticLockVersion>, BailAccountMovement, void>();

export const disassociateBailAccountMovementFromBatchActions = createAsyncAction(
  "manual-bail-account-movement/DISASSOCIATE_FROM_BATCH_REQUEST",
  "manual-bail-account-movement/DISASSOCIATE_FROM_BATCH_SUCCESS",
  "manual-bail-account-movement/DISASSOCIATE_FROM_BATCH_FAILURE"
)<TwoLevelEntityObject<OptimisticLockVersion>, BailAccountMovement, void>();

export const deleteStateManualBailAccountMovementsPageAction = createAction(
  "manual-bail-account-movement/DELETE_STATE_PAGE"
)<void>();

/**
 * ACTIONS - SPECIAL COMMISSION
 */
export const filterSpecialCommissionsActions = createAsyncAction(
  "batch-special-commission/FILTER_REQUEST",
  "batch-special-commission/FILTER_SUCCESS",
  "batch-special-commission/FILTER_FAILURE"
)<
  TwoLevelEntityObject<SpecialCommissionsFilterPageRequest>,
  SpecialCommissionsFilterPageResult<SpecialCommission>,
  void
>();

export const excludeSpecialCommissionActions = createAsyncAction(
  "batch-special-commission/EXCLUDE_REQUEST",
  "batch-special-commission/EXCLUDE_SUCCESS",
  "batch-special-commission/EXCLUDE_FAILURE"
)<ThreeLevelEntityObject<ExcludeSpecialCommission>, SpecialCommission, void>();

export const includeSpecialCommissionActions = createAsyncAction(
  "batch-special-commission/INCLUDE_REQUEST",
  "batch-special-commission/INCLUDE_SUCCESS",
  "batch-special-commission/INCLUDE_FAILURE"
)<ThreeLevelEntityObject<OptimisticLockVersion>, SpecialCommission, void>();

export const deleteStateSpecialCommissionsPageAction = createAction(
  "batch-special-commission/DELETE_STATE_PAGE"
)<void>();

const actions = {
  filterCommissionsBatchesActions,
  getCommissionsBatchActions,
  calculateCommissionsBatchPaymentToBePaidActions,
  generateCommissionsBatchPaymentOrderActions,
  downloadCommissionsBatchAttachmentsAsZipActions,
  getCommissionsBatchNamePrefixActions,
  createCommissionsBatchActions,
  updateCommissionsBatchActions,
  deleteCommissionsBatchActions,
  changeCommissionsBatchStepActions,
  setStateCommissionsBatchesPageAction,
  setStateCommissionsBatchDetailAction,
  deleteStateCommissionsBatchesPageAction,
  deleteStateCommissionsBatchDetailAction,
  deleteStateCommissionsBatchPaymentToBePaidAction,
  deleteStateCommissionsBatchNamePrefixAction,
  createCommissionsUnitActions,
  updateCommissionsUnitActions,
  deleteCommissionsUnitActions,
  uploadCommissionsBatchAttachmentActions,
  replaceCommissionsBatchAttachmentActions,
  downloadCommissionsBatchAttachmentActions,
  deleteCommissionsBatchAttachmentActions,
  filterCommissionsActions,
  createCommissionActions,
  updateCommissionActions,
  postponeCommissionActions,
  tryToIncludeCommissionActions,
  recalculateCommissionsForContractActions,
  updateCalculatedCommissionsActions,
  deleteCommissionActions,
  setStateCommissionsPageAction,
  deleteStateCommissionsPageAction,
  filterManualBailAccountMovementsActions,
  associateBailAccountMovementToBatchActions,
  disassociateBailAccountMovementFromBatchActions,
  deleteStateManualBailAccountMovementsPageAction,
  filterSpecialCommissionsActions,
  excludeSpecialCommissionActions,
  includeSpecialCommissionActions,
  deleteStateSpecialCommissionsPageAction
};

export type CommissionBatchAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: CommissionsBatchReducerState = {
  batchesPage: {
    ...initSearchPageResult<CommissionsBatch>(),
    steps: []
  },
  batchDetail: null,
  batchPaymentToBePaid: null,
  batchNamePrefix: null,
  commissionsPage: {
    ...initSearchPageResult<Commission>(),
    origin: undefined,
    processingResult: undefined,
    postponementReasons: [],
    institutionIds: [],
    sourceIds: [],
    batch: undefined,
    commissionAmountsSum: undefined,
    sources: []
  },
  manualBailAccountMovementsPage: {
    ...initPageResult<BailAccountMovement>(),
    report: undefined,
    batch: undefined
  },
  specialCommissionsPage: {
    ...initPageResult<SpecialCommission>(),
    report: undefined,
    code: undefined,
    agent: undefined,
    batch: undefined,
    commissionAmountsSum: undefined
  }
};

const batchesPageReducer = createReducer(initialState.batchesPage)
  .handleAction(
    [filterCommissionsBatchesActions.success, setStateCommissionsBatchesPageAction],
    (_, { payload }) => payload
  )
  .handleAction(
    [filterCommissionsBatchesActions.failure, deleteStateCommissionsBatchesPageAction],
    () => initialState.batchesPage
  );

const batchDetailReducer = createReducer(initialState.batchDetail)
  .handleAction(
    [
      getCommissionsBatchActions.success,
      createCommissionsBatchActions.success,
      updateCommissionsBatchActions.success,
      changeCommissionsBatchStepActions.success,
      setStateCommissionsBatchDetailAction
    ],
    (_, { payload }) => {
      if (payload) {
        const institutionTypeOrder = Object.values(InstitutionType);
        return {
          ...payload,
          commissionsUnits: payload.commissionsUnits.sort(
            (u1, u2) =>
              institutionTypeOrder.indexOf(u1.institution.type) - institutionTypeOrder.indexOf(u2.institution.type) ||
              u1.institution.name.localeCompare(u2.institution.name, DEFAULT_LOCALE, { sensitivity: "accent" })
          )
        };
      } else {
        return null;
      }
    }
  )
  .handleAction(
    [
      getCommissionsBatchActions.failure,
      deleteCommissionsBatchActions.success,
      deleteStateCommissionsBatchDetailAction
    ],
    () => initialState.batchDetail
  );

const batchPaymentToBePaidReducer = createReducer(initialState.batchPaymentToBePaid)
  .handleAction(calculateCommissionsBatchPaymentToBePaidActions.success, (_, { payload }) => payload)
  .handleAction(
    [calculateCommissionsBatchPaymentToBePaidActions.failure, deleteStateCommissionsBatchPaymentToBePaidAction],
    () => initialState.batchPaymentToBePaid
  );

const batchNamePrefixReducer = createReducer(initialState.batchNamePrefix)
  .handleAction(getCommissionsBatchNamePrefixActions.success, (_, { payload }) => payload)
  .handleAction(
    [getCommissionsBatchNamePrefixActions.failure, deleteStateCommissionsBatchNamePrefixAction],
    () => initialState.batchNamePrefix
  );

const commissionsPageReducer = createReducer(initialState.commissionsPage)
  .handleAction([filterCommissionsActions.success, setStateCommissionsPageAction], (_, { payload }) => payload)
  .handleAction(
    [filterCommissionsActions.failure, deleteStateCommissionsPageAction],
    () => initialState.commissionsPage
  );

const manualBailAccountMovementsPageReducer = createReducer(initialState.manualBailAccountMovementsPage)
  .handleAction(filterManualBailAccountMovementsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterManualBailAccountMovementsActions.failure, deleteStateManualBailAccountMovementsPageAction],
    () => initialState.manualBailAccountMovementsPage
  );

const specialCommissionsPageReducer = createReducer(initialState.specialCommissionsPage)
  .handleAction([filterSpecialCommissionsActions.success], (_, { payload }) => payload)
  .handleAction(
    [filterSpecialCommissionsActions.failure, deleteStateSpecialCommissionsPageAction],
    () => initialState.specialCommissionsPage
  );

export const commissionsBatchReducer = combineReducers<CommissionsBatchReducerState>({
  batchesPage: batchesPageReducer,
  batchDetail: batchDetailReducer,
  batchPaymentToBePaid: batchPaymentToBePaidReducer,
  batchNamePrefix: batchNamePrefixReducer,
  commissionsPage: commissionsPageReducer,
  manualBailAccountMovementsPage: manualBailAccountMovementsPageReducer,
  specialCommissionsPage: specialCommissionsPageReducer
});

/**
 * SELECTORS
 */
const selectCommissionsBatch = (state: RootState): CommissionsBatchReducerState => state.commissions.batch;

export const selectBatchesPage = (state: RootState): CommissionsBatchFilterPageResult =>
  selectCommissionsBatch(state).batchesPage;
export const selectBatchDetail = (state: RootState): CommissionsBatch | undefined =>
  selectCommissionsBatch(state).batchDetail ?? undefined;
export const selectBatchPaymentToBePaid = (state: RootState): number | undefined =>
  selectCommissionsBatch(state).batchPaymentToBePaid ?? undefined;
export const selectBatchNamePrefix = (state: RootState): string | undefined =>
  selectCommissionsBatch(state).batchNamePrefix ?? undefined;
export const selectCommissionsPage = (state: RootState): CommissionsFilterPageResult =>
  selectCommissionsBatch(state).commissionsPage;
export const selectManualBailAccountMovementsPage = (state: RootState): ManualBailAccountMovementsFilterPageResult =>
  selectCommissionsBatch(state).manualBailAccountMovementsPage;
export const selectSpecialCommissionsPage = (state: RootState): SpecialCommissionsFilterPageResult<SpecialCommission> =>
  selectCommissionsBatch(state).specialCommissionsPage;

/**
 * SAGAS - BATCH
 */
function* filterCommissionsBatches({ payload }: ReturnType<typeof filterCommissionsBatchesActions.request>) {
  try {
    const response: AxiosResponse<CommissionsBatchFilterPageResult> = yield call(api.filterCommissionsBatches, payload);
    yield put(filterCommissionsBatchesActions.success(response.data));
  } catch {
    yield put(filterCommissionsBatchesActions.failure());
  }
}

function* getCommissionsBatch({ payload }: ReturnType<typeof getCommissionsBatchActions.request>) {
  try {
    const response: AxiosResponse<CommissionsBatch> = yield call(api.getCommissionsBatch, payload);
    yield put(getCommissionsBatchActions.success(response.data));
    yield* refreshInProgressBatch(response.data);
  } catch {
    yield put(getCommissionsBatchActions.failure());
  }
}

function* calculateCommissionsBatchPaymentToBePaid({
  payload
}: ReturnType<typeof calculateCommissionsBatchPaymentToBePaidActions.request>) {
  try {
    const response: AxiosResponse<number> = yield call(api.calculateCommissionsBatchPaymentToBePaid, payload);
    yield put(calculateCommissionsBatchPaymentToBePaidActions.success(response.data));
  } catch {
    yield put(calculateCommissionsBatchPaymentToBePaidActions.failure());
  }
}

function* generateCommissionsBatchPaymentOrder({
  payload
}: ReturnType<typeof generateCommissionsBatchPaymentOrderActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.generateCommissionsBatchPaymentOrder, payload);
    openBlobFile(response, true);
    yield put(generateCommissionsBatchPaymentOrderActions.success());
    yield put(changeRunningRequestKeyAction());
  } catch {
    yield put(generateCommissionsBatchPaymentOrderActions.failure());
  }
}

function* downloadCommissionsBatchAttachmentsAsZip({
  payload
}: ReturnType<typeof downloadCommissionsBatchAttachmentsAsZipActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadCommissionsBatchAttachmentsAsZip, payload);
    openBlobFile(response, true);
    yield put(downloadCommissionsBatchAttachmentsAsZipActions.success());
    yield put(changeRunningRequestKeyAction());
  } catch {
    yield put(downloadCommissionsBatchAttachmentsAsZipActions.failure());
  }
}

function* getCommissionsBatchNamePrefix() {
  try {
    const response: AxiosResponse<string> = yield call(api.getCommissionsBatchNamePrefix);
    yield put(getCommissionsBatchNamePrefixActions.success(response.data));
  } catch {
    yield put(getCommissionsBatchNamePrefixActions.failure());
  }
}

function* createCommissionsBatch({ payload }: ReturnType<typeof createCommissionsBatchActions.request>) {
  try {
    const response: AxiosResponse<CommissionsBatch> = yield call(api.createCommissionsBatch, payload);
    yield put(createCommissionsBatchActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    yield put(push(generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: response.data.id })));
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createCommissionsBatchActions.failure());
  }
}

function* updateCommissionsBatch({ payload }: ReturnType<typeof updateCommissionsBatchActions.request>) {
  try {
    const response: AxiosResponse<CommissionsBatch> = yield call(api.updateCommissionsBatch, payload);

    if ((yield select(selectBatchDetail)) as CommissionsBatch) {
      yield put(updateCommissionsBatchActions.success(response.data));
    } else {
      yield put(updateCommissionsBatchActions.success(undefined));
      const page: CommissionsBatchFilterPageResult = yield select(selectBatchesPage);
      yield put(
        setStateCommissionsBatchesPageAction({
          ...page,
          pageData: replaceInArray(
            page.pageData,
            item => item.id === payload.id,
            () => response.data
          )
        })
      );
    }

    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateCommissionsBatchActions.failure());
  }
}

function* deleteCommissionsBatch({ payload }: ReturnType<typeof deleteCommissionsBatchActions.request>) {
  try {
    yield call(api.deleteCommissionsBatch, payload);

    if ((yield select(selectBatchDetail)) as CommissionsBatch) {
      yield put(deleteCommissionsBatchActions.success());
      yield put(replace(COMMISSIONS_BATCH_ROUTE_PATHS.list.to));
    } else {
      yield put(deleteCommissionsBatchActions.success());
      const page: CommissionsBatchFilterPageResult = yield select(selectBatchesPage);
      yield put(
        filterCommissionsBatchesActions.request({
          pageIndex: page.pageElementsCount === 1 ? Math.max(page.pageIndex - 1, 0) : page.pageIndex,
          pageSize: page.pageSize,
          keyword: page.keyword,
          steps: page.steps
        })
      );
    }
  } catch {
    yield put(deleteCommissionsBatchActions.failure());
  }
}

function* changeCommissionsBatchStep({ payload }: ReturnType<typeof changeCommissionsBatchStepActions.request>) {
  try {
    const response: AxiosResponse<CommissionsBatch> = yield call(api.changeCommissionsBatchStep, payload);
    yield put(changeCommissionsBatchStepActions.success(response.data));
    yield* refreshInProgressBatch(response.data);
  } catch {
    yield put(changeCommissionsBatchStepActions.failure());
  }
}

/**
 * SAGAS - UNIT
 */
function* createCommissionsUnit({ payload }: ReturnType<typeof createCommissionsUnitActions.request>) {
  try {
    const response: AxiosResponse<CommissionsUnit> = yield call(api.createCommissionsUnit, payload);
    yield put(createCommissionsUnitActions.success(response.data));
    yield put(getCommissionsBatchActions.request({ id: payload.id }));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createCommissionsUnitActions.failure());
  }
}

function* updateCommissionsUnit({ payload }: ReturnType<typeof updateCommissionsUnitActions.request>) {
  try {
    const response: AxiosResponse<CommissionsUnit> = yield call(api.updateCommissionsUnit, payload);
    yield put(updateCommissionsUnitActions.success(response.data));
    yield put(getCommissionsBatchActions.request({ id: payload.id1 }));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateCommissionsUnitActions.failure());
  }
}

function* deleteCommissionsUnit({ payload }: ReturnType<typeof deleteCommissionsUnitActions.request>) {
  try {
    yield call(api.deleteCommissionsUnit, payload);
    yield put(deleteCommissionsUnitActions.success());
    yield put(getCommissionsBatchActions.request({ id: payload.id }));
  } catch {
    yield put(deleteCommissionsUnitActions.failure());
  }
}

/**
 * SAGAS - ATTACHMENT
 */
function* uploadCommissionsBatchAttachment({
  payload
}: ReturnType<typeof uploadCommissionsBatchAttachmentActions.request>) {
  try {
    const response: AxiosResponse<CommissionsBatchInputAttachment> = yield call(
      api.uploadCommissionsBatchAttachment,
      payload
    );
    yield put(uploadCommissionsBatchAttachmentActions.success(response.data));
    yield put(getCommissionsBatchActions.request({ id: payload.id }));
  } catch {
    yield put(uploadCommissionsBatchAttachmentActions.failure());
  }
}

function* replaceCommissionsBatchAttachment({
  payload
}: ReturnType<typeof replaceCommissionsBatchAttachmentActions.request>) {
  try {
    const response: AxiosResponse<CommissionsBatchInputAttachment> = yield call(
      api.replaceCommissionsBatchAttachment,
      payload
    );
    yield put(replaceCommissionsBatchAttachmentActions.success(response.data));
    yield put(getCommissionsBatchActions.request({ id: payload.id1 }));
  } catch {
    yield put(replaceCommissionsBatchAttachmentActions.failure());
  }
}

function* downloadCommissionsBatchAttachment({
  payload
}: ReturnType<typeof downloadCommissionsBatchAttachmentActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadCommissionsBatchAttachment, payload);
    openBlobFile(response);
    yield put(downloadCommissionsBatchAttachmentActions.success());
  } catch {
    yield put(downloadCommissionsBatchAttachmentActions.failure());
  }
}

function* deleteCommissionsBatchAttachment({
  payload
}: ReturnType<typeof deleteCommissionsBatchAttachmentActions.request>) {
  try {
    yield call(api.deleteCommissionsBatchAttachment, payload);
    yield put(deleteCommissionsBatchAttachmentActions.success());
    yield put(getCommissionsBatchActions.request({ id: payload.id1 }));
  } catch {
    yield put(deleteCommissionsBatchAttachmentActions.failure());
  }
}

/**
 * SAGAS - COMMISSION
 */
function* filterCommissions({ payload }: ReturnType<typeof filterCommissionsActions.request>) {
  try {
    const response: AxiosResponse<CommissionsFilterPageResult> = yield call(api.filterCommissions, payload);
    yield put(filterCommissionsActions.success(response.data));
  } catch {
    yield put(filterCommissionsActions.failure());
  }
}

function* createCommission({ payload }: ReturnType<typeof createCommissionActions.request>) {
  try {
    const response: AxiosResponse<Commission> = yield call(api.createCommission, payload);
    yield put(createCommissionActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
    yield* refreshCommissionsPage(payload.id);
  } catch {
    yield put(createCommissionActions.failure());
  }
}

function* updateCommission({ payload }: ReturnType<typeof updateCommissionActions.request>) {
  try {
    const response: AxiosResponse<Commission> = yield call(api.updateCommission, payload);
    yield put(updateCommissionActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
    yield* replaceCommissionOnPage([response.data], payload.id1, true);
  } catch {
    yield put(updateCommissionActions.failure());
  }
}

function* postponeCommission({ payload }: ReturnType<typeof postponeCommissionActions.request>) {
  try {
    const response: AxiosResponse<Commission> = yield call(api.postponeCommission, payload);
    yield put(postponeCommissionActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
    yield* replaceCommissionOnPage([response.data], payload.id1);
  } catch {
    yield put(postponeCommissionActions.failure());
  }
}

function* tryToIncludeCommission({ payload }: ReturnType<typeof tryToIncludeCommissionActions.request>) {
  try {
    const response: AxiosResponse<Commission> = yield call(api.tryToIncludeCommission, payload);
    yield put(tryToIncludeCommissionActions.success(response.data));
    if (response.data.postponed) {
      messageUtils.warnNotification({
        message: t("common.warning"),
        description: t("commissions.batch.helpers.commissionIncludeFailure")
      });
    } else {
      messageUtils.itemUpdatedNotification();
    }
    yield* replaceCommissionOnPage([response.data], payload.id1);
  } catch {
    yield put(tryToIncludeCommissionActions.failure());
  }
}

function* recalculateCommissionsForContract({
  payload
}: ReturnType<typeof recalculateCommissionsForContractActions.request>) {
  try {
    const response: AxiosResponse<Commission[]> = yield call(api.recalculateCommissionsForContract, payload);
    recalculateCommissionsForContractActions.success(response.data);
    messageUtils.successNotification({
      message: t("common.operationSuccess"),
      description: t("commissions.batch.helpers.recalculationResult", { count: response.data.length })
    });
    yield* replaceCommissionOnPage(response.data, payload.id);
  } catch {
    recalculateCommissionsForContractActions.failure();
  }
}

function* updateCalculatedCommissions({ payload }: ReturnType<typeof updateCalculatedCommissionsActions.request>) {
  try {
    const response: AxiosResponse<Commission> = yield call(api.updateCalculatedCommissions, payload);
    yield put(updateCalculatedCommissionsActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
    yield* replaceCommissionOnPage([response.data], payload.id1);
  } catch {
    yield put(updateCalculatedCommissionsActions.failure());
  }
}

function* deleteCommission({ payload }: ReturnType<typeof deleteCommissionActions.request>) {
  try {
    yield call(api.deleteCommission, payload);
    yield put(deleteCommissionActions.success());
    yield* refreshCommissionsPage(payload.id1, true);
    yield put(getCommissionsBatchActions.request({ id: payload.id1 }));
  } catch {
    yield put(deleteCommissionActions.failure());
  }
}

/**
 * SAGAS - MANUAL BAIL ACCOUNT MOVEMENTS
 */
function* filterManualBailAccountMovements({
  payload
}: ReturnType<typeof filterManualBailAccountMovementsActions.request>) {
  try {
    const response: AxiosResponse<ManualBailAccountMovementsFilterPageResult> = yield call(
      api.filterManualBailAccountsMovements,
      payload
    );
    yield put(filterManualBailAccountMovementsActions.success(response.data));
  } catch {
    yield put(filterManualBailAccountMovementsActions.failure());
  }
}

function* associateBailAccountMovementToBatch({
  payload
}: ReturnType<typeof associateBailAccountMovementToBatchActions.request>) {
  try {
    const response: AxiosResponse<BailAccountMovement> = yield call(api.associateBailAccountMovementToBatch, payload);
    yield put(associateBailAccountMovementToBatchActions.success(response.data));
    messageUtils.itemUpdatedNotification();
    yield* refreshManualBailAccountMovementsPage();
  } catch {
    yield put(associateBailAccountMovementToBatchActions.failure());
  }
}

function* disassociateBailAccountMovementFromBatch({
  payload
}: ReturnType<typeof disassociateBailAccountMovementFromBatchActions.request>) {
  try {
    const response: AxiosResponse<BailAccountMovement> = yield call(
      api.disassociateBailAccountMovementFromBatch,
      payload
    );
    yield put(disassociateBailAccountMovementFromBatchActions.success(response.data));
    messageUtils.itemUpdatedNotification();
    yield* refreshManualBailAccountMovementsPage();
  } catch {
    yield put(disassociateBailAccountMovementFromBatchActions.failure());
  }
}

/**
 * SAGAS - SPECIAL COMMISSIONS
 */
function* filterSpecialCommissions({ payload }: ReturnType<typeof filterSpecialCommissionsActions.request>) {
  try {
    const response: AxiosResponse<SpecialCommissionsFilterPageResult<SpecialCommission>> = yield call(
      api.filterSpecialCommissions,
      payload
    );
    yield put(filterSpecialCommissionsActions.success(response.data));
  } catch {
    yield put(filterSpecialCommissionsActions.failure());
  }
}

function* excludeSpecialCommission({ payload }: ReturnType<typeof excludeSpecialCommissionActions.request>) {
  try {
    const response: AxiosResponse<SpecialCommission> = yield call(api.excludeSpecialCommission, payload);
    yield put(excludeSpecialCommissionActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
    yield* refreshSpecialCommissionsPage();
  } catch {
    yield put(excludeSpecialCommissionActions.failure());
  }
}

function* includeSpecialCommission({ payload }: ReturnType<typeof includeSpecialCommissionActions.request>) {
  try {
    const response: AxiosResponse<SpecialCommission> = yield call(api.includeSpecialCommission, payload);
    yield put(includeSpecialCommissionActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
    yield* refreshSpecialCommissionsPage();
  } catch {
    yield put(includeSpecialCommissionActions.failure());
  }
}

/**
 * SAGAS - HELPERS
 */
function* refreshInProgressBatch(batch: CommissionsBatch) {
  if (
    batch.stepChangeInProgress ||
    batch.attachments.some(
      a => a.type === CommissionsBatchAttachmentType.INPUT && (a as CommissionsBatchInputAttachment).importInProgress
    )
  ) {
    yield delay(4000);
    const currentBatch: CommissionsBatch = yield select(selectBatchDetail);
    if (currentBatch) {
      yield put(getCommissionsBatchActions.request({ id: currentBatch.id }));
    }
  }
}

function* replaceCommissionOnPage(commissions: Commission[], batchId: UUID, shouldRefresh?: boolean) {
  if (shouldRefresh) {
    yield* refreshCommissionsPage(batchId);
  } else {
    const page: CommissionsFilterPageResult = yield select(selectCommissionsPage);
    let pageData = [...page.pageData];
    commissions.forEach(commission => {
      pageData = replaceInArray(
        pageData,
        c => c.id === commission.id,
        () => commission
      );
    });
    yield put(setStateCommissionsPageAction({ ...page, pageData }));
  }

  const location: Pathname = yield select(selectRouterLocationPathname);
  if (!location.endsWith("imports")) {
    yield put(getCommissionsBatchActions.request({ id: batchId }));
  }
}

function* refreshCommissionsPage(batchId: UUID, movePage?: boolean) {
  const page: CommissionsFilterPageResult = yield select(selectCommissionsPage);
  yield put(
    filterCommissionsActions.request({
      id: batchId,
      object: {
        pageIndex: movePage
          ? page.pageElementsCount === 1
            ? Math.max(page.pageIndex - 1, 0)
            : page.pageIndex
          : page.pageIndex,
        pageSize: page.pageSize,
        keyword: page.keyword,
        origin: page.origin,
        processingResult: page.processingResult,
        postponementReasons: page.postponementReasons,
        institutionIds: page.institutionIds,
        sourceIds: page.sourceIds
      }
    })
  );
}

function* refreshManualBailAccountMovementsPage() {
  const page: ManualBailAccountMovementsFilterPageResult = yield select(selectManualBailAccountMovementsPage);

  if (page.batch) {
    yield put(
      filterManualBailAccountMovementsActions.request({
        id: page.batch.id,
        object: {
          pageIndex: page.pageElementsCount === 1 ? Math.max(page.pageIndex - 1, 0) : page.pageIndex,
          pageSize: page.pageSize,
          report: page.report
        }
      })
    );
  }
}

function* refreshSpecialCommissionsPage() {
  const page: SpecialCommissionsFilterPageResult<SpecialCommission> = yield select(selectSpecialCommissionsPage);

  if (page.batch && page.agent) {
    yield put(
      filterSpecialCommissionsActions.request({
        id1: page.batch.id,
        id2: page.agent.id,
        object: {
          pageIndex: page.pageElementsCount === 1 ? Math.max(page.pageIndex - 1, 0) : page.pageIndex,
          pageSize: page.pageSize,
          report: page.report,
          code: page.code
        }
      })
    );
  }
}

export function* commissionsBatchSaga() {
  yield takeLatest(filterCommissionsBatchesActions.request, filterCommissionsBatches);
  yield takeLatest(getCommissionsBatchActions.request, getCommissionsBatch);
  yield takeLatest(calculateCommissionsBatchPaymentToBePaidActions.request, calculateCommissionsBatchPaymentToBePaid);
  yield takeLatest(generateCommissionsBatchPaymentOrderActions.request, generateCommissionsBatchPaymentOrder);
  yield takeLatest(downloadCommissionsBatchAttachmentsAsZipActions.request, downloadCommissionsBatchAttachmentsAsZip);
  yield takeLatest(getCommissionsBatchNamePrefixActions.request, getCommissionsBatchNamePrefix);
  yield takeLatest(createCommissionsBatchActions.request, createCommissionsBatch);
  yield takeLatest(updateCommissionsBatchActions.request, updateCommissionsBatch);
  yield takeLatest(deleteCommissionsBatchActions.request, deleteCommissionsBatch);
  yield takeLatest(changeCommissionsBatchStepActions.request, changeCommissionsBatchStep);

  yield takeLatest(createCommissionsUnitActions.request, createCommissionsUnit);
  yield takeLatest(updateCommissionsUnitActions.request, updateCommissionsUnit);
  yield takeLatest(deleteCommissionsUnitActions.request, deleteCommissionsUnit);

  yield takeLatest(uploadCommissionsBatchAttachmentActions.request, uploadCommissionsBatchAttachment);
  yield takeLatest(replaceCommissionsBatchAttachmentActions.request, replaceCommissionsBatchAttachment);
  yield takeLatest(downloadCommissionsBatchAttachmentActions.request, downloadCommissionsBatchAttachment);
  yield takeLatest(deleteCommissionsBatchAttachmentActions.request, deleteCommissionsBatchAttachment);

  yield takeLatest(filterCommissionsActions.request, filterCommissions);
  yield takeLatest(createCommissionActions.request, createCommission);
  yield takeLatest(updateCommissionActions.request, updateCommission);
  yield takeLatest(postponeCommissionActions.request, postponeCommission);
  yield takeLatest(tryToIncludeCommissionActions.request, tryToIncludeCommission);
  yield takeLatest(recalculateCommissionsForContractActions.request, recalculateCommissionsForContract);
  yield takeLatest(updateCalculatedCommissionsActions.request, updateCalculatedCommissions);
  yield takeLatest(deleteCommissionActions.request, deleteCommission);

  yield takeLatest(filterManualBailAccountMovementsActions.request, filterManualBailAccountMovements);
  yield takeLatest(associateBailAccountMovementToBatchActions.request, associateBailAccountMovementToBatch);
  yield takeLatest(disassociateBailAccountMovementFromBatchActions.request, disassociateBailAccountMovementFromBatch);

  yield takeLatest(filterSpecialCommissionsActions.request, filterSpecialCommissions);
  yield takeLatest(excludeSpecialCommissionActions.request, excludeSpecialCommission);
  yield takeLatest(includeSpecialCommissionActions.request, includeSpecialCommission);
}
