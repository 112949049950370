import { Divider, Image, Popconfirm, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import classNames from "classnames";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import t from "../../../../../app/i18n";
import UserOutlined from "../../../../../assets/images/UserOutlined.svg";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import { RootState } from "../../../../../common/types";
import { getApiBaseUrl } from "../../../../../common/utils/utils";
import { selectToken, selectUserAccount } from "../../../../auth/ducks";
import { UserAccount } from "../../../../user/types";
import { deleteProfilePictureActions, updateProfilePictureActions } from "../../../ducks";
import { Agent, AgentProfile } from "../../../types";

interface Props {
  agent: AgentProfile | Agent;
  width?: number;
  height?: number;
  preview?: boolean;
  showActions?: boolean;
  renderFallback?: boolean;
  className?: string;
}

const AgentProfilePictureView = ({
  agent,
  width,
  height,
  preview = true,
  showActions,
  renderFallback = true,
  className
}: Props) => {
  const token = useSelector<RootState, string | undefined>(selectToken);
  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onUpdateClick: updateProfilePictureActions.request,
          onDeleteClick: deleteProfilePictureActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const handleProfilePictureUpload = (file: RcFile): boolean => {
    const formData = new FormData();
    formData.append("file", file);
    actions.onUpdateClick({ id: agent.id, object: formData });
    return false;
  };

  return agent && (agent.profilePicture || renderFallback) ? (
    <div className={classNames("center-align", className)}>
      <Image
        key={agent.profilePicture?.id}
        width={width}
        height={height}
        preview={preview}
        src={
          agent.profilePicture
            ? `${getApiBaseUrl()}/agents/${agent.id}/profile-picture?auth=${token}&accountId=${userAccount?.id}`
            : ""
        }
        fallback={UserOutlined}
      />
      {showActions && (
        <>
          <div className="clear-both" />
          <Upload accept="image/*" showUploadList={false} beforeUpload={handleProfilePictureUpload}>
            <ActionTextIcon icon="edit" color="blue" text={t("common.change")} />
          </Upload>
          <Divider type="vertical" />
          <Popconfirm
            title={t("agent.titles.deleteProfilePictureConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => actions.onDeleteClick({ id: agent.id })}
          >
            <ActionTextIcon icon="delete" color="red" text={t("common.remove")} disabled={!agent.profilePicture} />
          </Popconfirm>
        </>
      )}
    </div>
  ) : null;
};

export default AgentProfilePictureView;
