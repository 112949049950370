import t from "../../app/i18n";

export enum ClientType {
  NATURAL = "NATURAL",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  LEGAL = "LEGAL"
}

export enum ClientRepresentativeFunction {
  LEGAL_REPRESENTATIVE = "LEGAL_REPRESENTATIVE",
  DELEGATE = "DELEGATE"
}

export enum ClientFormType {
  INSURED = "INSURED",
  POLICY_HOLDER = "POLICY_HOLDER",
  REPRESENTATIVE = "REPRESENTATIVE",
  VINCULATION = "VINCULATION",
  HOLDER = "HOLDER",
  OWNER = "OWNER",
  CO_DEBTOR = "CO_DEBTOR"
}

export enum ClientFormStage {
  EMPTY = "EMPTY",
  NEW = "NEW",
  EXISTING = "EXISTING",
  SELECTED = "SELECTED"
}

export enum ClientSearchActionType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE"
}

export const clientTypeTMap = new Map<ClientType, string>(
  Object.values(ClientType).map(type => [type, t("client.enums.type." + type)])
);

export enum CalcValidationGroup {
  CALCULATE = "CALCULATE",
  GENERATE = "GENERATE"
}
