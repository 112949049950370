import { Alert, Col, Form, Input, Modal, Result, Row } from "antd";
import { useState } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { userCreateUserTotpDeviceActions, userCreateUserTotpDeviceSecretActions } from "../../ducks";
import { CreateUserTotpDevice, CreateUserTotpDeviceSecret, UserProfile, UserTotpDeviceSecret } from "../../types";

interface Props {
  open: boolean;
  user: UserProfile;
  totpDeviceSecret?: UserTotpDeviceSecret;
  onCreateSecret: typeof userCreateUserTotpDeviceSecretActions.request;
  onCreate: typeof userCreateUserTotpDeviceActions.request;
  onFormCancel: () => void;
}

type FormState = "password" | "password-submitted" | "qr-code" | "name-and-totp-code" | "name-and-totp-code-submitted";

const CreateUserTotpDeviceForm = ({ open, user, totpDeviceSecret, onCreateSecret, onCreate, onFormCancel }: Props) => {
  const [formState, setFormState] = useState<FormState>("password");

  const [form] = Form.useForm<CreateUserTotpDeviceSecret | CreateUserTotpDevice>();
  useFormErrorHandler(form, "user.totpDevice.attrs", [
    requests.USER_CREATE_USER_TOTP_DEVICE_SECRET,
    requests.USER_CREATE_USER_TOTP_DEVICE
  ]);

  const createTotpDeviceSecretInProgress = useRequestFinishedCallback(
    [requests.USER_CREATE_USER_TOTP_DEVICE_SECRET],
    () => {
      if (formState === "password-submitted" && totpDeviceSecret) {
        setFormState("qr-code");
        form.setFieldValue("secret", totpDeviceSecret.secret);
      }
    }
  );
  const createTotpDeviceInProgress = useRequestFinishedCallback([requests.USER_CREATE_USER_TOTP_DEVICE], () => {
    if (formState === "name-and-totp-code-submitted") {
      handleFormCancel();
    }
  });

  const handleOkClick = (): void => {
    form
      .validateFields()
      .then(values => {
        switch (formState) {
          case "password":
          case "password-submitted":
            setFormState("password-submitted");
            onCreateSecret({ id: user.id, object: values as CreateUserTotpDeviceSecret });
            break;
          case "qr-code":
            setFormState("name-and-totp-code");
            break;
          case "name-and-totp-code":
          case "name-and-totp-code-submitted":
            setFormState("name-and-totp-code-submitted");
            onCreate({ id: user.id, object: values as CreateUserTotpDevice });
            break;
        }
      })
      .catch(resolveFormValidationError);
  };

  const handleFormCancel = (): void => {
    onFormCancel();
    setFormState("password");
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t("user.totpDevice.titles.create")}
      okText={
        formState === "name-and-totp-code" || formState === "name-and-totp-code-submitted"
          ? t("common.save")
          : t("common.next")
      }
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={createTotpDeviceSecretInProgress || createTotpDeviceInProgress}
      afterClose={() => form.resetFields()}
      onOk={handleOkClick}
      onCancel={handleFormCancel}
    >
      <Form form={form} layout="vertical" name="createUserTotpDeviceForm">
        {formState === "password" || formState === "password-submitted" ? (
          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item name="password" label={t("user.totpDevice.attrs.password")} rules={[validations.notBlank]}>
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <HiddenInput name="password" />
        )}

        {(formState === "password-submitted" || formState === "qr-code") && (
          <Row gutter={rowGutter}>
            <Col span={24}>
              <div>
                {formState === "password-submitted" && createTotpDeviceSecretInProgress && (
                  <Result
                    status="info"
                    icon={<AntIcon type="loading" />}
                    title={t("user.totpDevice.helpers.loadingQrCodeInfo")}
                  />
                )}

                {formState === "qr-code" && totpDeviceSecret && (
                  <>
                    <HiddenInput name="secret" />

                    <h2 style={{ marginBottom: 0, textAlign: "center" }}>
                      {t("user.totpDevice.helpers.scanQrCodeInfo")}
                    </h2>

                    <img src={totpDeviceSecret.qrCodeDataUri} alt="qr-code" style={{ width: "100%" }} />

                    <Alert
                      type="info"
                      className="center-align"
                      message={<i>{t("user.totpDevice.helpers.copyManualSetupKeyInfo")}</i>}
                      description={<b>{totpDeviceSecret.secret}</b>}
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}

        {(formState === "name-and-totp-code" || formState === "name-and-totp-code-submitted") && (
          <>
            <HiddenInput name="secret" />

            <Row gutter={rowGutter}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={t("user.totpDevice.attrs.name")}
                  rules={[validations.notNull, validations.size(1, 64)]}
                >
                  <Input placeholder={t("user.totpDevice.helpers.nameHint")} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={24}>
                <Form.Item
                  name="code"
                  label={t("user.totpDevice.attrs.code")}
                  rules={[validations.notBlank, validations.length(6), validations.numeric]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CreateUserTotpDeviceForm;
