import { Modal, Tree } from "antd";
import { Key } from "rc-tree/lib/interface";
import { useState } from "react";
import t from "../../../../app/i18n";
import { ModalSizes } from "../../../../common/constants";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import type { UUID } from "../../../../typings/global";
import { requests } from "../../api";
import { DocumentNodeType } from "../../enums";
import { DocumentNodeMoveFormProps } from "../../types";

interface Props {
  formProps: DocumentNodeMoveFormProps;
  onNodesMove: (parentId?: UUID) => void;
  onFormCancel: () => void;
}

const DocumentNodeMoveForm = ({ formProps, onNodesMove, onFormCancel }: Props) => {
  const [selectedKey, setSelectedKey] = useState<Key>();

  const inProgress = useRequestFinishedCallback([requests.UPDATE_DOCUMENT_NODE], onFormCancel);

  const handleSelect = (selectedKeys: Key[]): void => {
    setSelectedKey(selectedKeys[0]);
  };

  const handleFormSubmit = (): void => {
    if (selectedKey) {
      onNodesMove(
        selectedKey === DocumentNodeType.GENERAL || selectedKey === DocumentNodeType.PERSONAL
          ? undefined
          : selectedKey.toString()
      );
    }
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={formProps.open}
      title={t("documentNode.titles.moveNodes")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
      destroyOnClose
    >
      <Tree.DirectoryTree
        treeData={formProps.treeData}
        defaultExpandedKeys={[formProps.treeData[0]?.key as string | number]}
        expandAction="doubleClick"
        onSelect={handleSelect}
      />
    </Modal>
  );
};

export default DocumentNodeMoveForm;
