import { Col, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import TableActionsView from "../../../../../common/components/views/TableActionsView";
import { rowGutter, TableSizes } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import {
  formatAgentName,
  formatLocaleCurrency,
  formatLocaleCurrencyWithNullAsZero
} from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../auth/ducks";
import { SpecialCommissionsReport } from "../../../special/enums";
import { SpecialCommission, SpecialCommissionsFilterPageResult } from "../../../special/types";
import { includeSpecialCommissionActions } from "../../ducks";
import { CommissionsBatchStep } from "../../enums";

interface Props {
  commissionsPage: SpecialCommissionsFilterPageResult<SpecialCommission>;
  onPageChange: (pageNumber: number) => void;
  onExcludeClick: (commission: SpecialCommission) => void;
  onInclude: typeof includeSpecialCommissionActions.request;
}

const BatchSpecialCommissionTableView = ({ commissionsPage, onPageChange, onExcludeClick, onInclude }: Props) => {
  const hasManageCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.COMMISSIONS_MANAGE)(state)
  );

  const columns: ColumnsType<SpecialCommission> = [
    {
      key: "code",
      title: t("commissions.special.attrs.code"),
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.code}</Ellipsis>
    },
    {
      key: "name",
      title: t("commissions.special.attrs.name"),
      width: 252,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "payer",
      title: t("commissions.special.attrs.payer"),
      width: 155,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatAgentName(record.payer)}</Ellipsis>
    },
    {
      key: "payee",
      title: t("commissions.special.attrs.payee"),
      width: 155,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatAgentName(record.payee)}</Ellipsis>
    },
    {
      key: "amount",
      title: t("commissions.special.attrs.commissionAmount"),
      align: "right",
      width: 100,
      render: (_, record) =>
        formatLocaleCurrency(
          record.payee.id === commissionsPage.agent?.id ? record.commissionAmount : -record.commissionAmount
        )
    }
  ];

  if (commissionsPage.batch?.step === CommissionsBatchStep.RECAPITULATION && hasManageCommissionsPermission) {
    columns.push({
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_, record) => {
        if (commissionsPage.report === SpecialCommissionsReport.INCLUDED) {
          return (
            <TableActionsView
              actions={[
                {
                  color: "orange",
                  text: t("common.exclude"),
                  icon: "branch",
                  disabled: commissionsPage.batch?.stepChangeInProgress,
                  onClick: () => onExcludeClick(record)
                }
              ]}
            />
          );
        }
        if (commissionsPage.report === SpecialCommissionsReport.EXCLUDED) {
          return (
            <TableActionsView
              actions={[
                {
                  color: "green",
                  text: t("common.include"),
                  icon: "pull-request",
                  disabled: commissionsPage.batch?.stepChangeInProgress,
                  onClick: () =>
                    commissionsPage.batch && commissionsPage.agent
                      ? onInclude({
                          id1: commissionsPage.batch.id,
                          id2: commissionsPage.agent.id,
                          id3: record.id,
                          object: { optimisticLockVersion: record.optimisticLockVersion }
                        })
                      : undefined
                }
              ]}
            />
          );
        }
        return null;
      }
    });
  }

  return (
    <>
      <div className={commissionsPage.totalElementsCount > 0 ? "table-header-margin" : "margin-bottom-small"}>
        <b>{t("commissions.special.helpers.filteredCommissionAmountsSum")}: </b>
        {formatLocaleCurrencyWithNullAsZero(commissionsPage.commissionAmountsSum)}
      </div>
      <Table<SpecialCommission>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.MEDIUM }}
        dataSource={commissionsPage.pageData}
        expandable={{
          rowExpandable: () => commissionsPage.report === SpecialCommissionsReport.EXCLUDED,
          expandedRowRender: record => (
            <Row gutter={rowGutter} justify="center" className="margin-top-small margin-bottom-small">
              <Col flex="140px">
                <b>{t("commissions.special.attrs.exclusionReason")}:</b>
              </Col>
              <Col flex="1 1 0">{record.exclusionReason}</Col>
            </Row>
          )
        }}
        pagination={{
          ...paginationTableProps,
          current: commissionsPage.pageIndex + 1,
          pageSize: commissionsPage.pageSize,
          total: commissionsPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </>
  );
};

export default BatchSpecialCommissionTableView;
