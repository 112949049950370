import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { QueryKeys, URLQuery } from "../../../../common/utils/queryUtils";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { VehicleCalcDraftList } from "../../calcs/vehicle/types";
import { CALC_ROUTE_PATHS } from "../../paths";
import CalcTypeTag from "../../records/components/CalcTypeTag";
import { CalcDraftTableViewProps } from "../types";
import CalcDraftAttachmentsTableView from "./CalcDraftAttachmentsTableView";

const VehicleCalcDraftTableView = ({ draftsPage, ...props }: CalcDraftTableViewProps) => {
  const columns: ColumnsType<VehicleCalcDraftList> = [
    {
      key: "calcType",
      title: t("common.type"),
      width: 120,
      render: (_, record) => <CalcTypeTag type={record.calcType} style={{ marginRight: 0 }} />
    },
    {
      key: "clientName",
      title: t("calc.draft.attrs.clientName"),
      width: 210,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.clientName}</Ellipsis>
    },
    {
      key: "vehicle",
      title: t("common.vehicle"),
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <Ellipsis>
          {[
            `${record.customBrand || record.brandName} ${record.customModel || record.modelName}`,
            record.licensePlate,
            record.vin,
            t("calc.vehicle.enums.vehiclePurpose." + record.purpose)
          ]
            .filter(t => !!t)
            .join(" | ")}
        </Ellipsis>
      )
    },
    {
      key: "attachmentsCount",
      title: t("calc.draft.attrs.attachmentsCount"),
      width: 60,
      render: (_, record) => record.attachments?.length || 0
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 190,
      render: (_, record) => (
        <>
          <ActionTextIcon
            path={`${CALC_ROUTE_PATHS.vehicle.to}${URLQuery.toString(new URLSearchParams({ [QueryKeys.DRAFT_ID]: record.id }))}`}
            target="_blank"
            icon="import"
            color="blue"
            text={t("calc.draft.actions.setToCalcForm")}
          />

          <Divider type="vertical" />

          <Popconfirm
            title={t("calc.draft.titles.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => props.onDelete({ id: record.id })}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Table<VehicleCalcDraftList>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.MEDIUM }}
      dataSource={draftsPage.pageData as VehicleCalcDraftList[]}
      expandable={{
        expandedRowRender: record => (
          <CalcDraftAttachmentsTableView
            calcDraft={record}
            onUpload={props.onUploadCalcDraftAttachments}
            onDelete={props.onDeleteCalcDraftAttachment}
          />
        )
      }}
      pagination={{
        ...paginationTableProps,
        current: draftsPage.pageIndex + 1,
        pageSize: draftsPage.pageSize,
        total: draftsPage.totalElementsCount,
        onChange: props.onPageChange
      }}
    />
  );
};

export default VehicleCalcDraftTableView;
