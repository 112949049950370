import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { VehicleAutocompleteAction, vehicleAutocompleteReducer, vehicleAutocompleteSaga } from "./autocomplete/ducks";
import { VehicleBrandAndModelAction, vehicleBrandAndModelReducer, vehicleBrandAndModelSaga } from "./brand/ducks";
import { VehicleCategoryAction, vehicleCategoryReducer, vehicleCategorySaga } from "./category/ducks";
import { VehicleColorAction, vehicleColorReducer, vehicleColorSaga } from "./color/ducks";
import { VehicleFuelTypeAction, vehicleFuelTypeReducer, vehicleFuelTypeSaga } from "./fueltype/ducks";
import { VehiclePriceAction, vehiclePriceReducer, vehiclePriceSaga } from "./price/ducks";
import { VehicleReducerState } from "./types";

/**
 * ACTIONS
 */
export type VehicleAction =
  | VehicleAutocompleteAction
  | VehicleBrandAndModelAction
  | VehicleCategoryAction
  | VehicleColorAction
  | VehicleFuelTypeAction
  | VehiclePriceAction;

/**
 * REDUCERS
 */
export const vehicleReducer = combineReducers<VehicleReducerState>({
  autocomplete: vehicleAutocompleteReducer,
  brandAndModel: vehicleBrandAndModelReducer,
  category: vehicleCategoryReducer,
  color: vehicleColorReducer,
  fuelType: vehicleFuelTypeReducer,
  price: vehiclePriceReducer
});

/**
 * SAGAS
 */
export function* vehicleSaga() {
  yield all([
    vehicleAutocompleteSaga(),
    vehicleBrandAndModelSaga(),
    vehicleCategorySaga(),
    vehicleColorSaga(),
    vehicleFuelTypeSaga(),
    vehiclePriceSaga()
  ]);
}
