import { Tag } from "antd";
import t from "../../../app/i18n";
import { ContractStatus } from "../enums";

interface Props {
  status?: ContractStatus;
  closable?: boolean;
  style?: React.CSSProperties;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  label?: React.ReactNode;
}

const ContractStatusTag = ({ status, closable, style, onClose, label }: Props) => {
  let color;

  switch (status) {
    case ContractStatus.UNSTARTED:
      color = "processing";
      break;
    case ContractStatus.ACTIVE:
      color = "success";
      break;
    case ContractStatus.CANCELED:
      color = "grey";
      break;
    case ContractStatus.FINISHED:
      color = "default";
      break;
    case ContractStatus.TRANSFERRED_TO_BROKER:
      color = "black";
      break;
  }

  return status || label ? (
    <Tag color={color} closable={closable} style={style} onClose={onClose}>
      {status ? t("contract.enums.status." + status) : label}
    </Tag>
  ) : null;
};

export default ContractStatusTag;
