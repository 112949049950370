import { Col, Form, InputNumber, Modal, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  inputNumberDecimalStandardProps,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import InstitutionSelect from "../../../../enumerations/components/form/InstitutionSelect";
import { requests } from "../../api";
import { createCommissionsUnitActions, updateCommissionsUnitActions } from "../../ducks";
import { CommissionsBatch, CommissionsUnit, CreateCommissionsUnit, UpdateCommissionsUnit } from "../../types";

interface Props {
  batch: CommissionsBatch;
  unit?: CommissionsUnit;
  open: boolean;
  onCreate: typeof createCommissionsUnitActions.request;
  onUpdate: typeof updateCommissionsUnitActions.request;
  onFormCancel: () => void;
}

const CommissionsUnitForm = ({ batch, unit, open, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateCommissionsUnit | UpdateCommissionsUnit>();
  useFormErrorHandler(form, "commissions.batch.attrs.unit", [
    requests.CREATE_COMMISSIONS_UNIT,
    requests.UPDATE_COMMISSIONS_UNIT
  ]);

  useEffect(() => {
    if (open && unit) {
      form.setFieldsValue({ ...unit, institutionId: unit.institution.id });
    }
  }, [open, unit, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_COMMISSIONS_UNIT, requests.UPDATE_COMMISSIONS_UNIT],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (unit) {
          onUpdate({ id1: batch.id, id2: unit.id, object: values as UpdateCommissionsUnit });
        } else {
          onCreate({ id: batch.id, object: values as CreateCommissionsUnit });
        }
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 8;

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={unit ? t("commissions.batch.titles.updateUnit") : t("commissions.batch.titles.createUnit")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="commissionsUnitForm">
        <HiddenInput name="optimisticLockVersion" />

        {!unit && (
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <InstitutionSelect
                formItemProps={{
                  name: "institutionId",
                  label: t("commissions.batch.attrs.unit.institutionId"),
                  rules: [validations.notNull]
                }}
                optionsProps={{
                  groupByType: true,
                  filter: institution =>
                    !batch.commissionsUnits.flatMap(u => u.institution).some(i => i.id === institution.id)
                }}
              />
            </Col>
          </Row>
        )}

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="bankAccountCommissionAmount"
              label={t("commissions.batch.attrs.unit.bankAccountCommissionAmount")}
              rules={[validations.none]}
            >
              <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="invoiceCommissionAmount"
              label={t("commissions.batch.attrs.unit.invoiceCommissionAmount")}
              rules={[validations.none]}
            >
              <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="volumeCommissionAmount"
              label={t("commissions.batch.attrs.unit.volumeCommissionAmount")}
              rules={[validations.none]}
            >
              <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CommissionsUnitForm;
