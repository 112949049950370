import { Col, Form, Input, Modal, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { createProductGroupActions, updateProductGroupActions } from "../../ducks";
import { CreateUpdateProductGroup, ProductGroup } from "../../types";

interface Props {
  open: boolean;
  productGroup?: ProductGroup;
  onCreate: typeof createProductGroupActions.request;
  onUpdate: typeof updateProductGroupActions.request;
  onFormCancel: () => void;
}

const ProductGroupForm = ({ open, productGroup, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateProductGroup>();
  useFormErrorHandler(form, "product.attrs", [requests.CREATE_PRODUCT_GROUP, requests.UPDATE_PRODUCT_GROUP]);

  useEffect(() => {
    if (open && productGroup) {
      form.setFieldsValue(productGroup);
    }
  }, [open, productGroup, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_PRODUCT_GROUP, requests.UPDATE_PRODUCT_GROUP],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (productGroup) {
          onUpdate({ id: productGroup.id, object: values });
        } else {
          onCreate(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t(productGroup ? "product.titles.updateProductGroup" : "product.titles.createProductGroup")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={productGroup} />

      <Form form={form} layout="vertical" name="productGroupForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={12} offset={6}>
            <Form.Item
              name="name"
              label={t("product.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 64)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductGroupForm;
