import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, RootState, SearchPageRequest, SearchPageResult } from "../../common/types";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { replaceInArray } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import { getEnumerationsActions } from "../enumerations/ducks";
import api from "./api";
import {
  CreateUpdateLifeInsuranceTariff,
  CreateUpdateLifeInsuranceTariffGroup,
  LifeInsuranceTariff,
  LifeInsuranceTariffFilterPageRequest,
  LifeInsuranceTariffFilterPageResult,
  LifeInsuranceTariffGroup,
  LifeInsuranceTariffReducerState
} from "./types";

/**
 * ACTIONS
 */
export const filterLifeInsuranceTariffGroupsActions = createAsyncAction(
  "life-insurance-tariff-group/FILTER_REQUEST",
  "life-insurance-tariff-group/FILTER_SUCCESS",
  "life-insurance-tariff-group/FILTER_FAILURE"
)<SearchPageRequest, SearchPageResult<LifeInsuranceTariffGroup>, void>();

export const createLifeInsuranceTariffGroupActions = createAsyncAction(
  "life-insurance-tariff-group/CREATE_REQUEST",
  "life-insurance-tariff-group/CREATE_SUCCESS",
  "life-insurance-tariff-group/CREATE_FAILURE"
)<CreateUpdateLifeInsuranceTariffGroup, LifeInsuranceTariffGroup, void>();

export const updateLifeInsuranceTariffGroupActions = createAsyncAction(
  "life-insurance-tariff-group/UPDATE_REQUEST",
  "life-insurance-tariff-group/UPDATE_SUCCESS",
  "life-insurance-tariff-group/UPDATE_FAILURE"
)<EntityObject<CreateUpdateLifeInsuranceTariffGroup>, LifeInsuranceTariffGroup, void>();

export const deleteLifeInsuranceTariffGroupActions = createAsyncAction(
  "life-insurance-tariff-group/DELETE_REQUEST",
  "life-insurance-tariff-group/DELETE_SUCCESS",
  "life-insurance-tariff-group/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateLifeInsuranceTariffGroupsPageAction = createAction(
  "life-insurance-tariff-group/DELETE_STATE_LIST"
)<void>();

export const filterLifeInsuranceTariffsActions = createAsyncAction(
  "life-insurance-tariff/FILTER_REQUEST",
  "life-insurance-tariff/FILTER_SUCCESS",
  "life-insurance-tariff/FILTER_FAILURE"
)<LifeInsuranceTariffFilterPageRequest, LifeInsuranceTariffFilterPageResult, void>();

export const createLifeInsuranceTariffActions = createAsyncAction(
  "life-insurance-tariff/CREATE_REQUEST",
  "life-insurance-tariff/CREATE_SUCCESS",
  "life-insurance-tariff/CREATE_FAILURE"
)<CreateUpdateLifeInsuranceTariff, LifeInsuranceTariff, void>();

export const updateLifeInsuranceTariffActions = createAsyncAction(
  "life-insurance-tariff/UPDATE_REQUEST",
  "life-insurance-tariff/UPDATE_SUCCESS",
  "life-insurance-tariff/UPDATE_FAILURE"
)<EntityObject<CreateUpdateLifeInsuranceTariff>, LifeInsuranceTariff, void>();

export const deleteLifeInsuranceTariffActions = createAsyncAction(
  "life-insurance-tariff/DELETE_REQUEST",
  "life-insurance-tariff/DELETE_SUCCESS",
  "life-insurance-tariff/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateLifeInsuranceTariffsPageAction = createAction(
  "life-insurance-tariff/DELETE_STATE_LIST"
)<void>();

const actions = {
  filterLifeInsuranceTariffGroupsActions,
  createLifeInsuranceTariffGroupActions,
  updateLifeInsuranceTariffGroupActions,
  deleteLifeInsuranceTariffGroupActions,
  deleteStateLifeInsuranceTariffGroupsPageAction,
  filterLifeInsuranceTariffsActions,
  createLifeInsuranceTariffActions,
  updateLifeInsuranceTariffActions,
  deleteLifeInsuranceTariffActions,
  deleteStateLifeInsuranceTariffsPageAction
};

export type LifeInsuranceTariffAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: LifeInsuranceTariffReducerState = {
  tariffGroupsPage: initSearchPageResult<LifeInsuranceTariffGroup>(),
  tariffsPage: {
    ...initSearchPageResult<LifeInsuranceTariff>(),
    groupIds: []
  }
};

const tariffGroupsPageReducer = createReducer(initialState.tariffGroupsPage)
  .handleAction(filterLifeInsuranceTariffGroupsActions.success, (_, { payload }) => payload)
  .handleAction(updateLifeInsuranceTariffGroupActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterLifeInsuranceTariffGroupsActions.failure, deleteStateLifeInsuranceTariffGroupsPageAction],
    () => initialState.tariffGroupsPage
  );

const tariffsPageReducer = createReducer(initialState.tariffsPage)
  .handleAction(filterLifeInsuranceTariffsActions.success, (_, { payload }) => payload)
  .handleAction(updateLifeInsuranceTariffActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterLifeInsuranceTariffsActions.failure, deleteStateLifeInsuranceTariffsPageAction],
    () => initialState.tariffsPage
  );

export const lifeInsuranceTariffReducer = combineReducers<LifeInsuranceTariffReducerState>({
  tariffGroupsPage: tariffGroupsPageReducer,
  tariffsPage: tariffsPageReducer
});

/**
 * SELECTORS
 */
const selectLifeInsuranceTariff = (state: RootState): LifeInsuranceTariffReducerState => state.lifeInsuranceTariff;

export const selectLifeInsuranceTariffGroupsPage = (state: RootState): SearchPageResult<LifeInsuranceTariffGroup> =>
  selectLifeInsuranceTariff(state).tariffGroupsPage;
export const selectLifeInsuranceTariffsPage = (state: RootState): LifeInsuranceTariffFilterPageResult =>
  selectLifeInsuranceTariff(state).tariffsPage;

/**
 * SAGAS
 */
function* filterLifeInsuranceTariffGroups({
  payload
}: ReturnType<typeof filterLifeInsuranceTariffGroupsActions.request>) {
  try {
    const response: AxiosResponse<SearchPageResult<LifeInsuranceTariffGroup>> = yield call(
      api.filterLifeInsuranceTariffGroups,
      payload
    );
    yield put(filterLifeInsuranceTariffGroupsActions.success(response.data));
  } catch {
    yield put(filterLifeInsuranceTariffGroupsActions.failure());
  }
}

function* createLifeInsuranceTariffGroup({
  payload
}: ReturnType<typeof createLifeInsuranceTariffGroupActions.request>) {
  try {
    const response: AxiosResponse<LifeInsuranceTariffGroup> = yield call(api.createLifeInsuranceTariffGroup, payload);
    yield put(createLifeInsuranceTariffGroupActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();

    const currentPage: SearchPageResult<LifeInsuranceTariffGroup> = yield select(selectLifeInsuranceTariffGroupsPage);
    yield put(
      filterLifeInsuranceTariffGroupsActions.request({
        pageIndex: currentPage.pageIndex,
        pageSize: currentPage.pageSize,
        keyword: currentPage.keyword
      })
    );
  } catch {
    yield put(createLifeInsuranceTariffGroupActions.failure());
  }
}

function* updateLifeInsuranceTariffGroup({
  payload
}: ReturnType<typeof updateLifeInsuranceTariffGroupActions.request>) {
  try {
    const response: AxiosResponse<LifeInsuranceTariffGroup> = yield call(api.updateLifeInsuranceTariffGroup, payload);
    yield put(updateLifeInsuranceTariffGroupActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateLifeInsuranceTariffGroupActions.failure());
  }
}

function* deleteLifeInsuranceTariffGroup({
  payload
}: ReturnType<typeof deleteLifeInsuranceTariffGroupActions.request>) {
  try {
    yield call(api.deleteLifeInsuranceTariffGroup, payload);
    yield put(deleteLifeInsuranceTariffGroupActions.success());
    yield put(getEnumerationsActions.request());
    messageUtils.itemDeletedNotification();

    const currentPage: SearchPageResult<LifeInsuranceTariffGroup> = yield select(selectLifeInsuranceTariffGroupsPage);
    yield put(
      filterLifeInsuranceTariffGroupsActions.request({
        pageIndex: currentPage.pageElementsCount === 1 ? Math.max(currentPage.pageIndex - 1, 0) : currentPage.pageIndex,
        pageSize: currentPage.pageSize,
        keyword: currentPage.keyword
      })
    );
  } catch {
    yield put(deleteLifeInsuranceTariffGroupActions.failure());
  }
}

function* filterLifeInsuranceTariffs({ payload }: ReturnType<typeof filterLifeInsuranceTariffsActions.request>) {
  try {
    const response: AxiosResponse<LifeInsuranceTariffFilterPageResult> = yield call(
      api.filterLifeInsuranceTariffs,
      payload
    );
    yield put(filterLifeInsuranceTariffsActions.success(response.data));
  } catch {
    yield put(filterLifeInsuranceTariffsActions.failure());
  }
}

function* createLifeInsuranceTariff({ payload }: ReturnType<typeof createLifeInsuranceTariffActions.request>) {
  try {
    const response: AxiosResponse<LifeInsuranceTariff> = yield call(api.createLifeInsuranceTariff, payload);
    yield put(createLifeInsuranceTariffActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();

    const currentPage: LifeInsuranceTariffFilterPageResult = yield select(selectLifeInsuranceTariffsPage);
    yield put(
      filterLifeInsuranceTariffsActions.request({
        pageIndex: currentPage.pageIndex,
        pageSize: currentPage.pageSize,
        keyword: currentPage.keyword,
        groupIds: currentPage.groupIds
      })
    );
  } catch {
    yield put(createLifeInsuranceTariffActions.failure());
  }
}

function* updateLifeInsuranceTariff({ payload }: ReturnType<typeof updateLifeInsuranceTariffActions.request>) {
  try {
    const response: AxiosResponse<LifeInsuranceTariff> = yield call(api.updateLifeInsuranceTariff, payload);
    yield put(updateLifeInsuranceTariffActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateLifeInsuranceTariffActions.failure());
  }
}

function* deleteLifeInsuranceTariff({ payload }: ReturnType<typeof deleteLifeInsuranceTariffActions.request>) {
  try {
    yield call(api.deleteLifeInsuranceTariff, payload);
    yield put(deleteLifeInsuranceTariffActions.success());
    yield put(getEnumerationsActions.request());
    messageUtils.itemDeletedNotification();

    const currentPage: LifeInsuranceTariffFilterPageResult = yield select(selectLifeInsuranceTariffsPage);
    yield put(
      filterLifeInsuranceTariffsActions.request({
        pageIndex: currentPage.pageElementsCount === 1 ? Math.max(currentPage.pageIndex - 1, 0) : currentPage.pageIndex,
        pageSize: currentPage.pageSize,
        keyword: currentPage.keyword,
        groupIds: currentPage.groupIds
      })
    );
  } catch {
    yield put(deleteLifeInsuranceTariffActions.failure());
  }
}

export function* lifeInsuranceTariffSaga() {
  yield takeLatest(filterLifeInsuranceTariffGroupsActions.request, filterLifeInsuranceTariffGroups);
  yield takeLatest(createLifeInsuranceTariffGroupActions.request, createLifeInsuranceTariffGroup);
  yield takeLatest(updateLifeInsuranceTariffGroupActions.request, updateLifeInsuranceTariffGroup);
  yield takeLatest(deleteLifeInsuranceTariffGroupActions.request, deleteLifeInsuranceTariffGroup);
  yield takeLatest(filterLifeInsuranceTariffsActions.request, filterLifeInsuranceTariffs);
  yield takeLatest(createLifeInsuranceTariffActions.request, createLifeInsuranceTariff);
  yield takeLatest(updateLifeInsuranceTariffActions.request, updateLifeInsuranceTariff);
  yield takeLatest(deleteLifeInsuranceTariffActions.request, deleteLifeInsuranceTariff);
}
