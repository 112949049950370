import { Button, Card } from "antd";
import { useState } from "react";
import t from "../../../../../../../app/i18n";
import AntIcon from "../../../../../../../common/components/icons/AntIcon";
import ComponentWithPermission from "../../../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../../../common/security/authorization/enums";
import {
  CommissionsBatch,
  CommissionsBatchAttachmentActions,
  CommissionsUnit,
  CommissionsUnitActions
} from "../../../../types";
import CommissionsUnitForm from "../../../forms/CommissionsUnitForm";
import CommissionsUnitTableView from "../views/CommissionsUnitTableView";

interface Props {
  batch: CommissionsBatch;
  unitActions: CommissionsUnitActions;
  attachmentActions: CommissionsBatchAttachmentActions;
}

const CommissionsBatchImportStep = ({ batch, unitActions, attachmentActions }: Props) => {
  const [unitFormOpen, setUnitFormOpen] = useState<boolean>(false);
  const [unitToUpdate, setUnitToUpdate] = useState<CommissionsUnit>();

  const handleUpdateClick = (unit: CommissionsUnit): void => {
    setUnitFormOpen(true);
    setUnitToUpdate(unit);
  };

  const handleFormCancel = (): void => {
    setUnitFormOpen(false);
    setUnitToUpdate(undefined);
  };

  return (
    <Card className="card-box">
      <ComponentWithPermission permissions={[Permission.COMMISSIONS_MANAGE]}>
        <div className={batch.commissionsUnits?.length > 0 ? "table-header-margin" : "margin-bottom-small"}>
          <Button type="primary" icon={<AntIcon type="plus" />} onClick={() => setUnitFormOpen(true)}>
            {t("commissions.batch.actions.addUnit")}
          </Button>
        </div>
      </ComponentWithPermission>

      <CommissionsUnitTableView
        batch={batch}
        onUpdateClick={handleUpdateClick}
        onDelete={unitActions.onDelete}
        attachmentActions={attachmentActions}
      />

      <CommissionsUnitForm
        batch={batch}
        unit={unitToUpdate}
        open={unitFormOpen}
        onCreate={unitActions.onCreate}
        onUpdate={unitActions.onUpdate}
        onFormCancel={handleFormCancel}
      />
    </Card>
  );
};

export default CommissionsBatchImportStep;
