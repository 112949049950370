import { List, Modal, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import HtmlView from "../../../../common/components/views/HtmlView";
import { ModalSizes } from "../../../../common/constants";
import { formatLocaleDateTime } from "../../../../common/utils/formatUtils";
import { fromNow } from "../../../../common/utils/formUtils";
import {
  DashboardNoticeAttachments,
  DashboardNoticeIcon
} from "../../../dashboard/components/widgets/notices/components";
import { DashboardNotice } from "../../../dashboard/types";

interface Props {
  open: boolean;
  notices: DashboardNotice[];
  onCancel: () => void;
}

const CalcNoticesModal = ({ open, notices, onCancel }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("calc.titles.notices")}
      forceRender
      closable
      maskClosable
      okButtonProps={{ style: { display: "none" } }}
      cancelText={t("common.close")}
      className="calc-notices-modal"
      onCancel={onCancel}
    >
      <div style={{ maxHeight: "65vh", overflow: "auto" }}>
        <List<DashboardNotice>
          itemLayout="vertical"
          dataSource={notices}
          renderItem={item => (
            <List.Item
              key={item.id}
              actions={item.attachments.length ? [<DashboardNoticeAttachments notice={item} />] : undefined}
            >
              <List.Item.Meta
                avatar={<DashboardNoticeIcon type={item.type} />}
                title={<Ellipsis>{item.title}</Ellipsis>}
                description={<Tooltip title={formatLocaleDateTime(item.createdAt)}>{fromNow(item.createdAt)}</Tooltip>}
              />
              <HtmlView value={item.text} />
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default CalcNoticesModal;
