import ClientTypeTag from "../../../../modules/client/components/tags/ClientTypeTag";
import { ClientType } from "../../../../modules/client/enums";
import { DateHistoryItem, NoteHistoryItem } from "./commonHistoryItems";

const TypeItem = (value: ClientType) => <ClientTypeTag type={value} />;

export const ClientHistoryItemsMap = new Map<string, (value: any) => React.JSX.Element>([
  ["birthDate", DateHistoryItem],
  ["note", NoteHistoryItem],
  ["type", TypeItem]
]);
