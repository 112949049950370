import { Checkbox, Col, Form, Input, Modal, Row, Select, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill-new";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import {
  quillEditorStandardProps,
  resolveFormValidationError,
  selectTagsStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { storageFileToRcFile } from "../../../../common/utils/utils";
import { validations } from "../../../../common/utils/validationUtils";
import type { UUID } from "../../../../typings/global";
import { requests } from "../../api";
import { createDashboardNoticeActions, updateDashboardNoticeActions } from "../../ducks";
import {
  DashboardNoticePosition,
  dashboardNoticePositionTMap,
  DashboardNoticeType,
  dashboardNoticeTypeTMap
} from "../../enums";
import { CreateUpdateDashboardNotice, DashboardNotice } from "../../types";
import { DashboardNoticePositionTag, DashboardNoticeTypeTag } from "../widgets/notices/components";

interface Props {
  open: boolean;
  notice?: DashboardNotice;
  onFormCancel: () => void;
}

export const DashboardNoticeForm = ({ open, notice, onFormCancel }: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<CreateUpdateDashboardNotice>();
  useFormErrorHandler(form, "dashboard.notices.attrs", [
    requests.CREATE_DASHBOARD_NOTICE,
    requests.UPDATE_DASHBOARD_NOTICE
  ]);

  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<UUID[]>([]);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onCreate: createDashboardNoticeActions.request,
          onUpdate: updateDashboardNoticeActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    if (open && notice) {
      setFileList(notice.attachments.map(attachment => storageFileToRcFile(attachment.file)) as RcFile[]);
      form.setFieldsValue({
        ...notice,
        positions: notice.positions || [],
        attachments: undefined
      } as DashboardNotice | CreateUpdateDashboardNotice);
    }
  }, [open, notice, form]);

  const inProgress = useRequestFinishedCallback(
    [
      requests.CREATE_DASHBOARD_NOTICE,
      requests.UPDATE_DASHBOARD_NOTICE,
      requests.ADD_DASHBOARD_NOTICE_ATACHMENTS,
      requests.DELETE_DASHBOARD_NOTICE_ATACHMENT
    ],
    onFormCancel
  );

  const handleAfterClosed = (): void => {
    setFileList([]);
    setFileIdsToDelete([]);
    form.resetFields();
  };

  const handleFormSubmit = (): void => {
    const formData = new FormData();
    fileList
      .filter(file => file.lastModified)
      .forEach(file => {
        formData.append("files", file);
      });

    form
      .validateFields()
      .then(values => {
        const processedValues: CreateUpdateDashboardNotice = {
          ...values,
          positions: values.positions || [],
          filesToAdd: formData,
          fileIdsToDelete
        };

        if (notice) {
          actions.onUpdate({ id: notice.id, object: processedValues });
        } else {
          actions.onCreate(processedValues);
        }
      })
      .catch(resolveFormValidationError);
  };

  const handleFilesAdd = (files: RcFile[]): boolean => {
    const allFiles = [...fileList, ...files];

    setFileList(
      allFiles.filter(
        (file, index) =>
          index === allFiles.findIndex(f => f.name === file.name && f.size === file.size && f.uid === file.uid)
      )
    );

    return false;
  };

  const handleFileRemove = (file: UploadFile): boolean => {
    setFileList(fileList.filter(f => f.uid !== file.uid));

    if (notice && !file.lastModified) {
      const attachmentId = notice.attachments.find(attachment => file.uid === attachment.file.id)?.id;

      if (attachmentId) {
        setFileIdsToDelete([...fileIdsToDelete, attachmentId]);
      }
    }

    return false;
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t(`dashboard.notices.actions.${notice ? "update" : "create"}`)}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
      afterClose={handleAfterClosed}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={notice} />

      <Form form={form} layout="vertical" name="createUpdateDashboardNotice">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("dashboard.notices.attrs.title")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="type" label={t("dashboard.notices.enums.type._label")} rules={[validations.notNull]}>
              <Select
                {...selectTagsStandardProps(dashboardNoticeTypeTMap)}
                options={Object.values(DashboardNoticeType).map(type => ({
                  value: type,
                  label: <DashboardNoticeTypeTag type={type} />
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="text"
              label={t("dashboard.notices.attrs.text")}
              rules={[validations.notBlank, validations.size(1, 8192)]}
              initialValue={undefined}
            >
              <ReactQuill {...quillEditorStandardProps} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item name="positions" label={t("dashboard.notices.enums.position._label")} rules={[validations.none]}>
              <Select
                {...selectTagsStandardProps(dashboardNoticePositionTMap)}
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                tagRender={props => (
                  <DashboardNoticePositionTag
                    position={props.value}
                    closable={props.closable}
                    onClose={props.onClose}
                    label={props.label}
                  />
                )}
                options={Object.values(DashboardNoticePosition).map(position => ({
                  value: position,
                  label: <DashboardNoticePositionTag position={position} />
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="closed"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("dashboard.notices.attrs.closed")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Upload
          multiple
          fileList={fileList}
          beforeUpload={(_, fileList) => handleFilesAdd(fileList)}
          onRemove={handleFileRemove}
        >
          <ActionButton icon="plus">{t("common.addAttachments")}</ActionButton>
        </Upload>
      </Form>
    </Modal>
  );
};
