export enum VehiclePurpose {
  NORMAL = "NORMAL",
  CAR_RENTAL = "CAR_RENTAL",
  DRIVING_SCHOOL = "DRIVING_SCHOOL",
  TAXI = "TAXI",
  PRIORITY_VEHICLE = "PRIORITY_VEHICLE",
  DANGEROUS_LOAD = "DANGEROUS_LOAD",
  VETERAN_CAR = "VETERAN_CAR",
  VETERAN_CAR_H = "VETERAN_CAR_H",
  INTERNATIONAL_PEOPLE_TRANSPORT = "INTERNATIONAL_PEOPLE_TRANSPORT"
}

export enum VehicleSecurityFeature {
  IMMOBILISER = "IMMOBILISER",
  MECHANICAL = "MECHANICAL",
  VIN_PRINT = "VIN_PRINT",
  ALARM = "ALARM",
  TRACKING_SYSTEM = "TRACKING_SYSTEM"
}

export enum HolderAccidentsYearsKey {
  TWO = "2",
  THREE = "3",
  FIVE = "5",
  EIGHT = "8",
  TEN = "10",
  ALL = "All"
}

export enum TimeWithoutAccident {
  NEVER = "NEVER", // 120 months
  LESS_THAN_1_YEAR = "LESS_THAN_1_YEAR", // 0 months
  AT_LEAST_1_YEAR = "AT_LEAST_1_YEAR", // 12 months
  AT_LEAST_2_YEARS = "AT_LEAST_2_YEARS", // 24 months
  AT_LEAST_3_YEARS = "AT_LEAST_3_YEARS", // 36 months
  AT_LEAST_4_YEARS = "AT_LEAST_4_YEARS", // 48 months
  AT_LEAST_5_YEARS = "AT_LEAST_5_YEARS", // 60 months
  AT_LEAST_6_YEARS = "AT_LEAST_6_YEARS", // 72 months
  AT_LEAST_7_YEARS = "AT_LEAST_7_YEARS", // 84 months
  AT_LEAST_8_YEARS = "AT_LEAST_8_YEARS", // 96 months
  AT_LEAST_9_YEARS = "AT_LEAST_9_YEARS" // 108 months
}

export enum VehiclePolicyHolderRelation {
  SAME_AS_VEHICLE_HOLDER = "SAME_AS_VEHICLE_HOLDER",
  DIFFERENT_FROM_VEHICLE_HOLDER = "DIFFERENT_FROM_VEHICLE_HOLDER"
}

export enum VehicleOwnerRelation {
  SAME_AS_VEHICLE_HOLDER = "SAME_AS_VEHICLE_HOLDER",
  SAME_AS_POLICY_HOLDER = "SAME_AS_POLICY_HOLDER",
  DIFFERENT_FROM_BOTH = "DIFFERENT_FROM_BOTH"
}

export enum GlassReinsuranceAmount {
  AT_LEAST_300 = "AT_LEAST_300",
  AT_LEAST_500 = "AT_LEAST_500",
  AT_LEAST_750 = "AT_LEAST_750",
  AT_LEAST_1000 = "AT_LEAST_1000"
}

export enum AnimalReinsuranceAmount {
  AT_LEAST_2000 = "AT_LEAST_2000",
  AT_LEAST_4000 = "AT_LEAST_4000",
  AT_LEAST_6000 = "AT_LEAST_6000"
}

export enum SmallDamageReinsuranceAmount {
  AT_LEAST_400 = "AT_LEAST_400",
  AT_LEAST_800 = "AT_LEAST_800",
  AT_LEAST_1200 = "AT_LEAST_1200"
}

export enum TotalDamageReinsuranceAmount {
  AT_LEAST_4000 = "AT_LEAST_4000",
  AT_LEAST_6000 = "AT_LEAST_6000",
  AT_LEAST_8000 = "AT_LEAST_8000"
}

export enum ReplacementVehicleCategory {
  LOWER_STANDARD = "LOWER_STANDARD",
  HIGHER_STANDARD = "HIGHER_STANDARD"
}

export enum VehicleEquipmentType {
  SUNROOF = "SUNROOF",
  NAVIGATION = "NAVIGATION",
  LEATHER = "LEATHER",
  ALLOY_WHEELS = "ALLOY_WHEELS",
  OTHER = "OTHER"
}

export enum VehicleTerritorialValidity {
  SVK = "SVK",
  INTERNATIONAL_CARD_STATES = "INTERNATIONAL_CARD_STATES",
  EUROPE = "EUROPE",
  EUROPE_TUR = "EUROPE_TUR"
}

export enum GapDuration {
  MONTHS_12 = "MONTHS_12", // 1 year
  MONTHS_24 = "MONTHS_24", // 2 years
  MONTHS_36 = "MONTHS_36", // 3 years
  MONTHS_48 = "MONTHS_48", // 4 years
  MONTHS_60 = "MONTHS_60", // 5 years
  MONTHS_72 = "MONTHS_72" // 6 years
}

export enum GapCoverageLimit {
  LIMIT_5000 = "LIMIT_5000",
  LIMIT_10000 = "LIMIT_10000",
  LIMIT_20000 = "LIMIT_20000",
  LIMIT_35000 = "LIMIT_35000",
  LIMIT_50000 = "LIMIT_50000"
}

export enum VehiclePriceSource {
  KPAS_KOOP_PRICE_LIST = "KPAS_KOOP_PRICE_LIST",
  KPAS_KOOP_INVOICE = "KPAS_KOOP_INVOICE",
  KPAS_KOOP_SLOVEXPERTA = "KPAS_KOOP_SLOVEXPERTA"
}

export enum VehicleImportCountry {
  CZE = "CZE",
  HUN = "HUN",
  POL = "POL",
  AUT = "AUT",
  SVN = "SVN",
  DEU = "DEU",
  CHE = "CHE",
  FRA = "FRA",
  ITA = "ITA",
  DNK = "DNK",
  NOR = "NOR",
  SWE = "SWE",
  FIN = "FIN",
  NLD = "NLD",
  BEL = "BEL",
  LUX = "LUX",
  ROU = "ROU",
  GBR = "GBR",
  GRC = "GRC",
  ESP = "ESP",
  PRT = "PRT",
  EST = "EST",
  LVA = "LVA",
  LTU = "LTU",
  RUS = "RUS",
  UKR = "UKR",
  BLR = "BLR",
  IRL = "IRL",
  OTHER = "OTHER"
}
