import isEmpty from "lodash/isEmpty";
import t from "../../../app/i18n";
import { formatAddress } from "../../utils/formatUtils";
import { isAddress, isArrayOfContacts, isLabelledHistoryItem } from "../../utils/typesUtils";
import { isDefinedValue } from "../../utils/utils";
import ContactsView from "../contact/ContactsView";
import { HistoryItem, HistoryItemRenderer, HistoryRecordType, LabelledHistoryItem } from "../types";
import { AgentHistoryItemsMap } from "./items/agentHistoryItems";
import { ClientHistoryItemsMap } from "./items/clientHistoryItems";
import { EmptyHistoryItem } from "./items/commonHistoryItems";
import { ContractHistoryItemsMap } from "./items/contractHistoryItems";

interface Props {
  item?: HistoryItem;
  recordType?: HistoryRecordType;
}

const HistoryItemValueView = ({ item, recordType }: Props) => {
  if (!item) {
    return <EmptyHistoryItem />;
  }

  const { value, fieldName } = item;

  const unsupportedValue = <span>{t("history.helpers.unsupportedValueType")}</span>;

  const renderHistoryRecordStringValue = (renderer: HistoryItemRenderer): React.JSX.Element => {
    const item = renderer.get(fieldName);
    return renderer.has(fieldName) && item ? item(value) : <span>{value}</span>;
  };

  const renderHistoryRecordObjectValue = (renderer: HistoryItemRenderer): React.JSX.Element => {
    const item = renderer.get(fieldName);
    return renderer.has(fieldName) && item ? item(value) : unsupportedValue;
  };

  if (!isDefinedValue(value)) {
    return <EmptyHistoryItem />;
  } else if (typeof value === "object") {
    if (isEmpty(value)) {
      return <EmptyHistoryItem />;
    } else if (isLabelledHistoryItem(value)) {
      return <span>{(value as LabelledHistoryItem).label}</span>;
    } else if (isAddress(value)) {
      return <span>{formatAddress(value)}</span>;
    } else if (isArrayOfContacts(value)) {
      return (
        <ContactsView
          contacts={value}
          marketingConsentsAreApplicable={recordType === "client"}
          size={recordType === "client" ? "small" : "normal"}
        />
      );
    } else {
      switch (recordType) {
        case "contract":
          return renderHistoryRecordObjectValue(ContractHistoryItemsMap);
        case "agent":
          return renderHistoryRecordObjectValue(AgentHistoryItemsMap);
        case "client":
          return renderHistoryRecordObjectValue(ClientHistoryItemsMap);
        default:
          return unsupportedValue;
      }
    }
  } else {
    switch (recordType) {
      case "contract":
        return renderHistoryRecordStringValue(ContractHistoryItemsMap);
      case "agent":
        return renderHistoryRecordStringValue(AgentHistoryItemsMap);
      case "client":
        return renderHistoryRecordStringValue(ClientHistoryItemsMap);
      default:
        return unsupportedValue;
    }
  }
};

export default HistoryItemValueView;
