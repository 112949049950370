export enum SpecialCommissionsSettingsRuleType {
  NETTO_POINTS_RATE = "NETTO_POINTS_RATE",
  MONTHLY = "MONTHLY",
  ONE_TIME = "ONE_TIME"
}

export enum SpecialCommissionsReport {
  INCLUDED = "INCLUDED",
  EXCLUDED = "EXCLUDED"
}
