import { generatePath } from "react-router-dom";
import t from "../../app/i18n";
import { AFFILIATE_PARTNER_ROUTE_PATHS } from "../../modules/affiliate/paths";
import { AGENT_ROUTE_PATHS } from "../../modules/agent/paths";
import { AUTH_ROUTE_PATHS } from "../../modules/auth/paths";
import { CALC_BLACKLIST_ROUTE_PATHS } from "../../modules/calculator/blacklist/paths";
import { CALC_ROUTE_PATHS } from "../../modules/calculator/paths";
import { ADMIN_CALC_ROUTE_PATHS } from "../../modules/calculator/settings/paths";
import { CLIENT_ROUTE_PATHS } from "../../modules/client/paths";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../modules/commissions/batch/paths";
import { COMMISSIONS_LEVEL_ROUTE_PATHS } from "../../modules/commissions/level/paths";
import { POSTPONED_COMMISSIONS_ROUTE_PATHS } from "../../modules/commissions/postponed/paths";
import { COMPLICITY_ROUTE_PATHS } from "../../modules/complicity/paths";
import { CONTRACT_ROUTE_PATHS } from "../../modules/contract/paths";
import { CONTRACT_UPDATE_ROUTE_PATHS } from "../../modules/contractupdate/paths";
import { COVERAGE_LIMIT_ROUTE_PATHS } from "../../modules/coveragelimit/paths";
import { DASHBOARD_ROUTE_PATHS } from "../../modules/dashboard/paths";
import { DOCUMENT_NODE_ROUTE_PATHS } from "../../modules/documents/paths";
import { CONFIG_PROPERTY_ROUTE_PATHS } from "../../modules/dynamicconfig/paths";
import { FINANCIAL_MEDIATION_ROUTE_PATHS } from "../../modules/financialmediation/paths";
import { INSTITUTION_ROUTE_PATHS } from "../../modules/institution/paths";
import { JOB_ROUTE_PATHS } from "../../modules/jobs/paths";
import { LIFE_INSURENCE_TARIFF_ROUTE_PATHS } from "../../modules/lifeinsurancetariff/paths";
import { NOTIFICATION_ROUTE_PATHS } from "../../modules/notifications/paths";
import { PARTNER_ROUTE_PATHS } from "../../modules/partner/paths";
import { PRODUCT_ROUTE_PATHS } from "../../modules/product/paths";
import { ADMIN_USER_ROUTE_PATHS, CURRENT_USER_ROUTE_PATHS } from "../../modules/user/paths";
import { VEHICLE_BRAND_ROUTE_PATHS } from "../../modules/vehicle/brand/paths";
import { VEHICLE_ENUM_ROUTE_PATHS } from "../../modules/vehicle/paths";
import type { UUID } from "../../typings/global";
import AntIcon from "../components/icons/AntIcon";
import { PageMapItem } from "../modules/types";
import { Permission } from "../security/authorization/enums";
import { regexPatterns } from "./validationUtils";

const UUID_PLACEHOLDER = "00000000-0000-0000-0000-000000000000";

export const PagesMap = new Map<string, PageMapItem>([
  [
    DASHBOARD_ROUTE_PATHS.homepage.to,
    {
      title: t("page.dashboard"),
      render: <AntIcon type="home" />,
      wrappedStyle: "wide"
    }
  ],
  [
    AUTH_ROUTE_PATHS.login.to,
    {
      title: t("page.login"),
      hideBreadcrumbs: true
    }
  ],
  [
    NOTIFICATION_ROUTE_PATHS.list.to,
    {
      title: t("page.notifications"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    CURRENT_USER_ROUTE_PATHS.profile.to,
    {
      title: t("page.userProfile"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      siblings: [
        {
          path: CURRENT_USER_ROUTE_PATHS.commissions.to,
          permissions: [Permission.PROFILE_COMMISSIONS]
        }
      ],
      wrappedStyle: true
    }
  ],
  [
    CURRENT_USER_ROUTE_PATHS.commissions.to,
    {
      title: t("page.userCommissionsSettings"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      siblings: [{ path: CURRENT_USER_ROUTE_PATHS.profile.to }]
    }
  ],
  [
    CLIENT_ROUTE_PATHS.list.to,
    {
      title: t("page.clients"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    generatePath(CLIENT_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.clientDetail"),
      parent: CLIENT_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    CLIENT_ROUTE_PATHS.create.to,
    {
      title: t("page.clientCreate"),
      parent: CLIENT_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.list.to,
    {
      title: t("page.contracts"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to
    }
  ],
  [
    generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.contractDetail"),
      parent: CONTRACT_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.create.to,
    {
      title: t("page.contractCreate"),
      parent: CONTRACT_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.predictedCommissions.to,
    {
      title: t("page.contractPredictedCommissions"),
      parent: CONTRACT_ROUTE_PATHS.list.to
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.anniversaryDate.to,
    {
      title: t("page.contractAnniversaryDate"),
      parent: CONTRACT_ROUTE_PATHS.list.to
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.fixationAnniversaryDate.to,
    {
      title: t("page.contractFixationAnniversaryDate"),
      parent: CONTRACT_ROUTE_PATHS.list.to
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.unpaid.to,
    {
      title: t("page.contractUnpaid"),
      parent: CONTRACT_ROUTE_PATHS.list.to
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.forms.to,
    {
      title: t("page.contractForms"),
      parent: CONTRACT_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    generatePath(FINANCIAL_MEDIATION_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.financialMediationDetail"),
      parent: CONTRACT_ROUTE_PATHS.forms.to,
      wrappedStyle: true
    }
  ],
  [
    FINANCIAL_MEDIATION_ROUTE_PATHS.create.to,
    {
      title: t("page.financialMediationCreate"),
      parent: CONTRACT_ROUTE_PATHS.forms.to,
      wrappedStyle: true
    }
  ],
  [
    CALC_ROUTE_PATHS.vehicle.to,
    {
      title: t("page.calcVehicle"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      siblings: [
        { path: CALC_ROUTE_PATHS.realty.to, permissions: [Permission.REALTY_CALCULATE] },
        { path: CALC_ROUTE_PATHS.travel.to, permissions: [Permission.TRAVEL_CALCULATE] }
      ],
      wrappedStyle: true
    }
  ],
  [
    CALC_ROUTE_PATHS.realty.to,
    {
      title: t("page.calcRealty"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      siblings: [
        { path: CALC_ROUTE_PATHS.vehicle.to, permissions: [Permission.MTPL_CALCULATE, Permission.CRASH_CALCULATE] },
        { path: CALC_ROUTE_PATHS.travel.to, permissions: [Permission.TRAVEL_CALCULATE] }
      ],
      wrappedStyle: true
    }
  ],
  [
    CALC_ROUTE_PATHS.travel.to,
    {
      title: t("page.calcTravel"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      siblings: [
        { path: CALC_ROUTE_PATHS.vehicle.to, permissions: [Permission.MTPL_CALCULATE, Permission.CRASH_CALCULATE] },
        { path: CALC_ROUTE_PATHS.realty.to, permissions: [Permission.REALTY_CALCULATE] }
      ],
      wrappedStyle: true
    }
  ],
  [
    CALC_ROUTE_PATHS.vehicleDraft.to,
    {
      title: t("page.calcDraftsVehicle"),
      parent: CALC_ROUTE_PATHS.vehicle.to,
      wrappedStyle: true
    }
  ],
  [
    CALC_ROUTE_PATHS.realtyDraft.to,
    {
      title: t("page.calcDraftsRealty"),
      parent: CALC_ROUTE_PATHS.realty.to,
      wrappedStyle: true
    }
  ],
  [
    CALC_ROUTE_PATHS.travelDraft.to,
    {
      title: t("page.calcDraftsTravel"),
      parent: CALC_ROUTE_PATHS.travel.to,
      wrappedStyle: true
    }
  ],
  [
    CALC_ROUTE_PATHS.records.to,
    {
      title: t("page.calcRecords"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    PARTNER_ROUTE_PATHS.configs.to,
    {
      title: t("page.partners"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    DOCUMENT_NODE_ROUTE_PATHS.list.to,
    {
      title: t("page.documents"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    AGENT_ROUTE_PATHS.list.to,
    {
      title: t("page.agents"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    AGENT_ROUTE_PATHS.tree.to,
    {
      title: t("page.agentTree"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    generatePath(AGENT_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.agentDetail"),
      parent: AGENT_ROUTE_PATHS.list.to,
      siblings: [
        {
          path: generatePath(AGENT_ROUTE_PATHS.commissions.to, { id: UUID_PLACEHOLDER }),
          permissions: [Permission.AGENT_COMMISSIONS]
        }
      ],
      wrappedStyle: true
    }
  ],
  [
    generatePath(AGENT_ROUTE_PATHS.commissions.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.agentCommissions"),
      parent: AGENT_ROUTE_PATHS.list.to,
      siblings: [
        {
          path: generatePath(AGENT_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER }),
          permissions: [Permission.AGENT_READ]
        }
      ]
    }
  ],
  [
    AGENT_ROUTE_PATHS.create.to,
    {
      title: t("page.agentCreate"),
      parent: AGENT_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    AGENT_ROUTE_PATHS.endingCompetences.to,
    {
      title: t("page.agentEndingCompetences"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    AGENT_ROUTE_PATHS.pointsReport.to,
    {
      title: t("page.agentPointsReport"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to
    }
  ],
  [
    AGENT_ROUTE_PATHS.bailAccountsReport.to,
    {
      title: t("page.agentBailAccountsReport"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    AGENT_ROUTE_PATHS.debtAccountsReport.to,
    {
      title: t("page.agentDebtAccountsReport"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    COMMISSIONS_BATCH_ROUTE_PATHS.list.to,
    {
      title: t("page.commissionsBatches"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.commissionsBatchDetail"),
      parent: COMMISSIONS_BATCH_ROUTE_PATHS.list.to
    }
  ],
  [
    generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.attachmentImports.to, { id1: UUID_PLACEHOLDER, id2: UUID_PLACEHOLDER }),
    {
      title: t("page.commissionsBatchAttachmentImports"),
      parent: generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER })
    }
  ],
  [
    generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.manualImports.to, { id1: UUID_PLACEHOLDER, id2: UUID_PLACEHOLDER }),
    {
      title: t("page.commissionsBatchManualImports"),
      parent: generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER })
    }
  ],
  [
    generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.postponedCommissions.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.commissionsBatchPostponedCommissions"),
      parent: generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER })
    }
  ],
  [
    generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.specialCommissions.to, { id1: UUID_PLACEHOLDER, id2: UUID_PLACEHOLDER }),
    {
      title: t("page.commissionsBatchSpecialCommissions"),
      parent: generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER })
    }
  ],
  [
    generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.manualBailAccountMovements.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.commissionsBatchBailAccountMovements"),
      parent: generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER })
    }
  ],
  [
    POSTPONED_COMMISSIONS_ROUTE_PATHS.list.to,
    {
      title: t("page.postponedCommissions"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to
    }
  ],
  [
    COMMISSIONS_LEVEL_ROUTE_PATHS.list.to,
    {
      title: t("page.commissionsLevel"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    generatePath(COMMISSIONS_LEVEL_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.commissionsLevelDetail"),
      parent: COMMISSIONS_LEVEL_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    INSTITUTION_ROUTE_PATHS.list.to,
    {
      title: t("page.institutions"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    generatePath(INSTITUTION_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.institutionUpdate"),
      parent: INSTITUTION_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    INSTITUTION_ROUTE_PATHS.create.to,
    {
      title: t("page.institutionCreate"),
      parent: INSTITUTION_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    PRODUCT_ROUTE_PATHS.list.to,
    {
      title: t("page.products"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    AFFILIATE_PARTNER_ROUTE_PATHS.list.to,
    {
      title: t("page.affiliatePartners"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    ADMIN_USER_ROUTE_PATHS.list.to,
    {
      title: t("page.users"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    generatePath(ADMIN_USER_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.userDetail"),
      parent: ADMIN_USER_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    ADMIN_USER_ROUTE_PATHS.create.to,
    {
      title: t("page.userCreate"),
      parent: ADMIN_USER_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    ADMIN_CALC_ROUTE_PATHS.list.to,
    {
      title: t("page.calculatorsSettings"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    generatePath(ADMIN_CALC_ROUTE_PATHS.detail.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.calculatorSettingsUpdate"),
      parent: ADMIN_CALC_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    ADMIN_CALC_ROUTE_PATHS.create.to,
    {
      title: t("page.calculatorSettingsCreate"),
      parent: ADMIN_CALC_ROUTE_PATHS.list.to,
      wrappedStyle: true
    }
  ],
  [
    CONTRACT_UPDATE_ROUTE_PATHS.list.to,
    {
      title: t("page.contractUpdates"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    LIFE_INSURENCE_TARIFF_ROUTE_PATHS.list.to,
    {
      title: t("page.lifeInsuranceTariffs"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    VEHICLE_BRAND_ROUTE_PATHS.brands.to,
    {
      title: t("page.vehicleBrands"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to
    }
  ],
  [
    generatePath(VEHICLE_BRAND_ROUTE_PATHS.models.to, { id: UUID_PLACEHOLDER }),
    {
      title: t("page.vehicleModels"),
      parent: VEHICLE_BRAND_ROUTE_PATHS.brands.to
    }
  ],
  [
    VEHICLE_ENUM_ROUTE_PATHS.list.to,
    {
      title: t("page.vehicleEnums"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    COVERAGE_LIMIT_ROUTE_PATHS.list.to,
    {
      title: t("page.coverageLimits"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    COMPLICITY_ROUTE_PATHS.list.to,
    {
      title: t("page.complicities"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    JOB_ROUTE_PATHS.list.to,
    {
      title: t("page.jobs"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    CALC_BLACKLIST_ROUTE_PATHS.list.to,
    {
      title: t("page.calcBlacklists"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ],
  [
    CONFIG_PROPERTY_ROUTE_PATHS.list.to,
    {
      title: t("page.configProperties"),
      parent: DASHBOARD_ROUTE_PATHS.homepage.to,
      wrappedStyle: true
    }
  ]
]);

export const replaceUrlUuidsWithPlaceholders = (path: string): { path: string; uuids: UUID[] } => {
  const uuids: UUID[] = [];

  const outputPath =
    "/" +
    path
      .substring(1)
      .split("/")
      .map(pathPart => {
        const uuid = pathPart.match(regexPatterns.uuidRegex)?.[0];
        if (uuid) {
          uuids.push(uuid);
          return UUID_PLACEHOLDER;
        }
        return pathPart;
      })
      .join("/");

  return { path: outputPath, uuids };
};

export const replaceUrlPlaceholdersWithUuids = (path: string, uuids: UUID[]): string => {
  let index = 0;
  return path.replaceAll(UUID_PLACEHOLDER, () => uuids[index++] as string);
};

export const resolvePageTitle = (path: string): string => {
  const page = PagesMap.get(replaceUrlUuidsWithPlaceholders(path).path);
  return page ? `${page.title} | ${t("common.appNameShort")}` : `${t("common.appName")} | ${t("common.appNameShort")}`;
};
