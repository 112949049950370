import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { formatLocaleCurrency, formatPlaceOfInsurance } from "../../../../common/utils/formatUtils";
import { QueryKeys, URLQuery } from "../../../../common/utils/queryUtils";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { RealtyCalcDraftList } from "../../calcs/realty/types";
import { CALC_ROUTE_PATHS } from "../../paths";
import { CalcDraftTableViewProps } from "../types";
import CalcDraftAttachmentsTableView from "./CalcDraftAttachmentsTableView";

const RealtyCalcDraftTableView = ({ draftsPage, ...props }: CalcDraftTableViewProps) => {
  const columns: ColumnsType<RealtyCalcDraftList> = [
    {
      key: "clientName",
      title: t("calc.draft.attrs.clientName"),
      width: 220,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.clientName}</Ellipsis>
    },
    {
      key: "buildingType",
      title: t("calc.realty.enums.buildingType._label"),
      width: 130,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{t("calc.realty.enums.buildingType." + record.buildingType)}</Ellipsis>
    },
    {
      key: "insurances",
      title: t("calc.draft.attrs.insurances"),
      children: [
        {
          key: "realty",
          title: t("calc.realty.sections.realtyInsurance"),
          align: "center",
          width: 130,
          render: (_, record) =>
            record.realtyInsuranceAmount ? (
              formatLocaleCurrency(record.realtyInsuranceAmount)
            ) : (
              <div className="center-align">
                <AntIcon type="close-circle" />
              </div>
            )
        },
        {
          key: "household",
          title: t("calc.realty.sections.householdInsurance"),
          align: "center",
          width: 130,
          render: (_, record) =>
            record.householdInsuranceAmount ? (
              formatLocaleCurrency(record.householdInsuranceAmount)
            ) : (
              <div className="center-align">
                <AntIcon type="close-circle" />
              </div>
            )
        }
      ]
    },
    {
      key: "placeOfInsurance",
      title: t("calc.draft.attrs.placeOfInsurance"),
      width: 240,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatPlaceOfInsurance(record.placeOfInsurance)}</Ellipsis>
    },
    {
      key: "attachmentsCount",
      title: t("calc.draft.attrs.attachmentsCount"),
      width: 60,
      render: (_, record) => record.attachments?.length || 0
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 190,
      render: (_, record) => (
        <>
          <ActionTextIcon
            path={`${CALC_ROUTE_PATHS.realty.to}${URLQuery.toString(new URLSearchParams({ [QueryKeys.DRAFT_ID]: record.id }))}`}
            target="_blank"
            icon="import"
            color="blue"
            text={t("calc.draft.actions.setToCalcForm")}
          />

          <Divider type="vertical" />

          <Popconfirm
            title={t("calc.draft.titles.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => props.onDelete({ id: record.id })}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Table<RealtyCalcDraftList>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.LARGE }}
      dataSource={draftsPage.pageData as RealtyCalcDraftList[]}
      expandable={{
        expandedRowRender: record => (
          <CalcDraftAttachmentsTableView
            calcDraft={record}
            onUpload={props.onUploadCalcDraftAttachments}
            onDelete={props.onDeleteCalcDraftAttachment}
          />
        )
      }}
      pagination={{
        ...paginationTableProps,
        current: draftsPage.pageIndex + 1,
        pageSize: draftsPage.pageSize,
        total: draftsPage.totalElementsCount,
        onChange: props.onPageChange
      }}
    />
  );
};

export default RealtyCalcDraftTableView;
