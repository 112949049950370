import { Button, Col, Collapse, Divider, Popconfirm, Row, Space, Tag, Tree } from "antd";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import { SecondaryButton } from "../../../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { Feature } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { formatAgentName, formatPhoneNumber } from "../../../../../common/utils/formatUtils";
import { selectIsSystemAdmin } from "../../../../auth/ducks";
import { selectTopAgentAllowedFeaturesEnums } from "../../../../enumerations/ducks";
import { adminDeleteAgentUserAccountActions, adminResendUserConfirmLinkActions } from "../../../ducks";
import { UserAccount, UserAdminView } from "../../../types";
import {
  buildPermissionTreeNodesView,
  hasConfirmedAgentUserAccount,
  hasDisabledAgentUserAccountsOnly
} from "../../../utils";

interface Props {
  user: UserAdminView;
  onUpdateUserClick: () => void;
  onResendUserConfirmLink: typeof adminResendUserConfirmLinkActions.request;
  onUpdateUserAccountClick: (account: UserAccount) => void;
  onDeleteUserAccount: typeof adminDeleteAgentUserAccountActions.request;
}

const AdminUserDetailView = ({
  user,
  onUpdateUserClick,
  onResendUserConfirmLink,
  onUpdateUserAccountClick,
  onDeleteUserAccount
}: Props) => {
  const isCurrentUserSystemAdmin = useSelector<RootState, boolean | undefined>(selectIsSystemAdmin);
  const topAgentAllowedFeatures = useSelector<RootState, Feature[]>(selectTopAgentAllowedFeaturesEnums);

  return (
    <>
      <Divider orientation="left" style={{ marginTop: 0 }}>
        {t("user.titles.basicData")}
      </Divider>

      <ItemCreatedUpdatedInfoView item={user} className="clear-both margin-bottom-medium" />

      <table className="data-table-view margin-top-medium margin-bottom-small">
        <tbody>
          <tr>
            <td>{t("user.attrs.name")}:</td>
            <td>{user.name}</td>
            <td>
              {t("user.attrs.wrongLoginAttempts")}: {user.wrongLoginAttempts}
            </td>
          </tr>
          <tr>
            <td>{t("user.attrs.email")}:</td>
            <td>{user.email || user.emailToConfirm}</td>
            <td>
              <Space>
                {user.locked && <Tag color="red">{t("user.helpers.lockedUser")}</Tag>}

                {hasDisabledAgentUserAccountsOnly(user) && <Tag color="purple">{t("user.helpers.disabledUser")}</Tag>}

                {!hasConfirmedAgentUserAccount(user) && (
                  <>
                    <Tag color="orange">{t("user.helpers.unconfirmedUser")}</Tag>
                    <Popconfirm
                      title={t("user.titles.resendConfirmLinkConfirm")}
                      icon={<PopconfirmDeleteIcon />}
                      okText={t("common.yes")}
                      cancelText={t("common.no")}
                      okType="danger"
                      onConfirm={() => onResendUserConfirmLink({ id: user.id })}
                    >
                      <SecondaryButton size="small" type="primary" icon={<AntIcon type="mail" />}>
                        {t("user.actions.resendConfirmLink")}
                      </SecondaryButton>
                    </Popconfirm>
                  </>
                )}

                {user.agentUserRole?.systemAdmin && <Tag color="cyan">{t("user.attrs.systemAdmin")}</Tag>}
              </Space>
            </td>
          </tr>
          <tr>
            <td>{t("user.attrs.phone")}:</td>
            <td>{formatPhoneNumber(hasConfirmedAgentUserAccount(user) ? user.phone : user.phoneToConfirm)}</td>
          </tr>
        </tbody>
      </table>

      <Button
        className="margin-bottom-tiny"
        size="small"
        type="primary"
        icon={<AntIcon type="edit" />}
        onClick={onUpdateUserClick}
      >
        {t("user.actions.updateBasicUserData")}
      </Button>

      <Divider orientation="left">{t("user.titles.accounts")}</Divider>

      <Collapse
        accordion
        className="margin-top-medium"
        items={user.agentUserRole?.userAccounts.map(account => ({
          key: account.id,
          label: account.representingAgent
            ? `${formatAgentName(account.agent)} | ${formatAgentName(account.representingAgent)}`
            : formatAgentName(account.agent),
          extra: (
            <>
              {account.disabled && <Tag color="purple">{t("user.helpers.disabledAccount")}</Tag>}
              <span onClick={event => event.stopPropagation()}>
                <Button
                  className="margin-right-tiny"
                  type="primary"
                  size="small"
                  icon={<AntIcon type="edit" />}
                  onClick={() => onUpdateUserAccountClick(account)}
                >
                  {t("user.actions.updateAccount")}
                </Button>
                <Popconfirm
                  title={
                    <div style={{ maxWidth: 450 }}>
                      <Row>
                        <Col>
                          {t("user.titles.deleteAgentAccountConfirm", {
                            aggregatedName: account.agent?.aggregatedName
                          })}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {user.agentUserRole?.userAccounts.length === 1
                            ? t("user.helpers.deleteLastAccountInfo")
                            : t("user.helpers.updateInfo")}
                        </Col>
                      </Row>
                    </div>
                  }
                  icon={<PopconfirmDeleteIcon />}
                  okText={t("common.yes")}
                  cancelText={t("common.no")}
                  okType="danger"
                  onConfirm={() => onDeleteUserAccount({ id1: user.id, id2: account.id })}
                >
                  <ActionButton icon="minus">{t("user.actions.deleteAccount")}</ActionButton>
                </Popconfirm>
              </span>
            </>
          ),
          children: (
            <>
              <ItemCreatedUpdatedInfoView item={account} className="clear-both margin-bottom-medium" />

              <h4>{t("user.attrs.permissionsLabel")}</h4>
              <Tree
                showLine={{ showLeafIcon: false }}
                treeData={buildPermissionTreeNodesView(
                  topAgentAllowedFeatures,
                  account.permissions,
                  isCurrentUserSystemAdmin
                )}
              />
            </>
          )
        }))}
      />
    </>
  );
};

export default AdminUserDetailView;
