import classNames from "classnames";
import styles from "./ContentWrapper.module.scss";

interface Props {
  children: React.ReactNode;
  wide?: boolean;
}

const ContentWrapper = ({ children, wide }: Props) => (
  <div className={classNames(styles.contentWrapper, wide ? styles.contentWrapperWide : "")}>{children}</div>
);

export default ContentWrapper;
