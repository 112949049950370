import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import { VehicleCategory } from "../../../../contract/enums";
import { requests } from "../../api";
import { createVehicleCategoryActions, updateVehicleCategoryActions } from "../../ducks";
import { CertificateVehicleCategory, CreateUpdateCertificateVehicleCategory } from "../../types";

interface Props {
  category?: CertificateVehicleCategory;
  open: boolean;
  onCreate?: typeof createVehicleCategoryActions.request;
  onUpdate?: typeof updateVehicleCategoryActions.request;
  onFormCancel: () => void;
}

const VehicleCategoryForm = ({ category, open, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateCertificateVehicleCategory>();

  useFormErrorHandler(form, "vehicleCategory.attrs", [
    requests.CREATE_VEHICLE_CATEGORY,
    requests.UPDATE_VEHICLE_CATEGORY
  ]);

  useEffect(() => {
    if (open && category) {
      form.setFieldsValue(category);
    }
  }, [category, open, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_VEHICLE_CATEGORY, requests.UPDATE_VEHICLE_CATEGORY],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (category) {
          onUpdate?.({ id: category.id, object: values });
        } else {
          onCreate?.(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={category ? t("vehicleCategory.titles.updateCategory") : t("vehicleCategory.titles.createCategory")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={category} />

      <Form form={form} layout="vertical" name="vehicleCategoryForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item name="category" label={t("contract.enums.vehicleCategory._label")} rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={Object.keys(VehicleCategory).map(category => ({
                  value: category,
                  label: t("contract.enums.vehicleCategory." + category)
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="value"
              label={t("vehicleCategory.attrs.value")}
              rules={[
                validations.notNull,
                validations.size(1, 64),
                validations.pattern(regexPatterns.vehicleCategoryValueRegex)
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={t("vehicleCategory.attrs.description")}
              rules={[validations.notNull, validations.size(1, 255)]}
            >
              <Input.TextArea autoSize={{ minRows: 4, maxRows: 7 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default VehicleCategoryForm;
