import { Card } from "antd";
import { memo } from "react";
import t from "../../../../../../../app/i18n";
import HtmlView from "../../../../../../../common/components/views/HtmlView";
import {
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocalePercentageNumber
} from "../../../../../../../common/utils/formatUtils";
import { LoanContract } from "../../../../../types";

interface Props {
  contract: LoanContract;
}

const LoanContractDataSection = ({ contract }: Props) => (
  <Card type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractData")}>
    <table className="data-table-view">
      <tbody>
        <tr>
          <td>{t("contract.attrs.mediationReportSignDate")}:</td>
          <td>{formatLocaleDate(contract.mediationReportSignDate)}</td>
          <td>{t("contract.attrs.approvedAmount")}:</td>
          <td className="right-align">{formatLocaleCurrency(contract.approvedAmount)}</td>
          <td>{t("contract.attrs.ltvRatio")}:</td>
          <td className="right-align">{formatLocalePercentageNumber(contract.ltvRatio)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.applicationSignDate")}:</td>
          <td>{formatLocaleDate(contract.applicationSignDate)}</td>
          <td>{t("contract.attrs.monthlyPaymentAmount")}:</td>
          <td className="right-align">{formatLocaleCurrency(contract.monthlyPaymentAmount)}</td>
          <td>{t("contract.attrs.fixationAnniversaryDate")}:</td>
          <td>{formatLocaleDate(contract.fixationAnniversaryDate)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.signDate")}:</td>
          <td>{formatLocaleDate(contract.signDate)}</td>
          <td>{t("contract.attrs.interestRate")}:</td>
          <td className="right-align">{formatLocalePercentageNumber(contract.interestRate)}</td>
          <td>{t("contract.attrs.contactClientDate")}:</td>
          <td>{formatLocaleDate(contract.contactClientDate)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.loanMaturityDate")}:</td>
          <td>{formatLocaleDate(contract.loanMaturityDate)}</td>
          <td>{t("contract.enums.loanRateType._label")}:</td>
          <td>{t("contract.enums.loanRateType." + contract.rateType)}</td>
          <td>{t("contract.attrs.cancellationDate")}:</td>
          <td>{formatLocaleDate(contract.cancellationDate)}</td>
        </tr>
      </tbody>
    </table>

    <table className="data-table-view margin-top-tiny">
      <tbody>
        <tr>
          <td>{t("contract.attrs.note")}:</td>
        </tr>
        <tr>
          <td>{contract.note ? <HtmlView value={contract.note} /> : undefined}</td>
        </tr>
      </tbody>
    </table>
  </Card>
);

export default memo(LoanContractDataSection);
