import { Popover, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { generatePath } from "react-router-dom";
import t from "../../../../../../app/i18n";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import FileTypeIconWithFilename from "../../../../../../common/components/icons/FileTypeIconWithFilename";
import { TableSizes } from "../../../../../../common/constants";
import { formatFileSize } from "../../../../../../common/utils/formatUtils";
import { tableStandardProps } from "../../../../../../common/utils/utils";
import { DASHBOARD_ROUTE_PATHS } from "../../../../paths";
import { DashboardNotice, DashboardNoticeAttachment } from "../../../../types";
import styles from "./DashboardNoticeAttachments.module.scss";

interface Props {
  notice: DashboardNotice;
}

export const DashboardNoticeAttachments = ({ notice }: Props) => {
  const columns: ColumnsType<DashboardNoticeAttachment> = [
    {
      key: "name",
      title: t("common.filename"),
      ellipsis: { showTitle: false },
      width: 350,
      render: (_, record) => (
        <FileTypeIconWithFilename contentType={record.file.contentType} filename={record.file.filename} ellipsis />
      )
    },
    {
      key: "size",
      title: t("common.filesize"),
      width: 150,
      render: (_, record) => formatFileSize(record.file.size)
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <ActionTextIcon
          path={generatePath(DASHBOARD_ROUTE_PATHS.noticeAttachment.to, { id1: notice.id, id2: record.id })}
          target="_blank"
          icon="export"
          color="blue"
          text={t("common.open")}
        />
      )
    }
  ];

  return (
    <Popover
      placement="bottom"
      content={
        <Table<DashboardNoticeAttachment>
          {...tableStandardProps()}
          columns={columns}
          scroll={{ x: TableSizes.SMALL }}
          dataSource={notice.attachments}
          pagination={false}
        />
      }
      trigger="click"
    >
      <div className={styles.dashboardNoticeAttachments}>
        <AntIcon type="paper-clip" /> {t("dashboard.notices.helpers.attachmentsList")}
      </div>
    </Popover>
  );
};
