import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../common/types";
import { selectIsRequestInProgress } from "../../ducks";
import { requests } from "./api";
import { deleteStateVehiclePriceResultAction, getVehiclePriceActions, selectVehiclePriceResult } from "./ducks";
import { VehiclePriceRequestProps } from "./types";

export const useVehiclePriceFetch = (): VehiclePriceRequestProps => {
  const result = useSelector<RootState, number | null>(selectVehiclePriceResult);
  const inProgress = useSelector<RootState, boolean>(state =>
    selectIsRequestInProgress(state, requests.GET_VEHICLE_PRICE)
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onGetPrice: getVehiclePriceActions.request,
          onResultDelete: deleteStateVehiclePriceResultAction
        },
        dispatch
      ),
    [dispatch]
  );

  return {
    result: result ?? undefined,
    inProgress,
    onGetPrice: actions.onGetPrice,
    onResultDelete: actions.onResultDelete
  };
};
