import { Button, Col, Form, Input, Row } from "antd";
import t from "../../../../app/i18n";
import { useFormErrorHandler } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { PasswordReset } from "../../types";

interface Props {
  resetPasswordParams: ResetPasswordParams;
  onFormSubmit: (passwordReset: PasswordReset) => void;
}

interface ResetPasswordParams {
  token: string;
}

const ResetPasswordForm = (props: Props) => {
  const [form] = Form.useForm<PasswordReset>();
  useFormErrorHandler(form, "user.attrs", [requests.USER_RESET_PASSWORD]);

  const handleFormFinish = (values: PasswordReset): void => {
    props.onFormSubmit(Object.assign({}, values, props.resetPasswordParams));
  };

  return (
    <>
      <h2 className="center-align margin-bottom-large">{t("user.titles.passwordReset")}</h2>

      <Row justify="center">
        <Col className="anonymous-form">
          <Form form={form} layout="vertical" name="resetPasswordForm" onFinish={handleFormFinish}>
            <Form.Item name="password" label={t("user.attrs.password")} rules={validations.sufficientPassword}>
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="passwordRepeat"
              label={t("user.attrs.passwordRepeat")}
              rules={[...validations.sufficientPassword, validations.repeatedPassword()]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button className="margin-top-medium" type="primary" htmlType="submit" block>
                {t("user.actions.resetPassword")}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ResetPasswordForm;
