import { ExclamationCircleTwoTone, InfoCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { cssVariables } from "../../../../../../common/utils/utils";
import { DashboardNoticeType } from "../../../../enums";
import styles from "./DashboardNoticeIcon.module.scss";

interface Props {
  type: DashboardNoticeType;
}

export const DashboardNoticeIcon = ({ type }: Props) => {
  let icon: React.ReactNode;

  switch (type) {
    case DashboardNoticeType.INFO:
      icon = <InfoCircleTwoTone twoToneColor={cssVariables.colorBlue} />;
      break;
    case DashboardNoticeType.WARNING:
      icon = <WarningTwoTone twoToneColor={cssVariables.colorYellow} />;
      break;
    case DashboardNoticeType.ERROR:
      icon = <ExclamationCircleTwoTone twoToneColor={cssVariables.colorRed} />;
      break;
    default:
      return null;
  }

  return <div className={styles.dashboardNoticeIconBox}>{icon}</div>;
};
