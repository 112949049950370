import classNames from "classnames";
import { Link } from "react-router-dom";
import ConditionalWrapper from "../../modules/wrappers/ConditionalWrapper";
import type { LinkTargetType } from "../../types";
import AntIcon, { type AntIconType } from "./AntIcon";
import styles from "./AntTextIcon.module.scss";

export interface ActionTextIconProps {
  color?: "blue" | "green" | "red" | "orange";
  text?: string;
  icon?: AntIconType;
  textBeforeIcon?: boolean;
  path?: string;
  externalPath?: boolean;
  target?: LinkTargetType;
  size?: "small" | "default" | "large" | "huge";
  disabled?: boolean;
  disableDelayEffect?: boolean;
  className?: string;
  onClick?: () => void;
}

const ActionTextIcon = ({
  color,
  text,
  icon,
  textBeforeIcon,
  path,
  externalPath,
  target,
  size = "default",
  disabled,
  disableDelayEffect,
  className,
  onClick
}: ActionTextIconProps) => {
  const actionClasses = classNames(
    size && size !== "default" ? styles[size] : "",
    color && !disabled ? styles[color] : "",
    disabled ? styles.disabled : "",
    !disableDelayEffect ? styles.delayEffect : "",
    className
  );

  return (
    <ConditionalWrapper
      condition={!!path && !disabled}
      wrapper={children =>
        externalPath ? (
          <a href={path} target={target} rel={target === "_blank" ? "noreferrer" : undefined}>
            {children}
          </a>
        ) : (
          <Link to={path as string} target={target}>
            {children}
          </Link>
        )
      }
    >
      <span
        onClick={disabled ? undefined : onClick}
        className={actionClasses}
        style={{ cursor: "pointer", whiteSpace: "nowrap" }}
      >
        {textBeforeIcon ? (
          <>
            {text && <span className={classNames({ "margin-right-tiny": !!icon })}>{text}</span>}
            {icon && <AntIcon type={icon} />}
          </>
        ) : (
          <>
            {icon && <AntIcon type={icon} />}
            {text && <span className={classNames({ "margin-left-tiny": !!icon })}>{text}</span>}
          </>
        )}
      </span>
    </ConditionalWrapper>
  );
};

export default ActionTextIcon;
