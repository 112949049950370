import { Card, Checkbox, Col, Divider, Form, Input, Radio, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { useState } from "react";
import t from "../../../../../app/i18n";
import { SecondaryButton } from "../../../../../common/components/buttons/SecondaryButton";
import BooleanRadioFormItem from "../../../../../common/components/form/components/BooleanRadioFormItem";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../common/constants";
import { selectStandardProps } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import {
  InvestmentQuestionnaireClientStatement,
  InvestmentQuestionnaireInvestorType,
  InvestmentQuestionnaireRisk,
  InvestmentQuestionnaireStrategyResult
} from "../../../enums";
import { CreateUpdateFinancialMediation, PillarsFinancialMediationData } from "../../../types";
import { getInvestmentQuestionnaireResultPointsForPillars } from "../../../utils";
import {
  InvestmentQuestionnaireEvaluationModal,
  InvestmentQuestionnairePoints
} from "../../InvestmentQuestionnaireEvaluationModal";
import FinancialMediationQuestionnaireSelect from "../items/FinancialMediationQuestionnaireSelect";

interface Props {
  form: FormInstance<CreateUpdateFinancialMediation<PillarsFinancialMediationData>>;
  validateAllFields?: boolean;
}

const FinancialMediationFormPillarsQuestionnaireSection = ({ form, validateAllFields }: Props) => {
  const [isOpenInvestmentResultModal, setIsOpenInvestmentResultModal] = useState(false);
  const [investmentQuestionnaireResult, setInvestmentQuestionnaireResult] = useState<InvestmentQuestionnairePoints>({
    knowledgeAndExperiencePoints: 0,
    financialSituationPoints: 0,
    riskTolerancePoints: 0
  });

  const clientStatement = Form.useWatch(
    ["mediationData", "investmentQuestionnaireData", "clientStatement"],
    form
  ) as InvestmentQuestionnaireClientStatement;

  const refuseToAnswerQuestions1To7 = Form.useWatch(
    ["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions1To7"],
    form
  );

  const refuseToAnswerQuestions8To10 = Form.useWatch(
    ["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions8To10"],
    form
  );

  const refuseToAnswerQuestions12To13 = Form.useWatch(
    ["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions12To13"],
    form
  );

  const answers = Form.useWatch(["mediationData", "investmentQuestionnaireData", "answers"], form);
  const sector = Form.useWatch("sector", form);

  const handleClickEvaluateInvestmentQuestionnaire = () => {
    if (answers) {
      setInvestmentQuestionnaireResult(getInvestmentQuestionnaireResultPointsForPillars(answers));
    }

    setIsOpenInvestmentResultModal(true);
  };

  return (
    <Card type="inner" className="card-box" title={t("financialMediation.sections.investmentQuestionnaire")}>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <span className="sub-header-info form-item-additional">
            {t("financialMediation.enums.investmentQuestionnaireClientStatement._label")}:
          </span>

          <Form.Item
            name={["mediationData", "investmentQuestionnaireData", "clientStatement"]}
            rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
            className="margin-bottom-small"
          >
            <Radio.Group
              options={Object.keys(InvestmentQuestionnaireClientStatement).map(statement => ({
                label: t("financialMediation.enums.investmentQuestionnaireClientStatement." + statement),
                value: statement
              }))}
            />
          </Form.Item>
        </Col>

        {clientStatement === InvestmentQuestionnaireClientStatement.ALREADY_ANSWERED && (
          <Col span={12}>
            <Divider orientation="left" className="divider-subheader">
              {t("financialMediation.sections.previousQuestionnaireResult")}
            </Divider>

            <Row gutter={rowGutter}>
              <Col span={8}>
                <Form.Item
                  name={["mediationData", "investmentQuestionnaireData", "previousInvestorType"]}
                  label={
                    <span className="form-item-additional">
                      {t("financialMediation.enums.investmentQuestionnaireInvestorType._label")}
                    </span>
                  }
                  rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
                >
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(InvestmentQuestionnaireInvestorType).map(type => ({
                      value: type,
                      label: t("financialMediation.enums.investmentQuestionnaireInvestorType." + type)
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name={["mediationData", "investmentQuestionnaireData", "previousStrategyResult"]}
                  label={
                    <span className="form-item-additional">
                      {t("financialMediation.enums.investmentQuestionnaireStrategyResult._label")}
                    </span>
                  }
                  rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
                >
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(InvestmentQuestionnaireStrategyResult).map(result => ({
                      value: result,
                      label: t("financialMediation.enums.investmentQuestionnaireStrategyResult." + result)
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name={["mediationData", "investmentQuestionnaireData", "previousContractNumber"]}
                  label={
                    <LabelWithTooltip
                      label={t(
                        "financialMediation.attrs.mediationData.investmentQuestionnaireData.previousContractNumber"
                      )}
                      tooltip={t("financialMediation.helpers.questionnaire.previousContractNumberDesc")}
                      className="form-item-additional"
                    />
                  }
                  rules={[
                    validations.conditionalRule(validations.notBlank, validateAllFields),
                    validations.size(1, 64)
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      {clientStatement === InvestmentQuestionnaireClientStatement.WILLING_TO_ANSWER && (
        <>
          <Divider orientation="left" className="divider-subheader">
            {t("financialMediation.sections.questionnaireKnowledge")}
          </Divider>

          <Row gutter={rowGutter} align="bottom">
            {[...Array(7)].map((_, index) => (
              <FinancialMediationQuestionnaireSelect
                key={index}
                index={index}
                questionNumber={index + 1}
                required={!refuseToAnswerQuestions1To7}
                validateAllFields={validateAllFields}
              />
            ))}
          </Row>

          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions1To7"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  {t("financialMediation.attrs.mediationData.investmentQuestionnaireData.refuseToAnswerQuestions1To7")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" className="divider-subheader">
            {t("financialMediation.sections.questionnaireFinanceSituation")}
          </Divider>

          <Row gutter={rowGutter} align="bottom">
            {[...Array(4)].map((_, index) => (
              <FinancialMediationQuestionnaireSelect
                key={index}
                index={index + 7}
                questionNumber={index + 11}
                required={index === 3 || !refuseToAnswerQuestions8To10}
                validateAllFields={validateAllFields}
              />
            ))}
          </Row>

          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions8To10"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  {t("financialMediation.attrs.mediationData.investmentQuestionnaireData.refuseToAnswerQuestions8To10")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" className="divider-subheader">
            {t("financialMediation.sections.questionnaireRiskTolerance")}
          </Divider>

          <Row gutter={rowGutter} align="bottom">
            {[...Array(3)].map((_, index) => (
              <FinancialMediationQuestionnaireSelect
                key={index}
                index={index + 11}
                questionNumber={index + 15}
                required={index === 2 || !refuseToAnswerQuestions12To13}
                validateAllFields={validateAllFields}
              />
            ))}
          </Row>

          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions12To13"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  {t(
                    "financialMediation.attrs.mediationData.investmentQuestionnaireData.refuseToAnswerQuestions12To13"
                  )}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider className="divider-subheader" />

          <Row gutter={rowGutter}>
            <Col span={8}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "strategyResult"]}
                label={
                  <span className="form-item-additional">
                    {t("financialMediation.helpers.questionnaire.strategyResultLabel")}
                  </span>
                }
                rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
              >
                <Select
                  {...selectStandardProps}
                  options={Object.keys(InvestmentQuestionnaireStrategyResult).map(result => ({
                    value: result,
                    label: t("financialMediation.enums.investmentQuestionnaireStrategyResult." + result)
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "informedAboutRisks"]}
                label={
                  <span className="form-item-additional">
                    {t("financialMediation.helpers.questionnaire.informedAboutRisksLabel")}
                  </span>
                }
                rules={[validations.conditionalRule(validations.notNull, validateAllFields)]}
              >
                <Select
                  {...selectStandardProps}
                  mode="multiple"
                  maxTagCount="responsive"
                  options={Object.keys(InvestmentQuestionnaireRisk).map(risk => ({
                    value: risk,
                    label: t("financialMediation.enums.investmentQuestionnaireRisk." + risk)
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <SecondaryButton className="form-item-without-label" onClick={handleClickEvaluateInvestmentQuestionnaire}>
                {t("financialMediation.actions.evaluateInvestmentQuestionnaire")}
              </SecondaryButton>

              {investmentQuestionnaireResult ? (
                <InvestmentQuestionnaireEvaluationModal
                  totalPoints={investmentQuestionnaireResult}
                  isOpen={isOpenInvestmentResultModal}
                  onFormCancel={() => setIsOpenInvestmentResultModal(false)}
                  sector={sector}
                />
              ) : undefined}
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={12}>
              <span className="sub-header-info form-item-additional">
                {t("financialMediation.attrs.mediationData.investmentQuestionnaireData.agentReceivedInfoFromClient")}:
              </span>

              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "investmentQuestionnaireData", "agentReceivedInfoFromClient"],
                  rules: [validations.conditionalRule(validations.notNull, validateAllFields)],
                  className: "margin-bottom-small"
                }}
                trueLabel={
                  <LabelWithTooltip
                    label={t("financialMediation.helpers.questionnaire.agentReceivedInfoFromClient.true")}
                    tooltip={t("financialMediation.helpers.questionnaire.agentReceivedInfoFromClient.trueDesc")}
                  />
                }
                falseLabel={t("financialMediation.helpers.questionnaire.agentReceivedInfoFromClient.falsePillars")}
              />
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default FinancialMediationFormPillarsQuestionnaireSection;
