import type { AxiosRequestConfig } from "axios";
import qs from "qs";
import t from "../../app/i18n";
import type { AutocompleteResult, ErrorResponse, PageResult, SearchPageResult } from "../types";
import messageUtils from "./messageUtils";
import { isNotEmptyArray } from "./utils";

const IDENTIFIER_DELIMITER = "-->";

export const createApiRequestIdentifier = (url: string, method: AxiosRequestConfig["method"]): string => {
  return method?.toUpperCase() + IDENTIFIER_DELIMITER + url;
};

export const serializeParams = (params: any): string => {
  return qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
};

export const initPageResult = <T = any>(): PageResult<T> => ({
  pageData: [],
  pageIndex: 0,
  pageSize: 0,
  totalElementsCount: 0,
  pageElementsCount: 0,
  pagesCount: 0,
  isFirst: true,
  isLast: true
});

export const initNonEmptyPageResult = <T = any>(pageData: T[], pageSize: number): PageResult<T> => {
  if (!isNotEmptyArray(pageData)) {
    return initPageResult<T>();
  }

  let pagesCount = Math.floor(pageData.length / pageSize);
  pagesCount = pageData.length % pageSize > 0 ? pagesCount + 1 : pagesCount;

  return {
    pageData,
    pageIndex: 0,
    pageSize,
    totalElementsCount: pageData.length,
    pageElementsCount: Math.min(pageData.length, pageSize),
    pagesCount,
    isFirst: true,
    isLast: pagesCount === 1
  };
};

export const initSearchPageResult = <T = any>(): SearchPageResult<T> => ({
  ...initPageResult<T>(),
  keyword: undefined
});

export const initAutocompleteResult = <T = any>(): AutocompleteResult<T> => ({
  data: [],
  maxResultSize: 0,
  actualResultSize: 0,
  keyword: undefined
});

export const showErrorResponseNotification = (errorResponse: ErrorResponse): void => {
  if (errorResponse.errors && errorResponse.errors.length > 0) {
    messageUtils.errorNotification({ message: errorResponse.message, description: errorResponse.errors.join(" ") });
  } else {
    messageUtils.errorNotification({
      message: t("common.error"),
      description: errorResponse.message,
      key: errorResponse.message
    });
  }
};
