import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { EntityIdObject, EntityObject, TwoLevelEntityIdObject } from "../../common/types";
import { BugReport, BugReportAttachment, CreateAutomaticFrontendErrorLog, CreateUserReportedBug } from "./types";

const CREATE_AUTOMATIC_FRONTEND_ERROR_LOG_BUG_REPORT = ApiRequestAdapter.create("/bug-reports/frontend", "POST");
const CREATE_USER_REPORTED_BUG_REPORT = ApiRequestAdapter.create("/bug-reports/user-reported", "POST");
const GET_BUG_REPORT_DETAIL = ApiRequestAdapter.create("/bug-reports/user-reported/{0}", "GET");
const ADD_BUG_REPORT_ATTACHMENTS = ApiRequestAdapter.create("/bug-reports/user-reported/{0}/attachments", "POST");
const DOWNLOAD_BUG_REPORT_ATTACHMENT = ApiRequestAdapter.create(
  "/bug-reports/user-reported/{0}/attachments/{1}",
  "GET"
);

export const requests = {
  CREATE_AUTOMATIC_FRONTEND_ERROR_LOG_BUG_REPORT,
  CREATE_USER_REPORTED_BUG_REPORT,
  GET_BUG_REPORT_DETAIL,
  ADD_BUG_REPORT_ATTACHMENTS,
  DOWNLOAD_BUG_REPORT_ATTACHMENT
};

const api = {
  createAutomaticFrontendErrorLogBugReport: (request: CreateAutomaticFrontendErrorLog): AxiosPromise => {
    return apiService.post(CREATE_AUTOMATIC_FRONTEND_ERROR_LOG_BUG_REPORT.url, request);
  },
  createUserReportedBugReport: (request: CreateUserReportedBug): AxiosPromise<BugReport> => {
    return apiService.post(CREATE_USER_REPORTED_BUG_REPORT.url, request);
  },
  getBugReport: (request: EntityIdObject): AxiosPromise<BugReport> => {
    return apiService.get(GET_BUG_REPORT_DETAIL.getParametrizedUrl(request.id));
  },
  addBugReportAttachments: (request: EntityObject<FormData>): AxiosPromise<BugReportAttachment[]> => {
    return apiService.post(ADD_BUG_REPORT_ATTACHMENTS.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  downloadBugReportAttachment: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_BUG_REPORT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  }
};

export default api;
