import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ReactQuill from "react-quill-new";
import t from "../../../../../app/i18n";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../common/constants";
import {
  ibanNormalizeFunction,
  phoneNumberNormalizeFunction,
  quillEditorStandardProps,
  selectStandardProps
} from "../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import CommercialRegisterDistrictOfficeAutoComplete from "../../../../enumerations/components/form/CommercialRegisterDistrictOfficeAutoComplete";
import CommercialRegisterSectionAutoComplete from "../../../../enumerations/components/form/CommercialRegisterSectionAutoComplete";
import { AgentType, FinancialSector } from "../../../enums";
import { AgentBase } from "../../../types";

interface Props {
  agent?: AgentBase;
  searchInProgress?: boolean;
  onTipperChange: (event: CheckboxChangeEvent) => void;
  onCrnSearchOrChange?: (crn: string) => void;
}

const LegalAgentDataFormPart = ({ agent, searchInProgress, onTipperChange, onCrnSearchOrChange }: Props) => {
  const colSpan = 4;

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="companyRegistrationNumber"
            label={t("agent.attrs.companyRegistrationNumber")}
            rules={[validations.notBlank, validations.crn]}
          >
            {onCrnSearchOrChange ? (
              <Input.Search
                loading={searchInProgress}
                onSearch={onCrnSearchOrChange}
                onChange={e => onCrnSearchOrChange?.(e.target.value)}
              />
            ) : (
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="companyName"
            label={t("agent.attrs.companyName")}
            rules={[
              validations.notBlank,
              validations.size(1, 255),
              validations.pattern(regexPatterns.agentSortNameRegex)
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <CommercialRegisterDistrictOfficeAutoComplete
            formItemProps={{
              name: "commercialRegisterDistrictOffice",
              label: t("agent.attrs.commercialRegisterDistrictOffice"),
              rules: [validations.size(1, 64)]
            }}
          />
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item name="taxId" label={t("agent.attrs.taxId")} rules={[validations.length(10), validations.numeric]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <CommercialRegisterSectionAutoComplete
            formItemProps={{
              name: "commercialRegisterSection",
              label: t("agent.attrs.commercialRegisterSection"),
              rules: [validations.size(1, 64)]
            }}
          />
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="vatId"
            label={t("agent.attrs.vatId")}
            rules={[validations.length(12), validations.pattern(regexPatterns.vatIdRegex)]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="commercialRegisterInsertionNumber"
            label={t("agent.attrs.commercialRegisterInsertionNumber")}
            rules={[validations.size(1, 64)]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 2}>
          <Form.Item name="sectors" label={t("common.financialSectors")} rules={[validations.none]}>
            <Select
              {...selectStandardProps}
              mode="multiple"
              maxTagCount="responsive"
              options={Object.keys(FinancialSector).map(sector => ({
                value: sector,
                label: t("agent.enums.financialSector." + sector)
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.tipper !== next.tipper}>
            {({ getFieldValue }) => (
              <Form.Item
                name="nbsRegistrationNumber"
                label={t("agent.attrs.nbsRegistrationNumber")}
                rules={[validations.size(1, 32), validations.numeric]}
              >
                <Input disabled={getFieldValue("tipper")} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="tipper"
            valuePropName="checked"
            rules={[validations.none]}
            className="form-item-without-label"
            initialValue={false}
          >
            <Checkbox onChange={onTipperChange}>{t("agent.attrs.tipper")}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="email"
            label={t("agent.attrs.email")}
            rules={[validations.notBlank, validations.size(1, 254), validations.email]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="phone"
            label={t("agent.attrs.phone")}
            rules={[validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber]}
            normalize={phoneNumberNormalizeFunction}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="web"
            label={t("agent.attrs.web")}
            rules={[validations.size(1, 255), validations.pattern(regexPatterns.webPageRegex)]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 2}>
          <Form.Item
            name="bankAccountNumber"
            label={t("agent.attrs.bankAccountNumber")}
            rules={[validations.size(1, 34), validations.iban]}
            normalize={ibanNormalizeFunction}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      {agent && (
        <Row gutter={rowGutter}>
          <Col span={colSpan * 2}>
            <AgentSelect
              formItemProps={{
                name: "representativeIds",
                label: (
                  <LabelWithTooltip
                    label={t("agent.attrs.representativeIds")}
                    tooltip={t("agent.helpers.representativesInfo")}
                  />
                ),
                rules: [validations.none]
              }}
              selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
              optionsProps={{
                filter: agentOption =>
                  !!agent.accessTreePath &&
                  !!agentOption.accessTreePath &&
                  agentOption.accessTreePath.startsWith(agent.accessTreePath) &&
                  agentOption.id !== agent.id &&
                  agentOption.type === AgentType.NATURAL
              }}
            />
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter}>
        <Col span={colSpan * 4}>
          <Form.Item
            name="note"
            label={t("agent.attrs.note")}
            rules={[validations.size(1, 8192)]}
            initialValue={undefined}
          >
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default LegalAgentDataFormPart;
