import { generatePath, Link } from "react-router-dom";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { LinkTargetType } from "../../../../common/types";
import { CLIENT_ROUTE_PATHS } from "../../paths";
import { ClientBase } from "../../types";
import ClientTypeTag from "./ClientTypeTag";

interface Props {
  client?: ClientBase;
  ellipsis?: boolean;
  showLink?: boolean;
  showLinkColor?: boolean;
  target?: LinkTargetType;
}

const ClientTypeTagWithName = ({ client, ellipsis, showLink, showLinkColor, target }: Props) =>
  client ? (
    <div style={{ height: 32, alignItems: "center", display: "flex" }}>
      <ClientTypeTag type={client.type} />

      {showLink && client.id ? (
        <Ellipsis tooltip={client.aggregatedName} shouldRender={!!ellipsis}>
          <Link
            className={showLinkColor ? "" : "no-link-color"}
            to={generatePath(CLIENT_ROUTE_PATHS.detail.to, { id: client.id })}
            target={target}
          >
            {client.aggregatedName}
          </Link>
        </Ellipsis>
      ) : (
        <Ellipsis shouldRender={!!ellipsis}>{client.aggregatedName}</Ellipsis>
      )}
    </div>
  ) : null;

export default ClientTypeTagWithName;
