import { Card, Col, Empty, Row, Tooltip } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import logo from "../../../../../assets/images/logo-cliip-1000x-transparent.png";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { rowGutter } from "../../../../../common/constants";
import { RootState } from "../../../../../common/types";
import { formatAgentAggregatedName } from "../../../../../common/utils/formatUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { selectUser, selectUserAccount } from "../../../../auth/ducks";
import { selectBrandingPersistData } from "../../../../ducks";
import { AgentBrandingEnumeration } from "../../../../enumerations/types";
import { User, UserAccount } from "../../../../user/types";
import { requests } from "../../../api";
import { refreshDashboardAction, selectDashboardStatistics } from "../../../ducks";
import { DashboardStatistics } from "../../../types";
import styles from "./DashboardStatisticsWidget.module.scss";

export const DashboardStatisticsWidget = () => {
  const { t } = useTranslation();

  const user = useSelector<RootState, User | undefined>(selectUser);
  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);
  const branding = useSelector<RootState, AgentBrandingEnumeration | undefined>(selectBrandingPersistData);
  const statistics = useSelector<RootState, DashboardStatistics | undefined>(selectDashboardStatistics);

  const inProgress = useRequestFinishedCallback([requests.GET_DASHBOARD_STATISTICS]);

  const dispatch = useDispatch();
  const actions = useMemo(() => bindActionCreators({ onRefresh: refreshDashboardAction }, dispatch), [dispatch]);

  const resolveGreetingText = (): string => {
    const hourOfDay = dayjs().hour();
    if (hourOfDay >= 5 && hourOfDay <= 8) {
      return t("dashboard.statistics.greetingMorning");
    } else if (hourOfDay >= 6 && hourOfDay <= 18) {
      return t("dashboard.statistics.greetingDay");
    }
    return t("dashboard.statistics.greetingEvening");
  };

  return (
    <Card className="card-box" style={{ height: 170 }} size="small" loading={inProgress}>
      {statistics ? (
        <Row justify="space-between" gutter={rowGutter}>
          <Col>
            <h2 style={{ marginBottom: 0 }}>
              {resolveGreetingText()}, {user?.name}
            </h2>

            <div className="margin-bottom-tiny">
              <Ellipsis className="sub-header-info">
                {formatAgentAggregatedName(userAccount?.agent)}
                {userAccount?.representingAgent
                  ? ` | ${formatAgentAggregatedName(userAccount?.representingAgent)}`
                  : ""}
              </Ellipsis>
            </div>

            {statistics.clientsCount > 0 ? (
              <Row>
                <Col className={styles.dashboardStatisticsLabel}>{t("dashboard.statistics.clientsCount")}: </Col>
                <Col className="right-align nowrap">
                  <CountUp
                    className={styles.dashboardStatisticsValue}
                    duration={4}
                    separator={" "}
                    end={statistics.clientsCount}
                  />
                </Col>
              </Row>
            ) : undefined}

            {statistics.contractsCount > 0 ? (
              <Row>
                <Col className={styles.dashboardStatisticsLabel}>{t("dashboard.statistics.contractsCount")}: </Col>
                <Col className="right-align nowrap">
                  <CountUp
                    className={styles.dashboardStatisticsValue}
                    duration={4}
                    separator={" "}
                    end={statistics.contractsCount}
                  />
                </Col>
              </Row>
            ) : undefined}

            {statistics.agentsCount > 0 ? (
              <Row>
                <Col className={styles.dashboardStatisticsLabel}>{t("dashboard.statistics.agentsCount")}: </Col>
                <Col className="right-align nowrap">
                  <CountUp
                    className={styles.dashboardStatisticsValue}
                    duration={4}
                    separator={" "}
                    end={statistics.agentsCount}
                  />
                </Col>
              </Row>
            ) : undefined}
          </Col>

          <Col flex={1} className="right-align">
            <img
              className={styles.dashboardStatisticsLogo}
              src={branding?.logoResourcePath ? `/images/${branding.logoResourcePath}` : logo}
              alt="dashboard-logo"
            />
          </Col>

          <Col className="right-align">
            <Tooltip title={t("dashboard.statistics.actions.refresh")} placement="left">
              <span>
                <ActionTextIcon icon="reload" color="green" size="huge" onClick={() => actions.onRefresh("manual")} />
              </span>
            </Tooltip>
          </Col>
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  );
};
