import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { EntityIdObject, EntityObject, SearchPageRequest, SearchPageResult } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  CreateUpdateProduct,
  CreateUpdateProductGroup,
  Product,
  ProductFilterExportRequest,
  ProductFilterPageRequest,
  ProductFilterPageResult,
  ProductGroup
} from "./types";

const FILTER_PRODUCT_GROUPS = ApiRequestAdapter.create("/product-groups", "GET");
const CREATE_PRODUCT_GROUP = ApiRequestAdapter.create("/product-groups", "POST");
const UPDATE_PRODUCT_GROUP = ApiRequestAdapter.create("/product-groups/{0}", "PUT");
const DELETE_PRODUCT_GROUP = ApiRequestAdapter.create("/product-groups/{0}", "DELETE");
const FILTER_PRODUCTS = ApiRequestAdapter.create("/products/filter", "POST");
const DOWNLOAD_PRODUCTS_EXPORT = ApiRequestAdapter.create("/products/export", "POST");
const CREATE_PRODUCT = ApiRequestAdapter.create("/products", "POST");
const UPDATE_PRODUCT = ApiRequestAdapter.create("/products/{0}", "PUT");
const DELETE_PRODUCT = ApiRequestAdapter.create("/products/{0}", "DELETE");

export const requests = {
  FILTER_PRODUCT_GROUPS,
  CREATE_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  FILTER_PRODUCTS,
  DOWNLOAD_PRODUCTS_EXPORT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT
};

const api = {
  filterProductGroups: (request: SearchPageRequest): AxiosPromise<SearchPageResult<ProductGroup>> => {
    return apiService.get(FILTER_PRODUCT_GROUPS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createProductGroup: (request: CreateUpdateProductGroup): AxiosPromise<ProductGroup> => {
    return apiService.post(CREATE_PRODUCT_GROUP.url, request);
  },
  updateProductGroup: (request: EntityObject<CreateUpdateProductGroup>): AxiosPromise<ProductGroup> => {
    return apiService.put(UPDATE_PRODUCT_GROUP.getParametrizedUrl(request.id), request.object);
  },
  deleteProductGroup: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_PRODUCT_GROUP.getParametrizedUrl(request.id));
  },
  filterProducts: (request: ProductFilterPageRequest): AxiosPromise<ProductFilterPageResult> => {
    return apiService.post(FILTER_PRODUCTS.url, request);
  },
  downloadProductsExportActions: (request: ProductFilterExportRequest): AxiosPromise<Blob> => {
    return apiService.post(DOWNLOAD_PRODUCTS_EXPORT.url, request, { responseType: "blob" });
  },
  createProduct: (request: CreateUpdateProduct): AxiosPromise<Product> => {
    return apiService.post(CREATE_PRODUCT.url, request);
  },
  updateProduct: (request: EntityObject<CreateUpdateProduct>): AxiosPromise<Product> => {
    return apiService.put(UPDATE_PRODUCT.getParametrizedUrl(request.id), request.object);
  },
  deleteProduct: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_PRODUCT.getParametrizedUrl(request.id));
  }
};

export default api;
