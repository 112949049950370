import { Divider, Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import t from "../../../app/i18n";
import AntIcon from "../../components/icons/AntIcon";
import { cssVariables } from "../../utils/utils";
import { MarketingConsent } from "../enums";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<MarketingConsent>;
}

const MarketingConsentSelect = ({ formItemProps, selectProps }: Props) => (
  <Form.Item {...formItemProps}>
    <Select
      {...selectProps}
      options={[
        {
          value: MarketingConsent.GRANTED,
          label: (
            <>
              <AntIcon type="check-circle-two-tone" twoToneColor={cssVariables.colorGreen} />
              <Divider type="vertical" />
              {t("contact.enums.marketingConsent.GRANTED")}
            </>
          )
        },
        {
          value: MarketingConsent.NOT_GRANTED,
          label: (
            <>
              <AntIcon type="close-circle-two-tone" twoToneColor={cssVariables.colorRed} />
              <Divider type="vertical" />
              {t("contact.enums.marketingConsent.NOT_GRANTED")}
            </>
          )
        }
      ]}
    />
  </Form.Item>
);

export default MarketingConsentSelect;
