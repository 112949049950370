import { Col, Form, Input, Row } from "antd";
import ReactQuill from "react-quill-new";
import t from "../../../../../app/i18n";
import { rowGutter } from "../../../../../common/constants";
import { quillEditorStandardProps } from "../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import CommercialRegisterDistrictOfficeAutoComplete from "../../../../enumerations/components/form/CommercialRegisterDistrictOfficeAutoComplete";
import CommercialRegisterSectionAutoComplete from "../../../../enumerations/components/form/CommercialRegisterSectionAutoComplete";

interface Props {
  colSpan: number;
  disableCrnInput?: boolean;
  searchInProgress?: boolean;
  onCrnSearchOrChange?: (crn: string) => void;
}

const LegalClientDataFormPart = ({ colSpan, disableCrnInput, searchInProgress, onCrnSearchOrChange }: Props) => (
  <>
    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item
          name="companyRegistrationNumber"
          label={t("client.attrs.companyRegistrationNumber")}
          rules={[validations.notBlank, validations.crn]}
        >
          {onCrnSearchOrChange ? (
            <Input.Search
              disabled={disableCrnInput}
              loading={searchInProgress}
              onSearch={onCrnSearchOrChange}
              onChange={e => onCrnSearchOrChange?.(e.target.value)}
            />
          ) : (
            <Input disabled={disableCrnInput} />
          )}
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item
          name="companyName"
          label={t("client.attrs.companyName")}
          rules={[validations.notBlank, validations.size(1, 255)]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <CommercialRegisterDistrictOfficeAutoComplete
          formItemProps={{
            name: "commercialRegisterDistrictOffice",
            label: t("client.attrs.commercialRegisterDistrictOffice"),
            rules: [validations.size(1, 64)]
          }}
        />
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item name="taxId" label={t("client.attrs.taxId")} rules={[validations.length(10), validations.numeric]}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <CommercialRegisterSectionAutoComplete
          formItemProps={{
            name: "commercialRegisterSection",
            label: t("client.attrs.commercialRegisterSection"),
            rules: [validations.size(1, 64)]
          }}
        />
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item
          name="vatId"
          label={t("client.attrs.vatId")}
          rules={[validations.length(12), validations.pattern(regexPatterns.vatIdRegex)]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={colSpan}>
        <Form.Item
          name="commercialRegisterInsertionNumber"
          label={t("client.attrs.commercialRegisterInsertionNumber")}
          rules={[validations.size(1, 64)]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={rowGutter}>
      <Col span={colSpan * 4}>
        <Form.Item
          name="note"
          label={t("client.attrs.note")}
          rules={[validations.size(1, 8192)]}
          initialValue={undefined}
        >
          <ReactQuill {...quillEditorStandardProps} />
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default LegalClientDataFormPart;
