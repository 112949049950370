import { Divider, Tabs, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../../../../common/components/buttons/ActionButton";
import { deleteCalcSettingsAttachmentConfActions, uploadCalcSettingsAttachmentConfsActions } from "../../../ducks";
import { AttachmentConfType } from "../../../enums";
import { AttachmentConf, CalcSettings } from "../../../types";
import { AttachmentsConfList } from "./AttachmentsConfList";

interface Props {
  calcSettings: CalcSettings;
  onUploadAttachmentConf: typeof uploadCalcSettingsAttachmentConfsActions.request;
  onDeleteAttachmentConf: typeof deleteCalcSettingsAttachmentConfActions.request;
}

export type CustomAttachmentConf = AttachmentConf & { key: number };

const CalcSettingsAttachmentConfsPart = ({ calcSettings, ...props }: Props) => {
  const { t } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState<AttachmentConfType>(AttachmentConfType.PROVIDED);

  const handleAttachmentConfUpload = (fileList: RcFile[], type: AttachmentConfType): boolean => {
    if (fileList.length > 0) {
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append("types", type);
        formData.append("files", file);
      });
      props.onUploadAttachmentConf({ object: formData, id: calcSettings.id });

      fileList.splice(0, fileList.length);
    }

    return false;
  };

  const customAttachments = calcSettings.attachmentConfs.map<CustomAttachmentConf>((attachmentConf, index) => ({
    ...attachmentConf,
    key: index
  }));

  const providedAttachments = customAttachments.filter(
    attachmentConf => attachmentConf.type === AttachmentConfType.PROVIDED
  );

  const staticAttachments = customAttachments.filter(
    attachmentConf => attachmentConf.type === AttachmentConfType.STATIC
  );

  const dynamicAttachments = customAttachments.filter(
    attachmentConf => attachmentConf.type === AttachmentConfType.DYNAMIC
  );

  return (
    <>
      <Divider orientation="left">{t("calc.settings.sections.attachments")}</Divider>

      <Tabs
        activeKey={activeTabKey}
        onChange={(key: string) => setActiveTabKey(key as AttachmentConfType)}
        tabBarExtraContent={
          activeTabKey !== AttachmentConfType.PROVIDED ? (
            <Upload
              showUploadList={false}
              multiple
              beforeUpload={(_, fileList: RcFile[]) => handleAttachmentConfUpload(fileList, activeTabKey)}
            >
              <ActionButton icon="plus" variant="outlined">
                {t("calc.settings.actions.addAttachmentConf")}
              </ActionButton>
            </Upload>
          ) : undefined
        }
        items={[
          {
            key: AttachmentConfType.PROVIDED,
            forceRender: true,
            label: `${t("calc.settings.enums.attachmentConfType." + AttachmentConfType.PROVIDED)} (${providedAttachments?.length ?? 0})`,
            children: (
              <AttachmentsConfList
                attachments={providedAttachments}
                onDeleteAttachmentConf={props.onDeleteAttachmentConf}
                calcSettingsId={calcSettings.id}
              />
            )
          },
          {
            key: AttachmentConfType.STATIC,
            forceRender: true,
            label: `${t("calc.settings.enums.attachmentConfType." + AttachmentConfType.STATIC)} (${staticAttachments?.length ?? 0})`,
            children: (
              <AttachmentsConfList
                attachments={staticAttachments}
                onDeleteAttachmentConf={props.onDeleteAttachmentConf}
                calcSettingsId={calcSettings.id}
              />
            )
          },
          {
            key: AttachmentConfType.DYNAMIC,
            forceRender: true,
            label: `${t("calc.settings.enums.attachmentConfType." + AttachmentConfType.DYNAMIC)} (${dynamicAttachments?.length ?? 0})`,
            children: (
              <AttachmentsConfList
                attachments={dynamicAttachments}
                onDeleteAttachmentConf={props.onDeleteAttachmentConf}
                calcSettingsId={calcSettings.id}
              />
            )
          }
        ]}
      />
    </>
  );
};

export default CalcSettingsAttachmentConfsPart;
