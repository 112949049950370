import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import { RootState } from "../../../../common/types";
import { formatAgentName } from "../../../../common/utils/formatUtils";
import type { UUID } from "../../../../typings/global";
import { selectAllAgentsEnums } from "../../ducks";
import { AgentEnumeration } from "../../types";

interface Props {
  id: UUID;
}

const AgentEnumName = ({ id }: Props) => {
  const agentsEnums = useSelector<RootState, AgentEnumeration[]>(selectAllAgentsEnums);

  const agent = agentsEnums.find(agent => agent.id === id);
  return agent ? <span>{formatAgentName(agent)}</span> : <span>{t("agent.helpers.unknownAgent")}</span>;
};

export default AgentEnumName;
