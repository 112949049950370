import { Tag } from "antd";
import t from "../../../app/i18n";

interface Props {
  canBeGainer?: boolean;
  style?: React.CSSProperties;
}

const AgentCanBeGainerTag = ({ canBeGainer, style }: Props) =>
  canBeGainer ? (
    <Tag color="green" style={style}>
      {t("agent.helpers.canBeGainer")}
    </Tag>
  ) : (
    <Tag color="red" style={style}>
      {t("agent.helpers.canNotBeGainer")}
    </Tag>
  );

export default AgentCanBeGainerTag;
