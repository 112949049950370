import { Tag } from "antd";
import t from "../../../app/i18n";

interface Props {
  agentDeactivated?: boolean;
  style?: React.CSSProperties;
}

const AgentActiveTag = ({ agentDeactivated, style }: Props) =>
  agentDeactivated ? (
    <Tag color="red" style={style}>
      {t("agent.helpers.deactivatedAgent")}
    </Tag>
  ) : (
    <Tag color="green" style={style}>
      {t("agent.helpers.activeAgent")}
    </Tag>
  );

export default AgentActiveTag;
