import { Col, Row } from "antd";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { ContractDetailViewProps, SecondPillarContract } from "../../../../types";
import ContractAgentsSection from "../ContractAgentsSection";
import ContractAttachmentsSection from "../ContractAttachmentsSection";
import ContractHeaderSection from "../ContractHeaderSection";
import SecondPillarContractDataSection from "./sections/SecondPillarContractDataSection";

const SecondPillarContractDetailView = ({ contract, ...props }: ContractDetailViewProps<SecondPillarContract>) => (
  <Row className="margin-top-small">
    <Col span={24}>
      <ContractHeaderSection
        contract={contract}
        updatePermission={Permission.SECOND_PILLAR_UPDATE}
        verificationPermission={Permission.SECOND_PILLAR_VERIFICATION_STATUS_CHANGE}
        deletePermission={Permission.SECOND_PILLAR_DELETE}
        onVerificationStatusChange={props.onVerificationStatusChange}
        onExternalViewGet={props.onExternalViewGet}
        onDelete={props.onDelete}
        onUpdateClick={props.onUpdateClick}
      />

      <SecondPillarContractDataSection contract={contract} />

      <ContractAgentsSection contract={contract} />

      <ContractAttachmentsSection
        contract={contract}
        onDownloadAsZip={props.onDownloadAsZip}
        onUpload={props.onAttachmentsUpload}
        onDelete={props.onAttachmentDelete}
      />
    </Col>
  </Row>
);

export default SecondPillarContractDetailView;
