import t from "../../../../../../../app/i18n";
import { formatLocaleCurrency, formatLocaleDate } from "../../../../../../../common/utils/formatUtils";
import { InsuranceContract, TravelInsurance } from "../../../../../types";

interface Props {
  contract: InsuranceContract;
  index: number;
  multipleInsurances: boolean;
}

const TravelInsuranceView = ({ contract, index, multipleInsurances }: Props) => {
  const { insuranceData, ...insurance } = contract.insurances[index] as TravelInsurance;

  return (
    <>
      <table className="data-table-view">
        <tbody>
          {multipleInsurances && (
            <>
              <tr>
                <td>{t("contract.attrs.insurances.contractEntryDate")}:</td>
                <td>{formatLocaleDate(insurance.contractEntryDate)}</td>
                <td>{t("contract.attrs.insurances.annualPremium")}:</td>
                <td className="right-align">{formatLocaleCurrency(insurance.annualPremium)}</td>
                <td colSpan={2} />
              </tr>
              <tr>
                <td>{t("contract.attrs.insurances.contractWithdrawalDate")}:</td>
                <td>{formatLocaleDate(insurance.contractWithdrawalDate)}</td>
                <td>{t("contract.attrs.insurances.partialPremium")}:</td>
                <td className="right-align">{formatLocaleCurrency(insurance.partialPremium)}</td>
                <td colSpan={2} />
              </tr>
            </>
          )}
          <tr>
            <td>{t("calc.travel.enums.territorialValidity._label")}:</td>
            <td>{t("calc.travel.enums.territorialValidity." + insuranceData.territorialValidity)}</td>
            <td>{t("calc.travel.enums.riskGroup._label")}:</td>
            <td>{insuranceData.riskGroup ? t("calc.travel.enums.riskGroup." + insuranceData.riskGroup) : undefined}</td>
            <td colSpan={2} />
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.insuredClients._label")}:</td>
            <td colSpan={5}>
              {insuranceData.insuredClients
                .map(client => `${client.firstName} ${client.lastName} (${formatLocaleDate(client.birthDate)})`)
                .join(", ")}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default TravelInsuranceView;
