export enum FinancialMediationVersion {
  VERSION_1 = "VERSION_1",
  VERSION_2 = "VERSION_2",
  VERSION_3 = "VERSION_3",
  VERSION_4 = "VERSION_4",
  VERSION_5 = "VERSION_5"
}

export enum FinancialMediationStatus {
  UNFINISHED = "UNFINISHED",
  FINISHED = "FINISHED",
  ASSIGNED_TO_CONTRACT = "ASSIGNED_TO_CONTRACT",
  ASSIGNED_TO_CLIENT = "ASSIGNED_TO_CLIENT"
}

export enum FinancialReserveLiquidity {
  IMMEDIATELY = "IMMEDIATELY",
  WITHIN_1_MONTH = "WITHIN_1_MONTH",
  WITHIN_1_YEAR = "WITHIN_1_YEAR",
  OVER_1_YEAR = "OVER_1_YEAR",
  NONE = "NONE"
}

export enum ClientFinancialServiceExperience {
  NONE = "NONE",
  LESS_THAN_1_YEAR = "LESS_THAN_1_YEAR",
  BETWEEN_1_TO_3_YEARS = "BETWEEN_1_TO_3_YEARS",
  MORE_THAN_3_YEARS = "MORE_THAN_3_YEARS"
}

export enum NonLifeInsuranceRequirement {
  MTPL = "MTPL",
  CRASH = "CRASH",
  GAP = "GAP",
  REALTY = "REALTY",
  HOUSEHOLD = "HOUSEHOLD",
  REALTY_AND_HOUSEHOLD_LIABILITY = "REALTY_AND_HOUSEHOLD_LIABILITY",
  BUSINESS = "BUSINESS",
  BUSINESS_LIABILITY = "BUSINESS_LIABILITY",
  BUSINESS_OTHER = "BUSINESS_OTHER",
  TRAVEL = "TRAVEL",
  EMPLOYEE_LIABILITY = "EMPLOYEE_LIABILITY",
  OTHER = "OTHER"
}

export enum LifeInsuranceRequirement {
  PROTECTION_OF_ME = "PROTECTION_OF_ME",
  PROTECTION_OF_ME_AND_FAMILY = "PROTECTION_OF_ME_AND_FAMILY",
  CHILDREN_INSURANCE = "CHILDREN_INSURANCE",
  LOAN_INSURANCE = "LOAN_INSURANCE",
  SAVINGS_WITH_GUARANTEED_RETURN = "SAVINGS_WITH_GUARANTEED_RETURN",
  SAVINGS_WITH_POTENTIALLY_HIGHER_RETURN = "SAVINGS_WITH_POTENTIALLY_HIGHER_RETURN",
  OTHER = "OTHER"
}

export enum LifeInsuranceSpecificRequirement {
  RISK_INSURANCE = "RISK_INSURANCE",
  RESERVE_INSURANCE = "RESERVE_INSURANCE",
  GUARANTEED_RETURN = "GUARANTEED_RETURN",
  POTENTIALLY_HIGHER_RETURN = "POTENTIALLY_HIGHER_RETURN"
}

export enum LifeInsuranceRiskRequirement {
  DEATH_FROM_ANY_CAUSE = "DEATH_FROM_ANY_CAUSE",
  INJURY_DEATH = "INJURY_DEATH",
  PERMANENT_CONSEQUENCES_EXEMPTION_FROM_PAYMENT = "PERMANENT_CONSEQUENCES_EXEMPTION_FROM_PAYMENT",
  PERMANENT_CONSEQUENCES_ONE_TIME_PAYMENT = "PERMANENT_CONSEQUENCES_ONE_TIME_PAYMENT",
  PERMANENT_CONSEQUENCES_PENSION = "PERMANENT_CONSEQUENCES_PENSION",
  DISABILITY_EXEMPTION_FROM_PAYMENT = "DISABILITY_EXEMPTION_FROM_PAYMENT",
  DISABILITY_ONE_TIME_PAYMENT = "DISABILITY_ONE_TIME_PAYMENT",
  DISABILITY_PENSION = "DISABILITY_PENSION",
  SERIOUS_ILLNESS_DIAGNOSIS = "SERIOUS_ILLNESS_DIAGNOSIS",
  LESS_SERIOUS_INJURY_DAILY_COMPENSATION = "LESS_SERIOUS_INJURY_DAILY_COMPENSATION",
  LESS_SERIOUS_INJURY_PERCENTAGE_OF_THE_INSURANCE_AMOUNT = "LESS_SERIOUS_INJURY_PERCENTAGE_OF_THE_INSURANCE_AMOUNT"
}

export enum DepositRequirement {
  ACCOUNT = "ACCOUNT",
  REGULAR_SAVING = "REGULAR_SAVING",
  FINANCING_OF_NEEDS = "FINANCING_OF_NEEDS",
  INVESTING = "INVESTING",
  GAIN_STATE_BENEFITS = "GAIN_STATE_BENEFITS",
  SAVINGS_FOR_CHILDREN = "SAVINGS_FOR_CHILDREN",
  OTHER = "OTHER"
}

export enum PillarsRequirement {
  CAPITAL_FORMATION = "CAPITAL_FORMATION",
  VOLUNTARY_CONTRIBUTION = "VOLUNTARY_CONTRIBUTION",
  PENSION_ACCOUNT_SAVINGS = "PENSION_ACCOUNT_SAVINGS",
  TAX_BENEFITS = "TAX_BENEFITS",
  EMPLOYER_CONTRIBUTION = "EMPLOYER_CONTRIBUTION",
  EMPLOYEE_CONTRIBUTION_OBLIGATION = "EMPLOYEE_CONTRIBUTION_OBLIGATION",
  BENEFIT_FOR_EMPLOYEES = "BENEFIT_FOR_EMPLOYEES",
  GUARANTEED_RETURN = "GUARANTEED_RETURN",
  POTENTIALLY_HIGHER_RETURN = "POTENTIALLY_HIGHER_RETURN",
  OTHER = "OTHER"
}

export enum InvestmentQuestionnaireClientStatement {
  WILLING_TO_ANSWER = "WILLING_TO_ANSWER",
  REFUSE_TO_ANSWER = "REFUSE_TO_ANSWER",
  ALREADY_ANSWERED = "ALREADY_ANSWERED"
}

export enum InvestmentQuestionnaireInvestorType {
  INEXPERIENCED = "INEXPERIENCED",
  INFORMED = "INFORMED",
  ADVANCED = "ADVANCED"
}

export enum InvestmentQuestionnaireStrategyResult {
  NOT_SUITABLE = "NOT_SUITABLE",
  CONSERVATIVE = "CONSERVATIVE",
  BALANCED = "BALANCED",
  DYNAMIC = "DYNAMIC"
}

export enum InvestmentQuestionnaireRisk {
  PREVIOUS_RETURN_GUARANTEE = "PREVIOUS_RETURN_GUARANTEE",
  SUPPLEMENTARY_PENSION_SAVINGS_CANCELLATION = "SUPPLEMENTARY_PENSION_SAVINGS_CANCELLATION",
  HIGHER_PRODUCT_RETURN = "HIGHER_PRODUCT_RETURN",
  GUARANTEED_RETURN_CONSTRAINT = "GUARANTEED_RETURN_CONSTRAINT"
}

export enum LoanPurpose {
  PURCHASE_REALTY = "PURCHASE_REALTY",
  REFINANCE_LOAN = "REFINANCE_LOAN",
  PURCHASE_CAR = "PURCHASE_CAR",
  EQUIP_HOUSEHOLD = "EQUIP_HOUSEHOLD",
  OTHER = "OTHER"
}

export enum AssetEvaluation {
  CONSERVATIVE = "CONSERVATIVE",
  HIGHER = "HIGHER",
  COMBINED = "COMBINED"
}

export enum FinancialMediationGeneralAttachmentType {
  FINANCIAL_MEDIATION = "FINANCIAL_MEDIATION",
  AML_FORM = "AML_FORM",
  INVESTMENT_QUESTIONNAIRE = "INVESTMENT_QUESTIONNAIRE"
}

export enum FinancialMediationSectorAttachmentType {
  CONTRACT = "CONTRACT",
  GENERAL_CONDITIONS = "GENERAL_CONDITIONS",
  FEE_TARIFFS = "FEE_TARIFFS",
  IPID = "IPID",
  IMPORTANT_CONTRACT_CONDITIONS = "IMPORTANT_CONTRACT_CONDITIONS",
  INSURANCE_PARTS_FORM = "INSURANCE_PARTS_FORM",
  HEALTH_QUESTIONNAIRE = "HEALTH_QUESTIONNAIRE",
  ANNUAL_INTEREST_RATE_AMOUNT = "ANNUAL_INTEREST_RATE_AMOUNT",
  STATUTE = "STATUTE",
  KEY_INFORMATION = "KEY_INFORMATION",
  BATCH_PLAN = "BATCH_PLAN"
}

export enum MediationClientFormType {
  POLICY_HOLDER = "POLICY_HOLDER",
  SECONDARY_CLIENT = "SECONDARY_CLIENT",
  POLICY_HOLDER_REPRESENTATIVE = "POLICY_HOLDER_REPRESENTATIVE",
  SECONDARY_CLIENT_REPRESENTATIVE = "SECONDARY_CLIENT_REPRESENTATIVE",
  CHILD_1 = "CHILD_1",
  CHILD_2 = "CHILD_2",
  CHILD_3 = "CHILD_3",
  CHILD_4 = "CHILD_4"
}
