import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import { RootState } from "../../../../common/types";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { CertificateVehicleColor } from "../../../vehicle/color/types";
import { selectVehicleColorsEnums } from "../../ducks";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
}

const VehicleColorSelect = ({ formItemProps, selectProps }: Props) => {
  const vehicleColorsEnums = useSelector<RootState, CertificateVehicleColor[]>(selectVehicleColorsEnums);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps}
        {...selectProps}
        options={vehicleColorsEnums.map(color => ({ value: color.id, label: color.value }))}
      />
    </Form.Item>
  );
};

export default VehicleColorSelect;
