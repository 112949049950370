import { message, notification } from "antd";
import { MessageType } from "antd/es/message/interface";
import { NotificationPlacement } from "antd/es/notification/interface";
import { ReactNode } from "react";
import t from "../../app/i18n";

const defaultMessageDuration: number = 5;
const defaultNotificationPlacement: NotificationPlacement = "topRight";

const infoMessage = (content: string, duration: number = defaultMessageDuration): MessageType =>
  message.info(content, duration);
const loadingMessage = (content: string, duration: number = defaultMessageDuration): MessageType =>
  message.loading(content, duration);
const successMessage = (content: string, duration: number = defaultMessageDuration): MessageType =>
  message.success(content, duration);
const warnMessage = (content: string, duration: number = defaultMessageDuration): MessageType =>
  message.warning(content, duration);
const errorMessage = (content: string, duration: number = defaultMessageDuration): MessageType =>
  message.error(content, duration);

type NotificationProps = {
  message: string;
  description: string | ReactNode;
  duration?: number;
  placement?: NotificationPlacement;
  top?: number;
  key?: string;
};

const infoNotification = ({
  message,
  description,
  duration = defaultMessageDuration,
  placement = defaultNotificationPlacement,
  key
}: NotificationProps): void => notification.info({ message, description, duration, placement, key });

const successNotification = ({
  message,
  description,
  duration = defaultMessageDuration,
  placement = defaultNotificationPlacement,
  key
}: NotificationProps): void => notification.success({ message, description, duration, placement, key });

const warnNotification = ({
  message,
  description,
  duration = defaultMessageDuration,
  placement = defaultNotificationPlacement,
  key
}: NotificationProps): void => notification.warning({ message, description, duration, placement, key });

const errorNotification = ({
  message,
  description,
  duration = defaultMessageDuration,
  placement = defaultNotificationPlacement,
  key
}: NotificationProps): void => notification.error({ message, description, duration, placement, key });

const itemCreatedNotification = (): void =>
  successNotification({ message: t("common.operationSuccess"), description: t("common.createOperationSuccess") });

const itemUpdatedNotification = (): void =>
  successNotification({ message: t("common.operationSuccess"), description: t("common.updateOperationSuccess") });

const itemDeletedNotification = (): void =>
  successNotification({ message: t("common.operationSuccess"), description: t("common.deleteOperationSuccess") });

const messageUtils = {
  infoMessage,
  loadingMessage,
  successMessage,
  warnMessage,
  errorMessage,
  infoNotification,
  successNotification,
  warnNotification,
  errorNotification,
  itemCreatedNotification,
  itemUpdatedNotification,
  itemDeletedNotification
};

export default messageUtils;
