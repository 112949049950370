import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import TableActionsView from "../../../../../common/components/views/TableActionsView";
import { TableSizes } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import {
  formatAgentName,
  formatLocaleCurrencyWithNullAsZero,
  formatLocaleDateTime
} from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../auth/ducks";
import { BailAccountMovement } from "../../../bailaccount/types";
import {
  associateBailAccountMovementToBatchActions,
  disassociateBailAccountMovementFromBatchActions
} from "../../ducks";
import { CommissionsBatchStep, ManualBailAccountMovementsReport } from "../../enums";
import { ManualBailAccountMovementsFilterPageResult } from "../../types";

interface Props {
  movementsPage: ManualBailAccountMovementsFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onAssociateToBatch: typeof associateBailAccountMovementToBatchActions.request;
  onDisassociateFromBatch: typeof disassociateBailAccountMovementFromBatchActions.request;
}

const BatchManualBailAccountMovementsTableView = ({
  movementsPage,
  onPageChange,
  onAssociateToBatch,
  onDisassociateFromBatch
}: Props) => {
  const hasManageCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.COMMISSIONS_MANAGE)(state)
  );

  const columns: ColumnsType<BailAccountMovement> = [
    {
      key: "agent",
      title: t("commissions.bailAccount.attrs.movements.agent"),
      width: 220,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatAgentName(record.agent)}</Ellipsis>
    },
    {
      key: "createdAt",
      title: t("common.createdAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "note",
      title: t("commissions.bailAccount.attrs.movements.note"),
      width: 300,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.note}</Ellipsis>
    },
    {
      key: "amount",
      title: t("common.amount"),
      align: "right",
      width: 130,
      render: (_, record) => formatLocaleCurrencyWithNullAsZero(record.amount)
    }
  ];

  if (movementsPage.batch?.step === CommissionsBatchStep.RECAPITULATION && hasManageCommissionsPermission) {
    columns.push({
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_, record) => {
        if (movementsPage.report === ManualBailAccountMovementsReport.ASSOCIATED) {
          return (
            <TableActionsView
              actions={[
                {
                  color: "orange",
                  text: t("common.exclude"),
                  icon: "branch",
                  disabled: movementsPage.batch?.stepChangeInProgress,
                  onClick: () =>
                    movementsPage.batch
                      ? onDisassociateFromBatch({
                          id1: movementsPage.batch.id,
                          id2: record.id,
                          object: { optimisticLockVersion: record.optimisticLockVersion }
                        })
                      : undefined
                }
              ]}
            />
          );
        }
        if (movementsPage.report === ManualBailAccountMovementsReport.DISASSOCIATED) {
          return (
            <TableActionsView
              actions={[
                {
                  color: "green",
                  text: t("common.include"),
                  icon: "pull-request",
                  disabled: movementsPage.batch?.stepChangeInProgress,
                  onClick: () =>
                    movementsPage.batch
                      ? onAssociateToBatch({
                          id1: movementsPage.batch.id,
                          id2: record.id,
                          object: { optimisticLockVersion: record.optimisticLockVersion }
                        })
                      : undefined
                }
              ]}
            />
          );
        }
        return null;
      }
    });
  }

  return (
    <Table<BailAccountMovement>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.MEDIUM }}
      dataSource={movementsPage.pageData}
      pagination={{
        ...paginationTableProps,
        position: ["bottomRight"],
        current: movementsPage.pageIndex + 1,
        pageSize: movementsPage.pageSize,
        total: movementsPage.totalElementsCount,
        onChange: onPageChange
      }}
    />
  );
};

export default BatchManualBailAccountMovementsTableView;
