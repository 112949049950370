import { Modal, Result } from "antd";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { RootState } from "../../../../common/types";
import { selectIsRequestInProgress } from "../../../ducks";
import { requests } from "../api";

const CalcLoadingModal = () => {
  const calcRequestInProgress = useSelector<RootState, boolean>(state =>
    selectIsRequestInProgress(state, requests.CALCULATE)
  );
  const genRequestInProgress = useSelector<RootState, boolean>(state =>
    selectIsRequestInProgress(state, requests.GENERATE)
  );

  return (
    <Modal
      open={calcRequestInProgress || genRequestInProgress}
      title={
        calcRequestInProgress
          ? t("calc.helpers.calcRequestInProgress")
          : genRequestInProgress
            ? t("calc.helpers.genRequestInProgress")
            : null
      }
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <Result status="info" icon={<AntIcon type="loading" />} title={t("calc.helpers.requestInProgressInfo")} />
    </Modal>
  );
};

export default CalcLoadingModal;
