import { Route, Routes } from "react-router-dom";
import { WILDCARD_ROUTE } from "../../../common/constants";
import NotFound from "../../../common/pages/NotFound/NotFound";
import { Permission } from "../../../common/security/authorization/enums";
import PageWithPermission from "../../../common/security/authorization/PageWithPermission";
import CalcSettingsDetailContainer from "../settings/containers/CalcSettingsDetailContainer";
import CalcSettingsCreateContainer from "./containers/CalcSettingsCreateContainer";
import CalcSettingsListContainer from "./containers/CalcSettingsListContainer";
import { ADMIN_CALC_ROUTE_PATHS } from "./paths";

const routes = () => (
  <Routes>
    <Route
      index
      element={
        <PageWithPermission
          component={<CalcSettingsListContainer />}
          permissions={[
            Permission.ADMIN_MTPL_CALCULATORS,
            Permission.ADMIN_CRASH_CALCULATORS,
            Permission.ADMIN_REALTY_CALCULATORS,
            Permission.ADMIN_TRAVEL_CALCULATORS
          ]}
          anyPermission
        />
      }
    />

    <Route
      path={ADMIN_CALC_ROUTE_PATHS.create.path}
      element={
        <PageWithPermission
          component={<CalcSettingsCreateContainer />}
          permissions={[
            Permission.ADMIN_MTPL_CALCULATORS,
            Permission.ADMIN_CRASH_CALCULATORS,
            Permission.ADMIN_REALTY_CALCULATORS,
            Permission.ADMIN_TRAVEL_CALCULATORS
          ]}
          anyPermission
        />
      }
    />

    <Route
      path={ADMIN_CALC_ROUTE_PATHS.detail.path}
      element={
        <PageWithPermission
          component={<CalcSettingsDetailContainer />}
          permissions={[
            Permission.ADMIN_MTPL_CALCULATORS,
            Permission.ADMIN_CRASH_CALCULATORS,
            Permission.ADMIN_REALTY_CALCULATORS,
            Permission.ADMIN_TRAVEL_CALCULATORS
          ]}
          anyPermission
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
