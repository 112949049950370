import { Col, Form, Input, Modal, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { createVehicleColorActions, updateVehicleColorActions } from "../../ducks";
import { CertificateVehicleColor, CreateUpdateCertificateVehicleColor } from "../../types";

interface Props {
  color?: CertificateVehicleColor;
  open: boolean;
  onCreate?: typeof createVehicleColorActions.request;
  onUpdate?: typeof updateVehicleColorActions.request;
  onFormCancel: () => void;
}

const VehicleColorForm = ({ color, open, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateCertificateVehicleColor>();

  useFormErrorHandler(form, "vehicleColor.attrs", [requests.CREATE_VEHICLE_COLOR, requests.UPDATE_VEHICLE_COLOR]);

  useEffect(() => {
    if (open && color) {
      form.setFieldsValue({ ...color });
    }
  }, [color, open, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_VEHICLE_COLOR, requests.UPDATE_VEHICLE_COLOR],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (color) {
          onUpdate?.({ id: color.id, object: values });
        } else {
          onCreate?.(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={color ? t("vehicleColor.titles.updateColor") : t("vehicleColor.titles.createColor")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={color} />

      <Form form={form} layout="vertical" name="vehicleColorForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item
              name="value"
              label={t("vehicleColor.attrs.value")}
              rules={[
                validations.notNull,
                validations.size(1, 255),
                validations.pattern(regexPatterns.vehicleColorValueRegex)
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="certValue"
              label={t("vehicleColor.attrs.certValue")}
              rules={[
                validations.notNull,
                validations.size(1, 255),
                validations.pattern(regexPatterns.vehicleColorCertValueRegex)
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="komunalnaValue"
              label={t("vehicleColor.attrs.komunalnaValue")}
              rules={[validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="kooperativaValue"
              label={t("vehicleColor.attrs.kooperativaValue")}
              rules={[validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="uniqaValue" label={t("vehicleColor.attrs.uniqaValue")} rules={[validations.size(1, 255)]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default VehicleColorForm;
