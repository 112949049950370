import { Form, TreeSelect, TreeSelectProps } from "antd";
import { FormItemProps } from "antd/lib/form";
import { DataNode } from "rc-tree-select/lib/interface";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../common/types";
import { formatProductName } from "../../../../common/utils/formatUtils";
import { treeNodeFilterFunction } from "../../../../common/utils/formUtils";
import type { UUID } from "../../../../typings/global";
import { selectProductGroupsEnums } from "../../ducks";
import { ProductGroupWithProducts } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: TreeSelectProps<UUID>;
}

const ProductTreeSelect = ({ formItemProps, selectProps }: Props) => {
  const productGroupsEnums = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroupsEnums);

  const productsTree = useMemo(
    () =>
      productGroupsEnums.map<DataNode>(productGroup => ({
        value: productGroup.id,
        title: productGroup.name,
        children: productGroup.products.map<DataNode>(product => ({
          value: product.id,
          title: formatProductName(product)
        }))
      })),
    [productGroupsEnums]
  );

  return (
    <Form.Item {...formItemProps}>
      <TreeSelect
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        treeCheckable
        maxTagCount="responsive"
        popupMatchSelectWidth={false}
        showSearch
        allowClear
        filterTreeNode={treeNodeFilterFunction}
        treeData={productsTree}
        {...selectProps}
      />
    </Form.Item>
  );
};

export default ProductTreeSelect;
