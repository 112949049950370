import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { BailAccountAction, bailAccountReducer, bailAccountSaga } from "./bailaccount/ducks";
import { CommissionBatchAction, commissionsBatchReducer, commissionsBatchSaga } from "./batch/ducks";
import { DebtAccountAction, debtAccountReducer, debtAccountSaga } from "./debtaccount/ducks";
import { commissionsLevelSaga, CommissionsSettingsLevelAction, commissionsSettingsLevelReducer } from "./level/ducks";
import { CommissionsOutputAction, commissionsOutputSaga, commissionsOutputsReducer } from "./outputs/ducks";
import { PointsAction, pointsReducer, pointsSaga } from "./points/ducks";
import { PostponedCommissionAction, postponedCommissionReducer, postponedCommissionSaga } from "./postponed/ducks";
import { CommissionSettingsAction, commissionsSettingsReducer, commissionsSettingsSaga } from "./settings/ducks";
import { SpecialCommissionsSettingsAction, specialCommissionsSettingsSaga } from "./special/ducks";
import { CommissionsReducerState } from "./types";

/**
 * ACTIONS
 */
export type CommissionAction =
  | BailAccountAction
  | CommissionBatchAction
  | CommissionSettingsAction
  | CommissionsOutputAction
  | CommissionsSettingsLevelAction
  | DebtAccountAction
  | PointsAction
  | PostponedCommissionAction
  | SpecialCommissionsSettingsAction;

/**
 * REDUCERS
 */
export const commissionsReducer = combineReducers<CommissionsReducerState>({
  bailAccount: bailAccountReducer,
  batch: commissionsBatchReducer,
  debtAccount: debtAccountReducer,
  level: commissionsSettingsLevelReducer,
  points: pointsReducer,
  output: commissionsOutputsReducer,
  postponedCommission: postponedCommissionReducer,
  settings: commissionsSettingsReducer
});

/**
 * SAGAS
 */
export function* commissionsSaga() {
  yield all([
    bailAccountSaga(),
    commissionsBatchSaga(),
    commissionsLevelSaga(),
    commissionsOutputSaga(),
    commissionsSettingsSaga(),
    debtAccountSaga(),
    pointsSaga(),
    postponedCommissionSaga(),
    specialCommissionsSettingsSaga()
  ]);
}
