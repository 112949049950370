import { Avatar, Card } from "antd";
import Meta from "antd/lib/card/Meta";
import UserOutlined from "../../../../../../assets/images/UserOutlined.svg";
import Ellipsis from "../../../../../../common/components/views/Ellipsis";
import { resolveInstitutionLogo } from "../../../../../institution/utils";
import { deleteDashboardContactActions } from "../../../../ducks";
import { DashboardContact, ExternalDashboardContact } from "../../../../types";
import { DashboardContactActionsView } from "./DashboardContactActionsView";
import { DashboardContactFooterView } from "./DashboardContactFooterView";

interface Props {
  contact: ExternalDashboardContact;
  showActions: boolean;
  onUpdate: (contact: DashboardContact) => void;
  onDelete: typeof deleteDashboardContactActions.request;
}

export const ExternalDashboardContactView = ({ contact, showActions, onUpdate, onDelete }: Props) => (
  <Card
    key={contact.id}
    styles={{ body: { padding: "14px 10px" } }}
    actions={[<DashboardContactFooterView email={contact.email} phone={contact.phone} />]}
  >
    <Meta
      avatar={
        <Avatar
          src={
            contact.institution ? (
              <img
                style={{ objectFit: "contain" }}
                src={resolveInstitutionLogo(contact.institution)}
                alt="Institution logo"
              />
            ) : (
              UserOutlined
            )
          }
        />
      }
      title={<Ellipsis style={{ fontSize: 16 }}>{contact.name}</Ellipsis>}
      description={
        <Ellipsis>
          {contact.institution ? `${contact.institution.name} | ${contact.position}` : contact.position}
        </Ellipsis>
      }
    />

    {showActions && (
      <DashboardContactActionsView contact={contact as DashboardContact} onUpdate={onUpdate} onDelete={onDelete} />
    )}
  </Card>
);
