import { Card, Col, Input, Row, Space, Tree } from "antd";
import { DataNode } from "rc-tree/lib/interface";
import { Key, useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import { accessTreePathDelimiter } from "../../../../../common/constants";
import { formatAgentIdNumber } from "../../../../../common/utils/formatUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { tInterval } from "../../../../../common/utils/translationUtils";
import { isNotEmptyArray, stripAccents } from "../../../../../common/utils/utils";
import type { UUID } from "../../../../../typings/global";
import { AgentTree, AgentWithSubordinates } from "../../../types";
import AgentTreeNodeTitleView from "./AgentTreeNodeTitleView";

interface Props {
  agentTree?: AgentTree;
}

const AgentTreeView = ({ agentTree }: Props) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(false);
  const [sanitizedSearchKeyword, setSanitizedSearchKeyword] = useState<string>();

  useEffect(() => {
    if (agentTree) {
      setExpandedKeys([agentTree.agent.id]);
      setAutoExpandParent(false);
      setSanitizedSearchKeyword(undefined);
    }
  }, [agentTree]);

  const handleTreeExpand = (treeExpandedKeys: Key[]): void => {
    setExpandedKeys(treeExpandedKeys as string[]);
    setAutoExpandParent(false);
  };

  const handleExpandTreeClick = (agent: AgentWithSubordinates): void => {
    const aggregateAgentIds = (agents: AgentWithSubordinates[]): string[] =>
      ([] as string[]).concat(
        ...agents.map(agent =>
          agent.subordinates && agent.subordinates.length > 0
            ? [agent.id, ...aggregateAgentIds(agent.subordinates)]
            : [agent.id]
        )
      );

    setExpandedKeys(aggregateAgentIds([agent]));
  };

  const handleCollapseTreeClick = (agent: AgentWithSubordinates): void => {
    setExpandedKeys([agent.id]);
  };

  const handleSearchSubmit = (keyword: string, agentWithSubordinates: AgentWithSubordinates): void => {
    if (keyword.trim()) {
      const sanitize = (str: string): string => stripAccents(str).toLowerCase().trim();

      const sanitizedKeyword = sanitize(keyword);

      const findAgentIdsByKeyword = (agents: AgentWithSubordinates[]): string[] =>
        ([] as string[]).concat(
          ...agents.map(agent => {
            const results: UUID[] = [];
            const formattedAgentId = formatAgentIdNumber(agent);
            const agentIdentifier = agent.identifier;
            const code = agent.commissionsSettings.commissionsSettingsLevel?.code;

            if (
              sanitizedKeyword &&
              (sanitize(agent.aggregatedName)?.indexOf(sanitizedKeyword) > -1 ||
                (formattedAgentId ? sanitize(formattedAgentId).indexOf(sanitizedKeyword) > -1 : undefined) ||
                (agentIdentifier ? sanitize(agentIdentifier).indexOf(sanitizedKeyword) > -1 : undefined) ||
                (code ? sanitize(code)?.indexOf(sanitizedKeyword) > -1 : undefined))
            ) {
              const treePathIds = agent.accessTreePath?.split(accessTreePathDelimiter);
              if (treePathIds && treePathIds[treePathIds.length - 2]) {
                results.push(treePathIds[treePathIds.length - 2] as string);
              }
            }

            if (agent.subordinates && agent.subordinates.length > 0) {
              results.push(...findAgentIdsByKeyword(agent.subordinates));
            }

            return results;
          })
        );

      const foundIds = findAgentIdsByKeyword([agentWithSubordinates]);
      const distinctIds = [...new Set(foundIds)];

      messageUtils.successMessage(tInterval("agent.helpers.treeSearchResultMessage_interval", foundIds.length));
      setExpandedKeys(distinctIds.length > 0 ? distinctIds : [agentWithSubordinates.id]);
      setAutoExpandParent(true);
      setSanitizedSearchKeyword(sanitizedKeyword);
    } else {
      setExpandedKeys([agentWithSubordinates.id]);
      setAutoExpandParent(false);
      setSanitizedSearchKeyword(undefined);
    }
  };

  const buildTreeNodes = (agents: AgentWithSubordinates[]): DataNode[] => {
    return agents.map<DataNode>(agent => ({
      title: <AgentTreeNodeTitleView agent={agent} sanitizedSearchKeyword={sanitizedSearchKeyword} />,
      key: agent.id,
      value: agent.id,
      selectable: false,
      children: isNotEmptyArray(agent.subordinates) ? buildTreeNodes(agent.subordinates) : undefined
    }));
  };

  return agentTree ? (
    <Card className="card-box">
      <Row gutter={0}>
        <Col>
          <Space size="small" className="margin-bottom-medium">
            <ActionButton icon="arrows" onClick={() => handleExpandTreeClick(agentTree.agent)}>
              {t("agent.actions.expandTree")}
            </ActionButton>
            <ActionButton icon="shrink" onClick={() => handleCollapseTreeClick(agentTree.agent)}>
              {t("agent.actions.collapseTree")}
            </ActionButton>
          </Space>
        </Col>
        <Col flex="auto" className="right-align">
          <Input.Search
            style={{ width: 340 }}
            enterButton
            allowClear
            placeholder={t("agent.helpers.treeSearchHint")}
            onSearch={keyword => handleSearchSubmit(keyword, agentTree.agent)}
          />
        </Col>
      </Row>

      <Tree
        style={{ width: "max-content" }}
        showLine={{ showLeafIcon: false }}
        treeData={buildTreeNodes([agentTree.agent])}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onExpand={handleTreeExpand}
      />
    </Card>
  ) : null;
};

export default AgentTreeView;
