import { Checkbox, Col, Form, InputNumber, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import get from "lodash/get";
import t from "../../../../../../app/i18n";
import InputAddon from "../../../../../../common/components/form/addons/InputAddon";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../common/constants";
import PlaceOfInsuranceForm from "../../../../../../common/modules/address/PlaceOfInsuranceForm";
import { formatClientName } from "../../../../../../common/utils/formatUtils";
import { inputNumberIntegerStandardProps, selectStandardProps } from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import {
  BuildingState,
  BuildingType,
  ConstructionMaterial,
  RoofType
} from "../../../../../calculator/calcs/realty/enums";
import { ClientType } from "../../../../../client/enums";
import { Client } from "../../../../../client/types";
import { CreateUpdateInsuranceContract, CreateUpdateRealtyInsurance } from "../../../../types";

interface Props {
  index: number;
  clients: (Client | undefined)[];
  form: FormInstance<CreateUpdateInsuranceContract>;
}

const RealtyInsuranceFormPart = ({ index, clients, form }: Props) => {
  const handleBuildingStateChange = (state: BuildingState): void => {
    if (state === BuildingState.UNDER_CONSTRUCTION) {
      const insurances = [...(form.getFieldValue(["insurances"]) || [])];
      insurances[index] = {
        ...insurances[index],
        insuranceData: {
          ...insurances[index].insuranceData,
          constructionYear: undefined,
          householdEnabled: false,
          household: undefined
        }
      } as CreateUpdateRealtyInsurance[];
      form.setFieldsValue({ insurances });
    }
  };

  const colSpan = 4;
  const colSpanBig = 6;
  const formNamePrefix = ["insurances", index];
  const formNameDataPrefix = [...formNamePrefix, "insuranceData"];

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "buildingType"]}
            label={t("calc.realty.enums.buildingType._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(BuildingType).map(type => ({
                value: type,
                label: t("calc.realty.enums.buildingType." + type)
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "buildingState"]}
            label={t("calc.realty.enums.buildingState._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(BuildingState).map(state => ({
                value: state,
                label: t("calc.realty.enums.buildingState." + state)
              }))}
              onChange={handleBuildingStateChange}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              get(prev, formNameDataPrefix)?.buildingState !== get(next, formNameDataPrefix)?.buildingState
            }
          >
            {({ getFieldValue }) => {
              const state = getFieldValue([...formNameDataPrefix, "buildingState"]) as BuildingState;
              return (
                <Form.Item
                  name={[...formNameDataPrefix, "constructionYear"]}
                  label={t("contract.attrs.insurances.insuranceData.constructionYear")}
                  rules={[
                    state === BuildingState.FINISHED || state === BuildingState.FINISHED_UNDER_RECONSTRUCTION
                      ? validations.notNull
                      : validations.none,
                    validations.minNumber(1900),
                    validations.maxNumber(dayjs().year())
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} disabled={state === BuildingState.UNDER_CONSTRUCTION} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "permanentlyOccupied"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
            className="form-item-without-label"
          >
            <Checkbox>{t("contract.attrs.insurances.insuranceData.permanentlyOccupied")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "complicity"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
            className="form-item-without-label"
          >
            <Checkbox>{t("contract.attrs.insurances.insuranceData.complicity")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "vinculation"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
            className="form-item-without-label"
          >
            <Checkbox>{t("contract.attrs.insurances.insuranceData.vinculation")}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          get(prev, formNameDataPrefix)?.buildingState !== get(next, formNameDataPrefix)?.buildingState
        }
      >
        {({ getFieldValue }) => {
          const buildingState = getFieldValue([...formNameDataPrefix, "buildingState"]) as BuildingState;
          return (
            <PlaceOfInsuranceForm
              form={form}
              rootNamePath={[...formNameDataPrefix, "placeOfInsurance"]}
              label={t("contract.attrs.insurances.insuranceData.placeOfInsurance._label")}
              buildingFinished={
                !buildingState ||
                buildingState === BuildingState.FINISHED ||
                buildingState === BuildingState.FINISHED_UNDER_RECONSTRUCTION
              }
            />
          );
        }}
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          get(prev, formNameDataPrefix)?.buildingType !== get(next, formNameDataPrefix)?.buildingType
        }
      >
        {({ getFieldValue }) => {
          const buildingType = getFieldValue([...formNameDataPrefix, "buildingType"]) as BuildingType;
          switch (buildingType) {
            case BuildingType.HOUSE:
            case BuildingType.RECREATIONAL_BUILDING:
              return (
                <>
                  <Row gutter={rowGutter}>
                    <Col span={24}>
                      <b>{t("calc.realty.enums.buildingType." + buildingType)}</b>
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameDataPrefix, "houseOrRecreationalBuilding", "constructionMaterial"]}
                        label={t("calc.realty.enums.constructionMaterial._label")}
                        rules={[validations.notNull]}
                      >
                        <Select
                          {...selectStandardProps}
                          options={Object.keys(ConstructionMaterial).map(material => ({
                            value: material,
                            label: t("calc.realty.enums.constructionMaterial." + material)
                          }))}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameDataPrefix, "houseOrRecreationalBuilding", "roofType"]}
                        label={t("calc.realty.enums.roofType._label")}
                        rules={[validations.notNull]}
                      >
                        <Select
                          {...selectStandardProps}
                          options={Object.keys(RoofType).map(type => ({
                            value: type,
                            label: t("calc.realty.enums.roofType." + type)
                          }))}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameDataPrefix, "houseOrRecreationalBuilding", "buildArea"]}
                        label={t("contract.attrs.insurances.insuranceData.houseOrRecreationalBuilding.buildArea")}
                        rules={[validations.notNull, validations.minNumber(1)]}
                      >
                        <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            case BuildingType.APARTMENT:
              return (
                <>
                  <Row gutter={rowGutter}>
                    <Col span={24}>
                      <b>{t("calc.realty.enums.buildingType." + buildingType)}</b>
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameDataPrefix, "apartment", "floor"]}
                        label={t("contract.attrs.insurances.insuranceData.apartment.floor")}
                        rules={[validations.notNull, validations.minNumber(1)]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameDataPrefix, "apartment", "floorArea"]}
                        label={t("contract.attrs.insurances.insuranceData.apartment.floorArea")}
                        rules={[validations.notNull, validations.minNumber(1)]}
                      >
                        <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="area" />} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            default:
              return null;
          }
        }}
      </Form.Item>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "realtyEnabled"]}
            valuePropName="checked"
            dependencies={[[...formNameDataPrefix, "householdEnabled"]]}
            rules={[
              validations.notFalseIfOtherFalse(
                [...formNameDataPrefix, "householdEnabled"],
                t("contract.attrs.insurances.insuranceData.household._label")
              )
            ]}
            initialValue={false}
            className="form-item-without-label"
          >
            <Checkbox>{t("contract.attrs.insurances.insuranceData.realty._label")}</Checkbox>
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            get(prev, formNameDataPrefix)?.realtyEnabled !== get(next, formNameDataPrefix)?.realtyEnabled
          }
        >
          {({ getFieldValue }) =>
            getFieldValue([...formNameDataPrefix, "realtyEnabled"]) && (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameDataPrefix, "realty", "insuranceAmount"]}
                    label={t("contract.attrs.insurances.insuranceData.realty.insuranceAmount")}
                    rules={[validations.notNull, validations.minNumber(1)]}
                  >
                    <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameDataPrefix, "realty", "liabilityInsuranceAmount"]}
                    label={t("contract.attrs.insurances.insuranceData.realty.liabilityInsuranceAmount")}
                    rules={[validations.minNumber(1)]}
                  >
                    <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
                  </Form.Item>
                </Col>
              </>
            )
          }
        </Form.Item>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              get(prev, formNameDataPrefix)?.buildingState !== get(next, formNameDataPrefix)?.buildingState
            }
          >
            {({ getFieldValue }) => {
              const state = getFieldValue([...formNameDataPrefix, "buildingState"]) as BuildingState;
              return (
                <Form.Item
                  name={[...formNameDataPrefix, "householdEnabled"]}
                  valuePropName="checked"
                  dependencies={[[...formNameDataPrefix, "realtyEnabled"]]}
                  rules={[
                    state === BuildingState.FINISHED || state === BuildingState.FINISHED_UNDER_RECONSTRUCTION
                      ? validations.notFalseIfOtherFalse(
                          [...formNameDataPrefix, "realtyEnabled"],
                          t("contract.attrs.insurances.insuranceData.realty._label")
                        )
                      : validations.none
                  ]}
                  initialValue={false}
                  className="form-item-without-label"
                >
                  <Checkbox disabled={state === BuildingState.UNDER_CONSTRUCTION}>
                    <LabelWithTooltip
                      label={t("contract.attrs.insurances.insuranceData.household._label")}
                      tooltip={t("contract.helpers.householdDisabled")}
                    />
                  </Checkbox>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            get(prev, formNameDataPrefix)?.householdEnabled !== get(next, formNameDataPrefix)?.householdEnabled
          }
        >
          {({ getFieldValue }) =>
            getFieldValue([...formNameDataPrefix, "householdEnabled"]) && (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameDataPrefix, "household", "insuranceAmount"]}
                    label={t("contract.attrs.insurances.insuranceData.household.insuranceAmount")}
                    rules={[validations.notNull, validations.minNumber(1)]}
                  >
                    <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameDataPrefix, "household", "liabilityInsuranceAmount"]}
                    label={t("contract.attrs.insurances.insuranceData.household.liabilityInsuranceAmount")}
                    rules={[validations.minNumber(1)]}
                  >
                    <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
                  </Form.Item>
                </Col>
              </>
            )
          }
        </Form.Item>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <b>{t("contract.attrs.insurances.insuranceData.reinsurances._label")}</b>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "reinsurances", "cyberSecurity"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("contract.attrs.insurances.insuranceData.reinsurances.cyberSecurity")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "reinsurances", "warranty"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("contract.attrs.insurances.insuranceData.reinsurances.warranty")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "reinsurances", "cycling"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("contract.attrs.insurances.insuranceData.reinsurances.cycling")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "reinsurances", "loan"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("contract.attrs.insurances.insuranceData.reinsurances.loan")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "reinsurances", "rent"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("contract.attrs.insurances.insuranceData.reinsurances.rent")}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpanBig}>
          <Form.Item
            name={[...formNamePrefix, "insuredClientIdentifier"]}
            label={t("contract.attrs.insurances.insuredClientIdentifier")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={clients
                .filter((c): c is Client => !!c)
                .map(c => ({ value: c.identifier, label: formatClientName(c) }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpanBig}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              get(prev, formNameDataPrefix)?.vinculation !== get(next, formNameDataPrefix)?.vinculation
            }
          >
            {({ getFieldValue }) =>
              getFieldValue([...formNameDataPrefix, "vinculation"]) && (
                <Form.Item
                  name={[...formNamePrefix, "vinculationClientIdentifier"]}
                  label={
                    <LabelWithTooltip
                      label={t("contract.attrs.insurances.vinculationClientIdentifier")}
                      tooltip={t("contract.helpers.vinculationClient")}
                    />
                  }
                  rules={[validations.notNull]}
                >
                  <Select
                    {...selectStandardProps}
                    options={clients
                      .filter((c, i): c is Client => c?.type === ClientType.LEGAL && i > 0)
                      .map(c => ({ value: c.identifier, label: formatClientName(c) }))}
                  />
                </Form.Item>
              )
            }
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default RealtyInsuranceFormPart;
