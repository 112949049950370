import { Card, Divider, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { ExportFileType } from "../../../../../common/enums";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { formatAgentIdNumber } from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../auth/ducks";
import { AGENT_ROUTE_PATHS } from "../../../paths";
import { AgentFilterPageResult, AgentList } from "../../../types";
import AgentActiveTag from "../../AgentActiveTag";
import AgentTipperTag from "../../AgentTipperTag";
import AgentTypeTagWithName from "../../AgentTypeTagWithName";

interface Props {
  agentsPage: AgentFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onExportClick: (exportFileType: ExportFileType) => void;
}

const AgentListTableView = (props: Props) => {
  const hasAgentCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.AGENT_COMMISSIONS)(state)
  );

  const columns: ColumnsType<AgentList> = [
    {
      key: "aggregatedName",
      title: t("agent.attrs.aggregatedName"),
      width: 220,
      ellipsis: { showTitle: false },
      render: (_, record) => <AgentTypeTagWithName agent={record} hideIdNumber ellipsis showLink />
    },
    {
      key: "idNumber",
      title: t("agent.attrs.idNumber"),
      width: 100,
      render: (_, record) => formatAgentIdNumber(record)
    },
    {
      key: "identifier",
      title: (
        <>
          {t("agent.attrs.personalIdentificationNumber")} / {t("agent.attrs.companyRegistrationNumber")}
        </>
      ),
      width: 100,
      render: (_, record) => record.identifier
    },
    {
      key: "commissionsSettingsLevel",
      title: t("agent.helpers.commissionsSettingsLevel"),
      width: 110,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.commissionsSettings.commissionsSettingsLevel?.code}</Ellipsis>
    },
    {
      key: "tags",
      align: "center",
      width: 190,
      render: (_, record) => (
        <>
          {record.tipper ? <AgentTipperTag /> : undefined}
          {record.deactivated && <AgentActiveTag agentDeactivated style={{ marginRight: 0 }} />}
        </>
      )
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <div className="nowrap">
          <ActionTextIcon
            path={generatePath(AGENT_ROUTE_PATHS.detail.to, { id: record.id })}
            icon="eye"
            color="blue"
            text={t("common.show")}
          />
          {hasAgentCommissionsPermission && (
            <>
              <Divider type="vertical" />
              <ActionTextIcon
                path={generatePath(AGENT_ROUTE_PATHS.commissions.to, { id: record.id })}
                icon="euro"
                color="green"
                text={t("common.commissions")}
              />
            </>
          )}
        </div>
      )
    }
  ];

  return (
    <Card className="card-box">
      <ComponentWithPermission permissions={[Permission.AGENT_EXPORT]}>
        <div
          className={props.agentsPage.totalElementsCount > 0 ? "table-header-export-actions" : "margin-bottom-small"}
        >
          <ActionTextIcon
            icon="download"
            color="blue"
            text={t("common.exportXlsx")}
            onClick={() => props.onExportClick(ExportFileType.XLSX)}
          />

          <Divider type="vertical" />

          <ActionTextIcon
            icon="download"
            color="green"
            text={t("common.exportCsv")}
            onClick={() => props.onExportClick(ExportFileType.CSV)}
          />
        </div>
      </ComponentWithPermission>

      <Table<AgentList>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.MEDIUM }}
        dataSource={props.agentsPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: props.agentsPage.pageIndex + 1,
          pageSize: props.agentsPage.pageSize,
          total: props.agentsPage.totalElementsCount,
          onChange: props.onPageChange
        }}
      />
    </Card>
  );
};

export default AgentListTableView;
