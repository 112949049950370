import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import FinancialMediationForm from "../components/forms/FinancialMediationForm";
import { createFinancialMediationActions } from "../ducks";

const FinancialMediationCreateContainer = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          createFinancialMediation: createFinancialMediationActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  return (
    <ContentWrapper>
      <FinancialMediationForm onCreate={actions.createFinancialMediation} />
    </ContentWrapper>
  );
};

export default FinancialMediationCreateContainer;
