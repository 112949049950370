import { Col, Row, Statistic } from "antd";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import CopyToClipboardView from "../../../../../common/components/views/CopyToClipboardView";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { PageHeader } from "../../../../../common/components/views/PageHeader";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { formatProductName } from "../../../../../common/utils/formatUtils";
import { threeSpacedStringNormalizeFunction } from "../../../../../common/utils/formUtils";
import { selectHasPermissions, selectUserAccount } from "../../../../auth/ducks";
import { ClientsListDrawerView } from "../../../../client/components/drawers/ClientsListDrawerView";
import { ClientFormType } from "../../../../client/enums";
import { Client } from "../../../../client/types";
import { selectInstitutionsEnums } from "../../../../enumerations/ducks";
import { InstitutionWithSettings } from "../../../../enumerations/types";
import { UserAccount } from "../../../../user/types";
import {
  changeContractVerificationStatusActions,
  deleteContractActions,
  getContractExternalViewActions
} from "../../../ducks";
import { ContractVerificationStatus } from "../../../enums";
import { Contract } from "../../../types";
import { isAgentOnContract } from "../../../utils";
import ContractStatusTag from "../../ContractStatusTag";
import ContractVerificationStatusTag from "../../ContractVerificationStatusTag";
import ContractChangeVerificationStatusForm from "../../forms/ContractChangeVerificationStatusForm";
import ContractHeaderActionsView from "./ContractHeaderActionsView";

interface Props {
  contract: Contract;
  updatePermission: Permission;
  verificationPermission: Permission;
  deletePermission: Permission;
  onVerificationStatusChange: typeof changeContractVerificationStatusActions.request;
  onExternalViewGet: typeof getContractExternalViewActions.request;
  onDelete: typeof deleteContractActions.request;
  onUpdateClick: () => void;
}

const ContractHeaderSection = ({
  contract,
  updatePermission,
  verificationPermission,
  deletePermission,
  onVerificationStatusChange,
  onExternalViewGet,
  onDelete,
  onUpdateClick
}: Props) => {
  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);
  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);
  const hasVerificationStatusChangePermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(verificationPermission)(state)
  );

  const [verificationStatusFormOpen, setVerificationStatusFormOpen] = useState<boolean>(false);

  const agentIsOnContract = useMemo(
    () => userAccount && isAgentOnContract(userAccount, contract),
    [userAccount, contract]
  );

  const getTags = () => {
    const tags = [<ContractStatusTag key={1} status={contract.status} />];

    if (contract.verificationStatus) {
      tags.push(
        <ContractVerificationStatusTag
          key={2}
          status={contract.verificationStatus}
          note={contract.verificationNote}
          onNoteChangeClick={
            hasVerificationStatusChangePermission ||
            (agentIsOnContract && contract.verificationStatus === ContractVerificationStatus.SENT)
              ? () => setVerificationStatusFormOpen(true)
              : undefined
          }
        />
      );
    }

    return tags;
  };

  return (
    <>
      <PageHeader
        title={t("contract.enums.contractType." + contract.type)}
        tags={getTags()}
        extra={
          <ContractHeaderActionsView
            contract={contract}
            updatePermission={updatePermission}
            deletePermission={deletePermission}
            hasVerificationStatusChangePermission={hasVerificationStatusChangePermission}
            onVerificationStatusChange={onVerificationStatusChange}
            onExternalViewGet={onExternalViewGet}
            onDelete={onDelete}
            onUpdateClick={onUpdateClick}
          />
        }
      >
        <ItemCreatedUpdatedInfoView item={contract} style={{ marginTop: "-15px" }} className="margin-bottom-small" />

        <Row>
          <Col>
            <Statistic
              className="statistic-small"
              title={t("contract.attrs.contractNumber")}
              value={contract.contractNumber}
              formatter={value => (
                <CopyToClipboardView
                  content={value as string}
                  wrapper={threeSpacedStringNormalizeFunction(value as string)}
                />
              )}
            />
          </Col>

          <Col className="margin-left-medium">
            <Statistic
              className="statistic-small"
              title={t("contract.attrs.otherContractNumber")}
              value={contract.secondaryContractNumber}
              formatter={value => (
                <CopyToClipboardView
                  content={value as string}
                  wrapper={threeSpacedStringNormalizeFunction(value as string)}
                />
              )}
            />
          </Col>

          <Col className="margin-left-medium">
            <Statistic
              className="statistic-small"
              title={t("common.institution")}
              value={
                contract.institution.name +
                (institutionsEnums.find(i => i.id === contract.institution.id)?.settings.deactivated
                  ? ` (${t("institution.helpers.deactivated")})`
                  : "")
              }
            />
          </Col>

          <Col className="margin-left-medium">
            <Statistic
              className="statistic-small"
              title={t("contract.attrs.product")}
              value={formatProductName(contract.product)}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <h3 className="margin-top-medium">{t("contract.sections.clients")}</h3>
            <ClientsListDrawerView
              clientsData={[
                {
                  client: contract.clients[contract.policyHolderIndex] as Client,
                  clientFormTypes: [ClientFormType.POLICY_HOLDER]
                }
              ]}
            />
          </Col>
        </Row>
      </PageHeader>

      <ContractChangeVerificationStatusForm
        open={verificationStatusFormOpen}
        contract={contract}
        viewType="noteEdit"
        onSubmit={onVerificationStatusChange}
        onFormCancel={() => setVerificationStatusFormOpen(false)}
      />
    </>
  );
};

export default React.memo(ContractHeaderSection);
