import { Tag } from "antd";
import t from "../../../../../../app/i18n";
import { DashboardNoticePosition } from "../../../../enums";

interface Props {
  position?: DashboardNoticePosition;
  closable?: boolean;
  style?: React.CSSProperties;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  label?: React.ReactNode;
}

export const DashboardNoticePositionTag = ({ position, closable, style, onClose, label }: Props) => {
  let color;

  switch (position) {
    case DashboardNoticePosition.VEHICLE_CALC:
      color = "cyan";
      break;
    case DashboardNoticePosition.REALTY_CALC:
      color = "red";
      break;
    case DashboardNoticePosition.TRAVEL_CALC:
      color = "orange";
      break;
  }

  return position || label ? (
    <Tag color={color} closable={closable} style={style} onClose={onClose}>
      {position ? t("dashboard.notices.enums.position." + position) : label}
    </Tag>
  ) : null;
};
