import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { SearchPageRequest, SearchPageResult } from "../../../../../common/types";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import { CertificateVehicleColor } from "../../types";

interface Props {
  filter: SearchPageResult<CertificateVehicleColor>;
  onFilterSubmit: (filter: SearchPageRequest) => void;
  onCreateClick: () => void;
}

const VehicleColorFilterView = ({ filter, onFilterSubmit, onCreateClick }: Props) => {
  const [form] = Form.useForm<SearchPageRequest>();
  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: filter.keyword
    });
  }, [filter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("vehicleColor.titles.pageTitle")}</h2>}
      extra={
        <Button type="primary" icon={<AntIcon type="plus" />} onClick={onCreateClick}>
          {t("vehicleColor.actions.createColor")}
        </Button>
      }
    >
      <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="vehicleColorSearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.search")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("vehicleColor.helpers.filterSearchPlaceholder")} />
            </Form.Item>
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default VehicleColorFilterView;
