import { Button, Card, Checkbox, Col, DatePicker, Form, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  disableDatePickerFuture
} from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import CommissionsLevelSelect from "../../../../enumerations/components/form/CommissionsLevelSelect";
import { BailAccountsReportFilterPageRequest, BailAccountsReportFilterPageResult } from "../../types";

interface Props {
  filter: BailAccountsReportFilterPageResult;
  onFilterSubmit: (filter: BailAccountsReportFilterPageRequest) => void;
}

const BailAccountsReportFilterView = ({ filter, onFilterSubmit }: Props) => {
  const [form] = Form.useForm<BailAccountsReportFilterPageRequest>();

  useEffect(() => {
    form.setFieldsValue({
      balanceAsOfDate: filter.balanceAsOfDate,
      commissionsSettingsLevelIds: filter.commissionsSettingsLevelIds || [],
      agentCreatedAtDateMin: filter.agentCreatedAtDateMin,
      includeZeroBalance: filter.includeZeroBalance,
      rootAgentId: filter.rootAgentId,
      onlyDirectSubordinates: filter.onlyDirectSubordinates,
      includeDeactivated: filter.includeDeactivated,
      includeRepresentatives: filter.includeRepresentatives,
      includeNonGainers: filter.includeNonGainers
    });
  }, [filter, form]);

  const handleFilterSubmit = (filter: BailAccountsReportFilterPageRequest): void => {
    onFilterSubmit(filter);
  };

  const handleFilterClear = (): void => {
    form.resetFields();
    handleFilterSubmit(form.getFieldsValue());
  };

  const colSpan = 4;

  return (
    <Card
      className="card-box"
      title={<h2>{t("commissions.bailAccount.titles.report")}</h2>}
      extra={
        <ActionButton color="danger" size="middle" variant="outlined" icon="reload" onClick={handleFilterClear}>
          {t("common.resetFilter")}
        </ActionButton>
      }
    >
      <Form form={form} onFinish={handleFilterSubmit} layout="vertical" name="bailAccountsReportForm">
        <Row gutter={rowGutter} justify="start">
          <Col span={colSpan + 1}>
            <Form.Item
              name="balanceAsOfDate"
              label={t("commissions.bailAccount.filter.balanceAsOfDate")}
              rules={[validations.notFuture]}
              {...datePickerFormItemProps}
            >
              <DatePicker {...datePickerClearableProps} disabledDate={current => disableDatePickerFuture(current)} />
            </Form.Item>
          </Col>

          <Col span={colSpan * 2}>
            <CommissionsLevelSelect
              formItemProps={{
                name: "commissionsSettingsLevelIds",
                label: t("agent.filter.commissionsSettingsLevelIds"),
                rules: [validations.none]
              }}
              selectProps={{
                allowClear: true,
                mode: "multiple",
                maxTagCount: "responsive"
              }}
              optionsProps={{ renderOnlyLevelCodeTag: true }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="agentCreatedAtDateMin"
              label={t("agent.filter.agentCreatedAtDateMin")}
              rules={[validations.notFuture]}
              {...datePickerFormItemProps}
            >
              <DatePicker {...datePickerClearableProps} disabledDate={current => disableDatePickerFuture(current)} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="includeZeroBalance"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("commissions.bailAccount.filter.includeZeroBalance")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter} justify="start">
          <Col span={colSpan + 1}>
            <AgentSelect
              formItemProps={{
                name: "rootAgentId",
                label: t("agent.filter.rootAgentId")
              }}
              selectProps={{ allowClear: true }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="onlyDirectSubordinates"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("agent.filter.onlyDirectSubordinates")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="includeDeactivated"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("agent.filter.includeDeactivated")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="includeRepresentatives"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("agent.filter.includeRepresentatives")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="includeNonGainers"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("agent.filter.includeNonGainers")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan - 1} className="no-title-space right-align">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BailAccountsReportFilterView;
