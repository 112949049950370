import { Card, Modal, Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import { SecondaryButton } from "../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../common/components/icons/AntIcon";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ItemCreatedUpdatedInfoView from "../../../common/components/views/ItemCreatedUpdatedInfoView";
import HistoryView from "../../../common/modules/history/HistoryView";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import ComponentWithPermission from "../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../common/security/authorization/enums";
import { ActionProps, EntityIdObject, RootState } from "../../../common/types";
import { formatAgentAggregatedName } from "../../../common/utils/formatUtils";
import { useRequestFinishedCallback } from "../../../common/utils/hooksUtils";
import { appendSearchParamsToURL, hasPermission } from "../../../common/utils/utils";
import { selectPermissions } from "../../auth/ducks";
import { selectRouterLocationSearchParam } from "../../ducks";
import {
  adminCreateUserWithSingleAgentUserAccountActions,
  adminDeleteAgentUserAccountActions,
  adminGetUsersByAgentActions,
  adminResendUserConfirmLinkActions,
  adminUpdateAgentUserAccountActions,
  adminUpdateUserActions,
  deleteStateAdminUsersByAgentAction,
  selectAdminUsersByAgent
} from "../../user/ducks";
import { UserAdminView } from "../../user/types";
import { requests } from "../api";
import AgentTypeTag from "../components/AgentTypeTag";
import AgentForm from "../components/forms/agent/AgentForm";
import AgentBrandingForm from "../components/forms/AgentBrandingForm";
import AgentDetailView from "../components/views/detail/agent/AgentDetailView";
import AgentAttachmentsTableView from "../components/views/detail/AgentAttachmentsTableView";
import AgentBrokerNumberTableView from "../components/views/detail/AgentBrokerNumberTableView";
import AgentCompetenceTableView from "../components/views/detail/AgentCompetenceTableView";
import AgentLicenseTableView from "../components/views/detail/AgentLicenseTableView";
import AgentUserAccountsView from "../components/views/detail/AgentUserAccountsView";
import {
  createAgentBrandingActions,
  createAgentBrokerNumberActions,
  createAgentCompetenceActions,
  createAgentLicenseActions,
  deleteAgentActions,
  deleteAgentAttachmentActions,
  deleteAgentBrandingActions,
  deleteAgentBrokerNumberActions,
  deleteAgentCompetenceActions,
  deleteAgentLicenseActions,
  deleteStateAgentDetailAction,
  downloadAgentAttachmentsAsZipActions,
  getAgentActions,
  getAgentBrandingActions,
  getAgentBrokerNumbersActions,
  getAgentCompetencesActions,
  getAgentLicensesActions,
  selectAgentBranding,
  selectAgentBrokerNumbers,
  selectAgentCompetences,
  selectAgentDetail,
  selectAgentLicenses,
  updateAgentActions,
  updateAgentBrandingActions,
  updateAgentBrokerNumberActions,
  updateAgentCompetenceActions,
  updateAgentLicenseActions,
  uploadAgentAttachmentsActions
} from "../ducks";
import { AgentType } from "../enums";
import { AGENT_ROUTE_PATHS } from "../paths";
import { Agent, AgentBranding, AgentBrokerNumber, AgentCompetence, AgentLicense } from "../types";

interface StateProps {
  agent?: Agent;
  competences: AgentCompetence[];
  licenses: AgentLicense[];
  brokerNumbers: AgentBrokerNumber[];
  branding?: AgentBranding;
  usersByAgent: UserAdminView[];
  permissions: Permission[];
  urlTabKey: string;
}

interface ActionsMap {
  getAgent: typeof getAgentActions.request;
  updateAgent: typeof updateAgentActions.request;
  deleteAgent: typeof deleteAgentActions.request;
  deleteStateAgentDetail: typeof deleteStateAgentDetailAction;
  downloadAgentAttachmentsAsZip: typeof downloadAgentAttachmentsAsZipActions.request;
  uploadAgentAttachments: typeof uploadAgentAttachmentsActions.request;
  deleteAgentAttachment: typeof deleteAgentAttachmentActions.request;
  getAgentCompetences: typeof getAgentCompetencesActions.request;
  createAgentCompetence: typeof createAgentCompetenceActions.request;
  updateAgentCompetence: typeof updateAgentCompetenceActions.request;
  deleteAgentCompetence: typeof deleteAgentCompetenceActions.request;
  getAgentLicenses: typeof getAgentLicensesActions.request;
  createAgentLicense: typeof createAgentLicenseActions.request;
  updateAgentLicense: typeof updateAgentLicenseActions.request;
  deleteAgentLicense: typeof deleteAgentLicenseActions.request;
  getAgentBrokerNumbers: typeof getAgentBrokerNumbersActions.request;
  createAgentBrokerNumber: typeof createAgentBrokerNumberActions.request;
  updateAgentBrokerNumber: typeof updateAgentBrokerNumberActions.request;
  deleteAgentBrokerNumber: typeof deleteAgentBrokerNumberActions.request;
  getAgentBranding: typeof getAgentBrandingActions.request;
  createAgentBranding: typeof createAgentBrandingActions.request;
  updateAgentBranding: typeof updateAgentBrandingActions.request;
  deleteAgentBranding: typeof deleteAgentBrandingActions.request;
  adminGetUsersByAgent: typeof adminGetUsersByAgentActions.request;
  adminCreateOrUpdateUserWithAgentUserAccount: typeof adminCreateUserWithSingleAgentUserAccountActions.request;
  adminUpdateUser: typeof adminUpdateUserActions.request;
  adminResendUserConfirmLink: typeof adminResendUserConfirmLinkActions.request;
  adminUpdateAgentUserAccount: typeof adminUpdateAgentUserAccountActions.request;
  adminDeleteAgentUserAccount: typeof adminDeleteAgentUserAccountActions.request;
  deleteStateAdminUsersByAgent: typeof deleteStateAdminUsersByAgentAction;
}

type Props = StateProps & ActionProps<ActionsMap>;

const TAB = {
  AGENT: "agent",
  HISTORY: "history",
  ATTACHMENTS: "attachments",
  COMPETENCES: "competences",
  LICENSES: "licenses",
  BROKER_NUMBERS: "broker-numbers",
  ACCOUNTS: "accounts",
  BRANDING: "branding"
};

const AgentDetailContainer = ({
  agent,
  competences,
  licenses,
  brokerNumbers,
  branding,
  usersByAgent,
  permissions,
  actions,
  urlTabKey
}: Props) => {
  const { id } = useParams<EntityIdObject>() as { id: string };
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState<string>(TAB.AGENT);
  const [agentUpdateMode, setAgentUpdateMode] = useState<boolean>(false);

  useRequestFinishedCallback([requests.UPDATE_AGENT], () => setAgentUpdateMode(false));

  const {
    hasAgentUpdatePermission,
    hasAgentCompetencePermission,
    hasAgentCompetenceManagePermission,
    hasAgentLicensePermission,
    hasAgentLicenseManagePermission,
    hasAgentBrokerNumberPermission,
    hasAgentBrokerNumberManagePermission,
    hasAdminUsersPermission,
    hasAdminTopAgentsPermission
  } = useMemo(
    () => ({
      hasAgentUpdatePermission: hasPermission(permissions, Permission.AGENT_UPDATE),
      hasAgentCompetencePermission: hasPermission(permissions, Permission.AGENT_COMPETENCE),
      hasAgentCompetenceManagePermission: hasPermission(permissions, Permission.AGENT_COMPETENCE_MANAGE),
      hasAgentLicensePermission: hasPermission(permissions, Permission.AGENT_LICENSE),
      hasAgentLicenseManagePermission: hasPermission(permissions, Permission.AGENT_LICENSE_MANAGE),
      hasAgentBrokerNumberPermission: hasPermission(permissions, Permission.AGENT_BROKER_NUMBER),
      hasAgentBrokerNumberManagePermission: hasPermission(permissions, Permission.AGENT_BROKER_NUMBER_MANAGE),
      hasAdminUsersPermission: hasPermission(permissions, Permission.ADMIN_USERS),
      hasAdminTopAgentsPermission: hasPermission(permissions, Permission.ADMIN_TOP_AGENTS)
    }),
    [permissions]
  );

  useEffect(() => {
    actions.getAgent({ id });

    switch (urlTabKey) {
      case TAB.AGENT:
      case TAB.HISTORY:
      case TAB.ATTACHMENTS:
        setTabKey(urlTabKey);
        break;
      case TAB.COMPETENCES:
        setTabKey(hasAgentCompetencePermission ? urlTabKey : TAB.AGENT);
        break;
      case TAB.LICENSES:
        setTabKey(hasAgentLicensePermission ? urlTabKey : TAB.AGENT);
        break;
      case TAB.BROKER_NUMBERS:
        setTabKey(hasAgentBrokerNumberPermission ? urlTabKey : TAB.AGENT);
        break;
      case TAB.ACCOUNTS:
        setTabKey(hasAdminUsersPermission ? urlTabKey : TAB.AGENT);
        break;
      case TAB.BRANDING:
        setTabKey(hasAdminTopAgentsPermission ? urlTabKey : TAB.AGENT);
        break;
      default:
        setTabKey(TAB.AGENT);
        break;
    }

    return () => {
      actions.deleteStateAgentDetail();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (agent) {
      actions.getAgent({ id });
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabKeyChange = (key: string): void => {
    if (tabKey === TAB.AGENT && agentUpdateMode) {
      Modal.confirm({
        title: t("agent.helpers.unsavedChanges"),
        okText: t("agent.helpers.unsavedChangesConfirm"),
        cancelText: t("common.back"),
        onOk: () => moveViewToTab(key)
      });
    } else {
      moveViewToTab(key);
    }
  };

  const moveViewToTab = (tabKey: string): void => {
    setTabKey(tabKey);
    navigate(appendSearchParamsToURL({ tab: tabKey }), { replace: true });
  };

  return (
    <DisplayWrapper itemLoaded={!!agent} notFoundCheckRequest={requests.GET_AGENT}>
      {agent && (
        <ContentWrapper>
          <Card
            className="card-box"
            title={
              <>
                <BackNavigationArrow>
                  <h2 className="left-float">{formatAgentAggregatedName(agent)}</h2>
                </BackNavigationArrow>
                <AgentTypeTag style={{ margin: "6px 0 0 8px" }} type={agent.type} />
              </>
            }
            extra={
              <ComponentWithPermission permissions={[Permission.AGENT_COMMISSIONS]}>
                <Link to={generatePath(AGENT_ROUTE_PATHS.commissions.to, { id: agent.id })}>
                  <SecondaryButton size="small" icon={<AntIcon type="euro" />}>
                    {t("agent.actions.goToAgentCommissions")}
                  </SecondaryButton>
                </Link>
              </ComponentWithPermission>
            }
          >
            <ItemCreatedUpdatedInfoView item={agent} className="clear-both margin-bottom-small" />

            <Tabs
              activeKey={tabKey}
              onChange={handleTabKeyChange}
              items={
                [
                  {
                    key: TAB.AGENT,
                    label: (
                      <span>
                        <AntIcon type="solution" className="margin-right-tiny" />
                        {t("agent.titles.data")}
                      </span>
                    ),
                    children: agentUpdateMode ? (
                      <AgentForm
                        agent={agent}
                        onUpdate={actions.updateAgent}
                        onCancelClick={() => setAgentUpdateMode(false)}
                      />
                    ) : (
                      <AgentDetailView
                        agent={agent}
                        onUpdateClick={() => setAgentUpdateMode(true)}
                        onDelete={actions.deleteAgent}
                      />
                    )
                  },
                  {
                    key: TAB.HISTORY,
                    label: (
                      <span>
                        <AntIcon type="history" className="margin-right-tiny" />
                        {t("agent.titles.history")}
                      </span>
                    ),
                    children: <HistoryView item={agent} translationRootPath="agent.attrs" type="agent" />
                  },
                  {
                    key: TAB.ATTACHMENTS,
                    label: (
                      <span>
                        <AntIcon type="paper-clip" className="margin-right-tiny" />
                        {t("agent.titles.attachments")}
                      </span>
                    ),
                    children: (
                      <AgentAttachmentsTableView
                        agent={agent}
                        onDownloadAsZip={actions.downloadAgentAttachmentsAsZip}
                        onUpload={hasAgentUpdatePermission ? actions.uploadAgentAttachments : undefined}
                        onDelete={hasAgentUpdatePermission ? actions.deleteAgentAttachment : undefined}
                      />
                    )
                  },
                  agent.type !== AgentType.LEGAL && hasAgentCompetencePermission
                    ? {
                        key: TAB.COMPETENCES,
                        label: (
                          <span>
                            <AntIcon type="file-done" className="margin-right-tiny" />
                            {t("agent.titles.competences")}
                          </span>
                        ),
                        children: (
                          <AgentCompetenceTableView
                            agentId={agent.id}
                            competences={competences}
                            showAllColumns
                            showActions={hasAgentCompetenceManagePermission}
                            onGet={actions.getAgentCompetences}
                            onCreate={actions.createAgentCompetence}
                            onUpdate={actions.updateAgentCompetence}
                            onDelete={actions.deleteAgentCompetence}
                          />
                        )
                      }
                    : undefined,
                  hasAgentLicensePermission
                    ? {
                        key: TAB.LICENSES,
                        label: (
                          <span>
                            <AntIcon type="bank" className="margin-right-tiny" />
                            {t("agent.titles.licenses")}
                          </span>
                        ),
                        children: (
                          <AgentLicenseTableView
                            agentId={agent.id}
                            defaultLicenseValue={agent.nbsRegistrationNumber}
                            licenses={licenses}
                            showAllColumns
                            showActions={hasAgentLicenseManagePermission}
                            onGet={actions.getAgentLicenses}
                            onCreate={actions.createAgentLicense}
                            onUpdate={actions.updateAgentLicense}
                            onDelete={actions.deleteAgentLicense}
                          />
                        )
                      }
                    : undefined,
                  hasAgentBrokerNumberPermission
                    ? {
                        key: TAB.BROKER_NUMBERS,
                        label: (
                          <span>
                            <AntIcon type="id-card" className="margin-right-tiny" />
                            {t("agent.titles.brokerNumbers")}
                          </span>
                        ),
                        children: (
                          <AgentBrokerNumberTableView
                            agentId={agent.id}
                            brokerNumbers={brokerNumbers}
                            showAllColumns
                            showActions={hasAgentBrokerNumberManagePermission}
                            onGet={actions.getAgentBrokerNumbers}
                            onCreate={actions.createAgentBrokerNumber}
                            onUpdate={actions.updateAgentBrokerNumber}
                            onDelete={actions.deleteAgentBrokerNumber}
                          />
                        )
                      }
                    : undefined,
                  hasAdminUsersPermission
                    ? {
                        key: TAB.ACCOUNTS,
                        label: (
                          <span>
                            <AntIcon type="user-switch" className="margin-right-tiny" />
                            {t("agent.titles.users")}
                          </span>
                        ),
                        children: (
                          <AgentUserAccountsView
                            agent={agent}
                            users={usersByAgent}
                            onGetUsers={actions.adminGetUsersByAgent}
                            onCreateUser={actions.adminCreateOrUpdateUserWithAgentUserAccount}
                            onUpdateUser={actions.adminUpdateUser}
                            onResendUserConfirmLink={actions.adminResendUserConfirmLink}
                            onUpdateUserAccount={actions.adminUpdateAgentUserAccount}
                            onDeleteUserAccount={actions.adminDeleteAgentUserAccount}
                            onDeleteStateAdminUsersByAgent={actions.deleteStateAdminUsersByAgent}
                          />
                        )
                      }
                    : undefined,
                  hasAdminTopAgentsPermission
                    ? {
                        key: TAB.BRANDING,
                        label: (
                          <span>
                            <AntIcon type="bg-colors" className="margin-right-tiny" />
                            {t("agent.titles.branding")}
                          </span>
                        ),
                        children: (
                          <AgentBrandingForm
                            branding={branding}
                            agent={agent}
                            onGet={actions.getAgentBranding}
                            onCreate={actions.createAgentBranding}
                            onUpdate={actions.updateAgentBranding}
                            onDelete={actions.deleteAgentBranding}
                          />
                        )
                      }
                    : undefined
                ].filter(item => item !== undefined) as TabsProps["items"]
              }
            />
          </Card>
        </ContentWrapper>
      )}
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  agent: selectAgentDetail(state),
  competences: selectAgentCompetences(state),
  licenses: selectAgentLicenses(state),
  brokerNumbers: selectAgentBrokerNumbers(state),
  branding: selectAgentBranding(state),
  usersByAgent: selectAdminUsersByAgent(state),
  permissions: selectPermissions(state),
  urlTabKey: selectRouterLocationSearchParam(state, "tab") || TAB.AGENT
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getAgent: getAgentActions.request,
      updateAgent: updateAgentActions.request,
      deleteAgent: deleteAgentActions.request,
      deleteStateAgentDetail: deleteStateAgentDetailAction,
      downloadAgentAttachmentsAsZip: downloadAgentAttachmentsAsZipActions.request,
      uploadAgentAttachments: uploadAgentAttachmentsActions.request,
      deleteAgentAttachment: deleteAgentAttachmentActions.request,
      getAgentCompetences: getAgentCompetencesActions.request,
      createAgentCompetence: createAgentCompetenceActions.request,
      updateAgentCompetence: updateAgentCompetenceActions.request,
      deleteAgentCompetence: deleteAgentCompetenceActions.request,
      getAgentLicenses: getAgentLicensesActions.request,
      createAgentLicense: createAgentLicenseActions.request,
      updateAgentLicense: updateAgentLicenseActions.request,
      deleteAgentLicense: deleteAgentLicenseActions.request,
      getAgentBrokerNumbers: getAgentBrokerNumbersActions.request,
      createAgentBrokerNumber: createAgentBrokerNumberActions.request,
      updateAgentBrokerNumber: updateAgentBrokerNumberActions.request,
      deleteAgentBrokerNumber: deleteAgentBrokerNumberActions.request,
      getAgentBranding: getAgentBrandingActions.request,
      createAgentBranding: createAgentBrandingActions.request,
      updateAgentBranding: updateAgentBrandingActions.request,
      deleteAgentBranding: deleteAgentBrandingActions.request,
      adminGetUsersByAgent: adminGetUsersByAgentActions.request,
      adminCreateOrUpdateUserWithAgentUserAccount: adminCreateUserWithSingleAgentUserAccountActions.request,
      adminUpdateUser: adminUpdateUserActions.request,
      adminResendUserConfirmLink: adminResendUserConfirmLinkActions.request,
      adminUpdateAgentUserAccount: adminUpdateAgentUserAccountActions.request,
      adminDeleteAgentUserAccount: adminDeleteAgentUserAccountActions.request,
      deleteStateAdminUsersByAgent: deleteStateAdminUsersByAgentAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AgentDetailContainer);
