import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import { RootState } from "../../../../common/types";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { UserBase } from "../../../user/types";
import { selectUsersEnums } from "../../ducks";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
}

const UserSelect = ({ formItemProps, selectProps }: Props) => {
  const usersEnums = useSelector<RootState, UserBase[]>(selectUsersEnums);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps}
        {...selectProps}
        options={usersEnums.map(user => ({
          value: user.id,
          label: user.name
        }))}
      />
    </Form.Item>
  );
};

export default UserSelect;
