import { Layout } from "antd";
import dayjs from "dayjs";
import cliipLogo from "../../../assets/images/logo-clip-1000xsquare-flower-transparent.png";
import itgoLogo from "../../../assets/images/logo-itgo-transparent.png";
import t from "../../i18n";
import styles from "./Footer.module.scss";

const Footer = () => (
  <Layout.Footer className={styles.footer}>
    <div className={styles.footerWrapper}>
      <div className={styles.logoWrapper}>
        <div>
          <div className={styles.footerText}>Powered by</div>
          <img className={styles.footerCompanyLogo} src={itgoLogo} alt="itgo logo" />
        </div>
      </div>
      <div className={styles.footerInfo}>
        <span>
          <strong>cliip</strong> -{" "}
          <span>
            <strong>C</strong>lient <strong>L</strong>oan <strong>I</strong>nsurance <strong>I</strong>nvestment{" "}
            <strong>P</strong>ortal
          </span>
        </span>
        {t("common.copyright", { year: dayjs().year() })}
      </div>
      <div className={styles.logoWrapper}>
        <img className={styles.footerAppLogo} src={cliipLogo} alt="cliip logo" />
      </div>
    </div>
  </Layout.Footer>
);

export default Footer;
