import { Col, Form, Input, Modal, Row } from "antd";
import t from "../../../../app/i18n";
import { rowGutter } from "../../../../common/constants";
import { resolveFormValidationError } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";

interface Props {
  openAsOffer: boolean;
  openAsDraft: boolean;
  initialName?: string;
  onOkClick: (clientName: string) => void;
  onCancelClick: () => void;
}

interface FormObject {
  clientName: string;
}

const CalcClientNameForm = ({ openAsOffer, openAsDraft, initialName, onOkClick, onCancelClick }: Props) => {
  const [form] = Form.useForm<FormObject>();

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        onOkClick(values.clientName);
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      title={openAsOffer ? t("calc.titles.offerCreate") : t("calc.titles.draftCreate")}
      open={openAsOffer || openAsDraft}
      okText={openAsOffer ? t("calc.actions.generateOffer") : t("calc.actions.saveDraft")}
      cancelText={t("common.close")}
      centered
      onOk={handleFormSubmit}
      onCancel={onCancelClick}
    >
      <Form form={form} layout="vertical" name="clientNameForm">
        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="clientName"
              label={t("calc.helpers.clientName")}
              rules={[validations.notBlank, validations.max(255)]}
              initialValue={initialName}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CalcClientNameForm;
