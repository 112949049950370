import { useState } from "react";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import ComponentWithPermission from "../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../common/security/authorization/enums";
import ClientsImportForm from "../../client/components/forms/import/ClientsImportForm";
import ContractsImportForm from "../../contract/components/forms/import/ContractsImportForm";
import ContractUpdateFilterView from "../components/views/ContractUpdateFilterView";

const ContractUpdateContainer = () => {
  const [clientImportFormOpen, setClientImportFormOpen] = useState<boolean>(false);
  const [contractImportFormOpen, setContractImportFormOpen] = useState<boolean>(false);

  return (
    <ContentWrapper>
      <ContractUpdateFilterView
        onImportClientsClick={() => setClientImportFormOpen(true)}
        onImportContractsClick={() => setContractImportFormOpen(true)}
      />

      <ComponentWithPermission permissions={[Permission.CLIENT_IMPORT]}>
        <ClientsImportForm open={clientImportFormOpen} onFormCancel={() => setClientImportFormOpen(false)} />
      </ComponentWithPermission>

      <ComponentWithPermission permissions={[Permission.CONTRACT_IMPORT]}>
        <ContractsImportForm open={contractImportFormOpen} onFormCancel={() => setContractImportFormOpen(false)} />
      </ComponentWithPermission>
    </ContentWrapper>
  );
};

export default ContractUpdateContainer;
