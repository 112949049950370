import { Button, ConfigProvider } from "antd";
import { ButtonProps } from "antd/lib/button";
import { useSelector } from "react-redux";
import { selectBrandingPersistData } from "../../../modules/ducks";
import { AgentBrandingEnumeration } from "../../../modules/enumerations/types";
import { RootState } from "../../types";
import { cssVariables } from "../../utils/utils";

export const SecondaryButton = ({ children, ...props }: ButtonProps) => {
  const branding = useSelector<RootState, AgentBrandingEnumeration | undefined>(selectBrandingPersistData);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: branding?.secondaryColor ?? cssVariables.colorGreen } }}>
      <Button type="primary" {...props}>
        {children}
      </Button>
    </ConfigProvider>
  );
};
