import { Card } from "antd";
import { memo } from "react";
import t from "../../../../../../../app/i18n";
import HtmlView from "../../../../../../../common/components/views/HtmlView";
import { formatLocaleCurrency, formatLocaleDate } from "../../../../../../../common/utils/formatUtils";
import { tBoolean } from "../../../../../../../common/utils/translationUtils";
import { InsuranceContract } from "../../../../../types";

interface Props {
  contract: InsuranceContract;
}

const InsuranceContractDataSection = ({ contract }: Props) => (
  <Card type="inner" className="card-box" title={t("contract.sections.contractData")}>
    <table className="data-table-view">
      <tbody>
        <tr>
          <td>{t("contract.attrs.mediationReportSignDate")}:</td>
          <td>{formatLocaleDate(contract.mediationReportSignDate)}</td>
          <td>{t("contract.enums.paymentFrequency._label")}:</td>
          <td className="right-align">{t("contract.enums.paymentFrequency." + contract.paymentFrequency)}</td>
          <td>{t("contract.enums.insurancePeriod._label")}:</td>
          <td>{t("contract.enums.insurancePeriod." + contract.insurancePeriod)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.signDateInsurance")}:</td>
          <td>{formatLocaleDate(contract.signDate)}</td>
          <td>{t("contract.attrs.annualPremium")}:</td>
          <td className="right-align">{formatLocaleCurrency(contract.annualPremium)}</td>
          <td>{t("contract.attrs.transferredFromOtherBroker")}:</td>
          <td>{tBoolean(contract.transferredFromOtherBroker)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.effectiveBeginningDate")}:</td>
          <td>{formatLocaleDate(contract.effectiveBeginningDate)}</td>
          <td>{t("contract.attrs.partialPremium")}:</td>
          <td className="right-align">{formatLocaleCurrency(contract.partialPremium)}</td>
          <td>{t("contract.attrs.transferredToOtherBroker")}:</td>
          <td>{formatLocaleDate(contract.transferredToOtherBrokerDate)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.effectiveEndDate")}:</td>
          <td>{formatLocaleDate(contract.effectiveEndDate)}</td>
          <td>{t("contract.attrs.insurancePeriodEndDate")}:</td>
          <td className="right-align">{formatLocaleDate(contract.insurancePeriodEndDate)}</td>
          <td>{t("contract.attrs.amountOwed")}:</td>
          <td className="highlighted-text right-align">{formatLocaleCurrency(contract.amountOwed)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.cancellationDate")}:</td>
          <td>{formatLocaleDate(contract.cancellationDate)}</td>
          <td>{t("contract.attrs.lastContractCancellationDate")}:</td>
          <td className="right-align">{formatLocaleDate(contract.lastContractCancellationDate)}</td>
          <td>{t("contract.attrs.paidUntilDate")}:</td>
          <td className="highlighted-text right-align">{formatLocaleDate(contract.paidUntilDate)}</td>
        </tr>
        <tr>
          <td>{t("contract.enums.periodType._label")}:</td>
          <td>{t("contract.enums.periodType." + contract.periodType)}</td>
          <td colSpan={4} />
        </tr>
      </tbody>
    </table>

    <table className="data-table-view margin-top-tiny">
      <tbody>
        <tr>
          <td>{t("contract.attrs.note")}:</td>
        </tr>
        <tr>
          <td>{contract.note ? <HtmlView value={contract.note} /> : undefined}</td>
        </tr>
      </tbody>
    </table>
  </Card>
);

export default memo(InsuranceContractDataSection);
