import { Alert, Col, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { rowGutter } from "../../../../../common/constants";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { formatLocaleCurrency, formatLocaleNettoPoints } from "../../../../../common/utils/formatUtils";
import { tableStandardProps } from "../../../../../common/utils/utils";
import AgentTypeTagWithName from "../../../../agent/components/AgentTypeTagWithName";
import { selectHasPermissions } from "../../../../auth/ducks";
import { CalculatedCommission, Commission } from "../../types";

interface Props {
  commission: Commission;
  showCalculations: boolean;
  onCalculationsUpdateClick?: (commission: Commission) => void;
}

const BatchCommissionExtendedTableView = ({ commission, showCalculations, ...props }: Props) => {
  const hasReadAgentPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.AGENT_READ)(state)
  );

  const hasCalculationErrors = commission.calculatedCommissions.some(c => !!c.errors.length);
  const hasModifiedCalculation = commission.calculatedCommissions.some(c => !!c.modified);

  const topAgentId = commission.calculatedCommissions[0]?.gainer.id;
  const calculatedCommissionsGroups: CalculatedCommission[][] = [];
  for (let i = 0; i < commission.calculatedCommissions.length; i++) {
    if (commission.calculatedCommissions[i]?.gainer.id === topAgentId) {
      const end = commission.calculatedCommissions.findIndex((cc, j) => j > i && cc.gainer.id === topAgentId);
      calculatedCommissionsGroups.push(
        commission.calculatedCommissions.slice(i, end > 0 ? end : commission.calculatedCommissions.length)
      );
    }
  }

  const columns: ColumnsType<CalculatedCommission> = [
    {
      key: "gainer",
      title: t("commissions.batch.attrs.commission.calculatedCommissions.gainer"),
      width: hasCalculationErrors || hasModifiedCalculation ? 140 : 180,
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <AgentTypeTagWithName agent={record.gainer} ellipsis showLink={hasReadAgentPermission} linkTarget="_blank" />
      )
    },
    {
      key: "commissionAmount",
      title: t("commissions.batch.attrs.commission.calculatedCommissions.commissionAmount"),
      align: "right",
      width: 120,
      render: (_, record) => formatLocaleCurrency(record.commissionAmount)
    },
    {
      key: "nettoPointAmount",
      title: t("commissions.batch.attrs.commission.calculatedCommissions.nettoPointAmount"),
      align: "right",
      width: 120,
      render: (_, record) => formatLocaleNettoPoints(record.nettoPointAmount)
    }
  ];

  if (hasCalculationErrors) {
    columns.push({
      key: "calculationErrors",
      width: 250,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        !!record.errors.length && (
          <Alert
            className="margin-left-medium margin-top-tiny margin-bottom-tiny"
            showIcon
            type="error"
            message={<Ellipsis>{record.errors.flatMap(e => e.description).join(" ")}</Ellipsis>}
            style={{ margin: 0 }}
          />
        )
    });
  }

  if (hasModifiedCalculation) {
    columns.push(
      {
        key: "modifiedBy",
        title: t("commissions.batch.attrs.commission.calculatedCommissions.modifiedBy"),
        width: 110,
        ellipsis: { showTitle: false },
        render: (_, record) => record.modified && <Ellipsis>{record.modifiedBy.name}</Ellipsis>
      },
      {
        key: "modificationReason",
        title: t("commissions.batch.attrs.commission.calculatedCommissions.modificationReason"),
        width: 140,
        ellipsis: { showTitle: false },
        render: (_, record) => record.modified && <Ellipsis>{record.modificationReason}</Ellipsis>
      }
    );
  }

  return (
    <>
      {!!commission.errors.length && (
        <Row
          gutter={rowGutter}
          className={classNames("margin-top-medium", {
            "margin-bottom-medium": !showCalculations || !calculatedCommissionsGroups.length
          })}
        >
          <Col flex="70px">
            <b>{t("common.errors")}:</b>
          </Col>
          <Col flex="1 1 0">{commission.errors.map(error => error.description).join(", ")}</Col>
        </Row>
      )}

      {showCalculations && !!calculatedCommissionsGroups.length && (
        <>
          <Row gutter={rowGutter} className="margin-top-medium">
            {calculatedCommissionsGroups.map((calculatedCommissions, index) => (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={hasCalculationErrors || hasModifiedCalculation ? 24 : 20}
                xl={hasCalculationErrors || hasModifiedCalculation ? 20 : 12}
                xxl={hasCalculationErrors || hasModifiedCalculation ? 12 : 8}
                key={index}
                className="margin-bottom-medium"
              >
                <h3>{t(`contract.attrs.gainerRecords.gainer${index + 1}Id`)}</h3>
                <Table<CalculatedCommission>
                  {...tableStandardProps(false, true, "inner-table")}
                  columns={columns}
                  scroll={{ x: hasCalculationErrors || hasModifiedCalculation ? 630 : 420 }}
                  dataSource={calculatedCommissions}
                  pagination={false}
                />
              </Col>
            ))}
          </Row>

          <ComponentWithPermission permissions={[Permission.COMMISSIONS_MANAGE]}>
            {props.onCalculationsUpdateClick && (
              <Row gutter={rowGutter} className="margin-bottom-medium">
                <Col span={24}>
                  <ActionTextIcon
                    icon="edit"
                    color="green"
                    text={t("common.edit")}
                    onClick={() => props.onCalculationsUpdateClick?.(commission)}
                  />
                </Col>
              </Row>
            )}
          </ComponentWithPermission>
        </>
      )}
    </>
  );
};

export default BatchCommissionExtendedTableView;
