import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import { RootState } from "../../../../common/types";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import type { UUID } from "../../../../typings/global";
import { selectVehicleBrandsEnums } from "../../ducks";
import { VehicleBrandWithModels } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  brandId: UUID;
}

const VehicleModelSelect = ({ formItemProps, selectProps, brandId }: Props) => {
  const vehicleBrandsEnums = useSelector<RootState, VehicleBrandWithModels[]>(selectVehicleBrandsEnums);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps}
        {...selectProps}
        options={(vehicleBrandsEnums.find(brand => brand.id === brandId)?.models || []).map(model => ({
          value: model.id,
          label: model.name
        }))}
      />
    </Form.Item>
  );
};

export default VehicleModelSelect;
