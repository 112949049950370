import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { deleteLifeInsuranceTariffActions } from "../../ducks";
import { LifeInsuranceTariff, LifeInsuranceTariffFilterPageResult } from "../../types";
import PrimaryTariffTag from "../PrimaryTariffTag";

interface Props {
  tariffsPage: LifeInsuranceTariffFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (tariff: LifeInsuranceTariff) => void;
  onDelete: typeof deleteLifeInsuranceTariffActions.request;
}

const LifeInsuranceTariffTableView = (props: Props) => {
  const columns: ColumnsType<LifeInsuranceTariff> = [
    {
      key: "group",
      title: t("lifeInsuranceTariff.attrs.groupId"),
      width: 160,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.group.name}</Ellipsis>
    },
    {
      key: "code",
      title: t("lifeInsuranceTariff.attrs.code"),
      width: 60,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.code}</Ellipsis>
    },
    {
      key: "name",
      title: t("lifeInsuranceTariff.attrs.name"),
      width: 420,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "primary",
      align: "center",
      width: 80,
      render: (_, record) => record.primaryTariff && <PrimaryTariffTag style={{ marginRight: 0 }} />
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <div className="nowrap">
          <ActionTextIcon
            icon="edit"
            color="blue"
            text={t("common.edit")}
            onClick={() => props.onUpdateClick(record)}
          />

          <Divider type="vertical" />

          <Popconfirm
            title={t("lifeInsuranceTariff.helpers.tariffDeleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => props.onDelete({ id: record.id })}
          >
            <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<LifeInsuranceTariff>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.MEDIUM }}
        dataSource={props.tariffsPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: props.tariffsPage.pageIndex + 1,
          pageSize: props.tariffsPage.pageSize,
          total: props.tariffsPage.totalElementsCount,
          onChange: props.onPageChange
        }}
      />
    </Card>
  );
};

export default LifeInsuranceTariffTableView;
