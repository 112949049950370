import { Alert, Button, Col, Modal, Popconfirm, Row, Space, Steps, Tabs } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import t from "../../../../../../app/i18n";
import { SecondaryButton } from "../../../../../../common/components/buttons/SecondaryButton";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";
import StatusTag from "../../../../../../common/components/tags/StatusTag";
import ItemCreatedUpdatedInfoView from "../../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { PageHeader } from "../../../../../../common/components/views/PageHeader";
import { rowGutter } from "../../../../../../common/constants";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../../common/types";
import { formatLocaleCurrencyWithNullAsZero, formatLocaleDateTime } from "../../../../../../common/utils/formatUtils";
import { useBackNavigation } from "../../../../../../common/utils/hooksUtils";
import { selectHasPermissions } from "../../../../../auth/ducks";
import { CommissionsBatchAttachmentType, CommissionsBatchStep, CommissionsBatchStepDirection } from "../../../enums";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../../paths";
import {
  CommissionsActions,
  CommissionsBatch,
  CommissionsBatchActions,
  CommissionsBatchAttachmentActions,
  CommissionsBatchInputAttachment,
  CommissionsFilterPageResult,
  CommissionsUnitActions
} from "../../../types";
import CommissionsBatchStepTag from "../../CommissionsBatchStepTag";
import CommissionsBatchForm from "../../forms/CommissionsBatchForm";
import CommissionsBatchPaymentOrderForm from "../../forms/CommissionsBatchPaymentOrderForm";
import CommissionsBatchCalculationStep from "./steps/CommissionsBatchCalculationStep";
import CommissionsBatchFinishStep from "./steps/CommissionsBatchFinishStep";
import CommissionsBatchImportStep from "./steps/CommissionsBatchImportStep";
import CommissionsBatchReviewStep from "./steps/CommissionsBatchReviewStep";

interface Props {
  batch: CommissionsBatch;
  paymentToBePaid?: number;
  commissionsPage: CommissionsFilterPageResult;
  batchActions: CommissionsBatchActions;
  unitActions: CommissionsUnitActions;
  attachmentActions: CommissionsBatchAttachmentActions;
  commissionsActions: CommissionsActions;
}

const CommissionsBatchDetailView = ({ batch, ...props }: Props) => {
  const onBackClick = useBackNavigation();

  const hasCommissionsManagePermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.COMMISSIONS_MANAGE)(state)
  );

  const [batchFormOpen, setBatchFormOpen] = useState<boolean>(false);
  const [paymentOrderFormOpen, setPaymentOrderFormOpen] = useState<boolean>(false);

  const attachmentImportInProgress = batch.attachments.some(
    a => a.type === CommissionsBatchAttachmentType.INPUT && (a as CommissionsBatchInputAttachment).importInProgress
  );
  const operationInProgress = batch.stepChangeInProgress || attachmentImportInProgress;

  const handleStepChange = (direction: CommissionsBatchStepDirection): void => {
    if (batch.step === CommissionsBatchStep.CALCULATION && direction === CommissionsBatchStepDirection.PREVIOUS) {
      Modal.confirm({
        title: t("commissions.batch.helpers.returnToImportStepConfirm"),
        okText: t("common.yes"),
        cancelText: t("common.cancel"),
        onOk: () => {
          props.batchActions.onStepChange({
            id: batch.id,
            object: { optimisticLockVersion: batch.optimisticLockVersion, direction }
          });
        }
      });
    } else {
      props.batchActions.onStepChange({
        id: batch.id,
        object: { optimisticLockVersion: batch.optimisticLockVersion, direction }
      });
    }
  };

  const batchSteps = (
    <Steps
      size="small"
      current={Object.keys(CommissionsBatchStep).indexOf(batch.step)}
      items={[
        {
          icon: attachmentImportInProgress && <AntIcon type="loading" />,
          title: t("commissions.batch.enums.batchStep." + CommissionsBatchStep.IMPORT)
        },
        {
          icon: batch.upcomingStep === CommissionsBatchStep.CALCULATION && <AntIcon type="loading" />,
          title: t("commissions.batch.enums.batchStep." + CommissionsBatchStep.CALCULATION)
        },
        {
          icon: batch.upcomingStep === CommissionsBatchStep.RECAPITULATION && <AntIcon type="loading" />,
          title: t("commissions.batch.enums.batchStep." + CommissionsBatchStep.RECAPITULATION)
        },
        {
          icon: batch.upcomingStep === CommissionsBatchStep.REVIEW && <AntIcon type="loading" />,
          title: t("commissions.batch.enums.batchStep." + CommissionsBatchStep.REVIEW)
        },
        {
          icon: batch.upcomingStep === CommissionsBatchStep.FINISH && <AntIcon type="loading" />,
          title: t("commissions.batch.enums.batchStep." + CommissionsBatchStep.FINISH)
        }
      ]}
    />
  );

  const getTags = (operationInProgress: boolean) => {
    const tags = [<CommissionsBatchStepTag key={1} step={batch.step} />];

    if (operationInProgress) {
      tags.push(<StatusTag key={2} status="processing" />);
    }

    return tags;
  };

  return (
    <>
      <PageHeader
        onBack={onBackClick}
        title={t("commissions.batch.titles.batchDetail", { name: batch.name })}
        tags={getTags(operationInProgress)}
        extra={
          hasCommissionsManagePermission ? (
            batch.step === CommissionsBatchStep.FINISH ? (
              <SecondaryButton icon={<AntIcon type="bank" />} onClick={() => setPaymentOrderFormOpen(true)}>
                {t("commissions.batch.actions.generatePaymentOrder")}
              </SecondaryButton>
            ) : (
              <Space>
                <Button
                  type="primary"
                  icon={<AntIcon type="double-left" />}
                  disabled={batch.step === CommissionsBatchStep.IMPORT || operationInProgress}
                  onClick={() => handleStepChange(CommissionsBatchStepDirection.PREVIOUS)}
                >
                  {t("commissions.batch.actions.batchPreviousStep")}
                </Button>

                <Button
                  type="primary"
                  icon={<AntIcon type="double-right" />}
                  disabled={operationInProgress}
                  onClick={() => handleStepChange(CommissionsBatchStepDirection.NEXT)}
                >
                  {t("commissions.batch.actions.batchNextStep")}
                </Button>

                {(batch.step === CommissionsBatchStep.RECAPITULATION || batch.step === CommissionsBatchStep.REVIEW) && (
                  <SecondaryButton icon={<AntIcon type="bank" />} onClick={() => setPaymentOrderFormOpen(true)}>
                    {t("commissions.batch.actions.generatePaymentOrder")}
                  </SecondaryButton>
                )}

                <SecondaryButton
                  icon={<AntIcon type="edit" />}
                  disabled={operationInProgress}
                  onClick={() => setBatchFormOpen(true)}
                >
                  {t("common.edit")}
                </SecondaryButton>

                <Popconfirm
                  title={t("commissions.batch.helpers.deleteBatchConfirm")}
                  icon={<PopconfirmDeleteIcon />}
                  okText={t("common.yes")}
                  cancelText={t("common.no")}
                  okType="danger"
                  disabled={operationInProgress}
                  onConfirm={() => props.batchActions.onDelete({ id: batch.id })}
                >
                  <Button danger icon={<AntIcon type="delete" />} disabled={operationInProgress}>
                    {t("common.delete")}
                  </Button>
                </Popconfirm>
              </Space>
            )
          ) : (
            batchSteps
          )
        }
      >
        <ItemCreatedUpdatedInfoView item={batch} style={{ marginTop: "-15px" }} className="margin-bottom-small" />
        {batch.finishedAt && (
          <div style={{ marginTop: "-15px" }} className="sub-header-info margin-bottom-small">
            <span>{t("commissions.batch.attrs.finishedAt")}:</span> {formatLocaleDateTime(batch.finishedAt)}
          </div>
        )}

        <Row gutter={rowGutter}>
          <Col flex="auto">
            <table className="data-table-view">
              <tbody>
                <tr>
                  <td>{t("commissions.batch.attrs.allCommissionAmount")}:</td>
                  <td className="right-align">{formatLocaleCurrencyWithNullAsZero(batch.allCommissionAmount)}</td>
                </tr>
                <tr>
                  <td>
                    {t("commissions.batch.attrs.currentBatchCommissionAmount")}:
                    <br />
                    {t("commissions.batch.attrs.previousBatchesCommissionAmount")}:
                  </td>
                  <td className="right-align">
                    {formatLocaleCurrencyWithNullAsZero(batch.currentBatchCommissionAmount)}
                    <br />
                    {formatLocaleCurrencyWithNullAsZero(batch.previousBatchesCommissionAmount)}
                  </td>
                  {batch.step === CommissionsBatchStep.IMPORT && (
                    <td>
                      &nbsp;
                      <br />
                      <ActionTextIcon
                        path={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.postponedCommissions.to, { id: batch.id })}
                        icon="interaction"
                        color="blue"
                        text={t("common.manage")}
                        disabled={batch.stepChangeInProgress}
                      />
                    </td>
                  )}
                </tr>
                <tr>
                  <td>
                    {t("commissions.batch.attrs.calculatedCommissionAmount")}:
                    <br />
                    {t("commissions.batch.attrs.postponedCommissionAmount")}:
                  </td>
                  <td className="right-align">
                    {formatLocaleCurrencyWithNullAsZero(batch.calculatedCommissionAmount)}
                    <br />
                    {formatLocaleCurrencyWithNullAsZero(batch.postponedCommissionAmount)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t("commissions.batch.attrs.bankAccountCommissionAmount")}:
                    <br />
                    {t("commissions.batch.attrs.invoiceCommissionAmount")}:
                    <br />
                    {t("commissions.batch.attrs.volumeCommissionAmount")}:
                  </td>
                  <td className="right-align">
                    {formatLocaleCurrencyWithNullAsZero(batch.bankAccountCommissionAmount)}
                    <br />
                    {formatLocaleCurrencyWithNullAsZero(batch.invoiceCommissionAmount)}
                    <br />
                    {formatLocaleCurrencyWithNullAsZero(batch.volumeCommissionAmount)}
                  </td>
                </tr>
                <tr>
                  <td>{t("commissions.batch.attrs.manualBailAccountMovementsCount")}:</td>
                  <td className="right-align">{batch.manualBailAccountMovementsCount}</td>
                  {(batch.step === CommissionsBatchStep.RECAPITULATION ||
                    batch.step === CommissionsBatchStep.REVIEW ||
                    batch.step === CommissionsBatchStep.FINISH) && (
                    <td>
                      <ActionTextIcon
                        path={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.manualBailAccountMovements.to, {
                          id: batch.id
                        })}
                        icon="interaction"
                        color="blue"
                        text={
                          batch.step === CommissionsBatchStep.RECAPITULATION ? t("common.manage") : t("common.show")
                        }
                        disabled={batch.stepChangeInProgress}
                      />
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </Col>

          <Col flex="740px" className="right-align">
            {hasCommissionsManagePermission && batchSteps}

            {batch.stepChangeError && (
              <Alert
                className="left-align margin-top-medium"
                message={t("commissions.batch.helpers.batchStepChangeError")}
                description={batch.stepChangeError.description}
                type="error"
                showIcon
              />
            )}
          </Col>
        </Row>
      </PageHeader>

      <Tabs
        activeKey={batch.step}
        className="margin-top-medium"
        renderTabBar={() => <></>}
        items={[
          {
            key: CommissionsBatchStep.IMPORT,
            label: null,
            children: (
              <CommissionsBatchImportStep
                batch={batch}
                unitActions={props.unitActions}
                attachmentActions={props.attachmentActions}
              />
            )
          },
          {
            key: CommissionsBatchStep.CALCULATION,
            label: null,
            children: (
              <CommissionsBatchCalculationStep
                batch={batch}
                commissionsPage={props.commissionsPage}
                commissionsActions={props.commissionsActions}
              />
            )
          },
          {
            key: CommissionsBatchStep.RECAPITULATION,
            label: null,
            children: <CommissionsBatchReviewStep batch={batch} />
          },
          {
            key: CommissionsBatchStep.REVIEW,
            label: null,
            children: <CommissionsBatchReviewStep batch={batch} />
          },
          {
            key: CommissionsBatchStep.FINISH,
            label: null,
            children: (
              <CommissionsBatchFinishStep
                batch={batch}
                commissionsPage={props.commissionsPage}
                onFilterCommissions={props.commissionsActions.onFilter}
                onDownloadAttachmentsAsZip={props.batchActions.onDownloadAttachmentsAsZip}
              />
            )
          }
        ]}
      />

      <CommissionsBatchForm
        batch={batch}
        open={batchFormOpen}
        onUpdate={props.batchActions.onUpdate}
        onFormCancel={() => setBatchFormOpen(false)}
      />

      <CommissionsBatchPaymentOrderForm
        batch={batch}
        paymentToBePaid={props.paymentToBePaid}
        open={paymentOrderFormOpen}
        onCalculatePaymentToBePaid={props.batchActions.onCalculatePaymentToBePaid}
        onDeleteStatePaymentToBePaid={props.batchActions.onDeleteStatePaymentToBePaid}
        onGenerate={props.batchActions.onGeneratePaymentOrder}
        onFormCancel={() => setPaymentOrderFormOpen(false)}
      />
    </>
  );
};

export default CommissionsBatchDetailView;
