import { Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance } from "antd/lib/form";
import ReactQuill from "react-quill-new";
import t from "../../../../../app/i18n";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../common/constants";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerPresentAndFuture,
  fillBirthDateFromPin,
  ibanNormalizeFunction,
  phoneNumberNormalizeFunction,
  quillEditorStandardProps,
  selectStandardProps,
  upperCaseStringNormalizeFunction
} from "../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import AcademicDegreeAfterSelect from "../../../../enumerations/components/form/AcademicDegreeAfterSelect";
import AcademicDegreeSelect from "../../../../enumerations/components/form/AcademicDegreeSelect";
import { FinancialSector } from "../../../enums";
import { CreateUpdateAgent } from "../../../types";

interface Props {
  form: FormInstance<CreateUpdateAgent>;
  searchInProgress?: boolean;
  onTipperChange: (event: CheckboxChangeEvent) => void;
  onPinSearchOrChange?: (pin: string) => void;
}

const NaturalAgentDataFormPart = ({ form, searchInProgress, onTipperChange, onPinSearchOrChange }: Props) => {
  const handlePinSearchOrChange = (pin: string): void => {
    fillBirthDateFromPin(pin, form);
    onPinSearchOrChange?.(pin);
  };

  const colSpan = 4;

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="personalIdentificationNumber"
            label={
              <LabelWithTooltip
                tooltip={t("helpers.pinFormat")}
                label={t("agent.attrs.personalIdentificationNumberLong")}
              />
            }
            rules={[validations.notBlank, validations.pin]}
          >
            {onPinSearchOrChange ? (
              <Input.Search
                loading={searchInProgress}
                onSearch={handlePinSearchOrChange}
                onChange={e => handlePinSearchOrChange(e.target.value)}
              />
            ) : (
              <Input onChange={e => fillBirthDateFromPin(e.target.value, form)} />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <AcademicDegreeSelect
            formItemProps={{
              name: "academicDegree",
              label: t("agent.attrs.academicDegree")
            }}
            selectProps={{ allowClear: true }}
          />
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="firstName"
            label={t("agent.attrs.firstName")}
            rules={[validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="lastName"
            label={t("agent.attrs.lastName")}
            rules={[
              validations.notBlank,
              validations.size(1, 255),
              validations.pattern(regexPatterns.wordRegex),
              validations.pattern(regexPatterns.agentSortNameRegex)
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <AcademicDegreeAfterSelect
            formItemProps={{ name: "academicDegreeAfter", label: t("agent.attrs.academicDegreeAfter") }}
            selectProps={{ allowClear: true }}
          />
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="birthDate"
            label={t("agent.attrs.birthDate")}
            rules={[validations.notNull, validations.notPresentAndFuture]}
            {...datePickerFormItemProps}
          >
            <DatePicker
              {...datePickerStandardProps}
              showToday={false}
              disabledDate={disableDatePickerPresentAndFuture}
            />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="identityCardNumber"
            label={t("agent.attrs.identityCardNumber")}
            rules={[validations.size(8, 10), validations.pattern(regexPatterns.idCardRegex)]}
            normalize={upperCaseStringNormalizeFunction}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 2}>
          <Form.Item name="sectors" label={t("common.financialSectors")} rules={[validations.none]}>
            <Select
              {...selectStandardProps}
              mode="multiple"
              maxTagCount="responsive"
              options={Object.keys(FinancialSector).map(sector => ({
                value: sector,
                label: t("agent.enums.financialSector." + sector)
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.tipper !== next.tipper}>
            {({ getFieldValue }) => (
              <Form.Item
                name="nbsRegistrationNumber"
                label={t("agent.attrs.nbsRegistrationNumber")}
                rules={[validations.size(1, 32), validations.numeric]}
              >
                <Input disabled={getFieldValue("tipper")} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="tipper"
            valuePropName="checked"
            rules={[validations.none]}
            className="form-item-without-label"
            initialValue={false}
          >
            <Checkbox onChange={onTipperChange}>{t("agent.attrs.tipper")}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="email"
            label={t("agent.attrs.email")}
            rules={[validations.notBlank, validations.size(1, 254), validations.email]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="phone"
            label={t("agent.attrs.phone")}
            rules={[validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber]}
            normalize={phoneNumberNormalizeFunction}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="web"
            label={t("agent.attrs.web")}
            rules={[validations.size(1, 255), validations.pattern(regexPatterns.webPageRegex)]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 2}>
          <Form.Item
            name="bankAccountNumber"
            label={t("agent.attrs.bankAccountNumber")}
            rules={[validations.size(1, 34), validations.iban]}
            normalize={ibanNormalizeFunction}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 4}>
          <Form.Item
            name="note"
            label={t("agent.attrs.note")}
            rules={[validations.size(1, 8192)]}
            initialValue={undefined}
          >
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default NaturalAgentDataFormPart;
