import { Route, Routes } from "react-router";
import { WILDCARD_ROUTE } from "../../../common/constants";
import NotFound from "../../../common/pages/NotFound/NotFound";
import { Permission } from "../../../common/security/authorization/enums";
import PageWithPermission from "../../../common/security/authorization/PageWithPermission";
import CommissionsBatchAttachmentImportsContainer from "./containers/CommissionsBatchAttachmentImportsContainer";
import CommissionsBatchDetailContainer from "./containers/CommissionsBatchDetailContainer";
import CommissionsBatchListContainer from "./containers/CommissionsBatchListContainer";
import CommissionsBatchManualBailAccountMovementsContainer from "./containers/CommissionsBatchManualBailAccountMovementsContainer";
import CommissionsBatchManualImportsContainer from "./containers/CommissionsBatchManualImportsContainer";
import CommissionsBatchPostponedCommissionsContainer from "./containers/CommissionsBatchPostponedCommissionsContainer";
import CommissionsBatchSpecialCommissionsContainer from "./containers/CommissionsBatchSpecialCommissionsContainer";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "./paths";

const routes = () => (
  <Routes>
    <Route
      index
      element={
        <PageWithPermission component={<CommissionsBatchListContainer />} permissions={[Permission.COMMISSIONS]} />
      }
    />

    <Route
      path={COMMISSIONS_BATCH_ROUTE_PATHS.detail.path}
      element={
        <PageWithPermission component={<CommissionsBatchDetailContainer />} permissions={[Permission.COMMISSIONS]} />
      }
    />

    <Route
      path={COMMISSIONS_BATCH_ROUTE_PATHS.attachmentImports.path}
      element={
        <PageWithPermission
          component={<CommissionsBatchAttachmentImportsContainer />}
          permissions={[Permission.COMMISSIONS]}
        />
      }
    />

    <Route
      path={COMMISSIONS_BATCH_ROUTE_PATHS.manualImports.path}
      element={
        <PageWithPermission
          component={<CommissionsBatchManualImportsContainer />}
          permissions={[Permission.COMMISSIONS]}
        />
      }
    />

    <Route
      path={COMMISSIONS_BATCH_ROUTE_PATHS.postponedCommissions.path}
      element={
        <PageWithPermission
          component={<CommissionsBatchPostponedCommissionsContainer />}
          permissions={[Permission.COMMISSIONS]}
        />
      }
    />

    <Route
      path={COMMISSIONS_BATCH_ROUTE_PATHS.specialCommissions.path}
      element={
        <PageWithPermission
          component={<CommissionsBatchSpecialCommissionsContainer />}
          permissions={[Permission.COMMISSIONS]}
        />
      }
    />

    <Route
      path={COMMISSIONS_BATCH_ROUTE_PATHS.manualBailAccountMovements.path}
      element={
        <PageWithPermission
          component={<CommissionsBatchManualBailAccountMovementsContainer />}
          permissions={[Permission.COMMISSIONS]}
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
