export enum OperationStage {
  CALCULATE = "CALCULATE",
  GENERATE = "GENERATE"
}

export enum BuildingType {
  HOUSE = "HOUSE",
  APARTMENT = "APARTMENT",
  RECREATIONAL_BUILDING = "RECREATIONAL_BUILDING"
}

export enum BuildingState {
  FINISHED = "FINISHED",
  FINISHED_UNDER_RECONSTRUCTION = "FINISHED_UNDER_RECONSTRUCTION",
  UNDER_CONSTRUCTION = "UNDER_CONSTRUCTION"
}

export enum BuildingLevel {
  PURPOSE_BUILT = "PURPOSE_BUILT",
  STANDARD = "STANDARD",
  PREMIUM = "PREMIUM"
}

export enum AreaType {
  SAFE = "SAFE",
  RISK = "RISK"
}

export enum ConstructionMaterial {
  BRICK = "BRICK",
  CONCRETE = "CONCRETE",
  WOOD = "WOOD",
  OTHER = "OTHER"
}

export enum RoofType {
  FLAT = "FLAT",
  PITCHED_WITHOUT_ATTIC = "PITCHED_WITHOUT_ATTIC",
  PITCHED_WITH_ATTIC = "PITCHED_WITH_ATTIC"
}

export enum NearbyBuildingType {
  DETACHED_GARAGE = "DETACHED_GARAGE",
  BUILT_SWIMMING_POOL = "BUILT_SWIMMING_POOL",
  BUILT_SWIMMING_POOL_WITH_ROOF = "BUILT_SWIMMING_POOL_WITH_ROOF",
  GARDEN = "GARDEN",
  TOMBSTONE = "TOMBSTONE",
  FARM_BUILDING = "FARM_BUILDING",
  WORKSHOP = "WORKSHOP",
  ARBOUR = "ARBOUR",
  GARDEN_HOUSE = "GARDEN_HOUSE",
  WINTER_GARDEN = "WINTER_GARDEN",
  GREENHOUSE = "GREENHOUSE",
  UNDERGROUND_CELLAR = "UNDERGROUND_CELLAR",
  SAUNA = "SAUNA",
  TENNIS_COURT = "TENNIS_COURT",
  FENCING = "FENCING",
  WALL = "WALL",
  PAVEMENT = "PAVEMENT",
  WELL = "WELL",
  HEAT_PUMP = "HEAT_PUMP",
  SEWAGE = "SEWAGE",
  GAS_TANK = "GAS_TANK",
  SEPTIC = "SEPTIC",
  OTHER = "OTHER"
}

export enum HouseholdEquipmentType {
  BIKE = "BIKE",
  E_BIKE = "E_BIKE",
  SCOOTER = "SCOOTER",
  E_SCOOTER = "E_SCOOTER",
  BIKE_TRAILER = "BIKE_TRAILER",
  BIKE_CART = "BIKE_CART",
  STRUCTURAL_COMPONENTS = "STRUCTURAL_COMPONENTS",
  OTHER = "OTHER"
}

export enum Liability {
  NONE = "NONE",
  AT_LEAST_40_000 = "AT_LEAST_40_000",
  AT_LEAST_100_000 = "AT_LEAST_100_000",
  AT_LEAST_200_000 = "AT_LEAST_200_000",
  AT_LEAST_300_000 = "AT_LEAST_300_000",
  AT_LEAST_400_000 = "AT_LEAST_400_000",
  AT_LEAST_1_000_000 = "AT_LEAST_1_000_000",
  AT_LEAST_2_000_000 = "AT_LEAST_2_000_000"
}

export enum RealtyComplicityAmount {
  AT_LEAST_40 = "AT_LEAST_40",
  AT_LEAST_100 = "AT_LEAST_100",
  AT_LEAST_150 = "AT_LEAST_150",
  AT_LEAST_300 = "AT_LEAST_300"
}

export enum LoanReinsuranceAmount {
  MONTHLY_500 = "MONTHLY_500",
  MONTHLY_1000 = "MONTHLY_1000",
  MONTHLY_1500 = "MONTHLY_1500"
}
