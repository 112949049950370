import t from "../../../../../../../app/i18n";
import {
  formatCoverageLimit,
  formatIntegerLocaleCurrency,
  formatLicensePlate,
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocaleNumber
} from "../../../../../../../common/utils/formatUtils";
import { tBoolean } from "../../../../../../../common/utils/translationUtils";
import { isDefinedValue } from "../../../../../../../common/utils/utils";
import { ClientsListDrawerView } from "../../../../../../client/components/drawers/ClientsListDrawerView";
import { ClientFormType } from "../../../../../../client/enums";
import { Client } from "../../../../../../client/types";
import { InsuranceType } from "../../../../../enums";
import {
  CrashInsuranceData,
  GapInsuranceData,
  InsuranceContract,
  MtplInsuranceData,
  VehicleInsurance
} from "../../../../../types";
import { parseVehicleModelName } from "../../../../../utils";

interface Props {
  contract: InsuranceContract;
  index: number;
  multipleInsurances: boolean;
  multipleClients: boolean;
}

const VehicleInsuranceView = ({ contract, index, multipleInsurances, multipleClients }: Props) => {
  const { vehicle, insuranceData, ...insurance } = contract.insurances[index] as VehicleInsurance;

  let insuranceDataView = undefined;
  switch (insurance.type) {
    case InsuranceType.MTPL:
      const mtplData = insuranceData as MtplInsuranceData;
      insuranceDataView = (
        <>
          <tr>
            <td>{t("contract.attrs.insurances.coverageLimit")}:</td>
            <td>{formatCoverageLimit(insurance.coverageLimit)}</td>
            <td>{t("calc.vehicle.enums.vehiclePurpose._label")}:</td>
            <td>{t("calc.vehicle.enums.vehiclePurpose." + insuranceData.purpose)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.leasing")}:</td>
            <td>{tBoolean(insuranceData.leasing)}</td>
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.extendedAssistance")}:</td>
            <td>{tBoolean(mtplData.coverages.extendedAssistance)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.glass")}:</td>
            <td>
              {tBoolean(mtplData.coverages.glass)}{" "}
              {mtplData.coverages.glassAmount ? (
                <>({formatIntegerLocaleCurrency(mtplData.coverages.glassAmount)})</>
              ) : undefined}
            </td>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.animal")}:</td>
            <td>{tBoolean(mtplData.coverages.animal)}</td>
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.element")}:</td>
            <td>{tBoolean(mtplData.coverages.element)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.injury")}:</td>
            <td>{tBoolean(mtplData.coverages.injury)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.theftAndVandalism")}:</td>
            <td>{tBoolean(mtplData.coverages.theftAndVandalism)}</td>
          </tr>
        </>
      );
      break;
    case InsuranceType.CRASH:
      const crashData = insuranceData as CrashInsuranceData;
      insuranceDataView = (
        <>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.price")}:</td>
            <td>{formatLocaleCurrency(crashData.price)}</td>
            <td>{t("contract.attrs.insurances.complicity")}:</td>
            <td>{insurance.complicity?.value}</td>
            <td>{t("calc.vehicle.enums.vehiclePurpose._label")}:</td>
            <td>{t("calc.vehicle.enums.vehiclePurpose." + insuranceData.purpose)}</td>
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.leasing")}:</td>
            <td>{tBoolean(insuranceData.leasing)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.extendedAssistance")}:</td>
            <td>{tBoolean(crashData.coverages.extendedAssistance)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.glass")}:</td>
            <td>{tBoolean(crashData.coverages.glass)}</td>
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.replacementVehicle")}:</td>
            <td>{tBoolean(crashData.coverages.replacementVehicle)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.abroadVehicleRepair")}:</td>
            <td>{tBoolean(crashData.coverages.abroadVehicleRepair)}</td>
            <td colSpan={2} />
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.gap")}:</td>
            <td>
              {tBoolean(crashData.coverages.gap)}{" "}
              {crashData.coverages.gapDuration ? (
                <>({t("calc.vehicle.enums.gapDuration." + crashData.coverages.gapDuration)})</>
              ) : undefined}
            </td>
            <td>{t("contract.attrs.insurances.insuranceData.coverages.gapComplicityReinsurance")}:</td>
            <td>{tBoolean(crashData.coverages.gapComplicityReinsurance)}</td>
            <td colSpan={2} />
          </tr>
          {crashData.mtpl && (
            <>
              <tr>
                <td colSpan={6}>
                  <b>{t("contract.attrs.insurances.insuranceData.mtpl._label")}</b>
                </td>
              </tr>

              <tr>
                <td>{t("contract.attrs.insurances.coverageLimit")}:</td>
                <td>{formatCoverageLimit(insurance.coverageLimit)}</td>
                <td>{t("contract.attrs.insurances.insuranceData.mtpl.effectiveBeginningDate")}:</td>
                <td>{formatLocaleDate(crashData.mtpl.effectiveBeginningDate)}</td>
                <td colSpan={2} />
              </tr>
            </>
          )}
        </>
      );
      break;
    case InsuranceType.GAP:
      const gapData = insuranceData as GapInsuranceData;
      insuranceDataView = (
        <>
          <tr>
            <td>{t("calc.vehicle.enums.gapDuration._label")}:</td>
            <td>{t("calc.vehicle.enums.gapDuration." + gapData.duration)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.buyingPrice")}:</td>
            <td>{formatLocaleCurrency(gapData.buyingPrice)}</td>
            <td>{t("calc.vehicle.enums.vehiclePurpose._label")}:</td>
            <td>{t("calc.vehicle.enums.vehiclePurpose." + insuranceData.purpose)}</td>
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.insuranceData.leasing")}:</td>
            <td>{tBoolean(insuranceData.leasing)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.complicityReinsurance")}:</td>
            <td>{tBoolean(gapData.complicityReinsurance)}</td>
            <td colSpan={2} />
          </tr>
        </>
      );
      break;
    case InsuranceType.PAS:
      insuranceDataView = (
        <tr>
          <td>{t("calc.vehicle.enums.vehiclePurpose._label")}:</td>
          <td>{t("calc.vehicle.enums.vehiclePurpose." + insuranceData.purpose)}</td>
          <td>{t("contract.attrs.insurances.insuranceData.leasing")}:</td>
          <td>{tBoolean(insuranceData.leasing)}</td>
          <td colSpan={2} />
        </tr>
      );
      break;
  }

  return (
    <>
      <table className="data-table-view">
        <tbody>
          {multipleInsurances && (
            <>
              <tr>
                <td>{t("contract.attrs.insurances.contractEntryDate")}:</td>
                <td>{formatLocaleDate(insurance.contractEntryDate)}</td>
                <td>{t("contract.attrs.insurances.annualPremium")}:</td>
                <td className="right-align">{formatLocaleCurrency(insurance.annualPremium)}</td>
                <td colSpan={2} />
              </tr>
              <tr>
                <td>{t("contract.attrs.insurances.contractWithdrawalDate")}:</td>
                <td>{formatLocaleDate(insurance.contractWithdrawalDate)}</td>
                <td>{t("contract.attrs.insurances.partialPremium")}:</td>
                <td className="right-align">{formatLocaleCurrency(insurance.partialPremium)}</td>
                <td colSpan={2} />
              </tr>
            </>
          )}
          <tr>
            <td colSpan={6}>
              <b>{t("contract.attrs.insurances.vehicle._label")}</b>
            </td>
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.vehicle.modelId")}:</td>
            <td>
              {parseVehicleModelName({
                customModel: vehicle.customModel,
                customBrand: vehicle.customBrand,
                modelName: vehicle.model.name,
                brandName: vehicle.model.brand.name
              })}
            </td>
            <td>{t("contract.attrs.insurances.licensePlate")}:</td>
            <td>{formatLicensePlate(insurance.licensePlate)}</td>
            <td>{t("contract.attrs.insurances.vehicle.vin")}:</td>
            <td>{vehicle.vin}</td>
          </tr>
          <tr>
            <td>{t("contract.enums.vehicleCategory._label")}:</td>
            <td>{t("contract.enums.vehicleCategory." + vehicle.category)}</td>
            <td>{t("contract.attrs.insurances.vehicle.firstRegistrationDate")}:</td>
            <td>{formatLocaleDate(vehicle.firstRegistrationDate)}</td>
            <td>{t("contract.attrs.insurances.insuranceData.registrationCertificateNumber")}:</td>
            <td>{insuranceData.registrationCertificateNumber}</td>
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.vehicle.engineDisplacement")}:</td>
            <td>
              {isDefinedValue(vehicle.engineDisplacement) ? (
                <>
                  {formatLocaleNumber(vehicle.engineDisplacement)} cm<sup>3</sup>
                </>
              ) : undefined}
            </td>
            <td>{t("contract.attrs.insurances.vehicle.enginePower")}:</td>
            <td>
              {isDefinedValue(vehicle.enginePower) ? (
                <>
                  {formatLocaleNumber(vehicle.enginePower)} {t("common.sign.enginePower")}
                </>
              ) : undefined}
            </td>
            <td>{t("contract.enums.fuelType._label")}:</td>
            <td>{vehicle.fuelType ? <>{t("contract.enums.fuelType." + vehicle.fuelType)}</> : undefined}</td>
          </tr>
          <tr>
            <td>{t("contract.enums.transmission._label")}:</td>
            <td>
              {vehicle.transmission ? <>{t("contract.enums.transmission." + vehicle.transmission)}</> : undefined}
            </td>
            <td>{t("contract.enums.bodywork._label")}:</td>
            <td>{vehicle.bodywork ? <>{t("contract.enums.bodywork." + vehicle.bodywork)}</> : undefined}</td>
            <td>{t("contract.attrs.insurances.vehicle.totalWeight")}:</td>
            <td>
              {formatLocaleNumber(vehicle.totalWeight)} {t("common.sign.weight")}
            </td>
          </tr>
          <tr>
            <td>{t("contract.attrs.insurances.vehicle.seatsNumber")}:</td>
            <td>{vehicle.seatsNumber}</td>
            <td>{t("contract.attrs.insurances.vehicle.doorsNumber")}:</td>
            <td>{vehicle.doorsNumber}</td>
            <td>{t("contract.attrs.insurances.vehicle.colorId")}:</td>
            <td>{vehicle.color.value}</td>
          </tr>
          <tr>
            <td colSpan={6}>
              <b>{t("contract.sections.vehicleInsuranceData")}</b>
            </td>
          </tr>
          {insuranceDataView}
        </tbody>
      </table>

      {multipleClients && (
        <ClientsListDrawerView
          className="margin-top-medium"
          clientsData={[
            {
              client: contract.clients[insurance.vehicleHolderIndex] as Client,
              clientFormTypes: [ClientFormType.HOLDER]
            },
            {
              client: contract.clients[insurance.vehicleOwnerIndex] as Client,
              clientFormTypes: [ClientFormType.OWNER]
            }
          ]}
        />
      )}
    </>
  );
};

export default VehicleInsuranceView;
