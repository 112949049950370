import { Button, Card, Col, Empty, Input, Row } from "antd";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { validationConstants, validationFunctions } from "../../../../../common/utils/validationUtils";
import { selectHasPermissions } from "../../../../auth/ducks";
import { requests } from "../../../api";
import {
  deleteDashboardContactActions,
  searchDashboardContactsActions,
  selectDashboardContactsSearchResult
} from "../../../ducks";
import { DashboardContactType } from "../../../enums";
import { DashboardContact, DashboardContactSearchResult } from "../../../types";
import { DashboardContactForm } from "../../forms";
import { ExternalDashboardContactView, InternalDashboardContactView } from "./components";

export const DashboardContactsWidget = () => {
  const { t } = useTranslation();

  const contactsSearchResult = useSelector<RootState, DashboardContactSearchResult>(
    selectDashboardContactsSearchResult
  );
  const hasAdminDashboardPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.ADMIN_DASHBOARD)(state)
  );

  const [searchValue, setSearchValue] = useState<string>();
  const [contactFormOpen, setContactFormOpen] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<DashboardContact>();

  const dispatch = useDispatch();
  const { onSearch, onDelete } = useMemo(
    () =>
      bindActionCreators(
        {
          onSearch: searchDashboardContactsActions.request,
          onDelete: deleteDashboardContactActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const inProgress = useRequestFinishedCallback([requests.SEARCH_DASHBOARD_CONTACTS]);

  useEffect(() => {
    setSearchValue(contactsSearchResult.keyword);
  }, [contactsSearchResult]);

  const debouncedSearch = useMemo(
    () =>
      debounce((keyword: string): void => {
        if (validationFunctions.validateSearchKeyword(keyword)) {
          onSearch({ keyword: keyword || undefined });
        } else {
          messageUtils.errorMessage(
            t("validation.size", {
              min: validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
              max: validationConstants.SEARCH_KEYWORD_MAX_LENGTH
            })
          );
        }
      }, 500),
    [onSearch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleUpdateClick = (contact: DashboardContact): void => {
    setSelectedContact(contact);
    setContactFormOpen(true);
  };

  const handleContactFormCancel = (): void => {
    setSelectedContact(undefined);
    setContactFormOpen(false);
  };

  const handleSearch = useCallback(
    (value: string): void => {
      setSearchValue(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  return (
    <Card
      className="card-box"
      style={{ height: 380 }}
      size="small"
      title={t("dashboard.contacts.card")}
      loading={inProgress}
      extra={
        <>
          <Input.Search
            size="small"
            style={{ width: 250 }}
            enterButton
            allowClear
            placeholder={t("common.search")}
            value={searchValue}
            onSearch={handleSearch}
            onChange={event => handleSearch(event.currentTarget.value)}
          />
          {hasAdminDashboardPermission && (
            <Button size="small" type="primary" icon={<AntIcon type="plus" />} onClick={() => setContactFormOpen(true)}>
              {t("common.add")}
            </Button>
          )}
        </>
      }
    >
      {contactsSearchResult.data.length ? (
        <Row gutter={rowGutter} style={{ maxHeight: 320, overflowY: "auto" }}>
          {contactsSearchResult.data.map(contact => (
            <Col span={8} xxl={6} key={contact.id} style={{ marginBottom: 16 }}>
              {contact.type === DashboardContactType.INTERNAL ? (
                <InternalDashboardContactView
                  contact={contact}
                  showActions={hasAdminDashboardPermission}
                  onUpdate={handleUpdateClick}
                  onDelete={onDelete}
                />
              ) : (
                <ExternalDashboardContactView
                  contact={contact}
                  showActions={hasAdminDashboardPermission}
                  onUpdate={handleUpdateClick}
                  onDelete={onDelete}
                />
              )}
            </Col>
          ))}
        </Row>
      ) : (
        <Empty style={{ marginTop: 80 }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      <DashboardContactForm contact={selectedContact} open={contactFormOpen} onFormCancel={handleContactFormCancel} />
    </Card>
  );
};
