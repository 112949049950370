import { Tabs } from "antd";
import { Search } from "history";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import { PageSizes } from "../../../common/constants";
import { ExportFileType } from "../../../common/enums";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { RootState, SearchPageRequest, SearchPageResult } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import ProductForm from "../components/forms/ProductForm";
import ProductGroupForm from "../components/forms/ProductGroupForm";
import ProductFilterView from "../components/views/ProductFilterView";
import ProductGroupFilterView from "../components/views/ProductGroupFilterView";
import ProductGroupTableView from "../components/views/ProductGroupTableView";
import ProductTableView from "../components/views/ProductTableView";
import {
  createProductActions,
  createProductGroupActions,
  deleteProductActions,
  deleteProductGroupActions,
  deleteStateProductGroupsPageAction,
  deleteStateProductsPageAction,
  downloadProductsExportActions,
  filterProductGroupsActions,
  filterProductsActions,
  selectProductGroupsPage,
  selectProductsPage,
  updateProductActions,
  updateProductGroupActions
} from "../ducks";
import { ProductFinancialSector } from "../enums";
import { Product, ProductFilterPageRequest, ProductFilterPageResult, ProductGroup } from "../types";

const TAB = {
  PRODUCT_GROUPS: "product-groups",
  PRODUCTS: "products"
};

export const ProductsContainer = () => {
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState<string>(TAB.PRODUCTS);

  const [productGroupFormOpen, setProductGroupFormOpen] = useState<boolean>(false);
  const [productGroupToUpdate, setProductGroupToUpdate] = useState<ProductGroup>();

  const [productFormOpen, setProductFormOpen] = useState<boolean>(false);
  const [productToUpdate, setProductToUpdate] = useState<Product>();

  const productGroupsPage = useSelector<RootState, SearchPageResult<ProductGroup>>(selectProductGroupsPage);
  const productsPage = useSelector<RootState, ProductFilterPageResult>(selectProductsPage);
  const urlSearchQuery = useSelector<RootState, Search>(selectRouterLocationSearch);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          filterProductGroups: filterProductGroupsActions.request,
          createProductGroup: createProductGroupActions.request,
          updateProductGroup: updateProductGroupActions.request,
          deleteProductGroup: deleteProductGroupActions.request,
          deleteStateProductGroupsPage: deleteStateProductGroupsPageAction,
          filterProducts: filterProductsActions.request,
          downloadProductsExport: downloadProductsExportActions.request,
          createProduct: createProductActions.request,
          updateProduct: updateProductActions.request,
          deleteProduct: deleteProductActions.request,
          deleteStateProductsPage: deleteStateProductsPageAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    const paramTab = urlParams.get("tab") ?? tabKey;
    const tab = Object.values(TAB).includes(paramTab) ? paramTab : TAB.PRODUCTS;

    setTabKey(tab);

    switch (tab) {
      case TAB.PRODUCT_GROUPS:
        actions.filterProductGroups({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: urlParams.get("keyword") ?? undefined
        });
        break;
      case TAB.PRODUCTS:
        actions.filterProducts({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: urlParams.get("keyword") ?? undefined,
          sectors: urlParams.getAll("sectors") as ProductFinancialSector[],
          groupIds: urlParams.getAll("groupIds")
        });
        break;
    }
    return () => {
      actions.deleteStateProductGroupsPage();
      actions.deleteStateProductsPage();
    };
  }, []);

  const handleTabKeyChange = (tab: string): void => {
    navigate(
      appendSearchParamsToURL({
        pageIndex: undefined,
        keyword: undefined,
        sectors: undefined,
        groupIds: undefined,
        tab
      }),
      {
        replace: true
      }
    );

    setTabKey(tab);

    switch (tab) {
      case TAB.PRODUCT_GROUPS:
        actions.filterProductGroups({
          pageIndex: 0,
          pageSize: PageSizes.LARGE,
          keyword: undefined
        });
        break;
      case TAB.PRODUCTS:
        actions.filterProducts({
          pageIndex: 0,
          pageSize: PageSizes.LARGE,
          keyword: undefined,
          sectors: [],
          groupIds: []
        });
        break;
    }
  };

  const handleProductGroupsFilterSubmit = (filter: SearchPageRequest): void => {
    const tabParam = new URLSearchParams(urlSearchQuery).get("tab");
    navigate(
      appendSearchParamsToURL({
        pageIndex: undefined,
        keyword: filter.keyword,
        tab: tabParam ?? undefined
      }),
      { replace: true }
    );

    actions.filterProductGroups({
      pageIndex: 0,
      pageSize: productGroupsPage.pageSize,
      keyword: filter.keyword
    });
  };

  const handleProductGroupsPageChange = (pageNumber: number): void => {
    const { pageSize, keyword } = productGroupsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterProductGroups({ pageIndex: pageNumber - 1, pageSize, keyword });
  };

  const handleProductGroupCreateClick = (): void => {
    setProductGroupFormOpen(true);
  };

  const handleProductGroupUpdateClick = (group: ProductGroup): void => {
    setProductGroupFormOpen(true);
    setProductGroupToUpdate(group);
  };

  const handleProductGroupFormCancel = (): void => {
    setProductGroupFormOpen(false);
    setProductGroupToUpdate(undefined);
  };

  const handleProductsFilterSubmit = (filter: ProductFilterPageRequest): void => {
    const tabParam = new URLSearchParams(urlSearchQuery).get("tab");
    navigate(
      appendSearchParamsToURL({
        ...filter,
        pageIndex: undefined,
        keyword: filter.keyword,
        tab: tabParam ?? undefined
      }),
      { replace: true }
    );

    actions.filterProducts({
      pageIndex: 0,
      pageSize: productsPage.pageSize,
      keyword: filter.keyword,
      sectors: filter.sectors,
      groupIds: filter.groupIds
    });
  };

  const handleProductsPageChange = (pageNumber: number): void => {
    const { pageSize, keyword, sectors, groupIds } = productsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterProducts({
      pageIndex: pageNumber - 1,
      pageSize,
      keyword,
      sectors,
      groupIds
    });
  };

  const handleProductCreateClick = (): void => {
    setProductFormOpen(true);
  };

  const handleProductUpdateClick = (product: Product): void => {
    setProductFormOpen(true);
    setProductToUpdate(product);
  };

  const handleProductFormCancel = (): void => {
    setProductFormOpen(false);
    setProductToUpdate(undefined);
  };

  const handleExport = (exportFileType: ExportFileType): void => {
    const { keyword, sectors, groupIds } = productsPage;

    actions.downloadProductsExport({ keyword, sectors, groupIds, exportFileType });
  };

  return (
    <ContentWrapper>
      <Tabs
        className="tabs-box"
        activeKey={tabKey}
        onChange={handleTabKeyChange}
        items={[
          {
            key: TAB.PRODUCT_GROUPS,
            label: (
              <span>
                <AntIcon type="group" className="margin-right-tiny" />
                {t("product.titles.productGroups")}
              </span>
            ),
            children: (
              <div className="margin-top-small">
                <ProductGroupFilterView
                  currentFilter={productGroupsPage}
                  onFilterSubmit={handleProductGroupsFilterSubmit}
                  onCreateClick={handleProductGroupCreateClick}
                />

                <ProductGroupTableView
                  productGroupsPage={productGroupsPage}
                  onPageChange={handleProductGroupsPageChange}
                  onUpdateClick={handleProductGroupUpdateClick}
                  onDelete={actions.deleteProductGroup}
                />

                <ProductGroupForm
                  open={productGroupFormOpen}
                  productGroup={productGroupToUpdate}
                  onCreate={actions.createProductGroup}
                  onUpdate={actions.updateProductGroup}
                  onFormCancel={handleProductGroupFormCancel}
                />
              </div>
            )
          },
          {
            key: TAB.PRODUCTS,
            label: (
              <span>
                <AntIcon type="unordered-list" className="margin-right-tiny" />
                {t("product.titles.products")}
              </span>
            ),
            children: (
              <div className="margin-top-small">
                <ProductFilterView
                  currentFilter={productsPage}
                  onFilterSubmit={handleProductsFilterSubmit}
                  onCreateClick={handleProductCreateClick}
                />

                <ProductTableView
                  productsPage={productsPage}
                  onPageChange={handleProductsPageChange}
                  onUpdateClick={handleProductUpdateClick}
                  onDelete={actions.deleteProduct}
                  onExport={handleExport}
                />

                <ProductForm
                  open={productFormOpen}
                  product={productToUpdate}
                  onCreate={actions.createProduct}
                  onUpdate={actions.updateProduct}
                  onFormCancel={handleProductFormCancel}
                />
              </div>
            )
          }
        ]}
      />
    </ContentWrapper>
  );
};
