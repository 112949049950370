import { Button, Col, Divider, Form, Modal, Radio, Row, Select } from "antd";
import { FormInstance, FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import groupBy from "lodash/groupBy";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import latinise from "voca/latinise";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { accessTreePathDelimiter, ModalSizes } from "../../../../common/constants";
import { RootState } from "../../../../common/types";
import { formatAgentName } from "../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import type { UUID } from "../../../../typings/global";
import { selectAllAgentsEnums, selectSubordinateAgentsEnums } from "../../ducks";
import { AgentEnumeration, FilterFunction } from "../../types";
import styles from "./AgentSelect.module.scss";

type AgentSelectValue = UUID | UUID[];

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<AgentSelectValue>;
  optionsProps?: AgentOptionsProps;
}

interface AgentOptionsProps {
  useAllAgents?: boolean;
  selected?: AgentEnumeration;
  groupByBulkPaymentInclusion?: boolean;
  filter?: FilterFunction<AgentEnumeration>;
  onChange?: (agent: AgentEnumeration) => void;
  extendedFilter?: {
    form: FormInstance;
  };
}

interface AgentSelectOptionRenderProps {
  agentName?: string;
  representatives?: string;
}

const AgentSelectOptionRender = ({ agentName, representatives }: AgentSelectOptionRenderProps) => (
  <>
    <div>{agentName}</div>
    {representatives && <Ellipsis className={styles.selectOptionSecondaryRow}>{representatives}</Ellipsis>}
  </>
);

type AgentsGroupedByBulkPaymentInclusion = { includedAgents: AgentEnumeration[]; excludedAgents: AgentEnumeration[] };

const getAgentsGroupedByBulkPaymentInclusion = (
  resolvedAgentOptions: AgentEnumeration[]
): AgentsGroupedByBulkPaymentInclusion => {
  const groups = groupBy(resolvedAgentOptions, agent => agent.includeInCommissionsBulkPayment);

  return {
    includedAgents: groups["true"] ?? [],
    excludedAgents: groups["false"] ?? []
  };
};

const getAgentsOptionsGroupedByBulkPaymentInclusion = ({
  includedAgents,
  excludedAgents
}: AgentsGroupedByBulkPaymentInclusion) => (
  <>
    {includedAgents?.length ? (
      <Select.OptGroup key={1} label={t("agent.helpers.includedInBulkPayment")}>
        {includedAgents.map(agent => {
          const agentName = formatAgentName(agent);
          const representatives = agent.representatives?.map(r => formatAgentName(r, false)).join(", ");
          return (
            <Select.Option key={agent.id} value={agent.id} label={agentName} representatives={representatives}>
              <AgentSelectOptionRender agentName={agentName} representatives={representatives} />
            </Select.Option>
          );
        })}
      </Select.OptGroup>
    ) : undefined}
    {excludedAgents?.length ? (
      <Select.OptGroup key={2} label={t("agent.helpers.excludedFromBulkPayment")}>
        {excludedAgents.map(agent => {
          const agentName = formatAgentName(agent);
          const representatives = agent.representatives?.map(r => formatAgentName(r, false)).join(", ");
          return (
            <Select.Option key={agent.id} value={agent.id} label={agentName} representatives={representatives}>
              <AgentSelectOptionRender agentName={agentName} representatives={representatives} />
            </Select.Option>
          );
        })}
      </Select.OptGroup>
    ) : undefined}
  </>
);

enum AGENT_FILTER_TYPE {
  DIRECT_SUBORDINATES,
  ALL_SUBORDINATES
}

const AgentSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {
  const subordinateAgentsEnums = useSelector<RootState, AgentEnumeration[]>(selectSubordinateAgentsEnums);
  const allAgentsEnums = useSelector<RootState, AgentEnumeration[]>(selectAllAgentsEnums);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agentFilterType, setAgentFilterType] = useState<AGENT_FILTER_TYPE>(AGENT_FILTER_TYPE.DIRECT_SUBORDINATES);
  const [selectedAgentIdsFromFilter, setSelectedAgentIdsFromFilter] = useState<UUID[]>([]);
  const [processedAgentIdsFromFilter, setProcessedAgentIdsFromFilter] = useState<UUID[]>([]);

  const resolvedAgentOptions: AgentEnumeration[] = useMemo(() => {
    let options = optionsProps?.useAllAgents ? [...allAgentsEnums] : [...subordinateAgentsEnums];

    if (!optionsProps) {
      return options;
    }

    if (optionsProps.filter) {
      options = options.filter(optionsProps.filter);
    }

    if (optionsProps.selected && !options.some(agent => agent.id === optionsProps?.selected?.id)) {
      options.unshift(optionsProps.selected);
    }

    return options;
  }, [subordinateAgentsEnums, allAgentsEnums, optionsProps]);

  const { includedAgents, excludedAgents } = getAgentsGroupedByBulkPaymentInclusion(resolvedAgentOptions);
  const agentsList = optionsProps?.groupByBulkPaymentInclusion
    ? [...includedAgents, ...excludedAgents]
    : resolvedAgentOptions;

  const renderedAgentOptions = optionsProps?.groupByBulkPaymentInclusion
    ? getAgentsOptionsGroupedByBulkPaymentInclusion({ includedAgents, excludedAgents })
    : resolvedAgentOptions.map(agent => {
        const agentName = formatAgentName(agent);
        const representatives = agent.representatives?.map(r => formatAgentName(r, false)).join(", ");
        return (
          <Select.Option key={agent.id} value={agent.id} label={agentName} representatives={representatives}>
            <AgentSelectOptionRender agentName={agentName} representatives={representatives} />
          </Select.Option>
        );
      });

  const handleOptionFilter = (input: string, option?: Record<string, string>): boolean => {
    if (!option) {
      return false;
    }

    return (
      option &&
      latinise((option["label"] + (option["representatives"] || "")).toLowerCase()).indexOf(
        latinise(input.toLowerCase())
      ) !== -1
    );
  };

  const onChange = (selectedValue: AgentSelectValue, option: any): void => {
    selectProps?.onChange?.(selectedValue, option);

    if (optionsProps && typeof selectedValue === "string") {
      const result = (optionsProps.useAllAgents ? allAgentsEnums : subordinateAgentsEnums).find(
        agent => agent.id === selectedValue
      );

      if (result) {
        optionsProps.onChange?.(result);
      }
    }
  };

  const appendAgentsToForm = (processedAgentIdsFromFilter: UUID[]) => {
    const namePath = formItemProps.name;
    if (namePath) {
      const currentSelectValues = optionsProps?.extendedFilter?.form.getFieldValue(namePath);

      optionsProps?.extendedFilter?.form.setFieldValue(namePath, [
        ...new Set([...currentSelectValues, ...processedAgentIdsFromFilter])
      ]);
    }

    setIsModalOpen(false);
  };

  const processAgentsFromFilter = (
    agentIdsFromFilter: UUID[],
    agentFilterType: AGENT_FILTER_TYPE,
    agentsList: AgentEnumeration[]
  ) => {
    const accessTreePathsForSelectedAgents = agentsList
      .filter(agent => agentIdsFromFilter.some(agentId => agentId === agent.id))
      .map(agent => agent.accessTreePath);

    switch (agentFilterType) {
      case AGENT_FILTER_TYPE.DIRECT_SUBORDINATES:
        const directSubordinatesAgentIds = agentsList
          .filter(agent =>
            accessTreePathsForSelectedAgents.some(
              agentAccessTreePath =>
                agentAccessTreePath &&
                agent.accessTreePath?.startsWith(agentAccessTreePath) &&
                agent.accessTreePath.split(accessTreePathDelimiter).length <
                  agentAccessTreePath.split(accessTreePathDelimiter).length + 2
            )
          )
          .map(agent => agent.id);

        setProcessedAgentIdsFromFilter(directSubordinatesAgentIds);
        break;

      case AGENT_FILTER_TYPE.ALL_SUBORDINATES:
        const allSubordinatesAgentIds = agentsList
          .filter(agent =>
            accessTreePathsForSelectedAgents.some(
              agentAccessTreePath => agentAccessTreePath && agent.accessTreePath?.startsWith(agentAccessTreePath)
            )
          )
          .map(agent => agent.id);

        setProcessedAgentIdsFromFilter(allSubordinatesAgentIds);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Form.Item {...formItemProps}>
        <Select<AgentSelectValue>
          {...selectStandardProps}
          {...selectProps}
          dropdownRender={menu => (
            <>
              {menu}
              {optionsProps?.extendedFilter && selectProps?.mode === "multiple" ? (
                <>
                  <Divider style={{ margin: "4px 0" }} />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="text" icon={<AntIcon type="zoom" />} onClick={() => setIsModalOpen(true)}>
                      {t("agent.filter.extendedFilter")}
                    </Button>
                  </div>
                </>
              ) : undefined}
            </>
          )}
          filterOption={handleOptionFilter}
          onChange={onChange}
          optionLabelProp="label"
        >
          {renderedAgentOptions}
        </Select>
      </Form.Item>

      <Modal
        open={isModalOpen}
        maskClosable={false}
        onCancel={() => setIsModalOpen(false)}
        width={ModalSizes.MEDIUM}
        title={t("agent.filter.extendedFilter")}
        okText={t("common.select")}
        cancelText={t("common.cancel")}
        onOk={() => appendAgentsToForm(processedAgentIdsFromFilter)}
        afterClose={() => {
          setAgentFilterType(AGENT_FILTER_TYPE.DIRECT_SUBORDINATES);
          setSelectedAgentIdsFromFilter([]);
          setProcessedAgentIdsFromFilter([]);
        }}
        wrapClassName={styles.modalOverDropdown}
      >
        <Row>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Radio.Group
              value={agentFilterType}
              onChange={event => {
                const filterType = event.target.value as AGENT_FILTER_TYPE;
                setAgentFilterType(filterType);
                processAgentsFromFilter(selectedAgentIdsFromFilter, filterType, agentsList);
              }}
              size="large"
            >
              <Radio.Button value={AGENT_FILTER_TYPE.DIRECT_SUBORDINATES}>
                {t("agent.filter.directSubordinates")}
              </Radio.Button>
              <Radio.Button value={AGENT_FILTER_TYPE.ALL_SUBORDINATES}>
                {t("agent.filter.allSubordinates")}
              </Radio.Button>
            </Radio.Group>
          </Col>

          <Col span={24} style={{ marginTop: "25px" }}>
            <Select<UUID[]>
              {...selectStandardProps}
              style={{ width: "100%" }}
              dropdownStyle={{ zIndex: "1052" }}
              maxTagCount="responsive"
              mode="multiple"
              optionLabelProp="label"
              onChange={agentIds => {
                setSelectedAgentIdsFromFilter(agentIds);
                processAgentsFromFilter(agentIds, agentFilterType, agentsList);
              }}
              allowClear
              value={selectedAgentIdsFromFilter}
            >
              {renderedAgentOptions}
            </Select>
          </Col>

          {processedAgentIdsFromFilter.length ? (
            <Col span={24} className="margin-top-small">
              {t("agent.filter.selectedAgentsCount")}: {processedAgentIdsFromFilter.length}
            </Col>
          ) : undefined}
        </Row>
      </Modal>
    </>
  );
};

export default AgentSelect;
