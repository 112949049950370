import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  dateToIsoDateString,
  disableDatePickerOutOfMinDate,
  inputNumberDecimalStandardProps,
  resolveFormValidationError,
  selectStandardProps,
  toDate,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { Agent } from "../../../../agent/types";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import { requests } from "../../api";
import { createSpecialCommissionsRuleActions, updateSpecialCommissionsRuleActions } from "../../ducks";
import { SpecialCommissionsSettingsRuleType } from "../../enums";
import { CreateUpdateSpecialCommissionsSettingsRule, SpecialCommissionsSettingsRule } from "../../types";

interface Props {
  open: boolean;
  rule?: SpecialCommissionsSettingsRule;
  agent: Agent;
  onCreate: typeof createSpecialCommissionsRuleActions.request;
  onUpdate: typeof updateSpecialCommissionsRuleActions.request;
  onFormCancel: () => void;
}

type GetDateDataType = {
  type: SpecialCommissionsSettingsRuleType;
  startDate?: string;
  endDate?: string;
};

const getCommissionSettingsRuleDate = (
  configuration: "startDate" | "endDate",
  { type, startDate, endDate }: GetDateDataType
) => {
  const isOneTime = type === SpecialCommissionsSettingsRuleType.ONE_TIME;
  const MONTH = "month";

  if (configuration === "startDate") {
    if (isOneTime && startDate) {
      return dateToIsoDateString(dayjs.utc(startDate).startOf(MONTH));
    }

    return startDate;
  } else {
    if (isOneTime && startDate) {
      return dateToIsoDateString(dayjs.utc(startDate).endOf(MONTH));
    }

    return endDate;
  }
};

const SpecialCommissionsSettingsRuleForm = ({ open, rule, agent, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateSpecialCommissionsSettingsRule>();
  useFormErrorHandler(form, "commissions.special.attrs", [
    requests.CREATE_SPECIAL_COMMISSIONS_RULE,
    requests.UPDATE_SPECIAL_COMMISSIONS_RULE
  ]);

  useEffect(() => {
    if (open && rule) {
      form.setFieldsValue({
        ...rule,
        nettoPointsTargetAgentId: rule.nettoPointsTargetAgent?.id,
        payerId: rule.payer.id
      });
    }
  }, [rule, open, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_SPECIAL_COMMISSIONS_RULE, requests.UPDATE_SPECIAL_COMMISSIONS_RULE],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        const processedValues = {
          ...values,
          startDate: getCommissionSettingsRuleDate("startDate", {
            type: values.type,
            startDate: values.startDate,
            endDate: values.endDate
          }),
          endDate: getCommissionSettingsRuleDate("endDate", {
            type: values.type,
            startDate: values.startDate,
            endDate: values.endDate
          })
        };
        if (rule) {
          onUpdate({ id1: agent.id, id2: rule.id, object: processedValues });
        } else {
          onCreate({ id: agent.id, object: processedValues });
        }
      })
      .catch(resolveFormValidationError);
  };

  const colSpanSmall = 4;
  const colSpan = 6;
  const colSpanBig = 8;
  const colSpanHuge = 20;

  const specialCommissionsSettingsRuleType = Form.useWatch("type", form);
  const startDate = Form.useWatch("startDate", form);
  const endDate = Form.useWatch("endDate", form);

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={open}
      title={rule ? t("commissions.special.titles.updateRule") : t("commissions.special.titles.createRule")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="specialCommissionsSettingsRuleForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpanSmall}>
            <Form.Item
              name="code"
              label={t("commissions.special.attrs.code")}
              rules={[validations.notBlank, validations.size(1, 16)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpanHuge}>
            <Form.Item
              name="name"
              label={t("commissions.special.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpanBig}>
            <Form.Item name="type" label={t("commissions.special.enums.ruleType._label")} rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={Object.keys(SpecialCommissionsSettingsRuleType).map(type => ({
                  value: type,
                  label: t("commissions.special.enums.ruleType." + type)
                }))}
              />
            </Form.Item>
          </Col>

          {specialCommissionsSettingsRuleType && (
            <>
              {specialCommissionsSettingsRuleType === SpecialCommissionsSettingsRuleType.ONE_TIME ? (
                <Col span={colSpan}>
                  <Form.Item
                    name="startDate"
                    label={t("commissions.special.helpers.oneTimeRuleDate")}
                    rules={[validations.notNull]}
                    {...datePickerFormItemProps}
                  >
                    <DatePicker format="MMMM YYYY" placeholder={undefined} allowClear={false} picker="month" />
                  </Form.Item>
                </Col>
              ) : (
                <>
                  <Col span={colSpan}>
                    <Form.Item
                      name="startDate"
                      label={t("commissions.special.attrs.startDate")}
                      rules={[validations.none]}
                      {...datePickerFormItemProps}
                    >
                      <DatePicker {...datePickerClearableProps} />
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name="endDate"
                      label={t("commissions.special.attrs.endDate")}
                      rules={[
                        startDate
                          ? validations.notBefore(startDate, t("commissions.special.attrs.startDate"))
                          : validations.none
                      ]}
                      {...datePickerFormItemProps}
                    >
                      <DatePicker
                        {...datePickerClearableProps}
                        defaultPickerValue={toDate(endDate || startDate)}
                        disabledDate={current =>
                          startDate ? disableDatePickerOutOfMinDate(current, startDate) : false
                        }
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={colSpanSmall}>
                <Form.Item
                  name="suspended"
                  rules={[validations.none]}
                  className="form-item-without-label"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox>{t("commissions.special.attrs.suspended")}</Checkbox>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpanBig}>
            <AgentSelect
              formItemProps={{
                name: "payerId",
                label: t("commissions.special.attrs.payerId"),
                rules: [validations.notNull]
              }}
              optionsProps={{
                selected: rule?.payer,
                filter: a => agent.id !== a.id
              }}
            />
          </Col>

          {specialCommissionsSettingsRuleType &&
            (specialCommissionsSettingsRuleType === SpecialCommissionsSettingsRuleType.NETTO_POINTS_RATE ? (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name="nettoPointsRate"
                    label={t("commissions.special.attrs.nettoPointsRate")}
                    rules={[validations.notNull, validations.minNumber(0.01), validations.maxNumber(100)]}
                  >
                    <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="percentage" />} />
                  </Form.Item>
                </Col>

                <Col span={colSpanBig}>
                  <AgentSelect
                    formItemProps={{
                      name: "nettoPointsTargetAgentId",
                      label: t("commissions.special.attrs.nettoPointsTargetAgentId"),
                      rules: [validations.notNull]
                    }}
                  />
                </Col>
              </>
            ) : (
              <Col span={colSpan}>
                <Form.Item
                  name="amount"
                  label={t("commissions.special.attrs.amount")}
                  rules={[validations.notNull, validations.minNumber(0.01)]}
                >
                  <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
                </Form.Item>
              </Col>
            ))}
        </Row>
      </Form>
    </Modal>
  );
};

export default SpecialCommissionsSettingsRuleForm;
