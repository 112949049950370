import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { RootState } from "../../../common/types";
import { openBlobFile } from "../../../common/utils/utils";
import api from "./api";
import {
  PointsReducerState,
  PointsReportFilterExportRequest,
  PointsReportFilterRequest,
  PointsReportFilterResult
} from "./types";

/**
 * ACTIONS
 */
export const filterPointsReportActions = createAsyncAction(
  "points-report/FILTER_REQUEST",
  "points-report/FILTER_SUCCESS",
  "points-report/FILTER_FAILURE"
)<PointsReportFilterRequest, PointsReportFilterResult, void>();

export const downloadPointsReportExportActions = createAsyncAction(
  "points-report/EXPORT_REQUEST",
  "points-report/EXPORT_SUCCESS",
  "points-report/EXPORT_FAILURE"
)<PointsReportFilterExportRequest, void, void>();

export const deleteStatePointsReportAction = createAction("points-report/DELETE_STATE_REPORT")<void>();

const actions = {
  filterPointsReportActions,
  downloadPointsReportExportActions,
  deleteStatePointsReportAction
};

export type PointsAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: PointsReducerState = {
  report: {
    data: [],
    periodStartDate: undefined,
    periodEndDate: undefined,
    institutionIds: [],
    productIds: [],
    commissionsSettingsLevelIds: [],
    agentCreatedAtDateMin: undefined,
    reportType: undefined,
    rootAgentId: undefined,
    onlyDirectSubordinates: false,
    includeDeactivated: false,
    includeRepresentatives: false,
    includeNonGainers: false,
    includeWithoutPoints: false
  }
};

const pointsReportReducer = createReducer(initialState.report)
  .handleAction(filterPointsReportActions.success, (_, { payload }) => payload)
  .handleAction([filterPointsReportActions.failure, deleteStatePointsReportAction], () => initialState.report);

export const pointsReducer = combineReducers<PointsReducerState>({
  report: pointsReportReducer
});

/**
 * SELECTORS
 */
const selectPoints = (state: RootState): PointsReducerState => state.commissions.points;

export const selectPointsReport = (state: RootState): PointsReportFilterResult => selectPoints(state).report;

/**
 * SAGAS
 */
function* filterPointsReport({ payload }: ReturnType<typeof filterPointsReportActions.request>) {
  try {
    const response: AxiosResponse<PointsReportFilterResult> = yield call(api.filterPointsReport, payload);
    yield put(filterPointsReportActions.success(response.data));
  } catch {
    yield put(filterPointsReportActions.failure());
  }
}

function* downloadPointsReportExport({ payload }: ReturnType<typeof downloadPointsReportExportActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadPointsReportExport, payload);
    openBlobFile(response, true);
    yield put(downloadPointsReportExportActions.success());
  } catch {
    yield put(downloadPointsReportExportActions.failure());
  }
}

export function* pointsSaga() {
  yield takeLatest(filterPointsReportActions.request, filterPointsReport);
  yield takeLatest(downloadPointsReportExportActions.request, downloadPointsReportExport);
}
