import { Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  inputNumberIntegerStandardProps,
  resolveFormValidationError,
  selectTagsStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import CommissionsLevelSelect from "../../../../enumerations/components/form/CommissionsLevelSelect";
import { requests } from "../../api";
import { createCommissionsLevelActions, updateCommissionsLevelActions } from "../../ducks";
import { CommissionsSettingsLevelType, commissionsSettingsLevelTypeTMap } from "../../enums";
import { CommissionsSettingsLevel, CreateUpdateCommissionsSettingsLevel } from "../../types";
import CommissionsLevelTypeTag from "../CommissionsLevelTypeTag";

interface Props {
  open: boolean;
  level?: CommissionsSettingsLevel;
  onCreate?: typeof createCommissionsLevelActions.request;
  onUpdate?: typeof updateCommissionsLevelActions.request;
  onFormCancel: () => void;
}

const CommissionsLevelForm = ({ open, level, onCreate, onUpdate, onFormCancel }: Props) => {
  const colSpan = 6;

  const [form] = Form.useForm<CreateUpdateCommissionsSettingsLevel>();
  useFormErrorHandler(form, "commissions.level.attrs", [
    requests.CREATE_COMMISSIONS_LEVEL,
    requests.UPDATE_COMMISSIONS_LEVEL
  ]);

  useEffect(() => {
    if (open && level) {
      form.setFieldsValue({
        ...level,
        includeGroupPointsIntoPromotion: !!level.includeGroupPointsIntoPromotion,
        nextLevelIds: level.nextLevels?.map(nextLevel => nextLevel.id),
        nextLevels: undefined,
        formerLevels: undefined,
        rules: undefined,
        bailAccountSettings: undefined
      } as CreateUpdateCommissionsSettingsLevel);
    }
  }, [open, level, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_COMMISSIONS_LEVEL, requests.UPDATE_COMMISSIONS_LEVEL],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (level) {
          onUpdate?.({ id: level.id, object: values });
        } else {
          onCreate?.(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={open}
      title={level ? t("commissions.level.titles.updateLevel") : t("commissions.level.titles.createLevel")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="commissionsSettingsLevelForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item name="type" label={t("commissions.level.enums.type._label")} rules={[validations.notNull]}>
              <Select
                {...selectTagsStandardProps(commissionsSettingsLevelTypeTMap)}
                options={Object.keys(CommissionsSettingsLevelType).map(type => ({
                  value: type,
                  label: (
                    <CommissionsLevelTypeTag
                      type={CommissionsSettingsLevelType[type as keyof typeof CommissionsSettingsLevelType]}
                    />
                  )
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item name="category" label={t("commissions.level.attrs.category")} rules={[validations.size(1, 255)]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="code"
              label={t("commissions.level.attrs.code")}
              rules={[validations.notNull, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="name"
              label={t("commissions.level.attrs.name")}
              rules={[validations.notNull, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
          {({ getFieldValue }) =>
            getFieldValue("type") === CommissionsSettingsLevelType.CAREER && (
              <>
                <Row gutter={rowGutter}>
                  <Col span={colSpan}>
                    <Form.Item
                      name="careerPromotionPoints"
                      label={
                        <LabelWithTooltip
                          label={t("commissions.level.attrs.careerPromotionPoints")}
                          tooltip={t("commissions.level.helpers.careerPromotionPointsDesc")}
                        />
                      }
                      rules={[validations.notNull, validations.minNumber(1)]}
                    >
                      <InputNumber
                        {...inputNumberIntegerStandardProps}
                        addonAfter={<InputAddon type="nettoPoints" />}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name="careerDeclineAvoidmentPoints"
                      label={
                        <LabelWithTooltip
                          label={t("commissions.level.attrs.careerDeclineAvoidmentPoints")}
                          tooltip={t("commissions.level.helpers.careerDeclineAvoidmentPointsDesc")}
                        />
                      }
                      rules={[validations.notNull, validations.minNumber(1)]}
                    >
                      <InputNumber
                        {...inputNumberIntegerStandardProps}
                        addonAfter={<InputAddon type="nettoPoints" />}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name="careerDeclineDefinitivePoints"
                      label={
                        <LabelWithTooltip
                          label={t("commissions.level.attrs.careerDeclineDefinitivePoints")}
                          tooltip={t("commissions.level.helpers.careerDeclineDefinitivePointsDesc")}
                        />
                      }
                      rules={[validations.notNull, validations.minNumber(1)]}
                    >
                      <InputNumber
                        {...inputNumberIntegerStandardProps}
                        addonAfter={<InputAddon type="nettoPoints" />}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      name="includeGroupPointsIntoPromotion"
                      className="form-item-without-label"
                      valuePropName="checked"
                      rules={[validations.none]}
                      initialValue={false}
                    >
                      <Checkbox style={{ maxHeight: 22 }}>
                        <LabelWithTooltip
                          label={t("commissions.level.attrs.includeGroupPointsIntoPromotion")}
                          tooltip={t("commissions.level.helpers.includeGroupPointsIntoPromotionDesc")}
                        />
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={rowGutter}>
                  <Col span={24}>
                    <CommissionsLevelSelect
                      formItemProps={{
                        name: "nextLevelIds",
                        label: t("commissions.level.attrs.nextLevelIds"),
                        rules: [validations.none]
                      }}
                      selectProps={{
                        mode: "multiple",
                        maxTagCount: "responsive",
                        allowClear: true
                      }}
                      optionsProps={{
                        filterType: CommissionsSettingsLevelType.CAREER,
                        filter: l => level?.id !== l.id
                      }}
                    />
                  </Col>
                </Row>
              </>
            )
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CommissionsLevelForm;
