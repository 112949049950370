import { Col, Form, Input, Modal, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenCheckbox from "../../../../common/components/form/components/HiddenCheckbox";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { formatCron } from "../../../../common/utils/formatUtils";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { updateJobActions } from "../../ducks";
import { JobSettings, UpdateJobSettings } from "../../types";

interface Props {
  job?: JobSettings;
  open: boolean;
  onUpdate: typeof updateJobActions.request;
  onFormCancel: () => void;
}

const UpdateJobForm = ({ job, open, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<UpdateJobSettings>();
  useFormErrorHandler(form, "job.attrs", [requests.UPDATE_JOB]);

  useEffect(() => {
    if (open && job) {
      form.setFieldsValue(job);
    }
  }, [open, job, form]);

  const inProgress = useRequestFinishedCallback([requests.UPDATE_JOB], onFormCancel);

  const handleFormSubmit = (): void => {
    if (job) {
      form
        .validateFields()
        .then(values =>
          onUpdate({
            id: job.id,
            object: { ...values, preferences: job.preferences }
          })
        )
        .catch(resolveFormValidationError);
    }
  };

  const colSpan = 12;

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("job.titles.updateJob")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="updateJobForm">
        <HiddenInput name="optimisticLockVersion" />
        <HiddenCheckbox name="enabled" />

        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item name="name" label={t("job.attrs.name")} rules={[validations.notBlank, validations.size(1, 128)]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.cron !== next.cron}>
              {({ getFieldValue }) => {
                const cronValue = formatCron(getFieldValue("cron"));
                return (
                  <Form.Item
                    name="cron"
                    label={
                      cronValue ? (
                        <>
                          {t("job.attrs.cron")} - {cronValue}
                        </>
                      ) : (
                        <>{t("job.attrs.cron")}</>
                      )
                    }
                    rules={[validations.size(1, 255)]}
                  >
                    <Input />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateJobForm;
