import { Col, Row } from "antd";
import Ellipsis from "../../../common/components/views/Ellipsis";
import { InstitutionBase } from "../types";
import InstitutionTypeTag from "./InstitutionTypeTag";

interface Props {
  institution: InstitutionBase;
  ellipsis?: boolean;
}

const InstitutionTypeTagWithName = ({ institution, ellipsis }: Props) =>
  institution ? (
    <Row gutter={0}>
      <Col>
        <InstitutionTypeTag type={institution.type} />
      </Col>
      <Col flex="1 1 0" style={{ minWidth: 0 }}>
        <Ellipsis shouldRender={!!ellipsis}>{institution.name}</Ellipsis>
      </Col>
    </Row>
  ) : null;

export default InstitutionTypeTagWithName;
