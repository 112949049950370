import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { EntityIdObject, EntityObject } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  CreateUpdateDynamicConfigProperty,
  DynamicConfigProperty,
  DynamicConfigPropertyFilterPageRequest,
  DynamicConfigPropertyFilterPageResult
} from "./types";

const FILTER_CONFIG_PROPERTIES = ApiRequestAdapter.create("/config-properties", "GET");
const CREATE_CONFIG_PROPERTY = ApiRequestAdapter.create("/config-properties", "POST");
const UPDATE_CONFIG_PROPERTY = ApiRequestAdapter.create("/config-properties/{0}", "PUT");
const DELETE_CONFIG_PROPERTY = ApiRequestAdapter.create("/config-properties/{0}", "DELETE");

export const requests = {
  FILTER_CONFIG_PROPERTIES,
  CREATE_CONFIG_PROPERTY,
  UPDATE_CONFIG_PROPERTY,
  DELETE_CONFIG_PROPERTY
};

const api = {
  filterConfigProperties: (
    request: DynamicConfigPropertyFilterPageRequest
  ): AxiosPromise<DynamicConfigPropertyFilterPageResult> => {
    return apiService.get(FILTER_CONFIG_PROPERTIES.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createConfigProperty: (request: CreateUpdateDynamicConfigProperty): AxiosPromise<DynamicConfigProperty> => {
    return apiService.post(CREATE_CONFIG_PROPERTY.url, request);
  },
  updateConfigProperty: (
    request: EntityObject<CreateUpdateDynamicConfigProperty>
  ): AxiosPromise<DynamicConfigProperty> => {
    return apiService.put(UPDATE_CONFIG_PROPERTY.getParametrizedUrl(request.id), request.object);
  },
  deleteConfigProperty: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_CONFIG_PROPERTY.getParametrizedUrl(request.id));
  }
};

export default api;
