import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { RootState } from "../../common/types";
import api from "./api";
import { GlobalSearchReducerState, GlobalSearchRequest, GlobalSearchResult } from "./types";

/**
 * ACTIONS
 */
export const searchGloballyActions = createAsyncAction(
  "global-search/SEARCH_GLOBALLY_REQUEST",
  "global-search/SEARCH_GLOBALLY_SUCCESS",
  "global-search/SEARCH_GLOBALLY_FAILURE"
)<GlobalSearchRequest, GlobalSearchResult, void>();

export const deleteStateSearchResultAction = createAction("global-search/DELETE_STATE_RESULT")<void>();

const actions = {
  searchGloballyActions,
  deleteStateSearchResultAction
};

export type GlobalSearchAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: GlobalSearchReducerState = {
  searchResult: {
    keyword: undefined,
    contracts: [],
    clients: [],
    agents: [],
    users: [],
    mediations: []
  }
};

const searchResultReducer = createReducer(initialState.searchResult)
  .handleAction(searchGloballyActions.success, (_, { payload }) => payload)
  .handleAction([searchGloballyActions.failure, deleteStateSearchResultAction], () => initialState.searchResult);

export const globalSearchReducer = combineReducers<GlobalSearchReducerState>({
  searchResult: searchResultReducer
});

/**
 * SELECTORS
 */
const selectGlobalSearch = (state: RootState): GlobalSearchReducerState => state.globalSearch;

export const selectGlobalSearchResult = (state: RootState): GlobalSearchResult =>
  selectGlobalSearch(state).searchResult;

/**
 * SAGAS
 */
function* searchGlobally({ payload }: ReturnType<typeof searchGloballyActions.request>) {
  try {
    const response: AxiosResponse<GlobalSearchResult> = yield call(api.searchGlobally, payload);
    yield put(searchGloballyActions.success(response.data));
  } catch {
    yield put(searchGloballyActions.failure());
  }
}

export function* globalSearchSaga() {
  yield takeLatest(searchGloballyActions.request, searchGlobally);
}
