import { ATTACHMENT_BOUNDARY_PATH_PREFIX } from "../../common/constants";

export const CONTRACT_ROUTE_PREFIX = "/contracts";

export const CONTRACT_ROUTE_PATHS = {
  list: { path: "*", to: CONTRACT_ROUTE_PREFIX },
  predictedCommissions: { path: "predicted-commissions", to: `${CONTRACT_ROUTE_PREFIX}/predicted-commissions` },
  anniversaryDate: { path: "anniversary-date", to: `${CONTRACT_ROUTE_PREFIX}/anniversary-date` },
  fixationAnniversaryDate: { path: "fixation-anniversary", to: `${CONTRACT_ROUTE_PREFIX}/fixation-anniversary` },
  unpaid: { path: "unpaid", to: `${CONTRACT_ROUTE_PREFIX}/unpaid` },
  create: { path: "new", to: `${CONTRACT_ROUTE_PREFIX}/new` },
  detail: { path: ":id", to: `${CONTRACT_ROUTE_PREFIX}/:id` },
  attachment: { to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${CONTRACT_ROUTE_PREFIX}/:id1/attachments/:id2` },
  importResultFile: { to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${CONTRACT_ROUTE_PREFIX}/import/:id` },
  forms: { path: "forms", to: `${CONTRACT_ROUTE_PREFIX}/forms` }
};
