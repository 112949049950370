import { Alert, Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import {
  resolveFormValidationError,
  selectTagsStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { changeContractVerificationStatusActions } from "../../ducks";
import { ContractVerificationStatus, contractVerificationStatusTMap } from "../../enums";
import { ChangeContractVerificationStatus, Contract, ContractList } from "../../types";
import ContractVerificationStatusTag from "../ContractVerificationStatusTag";

interface Props {
  open: boolean;
  contract?: Contract | ContractList;
  viewType?: "sendToBackoffice" | "noteEdit" | "default";
  onSubmit: typeof changeContractVerificationStatusActions.request;
  onFormCancel: () => void;
}

const ALLOWED_STATUSES_MAP = new Map<ContractVerificationStatus, ContractVerificationStatus[]>([
  [
    ContractVerificationStatus.TYPED,
    [
      ContractVerificationStatus.SENT,
      ContractVerificationStatus.VERIFIED,
      ContractVerificationStatus.EXTERNAL_INTERVENTION,
      ContractVerificationStatus.NOT_ACCEPTED
    ]
  ],
  [
    ContractVerificationStatus.SENT,
    [
      ContractVerificationStatus.VERIFIED,
      ContractVerificationStatus.INTERNAL_INTERVENTION,
      ContractVerificationStatus.EXTERNAL_INTERVENTION,
      ContractVerificationStatus.NOT_ACCEPTED
    ]
  ],
  [
    ContractVerificationStatus.INTERNAL_INTERVENTION,
    [
      ContractVerificationStatus.SENT,
      ContractVerificationStatus.EXTERNAL_INTERVENTION,
      ContractVerificationStatus.NOT_ACCEPTED
    ]
  ],
  [
    ContractVerificationStatus.EXTERNAL_INTERVENTION,
    [ContractVerificationStatus.SENT, ContractVerificationStatus.NOT_ACCEPTED]
  ],
  [ContractVerificationStatus.NOT_ACCEPTED, []],
  [
    ContractVerificationStatus.VERIFIED,
    [ContractVerificationStatus.EXTERNAL_INTERVENTION, ContractVerificationStatus.NOT_ACCEPTED]
  ],
  [ContractVerificationStatus.PAID, []]
]);

const ContractChangeVerificationStatusForm = ({ open, contract, viewType, onSubmit, onFormCancel }: Props) => {
  const [form] = Form.useForm<ChangeContractVerificationStatus>();
  useFormErrorHandler(form, "contract.attrs", [requests.CHANGE_CONTRACT_VERIFICATION_STATUS]);

  const inProgress = useRequestFinishedCallback([requests.CHANGE_CONTRACT_VERIFICATION_STATUS], onFormCancel);

  useEffect(() => {
    if (open && contract) {
      let formData: Partial<ChangeContractVerificationStatus> = {
        optimisticLockVersion: contract.optimisticLockVersion
      };

      switch (viewType) {
        case "sendToBackoffice":
          formData = { ...formData, verificationStatus: ContractVerificationStatus.SENT };
          break;
        case "noteEdit":
          formData = {
            ...formData,
            verificationStatus: contract.verificationStatus,
            verificationNote: contract.verificationNote
          };
          break;
      }

      form.setFieldsValue(formData as ChangeContractVerificationStatus);
    }
  }, [open, contract, viewType, form]);

  const handleFormSubmit = (): void => {
    if (contract) {
      form
        .validateFields()
        .then(values => onSubmit({ id: contract.id, object: values }))
        .catch(resolveFormValidationError);
    }
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t("contract.helpers.verificationStatusChange")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="contractChangeVerificationStatusForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="verificationStatus"
              label={t("contract.enums.verificationStatus._label")}
              rules={[validations.notNull]}
            >
              <Select
                {...selectTagsStandardProps(contractVerificationStatusTMap)}
                disabled={viewType === "sendToBackoffice" || viewType === "noteEdit"}
                options={(viewType === "sendToBackoffice" || viewType === "noteEdit"
                  ? ContractVerificationStatus && Object.values(ContractVerificationStatus)
                  : ALLOWED_STATUSES_MAP.get(contract?.verificationStatus as ContractVerificationStatus)
                )?.map(status => ({
                  value: status,
                  label: <ContractVerificationStatusTag status={status} />
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.verificationStatus !== next.verificationStatus}>
          {({ getFieldValue }) => {
            const status = getFieldValue("verificationStatus") as ContractVerificationStatus;

            if (
              status === ContractVerificationStatus.SENT ||
              status === ContractVerificationStatus.INTERNAL_INTERVENTION ||
              status === ContractVerificationStatus.EXTERNAL_INTERVENTION
            ) {
              return (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="verificationNote"
                      label={t("contract.attrs.verificationNote")}
                      rules={[validations.size(1, 1024)]}
                    >
                      <Input.TextArea autoSize={{ minRows: 3, maxRows: 15 }} />
                    </Form.Item>
                  </Col>
                </Row>
              );
            }

            if (contract?.commissionPaid && status === ContractVerificationStatus.VERIFIED) {
              return <Alert message={t("contract.helpers.commissionAlreadyPaid")} type="info" showIcon />;
            }

            return null;
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContractChangeVerificationStatusForm;
