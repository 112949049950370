import { ATTACHMENT_BOUNDARY_PATH_PREFIX } from "../../common/constants";

export const CLIENT_ROUTE_PREFIX = "/clients";

export const CLIENT_ROUTE_PATHS = {
  list: { path: "*", to: CLIENT_ROUTE_PREFIX },
  create: { path: "new", to: `${CLIENT_ROUTE_PREFIX}/new` },
  detail: { path: ":id", to: `${CLIENT_ROUTE_PREFIX}/:id` },
  attachment: { to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${CLIENT_ROUTE_PREFIX}/:id1/attachments/:id2` },
  importResultFile: { to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${CLIENT_ROUTE_PREFIX}/import/:id` }
};
