import { Route, Routes } from "react-router-dom";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import { Permission } from "../../common/security/authorization/enums";
import PageWithPermission from "../../common/security/authorization/PageWithPermission";
import FinancialMediationCreateContainer from "./containers/FinancialMediationCreateContainer";
import FinancialMediationDetailContainer from "./containers/FinancialMediationDetailContainer";
import { FINANCIAL_MEDIATION_ROUTE_PATHS } from "./paths";

const routes = () => (
  <Routes>
    <Route
      path={FINANCIAL_MEDIATION_ROUTE_PATHS.detail.path}
      element={
        <PageWithPermission
          component={<FinancialMediationDetailContainer />}
          permissions={[
            Permission.INSURANCE_READ,
            Permission.LOAN_READ,
            Permission.DEPOSIT_READ,
            Permission.SECOND_PILLAR_READ,
            Permission.THIRD_PILLAR_READ
          ]}
          anyPermission
        />
      }
    />

    <Route
      path={FINANCIAL_MEDIATION_ROUTE_PATHS.create.path}
      element={
        <PageWithPermission
          component={<FinancialMediationCreateContainer />}
          permissions={[
            Permission.INSURANCE_CREATE,
            Permission.LOAN_CREATE,
            Permission.DEPOSIT_CREATE,
            Permission.SECOND_PILLAR_CREATE,
            Permission.THIRD_PILLAR_CREATE
          ]}
          anyPermission
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
