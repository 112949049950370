import t from "../../../app/i18n";

export enum CommissionsSettingsLevelType {
  BASIC = "BASIC",
  CAREER = "CAREER"
}

export const commissionsSettingsLevelTypeTMap = new Map<CommissionsSettingsLevelType, string>(
  Object.values(CommissionsSettingsLevelType).map(type => [type, t("commissions.level.enums.type." + type)])
);
