import t from "../../../../../app/i18n";
import InstitutionEnumName from "../../../../enumerations/components/view/InstitutionEnumName";
import { CommissionsSettingsLevelRule } from "../../../level/types";
import { CommissionsSettingsRule } from "../../types";

interface Props {
  rule: CommissionsSettingsRule | CommissionsSettingsLevelRule;
}

const CommissionsSettingsRuleView = ({ rule }: Props) => (
  <>
    <span className="nowrap">
      <b>{t("commissions.settings.helpers.sector")}:</b> {t("product.enums.financialSector." + rule.sector)}
    </span>
    {rule.productGroup && (
      <>
        {" | "}
        <span className="nowrap">
          <b>{t("commissions.settings.helpers.productGroup")}:</b> {rule.productGroup.name}
        </span>
      </>
    )}
    {rule.institution && (
      <>
        {" | "}
        <span className="nowrap">
          <b>{t("commissions.settings.attrs.institutionId")}:</b>{" "}
          <InstitutionEnumName institution={rule.institution} hideDeactivatedLabel />
        </span>
      </>
    )}
    {rule.product && (
      <>
        {" | "}
        <span className="nowrap">
          <b>{t("commissions.settings.attrs.productId")}:</b> {rule.product.name}
        </span>
      </>
    )}
  </>
);

export default CommissionsSettingsRuleView;
