import { useSelector } from "react-redux";
import { selectPermissions } from "../../../modules/auth/ducks";
import { RootState } from "../../types";
import { hasAllPermissions, hasAnyPermission } from "../../utils/utils";
import { Permission } from "./enums";

interface Props {
  children: React.ReactNode;
  permissions: Permission[];
  anyPermissionPresent?: boolean;
}

const ComponentWithPermission = ({ permissions, anyPermissionPresent, children }: Props) => {
  const accountPermissions = useSelector<RootState, Permission[]>(selectPermissions);

  return (
    anyPermissionPresent
      ? hasAnyPermission(accountPermissions, permissions)
      : hasAllPermissions(accountPermissions, permissions)
  ) ? (
    <>{children}</>
  ) : null;
};

export default ComponentWithPermission;
