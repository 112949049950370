import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";
import { combineReducers } from "redux";
import { replace } from "redux-first-history";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import t from "../../app/i18n";
import {
  EntityIdObject,
  EntityObject,
  RootState,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../common/types";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { openBlobFile, removeFromArray, replaceInArray } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import {
  AgentCreateUserWithClientUserAccount,
  AgentUpdateClientUserAccountWithUser,
  ClientUserAccountWithUserAgentView
} from "../user/types";
import api from "./api";
import { CLIENT_ROUTE_PATHS } from "./paths";
import {
  Client,
  ClientAttachment,
  ClientFilterExportRequest,
  ClientFilterPageRequest,
  ClientFilterPageResult,
  ClientList,
  ClientReducerState,
  ClientSearchRequest,
  ClientSearchResult,
  CreateUpdateClient,
  ValidateContractClientRequest,
  ValidateContractClientsRequest
} from "./types";

/**
 * ACTIONS
 */
export const filterClientsActions = createAsyncAction(
  "client/FILTER_REQUEST",
  "client/FILTER_SUCCESS",
  "client/FILTER_FAILURE"
)<ClientFilterPageRequest, ClientFilterPageResult, void>();

export const importClientsActions = createAsyncAction(
  "client/IMPORT_REQUEST",
  "client/IMPORT_SUCCESS",
  "client/IMPORT_FAILURE"
)<FormData, void, void>();

export const downloadClientsImportResultActions = createAsyncAction(
  "client/IMPORT_RESULT_REQUEST",
  "client/IMPORT_RESULT_SUCCESS",
  "client/IMPORT_RESULT_FAILURE"
)<EntityIdObject, void, void>();

export const downloadClientsExportActions = createAsyncAction(
  "client/EXPORT_REQUEST",
  "client/EXPORT_SUCCESS",
  "client/EXPORT_FAILURE"
)<ClientFilterExportRequest, void, void>();

export const searchClientActions = createAsyncAction(
  "client/SEARCH_REQUEST",
  "client/SEARCH_SUCCESS",
  "client/SEARCH_FAILURE"
)<ClientSearchRequest, ClientSearchResult, void>();

export const validateClientActions = createAsyncAction(
  "client/VALIDATE_REQUEST",
  "client/VALIDATE_SUCCESS",
  "client/VALIDATE_FAILURE"
)<ValidateContractClientRequest, void, void>();

export const validateClientsActions = createAsyncAction(
  "client/VALIDATE_ALL_REQUEST",
  "client/VALIDATE_ALL_SUCCESS",
  "client/VALIDATE_ALL_FAILURE"
)<ValidateContractClientsRequest, void, void>();

export const getClientActions = createAsyncAction("client/GET_REQUEST", "client/GET_SUCCESS", "client/GET_FAILURE")<
  EntityIdObject,
  Client,
  void
>();

export const updateClientActions = createAsyncAction(
  "client/UPDATE_REQUEST",
  "client/UPDATE_SUCCESS",
  "client/UPDATE_FAILURE"
)<EntityObject<CreateUpdateClient>, Client, void>();

export const createClientActions = createAsyncAction(
  "client/CREATE_REQUEST",
  "client/CREATE_SUCCESS",
  "client/CREATE_FAILURE"
)<CreateUpdateClient, Client, void>();

export const deleteClientActions = createAsyncAction(
  "client/DELETE_REQUEST",
  "client/DELETE_SUCCESS",
  "client/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateClientsPageAction = createAction("client/DELETE_STATE_LIST")<void>();
export const deleteStateClientSearchResultAction = createAction("client/DELETE_STATE_SEARCH_RESULT")<void>();
export const deleteStateClientDetailAction = createAction("client/DELETE_STATE_DETAIL")<void>();

export const getClientUserAccountsActions = createAsyncAction(
  "client/GET_CLIENT_USER_ACCOUNTS_REQUEST",
  "client/GET_CLIENT_USER_ACCOUNTS_SUCCESS",
  "client/GET_CLIENT_USER_ACCOUNTS_FAILURE"
)<EntityIdObject, ClientUserAccountWithUserAgentView[], void>();

export const createUserWithClientUserAccountActions = createAsyncAction(
  "client/CREATE_USER_ACCOUNT_REQUEST",
  "client/CREATE_USER_ACCOUNT_SUCCESS",
  "client/CREATE_USER_ACCOUNT_FAILURE"
)<EntityObject<AgentCreateUserWithClientUserAccount>, ClientUserAccountWithUserAgentView, void>();

export const resendUserConfirmLinkActions = createAsyncAction(
  "client/RESEND_CONFIRM_LINK_REQUEST",
  "client/RESEND_CONFIRM_LINK_SUCCESS",
  "client/RESEND_CONFIRM_LINK_FAILURE"
)<TwoLevelEntityIdObject, ClientUserAccountWithUserAgentView, void>();

export const updateClientUserAccountActions = createAsyncAction(
  "client/UPDATE_USER_ACCOUNT_REQUEST",
  "client/UPDATE_USER_ACCOUNT_SUCCESS",
  "client/UPDATE_USER_ACCOUNT_FAILURE"
)<TwoLevelEntityObject<AgentUpdateClientUserAccountWithUser>, ClientUserAccountWithUserAgentView, void>();

export const deleteClientUserAccountActions = createAsyncAction(
  "client/DELETE_USER_ACCOUNT_REQUEST",
  "client/DELETE_USER_ACCOUNT_SUCCESS",
  "client/DELETE_USER_ACCOUNT_FAILURE"
)<TwoLevelEntityIdObject, TwoLevelEntityIdObject, void>();

export const deleteStateClientUserAccountsAction = createAction("client/DELETE_STATE_CLIENT_USER_ACCOUNTS")<void>();

export const getClientAttachmentsActions = createAsyncAction(
  "client-attachment/GET_REQUEST",
  "client-attachment/GET_SUCCESS",
  "client-attachment/GET_FAILURE"
)<EntityIdObject, ClientAttachment[], void>();

export const uploadClientAttachmentsActions = createAsyncAction(
  "client-attachment/UPLOAD_REQUEST",
  "client-attachment/UPLOAD_SUCCESS",
  "client-attachment/UPLOAD_FAILURE"
)<EntityObject<FormData>, ClientAttachment[], void>();

export const downloadClientAttachmentActions = createAsyncAction(
  "client-attachment/DOWNLOAD_REQUEST",
  "client-attachment/DOWNLOAD_SUCCESS",
  "client-attachment/DOWNLOAD_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const downloadClientAttachmentsAsZipActions = createAsyncAction(
  "client-attachment/DOWNLOAD_ATTACHMENTS_AS_ZIP_REQUEST",
  "client-attachment/DOWNLOAD_ATTACHMENTS_AS_ZIP_SUCCESS",
  "client-attachment/DOWNLOAD_ATTACHMENTS_AS_ZIP_FAILURE"
)<EntityIdObject, void, void>();

export const deleteClientAttachmentActions = createAsyncAction(
  "client-attachment/DELETE_REQUEST",
  "client-attachment/DELETE_SUCCESS",
  "client-attachment/DELETE_FAILURE"
)<TwoLevelEntityIdObject, EntityIdObject, void>();

const actions = {
  filterClientsActions,
  importClientsActions,
  downloadClientsImportResultActions,
  downloadClientsExportActions,
  searchClientActions,
  validateClientActions,
  validateClientsActions,
  getClientActions,
  updateClientActions,
  createClientActions,
  deleteClientActions,
  deleteStateClientsPageAction,
  deleteStateClientSearchResultAction,
  deleteStateClientDetailAction,
  getClientUserAccountsActions,
  createUserWithClientUserAccountActions,
  resendUserConfirmLinkActions,
  updateClientUserAccountActions,
  deleteClientUserAccountActions,
  deleteStateClientUserAccountsAction,
  getClientAttachmentsActions,
  uploadClientAttachmentsActions,
  downloadClientAttachmentActions,
  downloadClientAttachmentsAsZipActions,
  deleteClientAttachmentActions
};

export type ClientAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: ClientReducerState = {
  currentPage: {
    ...initSearchPageResult<ClientList>(),
    types: []
  },
  searchResult: {
    keyword: undefined,
    clientType: undefined,
    data: undefined
  },
  clientDetail: null,
  clientUserAccounts: []
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterClientsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterClientsActions.failure, deleteClientActions.success, deleteStateClientsPageAction],
    () => initialState.currentPage
  );

const searchResultReducer = createReducer(initialState.searchResult)
  .handleAction(searchClientActions.success, (_, { payload }) => payload)
  .handleAction([searchClientActions.failure, deleteStateClientSearchResultAction], () => initialState.searchResult);

const clientDetailReducer = createReducer(initialState.clientDetail)
  .handleAction(
    [getClientActions.success, updateClientActions.success, createClientActions.success],
    (_, { payload }) => payload
  )
  .handleAction([getClientAttachmentsActions.success, uploadClientAttachmentsActions.success], (state, { payload }) =>
    state ? { ...state, attachments: payload } : state
  )
  .handleAction(deleteClientAttachmentActions.success, (state, { payload }) =>
    state && state.attachments
      ? { ...state, attachments: removeFromArray(state.attachments, item => item.id === payload.id) }
      : state
  )
  .handleAction(
    [getClientActions.failure, deleteClientActions.success, deleteStateClientDetailAction],
    () => initialState.clientDetail
  );

const clientUserAccountsReducer = createReducer(initialState.clientUserAccounts)
  .handleAction(getClientUserAccountsActions.success, (_, { payload }) => payload)
  .handleAction(
    [getClientUserAccountsActions.failure, deleteStateClientUserAccountsAction],
    () => initialState.clientUserAccounts
  )
  .handleAction(createUserWithClientUserAccountActions.success, (state, { payload }) => [...state, payload])
  .handleAction([resendUserConfirmLinkActions.success, updateClientUserAccountActions.success], (state, { payload }) =>
    replaceInArray(
      state,
      item => item.id === payload.id,
      () => payload
    )
  )
  .handleAction(deleteClientUserAccountActions.success, (state, { payload }) =>
    removeFromArray(state, item => item.id === payload.id2)
  );

export const clientReducer = combineReducers<ClientReducerState>({
  currentPage: currentPageReducer,
  searchResult: searchResultReducer,
  clientDetail: clientDetailReducer,
  clientUserAccounts: clientUserAccountsReducer
});

/**
 * SELECTORS
 */
const selectClient = (state: RootState): ClientReducerState => state.client;

export const selectClientsCurrentPage = (state: RootState): ClientFilterPageResult => selectClient(state).currentPage;
export const selectClientSearchResult = (state: RootState): ClientSearchResult => selectClient(state).searchResult;
export const selectClientDetail = (state: RootState): Client | undefined =>
  selectClient(state).clientDetail ?? undefined;
export const selectClientUserAccounts = (state: RootState): ClientUserAccountWithUserAgentView[] =>
  selectClient(state).clientUserAccounts;

/**
 * SAGAS
 */
function* filterClients({ payload }: ReturnType<typeof filterClientsActions.request>) {
  try {
    const response: AxiosResponse<ClientFilterPageResult> = yield call(api.filterClients, payload);
    yield put(filterClientsActions.success(response.data));
  } catch {
    yield put(filterClientsActions.failure());
  }
}

function* importClients({ payload }: ReturnType<typeof importClientsActions.request>) {
  try {
    const response: AxiosResponse<number> = yield call(api.importClients, payload);
    yield put(importClientsActions.success());
    yield put(changeRunningRequestKeyAction());
    messageUtils.successNotification({
      message: t("common.operationSuccess"),
      description: t("client.helpers.import.processStarted", { count: response.data })
    });
  } catch {
    yield put(importClientsActions.failure());
  }
}

function* downloadClientsImportResult({ payload }: ReturnType<typeof downloadClientsImportResultActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadClientsImportResult, payload);
    openBlobFile(response, true);
    yield put(downloadClientsImportResultActions.success());
  } catch {
    yield put(downloadClientsImportResultActions.failure());
  }
}

function* downloadClientsExport({ payload }: ReturnType<typeof downloadClientsExportActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadClientsExport, payload);
    openBlobFile(response, true);
    yield put(downloadClientsExportActions.success());
  } catch {
    yield put(downloadClientsExportActions.failure());
  }
}

function* searchClient({ payload }: ReturnType<typeof searchClientActions.request>) {
  try {
    const response: AxiosResponse<ClientSearchResult> = yield call(api.searchClient, payload);
    yield put(searchClientActions.success(response.data));
  } catch {
    yield put(searchClientActions.failure());
  }
}

function* validateClient({ payload }: ReturnType<typeof validateClientActions.request>) {
  try {
    yield call(api.validateClient, payload);
    yield put(validateClientActions.success());
  } catch {
    yield put(validateClientActions.failure());
  }
}

function* validateClients({ payload }: ReturnType<typeof validateClientsActions.request>) {
  try {
    yield call(api.validateClients, payload);
    yield put(validateClientsActions.success());
  } catch {
    yield put(validateClientsActions.failure());
  }
}

function* getClientDetail({ payload }: ReturnType<typeof getClientActions.request>) {
  try {
    const response: AxiosResponse<Client> = yield call(api.getClient, payload);
    yield put(getClientActions.success(response.data));
    yield put(getClientAttachmentsActions.request(payload));
  } catch {
    yield put(getClientActions.failure());
  }
}

function* updateClient({ payload }: ReturnType<typeof updateClientActions.request>) {
  try {
    const response: AxiosResponse<Client> = yield call(api.updateClient, payload);
    yield put(updateClientActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateClientActions.failure());
  }
}

function* createClient({ payload }: ReturnType<typeof createClientActions.request>) {
  try {
    const response: AxiosResponse<Client> = yield call(api.createClient, payload);
    yield put(createClientActions.success(response.data));
    yield put(replace(generatePath(CLIENT_ROUTE_PATHS.detail.to, { id: response.data.id })));
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createClientActions.failure());
  }
}

function* deleteClient({ payload }: ReturnType<typeof deleteClientActions.request>) {
  try {
    yield call(api.deleteClient, payload);
    yield put(deleteClientActions.success());
    yield put(replace(CLIENT_ROUTE_PATHS.list.to));
  } catch {
    yield put(deleteClientActions.failure());
  }
}

function* getClientUserAccounts({ payload }: ReturnType<typeof getClientUserAccountsActions.request>) {
  try {
    const response: AxiosResponse<ClientUserAccountWithUserAgentView[]> = yield call(
      api.getClientUserAccounts,
      payload
    );
    yield put(getClientUserAccountsActions.success(response.data));
  } catch {
    yield put(getClientUserAccountsActions.failure());
  }
}

function* createUserWithClientUserAccount({
  payload
}: ReturnType<typeof createUserWithClientUserAccountActions.request>) {
  try {
    const response: AxiosResponse<ClientUserAccountWithUserAgentView> = yield call(
      api.createUserWithClientUserAccount,
      payload
    );
    yield put(createUserWithClientUserAccountActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
  } catch {
    yield put(createUserWithClientUserAccountActions.failure());
  }
}

function* resendUserConfirmLink({ payload }: ReturnType<typeof resendUserConfirmLinkActions.request>) {
  try {
    const response: AxiosResponse<ClientUserAccountWithUserAgentView> = yield call(api.resendUserConfirmLink, payload);
    yield put(resendUserConfirmLinkActions.success(response.data));
    messageUtils.successNotification({
      message: t("common.operationSuccess"),
      description: t("user.helpers.resendConfirmationLinkSuccess")
    });
  } catch {
    yield put(resendUserConfirmLinkActions.failure());
  }
}

function* updateClientUserAccount({ payload }: ReturnType<typeof updateClientUserAccountActions.request>) {
  try {
    const response: AxiosResponse<ClientUserAccountWithUserAgentView> = yield call(
      api.updateClientUserAccount,
      payload
    );
    yield put(updateClientUserAccountActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch {
    yield put(updateClientUserAccountActions.failure());
  }
}

function* deleteClientUserAccount({ payload }: ReturnType<typeof deleteClientUserAccountActions.request>) {
  try {
    yield call(api.deleteClientUserAccount, payload);
    yield put(deleteClientUserAccountActions.success(payload));
    messageUtils.itemDeletedNotification();
  } catch {
    yield put(deleteClientUserAccountActions.failure());
  }
}

function* getClientAttachments({ payload }: ReturnType<typeof getClientAttachmentsActions.request>) {
  try {
    const response: AxiosResponse<ClientAttachment[]> = yield call(api.getClientAttachments, payload);
    yield put(getClientAttachmentsActions.success(response.data));
  } catch {
    yield put(getClientAttachmentsActions.failure());
  }
}

function* uploadClientAttachments({ payload }: ReturnType<typeof uploadClientAttachmentsActions.request>) {
  try {
    const response: AxiosResponse<ClientAttachment[]> = yield call(api.uploadClientAttachments, payload);
    yield put(uploadClientAttachmentsActions.success(response.data));
  } catch {
    yield put(uploadClientAttachmentsActions.failure());
  }
}

function* downloadClientAttachment({ payload }: ReturnType<typeof downloadClientAttachmentActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadClientAttachment, payload);
    openBlobFile(response);
    yield put(downloadClientAttachmentActions.success());
  } catch {
    yield put(downloadClientAttachmentActions.failure());
  }
}

function* downloadClientAttachmentsAsZip({
  payload
}: ReturnType<typeof downloadClientAttachmentsAsZipActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadClientAttachmentsAsZip, payload);
    openBlobFile(response, true);
    yield put(downloadClientAttachmentsAsZipActions.success());
  } catch {
    yield put(downloadClientAttachmentsAsZipActions.failure());
  }
}

function* deleteClientAttachment({ payload }: ReturnType<typeof deleteClientAttachmentActions.request>) {
  try {
    yield call(api.deleteClientAttachment, payload);
    yield put(deleteClientAttachmentActions.success({ id: payload.id2 }));
  } catch {
    yield put(deleteClientAttachmentActions.failure());
  }
}

export function* clientSaga() {
  yield takeLatest(filterClientsActions.request, filterClients);
  yield takeLatest(importClientsActions.request, importClients);
  yield takeLatest(downloadClientsImportResultActions.request, downloadClientsImportResult);
  yield takeLatest(downloadClientsExportActions.request, downloadClientsExport);
  yield takeLatest(searchClientActions.request, searchClient);

  yield takeLatest(validateClientActions.request, validateClient);
  yield takeLatest(validateClientsActions.request, validateClients);

  yield takeLatest(getClientActions.request, getClientDetail);
  yield takeLatest(updateClientActions.request, updateClient);
  yield takeLatest(createClientActions.request, createClient);
  yield takeLatest(deleteClientActions.request, deleteClient);

  yield takeLatest(getClientUserAccountsActions.request, getClientUserAccounts);
  yield takeLatest(createUserWithClientUserAccountActions.request, createUserWithClientUserAccount);
  yield takeLatest(resendUserConfirmLinkActions.request, resendUserConfirmLink);

  yield takeLatest(updateClientUserAccountActions.request, updateClientUserAccount);
  yield takeLatest(deleteClientUserAccountActions.request, deleteClientUserAccount);

  yield takeLatest(getClientAttachmentsActions.request, getClientAttachments);
  yield takeLatest(uploadClientAttachmentsActions.request, uploadClientAttachments);
  yield takeLatest(downloadClientAttachmentActions.request, downloadClientAttachment);
  yield takeLatest(downloadClientAttachmentsAsZipActions.request, downloadClientAttachmentsAsZip);
  yield takeLatest(deleteClientAttachmentActions.request, deleteClientAttachment);
}
