import { Button, Card, Checkbox, Col, DatePicker, Form, InputNumber, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance, Rule } from "antd/lib/form";
import ReactQuill from "react-quill-new";
import t from "../../../../../../app/i18n";
import InputAddon from "../../../../../../common/components/form/addons/InputAddon";
import LabelWithPopover from "../../../../../../common/components/form/labels/LabelWithPopover";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../common/constants";
import { formatLocaleCurrencyWithNullAsZero } from "../../../../../../common/utils/formatUtils";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerOutOfMaxDate,
  disableDatePickerOutOfMaxDateIncluded,
  disableDatePickerOutOfMinDate,
  disableDatePickerOutOfMinDateIncluded,
  inputNumberDecimalStandardProps,
  quillEditorStandardProps,
  selectStandardProps
} from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { ContractStatus, InsurancePeriod, PaymentFrequency, PeriodType } from "../../../../enums";
import { CreateUpdateContractGainerRecord, CreateUpdateInsuranceContract } from "../../../../types";
import {
  calculateContractStatus,
  calculateInsurancePeriodEndDate,
  calculateLastContractCancellationDate
} from "../../../../utils";
import ContractStatusTag from "../../../ContractStatusTag";

interface Props {
  form: FormInstance<CreateUpdateInsuranceContract>;
  insurancesCount: number;
  insurancesAnnualPremiumsSum: number;
  insurancesPartialPremiumsSum: number;
}

const InsuranceContractFormDataSection = ({ form, insurancesCount, ...props }: Props) => {
  const handlePeriodTypeChange = (periodType: PeriodType): void => {
    if (periodType === PeriodType.INDEFINITE) {
      form.setFieldsValue({
        insurancePeriod: InsurancePeriod.TECHNICAL_YEAR,
        effectiveEndDate: undefined
      });
    } else {
      form.setFieldsValue({ insurancePeriod: InsurancePeriod.FIXED });
    }
    handleStatusDefiningDateChange();
  };

  const handleInsurancePeriodChange = (): void => {
    setContractSpecificDates(getContractStatus());
  };

  const handleStatusDefiningDateChange = (): void => {
    const status = getContractStatus();
    form.setFieldsValue({ status });
    setContractSpecificDates(status);
  };

  const handleEffectiveBeginningChange = (): void => {
    handleStatusDefiningDateChange();
    const effectiveBeginningDate = form.getFieldValue(["effectiveBeginningDate"]);
    const records = [...form.getFieldValue(["gainerRecords"])] as CreateUpdateContractGainerRecord[];
    records[0] = { ...(records[0] as CreateUpdateContractGainerRecord), startDate: effectiveBeginningDate };
    form.setFieldsValue({ gainerRecords: records });
  };

  const handleTransferredToOtherBrokerChange = (event: CheckboxChangeEvent): void => {
    if (!event.target.checked) {
      form.setFieldsValue({ transferredToOtherBrokerDate: undefined });
      handleStatusDefiningDateChange();
    }
  };

  const getContractStatus = (): ContractStatus | undefined => {
    const { effectiveBeginningDate, effectiveEndDate, cancellationDate, transferredToOtherBrokerDate } =
      form.getFieldsValue([
        ["effectiveBeginningDate"],
        ["effectiveEndDate"],
        ["cancellationDate"],
        ["transferredToOtherBrokerDate"]
      ]) as CreateUpdateInsuranceContract;

    return calculateContractStatus({
      startDate: effectiveBeginningDate,
      endDate: effectiveEndDate,
      cancellationDate: cancellationDate,
      transferredToOtherBrokerDate: transferredToOtherBrokerDate
    });
  };

  const setContractSpecificDates = (status?: ContractStatus): void => {
    const {
      cancellationDate,
      periodType,
      effectiveEndDate,
      insurancePeriod,
      effectiveBeginningDate,
      transferredToOtherBrokerDate
    } = form.getFieldsValue([
      ["cancellationDate"],
      ["periodType"],
      ["effectiveEndDate"],
      ["insurancePeriod"],
      ["effectiveBeginningDate"],
      ["transferredToOtherBrokerDate"]
    ]) as CreateUpdateInsuranceContract;

    const insurancePeriodEndDate = calculateInsurancePeriodEndDate(
      cancellationDate,
      periodType,
      effectiveEndDate,
      insurancePeriod,
      effectiveBeginningDate,
      transferredToOtherBrokerDate,
      status
    );
    const lastContractCancellationDate = calculateLastContractCancellationDate(
      periodType,
      cancellationDate,
      transferredToOtherBrokerDate,
      effectiveBeginningDate,
      insurancePeriodEndDate
    );

    form.setFieldsValue({ insurancePeriodEndDate, lastContractCancellationDate });
  };

  const setPremiumsSumToForm = (type: "annualPremium" | "partialPremium"): void => {
    if (type === "annualPremium") {
      form.setFieldsValue({ annualPremium: props.insurancesAnnualPremiumsSum });
    } else {
      form.setFieldsValue({ partialPremium: props.insurancesPartialPremiumsSum });
    }
  };

  const resolvePremiumLabel = (type: "annualPremium" | "partialPremium"): React.ReactNode => {
    const label = type === "annualPremium" ? t("contract.attrs.annualPremium") : t("contract.attrs.partialPremium");

    if (insurancesCount > 1) {
      const currentValue = form.getFieldValue([type === "annualPremium" ? "annualPremium" : "partialPremium"]);
      const insurancesSumValue =
        type === "annualPremium" ? props.insurancesAnnualPremiumsSum : props.insurancesPartialPremiumsSum;
      const helperLabel = t(
        "contract.helpers." +
          (type === "annualPremium" ? "insurancesAnnualPremiumsSum" : "insurancesPartialPremiumsSum"),
        { premiumsSum: formatLocaleCurrencyWithNullAsZero(insurancesSumValue) }
      );

      return currentValue !== insurancesSumValue ? (
        <LabelWithPopover
          label={label}
          popoverContent={
            <>
              <div>{helperLabel}</div>
              <div className="right-align">
                <Button style={{ paddingRight: "0" }} type="link" onClick={() => setPremiumsSumToForm(type)}>
                  {t("contract.actions.putPremiumsSumToContract")}
                </Button>
              </div>
            </>
          }
        />
      ) : (
        label
      );
    }

    return label;
  };

  const colSpan = 4;

  return (
    <Card
      type="inner"
      className="card-box card-box--inner-extra"
      title={t("contract.sections.contractData")}
      extra={
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.status !== next.status}>
          {({ getFieldValue }) => {
            const status = getFieldValue("status");
            return status ? <ContractStatusTag status={status} /> : undefined;
          }}
        </Form.Item>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.signDate !== next.signDate}>
            {({ getFieldValue }) => {
              const signDate = getFieldValue("signDate") as string | undefined;
              const rules: Rule[] = [validations.notNull];
              if (signDate) {
                rules.push(validations.notAfter(signDate, t("contract.attrs.signDateInsurance")));
              }
              return (
                <Form.Item
                  name="mediationReportSignDate"
                  label={t("contract.attrs.mediationReportSignDate")}
                  rules={rules}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerStandardProps}
                    disabledDate={current => (signDate ? disableDatePickerOutOfMaxDate(current, signDate) : false)}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.effectiveBeginningDate !== next.effectiveBeginningDate}>
            {({ getFieldValue }) => {
              const effectiveBeginningDate = getFieldValue("effectiveBeginningDate");
              const rules: Rule[] = [validations.notNull];
              if (effectiveBeginningDate) {
                rules.push(validations.notAfter(effectiveBeginningDate, t("contract.attrs.effectiveBeginningDate")));
              }
              return (
                <Form.Item
                  name="signDate"
                  label={t("contract.attrs.signDateInsurance")}
                  rules={rules}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerStandardProps}
                    disabledDate={current =>
                      effectiveBeginningDate ? disableDatePickerOutOfMaxDate(current, effectiveBeginningDate) : false
                    }
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="effectiveBeginningDate"
            label={t("contract.attrs.effectiveBeginningDate")}
            rules={[validations.notNull]}
            {...datePickerFormItemProps}
          >
            <DatePicker {...datePickerStandardProps} onChange={handleEffectiveBeginningChange} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item name="periodType" label={t("contract.enums.periodType._label")} rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={Object.keys(PeriodType).map(type => ({
                value: type,
                label: t("contract.enums.periodType." + type)
              }))}
              onChange={handlePeriodTypeChange}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.effectiveBeginningDate !== next.effectiveBeginningDate || prev.periodType !== next.periodType
            }
          >
            {({ getFieldValue }) => {
              const beginningDate = getFieldValue("effectiveBeginningDate") as string | undefined;
              const periodType = getFieldValue("periodType") as PeriodType;
              const rules: Rule[] = periodType === PeriodType.DEFINITE ? [validations.notNull] : [validations.none];
              if (beginningDate) {
                rules.push(validations.notBefore(beginningDate, t("contract.attrs.effectiveBeginningDate")));
              }
              return (
                <Form.Item
                  name="effectiveEndDate"
                  label={t("contract.attrs.effectiveEndDate")}
                  rules={rules}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerStandardProps}
                    disabled={periodType === PeriodType.INDEFINITE}
                    disabledDate={current =>
                      beginningDate ? disableDatePickerOutOfMinDate(current, beginningDate) : false
                    }
                    onChange={handleStatusDefiningDateChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.effectiveBeginningDate !== next.effectiveBeginningDate ||
              prev.effectiveEndDate !== next.effectiveEndDate
            }
          >
            {({ getFieldValue }) => {
              const beginningDate = getFieldValue("effectiveBeginningDate") as string | undefined;
              const endDate = getFieldValue("effectiveEndDate") as string | undefined;
              const rules = [];
              if (beginningDate) {
                rules.push(validations.notBefore(beginningDate, t("contract.attrs.effectiveBeginningDate")));
              }
              if (endDate) {
                rules.push(validations.notSameOrAfter(endDate, t("contract.attrs.effectiveEndDate")));
              }
              return (
                <Form.Item
                  name="cancellationDate"
                  label={t("contract.attrs.cancellationDate")}
                  rules={rules.length > 0 ? rules : [validations.none]}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current =>
                      (!!beginningDate && disableDatePickerOutOfMinDate(current, beginningDate)) ||
                      (!!endDate && disableDatePickerOutOfMaxDateIncluded(current, endDate))
                    }
                    onChange={handleStatusDefiningDateChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="paymentFrequency"
            label={t("contract.enums.paymentFrequency._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(PaymentFrequency).map(frequency => ({
                value: frequency,
                label: t("contract.enums.paymentFrequency." + frequency)
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="annualPremium"
            label={resolvePremiumLabel("annualPremium")}
            rules={[validations.notNull, validations.minNumber(0.01)]}
          >
            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.annualPremium !== next.annualPremium}>
            {({ getFieldValue }) => (
              <Form.Item
                name="partialPremium"
                label={resolvePremiumLabel("partialPremium")}
                rules={[
                  validations.notNull,
                  validations.minNumber(0.01),
                  validations.maxNumber(getFieldValue("annualPremium"), t("contract.attrs.annualPremium"))
                ]}
              >
                <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.periodType !== next.periodType}>
            {({ getFieldValue }) => {
              const periodType = getFieldValue("periodType") as PeriodType;
              const options = [];
              if (periodType === PeriodType.INDEFINITE) {
                options.push(InsurancePeriod.TECHNICAL_YEAR, InsurancePeriod.CALENDAR_YEAR);
              } else if (periodType === PeriodType.DEFINITE) {
                options.push(InsurancePeriod.FIXED);
              }
              return (
                <Form.Item
                  name="insurancePeriod"
                  label={t("contract.enums.insurancePeriod._label")}
                  rules={[validations.notNull]}
                >
                  <Select
                    {...selectStandardProps}
                    options={options.map(period => ({
                      value: period,
                      label: t("contract.enums.insurancePeriod." + period)
                    }))}
                    onChange={handleInsurancePeriodChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="insurancePeriodEndDate"
            label={t("contract.attrs.insurancePeriodEndDate")}
            rules={[validations.notNull]}
            {...datePickerFormItemProps}
          >
            <DatePicker {...datePickerStandardProps} disabled />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="lastContractCancellationDate"
            label={t("contract.attrs.lastContractCancellationDate")}
            rules={[validations.none]}
            {...datePickerFormItemProps}
          >
            <DatePicker {...datePickerStandardProps} disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="transferredFromOtherBroker"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox style={{ maxHeight: 22 }}>
              <LabelWithTooltip
                label={t("contract.attrs.transferredFromOtherBroker")}
                tooltip={t("contract.helpers.transferredFromOtherBrokerDesc")}
              />
            </Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="transferredToOtherBroker"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox style={{ maxHeight: 22 }} onChange={handleTransferredToOtherBrokerChange}>
              <LabelWithTooltip
                label={t("contract.attrs.transferredToOtherBroker")}
                tooltip={t("contract.helpers.transferredToOtherBrokerDesc")}
              />
            </Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.effectiveBeginningDate !== next.effectiveBeginningDate ||
              prev.effectiveEndDate !== next.effectiveEndDate ||
              prev.cancellationDate !== next.cancellationDate ||
              prev.transferredToOtherBroker !== next.transferredToOtherBroker
            }
          >
            {({ getFieldValue }) => {
              const beginningDate = getFieldValue("effectiveBeginningDate") as string | undefined;
              const endDate = getFieldValue("effectiveEndDate") as string | undefined;
              const cancellationDate = getFieldValue("cancellationDate") as string | undefined;
              const transferredToOtherBroker = getFieldValue("transferredToOtherBroker");
              const rules = [];
              if (beginningDate) {
                rules.push(validations.notSameOrBefore(beginningDate, t("contract.attrs.effectiveBeginningDate")));
              }
              if (cancellationDate) {
                rules.push(validations.notAfter(cancellationDate, t("contract.attrs.cancellationDate")));
              } else if (endDate) {
                rules.push(validations.notAfter(endDate, t("contract.attrs.effectiveEndDate")));
              }
              if (transferredToOtherBroker) {
                rules.push(validations.notNull);
              }
              return (
                <Form.Item
                  name="transferredToOtherBrokerDate"
                  label={
                    <LabelWithTooltip
                      label={t("contract.attrs.transferredToOtherBrokerDate")}
                      tooltip={t("contract.helpers.transferredToOtherBrokerDateDesc")}
                    />
                  }
                  rules={rules.length > 0 ? rules : [validations.none]}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerClearableProps}
                    disabled={!transferredToOtherBroker}
                    disabledDate={current =>
                      (!!beginningDate && disableDatePickerOutOfMinDateIncluded(current, beginningDate)) ||
                      (!!cancellationDate && disableDatePickerOutOfMaxDate(current, cancellationDate)) ||
                      (!!endDate && disableDatePickerOutOfMaxDate(current, endDate))
                    }
                    onChange={handleStatusDefiningDateChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} className="margin-top-small">
        <Col span={24}>
          <Form.Item
            name="note"
            label={t("contract.attrs.note")}
            rules={[validations.size(1, 8192)]}
            initialValue={undefined}
          >
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default InsuranceContractFormDataSection;
