import { Checkbox, Empty, Flex, Form, Input, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import HiddenInput from "../../../../../../common/components/form/components/HiddenInput";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { regexPatterns, validations } from "../../../../../../common/utils/validationUtils";
import { UUID } from "../../../../../../typings/global";
import { deleteCalcSettingsAttachmentConfActions } from "../../../ducks";
import { AttachmentConfType } from "../../../enums";
import { ADMIN_CALC_ROUTE_PATHS } from "../../../paths";
import styles from "./AttachmentsConfList.module.scss";
import type { CustomAttachmentConf } from "./CalcSettingsAttachmentConfsPart";

type AttachmentsConfDetailProps = {
  attachments: CustomAttachmentConf[];
  calcSettingsId: UUID;
  onDeleteAttachmentConf: typeof deleteCalcSettingsAttachmentConfActions.request;
};

export const AttachmentsConfList = ({
  attachments,
  calcSettingsId,
  onDeleteAttachmentConf
}: AttachmentsConfDetailProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {attachments.map(conf => (
        <div key={conf.key} className={styles.box}>
          <HiddenInput name={["attachmentConfs", conf.key, "id"]} />
          <HiddenInput name={["attachmentConfs", conf.key, "optimisticLockVersion"]} />

          <div style={{ marginBottom: 4 }}>
            <div className={styles.header}>
              <span>
                <span>{t("calc.settings.attrs.attachmentConfs.originalFilename")}: </span>
                <ActionTextIcon
                  path={generatePath(ADMIN_CALC_ROUTE_PATHS.attachmentConf.to, {
                    id1: calcSettingsId,
                    id2: conf.id
                  })}
                  target="_blank"
                  icon="export"
                  color="green"
                  size="large"
                  text={conf.originalFilename}
                />
              </span>
              <span className={styles.deleteWrapper}>
                <Popconfirm
                  title={t("calc.settings.titles.deleteAttachmentConfirm")}
                  icon={<PopconfirmDeleteIcon />}
                  okText={t("common.yes")}
                  cancelText={t("common.no")}
                  okType="danger"
                  onConfirm={() => onDeleteAttachmentConf({ id1: calcSettingsId, id2: conf.id })}
                >
                  <span>
                    <AntIcon type="delete" color="red" size="huge" />
                  </span>
                </Popconfirm>
              </span>
            </div>

            <ItemCreatedUpdatedInfoView item={conf} />
          </div>
          <Flex gap="middle" align="flex-end">
            <Form.Item
              style={{ width: 350 }}
              name={["attachmentConfs", conf.key, "filename"]}
              label={t("calc.settings.attrs.attachmentConfs.filename")}
              rules={[validations.notBlank, validations.pattern(regexPatterns.filenameRegex)]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              style={{ display: "inline-block" }}
              name={["attachmentConfs", conf.key, "shouldAttachToEmail"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("calc.settings.attrs.attachmentConfs.shouldAttachToEmail")}</Checkbox>
            </Form.Item>

            <Form.Item
              style={{ display: "inline-block" }}
              name={["attachmentConfs", conf.key, "shouldAttachToContract"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("calc.settings.attrs.attachmentConfs.shouldAttachToContract")}</Checkbox>
            </Form.Item>

            <Form.Item
              style={{ display: "inline-block" }}
              name={["attachmentConfs", conf.key, "shouldEncrypt"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("calc.settings.attrs.attachmentConfs.shouldEncrypt")}</Checkbox>
            </Form.Item>

            {conf.type !== AttachmentConfType.PROVIDED ? (
              <Form.Item
                style={{ display: "inline-block" }}
                name={["attachmentConfs", conf.key, "shouldAttachToCalc"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("calc.settings.attrs.attachmentConfs.shouldAttachToCalc")}</Checkbox>
              </Form.Item>
            ) : undefined}
          </Flex>
        </div>
      ))}

      {attachments.length === 0 ? <Empty className={styles.emptyBox} description={t("common.noData")} /> : undefined}
    </div>
  );
};
