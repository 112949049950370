import { Route, Routes } from "react-router";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import DocumentNodeContainer from "./containers/DocumentNodeContainer";

const routes = () => (
  <Routes>
    <Route index element={<DocumentNodeContainer />} />
    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
