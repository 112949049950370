import cloneDeep from "lodash/cloneDeep";
import { CreateUpdateContractClient } from "../../../client/types";
import { createUpdateContractClientToClient } from "../../../client/utils";
import { CalcType } from "../../enums";
import {
  TravelCalc,
  TravelCalcCoveragesData,
  TravelCalcInsuredClient,
  TravelFormClients,
  TravelGen,
  TravelGenForm,
  TravelGenFormInsuredClient
} from "./types";

export const createTravelCalcObjectFromCalcData = (calcData: TravelCalc): TravelCalc => {
  let data = cloneDeep(calcData);

  const cancellationData = calcData.cancellationData;
  if (cancellationData?.firstPaymentDate) {
    data = {
      ...data,
      cancellationData: {
        ...cancellationData
      }
    };
  }

  const coveragesCancellationData = calcData.coveragesData?.cancellationData;
  if (coveragesCancellationData?.firstPaymentDate) {
    data = {
      ...data,
      coveragesData: {
        ...(data.coveragesData as TravelCalcCoveragesData),
        cancellationData: {
          ...coveragesCancellationData
        }
      }
    };
  }

  return data;
};

export const createTravelFormClientsObject = (genData: TravelGen): TravelFormClients => ({
  policyHolder: createUpdateContractClientToClient(genData.clientsData.clients[0] as CreateUpdateContractClient),
  representative: createUpdateContractClientToClient(genData.clientsData.clients[1] as CreateUpdateContractClient)
});

export const createTravelCalcObjectFromGenData = (genData: TravelGen): TravelCalc => {
  const genDataCopy = cloneDeep(genData);
  const data = {
    type: CalcType.TRAVEL,
    generalData: genDataCopy.generalData,
    clientsData: {
      insuredClientsCount: genDataCopy.clientsData.insuredClientsCount,
      insuredClients: genDataCopy.clientsData.insuredClients
        ? genDataCopy.clientsData.insuredClients.map<TravelCalcInsuredClient>(client => ({
            birthDate: client.birthDate,
            discountType: client.discountType
          }))
        : null
    },
    cancellationData: genDataCopy.cancellationData,
    coveragesData: genDataCopy.coveragesData,
    discountsData: genDataCopy.discountsData
  } as TravelCalc & TravelGen;

  if (data.coveragesData) {
    delete data.coveragesData.vehicleAssistanceData;
    delete data.coveragesData.abandonedHouseholdData;
    delete data.coveragesData.petData;
  }

  return createTravelCalcObjectFromCalcData(data);
};

export const createTravelGenFormDataObject = (genData: TravelGen, clients: TravelFormClients): TravelGenForm => {
  return {
    clientsData: {
      policyHolderIdentifier: clients.policyHolder?.identifier,
      policyHolderIsAlsoInsured: genData.clientsData.policyHolderIsAlsoInsured,
      policyHolderEmail: genData.clientsData.policyHolderEmail,
      policyHolderPhone: genData.clientsData.policyHolderPhone,
      policyHolderMarketingConsent: genData.clientsData.policyHolderMarketingConsent,
      representativeIdentifier: clients.representative?.identifier,
      representativeFunction: genData.clientsData.representativeFunction,
      insuredClientForFamilyInsurance: genData.clientsData.insuredClientForFamilyInsurance,
      insuredClients: genData.clientsData.insuredClients?.map<TravelGenFormInsuredClient>(client => ({
        birthDate: client.birthDate,
        personalIdentificationNumber: client.personalIdentificationNumber,
        firstName: client.firstName ?? "",
        lastName: client.lastName ?? "",
        academicDegree: client.academicDegree,
        academicDegreeAfter: client.academicDegreeAfter,
        discountIdentifier: client.discountIdentifier
      }))
    },
    coveragesData: genData.coveragesData
      ? {
          vehicleAssistanceData: genData.coveragesData.vehicleAssistanceData,
          abandonedHouseholdData: genData.coveragesData.abandonedHouseholdData,
          petData: genData.coveragesData.petData
        }
      : null,
    financialMediationData: genData.financialMediationData
  } as TravelGenForm;
};
