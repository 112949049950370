import { Col, Divider, Form, Row } from "antd";
import t from "../../../../../../app/i18n";
import { rowGutter } from "../../../../../../common/constants";
import { validations } from "../../../../../../common/utils/validationUtils";
import ProductSelect from "../../../../../enumerations/components/form/ProductSelect";
import { ProductFinancialSector } from "../../../../../product/enums";
import { CalcType } from "../../../../enums";

interface Props {
  calcType: CalcType;
}

const CalcSettingsProductsPart = ({ calcType }: Props) => {
  const formProductsIds: string[] = [];
  switch (calcType) {
    case CalcType.MTPL:
      formProductsIds.push("mtplProductId");
      break;
    case CalcType.CRASH:
      formProductsIds.push("crashProductId");
      break;
    case CalcType.MTPL_CRASH:
      formProductsIds.push("mtplCrashProductId");
      break;
    case CalcType.GAP:
      formProductsIds.push("gapProductId");
      break;
    case CalcType.PAS:
      formProductsIds.push("pasProductId");
      break;
    case CalcType.REALTY:
      formProductsIds.push("realtyProductId");
      break;
    case CalcType.TRAVEL:
      formProductsIds.push("shortTermInsuranceProductId", "yearInsuranceProductId", "cancellationInsuranceProductId");
      break;
  }

  const colSpan = 5;

  return (
    <>
      <Divider orientation="left">{t("calc.settings.sections.basicSettings")}</Divider>

      <Form.Item noStyle shouldUpdate={(prev, next) => prev.institutionId !== next.institutionId}>
        {({ getFieldValue }) => {
          const institutionId = getFieldValue("institutionId");
          return (
            <Row gutter={rowGutter}>
              {formProductsIds.map((productId, index) => (
                <Col span={colSpan} key={index}>
                  <ProductSelect
                    formItemProps={{
                      name: productId,
                      label: t(`calc.settings.attrs.${productId}`),
                      rules: [validations.notNull]
                    }}
                    optionsProps={{
                      institutionId,
                      groupByProductGroup: true,
                      filterSectors: [ProductFinancialSector.NON_LIFE_INSURANCES]
                    }}
                  />
                </Col>
              ))}
            </Row>
          );
        }}
      </Form.Item>
    </>
  );
};

export default CalcSettingsProductsPart;
