import { Checkbox, Form } from "antd";
import { NamePath } from "rc-field-form/lib/interface";
import { validations } from "../../../utils/validationUtils";

interface Props {
  name: NamePath;
  initialValue?: boolean;
}

const HiddenCheckbox = ({ name, initialValue }: Props) => (
  <Form.Item
    hidden
    noStyle
    name={name}
    rules={[validations.none]}
    valuePropName="checked"
    initialValue={!!initialValue}
  >
    <Checkbox type="hidden" />
  </Form.Item>
);

export default HiddenCheckbox;
