import { Form, Input, Modal } from "antd";
import t from "../../../../../app/i18n";
import { ModalSizes } from "../../../../../common/constants";
import {
  phoneNumberNormalizeFunction,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import { AgentCreateUserWithClientUserAccount } from "../../../../user/types";
import { requests } from "../../../api";
import { createUserWithClientUserAccountActions } from "../../../ducks";
import { Client } from "../../../types";

interface Props {
  open: boolean;
  client: Client;
  onFormSubmit: typeof createUserWithClientUserAccountActions.request;
  onFormCancel: () => void;
}

const CreateClientUserAccountForm = (props: Props) => {
  const [form] = Form.useForm<AgentCreateUserWithClientUserAccount>();
  useFormErrorHandler(form, "user.attrs", [requests.CREATE_USER_WITH_CLIENT_USER_ACCOUNT]);

  const inProgress = useRequestFinishedCallback([requests.CREATE_USER_WITH_CLIENT_USER_ACCOUNT], props.onFormCancel);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        props.onFormSubmit({
          id: props.client.id,
          object: values
        });
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={props.open}
      title={t("user.titles.addAccount")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={props.onFormCancel}
    >
      <Form form={form} layout="vertical" name="clientUserAccountCreateForm">
        <Form.Item
          name="name"
          label={t("user.attrs.name")}
          rules={[validations.notNull, validations.size(1, 255), validations.pattern(regexPatterns.nameRegex)]}
        >
          <Input placeholder={t("user.helpers.userNamePlaceholder")} />
        </Form.Item>

        <Form.Item
          name="email"
          label={t("user.attrs.email")}
          rules={[validations.notBlank, validations.size(1, 254), validations.email]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone"
          label={t("user.attrs.phone")}
          rules={[validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber]}
          normalize={phoneNumberNormalizeFunction}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateClientUserAccountForm;
